<template>
    <div>
        
        <!-- <div class="container full_container_dsk"> -->
        <div class="row">
           
              <!-- <div class="row mobile_email_row custom_dsk_row"> -->
              <div class="container my_new_container mobile_email_row custom_dsk_row">
                  <!-- Dashboard Headline -->
                  <!-- <div class="dashboard-headline">
                      <h1><span class="icon-material-outline-arrow-back new_arrow_back" @click="$router.go(-1)"></span>{{last_name | capitalize}} {{first_name | capitalize}} Badges</h1>
                             <button class="btn green-border-btn add_btn_admin_e desktop_margin_right_zero desktop_margin_left_zero"  @click="openInfoModel()" style="background-color: rgb(76, 139, 245);color: white; margin-top: 15px !important">Award Badge</button>

                     
                      <nav id="breadcrumbs" class="dark email_tmp_tbl_nav">
                          <ul>
                              <li><a href="#">Home</a></li>
                              <li>Badges</li>

                          </ul>
                      </nav>
                  </div> -->
                  <div class="manage_candidates_singlez">
                    <div id="breadcrumb-v2">
                            <b-breadcrumb>
                                <b-breadcrumb-item href="" :to="{name: 'jobseekers-list'}">Jobseekers</b-breadcrumb-item>
                                <b-breadcrumb-item active>{{last_name | capitalize}} {{first_name | capitalize}} Badges</b-breadcrumb-item>
                            </b-breadcrumb>
                        </div>
                        <button class="btn green-border-btn add_btn_admin_e desktop_margin_right_zero desktop_margin_left_zero"  @click="openInfoModel()" v-if="show('award badge')" style="background-color: rgb(76, 139, 245);color: white; margin-top: 15px !important">Award Badge</button>
                  </div>
                  <!-- <div class="row">
                    <div class="col-xl-8 col-md-8 mobile_tab_max_width_flex text_align_end_desktop search_common_margin_bottom_28">
                        <button class="btn green-border-btn add_btn_admin_e desktop_margin_right_zero desktop_margin_left_zero"  @click="openInfoModel()" style="background-color: rgb(76, 139, 245);color: white;">Award Badge</button>
                    </div>
                  </div> -->
                  <!-- <div class="col-xl-4 remove_search_box_pdng">
                      <div class="search-box custom_search_admin">
                          <b-input-group>
                          <b-input-group-prepend>
                              <b-form-input id="searchBtn" v-model="filter" v-on:change="getBadge" placeholder="Enter keyword to search"></b-form-input>
                              <b-button class="btn"><i class="fa fa-search"></i></b-button>
                          </b-input-group-prepend>
                          </b-input-group>
                      </div>
                  </div> -->

                  
                  </div>

            </div>

        <!-- Page Content
        ================================================== -->
        <!-- <div class="container full_container_dsk"> -->
        <div class="row">
           <!-- <div class="row"> -->
           <div class="container my_new_container show_filter_arrow">
				        <b-table ref="datatable" show-empty striped hover responsive="" :items="getBadge" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" class="mobile_min_width_1000 certificate_table">

                  <template #cell(name)="items">
                    {{items.item.name | capitalize}}
                    </template>
                  <!-- <template #cell(url)="items">
                      <p v-if="items.item.user_cert.length > 0 ">
                        <a :href="items.item.user_cert[0].url" target="_blank"><img :src="items.item.user_cert[0].url" height="80" width="80"/></a>
                      </p>
                  </template> -->
                 <template #cell(company_name)="items">
                    {{items.item.company_name | capitalize}}
                  </template>
                  <template #cell(badge_image)="items">
                    <img :src="(items.item.badge_image != null ? items.item.badge_image: webUrl+'assets/images/logo.png')" height="100px" width="100px" alt="logo">
                  </template>
                  <template #cell(remarks)="items">
                    {{items.item.remarks | capitalize}}
                  </template>
                  <!-- <template slot="status" slot-scope="items">
                    {{items.item.certificate_types != null ? items.item.certificate_types.user_certificates.status  : '-' }}
                  </template> -->
                 
                    <template #cell(toggleAction)="items">
                    <div class="toggle-action">
                      <b-dropdown class="mx-1" right text="Actions" boundary="window">
                          <b-dropdown-item v-if="show('edit remark')"  @click="openRemarkModel(items.item.id,items.item.user_id,items.item.badge_id,items.item.remarks)">Edit Remark 
                          </b-dropdown-item>
                          <b-dropdown-item  v-if="show('delete badge')" @click="deleteRecord(items.item.id,items.item.user_id,items.item.badge_id)">Delete 
                          </b-dropdown-item>
                      </b-dropdown>
                    </div>
                </template>
                </b-table>
                <nav class="pagination-box  custom_pagination_border_radius">
                    <div class="page-row-box d-inline-block">
                        <b-form-group id="input-group-4">
                             <span class="d-inline-block">Rows per page</span>
                            <span class="d-inline-block">
                            <b-form-select v-model="form.rowsPerPage" @change='getBadge'>
                                        <option>25</option>
                                        <option>50</option>
                                        <option>75</option>
                                        <option>100</option>
                            </b-form-select></span>
                        </b-form-group>
                    </div>
                    <div class="total-page-count d-inline-block">
                        <ul>
                            <li>
                                <span v-if='totalRows != 0'>{{from}} - {{to}} of {{totalRows}}</span>
                            </li>
                        </ul>
                    </div>
                    <b-pagination :total-rows="totalRows"   :per-page="perPage" v-model="currentPage" prev-text="" next-text="" hide-goto-end-buttons />
                </nav>
			      </div>
        </div>
        <!-- Spacer -->
        <!-- <div class="margin-top-70"></div> -->
        <!-- Spacer / End-->
        <!-- Row / End -->
       
        <b-modal ref="badge-modal" title="Award Badge" hide-footer modal-class="background_custom_class" content-class="common_model_header common_model_title">
                <div class="edit-form-box model_margin_top_zero model_legend">
                    <div class="profile-repeate form-box-field">
                        <b-row>
                            <b-col lg="12" md="12" class="submit-field">
                                <b-form-group id="input-group-8" label="Select Badge" class="required model_margin_bottom_zero">
                                    <multiselect v-model="awarded_badge" id="ajax" label="name" track-by="id" placeholder="Type to search badge" open-direction="bottom" :options="allBadges" :multiple="true" :searchable="true" :clear-on-select="true" :close-on-select="true" :value="awarded_badge" :allow-empty="true" class="job_list_multi_select model_multi_select">
                                        <span slot="noResult">No Data Found.</span>
                                    </multiselect>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="12" md="12" class="submit-field">
                                <b-form-group id="input-group-8" label="Select Companies" class="required model_margin_bottom_zero">
                                    <multiselect v-model="awarded_badge_companies" id="ajax" label="name" track-by="id" placeholder="Type to search company" open-direction="bottom" :options="filteredCompanies" :multiple="false" :searchable="true" :clear-on-select="true" :close-on-select="true" :value="awarded_badge_companies" :allow-empty="true" class="job_list_multi_select model_multi_select">
                                        <span slot="noResult">No Data Found.</span>
                                    </multiselect>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                        <b-col lg="12" md="12" class="submit-field">
                            <b-form-group id="input-group-0" label="Remarks" class="model_margin_bottom_zero">
                                <b-form-textarea id="textarea-3" type="textarea" placeholder="" :value="badge_remarks" @change="v => badge_remarks = v" class="model_box_shadow_unset model_margin_bottom_zero model_text_area"></b-form-textarea>
                            </b-form-group>
                        </b-col>
                        </b-row>
                        <div class="text-md-left text-center desktop_margin_top_zero common_crop_reset_btn">
                            <b-button type="button" @click="saveBadges()" variant="success" class="model_common_bt_success_color text-uppercase x-md btn-shadow desktop_margin_left_zero desktop_margin_right_zero">Update</b-button>

                        </div>
                    </div>
                </div>
        </b-modal>
        <b-modal ref="remark-modal" title="Edit Remark" hide-footer modal-class="background_custom_class" content-class="common_model_header common_model_title">
                <div class="edit-form-box model_margin_top_zero model_legend">
                    <div class="profile-repeate form-box-field">
                        <b-row>
                            <b-col lg="12" md="12" class="submit-field">
                                <b-form-group id="input-group-0" label="Remarks" class="model_margin_bottom_zero">
                                    <b-form-textarea id="textarea-3" type="textarea" placeholder="" :value="badge_remarks_update" @change="v => badge_remarks_update = v" class="model_box_shadow_unset model_margin_bottom_zero model_text_area"></b-form-textarea>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <div class="text-md-left text-center desktop_margin_top_zero common_crop_reset_btn">
                            <b-button type="button" @click="saveRemark()" variant="success" class="model_common_bt_success_color text-uppercase x-md btn-shadow desktop_margin_left_zero desktop_margin_right_zero">Update</b-button>

                        </div>
                    </div>
                </div>
        </b-modal>
    </div>
</template>

<script>
    import { POST_API} from "../../../../store/actions.type";
    import JwtService from "../../../../common/jwt.service";
    import moment from "moment";
    import Multiselect from 'vue-multiselect'
    import permission from "../../../../../../server/permission.js";
  
    export default {
  
      data() {
        return {
          fields: [
                  { key: 'name', label: 'Badge Name', sortable: true },
                  { key: 'company_name', label: 'Company Name', sortable: true },
                  { key: 'badge_image', label: 'Badge Image', sortable: true },
                  { key: 'remarks', label: 'Remarks', sortable: true },
                  // { key: 'status', label: 'Status', sortable: true },
                  { key: 'toggleAction', label: 'Actions', }
              ],
              form: {
                rowsPerPage:25,
              },
              allBadges : [],
              awarded_badge:'',
              awarded_badge_companies:'',
              awardedBadgeCompaniesList : [],
              badge_remarks:'',
              badge_remarks_update:'',
              user_badge_id:'',
              remark_badge_id:'',
              remark_user_id:'',
              first_name:'',
              last_name:'',
              items:null,
              totalRows: 1,
              currentPage: 1,
              perPage: 25,
              pageOptions: [25, 50, 75, 100],
              sortBy: null,
              sortDirection: 'asc',
              sortDesc: false ,
              filter: null,
              status: 'active',
              isBusy: false,
              to:'',
              from:'',
              webUrl: process.env.MIX_APP_URL,
              actions:'',
              user_type_id:'',
              fl_id: atob(this.$route.params.fl_id),
              actions:'',
              
        }
      },
      computed: {
        filteredCompanies() {
            const fullCompanies = this.$store.getters.getResults.companies;
            if (!this.awarded_badge || this.awarded_badge.length === 0) {
                return fullCompanies;
            }
            const anyNull = this.awarded_badge.some(badge => badge.company_id === null);
            if (anyNull) {
                return fullCompanies;
            }
            if (this.awarded_badge.length === 1) {
                const companyIdStr = this.awarded_badge[0].company_id;
                if (companyIdStr.indexOf(",") !== -1) {
                const companyIds = companyIdStr.split(",").map(id => Number(id.trim()));
                return fullCompanies.filter(c => companyIds.includes(c.id));
                } else {
                const compId = Number(companyIdStr);
                return fullCompanies.filter(c => c.id === compId);
                }
            }
            function parseIds(companyId) {
                return companyId
                .toString()
                .split(',')
                .map(id => Number(id.trim()))
                .sort((a, b) => a - b);
            }

            const firstIds = parseIds(this.awarded_badge[0].company_id);
            const allSame = this.awarded_badge.every(badge => {
                const ids = parseIds(badge.company_id);
                if (ids.length !== firstIds.length) return false;
                for (let i = 0; i < ids.length; i++) {
                if (ids[i] !== firstIds[i]) return false;
                }
                return true;
            });

            if (allSame) {
                return fullCompanies.filter(c => firstIds.includes(c.id));
            }
            return fullCompanies;
            }
     },
     watch: {
        awarded_badge: {
          handler(newVal) {
            if (newVal.length > 0) {
            const companyIds = newVal.map(badge => badge.company_id);
            const uniqueIds = [...new Set(companyIds)];
            if (uniqueIds.length === 1 && uniqueIds[0] !== null) {
                const filtered = this.filteredCompanies;
                if (filtered.length === 1) {
                this.awarded_badge_companies = filtered[0];
                }
            } else {
                this.awarded_badge_companies = "";
            }
            } else {
            this.awarded_badge_companies = "";
            }
        },
        deep: true
        }
      },
      components: {
        Multiselect
      },
      filters: {
          moment1: function(date) {
             if(date != ""){

               return moment(date).utc().format('DD MMM YYYY');

             }else{
               return '-'
             }
          },
          capitalize: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
          },
        },
      methods: {
          onFiltered(filteredItems) {
              // Trigger pagination to update the number of buttons/pages due to filtering
              this.totalRows = filteredItems.length
              this.currentPage = 1
          },
          showAlert() {
            this.$swal({
                position: 'center',
                icon: 'error',
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            })
        },
          hideModel(){
            this.$refs['badge-modal'].hide();
            this.$refs['remark-modal'].hide();
          },
          openInfoModel(id,type,info = null){
            this.awarded_badge = '';
            this.awarded_badge_companies = '';
            this.getBadgeList(id);
            this.badge_remarks = '';
            this.$refs['badge-modal'].show();
          },
          openRemarkModel(id,user_id,badge_id,remarks){
            this.user_badge_id = id;
            this.remark_badge_id = badge_id;
            this.remark_user_id = user_id;
            this.badge_remarks_update = remarks;
            this.$refs['remark-modal'].show();
          },
          getBadge() {
              return this.$store.dispatch(POST_API, {
                     data:{
                       status: this.status,
                       user_id:this.fl_id,
                       page: this.currentPage,
                       keyword: this.filter,
                       rowsPerPage:this.form.rowsPerPage,
                       sortBy:this.sortBy,
                       sortDirection:this.sortDirection,
                       sortDesc:this.sortDesc,
                       type:'web'
                     },
                     api: '/api/flBadgeList'
                  })
                  .then(() => {
                      if (this.$store.getters.containsErrors) {
                          this.error_message = this.$store.getters.getErrors;
                          this.showAlert();
                          return [];
                      } else {
                          this.items = this.$store.getters.getResults.pageOfItems;
                          this.totalRows = this.$store.getters.getResults.pager.total;
                          this.perPage = this.$store.getters.getResults.pager.per_page;
                          this.from = this.$store.getters.getResults.pager.from
                          this.to = this.$store.getters.getResults.pager.to
                          return this.items;
                      }
                  });
          },
        getBadgeList(id) {
            this.$store.dispatch(POST_API, {
                data:{
                    user_id: this.fl_id,
                },
                api: '/api/badge-list-admin'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                } else {
                    // this.allBadges = this.$store.getters.getResults.badges;
                    this.allBadges = this.$store.getters.getResults.badges_not_awarded;
                    // this.awarded_badge = this.$store.getters.getResults.awarded_badge;
                    this.awardedBadgeCompaniesList = this.$store.getters.getResults.companies;
                    // this.awarded_badge_companies = this.$store.getters.getResults.awarded_badge_companies;
                }
            });
        },
        saveBadges(){
            var msg = "Are you sure you want to award this badge to user ?";
            var msgg = 'Badge awarded successfully.';
            this.$swal({
                title: 'Please Confirm',
                text: msg,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            })
            .then(result => {
              if (result.value) {
                  return this.$store.dispatch(POST_API, {
                    data:{
                          user_id: this.fl_id,
                          awarded_badge:this.awarded_badge,
                          remarks:this.badge_remarks,
                          awarded_badge_companies:this.awarded_badge_companies
                        },
                    api:'/api/award-badge-to-user',
                      })
                      .then(() => {
                          if (this.$store.getters.containsErrors) {
                              this.error_message = this.$store.getters.getErrors;
                              // this.hideModel();
                              this.showAlert();
                          } else {
                            this.hideModel();
                            this.$swal({
                                position: 'center',
                                icon: 'success',
                                iconColor:'#4c8bf5',
                                title: msgg,
                                showConfirmButton: false,
                                timer: 2500
                            }).then((result) => {
                                this.awarded_badge = '';
                                this.awarded_badge_companies = '';
                                this.badge_remarks = '';
                                this.$refs.datatable.refresh();
                            });
                          }
                      });
                    }
                })
                .catch(err => {
                    // An error occurred
                })
        },
        saveRemark(){
            var msg = "Are you sure you want to save this remark to badge ?";
            var msgg = 'Remark updated successfully.';
            this.$swal({
                title: 'Please Confirm',
                text: msg,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            })
            .then(result => {
              if (result.value) {
                  return this.$store.dispatch(POST_API, {
                    data:{
                          id: this.user_badge_id,
                          user_id: this.remark_user_id,
                          badge_id: this.remark_badge_id,
                          remarks:this.badge_remarks_update,
                        },
                    api:'/api/update-badge-remark',
                      })
                      .then(() => {
                          if (this.$store.getters.containsErrors) {
                              this.error_message = this.$store.getters.getErrors;
                              this.showAlert();
                          } else {
                            this.hideModel();
                            this.$swal({
                                position: 'center',
                                icon: 'success',
                                iconColor:'#4c8bf5',
                                title: msgg,
                                showConfirmButton: false,
                                timer: 2500
                            }).then((result) => {
                                this.badge_remarks_update = '';
                                this.user_badge_id = '';
                                this.remark_user_id = '';
                                this.remark_badge_id = '';
                                this.$refs.datatable.refresh();
                            });
                          }
                      });
                    }
                })
                .catch(err => {
                    // An error occurred
                })
        },
          deleteRecord(id,user_id,badge_id) {
               this.$swal({
                title: 'Please Confirm',
                text: 'Are you sure you want to delete this record?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes!'
            })
            .then(result => {
                if (result.value) {
                  this.$store.dispatch(POST_API, {
                    data:{
                        id:id,
                        user_id: user_id,
                        badge_id: badge_id,
                    },
                    api: '/api/delete-fl-badge'
                  })
                  .then(() => {
                      if (this.$store.getters.containsErrors) {
                          this.error_message = this.$store.getters.getErrors;
                          this.showAlert();
                      } else {
                        this.success_message = this.$store.getters.getResults.message;
                            this.$swal({
                                position: 'center',
                                icon: 'success',
                                iconColor:'#4c8bf5',
                                title: 'Record deleted successfully.',
                                showConfirmButton: false,
                                timer: 1500
                            }).then((result) => {
                                this.$refs.datatable.refresh();
                            })
                      }
                  });
               }
            })
          },
          getUserData() {

            return this.$store.dispatch(POST_API, {
                data: {
                    id: atob(this.$route.params.fl_id),
                },
                api: '/api/user-data'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                    return [];
                } else {
                    if(this.$store.getters.getResults.data){
                        this.first_name = this.$store.getters.getResults.data.first_name;
                        this.last_name = this.$store.getters.getResults.data.last_name;
                    }
                }
            });
          },
          permissionStaff(){
                if(this.$store.getters.getCurrentUserPermission){
                if (this.$store.getters.getCurrentUserPermission.data.length > 0 && this.$store.getters.currentUser.user_type_id == 2) {
                var menu = this.$store.getters.getCurrentUserPermission.data;
                this.actions = permission.getPermissionStaff(menu,'Manage Badge');
                }
                }
            },
            show(action){
                if (this.$store.getters.currentUser.user_type_id == 2) {
                    return this.actions.indexOf(action) >= 0 ? true : false ;  
                }else{
                    return true;
                }
            },
          
      },
      mounted(){
        $(".show_filter_arrow .table thead th div").contents().unwrap();
        $(".dashboard-content-container .simplebar-scroll-content").animate({ 
            scrollTop: 0 
        }, "fast");
          this.user_type_id = this.$store.getters.currentUser.user_type_id;
          this.user_id = this.$store.getters.currentUser.id;
          this.fl_id = atob(this.$route.params.fl_id);
          this.getUserData();
          this.permissionStaff();


      }
    }
    
  </script>
  
  