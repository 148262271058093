<template>
  <div>
    <div class="row">
      <div class="container my_new_container">
        <!-- Dashboard Headline -->
        <div class="dashboard-headline">
          <h1>Daily Statistics</h1>
          <nav id="breadcrumbs" class="dark">
            <ul>
              <li><a href="#">Home</a></li>
              <li>Daily Statistics</li>
            </ul>
          </nav>
        </div>

        <!-- Input Fields -->
        <div class="row">
          <div class="col-xl-3">
            <b-form-group class="common_date_range_picker_new">
              <datepicker
                v-model="month"
                @input="dateChange"
                :format="customFormatter"
                minimum-view="month"
                maximum-view="year"
                class="add_calender_icon"
              ></datepicker>
            </b-form-group>
          </div>

          <!-- Export Data Button -->
          <div class="col-xl-3">
            <b-button class="button ripple-effect export-btn" @click="exportData">
              Export Data
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

---

### **🚀 Vue Script Section (Methods and API Calls)**
```vue
<script>
import { POST_API } from "../../../store/actions.type"; 
import moment from "moment";
import Datepicker from "vuejs-datepicker";

export default {
  components: {
    Datepicker,
  },
  data() {
    return {
      month: moment().format("DD MMM YYYY"),
      webServerUrl: process.env.VUE_APP_SERVER_URL, 
      debugMode: true, // Enable debug mode to print values
    };
  },
  methods: {
    dateChange() {
      console.log(this.month);
    },
    customFormatter(date) {
      return moment(date).format("MMM, YYYY");
    },

    // ✅ Export Functionality (Retained)
    exportData() {
      var base_url = this.webServerUrl;

      // Ensure 'this.month' is correctly formatted as a Moment.js object
      const selectedDate = moment(this.month, "DD MMM YYYY");  
      var year = selectedDate.format("YYYY"); // e.g., 2024
      var month = selectedDate.format("MM");  // e.g., 01, 02, ..., 12

      var export_url = `/api/emp-daily-statistics-export/${year}/${month}`;
      window.open(base_url + export_url, "_blank");
    },
  },
};
</script>
