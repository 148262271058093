<template>
    <div>
           
        <!-- Titlebar
        ================================================== -->
        <div>
        
        <div class="bg_img_top static_page_bg" v-bind:style="{backgroundImage: privacy_policy.image && privacy_policy.show_banner_image == 'yes' ? 'url('+ privacy_policy.image +')' : 'url('+ webUrl+ 'assets/images/faq-bg.jpeg)'}">
       
        <!-- <div id="titlebar" class="gradient signup_mrgn faq_mrgn"> -->
            <div class="full_gragient">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h1 class="text-center faq_haed" v-html="privacy_policy.title"></h1>
                    </div>
                </div>
            </div>
            </div>
        </div>
        

        <div class="margin-top-50 mobile_tab_margin_top_35"></div> 
    <!-- Page Content
        ================================================== -->
        <div class="container">
            <div class="row">
                <div class="col-xl-12 common_block common_desc">
                   <p v-html="privacy_policy.description"></p>
                </div>
            </div>
        </div>

       

        <!-- Spacer -->
        <div class="margin-top-50 mobile_tab_margin_top_35"></div>
        <!-- Spacer / End-->
    </div>
    </div>
</template>


<script>
    import { POST_API } from "../../store/actions.type"

    export default {
        metaInfo: {
            title: 'EL Connect - Privacy Policy',
            meta: [
                { charset: 'utf-8' },
            { equiv: 'content-type', content: 'text/html' },
                { name: 'description', content: 'Privacy Policy - Learn how EL Connect collects, uses, and protects your personal data. Your privacy matters to us, and we ensure secure data handling.' },
                { name: 'viewport', content: 'width=device-width, initial-scale=1' },
                {property: 'og:title', content: 'EL Connect - Privacy Policy'},
                {property: 'og:site_name', content: 'EL Connect'},
                {property: 'og:type', content: 'website'},
                {property: 'og:url', content: 'https://elconnect.sg/privacy-policy'},
                {property: 'og:image', content: 'https://elconnect.sgp1.digitaloceanspaces.com/v2/general_setting/202281202127_general_setting.png'},
                {property: 'og:description', content: 'Privacy Policy - Learn how EL Connect collects, uses, and protects your personal data. Your privacy matters to us, and we ensure secure data handling.'},
                {name: 'twitter:card', content: 'summary'}
            ],
            link: [
                {rel: 'canonical', href: 'https://elconnect.sg/privacy-policy'}
            ]
        },
        data() {
            return {
                privacy_policy:[],
                showDismissibleAlert:false,
                error_message:'',
                display:true,
                link_error:'',
                webUrl: process.env.VUE_APP_URL,
            }
        },
        methods:{
            getPrivacyPolicy(){
                 this.$store.dispatch(POST_API, {
                    data:{
                        token: this.email,
                    },
                    api:'/api/privacy-policy'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                       
                        this.$swal({
                            position: 'center',
                            icon: 'error',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });
                             
                    } else {
                        this.privacy_policy = this.$store.getters.getResults.privacy_policy[0];
                        this.scrollData()
                        // if(window.location.href.substring(window.location.href.lastIndexOf('/') + 1) == '#account-deletion'){
                        //     console.log(window.location.href.substring(window.location.href.lastIndexOf('/') + 1),'sdfgdf')
                        //     // document.getElementsById('#otherInformation').scrollIntoView();
                        //     $('html, body').scrollTop($('h3:first').offset().top);
                        //     // document.getElementsByTagName('h2')[3].scrollIntoView();
                        //     // window.scroll({ behavior: "smooth", top: 0 }); 
                        // }else{
                        //     window.scrollTo(0, 0)

                        // }
                    }
            })

            },
            scrollData(){
                if(window.location.href.substring(window.location.href.lastIndexOf('/') + 1) == '#account-deletion'){
                    function Scrolldown() {
                        // document.querySelector("#otherInformation").scrollIntoView({ behavior: "smooth", block: "center" });
                    document.getElementsByTagName('h3')[4].scrollIntoView({ behavior: "smooth", block: "center" });
                    }
                    window.onload = Scrolldown;
                    console.log(window.location.href.substring(window.location.href.lastIndexOf('/') + 1),'sdfgdf')
                }else{
                    window.scrollTo(0, 0)

                }
            }       
        
            
        },
        mounted(){
            this.getPrivacyPolicy();
            

            //document.title = "EL Connect - Privacy Policy";
            

        },
    }
</script>
