<template>

    <div>

         <!-- <div class="back_arrow_icon"> -->
                <!-- <span class="icon-material-outline-arrow-back new_arrow_back" @click="$router.go(-1)"></span> -->
                <!-- <b-link class="icon-material-outline-arrow-back new_arrow_back" :to="{name: 'manage-jobs'}"></b-link> -->
        <!-- </div> -->
        <div id="breadcrumb-v2" class="mb-1">
            <div class="d-flex justify-content-start breadcrumb-wrapper">
                <b-breadcrumb
                :items="breadCrumb()"
                />
            </div>
        </div>

        <!-- Dashboard Headline -->
        <!-- Page Content
        ================================================== -->

        <!--<div class="single-page-header" :data-background-image="jobData.banner_image ? jobData.banner_image: webUrl+'assets/images/single-job.jpg'">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div class="single-page-header-inner">
					<div class="left-side">
						<div class="header-image"><a href="#"><img :src="profile_image != null ? profile_image : webUrl+'assets/images/company-logo-placeholders.png'" alt="company_logo"></a></div>
						<div class="header-details">
							<h3>{{title | capitalize}}</h3>
							<h5>About the Employer</h5>
							<ul>
                                <h4 class="new_h4" v-if="jobData.short_description">{{ jobData.short_description | capitalize }}</h4>

								<li><a href="#"><i class="icon-material-outline-business"></i> {{company_name | capitalize}}</a></li>
								<li v-if="average_rating"><div class="star-rating" :data-rating="average_rating"></div></li>
								<li><img class="flag" src="images/flags/gb.svg" alt="user_flag">{{user_address | capitalize}}</li>
								<li v-if='email_verified == "yes"'><div class="verified-badge-with-title">Verified</div></li>
							</ul>
						</div>
					</div>
					<div class="right-side">
						<div class="salary-box">

							<div class="salary-type" v-if="job_type == 'part-time'">Per Hour Rate</div>
							<div class="salary-type" v-else>Salary</div>

						<div class="salary-amount">${{min_salary}}-${{max_salary}}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div> -->


<div class="bg_img_top new_bg_img_tp new_job_detail_full_dvz" v-bind:style="{backgroundImage: 'url('+( jobData.banner_image ? jobData.banner_image: webUrl+'assets/images/single-job.jpg' )+ ')'} " >
        <!-- <div class="grd_div inside_grd height_three_zero_one"> -->
        <div class="grd_div inside_grd ">
        <div class="container my_new_container">
            <div class="row">
			<div class="col-md-12">
				<div class="inrs_main">
					<div class="left-side updated_left_side_banner">
						<div class="header-image updated_header_image_banner updated_image_in_square_shape"><a href="#"><img :src="jobData.company_logo != null ? jobData.company_logo : webUrl+'assets/images/company-logo-placeholders.png'" alt="company_logo"></a></div>
						<div class="header-details updated_header_details_banner">
							<h1 class="header_detail_new_h1 new_daily_paid_jjobs">{{title | capitalize}}</h1>
							<h5>About the Employer</h5>
							<ul class="margin_top_12 mobile_header_ul">
                                <h4 class="new_h4" v-if="jobData.short_description">{{ jobData.short_description | capitalize }}</h4>

								<li class="margin_right_12"><a href="#"><i class="icon-material-outline-business common_i_color"></i> <span class="company_name_dt highlight">{{company_name | capitalize}}</span></a></li>
								<!-- <li v-if="company_description">
                                    <p v-if="company_description.length >= 200" v-html="company_description.substring(0,200)+'...' "></p>
                                    <p v-else v-html="company_description"></p>
                                </li> -->
								<!-- <li v-if="average_rating"><div class="star-rating" :data-rating="average_rating"></div></li> -->
								<!-- <li><img class="flag" src="images/flags/gb.svg" alt="user_flag">{{user_address | capitalize}}</li> -->
								<li v-if='email_verified == "yes"'><div class="verified-badge-with-title">Verified</div></li>
							</ul>
						</div>
					</div>
					<div class="right-side daily_right_side" v-if="(min_salary != 0 && max_salary != 0) || min_salary > 0 || max_salary > 0">
                        <div class="right_inner_contents">
						<div class="salary-box">
							<div class="salary-type" v-if="job_type == 'part-time'">Per Hour Rate</div>
							<div class="salary-type" v-else>Salary</div>
						<div class="salary-amount">${{min_salary}}-${{max_salary}} Monthly</div>
						</div>
                        </div>
					</div>
				</div>
			</div>
		</div>
        </div>


    </div>
    </div>

<!-- <div class="container"> -->
	<div class="row">

		<!-- Content -->
		<div class="col-xl-8 single_page_sec_updated  col-lg-8 single_left_content tab_max_width_and_flex sidebar_left_i_margin_tp">

			<div class="single-page-section " v-if="description">
				<h3 class="margin-bottom-25 font_weight_600">Job Description</h3>
				<p v-html="description" class="desktop_margin_bottom_zero"></p>
			</div>

			<!-- <div class="single-page-section">
				<h3 class="margin-bottom-30">Location</h3>

                {{address == 'Other' ? other_location : address | capitalize}}
				<div id="single-job-map-container">
					<div id="singleListingMap" data-latitude="51.507717" data-longitude="-0.131095" data-map-icon="im im-icon-Hamburger"></div>
					<a href="#" id="streetView">Street View</a>
				</div>
			</div> -->

			<!-- <div class="single-page-section">
				<h3 class="margin-bottom-25">Similar Jobs</h3>

				<div class="listings-container grid-layout">
                    <div  v-for="(s,i) in simJobs" :key="i">
                        <a href="#" class="job-listing">

                            <div class="job-listing-details">
                                <div class="job-listing-company-logo">
                                    <img src="" alt="company_logo">
                                </div>

                                <div class="job-listing-description">
                                    <h4 class="job-listing-company">test company</h4>
                                    <h3 class="job-listing-title">Barista and Cashier</h3>
                                </div>
                            </div>

                            <div class="job-listing-footer">
                                <ul>
                                    <li><i class="icon-material-outline-location-on"></i> San Francisco</li>
                                    <li><i class="icon-material-outline-business-center"></i> Full Time</li>
                                    <li><i class="common_i_color material-icons-outlined custom dollar_custom_sign">monetization_on_outline</i> $35000-$38000</li>
                                    <li><i class="icon-material-outline-access-time"></i> 2 days ago</li>
                                </ul>
                            </div>
                        </a>
                        <a href="#" class="job-listing">

                            <div class="job-listing-details">
                                <div class="job-listing-company-logo">
                                    <img src="" alt="company_logo">
                                </div>

                                <div class="job-listing-description">
                                    <h4 class="job-listing-company">test company</h4>
                                    <h3 class="job-listing-title">Barista and Cashier</h3>
                                </div>
                            </div>

                            <div class="job-listing-footer">
                                <ul>
                                    <li><i class="icon-material-outline-location-on"></i> San Francisco</li>
                                    <li><i class="icon-material-outline-business-center"></i> Full Time</li>
                                    <li><i class="common_i_color material-icons-outlined custom dollar_custom_sign">monetization_on_outline</i> $35000-$38000</li>
                                    <li><i class="icon-material-outline-access-time"></i> 2 days ago</li>
                                </ul>
                            </div>
                        </a>
                    </div>
                </div>

            </div> -->
		</div>


		<!-- Sidebar -->
		<div class="col-xl-4 col-lg-4 single_right_sidebar tab_max_width_and_flex new_sidebar_i_margin_tp">
			<div class="sidebar-container desktop_margin_bottom_zero">
				<!-- Sidebar Widget -->
				<div class="sidebar-widget margin-bottom-25 desktop_margin_bottom_zero">
					<div class="job-overview common_box_border">
						<div class="job-overview-headline common_right_box_heading_padding font_weight_600">Job Summary</div>
						<div class="job-overview-inner">
							<ul>
								<li>
									<i class="icon-material-outline-location-on"></i>
									<span>Location</span>
									<h5>{{address == 'Other' ? other_location : address | capitalize}}</h5>
								</li>
								<li>
									<i class="icon-material-outline-business-center"></i>
									<span>Job Role Type</span>
									<h5>{{job_type | customizeRoleType | capitalize}}</h5>
								</li>
                                <li>
                                <i class="icon-material-outline-business"></i>
									<span>Job Category</span>
									<h5>{{jobData.service_categories_name == 'Other' ? jobData.other_service_category : jobData.service_categories_name | removeUnderscore}}</h5>
								</li>
                                <li>
									<i class="icon-material-outline-school"></i>
									<span>Minimum Qualification Level</span>
									<h5>{{jobData.qualification_title | capitalize}}</h5>
								</li>
                                <li>
									<i class="icon-material-outline-watch"></i>
									<span>Minimum Years of Experience</span>
									<h5>{{jobData.experience}}</h5>
								</li>
                                <li>
									<i class="icon-material-outline-face"></i>
									<span>Position Level</span>
									<h5>{{jobData.level_title | capitalize}}</h5>
								</li>
								<li v-if="(min_salary != 0 && max_salary != 0) || min_salary > 0 || max_salary > 0">
									<i class="icon-material-outline-local-atm"></i>
									<span v-if="job_type == 'part-time'">Per Hour Rate</span>
									<span v-else>Salary</span>
									<h5>${{min_salary}}-${{max_salary}} Monthly</h5>
								</li>
                                <!-- <li>
									<i class="icon-material-outline-face"></i>
									<span>Featured</span>
									<h5>{{jobData.featured | capitalize}}</h5>
								</li> -->
								<li>
									<i class="icon-material-outline-access-time"></i>
									<span>Date Posted</span>
									<h5>{{posted_date}}</h5>
								</li>
							</ul>
						</div>
					</div>
				</div>

				<!-- Sidebar Widget -->
				<div class="sidebar-widget desktop_margin_bottom_zero common_side_bar_sections_margin">
					<h3 class="font_weight_600">Share</h3>
					<div class="copy-url">
						<input id="copy-url" type="text" value="" class="with-border" readonly>
						<button class="copy-url-button ripple-effect" @click="copyURL()"  data-clipboard-target="#copy-url" title="Copy to Clipboard" data-tippy-placement="top"><i class="icon-material-outline-file-copy"></i></button>
					</div>

					<div class="share-buttons margin-top-15">
						<div class="share-buttons-trigger"><i class="icon-feather-share-2"></i></div>
						<div class="share-buttons-content">
							<span>Interesting? <strong>Share It!</strong></span>
							<ul class="share-buttons-icons">
                               <li style="">
                                <ShareNetwork
                                    network="facebook"
                                    quote="AwesomeArticle"
                                    :url="this.jobUrl"
                                    hashtags="Jobs, SharedJobs">
                                    <!-- <span  style="color:#3b5998"><v-icon class="mr-3" style="color: #3b5998">fab fa-facebook</v-icon></span> -->
                                </ShareNetwork>
                               </li>

                                <li>

                                <ShareNetwork
                                    network="twitter"
                                    :url="this.jobUrl"
                                    title="Please find the shared job by using the given url"
                                    hashtags="Jobs, SharedJobs">
                                    <!-- <span  style="color:#3b5998">Share on Twitter</span> -->
                                </ShareNetwork>
                               </li>

                                <li>

                               <ShareNetwork
                                    network="linkedin"
                                    :url="this.jobUrl"
                                    title="Please find the shared job by using the given url"
                                    description="test"

                                    hashtags="Jobs, SharedJobs">
                                    <!-- <span  style="color:#3b5998">Share on Twitter</span> -->
                                </ShareNetwork>
                               </li>


								<!-- <li><a href="#" data-button-color="#3b5998" title="Share on Facebook" data-tippy-placement="top"><i class="icon-brand-facebook-f"></i></a></li>
								<li><a href="#" data-button-color="#1da1f2" title="Share on Twitter" data-tippy-placement="top"><i class="icon-brand-twitter"></i></a></li>
								<li><a href="#" data-button-color="#dd4b39" title="Share on Google Plus" data-tippy-placement="top"><i class="icon-brand-google-plus-g"></i></a></li>
								<li><a href="#" data-button-color="#0077b5" title="Share on LinkedIn" data-tippy-placement="top"><i class="icon-brand-linkedin-in"></i></a></li> -->
							</ul>
						</div>
					</div>
                    <div class="task-tags margin-top-25" v-if="tags">
                        <span v-for="tags in tagArr">{{tags | capitalize}}</span>
                    </div>
				</div>


			</div>
		</div>

	</div>
<!-- </div> -->

    </div>

</template>

<script>
    import { POST_API } from "../../store/actions.type"
    import VueSocialSharing from 'vue-social-sharing'

    import moment from 'moment'

    export default {

         data() {
            return {
                bookmarkVal:'',
                bookmarkjobs:[],
				simJobs: '',
                id: atob(this.$route.params.id),
                title:'',
                daily:'',
                posted_date:'',
                profile_image:'',
                email_verified:'',
                designation:'',
                address:'',
                other_location: '',

                description:'',
                category_id:'',
                other_category: '',
                company_name:'',

                items: null,
                job_type:'',
                keyword:'',
                userId:'',
                jobId:'',
                average_rating:'',
                alreadyApplied : false,
                min_salary:'',
                max_salary:'',
                error_message:'',
                skillsArr: [],
                tagArr: [],
                jobData:[],
                pager: {},
                pageOfItems: [],
                items:null,
                totalRows: 0,
                from: '',
                to: '',
                currentPage: 1,
                perPage: 25,
                pageOptions: [25, 50, 75, 100],
                form:{
                    rowsPerPage:25,
                },
                webUrl: process.env.VUE_APP_URL,
                user_address:''
            }
        },

        components: {
                    VueSocialSharing
            },


        filters: {
            capitalize: function(value) {
                if (!value) return ''
                value = value.toString()
                return value.charAt(0).toUpperCase() + value.slice(1)
            },

            dateTimeAgo: function(value) {
                if (!value) return ''
                const dateTimeAgo = moment(value).fromNow();
                return dateTimeAgo
            },

            removeUnderscore: function(value) {
                if (!value) return ''
                var i, frags = value.split('_');
                for (i=0; i<frags.length; i++) {
                    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
                }
                return frags.join(' ');
            },
            customizeRoleType: function(value) {
                if (!value) return ''
                var i, frags = value.split('-');
                for (i=0; i<frags.length; i++) {
                    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
                }
                return frags.join(' ');
            },
        },

        methods:{
            breadCrumb(){
                var item = [{
                    to:{name:'manage-jobs'},
                    text: 'Manage Job Ads',
                },{
                    to:null,
                    text: 'Job Detail'
                }];
                return item;
            },
            async copyURL() {
                    try {
                        let copyText = document.querySelector("#copy-url");
                        copyText.select();
                        document.execCommand("copy");

                        Snackbar.show({
                            text: 'Copied to clipboard!',
                        });
                        // alert('Copied to Clipboard.');
                    } catch($e) {

                        console.log($e);

                        // alert('Cannot copy');
                    }
              },
            showAlert() {
                    this.$swal({
                        position: 'center',
                        // icon: 'error',
                        imageUrl: '/assets/images/404_elcy.gif',
                        customClass:{
                            container: 'mascot_error_container',
                        },
                        title: this.error_message,
                        showConfirmButton: false,
                        timer: 1500
                    })
            },
            showSuccessAlert(message) {
                this.$swal({
                    position: 'center',
                    icon: 'success',
                    iconColor:'#4c8bf5',
                    title: message,
                    showConfirmButton: false,
                    timer: 1500
                });
            },


           getjobsDataById() {
                return this.$store.dispatch(POST_API, {
                     data:{
                            id: this.id,
                },
                    api: '/api/jobDataByIdEmp'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        this.jobData = this.$store.getters.getResults.data[0];
                        this.title = this.$store.getters.getResults.data[0].job_title;
                        this.category_id = this.$store.getters.getResults.data[0].service_categories_id;
                        this.other_category = this.$store.getters.getResults.data[0].other_service_category;

                        this.email_verified = this.$store.getters.getResults.data[0].email_verified;
                        this.description = this.$store.getters.getResults.data[0].job_description;
                        this.min_salary = this.$store.getters.getResults.data[0].min_salary;
                        this.max_salary = this.$store.getters.getResults.data[0].max_salary;
                        this.profile_image = this.$store.getters.getResults.data[0].profile_image;
                        this.address = this.$store.getters.getResults.data[0].job_address;
                        this.other_location = this.$store.getters.getResults.data[0].other_location;
                        this.company_name = this.$store.getters.getResults.data[0].company_name;
                        this.job_company_id = this.$store.getters.getResults.data[0].company_id;


                        this.job_type = this.$store.getters.getResults.data[0].job_type;
                        this.posted_by = this.$store.getters.getResults.data[0].posted_by;

                        this.designation = this.$store.getters.getResults.data[0].designation;
                        this.jobId = this.$store.getters.getResults.data[0].jobId;
                        this.average_rating = this.$store.getters.getResults.data[0].average_rating;
                        this.bookmarkjobs = this.$store.getters.getResults.data[0].bookmark == 'yes' ? this.$store.getters.getResults.data[0].jobId : '';
                        this.posted_date =  moment(String(this.$store.getters.getResults.data[0].posted_date)).format('DD MMM YYYY');
                        this.tagArr = this.jobData.tag_names ? this.jobData.tag_names.split(',') : [];

                        if(this.job_company_id != this.company_id){
                            window.location.replace('/v2/error-404');
                        }
                        // this.similarJobs(this.category_id);

                    }
                });
        },

        //   similarJobs(category_id) {
        //         return this.$store.dispatch(POST_API, {
        //             data:{
        //                 category_id: category_id,
        //                 job_id: this.id,
        //             },
        //             api: '/api/similarJobs'
        //         })
        //         .then(() => {
        //             if (this.$store.getters.containsErrors) {
        //                 this.error_message = this.$store.getters.getErrors;
        //                 this.showAlert();
        //                 return [];
        //             } else {
        //                 this.simJobs = this.$store.getters.getResults.data;

        //             }
        //         });
        //     },

             apply() {
                this.$store.dispatch(POST_API, {
                    data:{
                        jobId: this.jobId,
                        userId: this.user_id,
                    },
                    api:'/api/applyForJob'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        this.success_message = this.$store.getters.getResults.message;
                        this.alreadyApplied = true;
                    this.$swal({
                        position: 'center',
                        icon: 'success',
                        title: this.success_message,
                        showConfirmButton: false,
                        timer: 1500
                    }).then((result) => {
                        // window.location.reload();

                    });
                    }
                });
            },

            isApplied() {
                return this.$store.dispatch(POST_API, {
                        data:{
                            jobId: this.id,
                            userId:this.user_id
                },
                    api: '/api/isApplied'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        if(this.$store.getters.getResults.data.length != 0){
                            this.alreadyApplied = true;
                        }
                    }
                });
            },

        },
         mounted(){
            $(".share-buttons-icons").addClass('hide_social_share');
            $(".share-buttons-content").click(function(){
                $(".share-buttons-content").toggleClass('show_hide_share_text');
                // $(".share-buttons-icons").addClass('show_social_share');
                $(".share-buttons-icons").toggleClass('show_social_share');
            });
            $(".share-buttons-trigger").click(function(){
                $(".share-buttons-content").toggleClass('show_hide_share_text');
                // $(".share-buttons-icons").addClass('show_social_share');
                $(".share-buttons-icons").toggleClass('show_social_share');
            });
            $(".dashboard-content-container .simplebar-scroll-content").animate({
            scrollTop: 0
         }, "fast");
            //document.title = "EL Connect - Employer - Job Detail";
            this.user_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
            this.company_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.company_id : '');

            this.getjobsDataById();
            // $('#copy-url').val(window.location.href);
             $('#copy-url').val(process.env.VUE_APP_URL + "job?jobId=" + btoa(this.id));

            this.jobUrl =  process.env.VUE_APP_URL + "job?jobId=" + btoa(this.id);

            //this.jobUrl = 'www.google.com'


            // this.similarJobs(this.category_id);

        }
    }
</script>


