<template>
  <div>
    <div>
      <div class="dashboard-content-inner my_custom_dash desktop_padding_top_zero">
        <!-- Dashboard Headline -->
        <div class="dashboard-headline">
          <h1>Manage Job Ads</h1>
          <!-- Breadcrumbs -->
          <nav id="breadcrumbs" class="dark employer_nav">
            <ul>
              <li><a href="#">Home</a></li>
              <li><a href="#">Job</a></li>
              <li>Manage Job Ads</li>
            </ul>
          </nav>
        </div>

        <!-- Row -->

        <!-- <div class="row emplpyees_manage_job_emp_row" > -->
        <div class="row">
          <!-- Dashboard Box -->
          <!-- <div class="col-xl-12 mobile_left_right_zero_pdng"> -->
          <div class="col-xl-12">
            <div class="dashboard-box margin-top-0">
              <!-- Headline -->
              <div class="headline manage_post_new">
                <h3>
                  <i class="icon-material-outline-business-center"></i> My Job Ads<span
                    ><b-link
                      class="btn green-border-btn desktop_margin_top_zero desktop_margin_right_zero all_float_right"
                      :to="{ name: 'postJob' }"
                      >Post New Ads +
                    </b-link>
                  </span>
                </h3>
                <!-- <h3><i class="icon-material-outline-business-center"></i> My Job Ads<span><b-link @click="exportJobs(activeTab)" class="btn green-border-btn desktop_margin_top_zero desktop_margin_right_zero all_float_right">
                                    Export
                                </b-link><b-link class="btn green-border-btn desktop_margin_top_zero desktop_margin_right_zero all_float_right" :to="{name:'postJob'}" style="margin-right: 15px!important;">Post New Ads + </b-link>
                                
                                </span></h3> -->
              </div>
              <div class="container new_cnt_pdng my_new_container">
                <div class="row desktop_search_top_div">
                  <!-- <div class="col-xl-12"> -->
                  <!-- <div class="row"> -->
                  <!-- <div class="upper_mix_functiobality"> -->
                  <!-- <div class="col-xl-4 col-md-4 mobile_max_width_and_flex mobile_paddding_unset search_div_daily_job new_search_manage_job"> -->
                  <div
                    class="big_screen_search_filter col-xl-3 col-md-3 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28"
                  >
                    <div class="search-box custom_search_admin new_custom_search_admin_remove">
                      <!-- <div role="group" class="input-group"> -->
                      <!---->
                      <div class="input-group-prepend">
                        <input
                          id="searchBtn"
                          type="text"
                          placeholder="Search by keyword"
                          v-model="filterSearch"
                          v-on:keyup.enter="changeTabStatus(activeTab)"
                          class="form-control"
                        />
                        <b-button class="btn points_events"
                          ><i class="fa fa-search" @click="changeTabStatus(activeTab)"></i
                        ></b-button>
                      </div>
                      <!-- </div> -->
                    </div>
                  </div>
                  <!-- <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28 admin_cat">
                                     <label class="mobile_tab_label new_style_label desktop_margin_bottom_zero">Services:</label>
                                    <b-form-select v-model="category_id_filter" id="jbs_category" class="jbs_category desktop_margin_bottom_zero  common_height_38" @change="changeTabStatus(activeTab)">
                                        <option value="">All</option>
                                        <option :value="category.id" v-for="category in categories">{{category.name |capitalize}}</option>
                                    </b-form-select>										
                                </div> -->
                  <div
                    class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28"
                  >
                    <label class="mobile_tab_label new_style_label desktop_margin_bottom_zero"
                      >Services:</label
                    >
                    <b-form-group id="jbs_category" class="jbs_category desktop_margin_bottom_zero">
                      <multiselect
                        id="ajax"
                        @input="changeTabStatus(activeTab)"
                        v-model="category_id_filter"
                        tag-placeholder="Search Category"
                        placeholder="Search Category"
                        :options="categories"
                        label="name"
                        track-by="id"
                        :multiple="true"
                        :searchable="true"
                        :clear-on-select="true"
                        :close-on-select="true"
                        :allow-empty="true"
                        :show-labels="false"
                        class="job_list_multi_select model_multi_select admin_multi_select new_multi_admin"
                        ><span slot="noResult">No Category Found.</span></multiselect
                      >
                    </b-form-group>
                  </div>
                  <!-- <div class="col-md-3 dropdown_daily_job">
                                    <div class="label_div text_align_end_desktop">
                                        <span>Company:</span>
                                    </div>
                                    <div class="input_div select_dropdown">
                                    <select name="cars" id="cars">
                                        <option value="All">All</option>
                                        <option value="saab">Saab</option>
                                        <option value="mercedes">Mercedes</option>
                                        <option value="audi">Audi</option>
                                        </select>
                                    </div>
                                </div> -->

                  <div
                    class="calneder_open_is col-xl-5 col-md-5 common_height_38 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28"
                  >
                    <div class="reset_btn_full_flex mobile_tab_display_block">
                      <div
                        class="calender_div_job_in_job calneder_custom_icon width_100_per calender_border"
                      >
                        <label class="mobile_tab_label new_style_label desktop_margin_bottom_zero"
                          >Date Range:</label
                        >
                        <!-- <div class="input_div"> -->
                        <b-form-group id="input-group-6" class="desktop_margin_bottom_zero">
                          <date-range-picker
                            ref="picker"
                            :minDate="minDate"
                            :opens="center"
                            :locale-data="locale"
                            :auto-apply="auto"
                            v-model="dateRange"
                            :ranges="range"
                            @update="changeTabStatus(activeTab)"
                            class="manage_job_date_claneder common_date_range_picker_new"
                          >
                            <div slot="input" slot-scope="picker">
                              {{ picker.startDate | datePicker1 }} -
                              {{ picker.endDate | datePicker1 }}
                            </div>
                          </date-range-picker>
                          <span class="input-icon web_common_input_icon">
                            <b-img
                              v-bind:src="webUrl + 'assets/images/calendar-grey-icon02.svg'"
                              alt="Calander Icon"
                            ></b-img>
                          </span>
                        </b-form-group>
                        <!-- </div> -->
                        <!-- <div class="input_div">
                                                <input type="date" id="birthday" name="birthday">
                                            </div> -->
                      </div>
                      <div class="reset_btn_top reset_btn_mrgn common_reset_btn">
                        <button @click="clear">RESET</button>
                      </div>
                    </div>
                  </div>
                  <!-- </div> -->
                  <!-- </div> -->
                  <!-- </div> -->
                </div>
              </div>

              <!-- <div v-if="loading" >
                        <div class = 'loader'>
                        </div> -->

              <!-- here put a spinner or whatever you want to indicate that a request is in progress -->
              <!-- </div> -->
              <div class="tab-section">
                <b-tabs
                  class="tab_nw_section my_tab_job remove_bx common_border_top_right_left_radius_unset daily_job_mange_div desktop_margin_top_zero admin_full_time_job"
                >
                  <b-tab
                    :title="'IN PROGRESS (' + inprogressCount + ')'"
                    :active="inprogress"
                    @click="changeTabStatus('inprogress')"
                    class="reject_last_li"
                  >
                    <div class="content" v-if="jobData.length > 0">
                      <ul class="dashboard-box-list desktop_margin_bottom_zero">
                        <li v-for="jobDatas in jobData" class="admin_featured_full">
                          <!-- Job Listing -->
                          <div class="listing_features_new">
                            <h5 v-if="jobDatas.featured == 'yes'"><b>Featured Ad</b></h5>
                          </div>
                          <div class="job-listing">
                            <!-- Job Listing Details -->
                            <div class="job-listing-details pb-0">
                              <!-- Logo -->
                              <!-- 											<a href="#" class="job-listing-company-logo">
                                                            <img src="images/company-logo-05.png" alt="company_logo">
                                                        </a> -->

                              <!-- Details -->
                              <div class="job-listing-description">
                                <!-- <span class="job-listing-title new_job_listing_article" v-if="jobDatas.title.length >= 25"><b-link :to="{name: 'employerJobDetail', params: {id: encrypt(jobDatas.jobId)}}">{{jobDatas.title.substring(0,25)+'...' | capitalize}}</b-link></span>
                                                            <span class="job-listing-title new_job_listing_article" v-else><b-link :to="{name: 'employerJobDetail', params: {id: encrypt(jobDatas.jobId)}}">{{jobDatas.title | capitalize}}</b-link></span> -->
                                <div class="heading_with_dots">
                                  <span class="job-listing-title"
                                    ><b>{{ jobDatas.title | capitalize }}</b></span
                                  >

                                  <!-- <span class="job-listing-title" ><b-link :to="{name: 'employerJobDetail', params: {id: encrypt(jobDatas.jobId)}}">{{jobDatas.title | capitalize}}</b-link></span>  -->

                                  <!-- <h3
                                    class="job-listing-title new_all_job_title mobile_margin_top_15"
                                    v-if="jobDatas.description"
                                    style="margin-bottom: 15px; margin-left: 20px"
                                  > -->
                                    <!-- <p v-if="jobDatas.description.length >= 250" v-html="jobDatas.description.substring(0,250)+'...' "></p>
                                                                  <p v-else v-html="jobDatas.description"></p> -->
                                  <!-- </h3> -->
                                  <div class="toggle-action toggle_margin_unset top_100 custom_menu_section_toggle_manage_job">
                                    <!-- <b-dropdown class="mx-1 common_toggle_menu_hide_from_top" right text="Action" boundary="window"> -->
                                    <!-- <b-dropdown class="mx-1" right text="Action" boundary="window"> -->
                                    <b-dropdown class="mx-1" left text="Action" boundary="window">
                                      <b-dropdown-item
                                          :href="'/v2/employer/ft-jobdetail/' + encrypt(jobDatas.jobId)"
                                          target="_blank"
                                          class=""
                                          ><i aria-hidden="true" class="fa fa-eye"></i>View Job Detail
                                        
                                      </b-dropdown-item>
                                    
                                      <b-dropdown-item
                                          
                                        :href="'/v2/employer/candidate-search/' + encrypt(jobDatas.jobId)"
                                          target="_blank"
                                          class=""
                                          ><i  class="material-icons">auto_awesome</i>AI Talent Match <span class="pink_ai common_pink_text_color">NEW</span>
                                        
                                      </b-dropdown-item>
                                      <b-dropdown-item v-if="showPermissionEMManageFL('view')"
                                          
                                            href="javascript:void(0);"
                                            @click="manageCandidate(encrypt(jobDatas.jobId))"
                                            class=""
                                            ><i class="icon-material-outline-supervisor-account"></i> Manage
                                            Candidates
                                            <span class="button-info">{{ jobDatas.total_applicants }}</span>
                                          
                                      </b-dropdown-item>
                                      <b-dropdown-item
                                            href="javascript:void(0);"
                                            :to="{ name: 'editJob', params: { id: encrypt(jobDatas.jobId) } }"
                                            class=""
                                            data-tippy-placement="top"
                                            ><i class="material-icons-outlined common_overflow_unset"
                                              >border_color_outlined</i
                                            >Edit Job
                                          
                                      </b-dropdown-item>
                                      <b-dropdown-item
                                            href="javascript:void(0);"
                                            :to="{ name: 'repostJob', params: { id: encrypt(jobDatas.jobId) } }"
                                            class=""
                                            data-tippy-placement="top"
                                            ><i class="material-icons-outlined common_overflow_unset"
                                              >repeat_outlined</i
                                            >Duplicate
                                          
                                      </b-dropdown-item>
                                      <!-- <b-dropdown-item> -->
                                        <li>
                                          <a
                                            @click="
                                              changeStatus(
                                                jobDatas.jobId,
                                                jobDatas.job_status_val,
                                                jobDatas.title,
                                                jobDatas.end_date
                                              )
                                            "
                                            id="show_hide_status"
                                            v-if="jobDatas.job_status_val == 'active'"
                                            class="danger_red dropdown-item common_cusror_pointer_hand ico non-active-btn-job-list"
                                          >
                                            <i class="fa fa-eye-slash" aria-hidden="true"></i>Inactivate
                                          </a>
                                          <a
                                            @click="
                                              changeStatus(
                                                jobDatas.jobId,
                                                jobDatas.job_status_val,
                                                jobDatas.title,
                                                jobDatas.end_date
                                              )
                                            "
                                            id="show_hide_status"
                                            v-else-if="jobDatas.job_status_val == 'inactive'"
                                            class="danger_red dropdown-item common_cusror_pointer_hand ico active-btn-job-list"
                                          >
                                            <i class="fa fa-eye" aria-hidden="true"></i>Activate
                                          </a>
                                        </li>
                                      <!-- </b-dropdown-item> -->
                                      <b-dropdown-item 
                                              @click="featureJob(jobDatas.jobId, jobDatas.company_id)"
                                              id="show_hide_status"
                                              v-if="jobDatas.featured == 'no' && user_type_id != 3"
                                              class="ico feature-btn-job-list"
                                            >
                                              <i class="gray ripple-effect ico" aria-hidden="true"></i>Featured Ad
                                        
                                      </b-dropdown-item>
                                    </b-dropdown>
                                  </div>
                                </div>
                                <!-- Job Listing Footer -->
                                <div class="job-listing-footer manage_job_li_nw display_inline">
                                  <ul class="fullTimeJobdata new_full_time_data">
                                    <li>
                                      <span v-if="jobDatas.start_date != null"
                                        ><i class="icon-material-outline-access-time"></i>
                                        {{ jobDatas.start_date | customFormatterOne }} </span
                                      ><span v-if="jobDatas.end_date != null">
                                        - {{ jobDatas.end_date | customFormatterOne }}</span
                                      >
                                      <!-- <br v-if="jobDatas.end_date != null"> -->
                                    </li>
                                  </ul>

                                  <ul class="fullTimeJobdata new_full_time_data">
                                    <li>
                                      <span v-if="jobDatas.service_categories_name"
                                        ><i class="icon-material-outline-business"></i>
                                        <span>{{
                                          jobDatas.service_categories_name == "Other"
                                            ? jobDatas.other_service_category
                                            : jobDatas.service_categories_name | removeUnderscore
                                        }}</span></span
                                      >

                                      <!-- <span v-if="jobDatas.start_date != null"> <i class="icon-material-outline-date-range"></i>Start Date {{jobDatas.start_date | customFormatterOne}}</span><br v-if="jobDatas.start_date != null">
                                                                    <span v-if="jobDatas.end_date != null"> <i class="icon-material-outline-date-range"></i>End Date {{jobDatas.end_date | customFormatterOne}}</span><br v-if="jobDatas.end_date != null">
                                                                    <span> <i class="icon-material-outline-date-range"></i>Posted on {{jobDatas.posted_date | customFormatterOne}}</span> -->
                                    </li>
                                    <li class="mobile_jb_hide">
                                      <span v-if="jobDatas.job_type"
                                        ><i class="icon-material-outline-business-center"></i>
                                        <span>{{
                                          jobDatas.job_type | customizeRoleType | capitalize
                                        }}</span></span
                                      >
                                    </li>
                                    <li class="mobile_jb_hide">
                                      <span v-if="jobDatas.level_title"
                                        ><i class="icon-material-outline-face"></i>
                                        {{ jobDatas.level_title | capitalize }}</span
                                      >
                                    </li>
                                  </ul>
                                  <ul class="fullTimeJobdata new_full_time_data">
                                    <li v-if="jobDatas.actual_posted_by_first_name">
                                      <span>
                                        <i class="icon-material-outline-date-range"></i>Posted By:
                                        {{ jobDatas.actual_posted_by_last_name | capitalize }}
                                        {{
                                          jobDatas.actual_posted_by_first_name | capitalize
                                        }}</span
                                      >
                                    </li>

                                    <li v-if="jobDatas.posted_date">
                                      <span>
                                        <i class="icon-material-outline-date-range"></i>Posted On:
                                        {{ jobDatas.posted_date | customFormatterposted }}</span
                                      >
                                    </li>

                                    <li v-if="jobDatas.job_address">
                                      <span
                                        ><i class="icon-material-outline-location-on"></i>
                                        <span>{{
                                          jobDatas.job_address == "Other"
                                            ? jobDatas.other_location
                                            : jobDatas.job_address | capitalize
                                        }}</span></span
                                      >
                                    </li>
                                    <li v-if="jobDatas.job_unique_id">
                                      <span
                                        ><i class="material-icons-outlined job_id_icon"
                                          >card_travel_outlined</i
                                        >Ads ID:
                                        <span>{{ jobDatas.job_unique_id | capitalize }}</span></span
                                      >
                                    </li>
                                    <li v-if="
                                          (jobDatas.min_salary != 0 && jobDatas.max_salary != 0) ||
                                          jobDatas.min_salary > 0 ||
                                          jobDatas.max_salary > 0
                                        ">
                                      <span
                                        v-if="
                                          (jobDatas.min_salary != 0 && jobDatas.max_salary != 0) ||
                                          jobDatas.min_salary > 0 ||
                                          jobDatas.max_salary > 0
                                        "
                                      >
                                        <i class="icon-material-outline-local-atm"></i
                                        ><span v-if="jobDatas.job_type == 'part-time'"
                                          >Per Hour Rate</span
                                        >
                                        <span v-else></span> ${{ jobDatas.min_salary }}-${{
                                          jobDatas.max_salary
                                        }}</span
                                      >
                                    </li>
                                  </ul>
                                  <!-- <div
                                    class="all_job_list_home featured_job_section_lists fulltimefeature"
                                  > -->
                                    <!-- <span v-if="jobDatas.featured == 'yes'"><b>Featured Ad</b></span> -->
                                    <!-- <h5 v-if="jobDatas.featured == 'yes'"><b>Featured Ad</b></h5> -->
                                    <!--<h5 v-else class="red_bg"><b>No Featured Ad</b></h5>-->
                                  <!-- </div> -->
                                  <!-- <ul>
                                                                    <li><i class="icon-material-outline-date-range"></i> Posted on {{jobDatas.posted_date | customFormatterOne}}</li>
                                                                </ul> -->
                                </div>
                              </div>
                            </div>
                          </div>

                          <!-- Buttons -->
                          <div
                            class="total_applied_emp_lg"
                          >
                            <!-- <div class="manage_candidate_in_admin job_view_count_inline_div"> -->
                              <!-- <a href="javascript:void(0);" @click="jobDetail(encrypt(jobDatas.jobId),$event)" class="button ripple-effect"><i aria-hidden="true" class="fa fa-eye"></i>&nbsp;&nbsp;View Detail </a> -->
                              <!-- <a
                                :href="'/v2/employer/ft-jobdetail/' + encrypt(jobDatas.jobId)"
                                target="_blank"
                                class="button ripple-effect"
                                ><i aria-hidden="true" class="fa fa-eye"></i>&nbsp;&nbsp;View Detail
                              </a> -->
                            <!-- </div> -->
                            <!-- <div class="manage_candidate_in_admin job_view_count_inline_div">
                              <a
                                :href="'/v2/employer/candidate-search/' + encrypt(jobDatas.jobId)"
                                target="_blank"
                                class="button ripple-effect"
                                ><i aria-hidden="true" class="fa fa-eye"></i>&nbsp;&nbsp;AI Talent Match
                              </a>
                            </div> -->
                            <!-- <div
                              class="manage_candidate_in_admin job_view_count_inline_div"
                              v-if="showPermissionEMManageFL('view')"
                            >
                              <a
                                href="javascript:void(0);"
                                @click="manageCandidate(encrypt(jobDatas.jobId))"
                                class="button ripple-effect"
                                ><i class="icon-material-outline-supervisor-account"></i> Manage
                                Candidates
                                <span class="button-info">{{ jobDatas.total_applicants }}</span></a
                              >
                            </div> -->
                            <!-- <div
                              class="other_operation_admin_full_time new_other_op job_view_count_inline_div"
                            > -->
                              <!-- <b-link
                                href="javascript:void(0);"
                                :to="{ name: 'editJob', params: { id: encrypt(jobDatas.jobId) } }"
                                class="button gray ripple-effect ico edit-btn-job-list"
                                data-tippy-placement="top"
                                ><i class="material-icons-outlined common_overflow_unset"
                                  >border_color_outlined</i
                                >Edit</b-link
                              > -->
                              <!-- <b-link
                                href="javascript:void(0);"
                                :to="{ name: 'repostJob', params: { id: encrypt(jobDatas.jobId) } }"
                                class="button gray ripple-effect ico repost-btn-job-list"
                                data-tippy-placement="top"
                                ><i class="material-icons-outlined common_overflow_unset"
                                  >repeat_outlined</i
                                >Duplicate</b-link
                              > -->
                              <!-- <a href="#" @click="deleteJob(jobDatas.jobId)" class="button gray ripple-effect ico" title="Remove" data-tippy-placement="top"><i class="icon-feather-trash-2"></i></a> -->
                              <!-- <a
                                @click="
                                  changeStatus(
                                    jobDatas.jobId,
                                    jobDatas.job_status_val,
                                    jobDatas.title,
                                    jobDatas.end_date
                                  )
                                "
                                id="show_hide_status"
                                v-if="jobDatas.job_status_val == 'active'"
                                class="button gray ripple-effect ico non-active-btn-job-list"
                              >
                                <i class="fa fa-eye-slash" aria-hidden="true"></i>Inactivate
                              </a>
                              <a
                                @click="
                                  changeStatus(
                                    jobDatas.jobId,
                                    jobDatas.job_status_val,
                                    jobDatas.title,
                                    jobDatas.end_date
                                  )
                                "
                                id="show_hide_status"
                                v-else-if="jobDatas.job_status_val == 'inactive'"
                                class="button gray ripple-effect ico active-btn-job-list"
                              >
                                <i class="fa fa-eye" aria-hidden="true"></i>Activate
                              </a>
                              <a
                                @click="featureJob(jobDatas.jobId, jobDatas.company_id)"
                                id="show_hide_status"
                                v-if="jobDatas.featured == 'no' && user_type_id != 3"
                                class="button gray ripple-effect ico feature-btn-job-list"
                              >
                                <i class="gray ripple-effect ico" aria-hidden="true"></i>Featured ad
                              </a> -->
                            <!-- </div> -->
                            <div
                              class="fa fa-eye view_count_job job_view_count_inline_div pb-2"
                              v-if="jobDatas.view_count"
                            >
                              <span>{{ jobDatas.view_count | commaSeperator }}</span>
                            </div>

                            <!-- <div class="fa fa-users"> -->
                                <span class="material-icons-outlined">grading_outlined</span><span class="applied_text_emp">Total Applied - {{ jobDatas.total_applicants }}</span>
                            <!-- </div> -->
                          
                            <!-- <b-link href=""  v-if="jobDatas.featured == 'no'" class="button gray ripple-effect ico" title="Feature job" data-tippy-placement="top" @click="$bvModal.show('my_manage_job_model')"><i class="material-icons-outlined">new_releases</i></b-link>
                                                        <div class="">
                                                            <b-modal id="my_manage_job_model" ref="my_manage_job_model" title="BootstrapVue" >
                                                                <h2>Feature Job</h2>
                                                                <p>Please select the featured job package</p>
                                                                <div class="custom_select_manage_jobz">
                                                                    <b-form-select v-model="ft_feature_job" id="package" class="package" @change="onChangeFeature()">
                                                                        <option value="" disabled>Select featured job package</option>
                                                                        <option value="ft_1_day_free_featured_jobs">1 days - Free Featured - ({{ft_1_day_free_featured_jobs}} Remaining)</option>
                                                                        <option value="ft_7_day_free_featured_jobs">7 days - Free Featured - ({{ft_7_day_free_featured_jobs}} Remaining)</option>
                                                                        <option value="ft_14_day_free_featured_jobs">14 days - Free Featured - ({{ft_14_day_free_featured_jobs}} Remaining)</option>
                                                                        <option value="ft_30_day_free_featured_jobs">30 days - Free Featured - ({{ft_30_day_free_featured_jobs}} Remaining)</option>
                                                                    </b-form-select>										
                                                                </div>
                                                                <h3 class="coin_msg" style="display:none">You will have <b-img :src="webUrl+'assets/images/Icons_Coin.png'" class="coins_manage_job_img"></b-img> {{remaining_coins}} EL Coins left after purchasing.</h3>
                                                                <p class="red_p">Note: Action cannot be undo once confirmed.</p>
                                                                <div class="model_footer_btns">
                                                                    <b-button  block @click="$bvModal.hide('my_manage_job_model')">Cancel</b-button>
                                                                    <button class="blue_btn" @click="submitFeatured(jobDatas.jobId)">Confirm Feature</button>
                                                                </div>
                                                            </b-modal>
                                                        </div> -->
                            <!-- <a @click="featureJob(jobDatas.jobId,jobDatas.company_id)" v-if="jobDatas.featured == 'no'" title="Edit" class="button gray ripple-effect ico">
                                                    </a> -->
                            <!-- <a href="#" @click ="changeStatus(jobDatas.jobId, jobDatas.job_status_val)" v-if="jobDatas.job_status_val == 'active'">Inactivate</a> -->
                            <!-- <a href="#" v-else-if="jobDatas.job_status_val == 'inactive'" @click ="changeStatus(jobDatas.jobId, jobDatas.job_status_val)">Activate</a> -->
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div class="content text-center" v-else>
                      <p class="margin_top_1rem"><b>No Record Found</b></p>
                    </div>
                  </b-tab>
                  <b-tab
                    :title="'UPCOMING (' + upcomingCount + ')'"
                    :active="upcoming"
                    @click="changeTabStatus('upcoming')"
                    class="reject_last_li"
                  >
                    <div class="content" v-if="jobData.length > 0">
                      <ul class="dashboard-box-list desktop_margin_bottom_zero">
                        <li v-for="jobDatas in jobData">
                          <!-- Job Listing -->
                          <div class="job-listing">
                            <!-- Job Listing Details -->
                            <div class="job-listing-details">
                              <!-- Logo -->
                              <!-- 											<a href="#" class="job-listing-company-logo">
                                                            <img src="images/company-logo-05.png" alt="company_logo">
                                                        </a> -->

                              <!-- Details -->
                              <div class="job-listing-description">
                                <!-- <span class="job-listing-title" v-if="jobDatas.title.length >= 25"><b-link :to="{name: 'employerJobDetail', params: {id: encrypt(jobDatas.jobId)}}">{{jobDatas.title.substring(0,25)+'...' | capitalize}}</b-link></span>
                                                            <span class="job-listing-title" v-else><b-link :to="{name: 'employerJobDetail', params: {id: encrypt(jobDatas.jobId)}}">{{jobDatas.title | capitalize}}</b-link></span> -->
                               <div class="heading_with_dots">
                                  <span class="job-listing-title"
                                    ><b>{{ jobDatas.title | capitalize }}</b></span
                                  >

                                  <!-- <span class="job-listing-title" ><b-link :to="{name: 'employerJobDetail', params: {id: encrypt(jobDatas.jobId)}}">{{jobDatas.title | capitalize}}</b-link></span> -->
                                  <!-- <h3
                                    class="job-listing-title new_all_job_title mobile_margin_top_15"
                                    v-if="jobDatas.description"
                                    style="margin-bottom: 15px; margin-left: 20px"
                                  > -->
                                    <!-- <p v-if="jobDatas.description.length >= 250" v-html="jobDatas.description.substring(0,250)+'...' "></p>
                                                                  <p v-else v-html="jobDatas.description"></p> -->
                                  <!-- </h3> -->
                                  <div class="toggle-action toggle_margin_unset top_100 custom_menu_section_toggle_manage_job">
                                      <!-- <b-dropdown class="mx-1 common_toggle_menu_hide_from_top" right text="Action" boundary="window"> -->
                                      <!-- <b-dropdown class="mx-1" right text="Action" boundary="window"> -->
                                      <b-dropdown class="mx-1" left text="Action" boundary="window">
                                        <b-dropdown-item
                                            :href="'/v2/employer/ft-jobdetail/' + encrypt(jobDatas.jobId)"
                                            target="_blank"
                                            class=""
                                            ><i aria-hidden="true" class="fa fa-eye"></i>View Job Detail
                                          
                                        </b-dropdown-item>
                                        <b-dropdown-item
                                              href="javascript:void(0);"
                                              :to="{ name: 'editJob', params: { id: encrypt(jobDatas.jobId) } }"
                                              class=""
                                              data-tippy-placement="top"
                                              ><i class="material-icons-outlined common_overflow_unset"
                                                >border_color_outlined</i
                                              >Edit Job
                                        </b-dropdown-item>
                                        <b-dropdown-item
                                              href="javascript:void(0);"
                                              :to="{ name: 'repostJob', params: { id: encrypt(jobDatas.jobId) } }"
                                              class=""
                                              title="Repost"
                                              data-tippy-placement="top"
                                              ><i class="material-icons-outlined common_overflow_unset"
                                                >repeat_outlined</i
                                              >Duplicate
                                        </b-dropdown-item>
                        
                                      </b-dropdown>
                                    </div>
                               </div>
                                <!-- Job Listing Footer -->
                                <div class="job-listing-footer manage_job_li_nw">
                                  <ul class="fullTimeJobdata new_full_time_data">
                                    <li>
                                      <span v-if="jobDatas.start_date != null"
                                        ><i class="icon-material-outline-access-time"></i>
                                        {{ jobDatas.start_date | customFormatterOne }} </span
                                      ><span v-if="jobDatas.end_date != null">
                                        - {{ jobDatas.end_date | customFormatterOne }}</span
                                      >
                                      <!-- <br v-if="jobDatas.end_date != null"> -->
                                    </li>
                                  </ul>

                                  <ul class="fullTimeJobdata new_full_time_data">
                                    <li>
                                      <span v-if="jobDatas.service_categories_name"
                                        ><i class="icon-material-outline-business"></i>
                                        <span>{{
                                          jobDatas.service_categories_name == "Other"
                                            ? jobDatas.other_service_category
                                            : jobDatas.service_categories_name | removeUnderscore
                                        }}</span></span
                                      >

                                      <!-- <span v-if="jobDatas.start_date != null"> <i class="icon-material-outline-date-range"></i>Start Date {{jobDatas.start_date | customFormatterOne}}</span><br v-if="jobDatas.start_date != null">
                                                                    <span v-if="jobDatas.end_date != null"> <i class="icon-material-outline-date-range"></i>End Date {{jobDatas.end_date | customFormatterOne}}</span><br v-if="jobDatas.end_date != null">
                                                                    <span> <i class="icon-material-outline-date-range"></i>Posted on {{jobDatas.posted_date | customFormatterOne}}</span> -->
                                    </li>
                                    <li class="mobile_jb_hide">
                                      <span v-if="jobDatas.job_type"
                                        ><i class="icon-material-outline-business-center"></i>
                                        <span>{{
                                          jobDatas.job_type | customizeRoleType | capitalize
                                        }}</span></span
                                      >
                                    </li>
                                    <li class="mobile_jb_hide">
                                      <span v-if="jobDatas.level_title"
                                        ><i class="icon-material-outline-face"></i>
                                        {{ jobDatas.level_title | capitalize }}</span
                                      >
                                    </li>
                                  </ul>
                                  <ul class="fullTimeJobdata new_full_time_data">
                                    <li v-if="jobDatas.actual_posted_by_first_name">
                                      <span>
                                        <i class="icon-material-outline-date-range"></i>Posted By:
                                        {{ jobDatas.actual_posted_by_last_name | capitalize }}
                                        {{
                                          jobDatas.actual_posted_by_first_name | capitalize
                                        }}</span
                                      >
                                    </li>

                                    <li v-if="jobDatas.posted_date">
                                      <span>
                                        <i class="icon-material-outline-date-range"></i>Posted On:
                                        {{ jobDatas.posted_date | customFormatterposted }}</span
                                      >
                                    </li>

                                    <li v-if="jobDatas.job_address">
                                      <span
                                        ><i class="icon-material-outline-location-on"></i>
                                        <span>{{
                                          jobDatas.job_address == "Other"
                                            ? jobDatas.other_location
                                            : jobDatas.job_address | capitalize
                                        }}</span></span
                                      >
                                    </li>
                                    <li v-if="jobDatas.job_unique_id">
                                      <span
                                        ><i class="material-icons-outlined job_id_icon"
                                          >card_travel_outlined</i
                                        >Ads ID:
                                        <span>{{ jobDatas.job_unique_id | capitalize }}</span></span
                                      >
                                    </li>
                                    <li>
                                      <span
                                        v-if="
                                          (jobDatas.min_salary != 0 && jobDatas.max_salary != 0) ||
                                          jobDatas.min_salary > 0 ||
                                          jobDatas.max_salary > 0
                                        "
                                      >
                                        <i class="icon-material-outline-local-atm"></i
                                        ><span v-if="jobDatas.job_type == 'part-time'"
                                          >Per Hour Rate</span
                                        >
                                        <span v-else></span> ${{ jobDatas.min_salary }}-${{
                                          jobDatas.max_salary
                                        }}</span
                                      >
                                    </li>
                                  </ul>
                                  <!-- <ul>
                                                                    <li>
                                                                    
                                                                    <span v-if="jobDatas.start_date != null"> <i class="icon-material-outline-date-range"></i>Start Date {{jobDatas.start_date | customFormatterOne}}</span><br v-if="jobDatas.start_date != null">
                                                                    <span v-if="jobDatas.end_date != null"> <i class="icon-material-outline-date-range"></i>End Date {{jobDatas.end_date | customFormatterOne}}</span><br v-if="jobDatas.end_date != null">
                                                                    <span> <i class="icon-material-outline-date-range"></i>Posted on {{jobDatas.posted_date | customFormatterOne}}</span>
                                                                    </li>
                                                                </ul> -->
                                  <!-- <ul>
                                                                    <li><i class="icon-material-outline-date-range"></i> Posted on {{jobDatas.posted_date | customFormatterOne}}</li>
                                                                </ul> -->
                                </div>
                              </div>
                            </div>
                          </div>

                          <!-- Buttons -->
                          <div
                            class="buttons-to-right always-visible upcoming_job_btnz common_flex_wrap"
                          >
                            <!-- <div class="manage_candidate_in_admin"> -->
                              <!-- <a href="javascript:void(0);" @click="jobDetail(encrypt(jobDatas.jobId),$event)" class="button ripple-effect"><i aria-hidden="true" class="fa fa-eye"></i>&nbsp;&nbsp;View Detail </a> -->
                              <!-- <a
                                :href="'/v2/employer/ft-jobdetail/' + encrypt(jobDatas.jobId)"
                                target="_blank"
                                class="button ripple-effect"
                                ><i aria-hidden="true" class="fa fa-eye"></i>&nbsp;&nbsp;View Detail
                              </a> -->
                            <!-- </div> -->
                            <!-- <a href="" @click="manageCandidate(jobDatas.jobId)" class="button ripple-effect"><i class="icon-material-outline-supervisor-account"></i> Manage Candidates <span class="button-info">{{jobDatas.total_applicants}}</span></a> -->
                            <!-- <div class="other_operation_admin_full_time new_other_op">
                              <b-link
                                href="javascript:void(0);"
                                :to="{ name: 'editJob', params: { id: encrypt(jobDatas.jobId) } }"
                                class="button gray ripple-effect ico edit-btn-job-list"
                                data-tippy-placement="top"
                                ><i class="material-icons-outlined common_overflow_unset"
                                  >border_color_outlined</i
                                >Edit</b-link
                              >
                              <b-link
                                href="javascript:void(0);"
                                :to="{ name: 'repostJob', params: { id: encrypt(jobDatas.jobId) } }"
                                class="button gray ripple-effect ico repost-btn-job-list"
                                title="Repost"
                                data-tippy-placement="top"
                                ><i class="material-icons-outlined common_overflow_unset"
                                  >repeat_outlined</i
                                >Duplicate</b-link
                              >
                            </div> -->
                            <!-- <a href="#" @click="deleteJob(jobDatas.jobId)" class="button gray ripple-effect ico" title="Remove" data-tippy-placement="top"><i class="icon-feather-trash-2"></i></a> -->
                            <!-- <a @click="changeStatus(jobDatas.jobId, jobDatas.job_status_val)" id="show_hide_status" v-if="jobDatas.job_status_val == 'active'" class="button gray ripple-effect ico">
                                                    <i class="fa fa-eye-slash" aria-hidden="true"></i>
                                                    </a>
                                                    <a @click="changeStatus(jobDatas.jobId, jobDatas.job_status_val)" id="show_hide_status" v-else-if="jobDatas.job_status_val == 'inactive'" class="button gray ripple-effect ico">
                                                    <i class="fa fa-eye" aria-hidden="true"></i>
                                                    </a> -->
                            <!-- <a href="#" @click ="changeStatus(jobDatas.jobId, jobDatas.job_status_val)" v-if="jobDatas.job_status_val == 'active'">Inactivate</a> -->
                            <!-- <a href="#" v-else-if="jobDatas.job_status_val == 'inactive'" @click ="changeStatus(jobDatas.jobId, jobDatas.job_status_val)">Activate</a> -->
                            <div
                              class="fa fa-eye view_count_job job_view_count_inline_div"
                              v-if="jobDatas.view_count"
                            >
                              <span>{{ jobDatas.view_count | commaSeperator }}</span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div class="content text-center" v-else>
                      <p class="margin_top_1rem"><b>No Record Found</b></p>
                    </div>
                  </b-tab>
                  <b-tab
                    :title="'EXPIRED (' + expiredCount + ')'"
                    :active="expired"
                    @click="changeTabStatus('expired')"
                    class="reject_last_li"
                  >
                    <div class="content" v-if="jobData.length > 0">
                      <ul class="dashboard-box-list desktop_margin_bottom_zero">
                        <li v-for="jobDatas in jobData">
                          <!-- Job Listing -->
                          <div class="job-listing">
                            <!-- Job Listing Details -->
                            <div class="job-listing-details pb-0">
                              <!-- Logo -->
                              <!-- 											<a href="#" class="job-listing-company-logo">
                                                            <img src="images/company-logo-05.png" alt="company_logo">
                                                        </a> -->

                              <!-- Details -->
                              <div class="job-listing-description">
                                <!-- <span class="job-listing-title" v-if="jobDatas.title.length >= 25"><b-link :to="{name: 'employerJobDetail', params: {id: encrypt(jobDatas.jobId)}}">{{jobDatas.title.substring(0,25)+'...' | capitalize}}</b-link></span>
                                                            <span class="job-listing-title" v-else><b-link :to="{name: 'employerJobDetail', params: {id: encrypt(jobDatas.jobId)}}">{{jobDatas.title | capitalize}}</b-link></span> -->
                                <div class="heading_with_dots">
                                  <span class="job-listing-title"
                                    ><b>{{ jobDatas.title | capitalize }}</b></span
                                  >

                                  <!-- <span class="job-listing-title" ><b-link :to="{name: 'employerJobDetail', params: {id: encrypt(jobDatas.jobId)}}">{{jobDatas.title | capitalize}}</b-link></span> -->
                                  <!-- <h3
                                    class="job-listing-title new_all_job_title mobile_margin_top_15"
                                    v-if="jobDatas.description"
                                    style="margin-bottom: 15px; margin-left: 20px"
                                  > -->
                                    <!-- <p v-if="jobDatas.description.length >= 250" v-html="jobDatas.description.substring(0,250)+'...' "></p>
                                                                  <p v-else v-html="jobDatas.description"></p> -->
                                  <!-- </h3> -->
                                  <div class="toggle-action toggle_margin_unset top_100 custom_menu_section_toggle_manage_job">
                                        <!-- <b-dropdown class="mx-1 common_toggle_menu_hide_from_top" right text="Action" boundary="window"> -->
                                        <!-- <b-dropdown class="mx-1" right text="Action" boundary="window"> -->
                                        <b-dropdown class="mx-1" left text="Action" boundary="window">
                                          <b-dropdown-item
                                               :href="'/v2/employer/ft-jobdetail/' + encrypt(jobDatas.jobId)"
                                                target="_blank"
                                              class=""
                                              ><i aria-hidden="true" class="fa fa-eye"></i>View Job Detail
                                            
                                          </b-dropdown-item>
                                          <b-dropdown-item  v-if="showPermissionEMManageFL('view')"
                                                href="javascript:void(0);"
                                                @click="manageCandidate(encrypt(jobDatas.jobId))"
                                                class=""
                                                ><i class="icon-material-outline-supervisor-account"></i> Manage
                                                Candidates
                                                <span class="button-info">{{ jobDatas.total_applicants }}</span>
                                          </b-dropdown-item>
                                          <b-dropdown-item
                                                 href="javascript:void(0);"
                                                :to="{ name: 'repostJob', params: { id: encrypt(jobDatas.jobId) } }"
                                                class=""
                                                data-tippy-placement="top"
                                                ><i class="material-icons-outlined common_overflow_unset"
                                                  >repeat_outlined</i
                                                >Duplicate
                                          </b-dropdown-item>
                          
                                        </b-dropdown>
                                  </div>
                                </div>
                                <!-- Job Listing Footer -->
                                <div class="job-listing-footer manage_job_li_nw">
                                  <ul class="fullTimeJobdata new_full_time_data">
                                    <li>
                                      <span v-if="jobDatas.start_date != null"
                                        ><i class="icon-material-outline-access-time"></i>
                                        {{ jobDatas.start_date | customFormatterOne }} </span
                                      ><span v-if="jobDatas.end_date != null">
                                        - {{ jobDatas.end_date | customFormatterOne }}</span
                                      >
                                      <!-- <br v-if="jobDatas.end_date != null"> -->
                                    </li>
                                  </ul>

                                  <ul class="fullTimeJobdata new_full_time_data">
                                    <li>
                                      <span v-if="jobDatas.service_categories_name"
                                        ><i class="icon-material-outline-business"></i>
                                        <span>{{
                                          jobDatas.service_categories_name == "Other"
                                            ? jobDatas.other_service_category
                                            : jobDatas.service_categories_name | removeUnderscore
                                        }}</span></span
                                      >
                                    </li>
                                    <li class="mobile_jb_hide">
                                      <span v-if="jobDatas.job_type"
                                        ><i class="icon-material-outline-business-center"></i>
                                        <span>{{
                                          jobDatas.job_type | customizeRoleType | capitalize
                                        }}</span></span
                                      >
                                    </li>
                                    <li class="mobile_jb_hide">
                                      <span v-if="jobDatas.level_title"
                                        ><i class="icon-material-outline-face"></i>
                                        {{ jobDatas.level_title | capitalize }}</span
                                      >
                                    </li>
                                  </ul>
                                  <ul class="fullTimeJobdata new_full_time_data">
                                    <li v-if="jobDatas.actual_posted_by_first_name">
                                      <span>
                                        <i class="icon-material-outline-date-range"></i>Posted By:
                                        {{ jobDatas.actual_posted_by_last_name | capitalize }}
                                        {{
                                          jobDatas.actual_posted_by_first_name | capitalize
                                        }}</span
                                      >
                                    </li>

                                    <li v-if="jobDatas.posted_date">
                                      <span>
                                        <i class="icon-material-outline-date-range"></i>Posted On:
                                        {{ jobDatas.posted_date | customFormatterposted }}</span
                                      >
                                    </li>

                                    <li v-if="jobDatas.job_address">
                                      <span
                                        ><i class="icon-material-outline-location-on"></i>
                                        <span>{{
                                          jobDatas.job_address == "Other"
                                            ? jobDatas.other_location
                                            : jobDatas.job_address | capitalize
                                        }}</span></span
                                      >
                                    </li>
                                    <li v-if="jobDatas.job_unique_id">
                                      <span
                                        ><i class="material-icons-outlined job_id_icon"
                                          >local_offer_outlined</i
                                        >Job Id:
                                        <span>{{ jobDatas.job_unique_id | capitalize }}</span></span
                                      >
                                    </li>
                                    <li  v-if="
                                          (jobDatas.min_salary != 0 && jobDatas.max_salary != 0) ||
                                          jobDatas.min_salary > 0 ||
                                          jobDatas.max_salary > 0
                                        ">
                                      <span
                                        v-if="
                                          (jobDatas.min_salary != 0 && jobDatas.max_salary != 0) ||
                                          jobDatas.min_salary > 0 ||
                                          jobDatas.max_salary > 0
                                        "
                                      >
                                        <i class="icon-material-outline-local-atm"></i
                                        ><span v-if="jobDatas.job_type == 'part-time'"
                                          >Per Hour Rate</span
                                        >
                                        <span v-else></span> ${{ jobDatas.min_salary }}-${{
                                          jobDatas.max_salary
                                        }}</span
                                      >
                                    </li>
                                  </ul>
                                  <!-- <ul>
                                                                    <li>
                                                                    <span v-if="jobDatas.start_date != null"> <i class="icon-material-outline-date-range"></i>Job Duration: {{jobDatas.start_date | customFormatterOne}} </span><span v-if="jobDatas.end_date != null"> - {{jobDatas.end_date | customFormatterOne}}</span><br v-if="jobDatas.end_date != null">
                                                                    <span v-if="jobDatas.posted_date"> <i class="icon-material-outline-date-range"></i>Posted on {{jobDatas.posted_date | customFormatterOne}}</span>
                                                                    <span v-if="jobDatas.service_categories_name"> <i class="icon-material-outline-date-range"></i>Category <h5>{{jobDatas.service_categories_name == 'Other' ? jobDatas.other_service_category : jobDatas.service_categories_name | removeUnderscore}}</h5></span>
                                                                    <span v-if="jobDatas.job_type"> <i class="icon-material-outline-date-range"></i>Role Type <h5>{{jobDatas.job_type | customizeRoleType | capitalize}}</h5></span>
                                                                    <span v-if="jobDatas.level_title"> <i class="icon-material-outline-date-range"></i>Position Level {{jobDatas.level_title | capitalize}}</span>
                                                                    <span v-if="jobDatas.job_address"> <i class="icon-material-outline-date-range"></i>Location <h5>{{jobDatas.job_address == 'Other' ? jobDatas.other_location : jobDatas.job_address | capitalize}}</h5></span>
                                                                    <span v-if="(jobDatas.min_salary != 0 && jobDatas.max_salary != 0) || jobDatas.min_salary > 0 || jobDatas.max_salary > 0"> <i class="icon-material-outline-date-range"></i><span v-if="jobDatas.job_type == 'part-time'">Per Hour Rate</span>
                                                                    <span v-else>Salary</span> ${{jobDatas.min_salary}}-${{jobDatas.max_salary}}</span>
                                                                    </li>
                                                                </ul> -->
                                  <!-- <ul>
                                                                    <li><i class="icon-material-outline-date-range"></i> Posted on {{jobDatas.posted_date | customFormatterOne}}</li>
                                                                </ul> -->
                                </div>
                              </div>
                            </div>
                          </div>

                          <!-- Buttons -->
                          <div
                            class="width_hundered_total_div"
                          >
                            <!-- <div class="manage_candidate_in_admin"> -->
                              <!-- <a href="javascript:void(0);" @click="jobDetail(encrypt(jobDatas.jobId),$event)" class="button ripple-effect"><i aria-hidden="true" class="fa fa-eye"></i>&nbsp;&nbsp;View Detail </a> -->
                              <!-- <a
                                :href="'/v2/employer/ft-jobdetail/' + encrypt(jobDatas.jobId)"
                                target="_blank"
                                class="button ripple-effect"
                                ><i aria-hidden="true" class="fa fa-eye"></i>&nbsp;&nbsp;View Detail
                              </a> -->
                            <!-- </div> -->
                            <!-- <div
                              class="manage_candidate_in_admin"
                              v-if="showPermissionEMManageFL('view')"
                            >
                              <a
                                href="javascript:void(0);"
                                @click="manageCandidate(encrypt(jobDatas.jobId))"
                                class="button ripple-effect"
                                ><i class="icon-material-outline-supervisor-account"></i> Manage
                                Candidates
                                <span class="button-info">{{ jobDatas.total_applicants }}</span></a
                              >
                            </div> -->
                            <!-- <b-link href="" :to="{name: 'editJob', params: {id: jobDatas.jobId}}" class="button gray ripple-effect ico" title="Edit" data-tippy-placement="top"><i class="material-icons-outlined common_overflow_unset">border_color_outlined</i></b-link> -->
                            <!-- <div class="other_operation_admin_full_time new_other_op">
                              <b-link
                                href="javascript:void(0);"
                                :to="{ name: 'repostJob', params: { id: encrypt(jobDatas.jobId) } }"
                                class="button gray ripple-effect ico repost-btn-job-list"
                                data-tippy-placement="top"
                                ><i class="material-icons-outlined common_overflow_unset"
                                  >repeat_outlined</i
                                >Duplicate</b-link
                              >
                            </div> -->
                            <!-- <a href="#" @click="deleteJob(jobDatas.jobId)" class="button gray ripple-effect ico" title="Remove" data-tippy-placement="top"><i class="icon-feather-trash-2"></i></a> -->
                            <!-- <a @click="changeStatus(jobDatas.jobId, jobDatas.job_status_val)" id="show_hide_status" v-if="jobDatas.job_status_val == 'active'" class="button gray ripple-effect ico">
                                                    <i class="fa fa-eye-slash" aria-hidden="true"></i>
                                                    </a>
                                                    <a @click="changeStatus(jobDatas.jobId, jobDatas.job_status_val)" id="show_hide_status" v-else-if="jobDatas.job_status_val == 'inactive'" class="button gray ripple-effect ico">
                                                    <i class="fa fa-eye" aria-hidden="true"></i>
                                                    </a> -->
                            <!-- <a href="#" @click ="changeStatus(jobDatas.jobId, jobDatas.job_status_val)" v-if="jobDatas.job_status_val == 'active'">Inactivate</a> -->
                            <!-- <a href="#" v-else-if="jobDatas.job_status_val == 'inactive'" @click ="changeStatus(jobDatas.jobId, jobDatas.job_status_val)">Activate</a> -->
                            <div
                              class="fa fa-eye view_count_job job_view_count_inline_div pb-2"
                              v-if="jobDatas.view_count"
                            >
                              <span>{{ jobDatas.view_count | commaSeperator }}</span>
                            </div>
                            <div class="total_applied_emp_lg">
                                 <span class="material-icons-outlined">grading_outlined</span><span class="applied_text_emp">Total Applied - {{ jobDatas.total_applicants }}</span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div class="content text-center" v-else>
                      <p class="margin_top_1rem"><b>No Record Found</b></p>
                    </div>
                  </b-tab>
                  <b-tab
                    :title="'INACTIVE (' + inactiveCount + ')'"
                    :active="inactiveTab"
                    @click="changeTabStatus('inactiveTab')"
                    class="reject_last_li"
                  >
                    <div class="content" v-if="jobData.length > 0">
                      <ul class="dashboard-box-list desktop_margin_bottom_zero">
                        <li v-for="jobDatas in jobData">
                          <!-- Job Listing -->
                          <div class="job-listing">
                            <!-- Job Listing Details -->
                            <div class="job-listing-details">
                              <!-- Logo -->
                              <!-- 											<a href="#" class="job-listing-company-logo">
                                                            <img src="images/company-logo-05.png" alt="company_logo">
                                                        </a> -->

                              <!-- Details -->
                              <div class="job-listing-description">
                                <!-- <span class="job-listing-title new_job_listing_article" v-if="jobDatas.title.length >= 25"><b-link :to="{name: 'employerJobDetail', params: {id: encrypt(jobDatas.jobId)}}">{{jobDatas.title.substring(0,25)+'...' | capitalize}}</b-link></span>
                                                            <span class="job-listing-title new_job_listing_article" v-else><b-link :to="{name: 'employerJobDetail', params: {id: encrypt(jobDatas.jobId)}}">{{jobDatas.title | capitalize}}</b-link></span> -->
                                <div class="heading_with_dots">
                                  <span class="job-listing-title"
                                    ><b>{{ jobDatas.title | capitalize }}</b></span
                                  >

                                  <!-- <span class="job-listing-title" ><b-link :to="{name: 'employerJobDetail', params: {id: encrypt(jobDatas.jobId)}}">{{jobDatas.title | capitalize}}</b-link></span>  -->

                                  <!-- <h3
                                    class="job-listing-title new_all_job_title mobile_margin_top_15"
                                    v-if="jobDatas.description"
                                    style="margin-bottom: 15px; margin-left: 20px"
                                  > -->
                                    <!-- <p v-if="jobDatas.description.length >= 250" v-html="jobDatas.description.substring(0,250)+'...' "></p>
                                                                  <p v-else v-html="jobDatas.description"></p> -->
                                  <!-- </h3> -->
                                  <div class="toggle-action toggle_margin_unset top_100 custom_menu_section_toggle_manage_job">
                                          <!-- <b-dropdown class="mx-1 common_toggle_menu_hide_from_top" right text="Action" boundary="window"> -->
                                          <!-- <b-dropdown class="mx-1" right text="Action" boundary="window"> -->
                                          <b-dropdown class="mx-1" left text="Action" boundary="window">
                                            <b-dropdown-item
                                                :href="'/v2/employer/ft-jobdetail/' + encrypt(jobDatas.jobId)"
                                                  target="_blank"
                                                class=""
                                                ><i aria-hidden="true" class="fa fa-eye"></i>View Job Detail
                                              
                                            </b-dropdown-item>
                                            <b-dropdown-item  v-if="showPermissionEMManageFL('view')"
                                                  href="javascript:void(0);"
                                                  @click="manageCandidate(encrypt(jobDatas.jobId))"
                                                  class=""
                                                  ><i class="icon-material-outline-supervisor-account"></i> Manage
                                                  Candidates
                                                  <span class="button-info">{{ jobDatas.total_applicants }}</span>
                                            </b-dropdown-item>
                                            <b-dropdown-item
                                                   href="javascript:void(0);"
                                                  :to="{ name: 'repostJob', params: { id: encrypt(jobDatas.jobId) } }"
                                                  class=""
                                                  data-tippy-placement="top"
                                                  ><i class="material-icons-outlined common_overflow_unset"
                                                    >repeat_outlined</i
                                                  >Duplicate
                                            </b-dropdown-item>
                            
                                          </b-dropdown>
                                    </div>
                                </div>

                                <!-- Job Listing Footer -->
                                <div class="job-listing-footer manage_job_li_nw display_inline">
                                  <ul class="fullTimeJobdata new_full_time_data">
                                    <li>
                                      <span v-if="jobDatas.start_date != null"
                                        ><i class="icon-material-outline-access-time"></i>
                                        {{ jobDatas.start_date | customFormatterOne }} </span
                                      ><span v-if="jobDatas.end_date != null">
                                        - {{ jobDatas.end_date | customFormatterOne }}</span
                                      >
                                      <!-- <br v-if="jobDatas.end_date != null"> -->
                                    </li>
                                  </ul>

                                  <ul class="fullTimeJobdata new_full_time_data">
                                    <li>
                                      <span v-if="jobDatas.service_categories_name"
                                        ><i class="icon-material-outline-business"></i>
                                        <span>{{
                                          jobDatas.service_categories_name == "Other"
                                            ? jobDatas.other_service_category
                                            : jobDatas.service_categories_name | removeUnderscore
                                        }}</span></span
                                      >

                                      <!-- <span v-if="jobDatas.start_date != null"> <i class="icon-material-outline-date-range"></i>Start Date {{jobDatas.start_date | customFormatterOne}}</span><br v-if="jobDatas.start_date != null">
                                                                    <span v-if="jobDatas.end_date != null"> <i class="icon-material-outline-date-range"></i>End Date {{jobDatas.end_date | customFormatterOne}}</span><br v-if="jobDatas.end_date != null">
                                                                    <span> <i class="icon-material-outline-date-range"></i>Posted on {{jobDatas.posted_date | customFormatterOne}}</span> -->
                                    </li>
                                    <li class="mobile_jb_hide">
                                      <span v-if="jobDatas.job_type"
                                        ><i class="icon-material-outline-business-center"></i>
                                        <span>{{
                                          jobDatas.job_type | customizeRoleType | capitalize
                                        }}</span></span
                                      >
                                    </li>
                                    <li class="mobile_jb_hide">
                                      <span v-if="jobDatas.level_title"
                                        ><i class="icon-material-outline-face"></i>
                                        {{ jobDatas.level_title | capitalize }}</span
                                      >
                                    </li>
                                  </ul>
                                  <ul class="fullTimeJobdata new_full_time_data">
                                    <li v-if="jobDatas.actual_posted_by_first_name">
                                      <span>
                                        <i class="icon-material-outline-date-range"></i>Posted By:
                                        {{ jobDatas.actual_posted_by_last_name | capitalize }}
                                        {{
                                          jobDatas.actual_posted_by_first_name | capitalize
                                        }}</span
                                      >
                                    </li>

                                    <li v-if="jobDatas.posted_date">
                                      <span>
                                        <i class="icon-material-outline-date-range"></i>Posted On:
                                        {{ jobDatas.posted_date | customFormatterposted }}</span
                                      >
                                    </li>

                                    <li v-if="jobDatas.job_address">
                                      <span
                                        ><i class="icon-material-outline-location-on"></i>
                                        <span>{{
                                          jobDatas.job_address == "Other"
                                            ? jobDatas.other_location
                                            : jobDatas.job_address | capitalize
                                        }}</span></span
                                      >
                                    </li>
                                    <li v-if="jobDatas.job_unique_id">
                                      <span
                                        ><i class="material-icons-outlined job_id_icon"
                                          >card_travel_outlined</i
                                        >Ads ID:
                                        <span>{{ jobDatas.job_unique_id | capitalize }}</span></span
                                      >
                                    </li>
                                    <li>
                                      <span
                                        v-if="
                                          (jobDatas.min_salary != 0 && jobDatas.max_salary != 0) ||
                                          jobDatas.min_salary > 0 ||
                                          jobDatas.max_salary > 0
                                        "
                                      >
                                        <i class="icon-material-outline-local-atm"></i
                                        ><span v-if="jobDatas.job_type == 'part-time'"
                                          >Per Hour Rate</span
                                        >
                                        <span v-else></span> ${{ jobDatas.min_salary }}-${{
                                          jobDatas.max_salary
                                        }}</span
                                      >
                                    </li>
                                  </ul>
                                  <!-- <ul>
                                                                    <li><i class="icon-material-outline-date-range"></i> Posted on {{jobDatas.posted_date | customFormatterOne}}</li>
                                                                </ul> -->
                                </div>
                              </div>
                            </div>
                          </div>

                          <!-- Buttons -->
                          <div
                            class="buttons-to-right always-visible upcoming_job_btnz common_flex_wrap"
                          >
                            <!-- <div class="manage_candidate_in_admin"> -->
                              <!-- <a href="javascript:void(0);" @click="jobDetail(encrypt(jobDatas.jobId),$event)" class="button ripple-effect"><i aria-hidden="true" class="fa fa-eye"></i>&nbsp;&nbsp;View Detail </a> -->
                              <!-- <a
                                :href="'/v2/employer/ft-jobdetail/' + encrypt(jobDatas.jobId)"
                                target="_blank"
                                class="button ripple-effect"
                                ><i aria-hidden="true" class="fa fa-eye"></i>&nbsp;&nbsp;View Detail
                              </a> -->
                            <!-- </div> -->
                            <!-- <div
                              class="manage_candidate_in_admin"
                              v-if="showPermissionEMManageFL('view')"
                            >
                              <a
                                href="javascript:void(0);"
                                @click="manageCandidate(encrypt(jobDatas.jobId))"
                                class="button ripple-effect"
                                ><i class="icon-material-outline-supervisor-account"></i> Manage
                                Candidates
                                <span class="button-info">{{ jobDatas.total_applicants }}</span></a
                              >
                            </div> -->
                            <!-- <div class="other_operation_admin_full_time new_other_op"> -->
                              <!-- <b-link href="" :to="{name: 'editJob', params: {id: encrypt(jobDatas.jobId)}}" class="button gray ripple-effect ico edit-btn-job-list" data-tippy-placement="top"><i class="material-icons-outlined common_overflow_unset">border_color_outlined</i>Edit</b-link> -->
                              <!-- <b-link
                                href="javascript:void(0);"
                                :to="{ name: 'repostJob', params: { id: encrypt(jobDatas.jobId) } }"
                                class="button gray ripple-effect ico repost-btn-job-list"
                                data-tippy-placement="top"
                                ><i class="material-icons-outlined common_overflow_unset"
                                  >repeat_outlined</i
                                >Duplicate</b-link
                              > -->
                              <!-- <a href="#" @click="deleteJob(jobDatas.jobId)" class="button gray ripple-effect ico" title="Remove" data-tippy-placement="top"><i class="icon-feather-trash-2"></i></a> -->
                              <!-- <a @click="changeStatus(jobDatas.jobId, jobDatas.job_status_val, jobDatas.title ,jobDatas.end_date)"  id="show_hide_status" v-if="jobDatas.job_status_val == 'active'" class="button gray ripple-effect ico non-active-btn-job-list">
                                                        <i class="fa fa-eye-slash" aria-hidden="true"></i>Inactivate
                                                        </a>
                                                        <a @click="changeStatus(jobDatas.jobId, jobDatas.job_status_val, jobDatas.title ,jobDatas.end_date)"  id="show_hide_status" v-else-if="jobDatas.job_status_val == 'inactive'" class="button gray ripple-effect ico active-btn-job-list">
                                                        <i class="fa fa-eye" aria-hidden="true"></i>Activate
                                                        </a> -->
                            <!-- </div> -->
                            <!-- <b-link href=""  v-if="jobDatas.featured == 'no'" class="button gray ripple-effect ico" title="Feature job" data-tippy-placement="top" @click="$bvModal.show('my_manage_job_model')"><i class="material-icons-outlined">new_releases</i></b-link>
                                                        <div class="">
                                                            <b-modal id="my_manage_job_model" ref="my_manage_job_model" title="BootstrapVue" >
                                                                <h2>Feature Job</h2>
                                                                <p>Please select the featured job package</p>
                                                                <div class="custom_select_manage_jobz">
                                                                    <b-form-select v-model="ft_feature_job" id="package" class="package" @change="onChangeFeature()">
                                                                        <option value="" disabled>Select featured job package</option>
                                                                        <option value="ft_1_day_free_featured_jobs">1 days - Free Featured - ({{ft_1_day_free_featured_jobs}} Remaining)</option>
                                                                        <option value="ft_7_day_free_featured_jobs">7 days - Free Featured - ({{ft_7_day_free_featured_jobs}} Remaining)</option>
                                                                        <option value="ft_14_day_free_featured_jobs">14 days - Free Featured - ({{ft_14_day_free_featured_jobs}} Remaining)</option>
                                                                        <option value="ft_30_day_free_featured_jobs">30 days - Free Featured - ({{ft_30_day_free_featured_jobs}} Remaining)</option>
                                                                    </b-form-select>										
                                                                </div>
                                                                <h3 class="coin_msg" style="display:none">You will have <b-img :src="webUrl+'assets/images/Icons_Coin.png'" class="coins_manage_job_img"></b-img> {{remaining_coins}} EL Coins left after purchasing.</h3>
                                                                <p class="red_p">Note: Action cannot be undo once confirmed.</p>
                                                                <div class="model_footer_btns">
                                                                    <b-button  block @click="$bvModal.hide('my_manage_job_model')">Cancel</b-button>
                                                                    <button class="blue_btn" @click="submitFeatured(jobDatas.jobId)">Confirm Feature</button>
                                                                </div>
                                                            </b-modal>
                                                        </div> -->
                            <!-- <a @click="featureJob(jobDatas.jobId,jobDatas.company_id)" v-if="jobDatas.featured == 'no'" title="Edit" class="button gray ripple-effect ico">
                                                    </a> -->
                            <!-- <a href="#" @click ="changeStatus(jobDatas.jobId, jobDatas.job_status_val)" v-if="jobDatas.job_status_val == 'active'">Inactivate</a> -->
                            <!-- <a href="#" v-else-if="jobDatas.job_status_val == 'inactive'" @click ="changeStatus(jobDatas.jobId, jobDatas.job_status_val)">Activate</a> -->
                            <div
                              class="fa fa-eye view_count_job job_view_count_inline_div"
                              v-if="jobDatas.view_count"
                            >
                              <span>{{ jobDatas.view_count | commaSeperator }}</span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div class="content text-center" v-else>
                      <p class="margin_top_1rem"><b>No Record Found</b></p>
                    </div>
                  </b-tab>
                </b-tabs>
              </div>
            </div>
          </div>
        </div>
        <!-- Row / End -->
        <!-- Row / End -->
        <div class="clearfix"></div>
        <nav class="pagination-box custom_pagination_border_radius">
          <div class="page-row-box d-inline-block">
            <b-form-group id="input-group-4">
              <span class="d-inline-block">Rows per page</span>
              <span class="d-inline-block"
                ><b-form-select v-model="form.rowsPerPage" @input="changeTabStatus(activeTab)">
                  <option>25</option>
                  <option>50</option>
                  <option>75</option>
                  <option>100</option>
                </b-form-select></span
              >
            </b-form-group>
          </div>
          <div class="total-page-count d-inline-block">
            <ul>
              <li>
                <span v-if="totalRows != 0">{{ from }} - {{ to }} of {{ totalRows }}</span>
              </li>
            </ul>
          </div>
          <b-pagination
            @input="changeTabStatus(activeTab)"
            :total-rows="totalRows"
            :per-page="perPage"
            v-model="currentPage"
            prev-text=""
            next-text=""
            hide-goto-end-buttons
          />
        </nav>
        <div class="clearfix"></div>
        <!-- <div class="margin-top-70"></div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { POST_API } from "../../store/actions.type";
import moment from "moment";
import Bus from "../../event-bus";
import DateRangePicker from "vue2-daterange-picker";
import Multiselect from "vue-multiselect";
import permission from "../../../../server/permission.js";

export default {
  data() {
    return {
      webUrl: process.env.VUE_APP_URL,
      jobData: "",
      skill: "",
      daily: "",
      full_time: "",
      location: "",
      type: "",
      keyword: "",
      salary: "",
      error_message: "",
      skillsArr: [],
      tagArr: [],
      value: [0, 15000],
      pager: {},
      pageOfItems: [],
      options: [],
      items: null,
      totalRows: 0,
      sliderValue: 50,
      from: "",
      to: "",
      currentPage: 1,
      perPage: 25,
      pageOptions: [25, 50, 75, 100],
      form: {
        rowsPerPage: 25,
      },
      activeTab: "inprogress",
      inprogress: true,
      upcoming: false,
      expired: false,
      inactiveTab: false,
      inprogressCount: "",
      upcomingCount: "",
      expiredCount: "",
      inactiveCount: "",
      remaining_feature_jobs: 0,
      loading: false,
      selected: null,
      ft_feature_job: "",
      ft_1_day_free_featured_jobs: 0,
      ft_7_day_free_featured_jobs: 0,
      ft_14_day_free_featured_jobs: 0,
      ft_30_day_free_featured_jobs: 0,
      sett_ft_1_day_featured_job_coins: 0,
      sett_ft_7_day_featured_job_coins: 0,
      sett_ft_14_day_featured_job_coins: 0,
      sett_ft_30_day_featured_job_coins: 0,
      total_coins: 0,
      remaining_coins: 0,
      filterSearch: "",
      dateRange: {
        startDate: "",
        endDate: "",
      },
      user_type_id: "",
      start_date_filter: "",
      end_date_filter: "",
      range: false,
      date: moment().utc().format("YYYY-MM-DD"),
      format: "YYYY-MM-DD HH:mm",
      auto: true,
      center: "center",
      minDate: null,
      locale: {
        direction: "ltr", //direction of text
        format: "DD-MMM-YYYY", //fomart of the dates displayed
        separator: " - ", //separator between the two ranges
        applyLabel: "Apply",
        cancelLabel: "Cancel",
        /*weekLabel: 'W',
                    customRangeLabel: 'Custom Range',*/
        daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
        monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
        firstDay: 0, //ISO first day of week - see moment documenations for details
        sameDateFormat: {
          from: "DD MM YYYY, hh:mm",
          to: "hh:mm",
        },
      },
      category_id_filter: [],
      categories: [],
      permissionActionsFL: "",
      webServerUrl: process.env.VUE_APP_SERVER_URL,

      // options: [
      //     { value: 'a', text: 'First Package' },
      //     { value: 'b', text: 'Second Package' },
      //     { value: 'b', text: 'Third Package' },
      //     { value: 'b', text: 'Fourth Package' }
      // ]
    };
  },

  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    capitalizeAll: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.toUpperCase();
    },
    customFormatterOne(date) {
      return moment(date).utc().format("DD MMM YYYY");
    },
    customFormatterposted(date) {
      return moment(date).utc().format("DD MMM YYYY hh:mm A");
    },
    removeUnderscore: function (value) {
      if (!value) return "";
      var i,
        frags = value.split("_");
      for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
      }
      return frags.join(" ");
    },
    customizeRoleType: function (value) {
      if (!value) return "";
      var i,
        frags = value.split("-");
      for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
      }
      return frags.join(" ");
    },
    datePicker1: function (value) {
      if (value == null) {
        return "";
      } else {
        return moment(value).format("DD MMM YYYY");
      }
    },
    commaSeperator: function (value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },

  components: {
    DateRangePicker,
    Multiselect,
  },
  methods: {
    showAlert() {
      this.$swal({
        position: "center",
        // icon: 'error',
        imageUrl: "/assets/images/404_elcy.gif",
        customClass: {
          container: "mascot_error_container",
        },
        title: this.error_message,
        showConfirmButton: false,
        timer: 1500,
      });
    },

    btoa(id) {
      return btoa(id);
    },
    showSuccessAlert(message) {
      this.$swal({
        position: "center",
        icon: "success",
        iconColor: "#4c8bf5",
        title: message,
        showConfirmButton: false,
        timer: 1500,
      }).then((result) => {
        // this.$router.push({ name: 'manage-jobs' });
      });
    },
    clear() {
      this.filterSearch = "";
      this.dateRange.startDate = null;
      this.dateRange.endDate = null;
      this.category_id_filter = [];
      this.changeTabStatus(this.activeTab);
    },
    customLabel({ first_name, last_name, name }) {
      return `${name} (${last_name} ${first_name})`;
    },
    getDropdownData() {
      this.$store
        .dispatch(POST_API, {
          data: {
            table: "service_categories",
          },
          api: "/api/dropdown-list-category",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;

            this.$swal({
              position: "center",
              // icon: 'error',
              imageUrl: "/assets/images/404_elcy.gif",
              customClass: {
                container: "mascot_error_container",
              },
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.categories = this.$store.getters.getResults.data;
          }
        });
    },
    changeTabStatus(status) {
      this.loading = true;
      this.activeTab = status;
      if (this.activeTab == "upcoming") {
        this.upcoming = true;
        this.inprogress = false;
        this.expired = false;
        this.inactiveTab = false;
      } else if (this.activeTab == "expired") {
        this.expired = true;
        this.upcoming = false;
        this.inprogress = false;
        this.inactiveTab = false;
      } 
        else if (this.activeTab == "inactiveTab"){
        this.expired = false;
        this.upcoming = false;
        this.inprogress = false;
        this.inactiveTab = true;
      }
      else {
        this.inprogress = true;
        this.expired = false;
        this.upcoming = false;
        this.inactiveTab = false;
      }
      (this.start_date_filter = this.dateRange.startDate
        ? moment(this.dateRange.startDate).format("YYYY-MM-DD")
        : ""),
        (this.end_date_filter = this.dateRange.endDate
          ? moment(this.dateRange.endDate).format("YYYY-MM-DD")
          : "");
      $(".dashboard-content-inner").animate(
        {
          scrollTop: 0,
        },
        "slow"
      );
      return this.$store
        .dispatch(POST_API, {
          data: {
            page: this.currentPage,
            rowsPerPage: this.form.rowsPerPage,
            userId: this.user_id,
            company_id: this.company_id,
            activeTab: status,
            search_filter: this.filterSearch,
            start_date_filter: this.start_date_filter,
            end_date_filter: this.end_date_filter,
            category_id_filter: this.category_id_filter,
          },
          api: "/api/jobDataForUser",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            this.loading = false;

            return [];
          } else {
            $(".dashboard-content-container .simplebar-scroll-content").animate(
              {
                scrollTop: 0,
              },
              "slow"
            );
            this.loading = false;

            this.jobData = this.$store.getters.getResults.pageOfItems;
            this.totalRows = this.$store.getters.getResults.pager.totalItems;
            this.perPage = this.$store.getters.getResults.pager.pageSize;
            this.from = this.$store.getters.getResults.pager.from;
            this.to = this.$store.getters.getResults.pager.to;
            Bus.$emit("get_em_job_count");
            this.getTotalCount();
            return this.jobData;
          }
        });
    },
    exportJobs(status) {
      console.log(status, "status");
      var base_url = this.webServerUrl;
      var start_date = this.start_date_filter ? this.start_date_filter : "null";
      var end_date = this.end_date_filter ? this.end_date_filter : "null";
      var search_filter = this.filterSearch != "" ? this.filterSearch : "null";
      var company_id = this.company_id ? this.company_id : "null";
      var category_id_filter = this.category_id_filter ? this.category_id_filter : "null";
      var activeTab = status ? status : null;
      var userId = this.user_id ? this.user_id : null;
      var page = this.currentPage;
      var rowsPerPage = this.form.rowsPerPage;
      var catArr = [];

      if (this.category_id_filter.length > 0) {
        this.category_id_filter.forEach((element) => {
          catArr.push(element.id);
        });
      }
      var category_id_filter = this.category_id_filter.length > 0 ? catArr : "null";
      var export_url =
        "/api/job-data-emp-excel/" +
        search_filter +
        "/" +
        page +
        "/" +
        rowsPerPage +
        "/" +
        activeTab +
        "/" +
        company_id +
        "/" +
        category_id_filter +
        "/" +
        start_date +
        "/" +
        end_date;
      window.open(base_url + export_url, "_blank");
    },
    deleteJob(id) {
      this.$swal({
        title: "Please Confirm",
        text: "Are you sure you want to delete this record?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      })
        .then((result) => {
          if (result.value) {
            return this.$store
              .dispatch(POST_API, {
                data: {
                  id: id,
                },
                api: "/api/deleteJob",
              })
              .then(() => {
                if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
                } else {
                  var message = "Job Deleted successfully.";
                  this.showSuccessAlert(message);
                  window.location.replace("/v2/employer/manage-jobs");
                }
              });
          }
        })
        .catch((err) => {});
    },

    changeStatus(id, status, title, end_date) {
      if (status == "active") {
        // var msg = 'Are you sure you want to inactivate this record?';
        var message = "Job Inactivated successfully.";
        var new_status = "inactive";
        var pop_up_title = title;
        var end_date = moment(end_date);
        var current_date = moment(new Date());
        // console.log(`Remaining Days : ${end_date.diff(current_date, 'days')} day(s)`);
        var ramaining_days = `${end_date.diff(current_date, "days")} days`;
        var pop_up_content =
          '<center><h2 class="swal2-title" id="swal2-title">Please confirm to<br>inactivate this job posting</h2></center><ul class="custom_swal_list_manage_job"><li><b>Job Title : </b>' +
          pop_up_title +
          '</li><li class="middle_para_job"><b>Remaining Days : </b>' +
          ramaining_days +
          '</li></ul><p class="manage_job_red_color">This action is irreversible and any remaining posting days will be forfeited.</p>';
      } else {
        var msg = "Are you sure you want to activate this record?";
        var message = "Job Activated successfully.";
        var new_status = "active";
        var pop_up_content =
          '<center><h2 class="swal2-title activate_h2" id="swal2-title">Please Confirm</h2><p>Are you sure you want to activate this record?</p></center>';
      }

      this.$swal({
        // title: 'Please Confirm',
        text: msg,
        html: pop_up_content,
        // type: 'warning',
        type: "success",
        icon: "success",
        iconColor: "#4c8bf5",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Proceed",
        cancelButtonText: "Cancel",
        customClass: {
          container: "manage_job_container_class",
        },
      })
        .then((result) => {
          if (result.value) {
            return this.$store
              .dispatch(POST_API, {
                data: {
                  id: id,
                  logged_in_user: this.user_id,
                  status: new_status,
                  page: this.currentPage,
                  keyword: this.filter,
                },
                api: "/api/changeJobStatus",
              })
              .then(() => {
                if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
                } else {
                  this.showSuccessAlert(message);
                  this.changeTabStatus(this.activeTab);
                  this.getTotalCount();
                  // window.location.replace('/v2/manage-jobs');
                }
              });
          }
        })
        .catch((err) => {});
    },

    onChangeFeature(id) {
      if (this.ft_feature_job == "ft_1_day_free_featured_jobs") {
        if (this.ft_1_day_free_featured_jobs == 0) {
          this.remaining_coins = this.total_coins - this.sett_ft_1_day_featured_job_coins;
        } else {
          this.remaining_coins = this.total_coins;
        }
      } else if (this.ft_feature_job == "ft_7_day_free_featured_jobs") {
        if (this.ft_7_day_free_featured_jobs == 0) {
          this.remaining_coins = this.total_coins - this.sett_ft_7_day_featured_job_coins;
        } else {
          this.remaining_coins = this.total_coins;
        }
      } else if (this.ft_feature_job == "ft_14_day_free_featured_jobs") {
        if (this.ft_14_day_free_featured_jobs == 0) {
          this.remaining_coins = this.total_coins - this.sett_ft_14_day_featured_job_coins;
        } else {
          this.remaining_coins = this.total_coins;
        }
      } else {
        if (this.ft_30_day_free_featured_jobs == 0) {
          this.remaining_coins = this.total_coins - this.sett_ft_30_day_featured_job_coins;
        } else {
          this.remaining_coins = this.total_coins;
        }
      }
      if (this.remaining_coins > 0) {
        $(".coin_msg").css("display", "block");
      }
    },
    closeModalFeature() {
      // this.$refs['my_manage_job_model'].hide();
      // $('#my_manage_job_model').close();
      // $bvModal.hide('my_manage_job_model');
      // this.ft_feature_job = '';
    },
    // submitFeatured(id) {
    //     if(this.ft_feature_job == ''){
    //         this.error_message = 'Please select the featured job package';
    //         this.showAlert();
    //     }else{

    //     var message='Job featured successfully.';
    //         return this.$store.dispatch(POST_API, {
    //                 data:{
    //                 id: id,
    //                 ft_feature_job: this.ft_feature_job,
    //                 company_admin_id: this.company_admin_id ? this.company_admin_id:this.user_id,
    //                 company_id:this.company_id,
    //                 page: this.currentPage,
    //                 keyword: this.filter
    //                 },
    //                 api:'/api/featureJob'
    //             })
    //         .then(() => {
    //                 if (this.$store.getters.containsErrors) {
    //                     this.error_message = this.$store.getters.getErrors;
    //                     this.showAlert();
    //                     // if(this.error_message = 'Insufficient Coins for featuring the job.'){
    //                     //     this.$router.push({ name: 'elcoins' });
    //                     // }
    //                 } else {
    //                     this.showSuccessAlert(message);
    //                     this.changeTabStatus(this.activeTab);
    //                     this.getTotalCount();
    //                     window.location.replace('/v2/employer/manage-jobs');
    //                 }
    //             });
    //     }
    // },

    featureJob(id, company_id) {
      var msg = "Are you sure you want to set it to featured job?";
      var message = "Job featured successfully.";

      this.$swal({
        title: "Please Confirm",
        text: msg,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      })
        .then((result) => {
          if (result.value) {
            return this.$store
              .dispatch(POST_API, {
                data: {
                  id: id,
                  company_admin_id: this.company_admin_id ? this.company_admin_id : this.user_id,
                  company_id: company_id,
                  page: this.currentPage,
                  keyword: this.filter,
                },
                api: "/api/featureJob",
              })
              .then(() => {
                if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
                } else {
                  this.showSuccessAlert(message);
                  this.changeTabStatus(this.activeTab);
                  this.getTotalCount();
                  // window.location.replace('/v2/employer/manage-jobs');
                }
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    jobDetail(id, event) {
      if (event) {
        event.stopPropagation();
      }
      //  window.location.replace('/v2/employer/ft-jobdetail/'+ id);
      window.open("/v2/employer/ft-jobdetail/" + id, "_blank");
    },
    manageCandidate(id) {
      // window.location.replace('/v2/manage-candidates/'+id);
      window.open("/v2/manage-candidates/" + id, "_blank");
    },

    getTotalCount() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            userId: this.user_id,
            company_id: this.company_id,
            search_filter: this.filterSearch,
            start_date_filter: this.start_date_filter,
            end_date_filter: this.end_date_filter,
            category_id_filter: this.category_id_filter,
          },
          api: "/api/jobsTotalCount",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            // this.showAlert();
            return [];
          } else {
            this.inprogressCount = this.$store.getters.getResults.inprogressCount;
            this.upcomingCount = this.$store.getters.getResults.upcomingCount;
            this.expiredCount = this.$store.getters.getResults.expiredCount;
            this.inactiveCount = this.$store.getters.getResults.inactiveCount;
          }
        });
    },

    getprofile() {
      this.$store
        .dispatch(POST_API, {
          data: {
            id: this.user_id,
          },
          api: "/api/employee-profile",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;

            this.$swal({
              position: "center",
              // icon: 'error',
              imageUrl: "/assets/images/404_elcy.gif",
              customClass: {
                container: "mascot_error_container",
              },
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.remaining_feature_jobs =
              this.$store.getters.getResults.user.remaining_feature_jobs;
          }
        });
    },

    getprofileEmployer() {
      this.$store
        .dispatch(POST_API, {
          data: {
            user_id: this.company_admin_id ? this.company_admin_id : this.user_id,
          },
          api: "/api/get-employer-profile",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;

            this.$swal({
              position: "center",
              // icon: 'error',
              imageUrl: "/assets/images/404_elcy.gif",
              customClass: {
                container: "mascot_error_container",
              },
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.ft_1_day_free_featured_jobs =
              this.$store.getters.getResults.data.ft_1_day_free_featured_jobs;
            this.ft_7_day_free_featured_jobs =
              this.$store.getters.getResults.data.ft_7_day_free_featured_jobs;
            this.ft_14_day_free_featured_jobs =
              this.$store.getters.getResults.data.ft_14_day_free_featured_jobs;
            this.ft_30_day_free_featured_jobs =
              this.$store.getters.getResults.data.ft_30_day_free_featured_jobs;
            this.sett_ft_1_day_featured_job_coins =
              this.$store.getters.getResults.ft_1_day_featured_job_coins;
            this.sett_ft_7_day_featured_job_coins =
              this.$store.getters.getResults.ft_7_day_featured_job_coins;
            this.sett_ft_14_day_featured_job_coins =
              this.$store.getters.getResults.ft_14_day_featured_job_coins;
            this.sett_ft_30_day_featured_job_coins =
              this.$store.getters.getResults.ft_30_day_featured_job_coins;
            this.total_coins = this.$store.getters.getResults.total_coins;
            this.remaining_coins = this.$store.getters.getResults.total_coins;
          }
        });
    },
    permissionEM() {
      if (this.$store.getters.getCurrentUserPermission) {
        if (
          this.$store.getters.getCurrentUserPermission.data.length > 0 &&
          this.$store.getters.currentUser.is_company_head == "no" &&
          (this.$store.getters.currentUser.user_type_id == 3 ||
            this.$store.getters.currentUser.user_type_id == 4)
        ) {
          var menu = this.$store.getters.getCurrentUserPermission.data;
          this.permissionActionsFL = permission.getPermission(menu, "Manage Candidates");
        }
      }
    },
    showPermissionEMManageFL(action) {
      if (
        this.$store.getters.currentUser.is_company_head == "no" &&
        (this.$store.getters.currentUser.user_type_id == 3 ||
          this.$store.getters.currentUser.user_type_id == 4)
      ) {
        return this.permissionActionsFL.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
  },
  mounted() {
    //document.title = "EL Connect - Employer - Manage Jobs";
    this.user_id =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : "";
    this.company_id =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.company_id : "";
    this.company_admin_id =
      this.$store.getters.currentUser != null
        ? this.$store.getters.currentUser.company_admin_id
        : "";

    this.user_type_id = this.$store.getters.currentUser.user_type_id;
    this.permissionEM();
    Bus.$emit("active_users");
    Bus.$emit("get_em_job_count");
    this.getDropdownData();
    this.changeTabStatus(this.activeTab);
    this.getprofile();
    this.getprofileEmployer();
    this.getTotalCount();
    $(".dashboard-content-container .simplebar-scroll-content").animate(
      {
        scrollTop: 0,
      },
      "fast"
    );
  },
};
</script>

