<template>
  <div>
    <!-- Navigation-->

    <!-- <header class="employer_header employer_new_header" :style="{ backgroundImage: 'url('+webUrl+'assets/employerImages/header-bg.png'+')'}"> -->
    <header class="employer_header employer_new_header">
      <!-- <link rel="stylesheet" href="/assets/css/jobseeker2.css"> -->
      <!-- <link rel="stylesheet" href="/assets/css/jobseekers-new.css"> -->
      <!-- <link rel="stylesheet" href="/assets/css/employer-new.css"> -->
      <link rel="stylesheet" href="/assets/css/common-font-family.css" />
      <link rel="stylesheet" href="/assets/css/common-footer.css" />
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css"
      />
      <!-- Mobile -->
      <!-- <span class="header_elipse" :style="{ backgroundImage: 'url('+webUrl+'assets/jobeekerImages/Logo-Ellipse.png'+')'}">
            <b-link href="" :to="{name: 'homepage'}" class="logo my_custom_logo">
              <img :src="webUrl+'/assets/jobseekerImages/NewLogo.png'" class="jobseeker_logo">
            </b-link>
          </span> -->
      <div class="mobile_employer_header">
        <div class="mobile_logo_with_toggle">
          <b-link href="" :to="{ name: 'jobseekers2' }" class="logo my_custom_logo">
            <img :src="webUrl + 'logo.png'" class="jobseeker_logo" alt="Employer logo" />
          </b-link>
          <!-- </span> -->
          <span
            class="toggle_employer hamburger"
            id="hamburger-2"
            @click="mobileHumbergerMenuEmployer"
          >
            <!-- <img :src="webUrl+'assets/employerImages/Vector.png'" @click="mobileHumbergerMenuEmployer"> -->
            <span class="line"></span>
            <span class="line"></span>
            <span class="line"></span>
          </span>
        </div>
        <div class="mobile_navigation_menu_employer">
          <ul>
            <!-- <form>
                        <div class="account-type mobile_tab_display_block mobile_tab_hundered_width jobseeker_account_type">
                            <div class="mobile_tab_margin_right_zero">
                                <input type="radio" name="account-type-radio" id="freelancer-radio-mobile" value="5" @click="redirect()"  class="account-type-radio">
                                <label for="freelancer-radio-mobile" class="ripple-effect-dark login_forms jobseeker_radio_label">For Jobseeker</label>
                                </div>
                            <div class="mobile_tab_display_inline  mobile_tab_margin_right_zero employer_change_to_pink employer_top_div_tab">
                                <input type="radio" name="account-type-radio" id="employer-radio-mobile" value="3" checked="checked" class="account-type-radio">
                                    <label for="employer-radio-mobile" class="ripple-effect-dark login_forms employer_radio_label">For Employer</label>
                            </div>
                        </div>
                    </form> -->
            <!-- <li><a href="#">Home</a></li> -->
            <li class="home_link"><b-link href="" :to="{}">Home</b-link></li>
            <li @click="showHideMobileDropDownMenuEmployer">
              <p class="dropdown_custom_arrow main_menu_dropdown_mobile_view_employer">
                For Jobseeker<span class="material-icons-outlined">expand_more_outlined</span>
              </p>
              <div class="sub_menu_mobile_view_employer" style="display: none">
                <ul class="pb-0 pt-0">
                  <li><b-link href="" :to="{ name: 'jobseekers2' }">Jobseekers</b-link></li>
                  <li>
                    <b-link href="" :to="{ name: 'allDailyPaidJoblisting' }">Temp. Staff</b-link>
                  </li>
                  <li><b-link href="" :to="{ name: 'allJobsListing' }">Full Time Jobs</b-link></li>
                  <li><a :href="'resume-builder'" target="_blank">Resume Builder</a></li>
                </ul>
              </div>
            </li>
            <li><b-link href="" :to="{ name: 'employer2' }">For Employer</b-link></li>
            <li><b-link href="" :to="{ name: 'blog' }">Blog</b-link></li>
            <li><b-link href="" :to="{name: 'Rewards'}">Rewards</b-link></li>
            <li><b-link href="" :to="{ name: 'aboutUs' }">About Us</b-link></li>
            <li>
              <a href="#" @click="$bvModal.show('bv-modal-example')" class="mobile_login_register"
                >Log In / Sign Up</a
              >
            </li>
            <li><a :href="qrdata.link" target="_blank">Download Our App</a></li>
          </ul>
        </div>
      </div>
      <div class="container header_contaner_employer">
        <div class="row desktop_employer_header">
          <div class="col-xl-2 col-md-2">
            <b-link href="" :to="{ name: 'jobseekers2' }" class="logo my_custom_logo">
              <img :src="webUrl + 'logo.png'" class="employer_logo" alt="Employer logo" />
            </b-link>
          </div>
          <div class="col-xl-6 col-md-6">
            <!-- <div class="account-type mobile_tab_display_block mobile_tab_hundered_width employer_account_type">
                          <div class="mobile_tab_margin_right_zero">
                              <input type="radio" name="account-type-radio" id="freelancer-radio" value="5"  @click="redirect()" class="account-type-radio">
                              <label for="freelancer-radio" class="ripple-effect-dark login_forms jobseeker_radio_label" style="">For Jobseeker</label>
                            </div>
                            <div class="mobile_tab_display_inline mobile_tab_margin_top_12 mobile_tab_margin_right_zero employer_change_to_pink employer_top_div_tab">
                                  <input type="radio" name="account-type-radio" id="employer-radio" value="3" checked="checked"  class="account-type-radio ">
                                  <label for="employer-radio" class="ripple-effect-dark login_forms employer_radio_label" style="">For Employer</label>
                              </div>
                      </div> -->
            <JobseekerEmployerNavbarComponent />
          </div>
          <div class="col-xl-4 col-md-4 employer_login_btn_div">
            <!-- <button id="" class="jobseeker_login_btn" @click="$bvModal.show('bv-modal-example')">
                        <i class="icon-feather-log-in"></i> <span>Log In / Register</span>
                      </button> -->
            <button
              id=""
              class="common_employer_animation_btn employer_login_btn"
              @click="$bvModal.show('bv-modal-example')"
            >
              <div>
                <span class="first_animation_span"
                  ><i class="icon-feather-log-in white_feather"></i> Log In / Sign Up
                </span>
                <span class="second_animation_span"
                  ><i class="icon-feather-log-in"></i> Log In / Sign Up
                </span>
              </div>
            </button>
          </div>
        </div>
      </div>
      <div class="container my_custom_container_employer jobseeker_padding_top_zero">
        <div class="row">
          <div class="col-xl-7 col-md-7 desktop_employer_header">
            <h1 class="top_heading_h1_emp common_futura_bold_font">
              <span class="talent_employer">Hire Smarter, Grow Faster</span>
            </h1>
            <p class="para_jobseeker_head_emp">
              Unlock top talent, empower your business with ease.
            </p>
            <p class="para_jobseeker_head_emp">
              Find skilled professionals to boost your business, even on a flexible schedule.
            </p>
            <!-- <div class="get_started">
                           <button class="common_employer_animation_btn" @click="getStartedEMH()">
                            <div>
                              <span class="first_animation_span">Get Started</span>
                              <span class="second_animation_span">Get Started</span>
                            </div>
                            </button>
                        </div> -->
          </div>
          <div class="col-xl-7 col-md-7 mobile_employer_header">
            <h2 class="top_heading_h1_emp common_futura_bold_font">
              <span class="talent_employer">Hire Smarter, Grow Faster</span>
            </h2>
            <p class="para_jobseeker_head_emp">
              Unlock top talent, empower your business with ease. Find skilled professionals to
              boost your business, even on a flexible schedule.
            </p>
            <!-- <div class="get_started">
                           <button class="common_employer_animation_btn" @click="getStartedEMH()">
                            <div>
                              <span class="first_animation_span">Get Started</span>
                              <span class="second_animation_span">Get Started</span>
                            </div>
                            </button>
                        </div> -->
          </div>
          <div class="col-xl-5 col-md-5 start_hiring_scroll" id="mobile_tab_max_width_flex12">
            <b-form @submit.prevent="signup1" id="register-account-form" class="">
              <div class="reg_right_div">
                <div class="top_box_div">
                  <div class="form_box_employer">
                    <h2>Join Us Now as an Employer</h2>
                    <div class="row">
                      <div class="col-xl-12 col-md-12">
                        <input
                          type="text"
                          @click="hidePasswordRequirements"
                          v-model="comp_name1"
                          value=""
                          class="input-text with-border form-control required-field1"
                          placeholder="Company Name"
                        />
                      </div>
                      <!-- <div class="col-xl-6 col-md-6">
                                      <input type="text" v-model="fname1" value="" class="input-text with-border form-control required-field1" placeholder="Given Name">
                                  </div>
                                 <div class="col-xl-6 col-md-6">
                                      <input type="text" v-model="lname1" value="" class="input-text with-border form-control required-field1" placeholder="Surname">
                                  </div> -->
                      <div class="col-xl-12 col-md-12">
                        <input
                          type="text"
                          @click="hidePasswordRequirements"
                          v-model="full_name1"
                          value=""
                          name="login-email"
                          placeholder="Full Name"
                          class="input-text with-border form-control required-field1"
                        />
                      </div>
                      <div class="col-xl-12 col-md-12">
                        <input
                          type="text"
                          @click="hidePasswordRequirements"
                          v-model="semail1"
                          value=""
                          name="login-email"
                          placeholder="Email Address"
                          class="input-text with-border form-control required-field1"
                        />
                      </div>
                      <div class="col-xl-12 col-md-12">
                        <input
                          type="text"
                          @click="hidePasswordRequirements"
                          v-model="mobile_number1"
                          maxlength="8"
                          class="input-text with-border form-control required-field1"
                          placeholder="Mobile Number"
                        />
                      </div>
                      <div
                        class="col-xl-12 col-md-12 input-group password_margin_bottom_16 password_field"
                        id="show_hide_password_employer"
                      >
                        <div class="custom_form_control_input_employer">
                          <input
                            type="password"
                            @click="showPasswordRequirements"
                            class="form-control with-border required-field-password"
                            v-model="spassword1"
                            value=""
                            placeholder="Password"
                          />
                          <div class="input-group-prepend">
                            <div class="input-group-text">
                              <i
                                aria-hidden="true"
                                class="fa fa-eye-slash"
                                @click="employerPasswordClick"
                              ></i>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="password-requirement">Minimum 8 characters required for password</div> -->
                        <div
                          class="password_requirements_text common_z_index_zero desktop_margin_bottom_zero"
                          :style="{
                            backgroundImage:
                              'url(' + webUrl + 'assets/images/password-bg-img.png' + ')',
                          }"
                        >
                          <span
                            >Minimum 8 characters, must contain an uppercase letter, a lowercase
                            letter, at least 1 number and 1 special character
                          </span>
                        </div>
                      </div>
                      <!-- <div class="col-xl-12 col-md-12 input-group password_margin_bottom_16 password_field" id="show_hide_repeat_password_employer">
                                       <div class="custom_form_control_input_employer">
                                          <input type="password" v-model="srepassword1" class="form-control with-border required-field1" value=""  placeholder="Retype Password">
                                          <div class="input-group-prepend">
                                            <div class="input-group-text"><i aria-hidden="true" class="fa fa-eye-slash"  @click="employerRepasswordClick"></i></div>
                                          </div>
                                      </div>
                                  </div> -->
                      <!-- <div class="show_span">
                                <span class="error_x_white">Please enter Retype Password </span>
                            </div> -->
                      <div class="col-xl-12">
                        <div class="tacbox agree_note common_sgn_agree form_agree_note">
                          <input
                            id="checkbox"
                            type="checkbox"
                            value="true"
                            style="font-size: 8px"
                          />
                          <span for="" id="empcontr2">
                            I hereby agree to the
                            <!-- <a
                            href="https://elconnect-preprod.sgp1.digitaloceanspaces.com/EL%20Connect%20Employer%20T&C%202024.pdf"
                            target="_blank"
                            >Terms of Service</a
                          > -->
                            <a href="/em-terms-of-service" target="_blank">Terms of Service</a>
                            of EL Connect Pte Ltd.</span
                          >
                          <span for="" id="jobcontr2" style="display: none">
                            I hereby agree to the
                            <a href="/terms-of-service" target="_blank">Terms of Service</a> of EL
                            Connect Pte Ltd.</span
                          >
                        </div>
                      </div>
                      <div class="col-xl-12">
                        <div class="show_span">
                          <span class="error_x_white" id="errorIdSignup1"></span>
                        </div>
                      </div>

                      <div class="col-xl-12 col-md-12 register_btn_new_employer mt-0">
                        <button
                          class="create_account_btn"
                          type="submit"
                          id="reg_button"
                          @click="createAccount"
                        >
                          Create account
                        </button>
                        <button
                          form="login-form"
                          class="pink_loading_button button full-width ripple-effect model_btn"
                          style="display: none"
                          id="loaderreg"
                          disabled
                        >
                          <span class="fa fa-spinner fa-spin employer_spin" id="spinner"></span>
                          Loading
                        </button>
                        <div class="chat_div">
                          <b-link :href="'https://wa.link/k7p8ww'" target="_blank"
                            >CHAT WITH SALES</b-link
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-form>
            <div class="relativ_div_employer">
              <div class="penguin_image_employer">
                <img
                  :src="webUrl + 'assets/jobeekerImages/ELC_wavingpenguin.png'"
                  class="penguin_static_img"
                  alt="Mascot"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="home_mdl">
      <b-modal
        ref="bv-modal-example"
        id="bv-modal-example"
        title="BootstrapVue login"
        class="home_model"
        content-class="jobseekers_login_register_model"
      >
        <div slot="modal-title" class="custom_model_header">
          <div
            class="modal"
            id="myModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="myModalLabel"
            style="display: block"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="">
                  <div class="">
                    <div class="">
                      <div>
                        <!-- Nav tabs -->
                        <ul class="nav nav-tabs" role="tablist">
                          <li role="presentation" class="active" id="login_li">
                            <a
                              href="javascript:void(0);"
                              data-target="#login"
                              aria-controls="login"
                              role="tab"
                              data-toggle="tab"
                              >Login</a
                            >
                          </li>
                          <li role="presentation" id="sign_up_li">
                            <a
                              href="javascript:void(0);"
                              data-target="#register"
                              aria-controls="register"
                              role="tab"
                              data-toggle="tab"
                              >Register</a
                            >
                          </li>
                          <li>
                            <b-button
                              class="mt-3 custom_close_btn model_margin_left_zero"
                              block
                              @click="$bvModal.hide('bv-modal-example')"
                              >x</b-button
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Tab panes -->
        <div class="tab-content">
          <div role="tabpanel" class="tab-pane active" id="login">
            <div class="welcome-text remove_mrgn top_mrgn" v-if="user_type == 3">
              <h3>Welcome Back, Employer! <br />Ready to Find The Best Talent?</h3>
              <span
                >Don't have an account yet?
                <b-link
                  href="javascript:void(0);"
                  data-target="#register"
                  @click="jobseekers_register_click('register')"
                  aria-controls="register"
                  role="tab"
                  data-toggle="tab"
                  class="register_tab"
                  >Sign Up Now!</b-link
                >
              </span>
            </div>
            <div class="welcome-text remove_mrgn top_mrgn" v-else>
              <h3>Welcome Back, Jobseeker!<br />It's Great to Have You Here Again</h3>
              <span
                >Don't have an account yet?
                <b-link
                  href="javascript:void(0);"
                  data-target="#register"
                  @click="jobseekers_register_click('register')"
                  aria-controls="register"
                  role="tab"
                  data-toggle="tab"
                  class="register_tab"
                  >Sign Up Now!</b-link
                >
              </span>
            </div>
            <div class="account-type cst_type my_account_type type_in_mobile_tab">
              <div>
                <input
                  type="radio"
                  v-model="user_type"
                  value="5"
                  name="account-type-radios"
                  id="freelancer-radio"
                  @change="radioClickLogin"
                  checked="checked"
                  class="account-type-radio"
                />
                <label for="freelancer-radio" class="ripple-effect-dark mobile_radio login_forms">
                  <i class="icon-material-outline-account-circle"></i> Jobseeker</label
                >
              </div>
              <div class="employer_change_to_pink">
                <input
                  type="radio"
                  v-model="user_type"
                  value="3"
                  @change="radioClickLogin"
                  name="account-type-radi"
                  id="employer-radio"
                  class="account-type-radio"
                />
                <label for="employer-radio" class="ripple-effect-dark login_forms">
                  <i class="icon-material-outline-business-center"></i> Employer</label
                >
              </div>
            </div>
            <div class="login_forms">
              <b-form id="login-form" @submit.prevent="loginPopup" class="new_pop_up_frm">
                <div>
                  <div class="input-with-icon-left">
                    <i class="icon-material-baseline-mail-outline"></i>
                    <b-form-input
                      type="text"
                      v-model="emailLoginPopup"
                      @keyup.enter="loginPopup"
                      name="emailaddress"
                      id="emailaddress"
                      placeholder="Email Address / Mobile Number"
                      class="input-text with-border required-fieldLoginPopup"
                    />
                  </div>
                  <div class="show_span">
                    <span class="error_x_white">Please enter email / mobile number </span>
                  </div>
                </div>
                <div>
                  <div class="input-with-icon-left">
                    <i class="icon-material-outline-lock"></i>
                    <b-form-input
                      type="password"
                      @keyup.enter="loginPopup"
                      v-model="passwordLoginPopup"
                      name="password"
                      id="jobseekers_model_show_hide_password"
                      placeholder="Password"
                      class="input-text with-border required-fieldLoginPopup"
                    />
                    <div class="input-group-addon-custom">
                      <b-link @click="jobseekersModelPasswordClick"
                        ><i class="fa fa-eye-slash model_i" aria-hidden="true"></i
                      ></b-link>
                    </div>
                  </div>
                  <div class="show_span">
                    <span class="error_x_white">Please enter Password </span>
                  </div>
                </div>
                <b-link @click="clickForgotPassword($event)">Forgot Password?</b-link>

                <!-- <button type="submit" form="login-form" class="button full-width button-sliding-icon ripple-effect model_btn" id="login_button">Log In <i class="icon-material-outline-arrow-right-alt"></i></button> -->
                <!-- <button type="submit" form="login-form" class="button full-width  ripple-effect model_btn common_animation_btn" id="login_button">
                          <div><span class="first_animation_span">Log In</span><span class="second_animation_span">Log In</span></div>
                        </button> -->
                <div class="show_span">
                  <span class="error_x_white" id="errorIdLoginpopup"></span>
                </div>
                <button
                  v-if="user_type == 5"
                  type="submit"
                  form="login-form"
                  class="pop_up_animation_btn button full-width ripple-effect model_btn"
                  id="login_button"
                >
                  <div>
                    <span class="first_animation_span">Log In</span>
                    <span class="second_animation_span">Log In</span>
                  </div>
                </button>
                <button
                  v-else-if="user_type == 3"
                  type="submit"
                  form="login-form"
                  class="pop_up_animation_btn button full-width ripple-effect model_btn"
                  id="login_button"
                  style="border: 2px solid #ef1aa7 !important; background-color: #ef1aa7 !important;"
                >
                  <div>
                    <span class="first_animation_span">Log In</span>
                    <span class="second_animation_span" style="color: #ef1aa7 !important;">Log In</span>
                  </div>
                </button>
                <button
                  form="login-form"
                  class="button full-width ripple-effect model_btn"
                  style="display: none"
                  id="loaderlogin"
                  disabled
                >
                  <span class="fa fa-spinner fa-spin employer_spin" id="spinner"></span> Loading
                </button>
              </b-form>
            </div>
          </div>
          <div role="tabpanel" class="tab-pane" id="register">
            <div class="welcome-text remove_mrgn top_mrgn" v-if="user_type == 3">
              <h3 style="font-size: 22px">
                First Time Here? Let's Create Your Employer Account Now!
              </h3>
              <span
                >Already have an account?
                <b-link
                  href="javascript:void(0);"
                  data-target="#login"
                  @click="jobseekers_register_click('login')"
                  role="tab"
                  data-toggle="tab"
                  >Log In!</b-link
                ></span
              >
            </div>
            <div class="welcome-text remove_mrgn top_mrgn" v-else>
              <h3 style="font-size: 22px">Let's Create Your Account Now</h3>
              <span
                >Already have an account?
                <b-link
                  href="javascript:void(0);"
                  data-target="#login"
                  @click="jobseekers_register_click('login')"
                  role="tab"
                  data-toggle="tab"
                  >Log In!</b-link
                ></span
              >
            </div>
            <!-- <b-form @submit.prevent="signup"> -->
            <div class="account-type cst_type my_account_type type_in_mobile_tab">
              <div>
                <input
                  type="radio"
                  v-model="user_type"
                  @change="radioClickLogin"
                  value="5"
                  name="account-type-radio"
                  id="freelancer-radio"
                  checked="checked"
                  class="account-type-radio"
                />
                <label for="freelancer-radio" class="ripple-effect-dark mobile_radio login_forms">
                  <i class="icon-material-outline-account-circle"></i> Jobseeker</label
                >
              </div>
              <div class="employer_change_to_pink">
                <input
                  type="radio"
                  v-model="user_type"
                  @change="radioClickLogin"
                  value="3"
                  name="account-type-radio"
                  id="employer-radio"
                  class="account-type-radio"
                />
                <label for="employer-radio" class="ripple-effect-dark login_forms">
                  <i class="icon-material-outline-business-center"></i> Employer</label
                >
              </div>
            </div>
            <!-- <div class="account-type">
                            <div>
                                <input type="radio" v-model="suser_type" name="account-type-radio" id="freelancer-radio" checked="checked" value="5" class="account-type-radio">
                                <label for="freelancer-radio" class="ripple-effect-dark"><i class="icon-material-outline-account-circle"></i> Jobseeker</label>
                                    </div><div><input type="radio" v-model="suser_type" name="account-type-radio" id="employer-radio" value="3" class="account-type-radio">
                                    <label for="employer-radio" class="ripple-effect-dark">
                                        <i class="icon-material-outline-business-center"></i> Employer</label>

                            </div>
                        </div> -->

            <div class="login_forms">
              <b-form
                @submit.prevent="signupFun"
                id="register-account-form1"
                class="pop_up_frm new_pop_up_frm"
              >
                <div id="compName" v-if="user_type == 3">
                  <div class="input-with-icon-left">
                    <!-- <i class="icon-material-baseline-mail-outline common_profile_icon"></i> -->
                    <i class="material-icons-outlined common_profile_icon company_name_icon_sign_up"
                      >business_center_outlined</i
                    >
                    <b-form-input
                      type="text"
                      id="company_name"
                      v-model="comp_name"
                      value=""
                      name="login-email"
                      class="input-text with-border form-control required-fieldEmp"
                      placeholder="Company Name"
                      @click="hidePasswordRequirementsPopUP"
                    />
                  </div>
                  <div class="show_span">
                    <span class="error_x_white">Please enter Company Name </span>
                  </div>
                </div>
                <!-- <div class="account-type remove_mrgn input-with-icon-left mobile_tab_login_pp">
                                <div><div class="full_width_input"><i class="fa fa-user pop_up_user common_profile_icon" aria-hidden="true"></i><b-form-input id="fname" name="login-email" value=""  v-model="fname" type="text" placeholder="Given Name" class="input-text with-border form-control required-field"/>
                                </div>
                                    <div class="show_span">
                                    <span class="error_x_white">Please enter Given Name </span>
                                </div>
                                </div>

                                <div><div class="input-with-icon-left full_width_input"><i class="fa fa-user pop_up_user common_profile_icon" aria-hidden="true"></i><b-form-input id="lname" name="login-email" value="" type="text"  v-model="lname" placeholder="Surname" class="input-text with-border form-control required-field"/></div>
                                <div class="show_span">
                                <span class="error_x_white">Please enter Surname </span>
                            </div>
                            </div>
                            </div> -->

                <div>
                  <div class="input-with-icon-left">
                    <i class="icon-material-baseline-mail-outline common_profile_icon"></i>
                    <b-form-input
                      id="semail"
                      @click="hidePasswordRequirementsPopUP"
                      v-model="full_name"
                      value=""
                      name="login-email"
                      type="text"
                      placeholder="Full Name"
                      class="input-text with-border form-control required-field"
                    />
                  </div>
                  <div class="show_span">
                    <span class="error_x_white">Please enter Full Name </span>
                  </div>
                </div>

                <div>
                  <div class="input-with-icon-left">
                    <i class="icon-material-baseline-mail-outline common_profile_icon"></i>
                    <b-form-input
                      id="semail"
                      @click="hidePasswordRequirementsPopUP"
                      v-model="semail"
                      value=""
                      name="login-email"
                      type="text"
                      placeholder="Email Address"
                      class="input-text with-border form-control required-field"
                    />
                  </div>
                  <div class="show_span">
                    <span class="error_x_white">Please enter Email</span>
                  </div>
                </div>
                <div class="input-with-icon-left" v-if="user_type == 5">
                  <i class="material-icons-outlined common_profile_icon company_name_icon_sign_up">phone_iphone_outlined</i>
                  <b-form-input
                      type="text"
                      id="mobile"
                      v-model="smobile_number"
                      value=""
                      maxlength="8"
                      name="login-email"
                      class="input-text with-border required-field"
                      placeholder="Mobile Number"
                    />
                </div>
                <div class="show_span new_show_span">
                    <span class="error_x_white">Please enter Mobile Number </span>
                  </div>
                <div v-if="user_type == 3">
                  <div class="input-with-icon-left">
                    <i class="material-icons-outlined common_profile_icon company_name_icon_sign_up"
                      >phone_iphone_outlined</i
                    >
                    <b-form-input
                      id="rmobile_number"
                      @click="hidePasswordRequirementsPopUP"
                      v-model="rmobile_number"
                      maxlength="8"
                      value=""
                      name="login-email"
                      type="text"
                      placeholder="Mobile Number"
                      class="input-text with-border form-control required-fieldEmp"
                    />
                  </div>
                  <div class="show_span">
                    <span class="error_x_white">Please enter Mobile Number </span>
                  </div>
                </div>
                <div>
                  <div class="input-with-icon-left">
                    <i class="icon-material-outline-lock common_profile_icon"></i>
                    <b-form-input
                      id="jobseeker_model_sign_up_password_click"
                      @click="showPasswordRequirementsPopUp"
                      v-model="spassword"
                      value=""
                      name="signup-password"
                      type="password"
                      placeholder="Password"
                      class="model_margin input-text with-border form-control required-field"
                    />
                    <!-- <div class="password-requirement">Minimum 8 characters required for password</div> -->
                    <div class="input-group-addon-custom">
                      <span @click="jobseekerModelSignUpPasswordClick"
                        ><i
                          class="fa fa-eye-slash cusror_point sign_up_model_i"
                          aria-hidden="true"
                        ></i
                      ></span>
                    </div>
                  </div>
                  <div
                    class="password_requirements_text_pop_up common_z_index_zero desktop_margin_bottom_zero"
                    :style="{
                      backgroundImage: 'url(' + webUrl + 'assets/images/password-bg-img.png' + ')',
                    }"
                    style="display: none"
                  >
                    <span
                      >Minimum 8 characters, must contain an uppercase letter, a lowercase letter,
                      at least 1 number and 1 special character
                    </span>
                  </div>
                  <div class="show_span">
                    <span class="error_x_white">Please enter Password </span>
                  </div>
                </div>
                <div>
                  <!-- <div class="input-with-icon-left" ><i class="common_profile_icon icon-material-outline-lock"></i>
                            <b-form-input id="jobseeker_model_sign_up_retype_password_click" name="repeate-password" v-model="srepassword" value="" type="password" placeholder="Retype Password" class="input-text with-border form-control required-field"/>
                            <div class="input-group-addon-custom">
                                <b-link  @click="jobseekerModelSignUpRetypePasswordClick"><i class="fa fa-eye-slash sign_up_model_retype_i" aria-hidden="true"></i></b-link>
                            </div>
                            </div>
                                <div class="show_span">
                                <span class="error_x_white">Please enter Confirm Password </span>
                            </div> -->
                </div>
                <div class="" v-if="user_type == 5">
                  <div class="tacbox agree_note common_sgn_agree">
                    <input id="checkbox" type="checkbox" value="true" style="font-size: 8px" />
                    <span for="" id="empcontr" style="display: none">
                      I hereby agree to the
                      <!-- <a
                        href="https://elconnect-preprod.sgp1.digitaloceanspaces.com/EL%20Connect%20Employer%20T&C%202024.pdf"
                        target="_blank"
                        >Terms of Service</a
                      > -->
                      <a href="/em-terms-of-service" target="_blank">Terms of Service</a>
                      of EL Connect Pte Ltd.</span
                    >
                    <span for="" id="jobcontr">
                      I hereby agree to the
                      <a href="/terms-of-service" target="_blank">Terms of Service</a> of EL Connect
                      Pte Ltd.</span
                    >
                  </div>
                </div>
                <div class="" v-else>
                  <div class="tacbox agree_note common_sgn_agree">
                    <input id="checkbox" type="checkbox" value="true" style="font-size: 8px" />
                    <span for="" id="empcontr2">
                      I hereby agree to the
                      <!-- <a
                        href="https://elconnect-preprod.sgp1.digitaloceanspaces.com/EL%20Connect%20Employer%20T&C%202024.pdf"
                        target="_blank"
                        >Terms of Service</a
                      > -->
                      <a href="/em-terms-of-service" target="_blank">Terms of Service</a>
                      of EL Connect Pte Ltd.</span
                    >
                    <span for="" id="jobcontr2" style="display: none">
                      I hereby agree to the
                      <a href="/terms-of-service" target="_blank">Terms of Service</a> of EL Connect
                      Pte Ltd.</span
                    >
                  </div>
                </div>
                <!-- <div class="">
                                <div class="tacbox agree_note common_sgn_agree">
                                    <input id="checkbox" type="checkbox" value="true" style="font-size:8px;"/>
                                    <span for="" id="empcontr" style="display:none;"> I hereby agree to the <a href="https://elconnect-preprod.sgp1.digitaloceanspaces.com/EL%20Connect%20Employer%20T&C%202024.pdf" target="_blank">Terms of Service</a> of EL Connect Pte Ltd.</span>
                                    <span for="" id="jobcontr"> I hereby agree to the <a href="/terms-of-service" target="_blank">Terms of Service</a> of EL Connect Pte Ltd.</span>

                                </div>
                            </div> -->

                <!-- <button type="submit" form="register-account-form" class="button full-width button-sliding-icon ripple-effect model_btn" id="reg_button">Register <i class="icon-material-outline-arrow-right-alt"></i> </button> -->
                <!-- <button type="submit" form="register-account-form" class="button full-width ripple-effect model_btn common_animation_btn" id="reg_button">
                            <div><span class="first_animation_span">Register</span><span class="second_animation_span">Register</span></div>
                         </button> -->
                <div class="show_span">
                  <span class="error_x_white" id="errorIdSignup"></span>
                </div>
                <button
                  v-if="user_type == 5"
                  type="submit"
                  form="register-account-form1"
                  class="pop_up_animation_btn button full-width ripple-effect model_btn"
                  id="reg_button"
                  @click="createAccountPopUP"
                >
                  <div>
                    <span class="first_animation_span">Register</span>
                    <span class="second_animation_span">Register</span>
                  </div>
                </button>

                <button
                  v-else
                  type="submit"
                  form="register-account-form1"
                  class="pop_up_animation_btn button full-width ripple-effect model_btn"
                  id="reg_button"
                  @click="createAccountPopUP"
                  style="border: 2px solid #ef1aa7 !important; background-color: #ef1aa7 !important;"
                >
                  <div>
                    <span class="first_animation_span">Register</span>
                    <span class="second_animation_span" style="color: #ef1aa7 !important;">Register</span>
                  </div>
                </button>

                <button
                  form="register-account-form"
                  class="button full-width ripple-effect model_btn"
                  style="display: none"
                  id="loaderreg"
                  disabled
                >
                  <span class="fa fa-spinner fa-spin employer_spin" id="spinner"></span> Loading
                </button>
              </b-form>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
    <!-- <b-modal ref="job-preference-modal" title="Choose your Posting Preference" id="background_custom_class_for_banner" hide-footer centered hide-header :no-close-on-backdrop="noClose" content-class="common_model_header common_model_title" modal-class="background_custom_class survey_model">
            <b-form>
                <template>
                        <div class="row">
                            <div class="mobile_tab_col_four col-xl-4 col-md-4 desktop_margin_bottom_zero  single_profile_eye" id="show_hide_current_password">
                                <b-form-group id="input-group-6" label="Welcome! To get started, please choose your primary job posting type. Let's tailor your experience to match your hiring needs" class="model_margin_bottom_zero required model_date_picker">
                                    <b-form-checkbox value="yes" unchecked-value="no" v-model = 'job_preference_dp' class="opacity_unset"><strong>Daily Paid</strong></b-form-checkbox>
                                    <b-form-checkbox value="yes" unchecked-value="no" v-model = 'job_preference_ft' class="opacity_unset"><strong>Full Time</strong></b-form-checkbox>
                                </b-form-group>
                            </div>
                        </div>
                        <div class="row">
                        <div class="col-xl-4 col-md-4 text-md-right new_text_align_end common_crop_reset_btn survey_model_btn">
                            <b-button variant="success" class="btn green-border-btntext-uppercase x-md btn-shadow" :class="job_preference_dp == 'yes' || job_preference_ft == 'yes'? '' : 'disabled'" @click.prevent="updateUserJobPreference">Submit</b-button>
                        </div>
                        </div>
                    </template>
            </b-form>
        </b-modal> -->
    <b-modal
      ref="job-preference-modal"
      title="Choose your Posting Preference"
      id="background_custom_class_for_banner"
      hide-footer
      centered
      hide-header
      :no-close-on-backdrop="noClose"
      content-class="common_model_header common_model_title"
      modal-class="background_custom_class job_prefrence_model"
    >
      <b-form>
        <template>
          <div class="row">
            <div class="col-xl-12 single_profile_eye" id="show_hide_current_password">
              <h2>Choose one or both services</h2>
              <h3>Welcome! Let's tailor your experience to match your hiring needs</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-6 col-md-6" @click="selectJobTypeDP">
              <!-- <input v-model = 'job_preference_dp'> -->
              <!-- <b-form-input>hello</b-form-input> -->
              <b-form-group
                id="input-group-6"
                label=""
                class="job_type_dp left_side_checkbox model_margin_bottom_zero required model_date_picker"
              >
                <img
                  src="https://elconnect-preprod.sgp1.digitaloceanspaces.com/images/dp_job.png"
                />
                <!-- <b-form-checkbox value="yes" unchecked-value="no" v-model = 'job_preference_dp' class="opacity_unset"></b-form-checkbox> -->
                <strong>Temporary Staffing Services</strong>
                <p>Ideal for short-term, part-time, seasonal demands & project-based staffing</p>
              </b-form-group>
            </div>
            <div class="col-xl-6 col-md-6" @click="selectJobTypeFT">
              <b-form-group
                id="input-group-6"
                label=""
                class="job_type_ft right_side_checkbox model_margin_bottom_zero required model_date_picker"
              >
                <img
                  src="https://elconnect-preprod.sgp1.digitaloceanspaces.com/images/ft_job.png"
                />
                <!-- <b-form-checkbox value="yes" unchecked-value="no" v-model = 'job_preference_ft' class="opacity_unset"></b-form-checkbox> -->
                <strong>Full Time Posting</strong>
                <p>Permanent employment for your business hiring needs</p>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-12 col-md-12 text-md-center common_crop_reset_btn mt-5">
              <b-button
                variant="success"
                class="btn green-border-btntext-uppercase x-md btn-shadow"
                :class="job_preference_dp == 'yes' || job_preference_ft == 'yes' ? '' : 'disabled'"
                @click.prevent="updateUserJobPreference"
                >Submit</b-button
              >
            </div>
          </div>
        </template>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import { POST_API, LOGIN, PERMISSION_DATA, LOGOUT } from "../../../store/actions.type";
import JobseekerEmployerNavbarComponent from "../JobseekerEmployerNavbarComponent.vue";
export default {
  data() {
    return {
      qrdata: [],
      phone_number: "",
      company_name: "",
      success_message: "",
      emailLoginPopup: "",
      passwordLoginPopup: "",
      fname: "",
      comp_name: "",
      lname: "",
      full_name: "",

      semail: "",
      smobile_number:"",
      spassword: "",
      srepassword: "",
      fname1: "",
      comp_name1: "",
      lname1: "",
      full_name1: "",

      semail1: "",
      mobile_number1: "",
      rmobile_number: "",
      spassword1: "",
      srepassword1: "",
      webUrl: process.env.VUE_APP_URL,
      user_type: "3",
      user_type_id: "",
      // showMobileMenu: false,
      noClose: true,
      currentSignupUserID: "",
      job_preference_update: "",
      job_preference_dp: "no",
      job_preference_ft: "no",
      login_api: "slogin1",
    };
  },
  methods: {
    selectJobTypeDP() {
      if (this.job_preference_dp == "no") {
        $(".job_type_dp").addClass("add_border_dp");
        this.job_preference_dp = "yes";
      } else {
        $(".job_type_dp").removeClass("add_border_dp");
        this.job_preference_dp = "no";
      }
    },
    selectJobTypeFT() {
      if (this.job_preference_ft == "no") {
        $(".job_type_ft").addClass("add_border_dp");
        this.job_preference_ft = "yes";
      } else {
        $(".job_type_ft").removeClass("add_border_dp");
        this.job_preference_ft = "no";
      }
    },
    showHideMobileDropDownMenuEmployer() {
      $(".sub_menu_mobile_view_employer").slideToggle("fast", "swing");
    },
    createAccount() {
      $(".password_requirements_text").hide();
      $(".model_margin").removeClass("model_margin_bottom_zero");
    },
    showPasswordRequirements() {
      $(".password_requirements_text").show();
      $(".password_margin").addClass("desktop_margin_bottom_zero");
      $(".model_margin").addClass("model_margin_bottom_zero");
      $("#errorIdSignup1").hide();
      $("#errorIdSignup").hide();
    },
    hidePasswordRequirements() {
      $(".password_margin").removeClass("desktop_margin_bottom_zero");
      $(".model_margin").removeClass("model_margin_bottom_zero");
      $(".password_requirements_text").hide();
    },
    createAccountPopUP() {
      $(".password_requirements_text_pop_up").hide();
      $(".model_margin").removeClass("model_margin_bottom_zero");
    },
    showPasswordRequirementsPopUp() {
      $(".password_requirements_text_pop_up").show();
      $(".model_margin").addClass("model_margin_bottom_zero");
      $("#errorIdSignup").hide();
    },
    hidePasswordRequirementsPopUP() {
      $(".model_margin").removeClass("model_margin_bottom_zero");
      $(".password_requirements_text_pop_up").hide();
    },
    employerPasswordClick(event) {
      event.preventDefault();
      if ($("#show_hide_password_employer input").attr("type") == "text") {
        $("#show_hide_password_employer input").attr("type", "password");
        $("#show_hide_password_employer i").addClass("fa-eye-slash");
        $("#show_hide_password_employer i").removeClass("fa-eye");
      } else if ($("#show_hide_password_employer input").attr("type") == "password") {
        $("#show_hide_password_employer input").attr("type", "text");
        $("#show_hide_password_employer i").removeClass("fa-eye-slash");
        $("#show_hide_password_employer i").addClass("fa-eye");
      }
    },
    getQRData() {
      return this.$store
        .dispatch(POST_API, {
          api: "/api/qr-data",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            return [];
          } else {
            if (this.$store.getters.getResults.data) {
              this.qrdata = this.$store.getters.getResults.data;
            } else {
            }
          }
        });
    },
    jobseekers_register_click(type) {
      if (type == "login") {
        $("#sign_up_li").removeClass("active");
        $("#login_li").addClass("active");
      } else {
        $("#sign_up_li").addClass("active");
        $("#login_li").removeClass("active");
      }
    },
    mobileHumbergerMenuEmployer() {
      $(".mobile_navigation_menu_employer").slideToggle();
      $(".mobile_login_register").click(function () {
        $(".mobile_navigation_menu_employer").hide();
      });
    },
    redirect() {
      this.$router.push({ name: "jobseekers2" });
    },
    jobseekerModelSignUpPasswordClick(event) {
      event.preventDefault();
      if ($("#jobseeker_model_sign_up_password_click").attr("type") == "text") {
        $("#jobseeker_model_sign_up_password_click").attr("type", "password");
        $(".sign_up_model_i").addClass("fa-eye-slash");
        $(".sign_up_model_i").removeClass("fa-eye");
      } else if ($("#jobseeker_model_sign_up_password_click").attr("type") == "password") {
        $("#jobseeker_model_sign_up_password_click").attr("type", "text");
        $(".sign_up_model_i").removeClass("fa-eye-slash");
        $(".sign_up_model_i").addClass("fa-eye");
      }
    },
    jobseekerModelSignUpRetypePasswordClick(event) {
      event.preventDefault();
      if ($("#jobseeker_model_sign_up_retype_password_click").attr("type") == "text") {
        $("#jobseeker_model_sign_up_retype_password_click").attr("type", "password");
        $(".sign_up_model_retype_i").addClass("fa-eye-slash");
        $(".sign_up_model_retype_i").removeClass("fa-eye");
      } else if ($("#jobseeker_model_sign_up_retype_password_click").attr("type") == "password") {
        $("#jobseeker_model_sign_up_retype_password_click").attr("type", "text");
        $(".sign_up_model_retype_i").removeClass("fa-eye-slash");
        $(".sign_up_model_retype_i").addClass("fa-eye");
      }
    },
    jobseekersModelPasswordClick(event) {
      event.preventDefault();
      if ($("#jobseekers_model_show_hide_password").attr("type") == "text") {
        $("#jobseekers_model_show_hide_password").attr("type", "password");
        $(".model_i").addClass("fa-eye-slash");
        $(".model_i").removeClass("fa-eye");
      } else if ($("#jobseekers_model_show_hide_password").attr("type") == "password") {
        $("#jobseekers_model_show_hide_password").attr("type", "text");
        $(".model_i").removeClass("fa-eye-slash");
        $(".model_i").addClass("fa-eye");
      }
    },
    jobseekerPasswordClick(event) {
      event.preventDefault();
      if ($("#show_hide_password_jobseeker input").attr("type") == "text") {
        $("#show_hide_password_jobseeker input").attr("type", "password");
        $("#show_hide_password_jobseeker i").addClass("fa-eye-slash");
        $("#show_hide_password_jobseeker i").removeClass("fa-eye");
      } else if ($("#show_hide_password_jobseeker input").attr("type") == "password") {
        $("#show_hide_password_jobseeker input").attr("type", "text");
        $("#show_hide_password_jobseeker i").removeClass("fa-eye-slash");
        $("#show_hide_password_jobseeker i").addClass("fa-eye");
      }
    },
    jobseekerRepasswordClick(event) {
      event.preventDefault();
      if ($("#show_hide_repeat_password_jobseeker input").attr("type") == "text") {
        $("#show_hide_repeat_password_jobseeker input").attr("type", "password");
        $("#show_hide_repeat_password_jobseeker i").addClass("fa-eye-slash");
        $("#show_hide_repeat_password_jobseeker i").removeClass("fa-eye");
      } else if ($("#show_hide_repeat_password_jobseeker input").attr("type") == "password") {
        $("#show_hide_repeat_password_jobseeker input").attr("type", "text");
        $("#show_hide_repeat_password_jobseeker i").removeClass("fa-eye-slash");
        $("#show_hide_repeat_password_jobseeker i").addClass("fa-eye");
      }
    },
    showMobileMenu() {
      // $(".mobile_nav").toggleClass("active");
      $(".mobile_nav").slideToggle("slow");
    },
    navMenu() {
      // $(".mobile_nav").removeClass("active");
      $(".mobile_nav").slideUp("slow");
    },
    getStartedEMH() {
      // console.log('gg')
      document
        .querySelector("#mobile_tab_max_width_flex1")
        .scrollIntoView({ behavior: "smooth", block: "center" });
    },
    getMenuPermission() {
      this.$store
        .dispatch(PERMISSION_DATA, {
          data: {
            user_id: this.$store.getters.currentUser.id,
          },
          api: "/api/emp-menu-permissions",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            // console.log(this.$store.getters.getResults.data.full_name)
            this.menus = this.$store.getters.getResults.data;
            // JwtService.setMenuPermission(JSON.stringify(this.menus));
            // window.location.replace('/admin/dashboard');
            if (
              this.is_company_head == "no" &&
              (this.user_type_id == "3" || this.user_type_id == "4")
            ) {
              //commented on 26-12-23
              // if(this.account_verification_status == 'completed'){
              //     window.location.replace('/v2/employer/dp-job-manage');
              // }else if(this.ft_account_status == 'completed'){
              //     window.location.replace('/v2/employer/manage-jobs');
              // }else{
              //     window.location.replace('/v2/employer/om-supervisor-profile');
              // }
              // //commented on 26-12-23
              // window.location.replace('/v2/employer/dashboard');
              if (this.ft_account_status == "completed") {
                window.location.replace("/v2/employer/dashboard");
              } else {
                window.location.replace("/v2/employer/om-supervisor-profile");
              }
            } else if (this.user_type_id == "3" && this.is_company_head == "yes") {
              //commented on 26-12-23
              // if(this.account_verification_status == 'completed'){
              //     window.location.replace('/v2/employer/dp-job-manage');
              // }else if(this.ft_account_status == 'completed'){
              //     window.location.replace('/v2/employer/manage-jobs');
              // }else{
              //     window.location.replace('/v2/employer/employer-profile');
              // }
              //commented on 26-12-23
              //   window.location.replace("/v2/employer/dashboard");
              if (this.ft_account_status == "completed") {
                window.location.replace("/v2/employer/dashboard");
              } else {
                window.location.replace("/v2/employer/employer-profile");
              }
            } else if (this.user_type_id == "5") {
              window.location.replace("/v2/jobseeker/jobseeker-profile");
            } else {
              window.location.replace("/v2/admin/dashboard");
            }
          }
        });
    },
    radioClickLogin() {
      if (this.user_type == 5) {
        $("#empcontr").hide();
        $("#jobcontr").show();
      } else {
        $("#jobcontr").hide();
        $("#empcontr").show();
      }
      $(".login_forms").fadeOut();
      $(".login_forms").fadeIn(200);
      // $(".login_forms").css({"position":"relative","opacity": 0, "left":"+=100"});
      // $(".login_forms").animate({left:0, opacity:1},500);
    },
    loginPopup() {
      $("#login_button").hide();
      $("#loaderlogin").show();
      var err = false;

      $(".required-fieldLoginPopup").each(function () {
        if ($(this).val() == "" || $(this).val().trim().length === 0) {
          err = true;
          $(this).addClass("error");
          $(this).parent().parent().find(".required_content").addClass("show");
          $(this).parent().parent().find(".error_x_white").addClass("show");
        } else {
          $(this).removeClass("error");
          $(this).parent().parent().find(".required_content").removeClass("show");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        }
      });

      setTimeout(function () {
        $(".second_indiv_step_wrap .error").first().focus();

        $(".error").unbind("keypress");
        $(".error").bind("keypress", function () {
          $(this).removeClass("error");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        });
      }, 100);
      if (err == false) {
        this.$store
          .dispatch(LOGIN, {
            data: {
              emailormobile: this.emailLoginPopup,
              password: this.passwordLoginPopup,
              user_type: this.user_type,
              role: "user",
              login_on: "web",
              popup: true,
            },
            api: "/api/login",
          })
          .then(() => {
            $("#login_button").show();
            $("#loaderlogin").hide();
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              console.log(this.error_message, "gdfgdf");
              if (this.error_message) {
                document.getElementById("errorIdLoginpopup").classList.add("show");
                document.getElementById("errorIdLoginpopup").textContent = this.error_message;
              }
            } else {
              this.$bvModal.hide("bv-modal-example");
              document.getElementById("errorIdLoginpopup").classList.remove("show");
              document.getElementById("errorIdLoginpopup").value = "";
              this.$swal({
                position: "center",
                icon: "success",
                iconColor: "#4c8bf5",
                title: "Login successfully.",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                this.is_company_head = this.$store.getters.currentUser.is_company_head;
                this.user_type_id = this.$store.getters.currentUser.user_type_id;
                this.account_verification_status =
                  this.$store.getters.currentUser.account_verification_status;
                this.ft_account_status = this.$store.getters.currentUser.ft_account_status;
                this.getMenuPermission();
                // if(this.is_company_head == 'no' && (this.user_type_id == '3' || this.user_type_id == '4')){
                //     this.getMenuPermission();
                //     if(this.account_verification_status == 'completed'){
                //         window.location.replace('/v2/employer/dp-job-manage');
                //     }else if(this.ft_account_status == 'completed'){
                //         window.location.replace('/v2/employer/manage-jobs');
                //     }else{
                //         window.location.replace('/v2/employer/om-supervisor-profile');
                //     }
                //     // window.location.replace('/v2/employer/om-supervisor-profile');
                // }else if(this.user_type_id == '3' && this.is_company_head == 'yes'){
                //     this.getMenuPermission();
                //     if(this.account_verification_status == 'completed'){
                //         window.location.replace('/v2/employer/dp-job-manage');
                //     }else if(this.ft_account_status == 'completed'){
                //         window.location.replace('/v2/employer/manage-jobs');
                //     }else{
                //         window.location.replace('/v2/employer/employer-profile');
                //     }
                //     // window.location.replace('/v2/employer/employer-profile');
                // }else if(this.user_type_id == '5'){
                //     window.location.replace('/v2/jobseeker/jobseeker-profile');
                // }else{
                //     window.location.replace('/v2/admin/dashboard');
                // }
              });
            }
          });
      } else {
        $("#login_button").show();
        $("#loaderlogin").hide();
      }
    },
    signupFun() {
      if (this.user_type == 3) {
        this.signup();
      } else {
        this.signupFL();
      }
    },
    signup() {
      console.log("vvvvv");
      var err = false;
      $("#reg_button").hide();
      $("#loaderreg").show();

      $(".required-field").each(function () {
        if ($(this).val() == "" || $(this).val().trim().length === 0) {
          err = true;
          $(this).addClass("error");
          $(this).parent().parent().find(".required_content").addClass("show");
          $(this).parent().parent().find(".error_x_white").addClass("show");
        } else {
          $(this).removeClass("error");
          $(this).parent().parent().find(".required_content").removeClass("show");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        }
      });

      setTimeout(function () {
        $(".second_indiv_step_wrap .error").first().focus();

        $(".error").unbind("keypress");
        $(".error").bind("keypress", function () {
          $(this).removeClass("error");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        });
      }, 100);
      // if ($("#checkbox:checked").val() != "true" && err == false) {
      //   this.$bvModal.hide("bv-modal-example");
      //   err = true;
      //   this.$swal({
      //     position: "center",
      //     icon: "error",
      //     title: "Please accept terms and condition first.",
      //     showConfirmButton: false,
      //     timer: 1500,
      //   });
      // }
      if ($("#checkbox:checked").val() != "true" && err == false) {
        err = true;
        document.getElementById("errorIdSignup").classList.add("show");
        $("#errorIdSignup").show();
        document.getElementById("errorIdSignup").textContent =
          "Please accept terms and condition first.";
      }
      $(".required-fieldEmp").each(function () {
        if ($(this).val() == "" || $(this).val().trim().length === 0) {
          err = true;
          $(this).addClass("error");
          $(this).parent().parent().find(".required_content").addClass("show");
          $(this).parent().parent().find(".error_x_white").addClass("show");
        } else {
          $(this).removeClass("error");
          $(this).parent().parent().find(".required_content").removeClass("show");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        }
      });

      setTimeout(function () {
        $(".second_indiv_step_wrap .error").first().focus();

        $(".error").unbind("keypress");
        $(".error").bind("keypress", function () {
          $(this).removeClass("error");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        });
      }, 100);
      // if($('#checkbox:checked').val() != "true" && err == false){
      //     err = true;
      //     document.getElementById("errorIdSignup").classList.add("show");
      //     document.getElementById("errorIdSignup").textContent = 'Please accept terms and condition first.';
      // }
      if (err == false) {
        document.getElementById("errorIdSignup").classList.remove("show");
        document.getElementById("errorIdSignup").value = "";
        const fullArr = this.full_name.split(" ");

        for (var i = 0; i < fullArr.length; i++) {
          fullArr[i] = fullArr[i].charAt(0).toUpperCase() + fullArr[i].slice(1);
        }
        this.lname = fullArr[0] ? fullArr[0] : "";

        fullArr.shift();

        this.fname = fullArr.length > 0 ? fullArr.join(" ") : "";

        this.$store
          .dispatch(POST_API, {
            data: {
              first_name: this.fname,
              last_name: this.lname,
              full_name: this.full_name,

              comp_name: this.comp_name,
              mobile_number: this.rmobile_number,
              email: this.semail,
              password: this.spassword,
              user_type: this.user_type,
              repassword: this.spassword,
              register_from: "web",
              popup: true,
              // checkbox: true,
              checkbox: $("#checkbox:checked").val() == "true" ? true : false,
            },
            api: "/api/signup",
          })
          .then(() => {
            //   $('#reg_button').show();
            //   $('#loaderreg').hide();
            if (this.$store.getters.containsErrors) {
              $("#reg_button").show();
              $("#loaderreg").hide();
              this.error_message = this.$store.getters.getErrors;
              this.showDismissibleAlert = true;
              document.getElementById("errorIdSignup").classList.add("show");
              $("#errorIdSignup").show();
              document.getElementById("errorIdSignup").textContent = this.error_message;
            } else {
              document.getElementById("errorIdSignup").classList.remove("show");
              document.getElementById("errorIdSignup").value = "";
              this.login_api = "slogin";
              this.currentSignupUserID = this.$store.getters.getResults.user_id;
              this.$bvModal.hide("bv-modal-example");
              this.$refs["job-preference-modal"].show();
              //   this.$swal({
              //     title: '',
              //     html:"<b class='profile_upadte_pop_up_txt'>Profile Updated Successfully</b>" +
              //         "<center><p class='welcome_txt_new_pop_up'>Welcome! To get started, please choose your primary job posting type. Let's tailor your experience to match your hiring needs</p>",
              //     type: 'success',
              //     icon: 'success',
              //     iconColor:'#4c8bf5',
              //     showCancelButton: false,
              //     showDenyButton: true,
              //     confirmButtonColor: '#3085d6',
              //     denyButtonColor: '#cccccc',
              //     cancelButtonColor: '#d33',
              //     confirmButtonText: 'Daily Paid',
              //     denyButtonText: `Full Time`,
              //     customClass: {
              //         container: 'new_register_user_emp',
              //     },
              //     }).then(res => {
              //         this.currentSignupUserID = this.$store.getters.getResults.user_id
              //         if (res.isConfirmed) {
              //             this.updateUserJobPreference('daily_paid',this.currentSignupUserID);
              //             this.slogin();
              //         } else if (res.isDenied) {
              //             this.updateUserJobPreference('full_time',this.currentSignupUserID);
              //             this.slogin();
              //         }else{
              //             this.slogin();
              //         }
              //     })
              //   this.slogin();
            }
          });
      } else {
        $("#reg_button").show();
        $("#loaderreg").hide();
      }
    },
    signupFL() {
      console.log("vvvvvFL");
      var err = false;
      $("#reg_button").hide();
      $("#loaderreg").show();

      $(".required-field").each(function () {
        if ($(this).val() == "" || $(this).val().trim().length === 0) {
          err = true;
          $(this).addClass("error");
          $(this).parent().parent().find(".required_content").addClass("show");
          $(this).parent().parent().find(".error_x_white").addClass("show");
        } else {
          $(this).removeClass("error");
          $(this).parent().parent().find(".required_content").removeClass("show");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        }
      });

      setTimeout(function () {
        $(".second_indiv_step_wrap .error").first().focus();

        $(".error").unbind("keypress");
        $(".error").bind("keypress", function () {
          $(this).removeClass("error");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        });
      }, 100);
      // if ($("#checkbox:checked").val() != "true" && err == false) {
      //   this.$bvModal.hide("bv-modal-example");
      //   err = true;
      //   this.$swal({
      //     position: "center",
      //     icon: "error",
      //     title: "Please accept terms and condition first.",
      //     showConfirmButton: false,
      //     timer: 1500,
      //   });
      // }
      if ($("#checkbox:checked").val() != "true" && err == false) {
        err = true;
        document.getElementById("errorIdSignup").classList.add("show");
        $("#errorIdSignup").show();
        document.getElementById("errorIdSignup").textContent =
          "Please accept terms and condition first.";
      }
      console.log(err, "err");

      if (err == false) {
        document.getElementById("errorIdSignup").classList.remove("show");
        document.getElementById("errorIdSignup").value = "";
        const fullArr = this.full_name.split(" ");

        for (var i = 0; i < fullArr.length; i++) {
          fullArr[i] = fullArr[i].charAt(0).toUpperCase() + fullArr[i].slice(1);
        }
        this.lname = fullArr[0] ? fullArr[0] : "";

        fullArr.shift();

        this.fname = fullArr.length > 0 ? fullArr.join(" ") : "";

        this.$store
          .dispatch(POST_API, {
            data: {
              first_name: this.fname,
              last_name: this.lname,
              full_name: this.full_name,

              //   comp_name: this.comp_name,
                mobile_number: this.smobile_number,
              email: this.semail,
              password: this.spassword,
              user_type: this.user_type,
              repassword: this.spassword,
              register_from: "web",
              popup: true,
              checkbox: $("#checkbox:checked").val() == "true" ? true : false,
            },
            api: "/api/signup",
          })
          .then(() => {
            //   $('#reg_button').show();
            //   $('#loaderreg').hide();
            if (this.$store.getters.containsErrors) {
              $("#reg_button").show();
              $("#loaderreg").hide();
              this.error_message = this.$store.getters.getErrors;
              this.showDismissibleAlert = true;
              document.getElementById("errorIdSignup").classList.add("show");
              $("#errorIdSignup").show();
              document.getElementById("errorIdSignup").textContent = this.error_message;
            } else {
              document.getElementById("errorIdSignup").classList.remove("show");
              document.getElementById("errorIdSignup").value = "";
              this.slogin();
            }
          });
      } else {
        $("#reg_button").show();
        $("#loaderreg").hide();
      }
    },
    signup1() {
      console.log("fhfhfg");

      var err = false;
      $("#reg_button").hide();
      $("#loaderreg").show();

      $(".required-field1").each(function () {
        if ($(this).val() == "" || $(this).val().trim().length === 0) {
          err = true;
          $(this).addClass("error-jbs");
          // $(this).parent().parent().find('.required_content').addClass('show');
          // $(this).parent().parent().find('.error_x_white').addClass('show');
        } else {
          $(this).removeClass("error-jbs");
          // $(this).parent().parent().find('.required_content').removeClass('show');
          // $(this).parent().parent().find('.error_x_white').removeClass('show');
        }
      });
      $(".required-field-password").each(function () {
        if ($(this).val() == "" || $(this).val().trim().length === 0) {
          err = true;
          $(this).parent().addClass("error-jbs");
          // $(this).parent().parent().find('.required_content').addClass('show');
          // $(this).parent().parent().find('.error_x_white').addClass('show');
        } else {
          $(this).parent().removeClass("error-jbs");
          // $(this).parent().parent().find('.required_content').removeClass('show');
          // $(this).parent().parent().find('.error_x_white').removeClass('show');
        }
      });

      setTimeout(function () {
        $(".second_indiv_step_wrap .error-jbs").first().focus();

        $(".error-jbs").unbind("keypress");
        $(".error-jbs").bind("keypress", function () {
          $(this).removeClass("error-jbs");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        });
      }, 100);
      if ($("#checkbox:checked").val() != "true" && err == false) {
        err = true;
        this.$swal({
          position: "center",
          icon: "error",
          title: "Please accept terms and condition first.",
          showConfirmButton: false,
          timer: 1500,
        });
      }
      // if($('#checkbox:checked').val() != "true" && err == false){
      //     err = true;
      //     document.getElementById("errorIdSignup1").classList.add("show");
      //     document.getElementById("errorIdSignup1").textContent = 'Please accept terms and condition first.';
      // }
      if (err == false) {
        document.getElementById("errorIdSignup1").classList.remove("show");
        document.getElementById("errorIdSignup1").value = "";
        const fullArr = this.full_name1.split(" ");

        for (var i = 0; i < fullArr.length; i++) {
          fullArr[i] = fullArr[i].charAt(0).toUpperCase() + fullArr[i].slice(1);
        }

        this.lname1 = fullArr[0];

        fullArr.shift();

        this.fname1 = fullArr.join(" ");
        this.$store
          .dispatch(POST_API, {
            data: {
              //   first_name: 'Demo',
              //   last_name: 'Demo',
              first_name: this.fname1,
              last_name: this.lname1,
              full_name: this.full_name1,

              comp_name: this.comp_name1,
              email: this.semail1,
              mobile_number: this.mobile_number1,
              password: this.spassword1,
              user_type: this.user_type,
              repassword: this.spassword1,
              register_from: "web",
              is_inquiry: "yes",
              popup: true,
              //   checkbox:$('#checkbox:checked').val() == 'true' ? true : false,
              checkbox: true,
            },
            api: "/api/signup",
          })
          .then(() => {
            $("#reg_button").show();
            $("#loaderreg").hide();
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showDismissibleAlert = true;
              document.getElementById("errorIdSignup1").classList.add("show");
              $("#errorIdSignup1").show();
              document.getElementById("errorIdSignup1").textContent = this.error_message;
            } else {
              document.getElementById("errorIdSignup1").classList.remove("show");
              document.getElementById("errorIdSignup1").value = "";
              var userTypeId = {
                user_type_id: this.user_type,
              };
              localStorage.setItem("userTypeId", JSON.stringify(userTypeId));
              this.login_api = "slogin1";
              this.currentSignupUserID = this.$store.getters.getResults.user_id;
              this.$bvModal.hide("bv-modal-example");
              this.$refs["job-preference-modal"].show();
              //   this.$swal({
              //                 position: 'center',
              //                 icon: 'success',
              //                 iconColor:'#4c8bf5',
              //                 title: 'Register successfully.',
              //                 showConfirmButton: false,
              //                 timer: 1500
              //     }).then(() => {
              // this.$swal({
              //     title: '',
              //     html:"<b class='profile_upadte_pop_up_txt'>Profile Updated Successfully</b>" +
              //         "<center><p class='welcome_txt_new_pop_up'>Welcome! To get started, please choose your primary job posting type. Let's tailor your experience to match your hiring needs</p>",
              //     type: 'success',
              //     icon: 'success',
              //     iconColor:'#4c8bf5',
              //     showCancelButton: false,
              //     showDenyButton: true,
              //     confirmButtonColor: '#3085d6',
              //     denyButtonColor: '#cccccc',
              //     cancelButtonColor: '#d33',
              //     confirmButtonText: 'Daily Paid',
              //     denyButtonText: `Full Time`,
              //     customClass: {
              //         container: 'new_register_user_emp',
              //     },
              //     }).then(res => {
              //         this.currentSignupUserID = this.$store.getters.getResults.user_id
              //         if (res.isConfirmed) {
              //             this.updateUserJobPreference('daily_paid',this.currentSignupUserID);
              //             this.slogin1();
              //         } else if (res.isDenied) {
              //             this.updateUserJobPreference('full_time',this.currentSignupUserID);
              //             this.slogin1();
              //         }else{
              //             this.slogin1();
              //         }
              // })
              //    this.slogin1();
            }
          });
      } else {
        $("#reg_button").show();
        $("#loaderreg").hide();
      }
    },
    showAlert() {
      this.$swal({
        position: "center",
        icon: "error",
        title: this.error_message,
        showConfirmButton: false,
        timer: 1500,
      });
    },
    updateUserJobPreference() {
      if (this.job_preference_dp == "no" && this.job_preference_ft == "no") {
        this.error_message = "Please select Job Preference";
        this.showAlert();
      } else if (this.job_preference_dp == "yes" && this.job_preference_ft == "yes") {
        this.job_preference_update = "both";
      } else if (this.job_preference_dp == "yes") {
        this.job_preference_update = "daily_paid";
      } else {
        this.job_preference_update = "full_time";
      }
      this.$store
        .dispatch(POST_API, {
          data: {
            job_preference: this.job_preference_update,
            user_id: this.currentSignupUserID,
          },
          api: "/api/updateUserJobPreference",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            console.log(this.$store.getters.getErrors);
          } else {
            console.log("updateUserJobPreference");
            this.$refs["job-preference-modal"].hide();
            if (this.login_api == "slogin1") {
              this.slogin1();
            } else {
              this.slogin();
            }
          }
        });
    },
    slogin() {
      var err = false;
      $(".required-field").each(function () {
        if ($(this).val() == "" || $(this).val().trim().length === 0) {
          err = true;
          $(this).addClass("error");
          $(this).parent().parent().find(".required_content").addClass("show");
          $(this).parent().parent().find(".error_x_white").addClass("show");
        } else {
          $(this).removeClass("error");
          $(this).parent().parent().find(".required_content").removeClass("show");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        }
      });

      setTimeout(function () {
        $(".second_indiv_step_wrap .error").first().focus();

        $(".error").unbind("keypress");
        $(".error").bind("keypress", function () {
          $(this).removeClass("error");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        });
      }, 100);
      if (err == false) {
        this.$store
          .dispatch(LOGIN, {
            data: {
              emailormobile: this.semail,
              password: this.spassword,
              user_type: this.user_type,
              role: "user",
              login_on: "web",
              popup: true,
            },
            api: "/api/login",
          })
          .then(() => {
            $("#reg_button").show();
            $("#loaderreg").hide();
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              document.getElementById("errorIdSignup").classList.add("show");
              document.getElementById("errorIdSignup").textContent = this.error_message;
            } else {
              this.$bvModal.hide("bv-modal-example");
              this.$swal({
                position: "center",
                icon: "success",
                iconColor: "#4c8bf5",
                title: "Register successfully.",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                // if(this.user_type == '3'){
                //     window.location.replace('/v2/employer/employer-profile');
                // }else{
                //     window.location.replace('/v2/jobseeker/jobseeker-profile');
                // }
                // this.is_company_head = this.$store.getters.currentUser.is_company_head;
                // if(this.is_company_head == 'no' && (this.user_type == '3' || this.user_type == '4')){
                //     window.location.replace('/v2/employer/om-supervisor-profile');
                // }else if(this.user_type == '3' && this.is_company_head == 'yes'){
                //     window.location.replace('/v2/employer/employer-profile');
                // }else if(this.user_type == '5'){
                //     window.location.replace('/v2/jobseeker/jobseeker-profile');
                // }else{
                //     window.location.replace('/v2/admin/dashboard');
                // }
                this.is_company_head = this.$store.getters.currentUser.is_company_head;
                this.user_type_id = this.$store.getters.currentUser.user_type_id;
                this.account_verification_status =
                  this.$store.getters.currentUser.account_verification_status;
                this.ft_account_status = this.$store.getters.currentUser.ft_account_status;
                this.getMenuPermission();
                // if(this.is_company_head == 'no' && (this.user_type_id == '3' || this.user_type_id == '4')){
                //     if(this.account_verification_status == 'completed'){
                //         window.location.replace('/v2/employer/dp-job-manage');
                //     }else if(this.ft_account_status == 'completed'){
                //         window.location.replace('/v2/employer/manage-jobs');
                //     }else{
                //         window.location.replace('/v2/employer/om-supervisor-profile');
                //     }
                //     // window.location.replace('/v2/employer/om-supervisor-profile');
                // }else if(this.user_type_id == '3' && this.is_company_head == 'yes'){
                //     if(this.account_verification_status == 'completed'){
                //         window.location.replace('/v2/employer/dp-job-manage');
                //     }else if(this.ft_account_status == 'completed'){
                //         window.location.replace('/v2/employer/manage-jobs');
                //     }else{
                //         window.location.replace('/v2/employer/employer-profile');
                //     }
                //     // window.location.replace('/v2/employer/employer-profile');
                // }else if(this.user_type_id == '5'){
                //     window.location.replace('/v2/jobseeker/jobseeker-profile');
                // }else{
                //     window.location.replace('/v2/admin/dashboard');
                // }
              });
            }
          });
      }
    },
    slogin1() {
      var err = false;
      $(".required-field").each(function () {
        if ($(this).val() == "" || $(this).val().trim().length === 0) {
          err = true;
          $(this).addClass("error");
          $(this).parent().parent().find(".required_content").addClass("show");
          $(this).parent().parent().find(".error_x_white").addClass("show");
        } else {
          $(this).removeClass("error");
          $(this).parent().parent().find(".required_content").removeClass("show");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        }
      });

      setTimeout(function () {
        $(".second_indiv_step_wrap .error").first().focus();

        $(".error").unbind("keypress");
        $(".error").bind("keypress", function () {
          $(this).removeClass("error");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        });
      }, 100);
      if (err == false) {
        this.$store
          .dispatch(LOGIN, {
            data: {
              emailormobile: this.semail1,
              password: this.spassword1,
              user_type: this.user_type,
              role: "user",
              login_on: "web",
              popup: true,
            },
            api: "/api/login",
          })
          .then(() => {
            $("#reg_button").show();
            $("#loaderreg").hide();
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              document.getElementById("errorIdSignup").classList.add("show");
              document.getElementById("errorIdSignup").textContent = this.error_message;
            } else {
              this.$bvModal.hide("bv-modal-example");
              this.$swal({
                position: "center",
                icon: "success",
                iconColor: "#4c8bf5",
                title: "Register successfully.",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                this.is_company_head = this.$store.getters.currentUser.is_company_head;
                this.user_type_id = this.$store.getters.currentUser.user_type_id;
                this.account_verification_status =
                  this.$store.getters.currentUser.account_verification_status;
                this.ft_account_status = this.$store.getters.currentUser.ft_account_status;
                this.getMenuPermission();
              });
            }
          });
      }
    },
    clickForgotPassword(event) {
      if (event) {
        event.stopPropagation();
      }
      var userTypeId = {
        user_type_id: this.user_type,
      };
      localStorage.setItem("userTypeId", JSON.stringify(userTypeId));
      this.$refs["bv-modal-example"].hide();
      this.$router.push({ name: "forgotpassword" });
    },
    reveal() {
      var reveals = document.querySelectorAll(".reveal");

      for (var i = 0; i < reveals.length; i++) {
        var windowHeight = window.innerHeight;
        var elementTop = reveals[i].getBoundingClientRect().top;
        var elementVisible = 150;

        if (elementTop < windowHeight - elementVisible) {
          reveals[i].classList.add("active");
        } else {
          reveals[i].classList.remove("active");
        }
      }
    },
    openLink() {
      window.open("https://elconnectptuser.app.link/OSbf0HwLOib", "_blank");
    },
    formSubmit(evt) {
      evt.preventDefault();
      this.$store
        .dispatch(POST_API, {
          data: {
            name: this.name,
            email: this.email,
            company_name: this.company_name,
            phone_number: "+65 " + this.phone_number,
          },
          api: "/api/get-in-touch-employer",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.success_message = this.$store.getters.getResults.message;

            this.$swal({
              position: "center",
              icon: "success",
              iconColor: "#4c8bf5",
              title: this.success_message,
              showConfirmButton: false,
              timer: 1500,
            });
            (this.name = ""), (this.email = ""), (this.phone_number = ""), (this.company_name = "");
          }
        });
    },
  },
  components: {
    JobseekerEmployerNavbarComponent,
  },
  mounted() {
    $("#empcontr2").show();
    $("#jobcontr2").hide();
    $(".password_requirements_text").hide();
    this.getQRData();
    // setTimeout(function ()
    //   {
    //     $(".linear_span").css({ 'background-size' : 'unset'});
    //   }, 3000);
    window.addEventListener("scroll", this.reveal);
    $(".toggle_employer").click(function () {
      $(this).toggleClass("is-active");
    });
    $(".mobile_navigation_menu_employer ul li a").click(function () {
      $(".toggle_employer").toggleClass("is-active");
      $(".mobile_navigation_menu_employer").hide();
    });
  },
};
</script>
<style scoped>
@import "/assets/css/employer-new.css";
@import "/assets/css/common-font-family.css";
@import "/assets/css/common-footer.css";
</style>
