<template>

    <div class="after_login_job_detail">

        <!-- Dashboard Headline -->
        <!-- Page Content
        ================================================== -->
         <div class="container my_new_container">
            <div class="row">
                <div class="col-xl-12">
                    <!-- <div class="back_arrow_icon new_bak_arr job_back_icon"> -->
                        <!-- <span class="icon-material-outline-arrow-back" @click="$router.go(-1)"></span> -->
	                    <!-- <b-link class="icon-material-outline-arrow-back new_arrow_back" :to="{name: 'jobList'}"></b-link> -->

                    <!-- </div> -->
                    <div id="breadcrumb-v2" class="mb-1">
                        <div class="d-flex justify-content-start breadcrumb-wrapper">
                            <b-breadcrumb
                            :items="breadCrumb()"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--<div class="single-page-header" :data-background-image="jobData.banner_image ? jobData.banner_image: webUrl+'assets/images/single-job.jpg'">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div class="single-page-header-inner">
					<div class="left-side">
						<div class="header-image"><b-link :to="{name: 'jobseeker-employer-profile-view', params: {id: posted_by}}"><img :src="profile_image != null ? profile_image : webUrl+'assets/images/company-logo-placeholders.png'" alt=""></b-link></div>
						<div class="header-details">
							<h3><b-link :to="{name: 'jobseeker-employer-profile-view', params: {id: posted_by}}">{{title | capitalize}}</b-link></h3>
							<h5>About the Employer</h5>
							<ul>
                                <h4 class="new_h4" v-if="jobData.short_description">{{ jobData.short_description | capitalize }}</h4>

								<li><a href="#"><i class="icon-material-outline-business"></i> {{company_name | capitalize}}</a></li>
								<li v-if="company_description">
                                    <p v-if="company_description.length >= 200" v-html="company_description.substring(0,200)+'...' "></p>
                                    <p v-else v-html="company_description"></p>
                                </li>
								<li v-if="average_rating"><div class="star-rating" :data-rating="average_rating"></div></li>
								<li><img class="flag" src="images/flags/gb.svg" alt="">{{user_address | capitalize}}</li>
								<li v-if='email_verified == "yes"'><div class="verified-badge-with-title">Verified</div></li>
							</ul>
						</div>
					</div>
					<div class="right-side">
						<div class="salary-box">
							<div class="salary-type" v-if="job_type == 'part-time'">Per Hour Rate</div>
							<div class="salary-type" v-else>Salary</div>
						<div class="salary-amount">${{min_salary}}-${{max_salary}}</div>
						</div>
					</div>
				</div>
			</div>
            </div>
            </div>
            </div> -->

            <!--hello -->
            <div class="mobile_view_jobs_banner bg_img_top new_bg_img_tp new_job_detail_full_dvz" v-bind:style="{backgroundImage: 'url('+( jobData.banner_image ? jobData.banner_image: webUrl+'assets/images/single-job.jpg' )+ ')'} " >

            </div>
            <div class="mobile_view_jobs bg_img_top new_bg_img_tp new_job_detail_full_dvz" v-bind:style="{backgroundImage: 'url('+( jobData.banner_image ? jobData.banner_image: webUrl+'assets/images/single-job.jpg' )+ ')'} " >
                <div class="grd_div inside_grd height_three_zero_one desktop_height_hundered">
                <div class="container my_new_container">
                    <div class="row">
                    <div class="col-md-12">
                        <div class="inrs_main">
                            <div class="left-side updated_left_side_banner">
                                <!-- <div class="header-image updated_header_image_banner updated_image_in_square_shape" title="View Employer Profile"><b-link :to="{name: 'jobseeker-employer-profile-view', params: {id: encrypt(posted_by)}}"><img :src="jobData.company_logo != null ? jobData.company_logo : webUrl+'assets/images/company-logo-placeholders.png'" alt=""></b-link> -->
                                <div class="header-image updated_header_image_banner updated_image_in_square_shape" title="View Employer Profile"><a href="javascript:void(0);" @click="employerDetail(encrypt(posted_by),$event)"><img :src="jobData.company_logo != null ? jobData.company_logo : webUrl+'assets/images/company-logo-placeholders.png'" alt=""></a>
                                
                                  <div class="right-side daily_right_side mobile_view_salary" v-if="(min_salary != 0 && max_salary != 0) || min_salary > 0 || max_salary > 0">
                                    <div class="right_inner_contents">
                                    <div class="salary-box">
                                        <div class="salary-type" v-if="job_type == 'part-time'">Per Hour Rate</div>
                                        <div class="salary-type" v-else>Salary</div>
                                    <div class="salary-amount">${{min_salary}}-${{max_salary}} Monthly</div>
                                    </div>
                                    </div>
                                 </div>
                                </div>
                                <div class="header-details updated_header_details_banner">
                                    <h1 class="header_detail_new_h1  new_daily_paid_jjobs">{{title | capitalize}}</h1>
                                    <h5>About the Employer</h5>
                                    <ul class="margin_top_12 mobile_header_ul">
                                        <h4 class="new_h4" v-if="jobData.short_description">{{ jobData.short_description | capitalize }}</h4>

                                        <!-- <li class="emp_li_fst" :class="styleClass"><b-link :to="{name: 'jobseeker-employer-profile-view', params: {id: encrypt(posted_by)}}"><i class="icon-material-outline-business common_i_color"></i><span class="company_name_dt highlight"> {{company_name | capitalize}}</span></b-link></li> -->
                                        <li class="emp_li_fst" :class="styleClass"><a href="javascript:void(0);" @click="employerDetail(encrypt(posted_by),$event)"><i class="icon-material-outline-business common_i_color"></i><span class="company_name_dt highlight"> {{company_name | capitalize}}</span></a></li>
                                        
                                        <!-- <li v-if="company_description">
                                            <p v-if="company_description.length >= 200" v-html="company_description.substring(0,200)+'...' "></p>
                                            <p v-else v-html="company_description"></p>
                                        </li> -->
                                        <!-- <li v-if="average_rating"><div class="star-rating" :data-rating="average_rating"></div></li> -->
                                        <!-- <li><img class="flag" src="images/flags/gb.svg" alt="">{{user_address | capitalize}}</li> -->
                                        <!-- <li v-if='email_verified == "yes"'><div class="verified-badge-with-title">Verified</div></li> -->
                                    </ul>
                                </div>
                            </div>
                            <div class="right-side daily_right_side desktop_salary" v-if="(min_salary != 0 && max_salary != 0) || min_salary > 0 || max_salary > 0">
                                <div class="right_inner_contents">
                                <div class="salary-box">
                                    <div class="salary-type" v-if="job_type == 'part-time'">Per Hour Rate</div>
                                    <div class="salary-type" v-else>Salary</div>
                                <div class="salary-amount">${{min_salary}}-${{max_salary}} Monthly</div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>


                </div>
            </div>




<div class="container job_detail_container my_new_container">
	<div class="row">

		<!-- Content -->
		<!-- <div class="col-xl-8 col-lg-8 content-right-offset single_left_content"> -->
        <div class="col-xl-8 col-lg-8 single_left_content tab_max_width_and_flex single_page_sec_updated">
            <div class="mobile_apply_now_button">
                <h1 v-if="this.job_end_date < this.today" class="mb-4"><b style="font-weight:1000;color: #f006b7;font-size:20px;">Applications have closed for this job</b></h1>
                <a v-if="this.alreadyApplied == true && this.job_end_date > this.today" @click="removeApplication(jobData.jobId,$event)" class="apply-now-button" style="color: white;cursor: pointer;background: red;">Withdraw</a>
                <a  v-if="this.alreadyApplied == false && this.job_end_date > this.today" class="apply-now-button" @click="apply($event)" style="color: white;background: #4c8bf5;cursor: pointer;">Apply Now <i class="icon-material-outline-arrow-right-alt"></i></a>
            </div>
			<div class="mobile_apply_now_button common_box_border margin-bottom-25">
					<div class="job-overview">
						<div class="job-overview-headline common_right_box_heading_padding font_weight_600">Job Summary</div>
						<div class="job-overview-inner common_padding_25">
							<ul>
								<li>
									<i class="icon-material-outline-location-on"></i>
									<span>Location</span>
									<h5>{{address == 'Other' ? other_location : address | capitalize}}</h5>
								</li>
								<li>
									<i class="icon-material-outline-business-center"></i>
									<span>Job Role Type</span>
									<h5>{{job_type | customizeRoleType | capitalize}}</h5>
								</li>
                                <li>
									<i class="icon-material-outline-business"></i>
									<span>Job Category</span>
									<h5>{{jobData.service_categories_name == 'Other' ? jobData.other_service_category : jobData.service_categories_name | removeUnderscore}}</h5>
								</li>
                                <li>
                                    <i class="icon-material-outline-school"></i>
									<span>Minimum Qualification Level</span>
									<h5>{{jobData.qualification_title | capitalize}}</h5>
								</li>
                                <li>
									<i class="icon-material-outline-watch"></i>
									<span>Minimum Years of Experience</span>
									<h5>{{jobData.experience}}</h5>
								</li>
                                <li>
									<i class="icon-material-outline-face"></i>
									<span>Position Level</span>
									<h5>{{jobData.level_title | capitalize}}</h5>
								</li>
								<li v-if="(min_salary != 0 && max_salary != 0) || min_salary > 0 || max_salary > 0">
									<i class="icon-material-outline-local-atm"></i>
									<span v-if="job_type == 'part-time'">Per Hour Rate</span>
									<span v-else>Salary</span>
									<h5>${{min_salary}}-${{max_salary}} Monthly</h5>
								</li>
                                <!-- <li>
									<i class="icon-material-outline-face"></i>
									<span>Featured</span>
									<h5>{{jobData.featured | capitalize}}</h5>
								</li> -->
								<li>
									<i class="icon-material-outline-access-time"></i>
									<span>Date Posted</span>
									<h5>{{posted_date}}</h5>
								</li>
							</ul>
						</div>
					</div>
			</div>
            <div class="single-page-section" v-if="description">
				<h3 class="margin-bottom-25 font_weight_600">Job Description</h3>
				<p v-html="description"></p>
			</div>

           <!--<div class="single-page-section">
				<h3 class="margin-bottom-30">Location</h3>
                {{address | capitalize}}
				 <div id="single-job-map-container">
					<div id="singleListingMap" data-latitude="51.507717" data-longitude="-0.131095" data-map-icon="im im-icon-Hamburger"></div>
					<a href="#" id="streetView">Street View</a>
				</div>
			</div>-->
		</div>


		<!-- Sidebar -->
		<div class="col-xl-4 col-lg-4 single_right_sidebar tab_max_width_and_flex sidebar_wdget_last_div">
			<div class="sidebar-container web_bottom_zero mobile_margin_bottom_zero tab_margin_bottom_zero">
				<!-- <h1 v-if="this.job_end_date < this.today" class=""><b style="font-weight:1000;color: #f006b7;font-size:20px;">Applications have closed for this job</b></h1>
                <a v-if="this.alreadyApplied == true && this.job_end_date > this.today" @click="removeApplication(jobData.jobId,$event)" class="apply-now-button" style="color: white;cursor: pointer;background: red;">Withdraw</a>
				<a  v-if="this.alreadyApplied == false && this.job_end_date > this.today" class="apply-now-button" @click="apply($event)" style="color: white;background: #4c8bf5;cursor: pointer;">Apply Now <i class="icon-material-outline-arrow-right-alt"></i></a> -->
				<!-- <a v-if="alreadyApplied == false || job_end_date > today" @click="apply($event)" class="apply-now-button" style="color: white;background: blue;cursor: pointer;">Apply Now <i class="icon-material-outline-arrow-right-alt"></i></a> -->

				<!-- <a v-else class="apply-now-button" @click="apply($event)" style="color: white;background: blue;cursor: pointer;">Apply Now <i class="icon-material-outline-arrow-right-alt"></i></a> -->
				<!-- <a v-if="alreadyApplied == false" @click="apply($event)" class="apply-now-button" style="color: white;cursor: pointer;">Apply Now <i class="icon-material-outline-arrow-right-alt"></i></a> -->

                <!-- <a v-if="alreadyApplied == true" class="apply-now-button" style="color: white;background: green;">Already Applied </a> -->



				<!-- Sidebar Widget -->
                <h1 v-if="this.job_end_date < this.today" class="desktop_apply_now_button mb-4"><b style="font-weight:1000;color: #f006b7;font-size:20px;">Applications have closed for this job</b></h1>
                <a v-if="this.alreadyApplied == true && this.job_end_date > this.today" @click="removeApplication(jobData.jobId,$event)" class="apply-now-button desktop_apply_now_button" style="color: white;cursor: pointer;background: red;">Withdraw</a>
				<a  v-if="this.alreadyApplied == false && this.job_end_date > this.today" class="apply-now-button desktop_apply_now_button" @click="apply($event)" style="color: white;background: #4c8bf5;cursor: pointer;">Apply Now <i class="icon-material-outline-arrow-right-alt"></i></a>
				
                <div class="desktop_apply_now_button sidebar-widget common_box_border margin-bottom-25">
					<div class="job-overview">
						<div class="job-overview-headline common_right_box_heading_padding font_weight_600">Job Summary</div>
						<div class="job-overview-inner">
							<ul>
								<li>
									<i class="icon-material-outline-location-on"></i>
									<span>Location</span>
									<h5>{{address == 'Other' ? other_location : address | capitalize}}</h5>
								</li>
								<li>
									<i class="icon-material-outline-business-center"></i>
									<span>Job Role Type</span>
									<h5>{{job_type | customizeRoleType | capitalize}}</h5>
								</li>
                                <li>
									<i class="icon-material-outline-business"></i>
									<span>Job Category</span>
									<h5>{{jobData.service_categories_name == 'Other' ? jobData.other_service_category : jobData.service_categories_name | removeUnderscore}}</h5>
								</li>
                                <li>
                                    <i class="icon-material-outline-school"></i>
									<span>Minimum Qualification Level</span>
									<h5>{{jobData.qualification_title | capitalize}}</h5>
								</li>
                                <li>
									<i class="icon-material-outline-watch"></i>
									<span>Minimum Years of Experience</span>
									<h5>{{jobData.experience}}</h5>
								</li>
                                <li>
									<i class="icon-material-outline-face"></i>
									<span>Position Level</span>
									<h5>{{jobData.level_title | capitalize}}</h5>
								</li>
								<li v-if="(min_salary != 0 && max_salary != 0) || min_salary > 0 || max_salary > 0">
									<i class="icon-material-outline-local-atm"></i>
									<span v-if="job_type == 'part-time'">Per Hour Rate</span>
									<span v-else>Salary</span>
									<h5>${{min_salary}}-${{max_salary}} Monthly</h5>
								</li>
                                <!-- <li>
									<i class="icon-material-outline-face"></i>
									<span>Featured</span>
									<h5>{{jobData.featured | capitalize}}</h5>
								</li> -->
								<li>
									<i class="icon-material-outline-access-time"></i>
									<span>Date Posted</span>
									<h5>{{posted_date}}</h5>
								</li>
							</ul>
						</div>
					</div>
				</div>

				<!-- Sidebar Widget -->
				<div class="sidebar-widget new_bookmark_share_btn mobile_bottom_margin_25 desktop_margin_bottom_zero">
					<h3 class="font_weight_600">Bookmark or Share</h3>

					<!-- Bookmark Button -->
					<!-- <button class="bookmark-button margin-bottom-25"> -->
                        <b-form-checkbox buttons class="bookmark-button margin-bottom-25" id="bookmark" v-model="bookmarkjobs" @click.native="bookmarkCurrentJob(jobId,$event)" :disabled="true">

                            <span class="bookmark-icon"></span>

                            <span class="bookmarked-text"  v-if="bookmarkjobs == jobId">Bookmarked</span>

                            <span class="bookmark-text" v-if="bookmarkjobs == false">Bookmark</span>
                              </b-form-checkbox>
						<!-- <span class="bookmark-icon"></span>
						<span class="bookmark-text">Bookmark</span>
						<span class="bookmarked-text">Bookmarked</span> -->
					<!-- </button> -->



					<!-- Copy URL -->
					<div class="copy-url">
						<input id="copy-url" type="text" value="" class="with-border" readonly>
						<button class="copy-url-button ripple-effect" @click="copyURL()" data-clipboard-target="#copy-url" title="Copy to Clipboard" data-tippy-placement="top"><i class="icon-material-outline-file-copy"></i></button>
					</div>

					<!-- Share Buttons -->
					<div class="share-buttons margin-top-25">
						<div class="share-buttons-trigger"><i class="icon-feather-share-2"></i></div>
						<div class="share-buttons-content">
							<span>Interesting? <strong>Share It!</strong></span>

                             <q-btn>

                                </q-btn>

							<ul class="share-buttons-icons">
                               <li style="">
                                <ShareNetwork
                                    network="facebook"
                                    quote="AwesomeArticle"
                                    description="test"
                                    :url="this.jobUrl"
                                    hashtags="Jobs, SharedJobs">
                                    <!-- <span  style="color:#3b5998"><v-icon class="mr-3" style="color: #3b5998">fab fa-facebook</v-icon></span> -->
                                </ShareNetwork>
                               </li>

                                <li>

                                <ShareNetwork
                                    network="twitter"
                                    :url="this.jobUrl"
                                    title="Please find the shared job by using the given url"
                                    hashtags="Jobs, SharedJobs">
                                    <!-- <span  style="color:#3b5998">Share on Twitter</span> -->
                                </ShareNetwork>
                               </li>

                                <li>

                               <ShareNetwork
                                    network="linkedin"
                                    :url="this.jobUrl"
                                    title="Please find the shared job by using the given url"
                                    description="test"

                                    hashtags="Jobs, SharedJobs">
                                    <!-- <span  style="color:#3b5998">Share on Twitter</span> -->
                                </ShareNetwork>
                               </li>


								</ul>
						</div>
					</div>
                    <div class="task-tags margin-top-15">
                        <span v-for="tags in tagArr">{{tags | capitalize}}</span>
                    </div>

				</div>
                <!-- <h1 v-if="this.job_end_date < this.today" class="desktop_apply_now_button desktop_margin_bottom_zero"><b style="font-weight:1000;color: #f006b7;font-size:20px;">Applications have closed for this job</b></h1>
                <a v-if="this.alreadyApplied == true && this.job_end_date > this.today" @click="removeApplication(jobData.jobId,$event)" class="desktop_margin_bottom_zero apply-now-button desktop_apply_now_button" style="color: white;cursor: pointer;background: red;">Withdraw</a>
				<a  v-if="this.alreadyApplied == false && this.job_end_date > this.today" class="apply-now-button desktop_apply_now_button desktop_margin_bottom_zero" @click="apply($event)" style="color: white;background: #4c8bf5;cursor: pointer;">Apply Now <i class="icon-material-outline-arrow-right-alt"></i></a> -->

			</div>
		</div>
    </div>

        <!-- Single Page Job Slider Start-->


        <div class="row" v-if="simJobs.length">
            <div class="col-xl-12 col-lg-12">
                 <h3 class="margin-bottom-25 font_weight_600">Similar Jobs</h3>
                <VueSlickCarousel :arrows="true" :dots="true" v-bind="settings" ref="carousel" v-if="simJobs.length" class="job_detail_slider all_job_slider_detail">

						<div v-for="(si, index) in simJobs" :key="index">

                            <a href="#" @click="jobDetail(si.jobId,$event)" class="job-listing   job_detail_footer_cs">
                                <!-- Job Listing Details -->
                                <div class="job-listing-details">
                                    <!-- Logo -->
                                    <div class="job-listing-company-logo">
                                        <img :src="si.company_logo != null ? si.company_logo : webUrl+'assets/images/company-logo-placeholders.png'" alt="">
                                    </div>

                                    <!-- Details -->
                                    <div class="job-listing-description">
                                        <h4 class="job-listing-company" v-if="si.name.length >= 42" v-html="si.name.substring(0,42)+'...' "></h4>
                                        <h4 v-else v-html="si.name" class="job-listing-company company_height"></h4>
                                         <h3 class="job-listing-title" v-if="si.title.length >= 38" v-html="si.title.substring(0,38)+'...' "></h3>
                                        <h3 v-else v-html="si.title" class="job-listing-title"></h3>
                                    </div>
                                </div>

                                <!-- Job Listing Footer -->
                                <div class="job-listing-footer ">
                                    <ul>
                                        <div class="li_main_div_dt">
                                            <li class="one_li"><i class="icon-material-outline-location-on common_i_color"></i> {{si.job_address == 'Other' ? si.other_location : si.job_address | capitalize}}</li>
                                            <!-- <li class="two_li"><i class="icon-material-outline-business-center common_i_color"></i> {{si.sc_name | capitalize}}</li> -->
                                            <li class="two_li" v-if="si.min_salary && si.max_salary"><i class="common_i_color material-icons-outlined custom dollar_custom_sign new_dollar_custom_sign">monetization_on_outline</i> ${{si.min_salary != null ? si.min_salary : 0}}-${{si.max_salary != null ? si.max_salary : 0}} Monthly</li>
                                        </div>
                                        <div class="li_main_div_dt">
                                            <!-- <li class="one_li" v-if="si.min_salary && si.max_salary"><i class="common_i_color material-icons-outlined custom dollar_custom_sign">monetization_on_outline</i> ${{si.min_salary != null ? si.min_salary : 0}}-${{si.max_salary != null ? si.max_salary : 0}}</li> -->
                                            <li class="one_li similar_job_li_height"><i class="icon-material-outline-business-center common_i_color"></i> {{si.sc_name | capitalize}}</li>
                                            <li class="two_li"><i class="common_i_color icon-material-outline-access-time time_slider_icon"></i> {{si.posted_date | dateTimeAgo | capitalize}}</li>
                                        </div>
                                    </ul>
                                </div>
                            </a>
                        </div>

				</VueSlickCarousel>
            </div>
        </div>
        <!-- Single Page Job Slider / End -->

</div>

    </div>

</template>

<script>
    import { POST_API } from "../../../store/actions.type"
    import moment from 'moment'
    import { Carousel, Slide } from 'vue-carousel';
    import VueSlickCarousel from 'vue-slick-carousel'
    import 'vue-slick-carousel/dist/vue-slick-carousel.css'
    import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
    import VueSocialSharing from 'vue-social-sharing'
    import Bus from "../../../event-bus";

    export default {

         data() {
            return {
                styleClass:'',
                bookmarkVal:'',
                cv:'',
                bookmarkjobs:[],
                bookmarkjobsData:'',
				simJobs: [],
                id: atob(this.$route.params.id),
                title:'',
                daily:'',
                posted_date:'',
                job_start_date:'',
                posted_by:'',
                profile_image:'',
                email_verified:'',
                company_name:'',
                company_description:'',
                address:'',
                other_location: '',
                description:'',
                category_id:'',
                items: null,
                job_type:'',
                keyword:'',
                userId:'',
                jobId:'',
                average_rating:'',
                min_salary:'',
                max_salary:'',
                error_message:'',
                skillsArr: [],
                jobData:[],
                tagArr:[],
                pager: {},
                pageOfItems: [],
                items:null,
                totalRows: 0,
                from: '',
                to: '',
                currentPage: 1,
                perPage: 25,
                pageOptions: [25, 50, 75, 100],
                form:{
                    rowsPerPage:25,
                },
                alreadyApplied:false,
                job_end_date:'',
                jobUrl:'',
                today:'',
                webUrl: process.env.VUE_APP_URL,
                user_address:'',
                settings: {
                    "slidesToShow": 3,
                    "slidesToScroll": 3,
                    "speed": 800,
                    "infinite": false,
                    responsive: [
					{
					// breakpoint: 768,
					// settings: {
					// 	"slidesToShow": 1,
					// 		"slidesToScroll": 1,
					// 		"speed": 800,
					// 		"infinite": false,
                    //         "dots":true,
                    //         "arrows":false
					//     }
                    "breakpoint": 998,
                            "settings": {
                                "slidesToShow": 1,
                                "slidesToScroll": 1,
                                // "infinite": true,
                                "dots": true,
                                "arrow":true
                            }
                            },
                            {
                            "breakpoint": 1000,
                            "settings": {
                                "slidesToShow": 1,
                                "slidesToScroll": 1,
                                // "infinite": true,
                                "dots": true,
                                "arrow":true
                            }
                            },
                            {
                            "breakpoint": 768,
                            "settings": {
                                "slidesToShow": 1,
                                "slidesToScroll": 1,
                                "initialSlide": 1,
                                "dots": true,
                                "arrow":true
                            }
                            },
                            {
                            "breakpoint": 480,
                            "settings": {
                                "slidesToShow": 1,
                                "slidesToScroll": 1,
                                "dots": true,
                                "arrow":true
                            }

					}
				]
			}
            }
        },
        filters: {
            capitalize: function(value) {
                if (!value) return ''
                value = value.toString()
                return value.charAt(0).toUpperCase() + value.slice(1)
            },
            moment: function(date) {
                return moment(date).utc().format('DD MMM YYYY')
            },
            dateTimeAgo: function(value) {
                var m = moment(value);  // or whatever start date you have
                // var today = moment().startOf('day');
                var today = moment();
                    var days = Math.round(moment.duration(today - m).asDays());
                if(days > 0){
                    return days +' Days Ago'
                }else{
                    return 'today'
                }
                // if (!value) return ''
                // const dateTimeAgo = moment(value).fromNow();
                // return dateTimeAgo
		    },

            removeUnderscore: function(value) {
                if (!value) return ''
                var i, frags = value.split('_');
                for (i=0; i<frags.length; i++) {
                    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
                }
                return frags.join(' ');
            },
            customizeRoleType: function(value) {
                if (!value) return ''
                var i, frags = value.split('-');
                for (i=0; i<frags.length; i++) {
                    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
                }
                return frags.join(' ');
            },
        },

        methods:{
            breadCrumb(){
                var item = [{
                    to:{name:'jobList'},
                    text: 'Full Time Jobs',
                },{
                    to:null,
                    text: 'Job Detail'
                }];
                return item;
            },
            showAlert() {
                    this.$swal({
                        position: 'center',
                        icon: 'error',
                        title: this.error_message,
                        showConfirmButton: false,
                        timer: 1500
                    })
            },

            async copyURL() {
                    try {
                        let copyText = document.querySelector("#copy-url");
                        copyText.select();
                        document.execCommand("copy");
                        // var mytext = $('#copy-url').val();
                        // navigator.permissions.query({ name: "clipboard-write" }).then((result) => {
                        //     if (result.state == "granted" || result.state == "prompt") {
                        //         navigator.clipboard.writeText(mytext);
                        //     }
                        // });

                        Snackbar.show({
                            text: 'Copied to clipboard!',
                        });
                        // alert('Copied to Clipboard.');
                    } catch($e) {
                        console.log($e);

                        // alert('Cannot copy');
                    }
              },
            showSuccessAlert(message) {
                this.$swal({
                    position: 'center',
                    icon: 'success',
                    iconColor:'#4c8bf5',
                    title: message,
                    showConfirmButton: false,
                    timer: 1500
                });
            },
            bookmarkCurrentJob(job_id,event){
                if (event) {
                event.stopPropagation()
                }
                return this.$store.dispatch(POST_API, {
                        data:{
                            job_id:job_id,
                            user_id:this.user_id
                    },
                    api: '/api/bookmark-job'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        // var message = 'Job Bookmarked successfully.'
                        this.bookmarkVal = this.$store.getters.getResults.bookmarkStatus
                        var message = this.$store.getters.getResults.message
                        if (this.bookmarkVal == 'yes') {
                            this.bookmarkjobs = job_id;
                            // this.bookmarkjobsd = job_id;
                            $('#bookmark').parent().addClass('bookmarked');
                        } else {
                            this.bookmarkjobs = false;
							$('#bookmark').parent().removeClass('bookmarked');
                        }
                        this.showSuccessAlert(message);
                        // this.getjobsDataById();
                        //this.similarJobs();
                        // this.similarJobs(this.category_id);
                        // this.isApplied();
                    }
                });
            },
            jobDetail(id,event){
                if (event) {
                event.stopPropagation()
                }
                window.location.replace('/v2/jobseeker/ft-jobdetail/'+btoa(id));
            },

            getjobsDataById() {
                var id = (this.id);

                return this.$store.dispatch(POST_API, {
                    data:{
                            job_id: id,
                            user_id:this.user_id
                },
                    api: '/api/jobDataById'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        var checked = [];
                        this.jobData = this.$store.getters.getResults.data[0];
                        this.title = this.$store.getters.getResults.data[0].job_title;
                        this.category_id = this.$store.getters.getResults.data[0].service_categories_id;

                        this.email_verified = this.$store.getters.getResults.data[0].email_verified;
                        this.description = this.$store.getters.getResults.data[0].job_description;
                        this.min_salary = this.$store.getters.getResults.data[0].min_salary;
                        this.max_salary = this.$store.getters.getResults.data[0].max_salary;
                        this.profile_image = this.$store.getters.getResults.data[0].profile_image;
                        this.address = this.$store.getters.getResults.data[0].job_address;
                        this.other_location = this.$store.getters.getResults.data[0].other_location;
                        this.job_type = this.$store.getters.getResults.data[0].job_type;
                        this.company_name = this.$store.getters.getResults.data[0].company_name;
                        this.company_description = this.$store.getters.getResults.data[0].company_description;
                        this.jobId = this.$store.getters.getResults.data[0].jobId;
                        this.average_rating = this.$store.getters.getResults.data[0].average_rating;
                        this.bookmarkjobsData = this.$store.getters.getResults.data[0].bookmark;
                        this.posted_date =  moment(String(this.$store.getters.getResults.data[0].posted_date)).format('DD MMM YYYY');
                        this.job_start_date = this.$store.getters.getResults.data[0].posted_date;
                        // this.job_end_date = moment(this.$store.getters.getResults.data[0].end_date).format('YYYY-MM-DD');
                        this.job_end_date = this.$store.getters.getResults.data[0].end_date;

                        this.today = moment().tz("Singapore").format('YYYY-MM-DD');

                        this.posted_by = this.$store.getters.getResults.data[0].posted_by;
                        this.tagArr = this.jobData.tag_names ? this.jobData.tag_names.split(',') : [];
                        if(this.bookmarkjobsData == 'yes'){
                            this.bookmarkjobs = checked;
                            checked.push (this.jobId);
                            $('#bookmark').parent().addClass('bookmarked');
                        }else{
                            this.bookmarkjobs = false;
                        }
			            this.similarJobs(this.category_id);
                    }
                });

            },

            similarJobs(category_id) {
                return this.$store.dispatch(POST_API, {
                    data:{
                        category_id: category_id,
                        job_id: this.id,
                        user_id: this.user_id,

                    },
                    api: '/api/similarJobs'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        this.simJobs = this.$store.getters.getResults.data;
                    }
                    $('#copy-url').val(process.env.VUE_APP_URL + "job?jobId=" + btoa(this.id));
                });
            },
            removeApplication(job_id,event){
                if (event) {
                event.stopPropagation()
                }
                this.$swal({
                    title: 'Please Confirm',
                    text: 'Are you sure you want to withdraw this job?',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes!'
                })
                .then(result => {
                    if (result.value) {
                        return this.$store.dispatch(POST_API, {
                            data:{
                                job_id:job_id,
                                user_id:this.user_id
                        },
                        api: '/api/cancel-job-application'
                    })
                    .then(() => {
                        if (this.$store.getters.containsErrors) {
                            this.error_message = this.$store.getters.getErrors;
                            this.showAlert();
                            return [];
                        } else {
                            var message = 'Job withdraw successfully'
                            this.alreadyApplied = false;
                            this.showSuccessAlert(message);
                            this.similarJobs(this.category_id);
                            this.getjobsDataById();
                            this.isApplied();
                        }
                    });
                    }
                })
                .catch(err => {
                })
            },
            apply(event) {
                if (event) {
                event.stopPropagation()
                }
                this.$swal({
                    title: 'Submit Application?',
                    html: "Confirm that your resume is up-to-date.",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Submit',
                     customClass: {
                        container: 'jbs_container_class'
                    }
                })
                .then(result => {
                    if (result.value) {
                        return this.$store.dispatch(POST_API, {
                        data:{
                            jobId: this.jobId,
                            userId: this.user_id,
                            job_title:this.title,
                            job_date:this.job_start_date ? moment(this.job_start_date).format('DD-MM-YYYY') : null,
                            posted_by:this.posted_by,
                        },
                        api:'/api/applyForJob'
                        })
                        .then(() => {
                            if (this.$store.getters.containsErrors) {
                                this.error_message = this.$store.getters.getErrors;
                                this.showAlert();
                                return [];
                            } else {
                                var message = this.$store.getters.getResults.message;
                                this.alreadyApplied = true;
                                this.showSuccessAlert(message);
                                this.similarJobs(this.category_id);
                                this.getjobsDataById();
                                this.isApplied();

                                // this.$swal({
                                //     position: 'center',
                                //     icon: 'success',
                                //     title: this.success_message,
                                //     showConfirmButton: false,
                                //     timer: 1500
                                // }).then((result) => {
                                //     // window.location.reload();
                                // });
                            }
                        });
                    }
                });
            },
            isApplied() {
                return this.$store.dispatch(POST_API, {
                        data:{
                            jobId: this.id,
                            userId:this.user_id
                },
                    api: '/api/isApplied'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        $('#copy-url').val(process.env.VUE_APP_URL + "job?jobId=" + btoa(this.id));
                        if(this.$store.getters.getResults.data.length != 0){
                            this.alreadyApplied = true;
                        }else{
                            this.alreadyApplied = false;
                        }
                    }
                });
            },
            employerDetail(id,event){
                if (event) {
                event.stopPropagation()
                }
                // window.location.replace('/v2/jobseeker/employer-profile/view/'+id);
                window.open('/v2/jobseeker/employer-profile/view/'+ id, '_blank');


            },
            getUserData() {
                return this.$store.dispatch(POST_API, {
                    data: {
                        id: this.user_id,
                    },
                    api: '/api/user-data'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        if(this.$store.getters.getResults.data){
                            this.cv = this.$store.getters.getResults.data.cv != null ? this.$store.getters.getResults.data.cv : '';

                        }else{
                        }
                    }
                });
            },
            jobViewCount(){
                this.$store.dispatch(POST_API, {
                    data:{
                    id: this.id,
                    },
                    api:'/api/ft-job-view-count'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        return [];
                    }
                    else {
                    }
                });
            },


        },
        mounted(){
            $(".share-buttons-icons").addClass('hide_social_share');
            $(".share-buttons-content").click(function(){
                $(".share-buttons-content").toggleClass('show_hide_share_text');
                // $(".share-buttons-icons").addClass('show_social_share');
                $(".share-buttons-icons").toggleClass('show_social_share');
            });
            $(".share-buttons-trigger").click(function(){
                $(".share-buttons-content").toggleClass('show_hide_share_text');
                // $(".share-buttons-icons").addClass('show_social_share');
                $(".share-buttons-icons").toggleClass('show_social_share');
            });
            $(".after_login_job_detail").parent().addClass('custom_dashboard_content_inner');
             window.scrollTo(0,0);
                $(".dashboard-content-container .simplebar-scroll-content").animate({
                    scrollTop: 0
                }, "fast");
            //document.title = "EL Connect - Jobseeker - Job Detail";
            this.user_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
            Bus.$emit('active_users');

            this.getjobsDataById();
            this.getUserData();

		    // this.similarJobs(this.category_id);
            // $('#copy-url').val(process.env.VUE_APP_URL + "job?jobId=" + btoa(this.id));
           this.jobUrl =  process.env.VUE_APP_URL + "job?jobId=" + btoa(this.id);
           // this.jobUrl =  "http://staging.elconnect.sg/job?jobId=MTg4Mg==";
            this.isApplied();
            this.jobViewCount();

        },
        components: {
        VueSlickCarousel,
        Carousel,
        VueSocialSharing,
        Slide
    }
    }
</script>
