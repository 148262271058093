<template>
  <div>
    <!-- Dashboard Headline -->

    <!-- Page Content
        ================================================== -->
    <div class="">
      <!-- <div class="dashboard-headline">
                <div class="back_arrow_icon new_bak_arr">
                    <h1><span class="icon-material-outline-arrow-back new_arrow_back" @click="$router.go(-1)"></span>Post Job</h1>
                </div>
                <nav id="breadcrumbs" class="dark employer_nav">
                    <ul>
                        <li><a href="#">Home</a></li>
                        <li><a href="#">Job</a></li>
                        <li>post Job</li>
                    </ul>
                </nav>
            </div> -->
      <div id="breadcrumb-v2" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb :items="breadCrumb()" />
        </div>
      </div>

      <div class="row">
        <b-form method="post" id="register-account-form" class="nw_frm">
          <!-- <p id="error"></p> -->
          <!-- Dashboard Box -->

          <div class="col-xl-12">
            <div class="dashboard-box desktop_margin_top_zero">
              <!-- Headline -->
              <div class="headline">
                <h3><i class="icon-material-outline-business-center"></i> Job Submission Form</h3>
              </div>

              <div class="content">
                <ul class="fields-ul second_indiv_step_wrap">
                  <li class="common_padding_30 common_input_field">
                    <div class="row">
                      <b-col lg="12" md="12">
                        <b-form-group
                          id="input-group-8"
                          label="Select Option "
                          class="category-radio required employer_multiple_radio"
                        >
                          <b-form-radio
                            v-model="form.jobFor"
                            name="jobFor"
                            :disabled="true"
                            value="forcompany"
                            >For Company</b-form-radio
                          >
                          <b-form-radio
                            v-model="form.jobFor"
                            name="jobFor"
                            :disabled="true"
                            value="forindividual"
                            >For Individual</b-form-radio
                          >
                        </b-form-group>
                      </b-col>
                      <div class="col-xl-12 cat_inner_divs" v-if="form.jobFor == 'forcompany'">
                        <div class="submit-field error_submit_field">
                          <h5>Job Company</h5>
                          <multiselect
                            v-model="companyName"
                            id="ajax"
                            label="name"
                            track-by="id"
                            placeholder="Type to search company name"
                            open-direction="bottom"
                            :options="selectedCompany"
                            :searchable="true"
                            :clear-on-select="true"
                            :close-on-select="true"
                            :allow-empty="true"
                            @input="company(companyName)"
                          >
                            <span slot="noResult">No Company Found.</span>
                          </multiselect>
                        </div>
                      </div>

                      <div class="col-xl-5 cat_inner_divs">
                        <div class="submit-field error_submit_field">
                          <h5>Job Category <span class="required_star">*</span></h5>
                          <b-form-select
                            v-model="form.service_categories_id"
                            id="jbs_category"
                            class="jbs_category"
                            @change="showCategory(form.service_categories_id)"
                          >
                            <option value="" disabled>Select Category</option>
                            <option :value="category.id" v-for="category in categories">
                              {{ category.name | capitalize }}
                            </option>
                          </b-form-select>
                        </div>
                        <span class="error_x_white">Please enter Job Category !</span>
                      </div>
                      <div class="col-xl-12 submit-field cat_inner_divs fb_category_class">
                        <div
                          class="submit-field desktop_margin_bottom_15"
                          v-if="form.service_categories_id == fb_category_id"
                        >
                          <div class="employer_multiple_radio">
                            <b-form-checkbox
                              value="yes"
                              unchecked-value="no"
                              v-model="form.is_hygiene_cert_req"
                              @input="hyiegne_cert($event)"
                              ><strong>Food Safety Certificate Required</strong></b-form-checkbox
                            >
                          </div>
                        </div>
                        <div class="" v-if="form.service_categories_id == fb_category_id">
                          <div class="employer_multiple_radio">
                            <b-form-checkbox
                              value="yes"
                              unchecked-value="no"
                              v-model="form.medical_history"
                              @input="medical_hist($event)"
                              ><strong>With No Medical History</strong></b-form-checkbox
                            >
                          </div>
                        </div>
                      </div>
                      <b-row v-if="has_department == 'yes'">
                        <b-col lg="12" md="12">
                          <b-form-group id="input-group-4" label="Select Department">
                            <span class="custom-dropdown">
                              <b-form-select v-model="form.department">
                                <option value="">Please Select Department</option>
                                <option :value="dl.id" v-for="dl in departmentList">
                                  {{ dl.department_name | capitalize }}
                                </option>
                              </b-form-select>
                            </span>
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <b-row v-if="branchList.length > 0">
                        <b-col lg="12" md="12">
                          <b-form-group id="input-group-4" label="Select Branch">
                            <span class="custom-dropdown">
                              <b-form-select v-model="form.branch">
                                <option value="">Please Select Branch</option>
                                <option :value="br.id" v-for="br in branchList">
                                  {{ br.name | capitalize }}
                                </option>
                              </b-form-select>
                            </span>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <div class="col-xl-12">
                        <div class="submit-field error_submit_field">
                          <h5>Job Title <span class="required_star">*</span></h5>
                          <b-form-input
                            type="text"
                            v-model="form.title"
                            id="company_reg_no"
                            class="input-text with-border required-field"
                            name="company_reg_no"
                            placeholder=""
                            value=""
                          />
                        </div>
                        <span class="error_x_white">Please enter Job Title !</span>
                      </div>

                      <div class="col-xl-12">
                        <div class="submit-field error_bg_white error_submit_field">
                          <h5>
                            Job Scope <span class="required_star">*</span>
                            <span class="eye_span_post"
                              ><i
                                class="fa fa-eye-slash job_scope_eye_pass"
                                id="show_job_scope_data"
                                @click="JobScopeEyeReveal"
                                style="cursor: pointer"
                              ></i
                            ></span>
                          </h5>
                          <!-- <ckeditor v-model="form.job_scope" :config="editorConfig" id="jb_description1" class="jb_description1"></ckeditor>  -->
                          <textarea
                            type="text"
                            class="job_scope_toggle with-border required-field"
                            value=""
                            v-model="form.job_scope"
                          ></textarea>
                        </div>
                        <span class="error_x_white">Please enter Job Scope !</span>
                      </div>

                      <div class="col-xl-12">
                        <div class="submit-field error_bg_white error_submit_field">
                          <h5>
                            Job Requirement <span class="required_star">*</span>
                            <span class="eye_span_post"
                              ><i
                                class="fa fa-eye-slash job_requirement_eye_pass"
                                id="show_job_requirement_data"
                                @click="JobRequirementEyeReveal"
                                style="cursor: pointer"
                              ></i
                            ></span>
                          </h5>
                          <!-- <ckeditor v-model="form.requirement" :config="editorConfig1" id="jb_description" class="jb_description"></ckeditor>  -->
                          <textarea
                            type="text"
                            class="job_requirement_toggle with-border required-field"
                            value=""
                            v-model="form.requirement"
                          ></textarea>
                        </div>
                        <span class="error_x_white">Please enter Job Requirement !</span>
                      </div>
                      <div class="col-xl-6">
                        <div class="submit-field error_submit_field">
                          <h5>
                            Work Site Contact Person 1 Name <span class="required_star">*</span>
                          </h5>
                          <b-form-input
                            type="text"
                            v-model="form.work_site_contact_person"
                            id="company_reg_no"
                            class="input-text with-border required-field"
                            name="company_reg_no"
                            placeholder=""
                            value=""
                          />
                        </div>
                        <span class="error_x_white"
                          >Please enter Work Site Contact Person 1 Name !</span
                        >
                      </div>

                      <div class="col-xl-6">
                        <div class="submit-field error_submit_field">
                          <h5>
                            Work Site Contact Person 1 Number <span class="required_star">*</span>
                          </h5>
                          <div class="submit-field mobile_view_numbr mobile_email">
                            <div class="input-group mb-3">
                              <input
                                type="text"
                                v-model="form.work_site_contact_person_country_code"
                                class="with-border mobile_number_padding mobile_input_prf form-control required-field country_code_txt"
                                readonly="readonly"
                              />
                              <input
                                type="text"
                                v-model="form.work_site_contact_person_mobile_no"
                                class="with-border mobile_input_prf form-control required-field"
                                id="contact"
                              />
                              <!-- <div class="input-group-append" v-if="this.form.mobile_verified_check == false" >
                                                                <button @click="sendOTP" class="btn btn-outline-primary" type="button">Send OTP</button>
                                                            </div> -->
                            </div>
                            <span class="error_x_white"
                              >Please enter Work Site Contact Person 1 Number !</span
                            >
                          </div>
                          <!-- <span class="error_x_white">Please enter Minimum Year of Experience !</span> -->
                        </div>
                      </div>
                      <div class="col-xl-6">
                        <div class="submit-field error_submit_field">
                          <h5>Work Site Contact Person 2 Name</h5>
                          <b-form-input
                            type="text"
                            v-model="form.work_site_contact_person_2"
                            id="company_reg_no"
                            class="input-text with-border"
                            name="company_reg_no"
                            placeholder=""
                            value=""
                          />
                        </div>
                        <!-- <span class="error_x_white">Please enter Work Site Contact Person 2 Name !</span> -->
                      </div>

                      <div class="col-xl-6">
                        <div class="submit-field error_submit_field">
                          <h5>Work Site Contact Person 2 Number</h5>
                          <div class="submit-field mobile_view_numbr mobile_email">
                            <div class="input-group mb-3">
                              <input
                                type="text"
                                v-model="form.work_site_contact_person_2_country_code"
                                class="with-border mobile_number_padding mobile_input_prf form-control country_code_txt"
                                readonly="readonly"
                              />
                              <input
                                type="text"
                                v-model="form.work_site_contact_person_2_mobile_no"
                                class="with-border mobile_input_prf form-control"
                                id="contact"
                              />
                              <!-- <div class="input-group-append" v-if="this.form.mobile_verified_check == false" >
                                                                <button @click="sendOTP" class="btn btn-outline-primary" type="button">Send OTP</button>
                                                            </div> -->
                            </div>
                            <!-- <span class="error_x_white">Please enter Work Site Contact Person 2 Number !</span> -->
                          </div>
                          <!-- <span class="error_x_white">Please enter Minimum Year of Experience !</span> -->
                        </div>
                      </div>

                      <div class="col-xl-12">
                        <div class="submit-field error_submit_field">
                          <h5>Remarks (Internal use)<span class="required_star"></span></h5>
                          <b-form-input
                            type="text"
                            v-model="form.remarks"
                            id="company_reg_no"
                            class="input-text with-border"
                            name="company_reg_no"
                            placeholder=""
                            value=""
                          />
                        </div>
                        <span class="error_x_white">Please enter Remarks !</span>
                      </div>

                      <div class="col-xl-6">
                        <div class="submit-field error_submit_field multiple_date_top_div">
                          <h5>Start Date <span class="required_star">*</span></h5>
                          <div class="new_date_pk">
                            <b-input-group>
                              <datepicker
                                                          v-model="form.start_date"
                                                          :open-date="new Date()"
                                                          :disabled-dates="disabledFromDate"
                                                          :format="customFormatterTwo"
                                                          id="scheduled_post"
                                                          class="scheduled_post add_calender_icon_edit_job"
                                                          
                                                        ></datepicker>
                              <template v-slot:append>
                                <b-button
                                  aria-label="Clear date"
                                  @click="dateArr = []"
                                  class="custom_close_date"
                                >
                                  <span class="clear-btn multiple_close_btn">x</span>
                                </b-button>
                              </template>
                            </b-input-group>
                            <!-- <m-date-picker v-model="dateArr" :multi="multi" :always-display="false" lang="en" :format="formatDate" id="scheduled_post" class="scheduled_post common_date_range_picker" min="2023-01-19" style="background-color:#fff"></m-date-picker> -->
                            <span class="error_x_white">Please enter Start Date !</span>
                          </div>
                          <!-- <span class="error_x_white">Please enter Start Date !</span> -->
                          <!-- <datepicker v-model="form.scheduled_post" :open-date="new Date()" :disabled-dates="disabledFromDate"  :format="customFormatterTwo" id="scheduled_post" class="scheduled_post"></datepicker> -->
                        </div>
                      </div>

                      <div class="col-xl-6 start_time_new">
                        <div class="submit-field error_submit_field">
                          <h5>Start Time <span class="required_star">*</span></h5>
                          <vue-timepicker
                            format="hh:mm A"
                            v-model="form.starttime"
                            id="scheduled_min"
                            class="common_time_picker scheduled_min desktop_margin_bottom_zero"
                          ></vue-timepicker>
                        </div>
                        <span class="error_x_white">Please enter Start Time !</span>
                      </div>

                      <div class="col-xl-6">
                        <div class="submit-field error_submit_field">
                          <h5>No. of Hours <span class="required_star">*</span></h5>
                          <select
                            v-model="form.hour"
                            class="custom-select number_of_hour"
                            id="number_of_hour"
                          >
                            <option value="" disabled>Select</option>
                            <option :value="hour" v-for="(hour, id) in hours" :key="id">
                              {{ hour }}
                            </option>
                          </select>
                        </div>
                        <span class="error_x_white">Please enter No. of Hours !</span>
                      </div>

                      <div class="col-xl-6">
                        <div class="submit-field error_submit_field">
                          <h5>No. of Minutes <span class="required_star">*</span></h5>
                          <select
                            v-model="form.min"
                            class="custom-select number_of_minute"
                            id="number_of_minute"
                          >
                            <option value="" disabled>Select</option>
                            <option value="00">00</option>
                            <option value="15">15</option>
                            <option value="30">30</option>
                            <option value="45">45</option>
                          </select>
                        </div>
                        <span class="error_x_white">Please enter No. of Minutes !</span>
                      </div>

                      <div class="col-xl-6">
                        <div class="submit-field error_submit_field">
                          <h5>Vacancy <span class="required_star">*</span></h5>
                          <b-form-input
                            id="input-9"
                            maxlength="6"
                            v-model="form.vacancy"
                            type="text"
                            placeholder=""
                            class="required-field"
                          ></b-form-input>
                        </div>
                        <span class="error_x_white">Please enter Vacancy !</span>
                      </div>

                      <div class="col-xl-6">
                        <div class="submit-field error_submit_field">
                          <h5>True Vacancy <span class="required_star">*</span></h5>
                          <b-form-input
                            id="input-9"
                            maxlength="6"
                            v-model="form.true_vacancy"
                            type="text"
                            placeholder=""
                            class="required-field"
                          ></b-form-input>
                        </div>
                        <span class="error_x_white">Please enter True Vacancy !</span>
                      </div>

                      <div class="col-xl-6">
                        <div class="submit-field error_submit_field">
                          <h5>Per hour Cost<span class="required_star">*</span></h5>
                          <b-form-select
                            v-model="form.price"
                            id="per_hour_cost"
                            class="per_hour_cost"
                          >
                            <option value="" disabled="true">Please Select Per Hour Cost</option>
                            <option :value="cost.per_hour_cost" v-for="cost in costListing">
                              {{ cost.per_hour_cost }}
                            </option>
                          </b-form-select>
                        </div>
                        <span class="error_x_white">Please enter Per hour Cost !</span>
                      </div>

                      <div class="col-xl-12">
                        <div class="submit-field error_submit_field">
                          <h5>Address<span class="required_star">*</span></h5>
                          <gmap-autocomplete
                            @place_changed="setPlace"
                            style="width: 1000px"
                            placeholder="Please type for suggestions"
                            @click="addMarker"
                            :options="autocompleteOptions"
                            :value="form.formattedAddress"
                          >
                          </gmap-autocomplete>
                          <!-- <vue-google-autocomplete
                                                    ref="address"
                                                    id="map"
                                                    classname="form-control required-field"
                                                    placeholder="Please type your address"
                                                    v-on:placechanged="setPlace"
                                                    country="SG"
                                                    v-model="form.formattedAddress" :options="autocompleteOptions"
                                                    >
                                                    </vue-google-autocomplete> -->
                        </div>
                        <span class="error_x_white">Please enter Address !</span>
                      </div>

                      <div class="col-xl-12">
                        <div class="submit-field">
                          <h5>
                            Unit No. <span class="red-text"><strong>(Optional)</strong></span>
                          </h5>
                          <b-form-group id="input-group-2" label="" class="">
                            <b-form-input
                              id="input-2"
                              v-model="form.unit"
                              type="text"
                              placeholder=""
                            ></b-form-input>
                          </b-form-group>
                        </div>
                        <span class="error_x_white">Please enter Minimum Year of Experience !</span>
                      </div>

                      <div class="col-xl-12">
                        <div class="submit-field">
                          <GmapMap
                            :center="center"
                            :zoom="12"
                            style="width: 100%; height: 400px"
                            map-type-id="terrain"
                          >
                            <GmapMarker
                              :key="index"
                              v-for="(m, index) in markers"
                              :position="m.position"
                              @dragend="showLocation"
                              @click="center = m.position"
                            />
                          </GmapMap>
                        </div>
                        <span class="error_x_white">Please enter Minimum Year of Experience !</span>
                      </div>
                      <b-col lg="12" md="12">
                        <div class="full_border submit-field">
                          <p class="red-text top_text_common_heading">
                            <strong>Additional Optional</strong>
                          </p>
                          <!-- <hr> -->
                          <div class="inner_common_content">
                            <!-- <div class="col-xl-12"> -->

                            <div
                              class="submit-field employer_multiple_radio common_margin_bottom_15"
                            >
                              <b-form-checkbox
                                value="yes"
                                unchecked-value="no"
                                v-model="is_ot"
                                class="lunch_check_box"
                                ><strong>Is OT Job?</strong></b-form-checkbox
                              >
                            </div>

                            <div
                              class="submit-field employer_multiple_radio common_margin_bottom_15"
                            >
                              <b-form-checkbox
                                value="yes"
                                unchecked-value="no"
                                v-model="is_perm_partime"
                                class="lunch_check_box"
                                ><strong>Is Permanent Part Time job?</strong></b-form-checkbox
                              >
                            </div>

                            <div
                              class="submit-field employer_multiple_radio common_margin_bottom_15"
                            >
                              <b-row>
                                <b-col lg="3" md="3">
                                  <b-form-group>
                                    <b-form-checkbox
                                      value="yes"
                                      unchecked-value="no"
                                      v-model="instruction_images"
                                      @input="instruction_img($event)"
                                      ><strong
                                        >Attach Pictorial Instructions?</strong
                                      ></b-form-checkbox
                                    >
                                  </b-form-group>
                                </b-col>
                                <b-col lg="4" md="4" v-if="instruction_images == 'yes'">
                                  <p>
                                    <b-link @click="showImagesModal()"
                                      >Select / View Pictorial Instructions</b-link
                                    >
                                  </p>
                                </b-col>
                              </b-row>
                            </div>
                            <!-- </div> -->
                            <!-- <div class="col-xl-12"> -->
                            <div class="employer_multiple_radio">
                              <b-form-checkbox
                                value="yes"
                                unchecked-value="no"
                                v-model="lunch_pay_not_provided"
                                class="lunch_check_box"
                                ><strong>Unpaid break?</strong></b-form-checkbox
                              >
                            </div>
                            <span class="error_x_white"
                              >Please enter Minimum Year of Experience !</span
                            >
                            <!-- </div> -->
                            <div class="margin_top_28" v-if="lunch_pay_not_provided == 'yes'">
                              <div class="">
                                <b-form-select v-model="form.lunch_pay_hours">
                                  <option value="" disabled="true">
                                    Please Select No. of Hours
                                  </option>
                                  <option :value="arr" v-for="arr in lunch_hours_arr">
                                    {{ arr }}
                                  </option>
                                </b-form-select>
                              </div>
                              <span class="error_x_white"
                                >Please enter Minimum Year of Experience !</span
                              >
                            </div>
                          </div>
                        </div>
                      </b-col>

                      <b-col lg="12" md="12">
                        <div class="full_border">
                          <p class="red-text top_text_common_heading">
                            <strong>FL Requirements (Optional)</strong>
                          </p>
                          <!-- <hr> -->
                          <div class="inner_common_content">
                            <div class="row">
                              <div class="col-xl-4">
                                <div
                                  class="submit-field error_submit_field mobile_and_big_screen_margin_bottom"
                                >
                                  <h5>Gender</h5>
                                  <b-form-select
                                    data-size="7"
                                    title="Select Gender"
                                    v-model="form.gender"
                                    id="genderval"
                                    class="custom-select web_bottom_zero"
                                  >
                                    <option :value="null">Select</option>
                                    <option value="female">Female</option>
                                    <option value="male">Male</option>
                                  </b-form-select>
                                </div>
                              </div>
                              <!-- <div class="col-xl-3">
                                                            <div class="submit-field error_submit_field mobile_and_big_screen_margin_bottom">
                                                                    <h5>Race</h5>
                                                                    <b-form-select data-size="7" title="Select Gender"  v-model="form.race_id" class="web_bottom_zero custom-select" id="race" :disabled="acStatus != 'completed' || companyAdminDPStatus != 'completed' || companyAdminPostingPaused == 'yes'">
                                                                        <option :value="null">Select</option>
                                                                        <option :value="race.id" v-for="(race,id) in race" :key="id">{{race.title}}</option>
                                                                    </b-form-select>
                                                            </div>
                                                        </div> -->
                              <div class="col-xl-4">
                                <div class="submit-field desktop_margin_bottom_zero">
                                  <h5>Age Range</h5>
                                  <b-form-input
                                    id="input-9"
                                    v-model="form.min_age"
                                    type="number"
                                    min="15"
                                    max="70"
                                    placeholder="Min Age"
                                  ></b-form-input>
                                  <!-- <b-form-input id="input-9" v-model="form.max_age" type="number" min="15" max="70" placeholder="Max Age" :disabled="acStatus != 'completed' || companyAdminDPStatus != 'completed' || companyAdminPostingPaused == 'yes'"></b-form-input> -->
                                </div>
                              </div>
                              <div class="col-xl-4">
                                <div class="submit-field desktop_margin_bottom_zero">
                                  <h5 class="max_age_opacity">Age Range</h5>
                                  <b-form-input
                                    id="input-9"
                                    v-model="form.max_age"
                                    type="number"
                                    min="15"
                                    max="70"
                                    placeholder="Max Age"
                                  ></b-form-input>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-col>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="mobile_list_mrgn_btm mobile_tab_margin_bottom_zero">
            <div class="col-xl-12 post_jb_btn">
              <a
                @click="submit"
                class="button ripple-effect big margin-top-30 employee_btn common_vertical_align_bottom"
                style="background: #4c8bf5 !important"
                ><i class="material-icons-outlined">border_color_outlined</i>Update Job</a
              >
            </div>
          </div>
          <!-- Button -->
        </b-form>
      </div>
      <b-modal @hidden="resetModal" hide-footer hide-header ref="instruction-images-modal" centered>
        <div class="edit-task">
          <div class="popup-header">
            <h2 class="">Select Pictorial Instructions</h2>
            <div class="mt-2">
              <div class="addUserForm">
                <div class="editForm mw-100 pl-0">
                  <b-row>
                    <b-col lg="12" md="12" v-if="dataImages.length > 0">
                      <vue-select-image
                        :dataImages="dataImages"
                        :is-multiple="true"
                        :selectedImages="initialSelected"
                        h="100px"
                        w="100px"
                        @onselectmultipleimage="onSelectMultipleImage"
                      >
                      </vue-select-image>
                    </b-col>
                    <b-col lg="12" md="12" v-else>
                      <p>No images found!!</p>
                    </b-col>
                  </b-row>
                  <br />
                  <div slot="footer" class="form-btn">
                    <b-link @click="hideModal()" class="btn-sm green-bg-btn">OK</b-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
      <b-modal
        @hidden="hideModal"
        hide-footer
        hide-header
        ref="cost-modal"
        centered
        content-class=""
        modal-class="background_custom_class cost-modal"
      >
        <div class="edit-task">
          <div class="popup-header">
            <h3 class="">Please confirm and agree</h3>
            <div class="mt-2">
              <div class="addUserForm">
                <div class="editForm mw-100 pl-0">
                  <b-row>
                    <b-col lg="12" md="12">
                      <div class="summary_table_div">
                        <table
                          class="table-bordered ml-2 mr-2 mb-2 mt-2"
                          size="lg"
                          v-if="dataItem != null"
                        >
                          <!-- <thead>
                                                        <tr>
                                                            <th>Postings</th>
                                                            <th>Dates</th>
                                                            <th>Timing</th>
                                                            <th>Paid Hours</th>
                                                            <th>Rate / Hr</th>
                                                            <th>Vacancy</th>
                                                            <th>Total</th>
                                                        </tr>

                                                    </thead> -->
                          <tbody>
                            <tr class="head_tr">
                              <td>Postings</td>
                              <td>Dates</td>
                              <td>Timing</td>
                              <td>Paid Hours</td>
                              <td>Rate / Hr</td>
                              <td>Vacancy</td>
                              <td>Total</td>
                            </tr>
                            <tr v-for="(val, key) in dataItem" :key="key">
                              <td>{{ val.sno }}.</td>
                              <td class="blue-text">{{ val.date }}</td>
                              <td>{{ val.time }}</td>
                              <td>{{ val.hours }}</td>
                              <td :class="val.job_type_id == 2 ? 'medium-red-text' : ''">
                                ${{ val.basic_rate }}
                              </td>
                              <td>{{ val.vacancy }}</td>
                              <td>${{ val.total_cost }}</td>
                            </tr>
                            <tr>
                              <td></td>
                            </tr>
                            <tr>
                              <td><b>Overall</b></td>
                              <td>{{ dataItem.length }}</td>
                              <td>-</td>
                              <td>-</td>
                              <td>-</td>
                              <td>-</td>
                              <td class="bold-text">
                                <b>${{ sum_total }}</b>
                              </td>
                            </tr>
                            <tr v-if="is_prepaid == 'yes'">
                              <td style="text-align: right" colspan="6" class="mobile_td">
                                <b>Total with GST({{ gst_percent }}%)</b>
                              </td>
                              <td class="bold-text">
                                <b>${{ gst_total }}</b>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </b-col>
                  </b-row>
                  <br />
                  <div slot="footer" class="form-btn">
                    <b-button
                      @click="Finalsubmit()"
                      type="button"
                      variant="success"
                      class="desktop_margin_left_zero desktop_margin_right_zero"
                      >Agree</b-button
                    >
                    <b-button @click="hideModal()" type="button" variant="light">Cancel</b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
      <b-modal
        @hidden="hideModal"
        hide-footer
        hide-header
        ref="jobs-modal"
        size="lg"
        centered
        content-class="jobs-modal"
        modal-class="cost-modal"
      >
        <div class="edit-task">
          <div class="popup-header">
            <h3 class="">Existing Jobs</h3>
            <div class="mt-2">
              <div class="addUserForm">
                <div class="editForm mw-100 pl-0">
                  <b-row>
                    <b-col lg="12" md="12">
                      <div class="summary_table_div">
                        <table
                          class="table-bordered ml-2 mr-2 mb-2 mt-2"
                          v-if="existing_jobs != null"
                        >
                          <tbody>
                            <tr class="head_tr">
                              <td>S.No.</td>
                              <td>Title</td>
                              <td>Timing</td>
                              <td>Working Hours</td>
                            </tr>
                            <tr v-for="(val, key) in existing_jobs" :key="key">
                              <td>{{ key + 1 }}.</td>
                              <td class="blue-text">
                                <b-link
                                  v-if="val.status == 'in-progress'"
                                  href=""
                                  :to="{
                                    name: 'ViewJobDetailInprogressAdmin',
                                    params: {
                                      id: encrypt(val.id),
                                      from: 'null',
                                      search: 'null',
                                      companyFilter: 'null',
                                      serviceCategory: 'null',
                                    },
                                  }"
                                  target="_blank"
                                  >{{ val.title | capitalize }}({{ val.job_unique_id }})</b-link
                                >

                                <b-link
                                  v-else-if="val.status == 'active'"
                                  href=""
                                  :to="{
                                    name: 'ViewJobDetailUpcomingAdmin',
                                    params: {
                                      id: encrypt(val.id),
                                      from: 'null',
                                      search: 'null',
                                      companyFilter: 'null',
                                      serviceCategory: 'null',
                                    },
                                  }"
                                  target="_blank"
                                  >{{ val.title | capitalize }}({{ val.job_unique_id }})</b-link
                                >

                                <b-link
                                  v-else-if="val.status == 'completed'"
                                  href=""
                                  :to="{
                                    name: 'ViewJobDetailCompletedAdmin',
                                    params: {
                                      id: encrypt(val.id),
                                      from: 'null',
                                      search: 'null',
                                      companyFilter: 'null',
                                      serviceCategory: 'null',
                                    },
                                  }"
                                  target="_blank"
                                  >{{ val.title | capitalize }}({{ val.job_unique_id }})</b-link
                                >

                                <b-link
                                  v-else-if="val.status == 'cancelled'"
                                  href=""
                                  :to="{
                                    name: 'ViewJobDetailCancelledAdmin',
                                    params: {
                                      id: encrypt(val.id),
                                      from: 'null',
                                      search: 'null',
                                      companyFilter: 'null',
                                      serviceCategory: 'null',
                                    },
                                  }"
                                  target="_blank"
                                  >{{ val.title | capitalize }}({{ val.job_unique_id }})</b-link
                                >
                              </td>
                              <td>{{ val.time | datetime }}</td>
                              <td>{{ val.working_hours }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </b-col>
                  </b-row>
                  <br />
                  <div slot="footer" class="form-btn">
                    <b-button
                      @click="formSubmitData()"
                      type="button"
                      variant="success"
                      class="desktop_margin_left_zero desktop_margin_right_zero"
                      >Post Anyway</b-button
                    >
                    <b-button @click="hideModal()" type="button" variant="light">Cancel</b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </div>

    <!-- Spacer -->

    <!-- Spacer / End-->
    <!-- Row / End -->
  </div>
</template>
<script>
import { GET_API, POST_API } from "../../../store/actions.type";
import CKEditor from "ckeditor4-vue";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import VueTimepicker from "vuejs-timepicker";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
import Multiselect from "vue-multiselect";
import VueSelectImage from "vue-select-image";

import Bus from "../../../event-bus";

$("#scheduled_post_multi").click(function (e) {
  this.dateArr = new Date();
});

export default {
  name: "GoogleMap",
  props: ["name"],

  data() {
    const date = new Date();
    const isoDate = date.toISOString().split("T")[0];
    const [year, month, day] = isoDate.split("-");
    const formattedDate = `${day}-${month}-${year}`;
    return {
      form: {
        jobFor: "forcompany",
        title: "",
        type: "full_time",
        role_type_id: "",
        service_categories_id: "",
        other_service_category: "",
        location: "",
        scope: "",
        job_scope: "",
        requirement: "",
        remarks: "",
        work_site_contact_person_country_code: "+65",
        work_site_contact_person_2_country_code: "+65",
        work_site_contact_person_mobile_no: "",
        work_site_contact_person: "",
        work_site_contact_person_2_mobile_no: "",
        work_site_contact_person_2: "",
        max_salary: "",
        tagArr: [],
        description: "",
        qualification_id: "",
        location_id: "",
        other_job_location: "",
        position_level_id: "",
        experience: "",
        level: "",
        package: "",
        scheduled_post: "",
        user_id: "",
        company_id: "",
        postedjobs: "",
        hour: "",
        min: "",
        vacancy: "",
        true_vacancy: "",
        price: "",
        lunch_pay_hours: "",
        unit: "",
        formattedAddress: "",
        is_hygiene_cert_req: false,
        medical_history: false,
        // race_id:null,
        gender: null,
        min_age: "",
        max_age: "",
        starttime: {
          hh: "hh",
          mm: "mm",
          // // ss: '00',
          A: "AM",
        },
        department: "",
        branch: "",
      },
      race: [],
      dataItem: null,
      sum_total: 0,
      acStatus: "",
      companyAdminPostingPaused: "",
      address: "",
      companyAdminDPStatus: "",
      company_status: "",
      autocompleteOptions: {
        componentRestrictions: {
          country: ["SG"],
        },
      },
      lunch_pay_not_provided: "no",
      is_ot: "no",
      is_perm_partime: "no",
      instruction_images: "no",
      hours: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
      lunch_hours_arr: [0.5, 0.75, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5],

      salaryType: "Salary",
      other_location_id: "",
      fb_category_id: "",
      center: { lat: 1.3521, lng: 103.8198 },
      markers: [],
      places: [],
      costListing: [],
      currentPlace: null,

      coordl2: null,
      coordl2: null,
      l1: null,
      l2: null,
      latLng: "",
      gst_percent: 0,
      gst_total: 0,
      is_prepaid: "no",
      datas: "",
      part_time_id: "",
      // dateArr:[new Date().toISOString().split('T')[0]],
      //dateArr: new Date(),
      dateArr: [formattedDate],
      package_id: "",
      remaining_jobs: 0,
      qualifications: [],
      locations: [],
      position_levels: [],
      categories: [],
      templateId: "",
      unqiueJobList: [],
      role_types: [],
      experience_yrs: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24,
        25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47,
        48, 49, 50,
      ],
      showDismissibleAlert: false,
      error_message: "",
      isActive: 1,
      personal: true,
      links: false,
      disabled: true,
      disabledFromDate: {
        to: new Date(Date.now() - 8640000),
      },
      editorConfig1: {
        removeButtons: "Maximize",
        extraPlugins: "copyformatting,colorbutton,bidi,colordialog,widget,justify",
      },
      coreStyles_bold: {
        element: "b",
        overrides: "strong",
      },
      // Custom style definition for the Italic feature.
      coreStyles_italic: {
        element: "i",
        overrides: "em",
      },
      editorConfig: {
        removeButtons: "Maximize",
        extraPlugins: "copyformatting,colorbutton,bidi,colordialog,widget,justify",
      },

      isSubmitting: false,
      acStatus: "",
      loading: "",
      job_id: this.$route.params.id,
      selectedJobTemplate: "",
      jobTemplates: [],
      companyName: "",
      selectedCompany: [],
      instruction_images: "no",
      dataImages: [],
      initialSelected: [],
      unqiueJobList: [],
      departmentList: [],
      has_department: "no",
      existing_jobs: [],
      branchList: [],
      job_id: this.$route.params.id,
    };
  },
  components: {
    ckeditor: CKEditor.component,
    Datepicker,
    VueGoogleAutocomplete,
    VueTimepicker,
    // datePicker: VuePersianDatetimePicker,
    Multiselect,
    VueSelectImage,
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    removeUnderscore: function (value) {
      if (!value) return "";
      var i,
        frags = value.split("-");
      for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
      }
      return frags.join(" ");
    },
  },
  methods: {
    breadCrumb() {
      var item = [
        {
          to: { name: "DailyJobManage" },
          text: "Manage Jobs",
        },
        {
          to: null,
          text: "Post Job",
        },
      ];
      return item;
    },
    instruction_img($event) {
      if ($event != null) {
        this.instruction_images = $event;
      } else {
        this.instruction_images = $event;
      }
    },
    onSelectMultipleImage(img) {
      console.log("ii", img);
      if (img.length > 0) {
        this.instruction_images = "yes";
      } else {
        this.instruction_images = "no";
      }
      this.initialSelected = img;
    },
    showImagesModal() {
      if (this.instruction_images == "yes") {
        this.$refs["instruction-images-modal"].show();
      } else {
        this.hideModal();
        this.initialSelected = [];
      }
    },
    JobScopeEyeReveal() {
      $(".job_scope_eye_pass").toggleClass("fa-eye fa-eye-slash");
    },
    JobRequirementEyeReveal() {
      $(".job_requirement_eye_pass").toggleClass("fa-eye fa-eye-slash");
    },
    dateupdate() {
      if (this.dateArr.length > 31) {
        this.error_message = "Maximum 31 days jobs can be created at one time";
        this.showAlert();
        this.dateArr = [];
      } else if (this.dateArr.length > 0) {
        this.dateArr.sort((a, b) => {
          return new Date(a) - new Date(b);
        });
      }
    },

    activeTab() {
      this.personal = true;
      this.links = false;
    },

    showSalary(id) {
      if (id == this.part_time_id) {
        $(".perHourRate").css("display", "block");
        $(".minMaxSalary").css("display", "none");
      } else {
        $(".perHourRate").css("display", "none");
        $(".minMaxSalary").css("display", "block");
      }
    },
    hyiegne_cert($event) {
      if ($event != null) {
        this.form.is_hygiene_cert_req = $event;
      } else {
        this.form.is_hygiene_cert_req = $event;
      }
    },
    getDepartments(id) {
      // console.log(moment().subtract(16, 'years').format('DD-MM-YYYY'));
      return this.$store
        .dispatch(POST_API, {
          data: {
            id: id,
          },
          api: "/api/company-department-list",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.departmentList = this.$store.getters.getResults.data.data;
            // this.subCatg(this.service_id);
            // this.subvalue=[];
            this.has_department = this.departmentList.length > 0 ? "yes" : "no";

            return this.departmentList;
          }
        });
    },
    getBranchList() {
      // console.log(moment().subtract(16, 'years').format('DD-MM-YYYY'));
      return this.$store
        .dispatch(POST_API, {
          data: {
            company_id: this.companyName != "" ? this.companyName.id : "",
          },
          api: "/api/dp-jobs-branch-list",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.branchList = this.$store.getters.getResults.data.data;
            return this.branchList;
          }
        });
    },

    getRace() {
      return this.$store
        .dispatch(GET_API, {
          api: "/api/race",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.race = this.$store.getters.getResults.data;
            // this.showSuccessPopup();
          }
        });
    },
    medical_hist($event) {
      if ($event != null) {
        this.form.medical_history = $event;
      } else {
        this.form.medical_history = $event;
      }
    },
    showCategory(id) {
      if (id == this.fb_category_id) {
        $(".fb_category_class").css("display", "block");
      } else {
        $(".fb_category_class").css("display", "none");
      }
    },
    showLocation: function (evt) {
      //   this.l1=evt.latlng.latitude;

      this.datas = evt.latLng.toString().split(",");

      const marker = {
        lat: +this.datas[0].substring(1),
        lng: +this.datas[1].substring(0, this.datas[1].length - 1),
      };
      (this.center.lat = this.datas[0].substring(1)),
        (this.center.lng = this.datas[1].substring(0, this.datas[1].length - 1));

      this.center = marker;
      this.currentPlace = null;
      const _googleApiGeocoder = new google.maps.Geocoder();

      _googleApiGeocoder.geocode(
        {
          location: {
            lat: parseFloat(this.center.lat),
            lng: parseFloat(this.center.lng),
          },
        },
        (results_, status_) => {
          if (status_ !== google.maps.GeocoderStatus.OK) {
            // error process here
          } else {
            this.form.formattedAddress = results_[0].formatted_address;
          }
        }
      );
    },
    setPlace(place) {
      this.l1 = place.latitude;
      this.l2 = place.longitude;

      this.currentPlace = place;
      this.addMarker();
    },
    getAddressData: function (addressData, placeResultData, id) {
      this.address = addressData;
    },
    addMarker() {
      if (this.currentPlace) {
        const marker = {
          lat: +parseFloat(this.currentPlace.geometry.location.lat()),
          lng: +parseFloat(this.currentPlace.geometry.location.lng()),
        };
        this.form.formattedAddress = this.currentPlace.formatted_address;
        this.markers = [];
        this.markers.push({ position: marker });
        this.places = [];

        this.places.push(this.currentPlace);

        this.center = marker;
        this.currentPlace = null;
      } else {
        const marker = {
          lat: this.center.lat,
          lng: this.center.lng,
        };
        // console.log(marker);
        // this.form.formattedAddress =this.currentPlace.formatted_address;
        this.markers = [];
        this.markers.push({ position: marker });
        this.places = [];

        this.places.push(this.currentPlace);

        this.center = marker;
        this.currentPlace = null;
        console.log(this.center, this.currentPlace, "this.currentPlacethis.currentPlace");
      }
    },
    geolocate() {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.center = {
            lat: +parseFloat(position.coords.latitude),
            lng: +parseFloat(position.coords.longitude),
          };

          const marker = {
            lat: this.center.lat,
            lng: this.center.lng,
          };
          // this.form.formattedAddress =this.currentPlace.formatted_address;
          this.markers = [];
          this.markers.push({ position: marker });
          this.places = [];

          this.places.push(this.currentPlace);

          console.log(marker, "marker");
          // this.center = marker;
          this.currentPlace = this.center;
          this.addressUpdate();
        },
        (err) => {
          this.addMarker();
          this.addressUpdate();
          //console.log(err.message);
        }
      );
    },

    addressUpdate() {
      setTimeout(() => {
        const _googleApiGeocoder = new google.maps.Geocoder();

        _googleApiGeocoder.geocode(
          {
            location: {
              lat: parseFloat(this.center.lat),
              lng: parseFloat(this.center.lng),
            },
          },
          (results_, status_) => {
            if (status_ !== google.maps.GeocoderStatus.OK) {
              // error process here
            } else {
              this.form.formattedAddress = results_[0].formatted_address;
            }
          }
        );
      }, 200);
    },

    salaryTypeData(id) {
      if (id == "4") {
        this.salaryType = "Per Hour Rate";
      } else {
        this.salaryType = "Salary";
      }
    },

    customFormatterTwo(date) {
      return moment(date).format("DD-MM-YYYY");
    },

    showAlert() {
      this.$swal({
        position: "center",
        // icon: 'error',
        imageUrl: "/assets/images/404_elcy.gif",
        customClass: {
          container: "mascot_error_container",
        },
        title: this.error_message,
        showConfirmButton: false,
        timer: 1500,
      });
    },

    getDropdownData(type) {
      this.$store
        .dispatch(POST_API, {
          data: {
            table: "service_categories",
          },
          api: "/api/service-categories-list-dp",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;

            this.$swal({
              position: "center",
              // icon: 'error',
              imageUrl: "/assets/images/404_elcy.gif",
              customClass: {
                container: "mascot_error_container",
              },
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.categories = this.$store.getters.getResults.data;
            this.other_category_id = this.$store.getters.getResults.other_category_id;
            this.fb_category_id = this.$store.getters.getResults.fb_category_id;
            $(".fb_category_class").css("display", "none");
          }
        });
    },
    hideModal() {
      this.$refs["instruction-images-modal"].hide();
      this.$refs["cost-modal"].hide();
      this.$refs["jobs-modal"].hide();
      if (this.dataImages.length > 0) {
        this.instruction_images = "yes";
      } else {
        this.instruction_images = "no";
      }
    },

    submit() {
      var err = false;

      $(".required-field").each(function () {
        if ($(this).val() == "") {
          err = true;
          $(this).addClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
        } else {
          $(this).removeClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
        }
      });

      $(".job_role_type").each(function () {
        if (!$("#job_role_type").val()) {
          err = true;

          $(this).addClass("error");
          $(this).parent().parent().find(".required_content").addClass("show");
          $(this).parent().parent().find(".error_x_white").addClass("show");
        } else {
          $(this).removeClass("error");
          $(this).parent().parent().find(".required_content").removeClass("show");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        }
      });

      if ($("#maxSal").val()) {
        if (!$("#minSal").val()) {
          err = true;

          $("#minSal").addClass("error");
          $("#minSal").parent().parent().find(".required_content").addClass("show");
          $("#minSal").parent().parent().find(".error_x_white").addClass("show");
        } else {
          $("#minSal").removeClass("error");
          $("#minSal").parent().parent().find(".required_content").removeClass("show");
          $("#minSal").parent().parent().find(".error_x_white").removeClass("show");
        }
      }

      if ($("#minSal").val()) {
        if (!$("#maxSal").val()) {
          err = true;

          $("#maxSal").addClass("error");
          $("#maxSal").parent().parent().find(".required_content").addClass("show");
          $("#maxSal").parent().parent().find(".error_x_white").addClass("show");
        } else {
          $("#maxSal").removeClass("error");
          $("#maxSal").parent().parent().find(".required_content").removeClass("show");
          $("#maxSal").parent().parent().find(".error_x_white").removeClass("show");
        }
      }

      $(".per_hour_cost").each(function () {
        if (!$("#per_hour_cost").val()) {
          err = true;

          $(this).addClass("error");
          $(this).parent().parent().find(".required_content").addClass("show");
          $(this).parent().parent().find(".error_x_white").addClass("show");
        } else {
          $(this).removeClass("error");
          $(this).parent().parent().find(".required_content").removeClass("show");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        }
      });

      $(".jbs_category").each(function () {
        if (!$("#jbs_category").val()) {
          err = true;

          $(this).addClass("error");
          $(this).parent().parent().find(".required_content").addClass("show");
          $(this).parent().parent().find(".error_x_white").addClass("show");
        } else {
          $(this).removeClass("error");
          $(this).parent().parent().find(".required_content").removeClass("show");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        }
      });

      $(".min_year_exp").each(function () {
        if (!$("#min_year_exp").val()) {
          err = true;

          $(this).addClass("error");
          $(this).parent().parent().find(".required_content").addClass("show");
          $(this).parent().parent().find(".error_x_white").addClass("show");
        } else {
          $(this).removeClass("error");
          $(this).parent().parent().find(".required_content").removeClass("show");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        }
      });

      $(".min_month_exp").each(function () {
        if (!$("#min_month_exp").val()) {
          err = true;

          $(this).addClass("error");
          $(this).parent().parent().find(".required_content").addClass("show");
          $(this).parent().parent().find(".error_x_white").addClass("show");
        } else {
          $(this).removeClass("error");
          $(this).parent().parent().find(".required_content").removeClass("show");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        }
      });

      setTimeout(function () {
        //$('.second_indiv_step_wrap .error').first().focus();
        document
          .querySelector(".second_indiv_step_wrap .error")
          .scrollIntoView({ behavior: "smooth", block: "center" });
        $(".error").unbind("keypress");
        $(".error").bind("keypress", function () {
          $(this).removeClass("error");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
        });
      }, 100);

      // if(this.form.package == 'free'){
      //     if(this.remaining_jobs == 0){
      //             this.$swal({
      //                     position: 'center',
      //                     icon: 'error',
      //                     title: 'You dont have free jobs for posting.',
      //                     showConfirmButton: false,
      //                     timer: 1500
      //                 });
      //     }
      // }else{
      //     if(this.coins[0].total_coins < 800){
      //                 this.$swal({
      //                     position: 'center',
      //                     icon: 'error',
      //                     title: 'Insufficient coins for posting jobs',
      //                     showConfirmButton: false,
      //                     timer: 1500
      //                 });

      //     }
      // }

      if (this.form.role_type_id == this.part_time_id) {
        // this.form.min_salary = 0
      }

      if (this.form.formattedAddress == "") {
        err = true;
        this.$swal({
          position: "center",
          // icon: 'error',
          imageUrl: "/assets/images/404_elcy.gif",
          customClass: {
            container: "mascot_error_container",
          },
          title: "Please select Address again",
          showConfirmButton: false,
          timer: 1500,
        });
      }

      // if(this.form.starttime.hh == '' || this.form.starttime.mm == ''){
      //     err = true;
      //     this.$swal({
      //             position: 'center',
      //             icon: 'error',
      //             title: "Please select start time",
      //             showConfirmButton: false,
      //             timer: 1500
      //         });

      // }

      if (this.form.starttime.hh == "" || this.form.starttime.mm == "") {
        err = true;

        $(".scheduled_min").addClass("error");
        $(".scheduled_min")
          .parent()
          .parent()
          .find(".required_content")
          .addClass("show")
          .parent()
          .find(".error_submit_field")
          .addClass("bind_error_class");
        $(".scheduled_min")
          .parent()
          .parent()
          .find(".error_x_white")
          .addClass("show")
          .parent()
          .find(".error_submit_field")
          .addClass("bind_error_class");
      } else {
        $(".scheduled_min").removeClass("error");
        $(".scheduled_min")
          .parent()
          .parent()
          .find(".required_content")
          .removeClass("show")
          .parent()
          .find(".error_submit_field")
          .removeClass("bind_error_class");
        $(".scheduled_min")
          .parent()
          .parent()
          .find(".error_x_white")
          .removeClass("show")
          .parent()
          .find(".error_submit_field")
          .removeClass("bind_error_class");
      }

      if (this.form.service_categories_id == "") {
        err = true;
        this.$swal({
          position: "center",
          // icon: 'error',
          imageUrl: "/assets/images/404_elcy.gif",
          customClass: {
            container: "mascot_error_container",
          },
          title: "Please select category",
          showConfirmButton: false,
          timer: 1500,
        });
      }

      //  if(this.form.vacancy == '' || this.form.vacancy == 0){
      //     err = true;
      //     this.$swal({
      //             position: 'center',
      //             icon: 'error',
      //             title: "Please enter vacancy",
      //             showConfirmButton: false,
      //             timer: 1500
      //         });

      // }

      console.log(this.dateArr);

      this.loading = true;
      var total = this.form.vacancy * this.form.price * this.form.hour;

      var htmldata =
        "You are creating a job list with " +
        this.form.vacancy +
        " vacancy for " +
        this.form.hour +
        " hours. <br><br> Hourly Service Fee : <b>$" +
        this.form.price +
        "</b> <br><br>Est. Total Service Fee: <b>$" +
        total;

      

      if (err == false) {
        this.$store
          .dispatch(POST_API, {
            data: {
              id: atob(this.job_id),
              title: this.form.title,
              job_scope: this.form.job_scope,
              description: this.form.requirement,
              categories: this.form.service_categories_id,
              showCosting: true,
              work_site_contact_person: this.form.work_site_contact_person,
              work_site_contact_person_country_code: "+65",
              work_site_contact_person_mobile_no: this.form.work_site_contact_person_mobile_no,
              work_site_contact_person_2: this.form.work_site_contact_person_2,
              work_site_contact_person_2_country_code: "+65",
              work_site_contact_person_2_mobile_no: this.form.work_site_contact_person_2_mobile_no,
              salaryMax: this.form.max_salary ? this.form.max_salary : 0,
              remarks: this.form.remarks,
              working_hours:
                this.form.min == "00" || this.form.min == "" || this.form.min == null
                  ? this.form.hour + ".0"
                  : this.form.min == "45"
                  ? this.form.hour + ".75"
                  : this.form.min == "15"
                  ? this.form.hour + ".25"
                  : this.form.hour + ".5",

              // working_hours: (this.form.min == '00' || this.form.min == '' || this.form.min == null) ? this.form.hour + '.0' : this.form.hour +'.5',

              vacancy: this.form.vacancy,
              price: this.form.price,
              userId: this.user_id,
              company_admin_id: this.company_admin_id ? this.company_admin_id : this.user_id,
              company_id: this.company_id,
              job_type: this.form.role_type_id,
              start_date: this.form.start_date ? this.form.start_date : null,
              start_time: this.form.starttime,

              package: this.form.package,
              remaining_free: this.remaining_jobs,
              unit: this.form.unit,
              lunch_pay_hours: this.lunch_pay_not_provided == "yes" ? this.form.lunch_pay_hours : 0,
              lunch_pay_not_provided: this.lunch_pay_not_provided,
              is_ot: this.is_ot,
              address: this.form.formattedAddress,
              lat: this.center.lat,
              long: this.center.lng,
              is_hygiene_cert_req: this.form.is_hygiene_cert_req == "yes" ? "yes" : null,
              medical_history: this.form.medical_history == "yes" ? "yes" : null,
              gender: this.form.gender ? this.form.gender : null,
              // race_id:this.form.race_id ? this.form.race_id : null,
              min_age: this.form.min_age ? this.form.min_age : null,
              max_age: this.form.max_age ? this.form.max_age : null,
              platform_fee: this.form.platform_fee ? this.form.platform_fee : 0,
              from_v2: "yes",
              jobFor: this.form.jobFor,
              selected_company_id: this.form.jobFor == "forcompany" ? this.companyName.id : "",
              selected_individual_id:
                this.form.jobFor == "forindividual" ? this.individualName : "",
              sub_categories: this.subvalue,
              cpf_not_paid: this.form.cpf_not_paid == true ? "yes" : "no",
              is_perm_partime: this.is_perm_partime,
              instruction_images: this.initialSelected,
              instruction_images_checked: this.instruction_img,
              department: this.form.department ? this.form.department : null,
              true_vacancies:this.form.true_vacancy,
            },
            api: "/api/updateDailyJob",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showDismissibleAlert = true;
              this.$swal({
                position: "center",
                // icon: 'error',
                imageUrl: "/assets/images/404_elcy.gif",
                customClass: {
                  container: "mascot_error_container",
                },
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500,
              });
            } else {
              Bus.$emit("active_users");
              this.dataItem = this.$store.getters.getResults.message;

              this.$swal({
                title: "Please Confirm",
                html: this.dataItem,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "OK",
              }).then((res) => {
                if (res.value) {
                  this.Finalsubmit();
                }
              });
            }
          });
      }
    },

    Finalsubmit() {
      var err = false;

      this.loading = true;
      var total = this.form.vacancy * this.form.price * this.form.hour;

      var htmldata =
        "You are creating a job list with " +
        this.form.vacancy +
        " vacancy for " +
        this.form.hour +
        " hours. <br><br> Hourly Service Fee : <b>$" +
        this.form.price +
        "</b> <br><br>Est. Total Service Fee: <b>$" +
        total;

      if (err == false) {
        this.$store
          .dispatch(POST_API, {
            data: {
              id: atob(this.job_id),
              title: this.form.title,
              job_scope: this.form.job_scope,
              description: this.form.requirement,
              categories: this.form.service_categories_id,
              showCosting: true,
              work_site_contact_person: this.form.work_site_contact_person,
              work_site_contact_person_country_code: "+65",
              work_site_contact_person_mobile_no: this.form.work_site_contact_person_mobile_no,
              work_site_contact_person_2: this.form.work_site_contact_person_2,
              work_site_contact_person_2_country_code: "+65",
              work_site_contact_person_2_mobile_no: this.form.work_site_contact_person_2_mobile_no,
              salaryMax: this.form.max_salary ? this.form.max_salary : 0,
              remarks: this.form.remarks,
              working_hours:
                this.form.min == "00" || this.form.min == "" || this.form.min == null
                  ? this.form.hour + ".0"
                  : this.form.min == "45"
                  ? this.form.hour + ".75"
                  : this.form.min == "15"
                  ? this.form.hour + ".25"
                  : this.form.hour + ".5",

              // working_hours: (this.form.min == '00' || this.form.min == '' || this.form.min == null) ? this.form.hour + '.0' : this.form.hour +'.5',

              vacancy: this.form.vacancy,
              price: this.form.price,
              userId: this.user_id,
              company_admin_id: this.company_admin_id ? this.company_admin_id : this.user_id,
              company_id: this.company_id,
              job_type: this.form.role_type_id,
              start_date: this.form.start_date ? this.form.start_date : null,
              start_time: this.form.starttime,

              package: this.form.package,
              remaining_free: this.remaining_jobs,
              unit: this.form.unit,
              lunch_pay_hours: this.lunch_pay_not_provided == "yes" ? this.form.lunch_pay_hours : 0,
              lunch_pay_not_provided: this.lunch_pay_not_provided,
              is_ot: this.is_ot,
              address: this.form.formattedAddress,
              lat: this.center.lat,
              long: this.center.lng,
              is_hygiene_cert_req: this.form.is_hygiene_cert_req == "yes" ? "yes" : null,
              medical_history: this.form.medical_history == "yes" ? "yes" : null,
              gender: this.form.gender ? this.form.gender : null,
              // race_id:this.form.race_id ? this.form.race_id : null,
              min_age: this.form.min_age ? this.form.min_age : null,
              max_age: this.form.max_age ? this.form.max_age : null,
              platform_fee: this.form.platform_fee ? this.form.platform_fee : 0,
              from_v2: "yes",
              jobFor: this.form.jobFor,
              selected_company_id: this.form.jobFor == "forcompany" ? this.companyName.id : "",
              selected_individual_id:
                this.form.jobFor == "forindividual" ? this.individualName : "",
              sub_categories: this.subvalue,
              cpf_not_paid: this.form.cpf_not_paid == true ? "yes" : "no",
              is_perm_partime: this.is_perm_partime,
              instruction_images: this.initialSelected,
              instruction_images_checked: this.instruction_img,
              department: this.form.department ? this.form.department : null,
              true_vacancies:this.form.true_vacancy,

            },
            api: "/api/updateDailyJob",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showDismissibleAlert = true;
              this.$swal({
                position: "center",
                // icon: 'error',
                imageUrl: "/assets/images/404_elcy.gif",
                customClass: {
                  container: "mascot_error_container",
                },
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500,
              });
            } else {
              Bus.$emit("active_users");
              this.loading = false;

              this.$swal({
                position: "center",
                icon: "success",
                iconColor: "#4c8bf5",
                title: "Job Updated Successfully.",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                window.location.replace("/v2/admin/database/dp-jobs");
              });
            }
          });
      }
    },

    getEmployeeProfile() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            id: this.user_id,
          },
          api: "/api/employee-profile",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            return [];
          } else {
            if (this.$store.getters.getResults.data) {
              if (this.$store.getters.getResults.data.contact_number == "12345678") {
                this.form.mobile = "";
              } else {
                this.form.mobile = this.$store.getters.getResults.data.contact_number;
              }
              this.form.email = this.$store.getters.getResults.data.email;
              if (this.$store.getters.getResults.data.name == "test.co") {
                this.form.company_name = "";
              } else {
                this.form.company_name = this.$store.getters.getResults.data.name;
              }
              this.form.company_reg_no = this.$store.getters.getResults.data.registration_id;
              this.form.overview = this.$store.getters.getResults.data.description;
              this.form.office_address = this.$store.getters.getResults.data.address;
              this.form.image = this.$store.getters.getResults.data.company_logo;
              this.form.email_verify = this.$store.getters.getResults.user["email_verified"];
              this.form.mobile_verify = this.$store.getters.getResults.user["mobile_verified"];
              this.acStatus = this.$store.getters.getResults.user["account_verification_status"];

              this.company_status = this.$store.getters.getResults.company_status;
              this.companyAdminDPStatus =
                this.$store.getters.getResults.companyAdminData[0].account_verification_status;
              this.companyAdminPostingPaused =
                this.$store.getters.getResults.companyAdminData[0].posting_paused;
            } else {
              this.form.mobile = "";
              this.form.email = "";
            }
          }
        });
    },

    isInteger(num) {
      return num.toString().indexOf(".") === -1;
    },

    getHourlyCost() {
      return this.$store
        .dispatch(POST_API, {
          data: {},
          api: "/api/hourly-cost",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            return [];
          } else {
            if (this.$store.getters.getResults.data) {
              this.costListing = this.$store.getters.getResults.data;
            }
          }
        });
    },

    postedJobs() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            id: this.user_id,
          },
          api: "/api/postedJobs",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            return [];
          } else {
            if (this.$store.getters.getResults.data) {
              this.form.postedjobs = this.$store.getters.getResults.data[0].posted_jobs;

              // this.getPackageData();
            }
          }
        });
    },

    companyData() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            id: this.user_id,
          },
          api: "/api/companyData",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            return [];
          } else {
            if (this.$store.getters.getResults.data) {
              // this.form.service_categories_id = this.$store.getters.getResults.data[0].company_industry_id;
            }
          }
        });
    },

    getQualification() {
      this.$store
        .dispatch(POST_API, {
          data: {},
          api: "/api/qualification",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;

            this.$swal({
              position: "center",
              // icon: 'error',
              imageUrl: "/assets/images/404_elcy.gif",
              customClass: {
                container: "mascot_error_container",
              },
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.qualifications = this.$store.getters.getResults.data;
          }
        });
    },
    getLocation() {
      this.$store
        .dispatch(POST_API, {
          data: {},
          api: "/api/location",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;

            this.$swal({
              position: "center",
              // icon: 'error',
              imageUrl: "/assets/images/404_elcy.gif",
              customClass: {
                container: "mascot_error_container",
              },
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.locations = this.$store.getters.getResults.data;
            this.other_location_id = this.$store.getters.getResults.other_location_id;
            this.locations.forEach((element) => {
              if (element.location_names) {
                element.location_names_val = element.location_names.split(",");
                element.location_names_id = element.location_ids.split(",");
                // location[element.location_ids.split(',')] = element.location_names.split(',')
              }
            });
          }
        });
    },
    getPositionLevel() {
      this.$store
        .dispatch(POST_API, {
          data: {},
          api: "/api/position-level",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;

            this.$swal({
              position: "center",
              // icon: 'error',
              imageUrl: "/assets/images/404_elcy.gif",
              customClass: {
                container: "mascot_error_container",
              },
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.position_levels = this.$store.getters.getResults.data;
          }
        });
    },
    getjobRoleType() {
      this.$store
        .dispatch(POST_API, {
          data: {},
          api: "/api/job-role-type",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;

            this.$swal({
              position: "center",
              // icon: 'error',
              imageUrl: "/assets/images/404_elcy.gif",
              customClass: {
                container: "mascot_error_container",
              },
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.role_types = this.$store.getters.getResults.data;
            this.role_types.forEach((element) => {
              if (element.name == "part-time") {
                this.part_time_id = element.id;
              }
            });
            $(".perHourRate").css("display", "none");
            $(".minMaxSalary").css("display", "block");
          }
        });
    },

    getElCoins() {
      this.$store
        .dispatch(POST_API, {
          data: {
            user_id: this.company_admin_id ? this.company_admin_id : this.user_id,
          },
          api: "/api/userCoins",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;

            this.$swal({
              position: "center",
              // icon: 'error',
              imageUrl: "/assets/images/404_elcy.gif",
              customClass: {
                container: "mascot_error_container",
              },
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.coins = this.$store.getters.getResults.data;
          }
        });
    },

    sendEmail() {
      this.$store
        .dispatch(POST_API, {
          data: {
            title: this.form.title,
            job_scope: this.form.job_scope,
            description: this.form.requirement,
            categories: this.form.service_categories_id,
            showCosting: false,
            work_site_contact_person: this.form.work_site_contact_person,
            work_site_contact_person_country_code: "+65",
            work_site_contact_person_mobile_no: this.form.work_site_contact_person_mobile_no,
            work_site_contact_person_2: this.form.work_site_contact_person_2,
            work_site_contact_person_2_country_code: "+65",
            work_site_contact_person_2_mobile_no: this.form.work_site_contact_person_2_mobile_no,
            salaryMax: this.form.max_salary ? this.form.max_salary : 0,
            remarks: this.form.remarks,
            // working_hours: (this.form.min == '00' || this.form.min == '' || this.form.min == null) ? this.form.hour + '.0' : this.form.hour +'.5',
            working_hours:
              this.form.min == "00" || this.form.min == "" || this.form.min == null
                ? this.form.hour + ".0"
                : this.form.min == "45"
                ? this.form.hour + ".75"
                : this.form.min == "15"
                ? this.form.hour + ".25"
                : this.form.hour + ".5",

            vacancy: this.form.vacancy,
            price: this.form.price,
            userId: this.user_id,
            company_admin_id: this.company_admin_id ? this.company_admin_id : this.user_id,
            company_id: this.company_id,
            job_type: this.form.role_type_id,
            start_date: this.form.start_date ? this.form.start_date : null,
            start_time: this.form.starttime,
            package: this.form.package,
            remaining_free: this.remaining_jobs,
            unit: this.form.unit,
            lunch_pay_hours: this.lunch_pay_not_provided == "yes" ? this.form.lunch_pay_hours : 0,
            lunch_pay_not_provided: this.lunch_pay_not_provided,
            is_ot: this.is_ot,
            status: "active",
            address: this.form.formattedAddress,
            lat: this.center.lat,
            long: this.center.lng,
            is_hygiene_cert_req: this.form.is_hygiene_cert_req == "yes" ? "yes" : null,
            medical_history: this.form.medical_history == "yes" ? "yes" : null,
            gender: this.form.gender ? this.form.gender : null,
            // race_id:this.form.race_id ? this.form.race_id : null,
            min_age: this.form.min_age ? this.form.min_age : null,
            max_age: this.form.max_age ? this.form.max_age : null,
          },
          api: "/api/sendInsufficientCoinEmail",
        })
        .then(() => {
          this.$swal({
            position: "center",
            icon: "success",
            iconColor: "#4c8bf5",
            title: "Mail sent Successfully.",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    },
    getDpJobTemplate() {
      this.$store
        .dispatch(POST_API, {
          data: {
            userId: this.user_id,
          },
          api: "/api/findUniqueDPJobs4Employer",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;

            this.$swal({
              position: "center",
              // icon: 'error',
              imageUrl: "/assets/images/404_elcy.gif",
              customClass: {
                container: "mascot_error_container",
              },
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.jobTemplates = this.$store.getters.getResults.data;
          }
        });
    },
    getCompanyList() {
      this.$store
        .dispatch(POST_API, {
          data: {
            authUserID: this.user_id,
            from_v2: "yes",
          },
          api: "/api/company-listing-dp-manage-job",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;

            this.$swal({
              position: "center",
              // icon: 'error',
              imageUrl: "/assets/images/404_elcy.gif",
              customClass: {
                container: "mascot_error_container",
              },
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.selectedCompany = this.$store.getters.getResults.data;
            return this.selectedCompany;
          }
        });
    },
    company(value) {
      this.selected_company = value.user.is_individual_contractor;
      this.form.cpf_not_paid = this.selected_company == "no" ? false : true;
      this.getGallery(value.user.id);
      this.getDepartments(value.id);
      this.getBranchList();
      this.getJobList(value.id);
    },
    getGallery(id) {
      return this.$store
        .dispatch(POST_API, {
          data: {
            user_id: id,
            userId: this.user_id,
            from_v2: "yes",
          },
          api: "/api/get-gallery-records-dp-manage-job",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.dataImages = this.$store.getters.getResults.data.data;
            return this.dataImages;
          }
        });
    },

    templateChangeHandler() {
      this.$store
        .dispatch(POST_API, {
          data: {
            userId: this.user_id,
            selectedJobTemplate: this.selectedJobTemplate,
          },
          api: "/api/handleDpJobTemplate",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;

            this.$swal({
              position: "center",
              // icon: 'error',
              imageUrl: "/assets/images/404_elcy.gif",
              customClass: {
                container: "mascot_error_container",
              },
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            // console.log(this.$store.getters.getResults.message)
            if (this.$store.getters.getResults.data) {
              this.form.title = this.$store.getters.getResults.data.title;
              this.form.requirement = this.$store.getters.getResults.data.description;
              this.form.job_scope = this.$store.getters.getResults.data.job_scope;
              this.form.remarks = this.$store.getters.getResults.data.remarks
                ? this.$store.getters.getResults.data.remarks
                : "";

              this.form.gender = this.$store.getters.getResults.data.gender;
              // this.form.race_id = this.$store.getters.getResults.data.race_id;
              this.form.min_age = this.$store.getters.getResults.data.min_age;
              this.form.max_age = this.$store.getters.getResults.data.max_age;

              this.form.unit = this.$store.getters.getResults.data.unit;
              this.form.postal_code = this.$store.getters.getResults.data.postal_code;
              this.form.formattedAddress = this.$store.getters.getResults.data.address;
              this.l1 = parseFloat(this.$store.getters.getResults.data.lat);
              this.l2 = parseFloat(this.$store.getters.getResults.data.long);
              this.form.work_site_contact_person =
                this.$store.getters.getResults.data.work_site_contact_person;
              this.form.work_site_contact_person_mobile_no =
                this.$store.getters.getResults.data.work_site_contact_person_mobile_no;
              this.form.work_site_contact_person_2 =
                this.$store.getters.getResults.data.work_site_contact_person_2;
              this.form.work_site_contact_person_2_mobile_no =
                this.$store.getters.getResults.data.work_site_contact_person_2_mobile_no;

              this.form.service_categories_id = this.$store.getters.getResults.data.serviceCat.id;
              this.form.is_hygiene_cert_req =
                this.$store.getters.getResults.data.is_hygiene_cert_req != null
                  ? this.$store.getters.getResults.data.is_hygiene_cert_req == "yes"
                    ? "yes"
                    : false
                  : "";
              this.form.medical_history =
                this.$store.getters.getResults.data.medical_history != null
                  ? this.$store.getters.getResults.data.medical_history == "yes"
                    ? "yes"
                    : false
                  : "";
              if (this.form.service_categories_id == this.fb_category_id) {
                $(".fb_category_class").css("display", "block");
              } else {
                $(".fb_category_class").css("display", "none");
              }
              this.lunch_pay_not_provided =
                this.$store.getters.getResults.data.lunch_pay_not_provided;
              this.is_ot = this.$store.getters.getResults.data.is_ot;

              let marker = {
                lat: parseFloat(this.$store.getters.getResults.data.lat),
                lng: parseFloat(this.$store.getters.getResults.data.long),
              };

              this.markers.push({ position: marker });
              // this.addMarker();
              // this.places.push(this.currentPlace);
              this.center = marker;

              this.start = this.$store.getters.getResults.data.start_time;
              if (this.start) {
                this.startArray = this.start.split(":");
                this.form.starttime.hh =
                  this.startArray[0] > 12 ? this.startArray[0] - 12 : this.startArray[0];

                if (this.form.starttime.hh.length) {
                  this.form.starttime.hh.length != 2
                    ? (this.form.starttime.hh = "0" + this.form.starttime.hh)
                    : this.form.starttime.hh;
                } else {
                  this.form.starttime.hh < 10
                    ? (this.form.starttime.hh = "0" + this.form.starttime.hh)
                    : this.form.starttime.hh;
                }
                this.form.starttime.mm = this.startArray[1] ? this.startArray[1] : "00";
                this.form.starttime.A = this.startArray[0] >= 12 ? "PM" : "AM";
              } else {
                (this.form.starttime.hh = "00"), (this.form.starttime.mm = "00");
                this.form.starttime.A = "AM";
              }

              this.form.vacancy = this.$store.getters.getResults.data.total_vacancies;
              this.form.price = parseFloat(this.$store.getters.getResults.data.hourly_cost);
              var sum_hrs =
                parseFloat(this.$store.getters.getResults.data.working_hours) +
                parseFloat(this.$store.getters.getResults.data.lunch_pay_hours);

              if (this.$store.getters.getResults.data.lunch_pay_not_provided == "yes") {
                this.form.lunch_pay_hours = this.$store.getters.getResults.data.lunch_pay_hours;
                this.form.hour = this.isInteger(sum_hrs)
                  ? Math.floor(sum_hrs)
                  : Math.floor(sum_hrs);
              } else {
                this.form.lunch_pay_hours = "";
                this.form.hour = this.isInteger(this.$store.getters.getResults.data.working_hours)
                  ? this.$store.getters.getResults.data.working_hours
                  : Math.floor(this.$store.getters.getResults.data.working_hours);
              }
              var h = sum_hrs.toString();

              var split = this.isInteger(h) ? [h] : h.split(".");
              console.log(split, 1);
              if (split.length > 1) {
                if (split[1] == "25") {
                  this.form.min = "15";
                } else {
                  this.form.min =
                    split[1] == "75"
                      ? "45"
                      : split[1] == "5"
                      ? "30"
                      : split[1] == "25"
                      ? "15"
                      : "00";
                }
              } else {
                this.form.min = "00";
              }
            }
          }
        });
    },

    getDateRange(startDate, endDate, dateFormat) {
      var dates = [],
        end = moment(endDate),
        diff = endDate.diff(startDate, "days");

      if (!startDate.isValid() || !endDate.isValid() || diff <= 0) {
        return;
      }

      if (diff > 0) {
        for (var i = 0; i <= diff; i++) {
          if (i == 0) {
            dates.push(end.format(dateFormat));
          } else {
            dates.push(end.subtract(1, "d").format(dateFormat));
          }
        }
      } else {
        dates.push(end.format(dateFormat));
      }
      return dates;
    },
    resetModal() {
      this.form.id = "";
      this.supervisorName = "";
    },
    getJobList(company_id) {
      return this.$store
        .dispatch(POST_API, {
          data: {
            company_id: company_id,
          },
          api: "/api/unique-dp-job-list",
        })
        .then(() => {
          this.unqiueJobList = this.$store.getters.getResults.data.data;
          return this.unqiueJobList;
        });
    },
    getEditJob() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            status: this.status,
            page: this.currentPage,
            keyword: this.filter,
            id: this.templateId || atob(this.job_id),
          },
          api: "/api/dp-jobs-edit-data",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            return [];
          } else {
            this.items = this.$store.getters.getResults.data.data.job_details;



            console.log(this.items, "tetetetete");
            if (this.$store.getters.getResults.data.data.company) {
              this.companyName = this.$store.getters.getResults.data.data.company[0];
              this.company(this.companyName);
              this.form.jobFor = "forcompany";
            } else if (this.$store.getters.getResults.data.data.individual) {
              this.individualName = this.$store.getters.getResults.data.data.individual[0];
              this.individual(this.individualName);
              this.selected_individual =
                this.$store.getters.getResults.data.data.individual[0].is_individual_contractor;
              this.form.jobFor = "forindividual";
            }
            if (this.items.job_type_id == "2") {
              this.form.onDemandCheck = true;
            }
            this.form.gender = this.$store.getters.getResults.data.data.job_details.gender;
            this.form.race_id = this.$store.getters.getResults.data.data.job_details.race_id;
            this.form.min_age = this.$store.getters.getResults.data.data.job_details.min_age;
            this.form.max_age = this.$store.getters.getResults.data.data.job_details.max_age;
            this.form.title = this.$store.getters.getResults.data.data.job_details.title;
            this.form.requirement =
              this.$store.getters.getResults.data.data.job_details.description;
            this.form.job_scope = this.$store.getters.getResults.data.data.job_details.job_scope;
            this.form.remarks = this.$store.getters.getResults.data.data.job_details.remarks;
            this.form.unit = this.$store.getters.getResults.data.data.job_details.unit;
            this.form.formattedAddress =
              this.$store.getters.getResults.data.data.job_details.address;
            this.form.is_hygiene_cert_req =
              this.$store.getters.getResults.data.data.job_details.is_hygiene_cert_req != null
                ? this.$store.getters.getResults.data.data.job_details.is_hygiene_cert_req == "yes"
                  ? true
                  : false
                : "";
            this.form.medical_history =
              this.$store.getters.getResults.data.data.job_details.medical_history != null
                ? this.$store.getters.getResults.data.data.job_details.medical_history == "yes"
                  ? true
                  : false
                : "";
            this.form.work_site_contact_person =
              this.$store.getters.getResults.data.data.job_details.work_site_contact_person;
            this.form.work_site_contact_person_country_code =
              this.$store.getters.getResults.data.data.job_details.work_site_contact_person_country_code;
            this.form.work_site_contact_person_mobile_no =
              this.$store.getters.getResults.data.data.job_details.work_site_contact_person_mobile_no;

            this.form.work_site_contact_person_2 =
              this.$store.getters.getResults.data.data.job_details.work_site_contact_person_2;
            this.form.work_site_contact_person_2_country_code =
              this.$store.getters.getResults.data.data.job_details.work_site_contact_person_2_country_code;
            this.form.work_site_contact_person_2_mobile_no =
              this.$store.getters.getResults.data.data.job_details.work_site_contact_person_2_mobile_no;

            this.form.service_categories_id =
              this.$store.getters.getResults.data.data.job_details.service_categories_id;
            this.oldCatVal =
              this.$store.getters.getResults.data.data.job_details.service_categories_id != 0
                ? this.$store.getters.getResults.data.data.job_details.service_categories_id
                : "";
            this.form.categoryValue =
              this.$store.getters.getResults.data.data.job_details.service_categories_id != 0
                ? this.$store.getters.getResults.data.data.job_details.service_categories_id
                : "";
            this.form.cpf_not_paid =
              this.$store.getters.getResults.data.data.job_details.cpf_not_paid == "yes"
                ? true
                : false;


            // this.instruction_images = this.$store.getters.getResults.data.data.job_details.job_instruction_imgs.length > 0 ? 'yes' : 'no';

            var imgs = [];
            imgs = this.$store.getters.getResults.data.data.job_details.job_instruction_imgs;
            if (imgs) {
              imgs.forEach(function (key, index) {
                delete key.job_id;
                delete key.img_id;
                $("img#" + key.id).append('<span class="counter_img">1</span>');
              });
              this.initialSelected = imgs;
            }

            let marker = {
              lat: parseFloat(this.$store.getters.getResults.data.data.job_details.lat),
              lng: parseFloat(this.$store.getters.getResults.data.data.job_details.long),
            };

            this.markers.push({ position: marker });
            // console.log(this.places);
            this.addMarker();
            // this.places.push(this.currentPlace);


            this.center = marker;
            this.start = this.$store.getters.getResults.data.data.job_details.start_time;
            if (this.start) {
              this.startArray = this.start.split(":");
            console.log('lllllllllllll',this.startArray,this.form.starttime);

              this.form.starttime.hh =
                this.startArray[0] > 12 ? this.startArray[0] - 12 : this.startArray[0];
              if (this.form.starttime.hh.length) {
                this.form.starttime.hh.length != 2
                  ? (this.form.starttime.hh = "0" + this.form.starttime.hh)
                  : this.form.starttime.hh;
              } else {
                this.form.starttime.hh < 10
                  ? (this.form.starttime.hh = "0" + this.form.starttime.hh)
                  : this.form.starttime.hh;
              }
              this.form.starttime.mm = this.startArray[1] ? this.startArray[1] : "00";
              this.form.starttime.A = this.startArray[0] >= 12 ? "PM" : "AM";
            } else {
              (this.form.starttime.hh = "00"), (this.form.starttime.mm = "00");
              this.form.starttime.A = "AM";
            }

            this.form.vacancy =
              this.$store.getters.getResults.data.data.job_details.total_vacancies;
            this.form.true_vacancy =
              this.$store.getters.getResults.data.data.job_details.true_vacancies;
            this.form.department = this.$store.getters.getResults.data.data.job_details.department;
            this.form.branch = this.$store.getters.getResults.data.data.job_details.branch;
            this.form.assigned_supervisor =
              this.$store.getters.getResults.data.data.job_details.assigned_to;

            this.form.price = parseFloat(
              this.$store.getters.getResults.data.data.job_details.hourly_cost
            );
            this.form.lunch_pay_hours =
              this.$store.getters.getResults.data.data.job_details.lunch_pay_not_provided == "yes"
                ? this.$store.getters.getResults.data.data.job_details.lunch_pay_hours
                : 0;

            var sum_hrs =
              parseFloat(this.$store.getters.getResults.data.data.job_details.working_hours) +
              parseFloat(this.$store.getters.getResults.data.data.job_details.lunch_pay_hours);

           if (this.$store.getters.getResults.data.lunch_pay_not_provided == "yes") {
                this.form.lunch_pay_hours = this.$store.getters.getResults.data.lunch_pay_hours;
                this.form.hour = this.isInteger(sum_hrs)
                  ? Math.floor(sum_hrs)
                  : Math.floor(sum_hrs);
              } else {
                this.form.lunch_pay_hours = "";
                this.form.hour = this.isInteger(this.$store.getters.getResults.data.working_hours)
                  ? this.$store.getters.getResults.data.working_hours
                  : Math.floor(this.$store.getters.getResults.data.working_hours);
              }

              console.log(sum_hrs,'sums');

            var h = sum_hrs.toString();

              var split = this.isInteger(h) ? [h] : h.split(".");
              console.log(split, 1);
              if (split.length > 1) {
                if (split[1] == "25") {
                  this.form.min = "15";
                } else {
                  this.form.min =
                    split[1] == "75"
                      ? "45"
                      : split[1] == "5"
                      ? "30"
                      : split[1] == "25"
                      ? "15"
                      : "00";
                }
              } else {
                this.form.min = "00";
              }

              console.log(this.form.min,'minut',this.form.hour);

            this.lunch_pay_not_provided =
              this.$store.getters.getResults.data.data.job_details.lunch_pay_not_provided;

            this.is_ot = this.$store.getters.getResults.data.data.job_details.is_ot;
            this.is_perm_partime =
              this.$store.getters.getResults.data.data.job_details.is_perm_partime;

            return this.items;
          }
        });
    },
    isInteger(num) {
      return num.toString().indexOf(".") === -1;
    },
    updateRange() {
      this.dateArr = this.getDateRange(
        moment(this.dateRange.startDate, "DD.MM.YYYY"),
        moment(this.dateRange.endDate, "DD.MM.YYYY"),
        "YYYY-MM-DD"
      );
    },
  },

  mounted() {
    //document.title = "EL Connect - Employer - Post Job";
    $("#minSal").keyup(function (e) {
      if (/\D/g.test(this.value)) {
        // Filter non-digits from input value.
        this.value = this.value.replace(/\D/g, "");
      }
    });

    $("#maxSal").keyup(function (e) {
      if (/\D/g.test(this.value)) {
        // Filter non-digits from input value.
        this.value = this.value.replace(/\D/g, "");
      }
    });

    console.log(this.$store.getters.currentUser, "user");
    $("#exp").keyup(function (e) {
      if (/\D/g.test(this.value)) {
        // Filter non-digits from input value.
        this.value = this.value.replace(/\D/g, "");
      }
    });
    this.user_id =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : "";
    this.package_id =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.package_id : "";
    this.company_admin_id =
      this.$store.getters.currentUser != null
        ? this.$store.getters.currentUser.company_admin_id
        : "";
    this.company_id =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.company_id : "";
    Bus.$emit("active_users");

    if (this.package_id == 13) {
      $("#specific_job_cat").show();
    }
    this.getDropdownData("service_categories");
    this.getEditJob();
    // this.geolocate();
    this.getEmployeeProfile();
    this.getQualification();
    this.getPositionLevel();
    this.getLocation();

    this.getjobRoleType();
    this.postedJobs();
    //this.companyData();
    // this.getPackageData();
    this.getElCoins();
    this.getHourlyCost();
    this.getCompanyList();

    // this.getRace();

    $(".dashboard-content-container .simplebar-scroll-content").animate(
      {
        scrollTop: 0,
      },
      "fast"
    );
  },
};
</script>
