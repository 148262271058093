<template>
  <div>
    <!-- Dashboard Headline -->

    <!-- Page Content
        ================================================== -->
    <div class="">
      <!-- <div class="dashboard-headline"> -->
      <!-- <h1>Post Job</h1> -->
      <div id="breadcrumb-v2" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb :items="breadCrumb()" />
        </div>
      </div>
      <nav id="breadcrumbs" class="dark employer_nav">
        <ul>
          <li><a href="#">Home</a></li>
          <li><a href="#">Job</a></li>
          <li>Post Job</li>
        </ul>
      </nav>
      <!-- </div> -->
      <div class="notification warning" v-if="ftStatus == 'pending' || ftStatus == 'incomplete'">
        <p>
          <span class="icon-feather-info"></span>
          <a :href="'employer-profile'"
            >Account status {{ ftStatus }}. Jobs can be posted after profile completion.</a
          >
        </p>
        <a class="close"></a>
      </div>
      <div class="row common_row">
        <b-form
          method="post"
          id="register-account-form"
          class="emp_frm_mobile nw_frm desktop_margin_top_zero"
        >
          <!-- <p id="error"></p> -->
          <!-- Dashboard Box -->

          <div class="col-xl-12 post_job_mbl">
            <div class="dashboard-box desktop_margin_top_zero">
              <!-- Headline -->
              <div class="headline">
                <h3>
                  <i class="icon-material-outline-business-center"></i> Job Submission Form<span
                    id="headingText"
                    style="color: #4c8bf5; font-weight: 600; font-size: 15px"
                  >
                    (Enjoy 2 complimentary Jobs Ads posting every month.)</span
                  >
                  <span id="headingText2" style="color: #4c8bf5; font-weight: 600; font-size: 15px">
                    (Enjoy 2 complimentary Jobs Ads posting every month.)</span
                  >
                </h3>
              </div>

              <div class="content">
                <ul class="fields-ul second_indiv_step_wrap">
                  <li class="common_padding_30 custom_pagination_border_radius">
                    <div class="row">
                      <div class="col-xl-4">
                        <div class="submit-field error_submit_field">
                          <h5>Job Title <span class="required_star">*</span></h5>
                          <b-form-input
                            type="text"
                            v-model="form.title"
                            id="company_reg_no"
                            class="input-text with-border required-field"
                            name="company_reg_no"
                            placeholder=""
                            value=""
                            :disabled="ftStatus == 'pending' || ftStatus == 'incomplete'"
                          />
                        </div>
                        <span class="error_x_white">Please enter Job Title !</span>
                      </div>
                      <div class="col-xl-4">
                        <div class="submit-field error_submit_field">
                          <h5>Job Role Type <span class="required_star">*</span></h5>
                          <b-form-select
                            v-model="form.role_type_id"
                            id="job_role_type"
                            class="job_role_type"
                            @change="salaryTypeData(form.role_type_id)"
                            :disabled="ftStatus == 'pending' || ftStatus == 'incomplete'"
                          >
                            <option value="" disabled>Select Role Type</option>
                            <option :value="role_type.id" v-for="role_type in role_types">
                              {{ role_type.name | removeUnderscore }}
                            </option>
                          </b-form-select>
                        </div>
                        <span class="error_x_white">Please enter Job Role Type !</span>
                      </div>
                      <div class="col-xl-2 minMaxSalary">
                        <div class="submit-field">
                          <h5>{{ salaryType }}</h5>
                          <b-form-input
                            type="text"
                            v-model="form.min_salary"
                            id="minSal"
                            class="input-text with-border"
                            name="office_address"
                            placeholder="Min (SGD)"
                            value=""
                            :disabled="ftStatus == 'pending' || ftStatus == 'incomplete'"
                          />
                        </div>
                        <span class="error_x_white">Please enter Min (SGD) Amount !</span>
                      </div>
                      <div class="col-xl-2 minMaxSalary">
                        <div class="submit-field">
                          <h5 class="hidden_h5">Salary</h5>
                          <b-form-input
                            type="text"
                            v-model="form.max_salary"
                            id="maxSal"
                            class="desktop_margin_top_zero input-text with-border big_screen_margin_top_zero mobile_tab_margin_top_zero"
                            name="office_address"
                            placeholder="Max (SGD)"
                            value=""
                            :disabled="ftStatus == 'pending' || ftStatus == 'incomplete'"
                          />
                        </div>
                        <span class="error_x_white">Please enter Max (SGD) Amount !</span>
                      </div>
                      <!-- <div class="col-xl-4 perHourRate">
                                            <div class="submit-field">
                                                <h5>Per Hour Rate <span class="required_star">*</span></h5>
                                                <b-form-input
                                                    type="text"

                                                    id="per_hour"
                                                    class="input-text with-border required-field"
                                                    name="office_address"
                                                    placeholder="Per Hour Rate (SGD)"
                                                    value=""
                                                />
                                            </div>
                                            <span class="error_x_white">Please enter Per Hour Rate !</span>
                                        </div> -->

                      <div class="tags_div">
                        <div class="col-xl-4">
                          <div class="submit-field">
                            <h5>Tags</h5>
                            <!--<b-form-tags input-id="tags-basic" v-model="form.tagArr" ></b-form-tags>-->
                            <b-form-tags
                              v-model="form.tagArr"
                              no-outer-focus
                              class="mb-2 new_tag_ul"
                              :disabled="ftStatus == 'pending' || ftStatus == 'incomplete'"
                            >
                              <template
                                v-slot="{
                                  tags,
                                  inputAttrs,
                                  inputHandlers,
                                  tagVariant,
                                  addTag,
                                  removeTag,
                                }"
                              >
                                <b-input-group
                                  class="mb-2 full_skill_border hundered_width desktop_margin_bottom_28"
                                  :disabled="ftStatus == 'pending' || ftStatus == 'incomplete'"
                                  :class="
                                    ftStatus == 'pending' || ftStatus == 'incomplete'
                                      ? 'common_disabled_exist'
                                      : 'common_disabled_not_exist'
                                  "
                                >
                                  <b-form-input
                                    v-bind="inputAttrs"
                                    v-on="inputHandlers"
                                    placeholder="Add Tags"
                                    class="form-control remove_shadow tag_input_check"
                                    @keydown.enter.prevent="tagScroll()"
                                  ></b-form-input>
                                  <b-input-group-append class="plus_div new_plus_div">
                                    <b-button
                                      @click="
                                        addTag();
                                        tagScroll();
                                      "
                                      variant="primary"
                                      class="add_tag_plus_btn"
                                      >+</b-button
                                    >
                                  </b-input-group-append>
                                </b-input-group>
                                <div
                                  class="d-inline-block desktop_width_hundered selected_tag_full_div"
                                  style="font-size: 1.5rem"
                                  v-if="form.tagArr.length > 0"
                                >
                                  <h5>Selected Tags</h5>
                                  <div
                                    class="tag_blocks_section d-inline-block"
                                    style="font-size: 1.5rem"
                                    :class="form.tagArr.length > 2 ? 'inner_scroll' : 'not_scroll'"
                                  >
                                    <b-form-tag
                                      v-for="tag in tags"
                                      @remove="removeTag(tag)"
                                      :key="tag"
                                      :title="tag"
                                      :variant="tagVariant"
                                      class="mr-1"
                                      >{{ tag }}</b-form-tag
                                    >
                                  </div>
                                </div>
                              </template>
                            </b-form-tags>
                          </div>
                        </div>
                      </div>

                      <div class="cat_div">
                        <div class="top_inline_div">
                          <div class="col-xl-4 cat_inner_divs">
                            <div class="submit-field error_submit_field">
                              <h5>Job Category <span class="required_star">*</span></h5>
                              <b-form-select
                                v-model="form.service_categories_id"
                                id="jbs_category"
                                class="jbs_category"
                                @change="showCategory(form.service_categories_id)"
                                :disabled="ftStatus == 'pending' || ftStatus == 'incomplete'"
                              >
                                <option value="" disabled>Select Category</option>
                                <option :value="category.id" v-for="category in categories">
                                  {{ category.name | capitalize }}
                                </option>
                              </b-form-select>
                            </div>
                            <span class="error_x_white">Please enter Job Category !</span>
                          </div>
                          <div class="col-xl-4 cat_inner_divs other_category_class">
                            <div
                              class="submit-field"
                              v-if="form.service_categories_id == other_category_id"
                            >
                              <h5>Specify Job Category</h5>
                              <b-form-input
                                type="text"
                                v-model="form.other_service_category"
                                class="input-text with-border"
                                name="company_reg_no"
                                placeholder="Please Specify Category"
                                value=""
                              />
                            </div>
                          </div>
                        </div>

                        <div class="top_inline_div">
                          <div class="col-xl-4 cat_inner_divs">
                            <div class="submit-field error_submit_field">
                              <h5>Location <span class="required_star">*</span></h5>
                              <select
                                v-model="form.location_id"
                                id="job_location"
                                class="job_location custom-select"
                                @change="showLocation(form.location_id)"
                                :disabled="ftStatus == 'pending' || ftStatus == 'incomplete'"
                              >
                                <option value="" selected="selected" disabled>
                                  Select Location
                                </option>
                                <optgroup :label="location.title" v-for="location in locations">
                                  <option
                                    :value="location.location_names_id[index]"
                                    v-for="(area, index) in location.location_names_val"
                                  >
                                    {{ area | capitalize }}
                                  </option>
                                </optgroup>
                              </select>
                              <!-- <b-form-select v-model="form.location_id" id="job_location" class="job_location">
                                                        <option value="" disabled>Select Location</option>
                                                        <option :value="location.id" v-for="location in locations">{{location.title | capitalize}}</option>
                                                    </b-form-select> -->
                            </div>
                            <span class="error_x_white">Please enter Location !</span>
                          </div>

                          <div class="col-xl-4 cat_inner_divs other_location_class">
                            <div class="submit-field" v-if="form.location_id == other_location_id">
                              <h5>Specify Location</h5>
                              <b-form-input
                                type="text"
                                v-model="form.other_job_location"
                                class="input-text with-border"
                                name="company_reg_no"
                                placeholder="Please Specify Location"
                                value=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-12">
                        <div class="submit-field">
                          <h5>Job Description <span class="required_star">*</span></h5>
                          <ckeditor
                            v-model="form.description"
                            :config="editorConfig"
                            id="jb_description"
                            class="jb_description"
                            :readOnly="ftStatus == 'pending' || ftStatus == 'incomplete'"
                            :class="
                              ftStatus == 'pending' || ftStatus == 'incomplete'
                                ? 'common_ckeditor_disabled_exist'
                                : 'common_ckeditor_disabled_not_exist'
                            "
                          ></ckeditor>
                          <!-- <textarea type="text" class="with-border" value="" v-model="form.description"></textarea> -->
                        </div>
                        <span class="error_x_white">Please enter job description !</span>
                      </div>
                      <div class="col-xl-4">
                        <div class="submit-field error_submit_field">
                          <h5>Minimum Qualification <span class="required_star">*</span></h5>
                          <b-form-select
                            v-model="form.qualification_id"
                            id="job_qualification"
                            class="job_qualification"
                            :disabled="ftStatus == 'pending' || ftStatus == 'incomplete'"
                          >
                            <option value="" disabled>Select Qualification</option>
                            <option
                              :value="qualification.id"
                              v-for="qualification in qualifications"
                            >
                              {{ qualification.title | capitalize }}
                            </option>
                          </b-form-select>
                        </div>
                        <span class="error_x_white">Please enter Qualification !</span>
                      </div>

                      <div class="col-xl-4">
                        <div class="submit-field error_submit_field">
                          <h5>Minimum Year of Experience <span class="required_star">*</span></h5>
                          <select
                            v-model="form.experience"
                            class="custom-select min_year_exp"
                            id="min_year_exp"
                            :disabled="ftStatus == 'pending' || ftStatus == 'incomplete'"
                          >
                            <option value="" disabled>Select</option>
                            <option
                              :value="experience_yr"
                              v-for="(experience_yr, id) in experience_yrs"
                              :key="id"
                            >
                              {{ experience_yr }}
                            </option>
                          </select>
                        </div>
                        <span class="error_x_white">Please enter Minimum Year of Experience !</span>
                      </div>

                      <div class="col-xl-4">
                        <div class="submit-field error_submit_field">
                          <h5>Position Level <span class="required_star">*</span></h5>
                          <b-form-select
                            v-model="form.position_level_id"
                            id="position_level"
                            class="position_level"
                            :disabled="ftStatus == 'pending' || ftStatus == 'incomplete'"
                          >
                            <option value="" disabled>Select Position Level</option>
                            <option
                              :value="position_level.id"
                              v-for="position_level in position_levels"
                            >
                              {{ position_level.title | capitalize }}
                            </option>
                          </b-form-select>
                        </div>
                        <span class="error_x_white">Please enter Position Level !</span>
                      </div>

                      <div class="col-xl-4">
                        <div
                          class="submit-field desktop_margin_bottom_zero mobile_margin_bottom_28 big_screen_bottom_28"
                        >
                          <h5>Job Posting Package</h5>
                          <b-form-select
                            v-if="company_admin.free_job_posts > 0"
                            v-model="form.package"
                            id="package"
                            class="package desktop_margin_bottom_zero"
                          >
                            <option value="" disabled>Select Posting Package</option>
                            <!-- <option value="free">{{expiry}} days - Free Post - {{form.packageName}} ({{remaining_jobs}} Remaining)</option> -->
                            <option value="coins">
                              {{ expiry }} Days - {{ company_admin.free_job_posts }} Free Ads
                              Posting
                            </option>
                          </b-form-select>
                          <b-form-select
                            v-else
                            v-model="form.package"
                            id="package"
                            class="package desktop_margin_bottom_zero"
                            :disabled="ftStatus == 'pending' || ftStatus == 'incomplete'"
                          >
                            <option value="" disabled>Select Posting Package</option>
                            <!-- <option value="free">{{expiry}} days - Free Post - {{form.packageName}} ({{remaining_jobs}} Remaining)</option> -->
                            <option value="coins">{{ expiry }} days - 1 Job Ads</option>
                          </b-form-select>
                        </div>
                        <span class="error_x_white">Please select Posting Package !</span>
                      </div>

                      <div class="col-xl-4">
                        <div
                          class="submit-field error_submit_field desktop_margin_bottom_zero datepicker_input_err"
                        >
                          <h5>Posting Start Date <span class="required_star">*</span></h5>
                          <datepicker
                            v-model="form.scheduled_post"
                            :open-date="new Date()"
                            :disabled-dates="disabledFromDate"
                            :format="customFormatterTwo"
                            id="scheduled_post"
                            class="scheduled_post add_calender_icon show_calender_top"
                            :disabled="ftStatus == 'pending' || ftStatus == 'incomplete'"
                            :class="
                              ftStatus == 'pending' || ftStatus == 'incomplete'
                                ? 'common_date_disabled_exist'
                                : 'common__date_disabled_not_exist'
                            "
                          ></datepicker>
                        </div>
                        <span class="error_x_white desktop_margin_bottom_zero"
                          >Please enter Posting Start Date !</span
                        >
                      </div>

                      <div class="col-xl-4">

                        <div class="submit-field desktop_margin_bottom_zero post_job_tool">

                          <h5 class="common_opacity_zero">Featured ad</h5>
                          <b-form-checkbox
                            v-model="form.feature_job"
                            value="yes"
                            unchecked-value="no"
                            class="middle_checkbox opacity_unset common_disabled_check_box"
                            :disabled="ftStatus == 'pending' || ftStatus == 'incomplete'"

                            ><strong
                              >Featured Post <span>(Only {{ total_feat }} left)</span>
                            </strong></b-form-checkbox
                          >
                          <div class="tooltip_icon" @click="mobileToolTipFeaturedJOb">
                            <span class="material-icons-outlined info_tooltip_icon"
                              >info_outlined</span
                            >
                            <span class="tooltip_text tooltip_txt_mobile_number"
                              >Your job posting will be pushed to the top of the board for 14
                              days</span
                            >
                          </div>
                        </div>
                      </div>

                      <b-container class="single_accordian">
                        <!-- <br> -->
                        <div>
                          <div class="faq-content-box clearfix">
                            <div>
                              <div class="accordion-listing-box">
                                <b-card no-body>
                                  <b-card-header
                                    header-tag="header"
                                    role="tab"
                                    class="contact_header"
                                  >
                                    <b-button
                                      v-b-toggle="
                                        'accordion-emergency-contact-details-admin-edit-1'
                                      "
                                      class="m-1"
                                      variant="info"
                                    >
                                      <div class="dashboard-box">
                                        <div class="headline">
                                          <h3>
                                            <i
                                              class="material-icons-outlined new_icon_sidebar_material accordian_contact"
                                              >mail_outlined</i
                                            >Email Notifications for updates (max 3 users)
                                          </h3>
                                        </div>
                                      </div>
                                    </b-button>
                                  </b-card-header>
                                  <b-collapse
                                    visible
                                    id="accordion-emergency-contact-details-admin-edit-1"
                                    accordion="my-accordion"
                                    role="tabpanel"
                                    class="error_mrgn"
                                  >
                                    <b-card-body>
                                      <div class="content">
                                        <div class="row">
                                          <div class="col-xl-4">
                                            <b-form-group>
                                              <b-form-checkbox
                                                v-model="form.admin_notify"
                                                value="yes"
                                                unchecked-value="no"
                                                :disabled="
                                                  ftStatus == 'pending' || ftStatus == 'incomplete'
                                                "
                                                class="common_disabled_check_box"
                                                ><strong
                                                  >Admin to receive notification?
                                                </strong></b-form-checkbox
                                              >
                                            </b-form-group>
                                          </div>
                                        </div>

                                        <div v-for="(user, index) in form.users" :key="index">
                                          <!-- <div class="row"> -->

                                          <!-- <div class="col-xl-4">
                                                                                            <div class="submit-field web_bottom_zero">
                                                                                                <h5>Select User</h5>

                                                                                            </div>

                                                                                        </div>

                                                                                        <div class="col-xl-4 mobile_bottom_two_eight tab_margin_bottom_28 big_screen_bottom_28">
                                                                                            <div class="submit-field web_bottom_zero">
                                                                                                <h5>Email Address</h5>
                                                                                            </div>

                                                                                        </div> -->
                                          <!-- <div class="col-xl-4 mobile_bottom_two_eight tab_margin_bottom_28 big_screen_bottom_28"> -->
                                          <!-- <a href="javascript:void(0);" class="margin_top_zero_993 button ripple-effect eleven_zero_one_margin_top_zero mobile_anchore_set common_vertical_align_bottom" @click="addUser()" v-if="form.users.length <=2"><span class="add_plus">+</span> Add More</a> -->
                                          <!-- </div> -->

                                          <!-- </div> -->
                                          <div class="row" v-if="company_users.length > 0">
                                            <div
                                              class="col-xl-4 custom_tab_and_big_screen_col_md_4"
                                            >
                                              <div
                                                class="submit-field"
                                                :class="
                                                  index > 0
                                                    ? 'if_email_notification_exist'
                                                    : 'if_email_notification_not_exist'
                                                "
                                              >
                                                <b-form-select
                                                  data-size="7"
                                                  title="Select User"
                                                  v-model="user.user_id"
                                                  class="custom-select web_bottom_zero"
                                                  id="sel_user"
                                                  :disabled="
                                                    ftStatus == 'pending' ||
                                                    ftStatus == 'incomplete'
                                                  "
                                                >
                                                  <option value="">Select User</option>
                                                  <option
                                                    :value="cu.id"
                                                    v-for="(cu, i) in company_users"
                                                    :disabled="checkDisabled(cu.id)"
                                                    :key="i"
                                                  >
                                                    {{ cu.full_name }}
                                                  </option>
                                                </b-form-select>
                                              </div>
                                            </div>

                                            <div
                                              class="col-xl-4 custom_tab_and_big_screen_col_md_4"
                                            >
                                              <div
                                                class="submit-field"
                                                :class="
                                                  index > 0
                                                    ? 'if_email_notification_exist'
                                                    : 'if_email_notification_not_exist'
                                                "
                                              >
                                                <input
                                                  type="text"
                                                  readonly
                                                  :value="filterUser(user.user_id)"
                                                  class="with-border web_bottom_zero"
                                                  id="user_email"
                                                  placeholder="User Email"
                                                  :disabled="
                                                    ftStatus == 'pending' ||
                                                    ftStatus == 'incomplete'
                                                  "
                                                  :class="
                                                    ftStatus == 'pending' ||
                                                    ftStatus == 'incomplete'
                                                      ? 'common_disabled_exist'
                                                      : 'common_disabled_not_exist'
                                                  "
                                                />
                                              </div>
                                            </div>
                                            <div
                                              class="mobile_tab_margin_top_zero red_div col-xl-4 custom_tab_and_big_screen_col_md_4"
                                            >
                                              <!-- <a href="javascript:void(0);" class="button margin_top_zero_993 ripple-effect  mobile_anchore_set common_vertical_align_bottom" @click="removeUser(index)" style="background-color: red;"  ><span class="add_plus">x</span>Remove</a> -->
                                              <a
                                                href="javascript:void(0);"
                                                class="new_plus_btn_round button ripple-effect eleven_zero_one_margin_top_zero mobile_anchore_set common_vertical_align_bottom"
                                                @click="addUser()"
                                                v-if="form.users.length <= 2 && index == 0"
                                                ><span class="add_plus material-icons-outlined"
                                                  >add_circle_outlined</span
                                                ></a
                                              >

                                              <a
                                                href="javascript:void(0);"
                                                class="new_plus_btn_round button ripple-effect eleven_zero_one_margin_top_zero mobile_anchore_set common_vertical_align_bottom minus_margin_top_btn"
                                                @click="removeUser(index)"
                                                v-if="index > 0"
                                                ><span
                                                  class="add_plus material-icons-outlined red_icon"
                                                  >remove_circle_outlined</span
                                                ></a
                                              >
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </b-card-body>
                                  </b-collapse>
                                </b-card>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-container>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <!-- <div class="mobile_list_mrgn_btm"> -->
          <div class="">
            <div
              class="col-xl-12 post_jb_btn mobile_left_right_zero_pdng new_post_jb_btn_mobile_tab"
            >
                <a
                @click="submitDraft"
                class="mr-3 add_white_bg common_anchor_hover button ripple-effect big margin-top-30 employee_btn common_hover_effect_white_bg"
                style="color: #4c8bf5"
                ><span class="icon-material-outline-drafts"></span> Save As Draft</a
              >
              <a
                
                @click="submit"
                class="button ripple-effect big margin-top-30 employee_btn"
                style="background-color: #4c8bf5 !important; border: 2px solid #4c8bf5 !important;"
                >+ Post Job</a
              >
            </div>
          </div>
          <!-- Button -->
        </b-form>
      </div>
    </div>

    <!-- Spacer -->

    <!-- Spacer / End-->
    <!-- Row / End -->
  </div>
</template>

<script>
import { POST_API } from "../../store/actions.type";
import CKEditor from "ckeditor4-vue";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import Bus from "../../event-bus";

export default {
  data() {
    return {
      form: {
        title: "",
        type: "full_time",
        role_type_id: "",
        service_categories_id: "",
        other_service_category: "",

        location: "",
        min_salary: "",
        max_salary: "",
        tagArr: [],
        description: "",
        qualification_id: "",
        location_id: "",
        other_job_location: "",
        position_level_id: "",
        experience: "",
        level: "",
        // package: 'coins',
        package: "",
        scheduled_post: new Date(),
        user_id: "",
        company_id: "",
        postedjobs: "",
        company_id: "",
        users: [
          {
            user_id: "",
          },
        ],
        admin_notify: "no",
        feature_job: "no",
      },
      salaryType: "Salary",
      other_location_id: "",
      other_category_id: "",
      expiry: "",
      coinsperjob: "",
      company_admin: [],
      part_time_id: "",
      package_id: "",
      remaining_jobs: 0,
      qualifications: [],
      locations: [],
      position_levels: [],
      categories: [],
      role_types: [],
      experience_yrs: [
        "No experience",
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
      ],
      showDismissibleAlert: false,
      error_message: "",
      isActive: 1,
      personal: true,
      links: false,
      disabled: true,
      disabledFromDate: {
        from: new Date(new Date().setMonth(new Date().getMonth() + 3)),
        to: new Date(Date.now() - 8640000),
      },
      editorConfig: {
        versionCheck : false,
        removeButtons:
          "Maximize,Paste,Cut,Copy,Undo,Redo,SpellCheck,Link,Unlink,Anchor,Image,Table,Source,Subscript,Superscript,Blockquote,Styles,PasteText,RemoveFormat,Scayt,PasteFromWord,RemoveFormat,Blockquote,HorizontalRule,SpecialChar,Strike,About,Format",
        //extraPlugins: 'copyformatting,colorbutton,bidi,colordialog,widget,justify'
      },
      coreStyles_bold: {
        element: "b",
        overrides: "strong",
      },
      // Custom style definition for the Italic feature.
      coreStyles_italic: {
        element: "i",
        overrides: "em",
      },
      isSubmitting: false,
      ftStatus: "",
      free_feature: "",
      total_feat: 0,
      loading: "",
      company_users: [],
    };
  },
  components: {
    ckeditor: CKEditor.component,

    Datepicker,
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    removeUnderscore: function (value) {
      if (!value) return "";
      var i,
        frags = value.split("-");
      for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
      }
      return frags.join(" ");
    },
  },
  methods: {
    mobileToolTipFeaturedJOb() {
      var newWindowWidth = $(window).width();
      if (newWindowWidth <= 999) {
        $(".tooltip_txt_mobile_number").toggleClass("tooltip_text_mobile_view_number");
      }
    },
    breadCrumb() {
      var item = [
        {
          to: { name: "manage-jobs" },
          text: "Manage Job Ads",
        },
        {
          to: null,
          text: "Post Job",
        },
      ];
      return item;
    },
    tagScroll() {
      if ($(".tag_input_check").val() != "") {
        $(".inner_scroll").animate({ scrollLeft: "+=1500" }, 500);
      }
      $(".not_scroll").animate({ scrollLeft: "+=1500" }, 500);
    },
    activeTab() {
      this.personal = true;
      this.links = false;
    },

    showSalary(id) {
      if (id == this.part_time_id) {
        $(".perHourRate").css("display", "block");
        $(".minMaxSalary").css("display", "none");
      } else {
        $(".perHourRate").css("display", "none");
        $(".minMaxSalary").css("display", "block");
      }
    },
    showCategory(id) {
      if (id == this.other_category_id) {
        $(".other_category_class").css("display", "block");
      } else {
        $(".other_category_class").css("display", "none");
      }
    },

    salaryTypeData(id) {
      if (id == "4") {
        this.salaryType = "Per Hour Rate";
      } else {
        this.salaryType = "Salary";
      }
    },
    showLocation(id) {
      if (id == this.other_location_id) {
        $(".other_location_class").css("display", "block");
      } else {
        $(".other_location_class").css("display", "none");
      }
    },

    customFormatterTwo(date) {
      return moment(date).format("DD-MM-YYYY");
    },

    showAlert() {
      this.$swal({
        position: "center",
        // icon: 'error',
        imageUrl: "/assets/images/404_elcy.gif",
        customClass: {
          container: "mascot_error_container",
        },
        title: this.error_message,
        showConfirmButton: false,
        timer: 1500,
      });
    },

    getDropdownData(type) {
      this.$store
        .dispatch(POST_API, {
          data: {
            table: "service_categories",
          },
          api: "/api/dropdown-list-category",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;

            this.$swal({
              position: "center",
              // icon: 'error',
              imageUrl: "/assets/images/404_elcy.gif",
              customClass: {
                container: "mascot_error_container",
              },
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.categories = this.$store.getters.getResults.data;
            this.other_category_id = this.$store.getters.getResults.other_category_id;
            $(".other_category_class").css("display", "none");
          }
        });
    },
    submit() {
      var err = false;
      $(".required-field").each(function () {
        if ($(this).val() == "") {
          err = true;
          $(this).addClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
        } else {
          $(this).removeClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
        }
      });

      $(".job_role_type").each(function () {
        if (!$("#job_role_type").val()) {
          err = true;

          $(this).addClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
        } else {
          $(this).removeClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
        }
      });

      $("#job_role_type").change(function () {
        if ($(this).val() != "") {
          $(this).removeClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
        }
      });
      if ($("#maxSal").val()) {
        if (!$("#minSal").val()) {
          err = true;

          $("#minSal").addClass("error");
          $("#minSal").parent().parent().find(".required_content").addClass("show");
          $("#minSal").parent().parent().find(".error_x_white").addClass("show");
        } else {
          $("#minSal").removeClass("error");
          $("#minSal").parent().parent().find(".required_content").removeClass("show");
          $("#minSal").parent().parent().find(".error_x_white").removeClass("show");
        }
      }

      if ($("#minSal").val()) {
        if (!$("#maxSal").val()) {
          err = true;

          $("#maxSal").addClass("error");
          $("#maxSal").parent().parent().find(".required_content").addClass("show");
          $("#maxSal").parent().parent().find(".error_x_white").addClass("show");
        } else {
          $("#maxSal").removeClass("error");
          $("#maxSal").parent().parent().find(".required_content").removeClass("show");
          $("#maxSal").parent().parent().find(".error_x_white").removeClass("show");
        }
      }

      $(".jbs_category").each(function () {
        if (!$("#jbs_category").val()) {
          err = true;

          $(this).addClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
        } else {
          $(this).removeClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
        }
      });

      $("#jbs_category").change(function () {
        if ($(this).val() != "") {
          $(this).removeClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
        }
      });
      $(".job_location").each(function () {
        if (!$("#job_location").val()) {
          err = true;

          $(this).addClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
        } else {
          $(this).removeClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
        }
      });
      $("#job_location").change(function () {
        if ($(this).val() != "") {
          $(this).removeClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
        }
      });
      $(".job_qualification").each(function () {
        if (!$("#job_qualification").val()) {
          err = true;

          $(this).addClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
        } else {
          $(this).removeClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
        }
      });
      $("#job_qualification").change(function () {
        if ($(this).val() != "") {
          $(this).removeClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
        }
      });
      $(".min_year_exp").each(function () {
        if (!$("#min_year_exp").val()) {
          err = true;

          $(this).addClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
        } else {
          $(this).removeClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
        }
      });

      $("#min_year_exp").change(function () {
        if ($(this).val() != "") {
          $(this).removeClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
        }
      });

      $(".position_level").each(function () {
        if (!$("#position_level").val()) {
          err = true;

          $(this).addClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
        } else {
          $(this).removeClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
        }
      });

      $("#position_level").change(function () {
        if ($(this).val() != "") {
          $(this).removeClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
        }
      });

      $(".scheduled_post").each(function () {
        if (!$("#scheduled_post").val()) {
          err = true;

          $(this).addClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
        } else {
          $(this).removeClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
        }
      });

      $("#package").each(function () {
        if (!$("#package").val()) {
          err = true;

          $(this).addClass("error");
          $(this).parent().parent().find(".required_content").addClass("show");
          $(this).parent().parent().find(".error_x_white").addClass("show");
        } else {
          $(this).removeClass("error");
          $(this).parent().parent().find(".required_content").removeClass("show");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        }
      });

      $("#job_role_type").change(function () {
        $(this).removeClass("error");
        $(this).parent().parent().find(".error_x_white").removeClass("show");
      });
      $("#jbs_category").change(function () {
        $(this).removeClass("error");
        $(this).parent().parent().find(".error_x_white").removeClass("show");
      });
      $("#job_location").change(function () {
        $(this).removeClass("error");
        $(this).parent().parent().find(".error_x_white").removeClass("show");
      });
      $("#job_qualification").change(function () {
        $(this).removeClass("error");
        $(this).parent().parent().find(".error_x_white").removeClass("show");
      });
      $("#package").change(function () {
        $(this).removeClass("error");
        $(this).parent().parent().find(".error_x_white").removeClass("show");
      });
      $("#min_year_exp").change(function () {
        $(this).removeClass("error");
        $(this).parent().parent().find(".error_x_white").removeClass("show");
      });
      $("#position_level").change(function () {
        $(this).removeClass("error");
        $(this).parent().parent().find(".error_x_white").removeClass("show");
      });
      $(".scheduled_post").focusin(function () {
        $(this).removeClass("error");
        $(this).parent().parent().find(".error_x_white").removeClass("show");
      });
      // $(".scheduled_post").focusout(function(){
      //    $(this).addClass('error');
      //    $(this).parent().parent().find('.error_x_white').addClass('show');
      // });

      $("#package").focusout(function () {
        if ($("#package").val() === "") {
          $("#package").addClass("invalid");
          $("#package-warning-message").show();
        } else {
          $("#package").removeClass("invalid");
          $("#package-warning-message").hide();
        }
      });

      setTimeout(function () {
        $(".second_indiv_step_wrap .error").first().focus();

        $(".error").unbind("keypress");
        $(".error").bind("keypress", function () {
          $(this).removeClass("error");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
        });
      }, 100);

      if (this.form.service_categories_id == this.other_category_id) {
        if (!this.form.other_service_category) {
          err = true;
          this.$swal({
            position: "center",
            // icon: 'error',
            imageUrl: "/assets/images/404_elcy.gif",
            customClass: {
              container: "mascot_error_container",
            },
            title: "Please specify the category.",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }

      if (this.form.location_id == this.other_location_id) {
        if (!this.form.other_job_location) {
          err = true;
          this.$swal({
            position: "center",
            // icon: 'error',
            imageUrl: "/assets/images/404_elcy.gif",
            customClass: {
              container: "mascot_error_container",
            },
            title: "Please specify the location.",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }

      if (this.form.max_salary && this.form.min_salary) {
        if (parseInt(this.form.max_salary) < parseInt(this.form.min_salary)) {
          err = true;
          this.$swal({
            position: "center",
            // icon: 'error',
            imageUrl: "/assets/images/404_elcy.gif",
            customClass: {
              container: "mascot_error_container",
            },
            title: "Max salary cannot be less than min salary.",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }

      if (this.form.role_type_id == this.part_time_id) {
        // this.form.min_salary = 0
      }

      this.loading = true;

      if (err == false) {
        this.$store
          .dispatch(POST_API, {
            data: {
              title: this.form.title,
              type: this.form.type,
              email: this.form.email,
              category: this.form.service_categories_id,
              other_category: this.form.other_service_category,

              location: this.form.location_id,
              other_location: this.form.other_job_location,

              salarymin: this.form.min_salary ? this.form.min_salary : 0,
              salaryMax: this.form.max_salary ? this.form.max_salary : 0,
              tag: this.form.tagArr,
              description: this.form.description,
              qualification: this.form.qualification_id,
              experience: this.form.experience,
              level: this.form.position_level_id,
              userId: this.logged_in_user_id,
              actual_posted_by: this.logged_in_user_id,
              company_admin_id: this.company_admin_id
                ? this.company_admin_id
                : this.logged_in_user_id,
              company_id: this.company_id,
              job_type_id: this.form.role_type_id,
              start_date: this.form.scheduled_post
                ? moment(this.form.scheduled_post).format("YYYY-MM-DD")
                : null,
              package: this.form.package,
              remaining_free: this.remaining_jobs,
              status: "active",
              notify_users: this.form.users,
              admin_notify: this.form.admin_notify,
              feature_job: this.form.feature_job,
            },
            api: "/api/postJobPopup",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              if (this.error_message == "Insufficient ELC Credits for posting new jobs Ads.") {
                this.insufficientELCoins();
              } else {
                this.showDismissibleAlert = true;
                this.$swal({
                  position: "center",
                  // icon: 'error',
                  imageUrl: "/assets/images/404_elcy.gif",
                  customClass: {
                    container: "mascot_error_container",
                  },
                  title: this.error_message,
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
            } else {
              Bus.$emit("active_users");
              this.loading = false;
              var company_rem_coins = this.$store.getters.getResults.company_rem_coins;

              this.$store.getters.getResults.company_rem_job_ads = this.$store.getters.getResults
                .company_rem_job_ads
                ? this.$store.getters.getResults.company_rem_job_ads
                : 0;

              this.$store.getters.getResults.mainadmin[0].free_job_posts = this.$store.getters
                .getResults.mainadmin[0].free_job_posts
                ? this.$store.getters.getResults.mainadmin[0].free_job_posts
                : 0;
              var company_rem_job_ads =
                parseInt(this.$store.getters.getResults.company_rem_job_ads) +
                parseInt(this.$store.getters.getResults.mainadmin[0].free_job_posts);

              var start_date_popup = this.form.scheduled_post
                ? moment(this.form.scheduled_post).format("DD-MM-YYYY")
                : null;
              var featured_msg = "";
              var postingPackage = "";

              if (this.form.feature_job) {

                // featured_msg = '<p class=""><b>Featured Post Cost: </b> 0 ELC Credits</p>';
                featured_msg =
                  '<p class="middle_para_job"><b class="bold_txts">Featured Post: </b> <span class="end_span">14 Days</span></p>';
              }

              if (this.company_admin.free_job_posts <= 0) {
                postingPackage =
                  '<p class="middle_para_job"><b class="bold_txts">Job Posting Package: </b>' +
                  "<span class='end_span'>" +
                  this.expiry +
                  " Days</span>";
                ("</p>");
              } else {
                postingPackage =
                  '<p class="middle_para_job"><b class="bold_txts">Job Posting Package: </b>' +
                  "<span class='end_span'>" +
                  this.expiry +
                  " Days</span>";
                ("</p>");
              }
              //   if (this.company_admin.free_job_posts <= 0) {
              //     postingPackage =
              //       '<p class="middle_para_job"><b>Job Posting Package: </b>' +
              //       this.expiry +
              //       " days - " +
              //       this.coinsperjob +
              //       " ELC Credits</p>";
              //   } else {
              //     postingPackage =
              //       '<p class="middle_para_job"><b>Job Posting Package: </b>' +
              //       this.expiry +
              //       " days - " +
              //       this.company_admin.free_job_posts +
              //       " Complimentary Ads Posting</p>";
              //   }
              this.$swal({
                title: "Please Confirm",
                html:
                  "<center><h2 class='ft_green_h2'>1 Job Ads</h2><b class='deduct_b'>will be deducted from your account</b><br>" +
                  '<p class="current_b mb-4">Current Balance - ' +
                  company_rem_job_ads +
                  " Job Ads</p>" +
                  " </center><p class='middle_para_job'><b class='bold_txts'>Posting Start Date: </b>" +
                  "<span class='end_span'>" +
                  start_date_popup +
                  "</p></span>" +
                  postingPackage +
                  featured_msg,

                // type: 'warning',
                // icon: 'warning',
                // imageUrl: "/assets/images/success-ftjob-popup.png",
                // imageWidth: 88,
                // imageHeight: 85,
                // type: "success",
                // icon: "success",
                showCloseButton: true,
                showCancelButton: true,
                cancelButtonColor: "#d33",
                confirmButtonText: "Proceed",
                customClass: {
                  container: "ft_job_post_container_class full_time_single_popup",
                },
              }).then((res) => {
                if (res.isConfirmed) {
                  this.Finalsubmit();
                } else {
                }
              });
            }
          });
      }
    },
    Finalsubmit() {
      var err = false;
      $(".required-field").each(function () {
        if ($(this).val() == "") {
          err = true;
          $(this).addClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
        } else {
          $(this).removeClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
        }
      });

      $(".job_role_type").each(function () {
        if (!$("#job_role_type").val()) {
          err = true;

          $(this).addClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
        } else {
          $(this).removeClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
        }
      });

      $("#job_role_type").change(function () {
        if ($(this).val() != "") {
          $(this).removeClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
        }
      });
      if ($("#maxSal").val()) {
        if (!$("#minSal").val()) {
          err = true;

          $("#minSal").addClass("error");
          $("#minSal").parent().parent().find(".required_content").addClass("show");
          $("#minSal").parent().parent().find(".error_x_white").addClass("show");
        } else {
          $("#minSal").removeClass("error");
          $("#minSal").parent().parent().find(".required_content").removeClass("show");
          $("#minSal").parent().parent().find(".error_x_white").removeClass("show");
        }
      }

      if ($("#minSal").val()) {
        if (!$("#maxSal").val()) {
          err = true;

          $("#maxSal").addClass("error");
          $("#maxSal").parent().parent().find(".required_content").addClass("show");
          $("#maxSal").parent().parent().find(".error_x_white").addClass("show");
        } else {
          $("#maxSal").removeClass("error");
          $("#maxSal").parent().parent().find(".required_content").removeClass("show");
          $("#maxSal").parent().parent().find(".error_x_white").removeClass("show");
        }
      }

      $(".jbs_category").each(function () {
        if (!$("#jbs_category").val()) {
          err = true;

          $(this).addClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
        } else {
          $(this).removeClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
        }
      });

      $("#jbs_category").change(function () {
        if ($(this).val() != "") {
          $(this).removeClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
        }
      });
      $(".job_location").each(function () {
        if (!$("#job_location").val()) {
          err = true;

          $(this).addClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
        } else {
          $(this).removeClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
        }
      });
      $("#job_location").change(function () {
        if ($(this).val() != "") {
          $(this).removeClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
        }
      });
      $(".job_qualification").each(function () {
        if (!$("#job_qualification").val()) {
          err = true;

          $(this).addClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
        } else {
          $(this).removeClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
        }
      });
      $("#job_qualification").change(function () {
        if ($(this).val() != "") {
          $(this).removeClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
        }
      });
      $(".min_year_exp").each(function () {
        if (!$("#min_year_exp").val()) {
          err = true;

          $(this).addClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
        } else {
          $(this).removeClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
        }
      });

      $("#min_year_exp").change(function () {
        if ($(this).val() != "") {
          $(this).removeClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
        }
      });

      $(".position_level").each(function () {
        if (!$("#position_level").val()) {
          err = true;

          $(this).addClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
        } else {
          $(this).removeClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
        }
      });

      $("#position_level").change(function () {
        if ($(this).val() != "") {
          $(this).removeClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
        }
      });

      $(".scheduled_post").each(function () {
        if (!$("#scheduled_post").val()) {
          err = true;

          $(this).addClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
        } else {
          $(this).removeClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
        }
      });

      $("#package").each(function () {
        if (!$("#package").val()) {
          err = true;

          $(this).addClass("error");
          $(this).parent().parent().find(".required_content").addClass("show");
          $(this).parent().parent().find(".error_x_white").addClass("show");
        } else {
          $(this).removeClass("error");
          $(this).parent().parent().find(".required_content").removeClass("show");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        }
      });

      $("#job_role_type").change(function () {
        $(this).removeClass("error");
        $(this).parent().parent().find(".error_x_white").removeClass("show");
      });
      $("#jbs_category").change(function () {
        $(this).removeClass("error");
        $(this).parent().parent().find(".error_x_white").removeClass("show");
      });
      $("#job_location").change(function () {
        $(this).removeClass("error");
        $(this).parent().parent().find(".error_x_white").removeClass("show");
      });
      $("#job_qualification").change(function () {
        $(this).removeClass("error");
        $(this).parent().parent().find(".error_x_white").removeClass("show");
      });
      $("#package").change(function () {
        $(this).removeClass("error");
        $(this).parent().parent().find(".error_x_white").removeClass("show");
      });
      $("#min_year_exp").change(function () {
        $(this).removeClass("error");
        $(this).parent().parent().find(".error_x_white").removeClass("show");
      });
      $("#position_level").change(function () {
        $(this).removeClass("error");
        $(this).parent().parent().find(".error_x_white").removeClass("show");
      });
      $(".scheduled_post").focusin(function () {
        $(this).removeClass("error");
        $(this).parent().parent().find(".error_x_white").removeClass("show");
      });
      // $(".scheduled_post").focusout(function(){
      //    $(this).addClass('error');
      //    $(this).parent().parent().find('.error_x_white').addClass('show');
      // });

      $("#package").focusout(function () {
        if ($("#package").val() === "") {
          $("#package").addClass("invalid");
          $("#package-warning-message").show();
        } else {
          $("#package").removeClass("invalid");
          $("#package-warning-message").hide();
        }
      });

      setTimeout(function () {
        $(".second_indiv_step_wrap .error").first().focus();

        $(".error").unbind("keypress");
        $(".error").bind("keypress", function () {
          $(this).removeClass("error");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
        });
      }, 100);

      if (this.form.service_categories_id == this.other_category_id) {
        if (!this.form.other_service_category) {
          err = true;
          this.$swal({
            position: "center",
            // icon: 'error',
            imageUrl: "/assets/images/404_elcy.gif",
            customClass: {
              container: "mascot_error_container",
            },
            title: "Please specify the category.",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }

      if (this.form.location_id == this.other_location_id) {
        if (!this.form.other_job_location) {
          err = true;
          this.$swal({
            position: "center",
            // icon: 'error',
            imageUrl: "/assets/images/404_elcy.gif",
            customClass: {
              container: "mascot_error_container",
            },
            title: "Please specify the location.",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }

      if (this.form.max_salary && this.form.min_salary) {
        if (parseInt(this.form.max_salary) < parseInt(this.form.min_salary)) {
          err = true;
          this.$swal({
            position: "center",
            // icon: 'error',
            imageUrl: "/assets/images/404_elcy.gif",
            customClass: {
              container: "mascot_error_container",
            },
            title: "Max salary cannot be less than min salary.",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }

      if (this.form.role_type_id == this.part_time_id) {
        // this.form.min_salary = 0
      }

      this.loading = true;

      if (err == false) {
        this.$store
          .dispatch(POST_API, {
            data: {
              title: this.form.title,
              type: this.form.type,
              email: this.form.email,
              category: this.form.service_categories_id,
              other_category: this.form.other_service_category,

              location: this.form.location_id,
              other_location: this.form.other_job_location,

              salarymin: this.form.min_salary ? this.form.min_salary : 0,
              salaryMax: this.form.max_salary ? this.form.max_salary : 0,
              tag: this.form.tagArr,
              description: this.form.description,
              qualification: this.form.qualification_id,
              experience: this.form.experience,
              level: this.form.position_level_id,
              userId: this.logged_in_user_id,
              actual_posted_by: this.logged_in_user_id,
              company_admin_id: this.company_admin_id
                ? this.company_admin_id
                : this.logged_in_user_id,
              company_id: this.company_id,
              job_type_id: this.form.role_type_id,
              start_date: this.form.scheduled_post
                ? moment(this.form.scheduled_post).format("YYYY-MM-DD")
                : null,
              package: this.form.package,
              remaining_free: this.remaining_jobs,
              status: "active",
              notify_users: this.form.users,
              admin_notify: this.form.admin_notify,
              feature_job: this.form.feature_job,
            },
            api: "/api/postJob",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              if (this.error_message == "Insufficient ELC Credits for posting new jobs Ads.") {
                this.insufficientELCoins();
              } else {
                this.showDismissibleAlert = true;
                this.$swal({
                  position: "center",
                  // icon: 'error',
                  imageUrl: "/assets/images/404_elcy.gif",
                  customClass: {
                    container: "mascot_error_container",
                  },
                  title: this.error_message,
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
            } else {
              Bus.$emit("active_users");
              this.loading = false;
              this.$swal({
                position: "center",
                icon: "success",
                iconColor: "#4c8bf5",
                title: "Job Posted successfully.",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                window.location.replace("/v2/employer/manage-jobs");
              });
            }
          });
      }
    },
    showSuccessAlert(message) {
      this.$swal({
        position: "center",
        icon: "success",
        iconColor: "#4c8bf5",
        title: message,
        showConfirmButton: false,
        timer: 2000,
      }).then((result) => {});
    },

    insufficientELCoins() {
      this.$swal({
        // title: 'Please Confirm',
        // icon: 'error',
        imageUrl: "/assets/images/404_elcy.gif",
        // customClass: {
        //   container: "mascot_error_container",
        // },
        title: "You have insufficient Job Ads. <br/>Would you like to buy more job ads?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        customClass: {
          text: "pop_up_text_class",
          container: "pop_up_containerclass post_job_image_size",
          popup: "popup_class",
          header: "pop_up_header_class",
          title: "pop_up_title_class",
          closeButton: "pop_up_close_button_class",
          icon: "cross_icon_class",
          image: "your-image-class",
          content: "pop_up_content_class",
          input: "pop_up_input_class",
          actions: "pop_up_actions_class",
          confirmButton: "pop_up_cancel_button_class",
          cancelButton: "pop_up_confirm_button_class",
          footer: "pop_up_footer_class",
        },
      })
        .then((result) => {
          if (result.value) {
            return this.$store
              .dispatch(POST_API, {
                data: {
                  user_id: this.logged_in_user_id,
                  company_id: this.company_id,
                  company_admin_id: this.company_admin_id
                    ? this.company_admin_id
                    : this.logged_in_user_id,
                },
                api: "/api/buyMoreCoinsEmail",
              })
              .then(() => {
                if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
                } else {
                  var message = this.$store.getters.getResults.message;
                  // this.showSuccessAlert(message);
                  window.location.replace("/v2/employer/elcoins");
                }
              });
            // window.location.replace("/v2/employer/elcoins");
          } else {
            window.location.replace("/v2/employer/manage-jobs");
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    checkELCoinsJobPost() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            user_id: this.logged_in_user_id,
            company_id: this.company_id,
            company_admin_id: this.company_admin_id
              ? this.company_admin_id
              : this.logged_in_user_id,
          },
          api: "/api/checkELCoinsJobPost",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            if (this.error_message == "Insufficient ELC Credits for posting new jobs Ads.") {
              this.insufficientELCoins();
            } else {
              this.showDismissibleAlert = true;
              this.$swal({
                position: "center",
                // icon: 'error',
                imageUrl: "/assets/images/404_elcy.gif",
                customClass: {
                  container: "mascot_error_container",
                },
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500,
              });
            }
          }
        });
    },
    submitDraft() {
      var err = false;

      $(".required-field").each(function () {
        if ($(this).val() == "") {
          err = true;
          $(this).addClass("error");
          $(this).parent().parent().find(".required_content").addClass("show");
          $(this).parent().parent().find(".error_x_white").addClass("show");
        } else {
          $(this).removeClass("error");
          $(this).parent().parent().find(".required_content").removeClass("show");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        }
      });

      $(".job_role_type").each(function () {
        if (!$("#job_role_type").val()) {
          err = true;

          $(this).addClass("error");
          $(this).parent().parent().find(".required_content").addClass("show");
          $(this).parent().parent().find(".error_x_white").addClass("show");
        } else {
          $(this).removeClass("error");
          $(this).parent().parent().find(".required_content").removeClass("show");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        }
      });

      if ($("#maxSal").val()) {
        if (!$("#minSal").val()) {
          err = true;

          $("#minSal").addClass("error");
          $("#minSal").parent().parent().find(".required_content").addClass("show");
          $("#minSal").parent().parent().find(".error_x_white").addClass("show");
        } else {
          $("#minSal").removeClass("error");
          $("#minSal").parent().parent().find(".required_content").removeClass("show");
          $("#minSal").parent().parent().find(".error_x_white").removeClass("show");
        }
      }

      if ($("#minSal").val()) {
        if (!$("#maxSal").val()) {
          err = true;

          $("#maxSal").addClass("error");
          $("#maxSal").parent().parent().find(".required_content").addClass("show");
          $("#maxSal").parent().parent().find(".error_x_white").addClass("show");
        } else {
          $("#maxSal").removeClass("error");
          $("#maxSal").parent().parent().find(".required_content").removeClass("show");
          $("#maxSal").parent().parent().find(".error_x_white").removeClass("show");
        }
      }

      $(".jbs_category").each(function () {
        if (!$("#jbs_category").val()) {
          err = true;

          $(this).addClass("error");
          $(this).parent().parent().find(".required_content").addClass("show");
          $(this).parent().parent().find(".error_x_white").addClass("show");
        } else {
          $(this).removeClass("error");
          $(this).parent().parent().find(".required_content").removeClass("show");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        }
      });
      $(".job_location").each(function () {
        if (!$("#job_location").val()) {
          err = true;

          $(this).addClass("error");
          $(this).parent().parent().find(".required_content").addClass("show");
          $(this).parent().parent().find(".error_x_white").addClass("show");
        } else {
          $(this).removeClass("error");
          $(this).parent().parent().find(".required_content").removeClass("show");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        }
      });

      $("#job_role_type").change(function () {
        $(this).removeClass("error");
        $(this).parent().parent().find(".error_x_white").removeClass("show");
      });
      $("#jbs_category").change(function () {
        $(this).removeClass("error");
        $(this).parent().parent().find(".error_x_white").removeClass("show");
      });
      $("#job_location").change(function () {
        $(this).removeClass("error");
        $(this).parent().parent().find(".error_x_white").removeClass("show");
      });

      // $(".scheduled_post").focusout(function(){
      //    $(this).addClass('error');
      //    $(this).parent().parent().find('.error_x_white').addClass('show');
      // });

      setTimeout(function () {
        $(".second_indiv_step_wrap .error").first().focus();

        $(".error").unbind("keypress");
        $(".error").bind("keypress", function () {
          $(this).removeClass("error");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        });
      }, 100);

      if (this.form.max_salary && this.form.min_salary) {
        if (parseInt(this.form.max_salary) < parseInt(this.form.min_salary)) {
          err = true;
          this.$swal({
            position: "center",
            // icon: 'error',
            imageUrl: "/assets/images/404_elcy.gif",
            customClass: {
              container: "mascot_error_container",
            },
            title: "Max salary cannot be less than min salary.",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }

      if (this.form.role_type_id == this.part_time_id) {
        //this.form.min_salary = 0
      }
      if (this.form.service_categories_id == this.other_category_id) {
        if (!this.form.other_service_category) {
          err = true;
          this.$swal({
            position: "center",
            // icon: 'error',
            imageUrl: "/assets/images/404_elcy.gif",
            customClass: {
              container: "mascot_error_container",
            },
            title: "Please specify the category.",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }

      if (this.form.location_id == this.other_location_id) {
        if (!this.form.other_job_location) {
          err = true;
          this.$swal({
            position: "center",
            // icon: 'error',
            imageUrl: "/assets/images/404_elcy.gif",
            customClass: {
              container: "mascot_error_container",
            },
            title: "Please specify the location.",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }

      if (err == false) {
        this.$store
          .dispatch(POST_API, {
            data: {
              title: this.form.title,
              type: this.form.type,
              email: this.form.email,
              category: this.form.service_categories_id,
              other_category: this.form.other_service_category,

              location: this.form.location_id,
              other_location: this.form.other_job_location,

              salarymin: this.form.min_salary ? this.form.min_salary : 0,
              salaryMax: this.form.max_salary ? this.form.max_salary : 0,
              tag: this.form.tagArr,
              description: this.form.description ? this.form.description : "",
              qualification: this.form.qualification_id ? this.form.qualification_id : "",
              experience: this.form.experience,
              level: this.form.position_level_id ? this.form.position_level_id : "",
              userId: this.logged_in_user_id,
              actual_posted_by: this.logged_in_user_id,
              company_id: this.company_id,
              job_type_id: this.form.role_type_id,
              start_date: this.form.scheduled_post
                ? moment(this.form.scheduled_post).format("YYYY-MM-DD")
                : null,
              status: "draft",
              notify_users: this.form.users,
              admin_notify: this.form.admin_notify,
              company_admin_id: this.company_admin_id
                ? this.company_admin_id
                : this.logged_in_user_id,
              feature_job: this.form.feature_job,
            },
            api: "/api/draftJob",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showDismissibleAlert = true;
              this.$swal({
                position: "center",
                // icon: 'error',
                imageUrl: "/assets/images/404_elcy.gif",
                customClass: {
                  container: "mascot_error_container",
                },
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500,
              });
            } else {
              Bus.$emit("active_users");
              this.loading = false;
              this.$swal({
                position: "center",
                icon: "success",
                iconColor: "#4c8bf5",
                title: "Job Saved successfully.",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                window.location.replace("/v2/employer/drafted-jobs");

                // this.$router.push({ name: 'saved-jobs' });
              });
            }
          });
      }
    },

    getEmployeeProfile() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            id: this.logged_in_user_id,
          },
          api: "/api/employee-profile",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            return [];
          } else {
            if (this.$store.getters.getResults.data) {
              if (this.$store.getters.getResults.data.contact_number == "12345678") {
                this.form.mobile = "";
              } else {
                this.form.mobile = this.$store.getters.getResults.data.contact_number;
              }
              this.form.email = this.$store.getters.getResults.data.email;
              if (this.$store.getters.getResults.data.name == "test.co") {
                this.form.company_name = "";
              } else {
                this.form.company_name = this.$store.getters.getResults.data.name;
              }
              this.form.company_reg_no = this.$store.getters.getResults.data.registration_id;
              this.form.overview = this.$store.getters.getResults.data.description;
              this.form.office_address = this.$store.getters.getResults.data.address;
              this.form.image = this.$store.getters.getResults.data.company_logo;
              this.form.email_verify = this.$store.getters.getResults.user["email_verified"];
              this.form.mobile_verify = this.$store.getters.getResults.user["mobile_verified"];
              this.ftStatus = this.$store.getters.getResults.user["ft_account_status"];
              this.free_feature = this.$store.getters.getResults.user["free_featured"];
            } else {
              this.form.mobile = "";
              this.form.email = "";
            }
          }
        });
    },

    getPackageData() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            id: this.package_id,
            company_admin_id: this.company_admin_id,
          },
          api: "/api/package-data",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            return [];
          } else {
            if (this.$store.getters.getResults.data) {
              this.form.freejobs = this.$store.getters.getResults.data[0].option_value;
              this.form.packageName = this.$store.getters.getResults.data[0].title;
              var rem_jobs = parseInt(this.form.freejobs) - parseInt(this.form.postedjobs);

              if (rem_jobs < 0) {
                this.remaining_jobs = 0;
              } else {
                this.remaining_jobs = rem_jobs;
              }
            }
          }
        });
    },

    postedJobs() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            id: this.logged_in_user_id,
          },
          api: "/api/postedJobs",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            return [];
          } else {
            if (this.$store.getters.getResults.data) {
              this.form.postedjobs = this.$store.getters.getResults.data[0].posted_jobs;

              this.getPackageData();
            }
          }
        });
    },

    companyData() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            id: this.logged_in_user_id,
          },
          api: "/api/companyData",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            return [];
          } else {
            if (this.$store.getters.getResults.data) {
              // this.form.service_categories_id = this.$store.getters.getResults.data[0].company_industry_id;
            }
          }
        });
    },

    getQualification() {
      this.$store
        .dispatch(POST_API, {
          data: {},
          api: "/api/qualification",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;

            this.$swal({
              position: "center",
              // icon: 'error',
              imageUrl: "/assets/images/404_elcy.gif",
              customClass: {
                container: "mascot_error_container",
              },
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.qualifications = this.$store.getters.getResults.data;
          }
        });
    },
    getLocation() {
      this.$store
        .dispatch(POST_API, {
          data: {},
          api: "/api/location",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;

            this.$swal({
              position: "center",
              // icon: 'error',
              imageUrl: "/assets/images/404_elcy.gif",
              customClass: {
                container: "mascot_error_container",
              },
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.locations = this.$store.getters.getResults.data;
            this.other_location_id = this.$store.getters.getResults.other_location_id;
            this.locations.forEach((element) => {
              if (element.location_names) {
                element.location_names_val = element.location_names.split(",");
                element.location_names_id = element.location_ids.split(",");
                // location[element.location_ids.split(',')] = element.location_names.split(',')
              }
            });
          }
        });
    },
    getPositionLevel() {
      this.$store
        .dispatch(POST_API, {
          data: {},
          api: "/api/position-level",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;

            this.$swal({
              position: "center",
              // icon: 'error',
              imageUrl: "/assets/images/404_elcy.gif",
              customClass: {
                container: "mascot_error_container",
              },
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.position_levels = this.$store.getters.getResults.data;
          }
        });
    },
    getjobRoleType() {
      this.$store
        .dispatch(POST_API, {
          data: {},
          api: "/api/job-role-type",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;

            this.$swal({
              position: "center",
              // icon: 'error',
              imageUrl: "/assets/images/404_elcy.gif",
              customClass: {
                container: "mascot_error_container",
              },
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.role_types = this.$store.getters.getResults.data;
            this.role_types.forEach((element) => {
              if (element.name == "part-time") {
                this.part_time_id = element.id;
              }
            });
            $(".perHourRate").css("display", "none");
            $(".minMaxSalary").css("display", "block");
          }
        });
    },

    getElCoins() {
      this.$store
        .dispatch(POST_API, {
          data: {
            user_id: this.company_admin_id ? this.company_admin_id : this.logged_in_user_id,
          },
          api: "/api/userCoins",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;

            this.$swal({
              position: "center",
              // icon: 'error',
              imageUrl: "/assets/images/404_elcy.gif",
              customClass: {
                container: "mascot_error_container",
              },
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.coins = this.$store.getters.getResults.data;

            this.coins[0].featured_jobs = this.coins[0].featured_jobs
              ? this.coins[0].featured_jobs
              : 0;
            this.free_feature = this.free_feature ? this.free_feature : 0;

            this.total_feat = parseInt(this.coins[0].featured_jobs) + parseInt(this.free_feature);
          }
        });
    },

    getSettings() {
      this.$store
        .dispatch(POST_API, {
          data: {},
          api: "/api/expirySettings",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;

            this.$swal({
              position: "center",
              // icon: 'error',
              imageUrl: "/assets/images/404_elcy.gif",
              customClass: {
                container: "mascot_error_container",
              },
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.expiry = this.$store.getters.getResults.data[0].option_value;
            this.coinsperjob = this.$store.getters.getResults.data1[0].option_value;

            // this.coinsperjob = this.$store.getters.getResults.data3[0].option_value == 'no' ? this.$store.getters.getResults.data1[0].option_value : 0;
            this.freeFtJobs = this.$store.getters.getResults.data3[0].option_value;
          }
        });
    },
    addUser() {
      if (this.form.users.length < 3) {
        this.form.users.push({ user_id: "" });
      }
    },
    removeUser(index) {
      this.form.users.splice(index, 1);
      if (this.form.users.length == 0) {
        this.addUser();
      }
    },
    getCompanyUsers() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            company_id: this.$store.getters.currentUser.company_id,
          },
          api: "/api/all-company-users",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            return [];
          } else {
            if (this.$store.getters.getResults.data) {
              this.company_users = this.$store.getters.getResults.data;
              return this.company_users;
            }
          }
        });
    },

    getCompanyAdmin() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            company_id: this.$store.getters.currentUser.company_id,
          },
          api: "/api/get-company-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            return [];
          } else {
            if (this.$store.getters.getResults.data) {
              this.company_admin = this.$store.getters.getResults.data[0];
              return this.company_admin;
            }
          }
        });
    },

    filterUser(id) {
      let selUser = this.company_users.find((item) => {
        return item.id == id;
      });
      return selUser ? selUser.email : "";
    },
    checkDisabled(id) {
      var exp = this.form.users.map((item) => item.user_id).indexOf(id) >= 0 ? true : false;

      return exp;
    },
  },

  mounted() {
    //document.title = "EL Connect - Employer - Post Job";
    $("#minSal").keyup(function (e) {
      if (/\D/g.test(this.value)) {
        // Filter non-digits from input value.
        this.value = this.value.replace(/\D/g, "");
      }
    });

    $("#maxSal").keyup(function (e) {
      if (/\D/g.test(this.value)) {
        // Filter non-digits from input value.
        this.value = this.value.replace(/\D/g, "");
      }
    });

    $("#exp").keyup(function (e) {
      if (/\D/g.test(this.value)) {
        // Filter non-digits from input value.
        this.value = this.value.replace(/\D/g, "");
      }
    });
    this.logged_in_user_id =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : "";
    this.package_id =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.package_id : "";
    this.company_admin_id =
      this.$store.getters.currentUser != null
        ? this.$store.getters.currentUser.company_admin_id
        : "";
    this.company_id =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.company_id : "";
    Bus.$emit("active_users");

    if (this.package_id == 13) {
      $("#specific_job_cat").show();
    }

    this.getEmployeeProfile();
    this.getQualification();
    this.getPositionLevel();
    this.getLocation();
    this.getDropdownData("service_categories");
    this.getjobRoleType();
    this.postedJobs();
    //this.companyData();
    this.getPackageData();
    this.getElCoins();
    this.getSettings();
    this.getCompanyAdmin();

    this.getCompanyUsers();
    this.checkELCoinsJobPost();
    $(".dashboard-content-container .simplebar-scroll-content").animate(
      {
        scrollTop: 0,
      },
      "fast"
    );
    let today = new Date();
    let startDate = new Date("2024-01-01T00:00+07:00");
    if (today >= startDate) {
      console.log("hh");

      $("#headingText").css("display", "none");
      $("#headingText2").css("display", "inline");

      // $('headingText2').show();
    } else {
      console.log("hhpp");

      $("#headingText2").css("display", "none");
      $("#headingText").css("display", "inline");
    }
  },
};
</script>
