<template>
    <div>
        <div id="footer" class="v2_footer jobseeker_footer">
                    <!-- Footer Top Section -->
                    <div class="footer-top-section">
                        <!-- <div class="row"> -->
                            <div class="container custom_container ">
                                <div class="row">
                                    <!-- <div class="col-xl-12"> -->
                                        <!-- <div class="custom_top_section_footer sec_1">                            -->
                                        <!-- <div class="col-md-3 col-sm-3 sec_1 custom_top_section_footer border_footer_right"> -->
                                        <div class="col-md-12 col-sm-12 col-xl-12 custom_top_section_footer">
                                            <div id="custom_logo">
                                                <!--<b-link href="" :to="{name: 'homepage'}" ><img :src="webUrl+'assets/images/logo2.png'" :data-sticky-logo="webUrl+'assets/images/logo.png'" :data-transparent-logo="webUrl+'assets/images/logo.png'" alt="footer_logo"></b-link>-->
                                                <b-link href="" :to="{name: 'jobseekers2'}" >
                                                    <img :src="webUrl+'logo.png'" alt="Logo">
                                                </b-link>
                                            </div>
                                        </div>
                                    <!-- </div> -->
                                </div>
                            </div>
                    <!-- </div> -->
                    </div>
                    <!-- Footer Top Section / End -->

                    <!-- Footer Middle Section -->
                    <div class="footer-middle-section new_footer_middle_section">
                        <div class="container custom_container">
                            <div class="row">
                                <!-- Links -->
                                <div class="col-xl-5 col-lg-5 ">
                                    <div class="footer-links ul_with_icon">
                                        <ul v-if="get_address.length > 0 && contact_us.length > 0">
                                            <li><i class="icon-material-outline-business icon-custom-color"></i><span><p class="">{{get_address[0].option_key == 'address' ? get_address[0].option_value: ''}}</p></span></li>
                                            <li><i class="material-icons-outlined phone_footer icon-custom-color">local_phone_outlined</i><span><p class=""><a :href="'tel:'+ contact_us[0].option_value" >{{contact_us[0].option_key == 'telephone_no' ? contact_us[0].option_value : contact_us[1].option_value}}</a></p></span></li>
                                            <li><i class="icon-material-outline-access-time icon-custom-color"></i><span><p class="time_h2_footer">Mon - Fri: 8:00AM - 5:00PM</p>
                                                <p class="">Public Holiday: Closed</p></span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="row">
                                        <div class="new_footer_store col-xl-8 col-lg-8  desktop_download_now">
                                                <!-- <div class="desktop_float_right"> -->
                                                <p class="social_h3">Download now</p>
                                                <div class="">
                                                    <div class="inner_anchor_mobile">
                                                        <a href="https://apps.apple.com/sg/app/el-connect/id1515715893" class="first_a_footer" target="_blank"><i class="fa fa-apple"></i><span class="full_span">Download on the <span class="app_bt">App Store</span></span></a>
                                                        <a href="https://play.google.com/store/apps/details?id=com.elconnectptuser"  target="_blank" class="last_a_footer"><i class='fab fa-google-play'></i><span class="full_span">Get it on<span class="app_bt">Google Play</span></span></a>
                                                    </div>
                                                </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-4  social_share_follow_on desktop_download_now">
                                            <p class="social_h3">Follow us on</p>
                                            <div class="">
                                            <ul class="footer-social-links">
                                            <li ><a href="https://www.facebook.com/elconnectsgp/"   target="_blank" data-tippy-placement="bottom" data-tippy-theme="light" data-tippy="" data-original-title="Facebook" aria-label="Facebook"><i class="icon-brand-facebook-f"></i></a></li>
                                            <li ><a href="https://www.instagram.com/elconnectsgp/"   target="_blank" data-tippy-placement="bottom" data-tippy-theme="light" data-tippy="" data-original-title="Instagram" aria-label="Instagram"><i class="icon-brand-instagram"></i></a></li>
                                            <li ><a href="https://www.youtube.com/c/elconnect"   target="_blank" data-tippy-placement="bottom" data-tippy-theme="light" data-tippy="" data-original-title="You Tube" aria-label="You Tube"><i class="icon-brand-youtube"></i></a></li>
                                            <li ><a href="https://www.tiktok.com/@elconnectsgp" alt="tiktok" target="_blank" data-tippy-placement="bottom" data-tippy-theme="light" data-tippy="" data-original-title="Tik Tok" aria-label="Tik Tok"><i class="fa-brands fa-tiktok"></i></a></li>
                                            </ul>
                                        </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 social_share_follow_on mobile_download_now">
                                            <p class="social_h3">Follow us on</p>
                                            <div class="">
                                            <ul class="footer-social-links">
                                            <li ><a href="https://www.facebook.com/elconnectsgp/" target="_blank" data-tippy-placement="bottom" data-tippy-theme="light" data-tippy="" data-original-title="Facebook" aria-label="Facebook"><i class="icon-brand-facebook-f"></i></a></li>
                                            <li ><a href="https://www.instagram.com/elconnectsgp/"  target="_blank" data-tippy-placement="bottom" data-tippy-theme="light" data-tippy="" data-original-title="Instagram" aria-label="Instagram"><i class="icon-brand-instagram"></i></a></li>
                                            <li ><a href="https://www.youtube.com/c/elconnect" target="_blank" data-tippy-placement="bottom" data-tippy-theme="light" data-tippy="" data-original-title="You Tube" aria-label="You Tube"><i class="icon-brand-youtube"></i></a></li>
                                            <li ><a href="https://www.tiktok.com/@elconnectsgp" data-tippy-placement="bottom" data-tippy-theme="light" data-tippy="" data-original-title="Tik Tok" aria-label="Tik Tok" target="_blank"><i class="fa-brands fa-tiktok"></i></a></li>
                                            </ul>
                                        </div>
                                        </div>
                                        <div class="new_footer_store col-xl-8 col-lg-8  mobile_download_now">
                                                <!-- <div class="desktop_float_right"> -->
                                                <p class="social_h3">Download now</p>
                                                <div class="">
                                                    <div class="inner_anchor_mobile">
                                                        <a href="https://apps.apple.com/sg/app/el-connect/id1515715893" class="first_a_footer" target="_blank"><i class="fa fa-apple"></i><span class="full_span">Download on the <span class="app_bt">App Store</span></span></a>
                                                        <a href="https://play.google.com/store/apps/details?id=com.elconnectptuser"  target="_blank" class="last_a_footer"><i class='fab fa-google-play'></i><span class="full_span">Get it on<span class="app_bt">Google Play</span></span></a>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Links -->
                                <div class="col-xl-2 col-lg-2  custom_col_sm_2">
                                    <div class="footer-links">
                                        <p class="common_margin_top_35_mobile_footer footer_heading_futura">ABOUT ELCONNECT</p>
                                        <ul>
                                            <li><b-link :to="{name: 'homepage'}"  class="font-25"><span>Job Listings</span></b-link></li>
                                            <li><b-link :to="{name: 'aboutUs'}"><span>About Us</span></b-link></li>
                                            <li><b-link :to="{name: 'jobseekers2'}"><span>Jobseekers</span></b-link></li>
                                            <li><b-link :to="{name: 'employer2'}"><span>Employers</span></b-link></li>
                                            <li><b-link :to="{name: 'blog'}"><span>Blog</span></b-link></li>
                                            <!-- <li><b-link href="" :to="{name: 'allJobsListing', params: {role_type: 'all',category_id: 'all',keyword: 'all',location: 'all'}}" ><span>Full Time Job</span></b-link></li> -->
                                            <!--<li><a href="#"><span>Post a Job</span></a></li>-->
                                            <!-- <li><b-link :to="{name: 'allDailyPaidJoblisting'}"  class="font-25"><span>Daily Paid Job</span></b-link></li> -->
                                            <!-- <li><b-link :to="{name: 'Gigplace'}" class="font-25"><span>Gigplace</span></b-link></li> -->

                                        </ul>
                                    </div>
                                </div>

                                <!-- Links -->
                                <div class="col-xl-2 col-lg-2  custom_col_sm_2">
                                    <div class="footer-links">
                                        <p class="common_margin_top_35_mobile_footer footer_heading_futura">CAREERS</p>
                                        <ul>
                                            <!-- <li><b-link :to="{name: 'allPromotionlisting'}"><span>Promotions</span></b-link></li> -->
                                            <li><b-link href="" :to="{name: 'allJobsListing'}" ><span>Full Time Job</span></b-link></li>
                                            <li><b-link :to="{name: 'allDailyPaidJoblisting'}"  class="font-25"><span>Part Time Job</span></b-link></li>
                                            <!-- <li><b-link :to="{name: 'CareerAdvice'}"><span>Career Advice</span></b-link></li> -->
                                            <!-- <li><b-link :to="{name: 'CareerWithUs'}" class="font-25"><span>Career With Us</span></b-link></li> -->
                                        </ul>
                                    </div>
                                </div>

                                <!-- Links -->
                                <div class="col-xl-2 col-lg-2  custom_col_sm_2">
                                    <div class="footer-links">
                                        <p class="common_margin_top_35_mobile_footer footer_heading_futura">ASSISTANCE</p>
                                        <ul>
                                            <li><b-link :to="{name: 'faq'}" ><span>FAQ</span></b-link></li>
                                            <li><b-link :to="{name:'TermsConditions'}" class="font-25"><span>Terms of Service</span></b-link></li>
                                            <li><b-link :to="{name:'PrivacyPolicy'}"><span>Privacy Policy</span></b-link></li>
                                            <li><b-link :to="{name:'ContactUs'}"><span>Contact Us</span></b-link></li>

                                            <!-- <li><b-link :to="{name: 'DataProtectionPolicy'}"><span>Data Protection Policy</span></b-link></li>
                                            <li><b-link :to="{name: 'AdvertisingTermsConditions'}"><span>Advertising Terms & Conditions</span></b-link></li>                                    -->
                                        </ul>
                                    </div>
                                </div>

                                <!-- Links -->
                                <div class="col-xl-1 col-lg-1  custom_col_sm_2">
                                    <div class="footer-links">
                                        <p class="common_margin_top_35_mobile_footer footer_heading_futura">ACCOUNT</p>
                                        <ul>
                                            <li v-if="user_type_id == ''"><b-link :to="{name: 'login'}" class="font-25"><span>Log In</span></b-link></li>
                                            <li  v-if="user_type_id == ''"><b-link :to="{name: 'signup'}"><span>Register</span></b-link></li>
                                            <li v-if="user_type_id != ''"><b-link class="log-in-button" href="/v2/admin/jobseekers">Profile</b-link></li>
                                            <!-- <b-link :to="{name: 'admin-dashboard'}"><span>Profile</span></b-link></li> -->
                                        </ul>
                                    </div>
                                </div>

                                <!-- Newsletter -->
                                <!-- <div class="col-xl-4 col-lg-4 col-md-12">
                                    <h3><i class="icon-feather-mail"></i> Sign Up For a Newsletter</h3>
                                    <p>Weekly breaking news, analysis and cutting edge advices on job searching.</p>
                                    <form  @submit="newsletterSubmit($event)" class="newsletter">
                                        <input type="text" v-model="newsLetterEmail" placeholder="Enter your email address">
                                        <button type="submit"><i class="icon-feather-arrow-right"></i></button>
                                    </form>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <!-- Footer Middle Section / End -->
                    <!-- Footer Copyrights -->
                    <div class="footer-bottom-section">
                        <div class="container">
                            <div class="row">
                                <div class="col-xl-12">
                                    Ⓡ 2025 <strong>EL Connect</strong>. All Rights Reserved.
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Footer Copyrights / End -->
                    <div class="jobseeker_chatbot_section fadeInBottomJobseeker">
                        <span style="visibility:hidden;" :class="{ show_msg_box: isHovered }" class="mobile_message_box"  :style="{ backgroundImage: 'url('+webUrl+'assets/jobeekerImages/Rectangle-312.png'+')'}">Meet ELCY our new AI chatbot!<br> Get instant assistance anytime</span>
                         <b-link v-bind:href="get_chatbot_link[0].option_key = 'chatbot_link' ? get_chatbot_link[0].option_value : get_chatbot_link[0].option_value" target="_blank">
                            <img :src="webUrl+'assets/jobeekerImages/chatbot-icon.png'" alt="chatbot" 
                                @mouseover="isHovered = true" 
                                @mouseout="isHovered = false"
                            >
                        </b-link>
                    </div>
            </div>
    </div>

</template>

<script>
import { POST_API } from "../../../store/actions.type";

export default{
  data() {
      return {
          isHovered: false,
          webUrl: process.env.VUE_APP_URL,
          items:'',
          items1:0,
          contact_us:'',
          get_address:'',
          user_type_id:'',
          get_chatbot_link:[]
      }
  },
  filters:{
      trimString: function(value) {
          if (!value) return '';
          else
          return value.length > 35 ? value.substring(0, 33) + "..." : value;
      }
  },
  methods:{
      getContent() {

         return this.$store.dispatch(POST_API, {
                 data: {

                 },
                 api: '/api/get-distinct-jobs-for-jobseekers'
             })
             .then(() => {
                  if (this.$store.getters.containsErrors) {
                      this.error_message = this.$store.getters.getErrors;
                      this.showAlert();
                      return [];
                  }
                  else
                  {
                      this.items = this.$store.getters.getResults.data;
                      this.items1 = this.$store.getters.getResults.data1;

                      return this.items;
                  }
             });
     },

     getFooterData() {

return this.$store.dispatch(POST_API, {
    api: '/api/footer-data'
})
.then(() => {
    if (this.$store.getters.containsErrors) {
        this.error_message = this.$store.getters.getErrors;
        // this.showAlert();
        return [];
    } else {
        this.contact_us = this.$store.getters.getResults.contact_us;
        this.get_header = this.$store.getters.getResults.get_header;
        this.about_us = this.$store.getters.getResults.about_us;
        this.get_android_link = this.$store.getters.getResults.get_android_link;
        this.get_ios_link = this.$store.getters.getResults.get_ios_link;
        this.get_facebook_link = this.$store.getters.getResults.get_facebook_link;
        this.get_instagram_link = this.$store.getters.getResults.get_instagram_link;
        this.get_youtube_link = this.$store.getters.getResults.get_youtube_link;
        this.get_tiktok_link = this.$store.getters.getResults.get_tiktok_link;
        this.get_address = this.$store.getters.getResults.get_address;
        this.get_chatbot_link = this.$store.getters.getResults.get_chatbot_link;
    }
});
},
      openLink(){
          window.open("https://elconnectptuser.app.link/OSbf0HwLOib","_blank");
      },
    //   showChatBot()
    //   {
    //         var pxShow = 600; 
    //         $(window).scroll(function(){
    //         if($(window).scrollTop() >= pxShow){
    //             $(".jobseeker_chatbot_section").addClass('fadeInBottomJobseeker');
    //         } else {
    //             $(".jobseeker_chatbot_section").removeClass('fadeInBottomJobseeker');
    //         }
    //         });
    //   }
  },
  mounted()
  {
      this.getFooterData();
    //   this.showChatBot();
  }
}
</script>
