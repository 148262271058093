<template>
    <div class="animated fadeIn create_footer desktop_padding_bottom_zero">
        <div class="create-new-job-section email-template-section">
            <div id="breadcrumb-v2" class="mb-1">
                <div class="d-flex justify-content-start breadcrumb-wrapper">
                    <b-breadcrumb
                    :items="breadCrumb()"
                    />
                </div>
            </div>
           
            <!-- Loop Start -->

                <div class="edit-profile-section border-radius-4 white-bg common_padding_30 commmon_label_heading loop_div">
                        <div class="edit-form-box desktop_margin_top_zero">
                            <div class="profile-repeate form-box-field ">
                                <b-row>
                                    <b-col lg="12" md="12">
                                        <ul class="view_audit_job_ul">
                                            <li>
                                                <p>
                                                    <b>Process- </b>
                                                    <i>Create job from web</i>
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    <b>Api- </b>
                                                    <i>store_job_api</i>
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    <b>Process Log- </b>
                                                    <i>{"job_id":40423,"user_id":12202,"job_status":"upcoming","current_lat":0,"current_long":0,"applied_by":1,"opt_insurance":"yes"}</i>
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    <b>Updated At- </b>
                                                    <i>12 Mar, 2025 02:05 PM</i>
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    <b>Updated By- </b>
                                                    <i>EL Connect Admin</i>
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    <b>FL/User ID- </b>
                                                    <i>Kian Kian</i>
                                                </p>
                                            </li>
                                        </ul>
                                    </b-col>
                                </b-row>
                            </div>
                        </div>
                </div>

                <div class="edit-profile-section border-radius-4 white-bg common_padding_30 commmon_label_heading loop_div">
                        <div class="edit-form-box desktop_margin_top_zero">
                            <div class="profile-repeate form-box-field ">
                                <b-row>
                                    <b-col lg="12" md="12">
                                        <ul class="view_audit_job_ul">
                                            <li>
                                                <p>
                                                    <b>Process- </b>
                                                    <i>Create job from web</i>
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    <b>Api- </b>
                                                    <i>store_job_api</i>
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    <b>Process Log- </b>
                                                    <i>{"job_id":40423,"user_id":12202,"job_status":"upcoming","current_lat":0,"current_long":0,"applied_by":1,"opt_insurance":"yes"}</i>
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    <b>Updated At- </b>
                                                    <i>12 Mar, 2025 02:05 PM</i>
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    <b>Updated By- </b>
                                                    <i>EL Connect Admin</i>
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    <b>FL/User ID- </b>
                                                    <i>Kian Kian</i>
                                                </p>
                                            </li>
                                        </ul>
                                    </b-col>
                                </b-row>
                            </div>
                        </div>
                </div>

             <!-- Loop End -->
        </div>
    </div>
</template>

<script>

export default {

    data() {
        return {
           
        }
    },
    components: {
        
    },
    methods: {
        breadCrumb(){
            var item = [{
                to:{name:'dp-jobs'},
                text: 'Manage Jobs',
            },{
                to:null,
                text: 'View Audit Job'
            }];
            return item;
        },
    },
    mounted()
    {
        $(".dashboard-content-container .simplebar-scroll-content").animate({ 
            scrollTop: 0 
        }, "fast");
    }
};

</script>
