<template>
    <div>
        <!-- Titlebar
        ================================================== -->
        <div id="titlebar" class="gradient login_title signup_mrgn new_contact_titlebar">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">

                        <h1 class="common_center">Contact Our Team</h1>
                        <div class="welcome-text new_welcome">
                            Our friendly support team will get back to you as soon as possible
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <!-- Page Content
        ================================================== -->
        <div class="container">
            <div class="row">
                <div class="col-xl-5 common_block">

                    <div class="login-register-page contact_page_cst">
                        <!-- Welcome Text -->
                        <!-- <div class="welcome-text">
                            <h3>We're glad to see you again!</h3>
                            <span>Don't have an account? <b-link href="" :to="{name: 'signup'}" >Sign Up!</b-link></span>
                        </div> -->
                        <!-- Form -->
                        <!-- <b-form method="post" id="login-form" @submit.prevent="formSubmit"> -->

                            <div class="submit-field">
                                <!-- <h5>Name of person<span class="required_star">*</span></h5> -->
                                <div class="input-with-icon-left">
                                            <b-form-select data-size="7" title="Select Type"  v-model="form.type" class="custom-select" id="gender">
                                                <option value="" disabled>Select</option>
                                                <option value="full-time" selected>Full Time</option>
                                                <option value="daily-paid">Daily Paid</option>
                                            </b-form-select>
                                </div>
                            </div>

                             <div class="submit-field">
                                <!-- <h5>Name of person<span class="required_star">*</span></h5> -->
                                <div class="input-with-icon-left">
                                <i class="fa fa-user cst_users common_i_height" aria-hidden="true"></i>
                                <b-form-input
                                    type="text"
                                    v-model="form.name"
                                    id="company_reg_no"
                                    class="input-text with-border required-field"
                                    name="company_reg_no"
                                    placeholder="Name of person*"
                                    value=""
                                />
                                </div>
                            </div>
                            <div class="submit-field">
                                <!-- <h5>Email<span class="required_star">*</span></h5> -->
                                <div class="input-with-icon-left">
                                <i class="icon-material-baseline-mail-outline common_i_height"></i>
                                <b-form-input
                                    type="text"
                                    v-model="form.email"
                                    id="company_reg_no"
                                    class="input-text with-border required-field"
                                    name="company_reg_no"
                                    placeholder="Email*"
                                    value=""
                                />
                                </div>
                            </div>
                             <div class="submit-field mobile_view_numbr mobile_email" >
                                <!-- <h5><span class="verify_badge" v-if="this.form.mobile_verified_check == true"><i class="fa fa-check"></i>Verified</span></h5> -->
                                <div class="input-with-icon-left mb-3">
                                <i class="country_code_i"><input type="text" v-model="form.country_code" class="common_i_height with-border mobile_input_prf form-control required-field country_code_txt country_code_for_contact" readonly="readonly"></i>
                                <input type="text" v-model="form.contact_number" class="with-border mobile_input_prf form-control required-field" id="contact" placeholder="Mobile Number* ">

                                </div>
                            </div>
                            <div class="submit-field">
                                <!-- <h5>Message<span class="required_star">*</span></h5> -->
                                <textarea type="text" class="with-border" value="" v-model="form.message" placeholder="Message*"></textarea>
                                <!-- <b-form-input
                                    type="text"
                                    v-model="form.message"
                                    id="company_reg_no"
                                    class="input-text with-border required-field"
                                    name="company_reg_no"
                                    placeholder=""
                                    value=""
                                /> -->
                            </div>
                        <!-- </b-form> -->
                        <!-- Button -->
                        <button @click.prevent="formSubmit" class="button full-width button-sliding-icon desktop_button_width_hundered ripple-effect margin-top-10" type="submit" form="login-form">Submit<i class="icon-material-outline-arrow-right-alt"></i></button>

                    </div>

                </div>
            </div>
        </div>
         <div id="titlebar" class="gradient signup_mrgn office_title">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h2 class="text-center">Our Office</h2>
                    </div>
                </div>
            </div>
        </div>
        <div class="address_iframe">
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d498.59224010643146!2d103.852831!3d1.333408!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da1728c6ae4fb5%3A0xff1884e01625bf7c!2sEL%20Connect%20Pte%20Ltd!5e0!3m2!1sen!2sin!4v1691587972093!5m2!1sen!2sin" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>

        <!-- Spacer -->
        <!-- <div class="margin-top-70 margin-top-50 mobile_tab_margin_top_35"></div> -->
        <!-- Spacer / End-->
    </div>
</template>
<script>
import { GET_API, POST_API } from "../../store/actions.type";
import JwtService from "../../common/jwt.service";
import Bus from "../../event-bus";

// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
    metaInfo: {
    title: 'EL Connect - Contact Us',
        meta: [
                { charset: 'utf-8' },
                { equiv: 'content-type', content: 'text/html' },
                { name: 'description', content: 'Contact Us - Freelancers facing issues with our Daily Paid Part-Time Job Platform can reach out to our support team for quick assistance and solutions.' },
                { name: 'viewport', content: 'width=device-width'},
                {property: 'og:title', content: 'EL Connect -Contact Us'},
			    {property: 'og:site_name', content: 'EL Connect'},
			    {property: 'og:type', content: 'website'},
			    {property: 'og:url', content: 'https://elconnect.sg/contact-us'},
			    {property: 'og:image', content: 'https://elconnect.sgp1.digitaloceanspaces.com/v2/general_setting/202281202127_general_setting.png'},
			    {property: 'og:description', content: 'Contact Us - Freelancers facing issues with our Daily Paid Part-Time Job Platform can reach out to our support team for quick assistance and solutions.'},
                {name: 'twitter:card', content: 'summary'}
            ],
            link: [
    		    {rel: 'canonical', href: 'https://elconnect.sg/contact-us'}
  		    ]
        },
    components: {
        Loading
    },
    data: function() {
        return {
            form: {
                name: '',
                email: '',
                country_code: '+65',
                contact_number: '',
                message:'',
                type:'full-time'
            },
            countries: [],
            error_message: '',
            success_message: '',
            dismissSecs: 3,
            dismissCountDown: 0,
            showDismissibleAlert: false,
            isLoading: false,
            color:'#FFD700',
            backgroundColor: '#808080',
            width: 140,
            height: 100,
            fullPage: true,
            opacity:0.6,

        }
    },
    methods: {
        formSubmit(evt)
        {
            this.$store.dispatch(POST_API, {
                    data: {
                        name: this.form.name,
                        email: this.form.email,
                        country_code: this.form.country_code,
                        contact_number: this.form.contact_number,
                        message:this.form.message,
                        type:this.form.type,

                    },
                    api: '/api/contact-us'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.$swal({
                            position: 'center',
                            icon: 'error',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });
                    } else {
                    this.$swal({
                            position: 'center',
                            icon: 'success',
                            iconColor:'#4c8bf5',
                            title: 'Message Sent Successfully.',
                            showConfirmButton: false,
                            timer: 1500
                        }).then((result) => {
                            window.location.replace('/contact-us');
                        });
                    }
                });
        },
        getCountryCode()
        {

            this.$store.dispatch(GET_API, {
                    api: '/api/countries'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.$swal({
                            position: 'center',
                            icon: 'error',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });
                         } else {
                        this.countries = this.$store.getters.getResults.data;
                    }
                });
        },
        validateForm()
        {
        return this.$validator.validate().then(valid => {
            if (!valid) {
                this.error_message = this.errors.all()[0];
                this.showAlert();
                return false;
            } else {
            }
            return true;
        });
    },
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown
        },
        showAlert() {
            // window.scrollTo(0,0);
            this.dismissCountDown = this.dismissSecs
            Swal.fire({
              position: 'top-center',
              type: 'error',
              text: this.error_message,
              showConfirmButton: false,
              timer: 3000
            })
        },
        showSuccessPopup() {
            this.$bvModal.msgBoxOk('Message send successfully.')
                .then(value => {
                    // window.location.replace('/contact');
                })
                .catch(err => {
                    // An error occurred
                })
        },
         showSuccessAlert() {
            // window.scrollTo(0,0);
            // this.dismissSuccessCountDown = this.dismissSecs
            Swal.fire({
                  position: 'top-center',
                  type: 'success',
                  text: 'Message Sent Successfully.',
                  showConfirmButton: false,
                  timer: 3000
                })
            // Toast.fire({
            //     type: 'success',
            //     title: 'Message Sent Successfully.',
            // });
            this.$router.push({ name: 'contact' });
        },
    },
    mounted() {
        Bus.$on('showLoader', () => {
          this.isLoading = true;

        })

        Bus.$on('hideLoader', () => {
          this.isLoading = false;

        })
        window.scrollTo(0,0);
        this.getCountryCode();
    }
}

</script>
