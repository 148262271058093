<template>
    <div>

        <div class="container full_container_dsk">
            <div class="row mobile_email_row custom_dsk_row">
        <!-- Dashboard Headline -->
        <div class="dashboard-headline">
            <h1>Referral History</h1>
            <!-- Breadcrumbs -->
            <nav id="breadcrumbs" class="dark">
                <ul>
                    <li><a href="#">Home</a></li>
                    <li>Referral History</li>
                </ul>
            </nav>
        </div>
        <div class="row">
                    <div class="service_col_3 col-xl-3 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28">
                    <div class="search-box custom_search_admin desktop_margin_top_zero">
                            <b-input-group-prepend class="common_bg_white">
                                <b-form-input id="searchBtn" @keyup.enter.native="filter = searchKeyword" v-model="searchKeyword" placeholder="Search by keyword" class="common_bg_white"></b-form-input>
                                <b-button class="btn points_events"><i class="fa fa-search common_bg_white" @click="filter = searchKeyword"></i></b-button>
                            </b-input-group-prepend>
                            <!-- <b-input-group-prepend>
                            <b-form-input id="searchBtn" v-model="filter" v-on:change="onChangeData" placeholder="Enter keyword to search"></b-form-input>
                            <b-button class="btn"><i class="fa fa-search"></i></b-button>
                        </b-input-group-prepend> -->
                    </div>
                    </div>
                    <div class="service_col_3 col-xl-2  search_common_margin_bottom_28 mobile_tab_max_width_flex">
                        <b-form-group id="input-group-6" class="desktop_margin_bottom_zero date_style_size">
                        <datepicker v-model="defaultDate" @input="onChangeData" format="MMMM yyyy" minimum-view="month" month-picker class="add_calender_icon common_normal_calender_height freelancer_incentive_calender freelancer_incentive_big_calender" style="height: 31px;"></datepicker>
                    </b-form-group>
                    </div>
                    <div class="service_col_3 col-xl-2  search_common_margin_bottom_28 mobile_tab_max_width_flex">
                        <b-form-group id="input-group-6" class="desktop_margin_bottom_zero">
                        <datepicker v-model="defaultDate1" @input="onChangeData" format="MMMM yyyy" minimum-view="month" month-picker class="add_calender_icon common_normal_calender_height freelancer_incentive_calender freelancer_incentive_big_calender"></datepicker>
                    </b-form-group>
                    </div>


                    <template>
                        <div class="service_col_4 col-xl-4  search_common_margin_bottom_28 mobile_tab_max_width_flex">
                            <b-form-group id="input-group-8" class="desktop_margin_bottom_zero">
                                <multiselect
                                    id="selectServices"
                                    v-model="status"
                                    :options="serviceStatuses"
                                    :close-on-select="true"
                                    placeholder="All"
                                    track-by="value"
                                    label="label"
                                    class="common_black_multiselect_border job_list_multi_select model_multi_select admin_multi_select"
                                    @input="onChangeData()"
                                >
                                </multiselect>
                                </b-form-group>
                            </div>
                    </template>
                    <div class="service_col_3 col-xl-3 search_common_margin_bottom_28 mobile_tab_max_width_flex search_common_margin_bottom_28 new_reset_btn">
                        <div class="sidebar-widget job_list_btn_search mobile_space_between penalty_btn desktop_margin_bottom_zero food_btn">
                            <b-link class="btn green-border-btn add_btn_admin_e desktop_margin_left_zero desktop_margin_right_zero" @click="exportPdf()">Export</b-link>
                            <!-- <b-button @click="exportPdf()" class="button ripple-effect search_btn_all filter_btn_widht_120 desktop_margin_left_zero desktop_margin_right_zero" style="width: 120px;">Export</b-button> -->
                            <!-- <b-button v-if="(filter || searchKeyword || status || company_id_filter || (dateRange.startDate != start_date_filter || dateRange.endDate != end_date_filter))" @click="clearFilterEmpServiceAgreement()" class="button ripple-effect search_all_red_btn  filter_btn_widht_120 desktop_margin_right_zero" style="background-color: red;"><span>Reset all filters</span></b-button> -->
                        </div>
                    </div>
                </div>
        </div>
                </div>
        <!-- Page Content
        ================================================== -->
        <div class="container full_container_dsk">
           <div class="row">
               <b-table ref="datatable" show-empty striped hover responsive :items="getReferralList" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" class="desktop_margin_top_zero new_box_tbl_mrgn   common_small_font_table  new_layout_table_like_v1">
                <template #cell(toggleAction)="items">
                        <div class="toggle-action toggle_margin_unset">
                            <b-dropdown class="mx-1" left text="Action" boundary="window">
                                <b-dropdown-item v-if="show('remove referral')" @click="removeReferral(items.item.id)" >Remove Referral
                        </b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </template>
                <template #cell(referredBy)="items">
                    <div class="name-td-box">
                       <!--  <div class="manage_user_img">
                            <b-img :src="items.item.referred_by_thumbnail != null ? items.item.referred_by_thumbnail : (items.item.referred_by_profile_image != null ? items.item.referred_by_profile_image: webUrl+'/images/default-user-icon.svg')" alt="User Icon">
                            </b-img>
                        </div> -->
                        <div class="user_name_div">
                            <b-link :to="showFreelancer('view profile') ? {name: 'jobseeker-dp-profile-view', params: {id: encrypt(items.item.referred_by_id)}} : null" target="_blank">
                                <b>{{items.item.referred_by_last_name}} {{items.item.referred_by_first_name}}</b>
                            </b-link>
                            <p class="grey-text">
                                {{items.item.referred_by_email | trim}}
                            </p>
                            <b-link :href="'https://wa.me/+65' + items.item.referred_by_mobile_number" target="_blank">
                            <p class="black-text">
                                {{items.item.referred_by_country_code}}-{{items.item.referred_by_mobile_number}}
                            </p>
                            <p :class="items.item.referred_by_status == 'active' ? 'green-text' : (items.item.referred_by_status == 'inactive' ? 'orange-text' : (items.item.referred_by_status == 'blacklisted' ? 'red-text' : 'grey-text'))">
                                {{items.item.referred_by_status | capitalize}}
                            </p>
                            </b-link>
                        </div>
                    </div>
                </template>
                <template #cell(referred_by_account_verification_status)="items">
                  {{items.item.referred_by_account_verification_status | capitalize}}
                </template>
                <template #cell(account_completed_on_by)="items">
                  {{items.item.referred_by_approved_on | moment}}
                </template>
                <template #cell(referredTo)="items">
                  <div class="name-td-box">
                      <!-- <div class="manage_user_img">
                        <b-img :src="items.item.referred_to_thumbnail != null ? items.item.referred_to_thumbnail : (items.item.referred_to_profile_image != null ? items.item.referred_to_profile_image: webUrl+'/images/default-user-icon.svg')" alt="User Icon">
                        </b-img>
                      </div> -->
                      <div class="user_name_div">
                        <b-link :to="showFreelancer('view profile') ? {name: 'jobseeker-dp-profile-view', params: {id: encrypt(items.item.referred_to_id)}} : null" target="_blank">
                          <b>{{items.item.referred_to_last_name}} {{items.item.referred_to_first_name}}</b>
                       </b-link>
                        <p class="grey-text">
                            {{items.item.referred_to_email | trim}}
                        </p>
                        <b-link :href="'https://wa.me/+65' + items.item.referred_to_mobile_number" target="_blank">
                            <p class="black-text">
                                {{items.item.referred_to_country_code}}-{{items.item.referred_to_mobile_number}}
                            </p>
                            <p :class="items.item.referred_to_status == 'active' ? 'green-text' : (items.item.referred_to_status == 'inactive' ? 'orange-text' : (items.item.referred_to_status == 'blacklisted' ? 'red-text' : 'grey-text'))">
                                {{items.item.referred_to_status | capitalize}}
                            </p>
                        </b-link>
                      </div>
                  </div>
                </template>
                <template #cell(referred_to_account_verification_status)="items">
                  {{items.item.referred_to_account_verification_status | capitalize}}
                </template>
                <template #cell(account_completed_on_to)="items">
                  {{items.item.referred_to_approved_on | moment}}
                </template>
                <template #cell(status)="items">
                  <p v-if="items.item.status == 'completed'"> {{'Transferred'| capitalize}}</p>
                  <p v-else-if="items.item.status == 'awarded'">{{'Awarded' | capitalize}}</p>
                  <p v-else-if="items.item.status == 'all'">{{'All' | capitalize}}</p>
                  <p v-else>{{'Hold' | capitalize}}</p>
                </template>
                <template #cell(completed_on)="items">
                  <p v-if="items.item.status == 'completed'">{{items.item.completed_on != null ? items.item.completed_on : '-'| moment}}</p>
                  <p v-else> - </p>
                </template>
                <template #cell(awarded_on)="items">
                  <p v-if="items.item.awarded_on != null ">{{items.item.awarded_on != null ? items.item.awarded_on : '-'| moment}}</p>
                  <p v-else> - </p>
                </template>
                <template #cell(account_status)="items">
                  {{items.item.referred_to_account_verification_status | capitalize}}
                </template>
                <template #cell(amount)="items">
                  {{items.item.referred_amount | filterMoney}}
                </template>

                <template #cell(created_at)="items">
                  {{items.item.created_at | moment}}
                </template>
                </b-table>
                <nav class="pagination-box">
                    <div class="page-row-box d-inline-block">
                        <b-form-group id="input-group-4">
                             <span class="d-inline-block">Rows per page</span>
                            <span class="d-inline-block"><b-form-select v-model="form.rowsPerPage" @change='getReferralList'>
                                        <option>25</option>
                                        <option>50</option>
                                        <option>75</option>
                                        <option>100</option>
                            </b-form-select></span>
                        </b-form-group>
                    </div>
                    <div class="total-page-count d-inline-block">
                        <ul>
                            <li>
                                <span v-if='totalRows != 0'>{{from}} - {{to}} of {{totalRows}}</span>
                            </li>
                        </ul>
                    </div>
                    <b-pagination :total-rows="totalRows"   :per-page="perPage" v-model="currentPage" prev-text="" next-text="" hide-goto-end-buttons />
                </nav>
			</div>
        </div>
        <!-- Spacer -->
        <div class="margin-top-70"></div>
        <!-- Spacer / End-->
        <!-- Row / End -->
    </div>
</template>

<script>
import { POST_API } from '../../../store/actions.type'
import moment from 'moment'
import Multiselect from "vue-multiselect";
import Datepicker from 'vuejs-datepicker';
import permission from "../../../../../server/permission.js";

export default {
    data() {
        return {
            status: null,
            serviceStatuses: [
                { value: 'all', label: 'All' },
                { value: 'awarded', label: 'Awarded' },
                { value: 'pending', label: 'Hold' },
                { value: 'completed', label: 'Transferred' },
            ],
            fields: [
                { key: 'toggleAction', label: 'Actions', sortable: false , thStyle:  {width: '2%'}},
                { key: 'referredBy', label: 'Referred By', sortable: true , thStyle:  {width: '8%'}},
                { key: 'referred_by_account_verification_status', label: 'Account Verification Status', sortable: true , thStyle:  {width: '10%'}},
                { key: 'account_completed_on_by', label: 'Account completed On', sortable: false , thStyle:  {width: '9%'}},
                { key: 'first_job_referred_by', label: 'First Job Date', sortable: false , thStyle:  {width: '9%'}},
                { key: 'completed_jobs_referred_by', label: 'Jobs Completed', sortable: false , thStyle:  {width: '5%'}},

                { key: 'referredTo', label: 'Referred To', sortable: true , thStyle:  {width: '5%'}},
                { key: 'referred_to_account_verification_status', label: 'Account Verification Status', sortable: true , thStyle:  {width: '8%'}},
                { key: 'account_completed_on_to', label: 'Account completed On', sortable: false , thStyle:  {width: '8%'}},
                { key: 'first_job_referred_to', label: 'First Job Date', sortable: false , thStyle:  {width: '8%'}},
                { key: 'completed_jobs_referred_to', label: 'Jobs Completed', sortable: false , thStyle:  {width: '5%'}},

                { key: 'amount', label: 'Referral Amount', sortable: true , thStyle:  {width: '3%'}},
                { key: 'status', label: 'Status', sortable: true , thStyle:  {width: '5%'}},
                { key: 'awarded_on', label: 'Awarded on', sortable: false , thStyle:  {width: '5%'}},
                { key: 'completed_on', label: 'Completed on', sortable: false , thStyle:  {width: '5%'}},
                { key: 'created_at', label: 'Referred on', sortable: false , thStyle:  {width: '5%'}},

            ],
            pager: {},
            pageOfItems: [],
            items:null,
            totalRows: 0,
            from: '',
            to: '',
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 75, 100],
            sortBy: null,
            sortDirection: 'asc',
            filter: '',
            sortDesc: false,
            webUrl: process.env.VUE_APP_URL,
            form:{
                rowsPerPage:25,
            },
            month: new Date().toLocaleString('default', { month: 'short' }),
            status: { value: 'awarded', label: 'Awarded' },
            defaultDate: moment().subtract(1, 'months').format('01 MMM YYYY'),
            defaultDate1: moment().endOf('month').format('DD MMM YYYY'),
            webUrlV1: process.env.VUE_APP_URL_V1,
            searchKeyword:'',
            actions:'',
            actionsFreelancer:'',
        }
    },
    components: {
        Datepicker,
        Multiselect        
    },
    filters: {
        capitalize: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
        moment: function(date) {
          if (!date) return '-'
          return moment(date).format('DD-MM-YYYY hh:mm A')
        },
        encode: function(value) {
          return btoa(value);
        },
        filterMoney: function(value){
          if (value == null || value == "") return "-"
          return 'S$ '+parseFloat(value);
        },

        trim: function(value) {
            value = value.toString()
            if (value.length < 25) {
                return value;
            } else {
                return value.substring(0, 25) + '...';
            }
        },
    },
    methods:{
        showSuccessAlert(message) {
            this.$swal({
                position: 'center',
                icon: 'success',
                iconColor:'#4c8bf5',
                title: message,
                showConfirmButton: false,
                timer: 1500
            }).then((result) => {
                this.$refs.datatable.refresh();
                // this.$router.push({ name: 'country-list' });
            });
        },
        showAlert() {
            this.$swal({
                position: 'center',
                icon: 'error',
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            })
        },
        downloadPdf(url){
          window.open(url);
        },
        onChangeData()
        {
            var search = $('#searchBtn').val();
            this.search = search;
            this.defaultDate = moment(this.defaultDate).format('DD MMM YYYY');
            this.defaultDate1 = moment(this.defaultDate1).endOf('month').format('DD MMM YYYY');

            this.getReferralList().then(() => {
                this.$refs.datatable.refresh();
            });

        },
        exportPdf(){
          var base_url = this.webUrlV1;
          var start_date = this.defaultDate ? moment(this.defaultDate).format('YYYY-MM-DD') : 'null';
          var end_date = this.defaultDate1 ? moment(this.defaultDate1).format('YYYY-MM-DD') : 'null';
          var search = (this.search != null && this.search != '') ? this.search : 'null';
          // var status = (this.status != null && this.status != '') ? (this.status.length > 0 ? this.status.map(a => a.id) : 'null') : 'null';
          var status = (this.status != null && this.status != '') ? this.status.value : 'null';

          var export_url = '/api/export-referrals/'+search+'/'+start_date+'/'+end_date+'/'+status;
          window.open(base_url+export_url,'_blank')
        },
        removeReferral(id)
        {
            this.$swal({
                title: 'Please Confirm',
                text: "Are you sure want to remove this referral?",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            })
            .then(result => {
              if (result.value) {
                  return this.$store.dispatch(POST_API, {
                    data:{
                        id:id
                        },
                    api:"/api/referral-delete",
                      })
                      .then(() => {
                          if (this.$store.getters.containsErrors) {
                              this.error_message = this.$store.getters.getErrors;
                              this.showAlert();
                          } else {
                            this.success_message = this.$store.getters.getResults.message;
                            this.showSuccessAlert(this.success_message);
                          }
                      });
                    }
                })
                .catch(err => {
                    console.log(err)
                    // An error occurred
                })
        },

        getReferralList() {
            return this.$store.dispatch(POST_API, {
                data:{
                    status: this.status.value,
                    page: this.currentPage,
                    keyword: this.filter,
                    rowsPerPage:this.form.rowsPerPage,
                    sortBy:this.sortBy,
                    sortDirection:this.sortDirection,
                    sortDesc:this.sortDesc,
                    type:'admin',
                    startDate: this.defaultDate ? moment(this.defaultDate).format('YYYY-MM-DD') : '',
                    endDate: this.defaultDate1 ? moment(this.defaultDate1).format('YYYY-MM-DD') : '',

                },
                api: '/api/referral-list'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    // this.showAlert();
                    return [];
                } else {
                    this.items = this.$store.getters.getResults.data.data;
                    this.totalRows = this.$store.getters.getResults.data.total;
                    this.perPage = this.$store.getters.getResults.data.per_page;
                    this.from = this.$store.getters.getResults.data.from
                    this.to = this.$store.getters.getResults.data.to
                    return this.items;
                }
            });
        },
        permissionStaff(){
            if(this.$store.getters.getCurrentUserPermission){
              if (this.$store.getters.getCurrentUserPermission.data.length > 0 && this.$store.getters.currentUser.user_type_id == 2) {
               var menu = this.$store.getters.getCurrentUserPermission.data;
               this.actions = permission.getPermissionStaff(menu,'Referral History');
               this.actionsFreelancer = permission.getPermissionStaff(menu,'Jobseekers');
              }
            }
        },
        show(action){
            if (this.$store.getters.currentUser.user_type_id == 2) {
                 return this.actions.indexOf(action) >= 0 ? true : false ;  
            }else{
                return true;
            }
        },
        showFreelancer(action){
            if (this.$store.getters.currentUser.user_type_id == 2) {
                return this.actionsFreelancer.indexOf(action) >= 0 ? true : false ;  
            }else{
                return true;
            }
        },

    },
     mounted(){
            //document.title = "EL Connect - Jobseeker - Transaction";
            this.user_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
            this.permissionStaff();

     }
}
</script>
