<template>
    <div>

        <!-- Titlebar
        ================================================== -->
        <!-- <div id="titlebar" class="gradient"> -->
        <!-- <div id="titlebar" class="gradient signup_mrgn">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h1 class="text-center">About our Job Posting Site</h1>
                    </div>
                </div>
            </div>
        </div> -->


    <!-- Page Content
        ================================================== -->
        <div v-bind:style="{backgroundImage: 'url('+ ( about_us[0].image ? about_us[0].image: webUrl+'assets/images/about-us-image.jpg' )+ ')'} " class="about_bg_image bg_img_top">
             <div class="full_gragient">
                <div id="titlebar" class="gradient signup_mrgn about_headings">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                <h2 class="text-center common_top_banner_of_web_heading">About Us</h2>
                            </div>
                        </div>
                    </div>
                </div>
             </div>
        </div>
        <div class="container">
                <div class="row">
                    <div v-if="about_us.length > 0" class="col-xl-12 col-md-12 common_block last_child_p  mobile_tab_max_width_flex about_content">
                        <div v-if="about_us[0].description" class="">
                            <p v-html="about_us[0].description"></p>
                        </div>
                        <div v-else class="no_data_about  mobile_tab_max_width_flex">
                            <h3>No Data Found</h3>
                        </div>
                    </div>
                <!-- <div class="col-xl-6 col-md-6 iframe_about mobile_tab_max_width_flex">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d249.29611652326852!2d103.85267313372445!3d1.3334428538541812!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da176451bf8fe3%3A0xd9f3227da2258e46!2sThe%20Actuary!5e0!3m2!1sen!2sin!4v1691557100744!5m2!1sen!2sin"  height="350" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div> -->
                </div>
        </div>
       <!--  <div class="video common_padding_bottom_50 about_ifame_video" id="exploration">
            <div class="container">
                <div class="row">
                    <div class="col-xl-12">
                            <div class="exploration" v-for="(exploration, h) in video" :key="h">
                            <iframe :src = "exploration.link"
                                    class="fade-in"
                                    title="YouTube video player"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            <div class="desc fade-down">
                                <p>{{ exploration.description }}</p>
                            </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
 -->
        <!-- <div class="container" v-else>
            <div class="row">
                <div class="col-xl-5 offset-xl-3">
                    <div class="col-xl-5 offset-xl-3">
                        <h3>No Data Found</h3>
                    </div>
                </div>
            </div>
        </div> -->

        <!-- Spacer -->
        <div class="margin-top-70 mobile_margin_top_35"></div>
        <!-- Spacer / End-->
    </div>
</template>

<style scoped>
  @import '../../assets/css/style.css';
  @import '../../assets/css/icons.css';

  /* Color css  */

  @import '../../assets/css/colors/blue.css';

</style>
<script>
    import { POST_API } from "../../store/actions.type"

    export default {
        metaInfo: {
            title: 'Job Application Portal  - About Us',
            meta: [
                { charset: 'utf-8' },
            { equiv: 'content-type', content: 'text/html' },
                { name: 'description', content: 'EL Connect is a job application portal which connects non-executive job seekers to reliable hirers in the casual labor job market. Download our app for more!' },
                { name: 'viewport', content: 'width=device-width, initial-scale=1' },
                { name: 'keywords', content: 'el connect, jobs, job, job platform singapore, find full time jobs, daily paid jobs, part time daily paid jobs, daily paid jobs singapore, job posting site, part time jobs, daily paid,daily paid job, apply for full time jobs, connect'},
                {property: 'og:title', content: 'Job Application Portal  - About Us'},
                {property: 'og:site_name', content: 'EL Connect'},
                {property: 'og:type', content: 'website'},
                {property: 'og:url', content: 'https://elconnect.sg/about-us'},
                {property: 'og:image', content: 'https://elconnect.sgp1.digitaloceanspaces.com/v2/general_setting/202281202127_general_setting.png'},
                {property: 'og:description', content: 'EL Connect is a job application portal which connects non-executive job seekers to reliable hirers in the casual labor job market. Download our app for more!'},
                {name: 'twitter:card', content: 'summary'}
            ],
            link: [
    		    {rel: 'canonical', href: 'https://elconnect.sg/about-us'}
  		    ]
        },
        data() {
            return {
                about_us:[],
                showDismissibleAlert:false,
                error_message:'',
                display:true,
                link_error:'',
                webUrl: process.env.VUE_APP_URL,
                video: null,
            }
        },
        methods:{
            getAboutUsData(){
                 this.$store.dispatch(POST_API, {
                    data:{
                        token: this.email,
                    },
                    api:'/api/about-us-data'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        this.$swal({
                            position: 'center',
                            icon: 'error',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });

                    } else {
                        this.about_us = this.$store.getters.getResults.about_us;
                    }
            })

            },
             getWebsiteData() {
      return this.$store.dispatch(POST_API, {
        data: { keyword: this.filter, },
            api: '/api/website-data'
      })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                return [];
            } else {
                this.video = this.$store.getters.getResults.video;
              }
        });
            }
        },
        mounted(){
            //document.title = "EL Connect - About Us";
            this.getAboutUsData();
            window.scrollTo(0, 0);
            this.getWebsiteData();
        },
    }
</script>
