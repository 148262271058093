<template>
    <div>
        <b-form-file style="display: none;" @change="uploadedResumeFile" accept=".pdf"
            id="uploadfileinput"></b-form-file>
        <div v-if="step == 1">
            <section class="padding_btm_section hide_in_mobile">
                <div v-bind:style="{ backgroundImage: 'url(' + (webUrl + 'assets/images/Ellipse-345.png') + ')' }"
                    class="resume_elipse">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-7 col-md-7 resume_margin_col">
                                <h1 class="common_heading_resume_builder">Revolutionize your job search <br>with our AI
                                    Resume Builder</h1>
                                <p class="common_p_resume_builder ai_margin">Our AI Resume Builder helps you craft
                                    compelling resumes tailored to your skills and experience, giving you the edge to
                                    stand
                                    out in the competitive job market. Get professional resumes in minutes.</p>
                                <a href="javascript:void(0);" class="generate_btn_resume"
                                    @click="openResumeBuilderPopUp">Generate Resume
                                    Now</a>
                                <p class="free_to_use">It’s free to use !</p>
                            </div>
                            <div class="col-xl-5 col-md-5 resume_margin_col">
                                <div>
                                    <img :src="webUrl + 'assets/images/RESUME.png'">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="padding_btm_section hide_in_dsktop">
                <div class="resume_elipse">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-12 col-md-12 resume_margin_col">
                                <h2 class="common_heading_resume_builder">Revolutionize your job search with our AI
                                    Resume Builder</h2>
                                <p class="common_p_resume_builder ai_margin">Our AI Resume Builder helps you craft
                                    compelling resumes tailored to your skills and experience, giving you the edge to
                                    stand out in the competitive job market. Get professional resumes in minutes.</p>
                                <a href="javascript:void(0);" class="generate_btn_resume"
                                    @click="openResumeBuilderPopUp">Generate Resume
                                    Now</a>
                                <p class="free_to_use">It’s free to use !</p>
                            </div>

                        </div>
                    </div>
                    <div class="mobile_tab_max_width_flex col-xl-6 bg_elipse_rs col-md-6 resume_elipse2 resume_margin_col"
                        v-bind:style="{ backgroundImage: 'url(' + (webUrl + 'assets/images/Ellipses-345.png') + ')' }">
                        <div>
                            <img :src="webUrl + 'assets/images/RESUME.png'" class="bg_rs_img">
                        </div>
                    </div>
                </div>
            </section>
            <section class="padding_btm_section">
                <div>
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-12 col-md-12 mobile_tab_max_width_flex">
                                <h2 class="common_heading_resume_builder text_center_resume">AI Resume Builder, powered by ChatGPT</h2>
                                <p class="common_p_resume_builder text_center_resume ai_builder_p">Our AI Resume Builder is
                                    designed to enhance and rewrite your resume content, making it more professional and
                                    impactful. This tool is perfect for jobseekers aiming to create a new resume or to
                                    improve their resumes. By refining your existing information, it ensures that your
                                    resume stands out to potential employers, helps you put your best foot forward in
                                    the competitive job market.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="padding_btm_section">
                <div>
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex">
                                <div class="three_grid_div_resume_builder text_center_resume">
                                    <img :src="webUrl + 'assets/images/resume-illus-1.png'">
                                    <h2 class="three_grid_h2">Select Your Starting Point</h2>
                                    <p class="common_p_resume_builder">Choose to build a new resume from scratch or upload an existing one</p>
                                </div>
                            </div>
                            <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex">
                                <div class="three_grid_div_resume_builder text_center_resume">
                                    <img :src="webUrl + 'assets/images/resume-illus-2.png'">
                                    <h2 class="three_grid_h2">Complete Your Details</h2>
                                    <p class="common_p_resume_builder">Fill in all the necessary fields with your personal information</p>
                                </div>
                            </div>
                            <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex">
                                <div class="three_grid_div_resume_builder text_center_resume">
                                    <img :src="webUrl + 'assets/images/resume-illus-3.png'">
                                    <h2 class="three_grid_h2">Enhance with AI</h2>
                                    <p class="common_p_resume_builder">Utilize our AI builder to refine and enhance the resume content</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="padding_btm_section hide_in_mobile">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12 col-md-12">
                            <div class="gradient_div_resume_builder"
                                v-bind:style="{ backgroundImage: 'url(' + (webUrl + 'assets/images/Group-797.png') + ')' }">
                                <h2 class="common_heading_resume_builder text_center_resume">Ready to Create Your
                                    Resume?
                                </h2>
                                <p class="text_center_resume">Don't miss out on the opportunity to stand out in the job
                                    market. Let our Resume <br>Builder help you create a professional resume</p>
                                <div class="module-border-wrap text_center_resume">
                                    <span @click="openResumeBuilderPopUp">
                                        <div class="module">
                                            Try It Now!
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="hide_in_dsktop"
                v-bind:style="{ backgroundImage: 'url(' + (webUrl + 'assets/images/Group-797.png') + ')' }">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12 col-md-12 mobile_tab_max_width_flex">
                            <div class="gradient_div_resume_builder">
                                <h2 class="common_heading_resume_builder text_center_resume">Ready to Create Your Resume?
                                </h2>
                                <p class="text_center_resume">Don't miss out on the opportunity to stand out in the job
                                    market. Let our Resume <br>Builder help you create a professional resume</p>
                                <div class="module-border-wrap text_center_resume">
                                    <span @click="openResumeBuilderPopUp">
                                        <div class="module">
                                            Try It Now!
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <b-modal ref="resume-builder-modal" title="How do you want to build your resume?"
                id="background_custom_class_for_banner" hide-footer centered hide-header
                content-class="common_model_header common_model_title"
                modal-class="background_custom_class job_prefrence_model resume_builder_model">
                <b-form>
                    <template>
                        <div class="row">
                            <div class="col-xl-12 single_profile_eye" id="show_hide_current_password">
                                <h2>How do you want to build your resume?</h2>
                                <h3>Choose and option to get started with our AI Resume Builder</h3>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-6 col-md-6" @click="newResume">
                                <!-- <input v-model = 'job_preference_dp'> -->
                                <!-- <b-form-input>hello</b-form-input> -->
                                <b-form-group id="input-group-6" label=""
                                    class="new_resume_div add_border_rs job_type_dp left_side_checkbox model_margin_bottom_zero required model_date_picker">
                                    <img :src="webUrl + '/assets/images/resume-icon.png'">
                                    <!-- <b-form-checkbox value="yes" unchecked-value="no" v-model = 'job_preference_dp' class="opacity_unset"></b-form-checkbox> -->
                                    <strong>Generate New Resume</strong>
                                    <p>Start from scratch and create a professional resume with our easy-to-use templates</p>
                                </b-form-group>
                            </div>
                            <div class="col-xl-6 col-md-6" @click="existingResume">
                                <b-form-group id="input-group-6" label=""
                                    class="existing_resume_div job_type_ft right_side_checkbox model_margin_bottom_zero required model_date_picker">
                                    <img :src="webUrl + '/assets/images/upload-icon-rs.png'">
                                    <!-- <b-form-checkbox value="yes" unchecked-value="no" v-model = 'job_preference_ft' class="opacity_unset"></b-form-checkbox> -->
                                    <strong>Upload Existing Resume</strong>
                                    <p>Enhance your current resume by uploading it for improvements</p>
                                </b-form-group>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-12 col-md-12 text-md-center common_crop_reset_btn mt-5">
                                <b-button variant="success" class="btn green-border-btntext-uppercase x-md btn-shadow"
                                    @click.stop="checkModal">Next</b-button>
                            </div>
                        </div>
                    </template>
                </b-form>
            </b-modal>
        </div>
        <div class="container-fluid my_rs_container" v-else-if="step == 2">
            <div class="row">
                <div class="col-xl-4 col-md-4 mt-4 mobile_tab_max_width_flex" :id="'editPanel'">
                    <div class="left_side_section_resume_builder padding_btm_section">
                        <!-- <img src="https://elconnect.sgp1.digitaloceanspaces.com/v2/general_setting/202312181739_general_setting.png"
                        class="logo_img"> -->
                        <!-- <img :src="webUrl + '/assets/images/steps-resume-builder.png'" class="steps_resume_img"> -->
                        <div class='stepper-container'>
                            <div class="step-text active-step" @click="stepperOnClick(1)">
                                <div class="step-done-circle" v-if="currentStepper > 1">&#10004;</div>
                                <div class='step-circle active-circle' v-else>1</div>
                                <div class="step-text-content">Personal<br>Details</div>
                            </div>
                            <div class='step-line active-line'></div>
                            <div :class="stepper[2][0]" @click="stepperOnClick(2)">
                                <div class="step-done-circle" v-if="currentStepper > 2">&#10004;</div>
                                <div :class="stepper[2][1]" v-else>2</div>
                                <div class="step-text-content">Skills</div>
                            </div>
                            <div :class="stepper[2][2]"></div>
                            <div :class="stepper[3][0]" @click="stepperOnClick(3)">
                                <div class="step-done-circle" v-if="currentStepper > 3">&#10004;</div>
                                <div :class="stepper[3][1]" v-else>3</div>
                                <div class="step-text-content">Employment<br>History</div>
                            </div>
                            <div :class="stepper[3][2]"></div>
                            <div :class="stepper[4][0]" @click="stepperOnClick(4)">
                                <div :class="stepper[4][1]">4</div>
                                <div class="step-text-content">Education</div>
                            </div>
                        </div>
                        <div v-if="currentStepper == 1">
                            <h2 class="personal_detail_resume_builder">Personal Details</h2>
                            <p class="resume_p_left">Provide essential information about yourself to create a
                                comprehensive
                                profile</p>
                            <div class="form_area">
                                <h5>Profile Image</h5>
                                <div class="upload-form-group resume_builder_file_upload">
                                    <b-form-group id="link-group" label="" class="required">
                                        <div
                                            class="single-image-upload img_uploader_rs new_my_single_img_update my_single_img common_pointer_class emp_img_one height_unset_desktop mobile_max_width_image">
                                            <b-form-file class="customUpload form-control-file" name="Image"
                                                accept="image/*" ref="image" @change="imageHandler"></b-form-file>
                                            <!-- <span v-bind:style="form.selectedImage.image ? 'background-color: #fff !important;' : 'background-color:#F2FBFC !important;' "> -->
                                            <span style="background-color:#F2FBFC !important;" class="bg_span_rs">
                                                <!-- <b-img v-if='form.selectedImage' :src="form.selectedImage.image ? form.selectedImage.image : webUrl+'assets/images/upload-icon.svg'" :disabled="true" style="max-width: 300px;" alt="service_image"></b-img> -->
                                                <!-- <b-img v-if='form.selectedImage.image' :src="form.selectedImage.image" alt="service_image" class="actual_img"></b-img> -->
                                                <b-img v-if="profilePic == ''"
                                                    :src="webUrl + 'assets/images/upload-icon.svg'" alt="service_image"
                                                    class="dummy_img_resume object-fit-scaledown"></b-img>
                                                <b-img v-else :src="profilePic" alt="service_image"
                                                    class="dummy_img_resume object-fit-cover"></b-img>
                                            </span>

                                        </div>
                                        <div class="trash_rs">
                                            <i class="icon-feather-trash-2" style="cursor: pointer;"
                                                @click="clearProfilePic"></i>
                                        </div>
                                    </b-form-group>
                                    <b-modal ref="crop-modal" title="Edit Profile Photo" hide-footer
                                        :no-close-on-backdrop="noClose"
                                        content-class="common_model_header common_model_title"
                                        modal-class="background_custom_class">
                                        <b-form @submit="onSubmitImg">
                                            <template>
                                                <div>
                                                    <div class="content" @change="setImage">
                                                        <section class="cropper-area">
                                                            <div class="img-cropper">
                                                                <vue-cropper ref="cropper" :aspect-ratio="1 / 1"
                                                                    :src="tempPic" preview=".preview" />
                                                            </div>
                                                        </section>
                                                    </div>
                                                </div>
                                                <div class="pt-2 mt-3 text-md-left text-center common_crop_reset_btn">
                                                    <b-button variant="success" class="text-uppercase x-md btn-shadow"
                                                        @click.prevent="cropImage">Crop</b-button>

                                                    <b-button variant="danger" class="text-uppercase x-md btn-shadow"
                                                        @click.prevent="reset">Reset</b-button>
                                                </div>
                                            </template>
                                        </b-form>
                                    </b-modal>
                                </div>
                            </div>
                            <div class="form_area">
                                <h5>Full Name</h5>
                                <input type="text" class="with-border form-control" placeholder="Full Name"
                                    v-model="fullName">
                            </div>
                            <div class="form_area">
                                <h5>Mobile Number</h5>
                                <input type="text" class="with-border form-control" placeholder="Mobile Number"
                                    v-model="mobileNo">
                            </div>
                            <div class="form_area">
                                <h5>Email Address</h5>
                                <input type="text" class="with-border form-control" placeholder="Email Address"
                                    v-model="email">
                            </div>
                            <div class="form_area">
                                <h5>Residential Address</h5>
                                <input type="text" class="with-border form-control" placeholder="Residential Address"
                                    v-model="residentialAddress">
                            </div>
                            <div class="form_area">
                                <h5>About Myself</h5>
                                <textarea class="with-border form-control"
                                    placeholder="Brief introduction about yourself" v-model="aboutMeSummary"></textarea>
                                <div class="generate_ai" @click="getGeneratedText(1, aboutMeSummary)">
                                    <button><span><img
                                                :src="webUrl + 'assets/images/streamline_ai-redo-spark.png'"></span>Rewrite
                                        by AI</button>
                                </div>
                            </div>
                            <div class="form_area">
                                <h5>Interests</h5>
                                <textarea class="with-border form-control"
                                    placeholder="e.g. Reading, Painting, Dancing ..." v-model="interest"></textarea>
                            </div>
                        </div>
                        <div v-else-if="currentStepper == 2">
                            <h2 class="personal_detail_resume_builder">Skills</h2>
                            <p class="resume_p_left">Showcase your expertise and abilities</p>
                            <div class="form_area">
                                <h5 class="margin_bottom_20">Skill Recommendations</h5>
                                <div class="col-xl-7 col-md-7 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28">
                                    <div class="search-box desktop_margin_top_zero">
                                        <b-form-select class="with-border form-control category-select" v-model="skillRecommendations">
                                            <option value="accounting and finance">Accounting/Finance</option>
                                            <option value="administrative">Administrative</option>
                                            <option value="customer service and retail">Customer Service/Retail</option>
                                            <option value="education adn training">Education/Training</option>
                                            <option value="engineering">Engineering</option>
                                            <option value="food and beverage or hotel">Food and Beverage/Hotel</option>
                                            <option value="healthcare">Healthcare</option>
                                            <option value="information technology">Information Technology (IT)</option>
                                            <option value="logistics">Logistics</option>
                                            <option value="sales and marketing">Sales/Marketing</option>
                                        </b-form-select>
                                    </div>
                                </div>
            
                                <p>Recommendations: {{ recommendation }}</p>
                                
                                <div class="generate_ai" @click="getSkillRecommendations(skillRecommendations)">
                                    <button><span><img
                                            :src="webUrl + 'assets/images/streamline_ai-redo-spark.png'"></span>Get Skill Recommendations</button>
                                </div>
                            </div>                            
                            <div class="form_area">
                                <h5 class="margin_bottom_20">Key Skills (min 3)</h5>
                                <div class="row" v-for="(skill, index) of skills">
                                    <div class="col-xl-7 col-md-7">
                                        <input type="text" class="with-border form-control"
                                            placeholder="Type your skill here" v-model="skill[0]">
                                    </div>
                                    <div class="col-xl-5 col-md-5">
                                        <!-- <img :src="webUrl + '/assets/images/Frames-1598.png'" class="skill_img"> -->
                                        <div class="skill-rating">
                                            <span v-if="skill[1] == 5" @click="skillRate(index, 5)" :id="'skill' + index + 'rate5'" class="skill-checked"></span>
                                            <span v-if="skill[1] < 5" @click="skillRate(index, 5)" :id="'skill' + index + 'rate5'"></span>
                                            <span v-if="skill[1] >= 4" @click="skillRate(index, 4)" :id="'skill' + index + 'rate4'" class="skill-checked"></span>
                                            <span v-if="skill[1] < 4" @click="skillRate(index, 4)" :id="'skill' + index + 'rate4'"></span>
                                            <span v-if="skill[1] >= 3" @click="skillRate(index, 3)" :id="'skill' + index + 'rate3'" class="skill-checked"></span>
                                            <span v-if="skill[1] < 3" @click="skillRate(index, 3)" :id="'skill' + index + 'rate3'"></span>
                                            <span v-if="skill[1] >= 2" @click="skillRate(index, 2)" :id="'skill' + index + 'rate2'" class="skill-checked"></span>
                                            <span v-if="skill[1] < 2" @click="skillRate(index, 2)" :id="'skill' + index + 'rate2'"></span>
                                            <span v-if="skill[1] >= 1" @click="skillRate(index, 1)" :id="'skill' + index + 'rate1'" class="skill-checked"></span>
                                            <span v-if="skill[1] < 1" @click="skillRate(index, 1)" :id="'skill' + index + 'rate1'"></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form_area">
                                    <span class="add_skill" @click="addSkill"><i class="material-icons-outlined">add</i>
                                        Add
                                        Skill</span><br>
                                    <span class="add_skill remove_skills" v-if="skills.length > 3"
                                        @click="removeSkill"><i class="material-icons-outlined">remove</i>
                                        Remove
                                        Previous Skill</span>
                                </div>
                            </div>
                        </div>
                        <div v-else-if="currentStepper == 3">
                            <h2 class="personal_detail_resume_builder">Employment History</h2>
                            <p class="resume_p_left">Your employment experience and roles</p>
                            <div class="form_area">
                                <h5>Role Title</h5>
                                <input type="text" class="with-border form-control" placeholder="Role Title"
                                    v-model="employmentInfo.role">
                            </div>
                            <div class="form_area">
                                <h5>Company Name</h5>
                                <input type="text" class="with-border form-control" placeholder="Company Name"
                                    v-model="employmentInfo.company">
                            </div>
                            <div class="form_area">
                                <h5>Location</h5>
                                <textarea class="with-border form-control" placeholder="Company Location"
                                    v-model="employmentInfo.location"></textarea>
                            </div>
                            <div class="form_area">
                                <h5>Summary</h5>
                                <textarea class="with-border form-control"
                                    placeholder="Brief description of your responsibilities and achievements"
                                    v-model="employmentInfo.summary"></textarea>
                                <div class="generate_ai">
                                    <button @click="getGeneratedText(2, employmentInfo.summary)"><span><img
                                                :src="webUrl + 'assets/images/streamline_ai-redo-spark.png'"></span>Rewrite
                                        by AI</button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xl-6 col-md-6">
                                    <div class="form_area date_pick_div">
                                        <h5>Start Date</h5>
                                        <datepicker placeholder="Select Start Date" :format="customDateResume"
                                            minimum-view="month"
                                            class="resume_calender  freelancer_incentive_calender freelancer_incentive_big_calender"
                                            v-model="employmentInfo.startDate">
                                        </datepicker>
                                    </div>
                                </div>
                                <div class="col-xl-6 col-md-6">
                                    <div class="form_area date_pick_div">
                                        <h5>End Date</h5>
                                        <datepicker placeholder="Select End Date" :format="customDateResume"
                                            minimum-view="month"
                                            class="resume_calender  freelancer_incentive_calender freelancer_incentive_big_calender"
                                            v-model="employmentInfo.endDate">
                                        </datepicker>
                                    </div>
                                </div>
                            </div>
                            <div class="form_area">
                                <span class="add_skill" @click="addEmploymentRecord"><i
                                        class="material-icons-outlined">add</i>
                                    Add Record</span><br />
                                <span class="add_skill remove_skills" v-if="employmentHistory.length > 0"
                                    @click="removeEmploymentRecord"><i class="material-icons-outlined">remove</i> Remove
                                    Previous Record</span>
                            </div>
                        </div>
                        <div v-else-if="currentStepper == 4">
                            <h2 class="personal_detail_resume_builder">Education</h2>
                            <p class="resume_p_left">Your latest academic background or qualification</p>
                            <div class="form_area">
                                <h5>Course or Qualification Name</h5>
                                <input type="text" class="with-border form-control"
                                    placeholder="Course or Qualification" v-model="education.course">
                            </div>
                            <div class="form_area">
                                <h5>Institution Name</h5>
                                <input type="text" class="with-border form-control" placeholder="Institution Name"
                                    v-model="education.institution">
                            </div>
                            <div class="form_area">
                                <h5>Location</h5>
                                <textarea class="with-border form-control" placeholder="Institution Location"
                                    v-model="education.location"></textarea>
                            </div>
                            <div class="form_area">
                                <h5>Summary</h5>
                                <textarea class="with-border form-control"
                                    placeholder="Brief overview of your studies and accomplishments"
                                    v-model="education.summary"></textarea>
                                <div class="generate_ai">
                                    <button @click="getGeneratedText(3, education.summary)"><span><img
                                                :src="webUrl + 'assets/images/streamline_ai-redo-spark.png'"></span>Rewrite
                                        by AI</button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xl-6 col-md-6">
                                    <div class="form_area date_pick_div">
                                        <h5>Start Date</h5>
                                        <datepicker placeholder="Select Start Date" :format="customDateResume"
                                            minimum-view="month"
                                            class="resume_calender  freelancer_incentive_calender freelancer_incentive_big_calender"
                                            v-model="education.startDate">
                                        </datepicker>
                                    </div>
                                </div>
                                <div class="col-xl-6 col-md-6">
                                    <div class="form_area date_pick_div">
                                        <h5>End Date</h5>
                                        <datepicker placeholder="Select End Date" :format="customDateResume"
                                            minimum-view="month"
                                            class="resume_calender  freelancer_incentive_calender freelancer_incentive_big_calender"
                                            v-model="education.endDate">
                                        </datepicker>
                                    </div>
                                </div>
                            </div>
                            <div class="form_area">
                                <span class="add_skill" @click="addEducationRecord"><i
                                        class="material-icons-outlined">add</i>
                                    Add Record</span><br />
                                <span class="add_skill remove_skills" @click="removeEducationRecord"
                                    v-if="educationArray.length > 0"><i class="material-icons-outlined">remove</i>
                                    Remove
                                    Previous Record</span>
                            </div>
                        </div>
                        <div :class="formBtn">
                            <button v-if="currentStepper > 1" @click="backStepper" class="back_btn"><i
                                    class="material-icons-outlined">arrow_back</i>Back</button>
                            <button v-if="currentStepper < 4" @click="nextStepper">Next</button>
                            <button v-else @click="nextStepper" class="desktop_preview_section">Save</button>
                            <button class="mobile_preview_section" v-if="currentStepper == 4"
                                @click="editFinish">Next</button>
                        </div>
                    </div>
                </div>
                <div class="col-xl-8 col-md-8 resume-background desktop_preview_section">
                    <div class="mt-5"></div>
                    <div class="flex_div_rs" id="resume">
                        <div :class=leftCol>
                            <div v-if="profilePic != ''">
                                <!-- <img :src="webUrl + '/assets/images/Group-797.png'" class="left_side_rs_img"
                                    v-if="profilePic == ''"> -->
                                <img :src="profilePic" class="left_side_rs_img">
                            </div>
                            <div class="left_inner_dv_rs">
                                <h2 class="heading_left_h2_tg">Contact</h2>
                                <div class="content_section">
                                    <h5>Mobile</h5>
                                    <p data-placeholder="+65 1234 5678">{{ mobileNo }}</p>
                                </div>
                                <div class="content_section">
                                    <h5>Email</h5>
                                    <p data-placeholder="abc@elconnect.sg">{{ email }}</p>
                                </div>
                                <div class="content_section">
                                    <h5>Address</h5>
                                    <p v-if="residentialAddress != ''">{{ residentialAddress }}</p>
                                    <p v-else>Example, examples example <br>examplessssss Singapore 123456</p>
                                </div>
                                <div v-if="interests.length > 0">
                                    <h2 class="heading_left_h2_tg">Interests</h2>
                                    <!-- <ul class="interest_ul" v-if="interests.length == 0 && interest == ''">
                                    <li>Interest 1</li>
                                    <li>Interest 2</li>
                                    <li>Interest 3</li>
                                    <li>Interest 4</li>
                                    <li>Interest 5</li>
                                </ul> -->
                                    <ul class="interest_ul">
                                        <li v-for="i in interests" :key="i">{{ i }}</li>
                                    </ul>
                                </div>
                                <h2 class="heading_left_h2_tg">Skills</h2>
                                <ul class="interest_ul" v-if="skills[0][0] == ''">
                                    <li>Skill 1</li>
                                    <li class="list_style_none"><img :src="webUrl + '/assets/images/Frame-1598.png'">
                                    </li>
                                    <li>Skill 2</li>
                                    <li class="list_style_none"><img :src="webUrl + '/assets/images/Frame-1598.png'">
                                    </li>
                                    <li>Skill 3</li>
                                    <li class="list_style_none"><img :src="webUrl + '/assets/images/Frame-1598.png'">
                                    </li>
                                    <li>Skill 4</li>
                                    <li class="list_style_none"><img :src="webUrl + '/assets/images/Frame-1598.png'">
                                    </li>
                                </ul>
                                <ul class="interest_ul" v-else>
                                    <li v-for="(skill, index) of skills">{{ skill[0] }}<br>
                                        <!-- the purpose of having 10 in this manner is to ensure the skill rating is reflected correctly no matter what sequence the user completes the inputs in -->
                                        <div class="skill-rating-inside">
                                            <span v-if="skill[1] == 5" class="skill-checked"
                                                :id="'skillinside' + index + 'rate5'"></span>
                                            <span v-if="skill[1] < 5" :id="'skillinside' + index + 'rate5'"></span>
                                            <span v-if="skill[1] >= 4" class="skill-checked"
                                                :id="'skillinside' + index + 'rate4'"></span>
                                            <span v-if="skill[1] < 4" :id="'skillinside' + index + 'rate4'"></span>
                                            <span v-if="skill[1] >= 3" class="skill-checked"
                                                :id="'skillinside' + index + 'rate3'"></span>
                                            <span v-if="skill[1] < 3" :id="'skillinside' + index + 'rate3'"></span>
                                            <span v-if="skill[1] >= 2" class="skill-checked"
                                                :id="'skillinside' + index + 'rate2'"></span>
                                            <span v-if="skill[1] < 2" :id="'skillinside' + index + 'rate2'"></span>
                                            <span v-if="skill[1] >= 1" class="skill-checked"
                                                :id="'skillinside' + index + 'rate1'"></span>
                                            <span v-if="skill[1] < 1" :id="'skillinside' + index + 'rate1'"></span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div :class=rightCol>
                            <!-- <div data-placeholder="Full Name" class="right_side_rs_name">{{ fullName }}</div> -->
                            <div class="right_side_rs_name">{{ fullName }}</div>
                            <!-- <p v-show="aboutMeSummary.length == 0"><b>Place
                                    holder
                                    for about me</b>
                            </p> -->
                            <p v-show="aboutMeSummary.length > 0">{{
                                aboutMeSummary
                                }}</p>
                            <div class="resume-section"
                                v-if="employmentInfo.role != '' || employmentHistory.length > 0">
                                <h2 class="common_border_btm_rs border_h2_tag">Employment History</h2>
                                <div data-placeholder="Place holder for your employment history/ work experience"
                                    class="text-wrap">
                                    <!-- <div v-show="employmentHistory.length == 0">
                                        <p><strong>Aug 2019 - Sept 2023</strong></p>
                                        <p><strong>Company Name | Location</strong></p>
                                        <p><strong>Role Title</strong></p>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                            Lorem
                                            Ipsum
                                            has been the industry's standard dummy text ever since the 1500s, when an
                                            unknown
                                            printer took</p>
                                    </div> -->
                                    <div v-if="employmentInfo.role != ''">
                                        <p><strong
                                                v-if="employmentInfo.startDate != '' || employmentInfo.endDate != ''">{{
                                                    employmentInfo.startDate | customMonthYear }} - {{
                                                    employmentInfo.endDate |
                                                    customMonthYear }}</strong></p>
                                        <p><strong
                                                v-if="employmentInfo.company != '' || employmentInfo.location != ''">{{
                                                    employmentInfo.company }} | {{ employmentInfo.location }}</strong></p>
                                        <p><strong v-if="employmentInfo.role != ''">{{ employmentInfo.role }}</strong>
                                        </p>
                                        <p v-if="employmentInfo.summary != ''">{{ employmentInfo.summary }}</p>
                                    </div>
                                    <div v-for="e of employmentHistory" :key="e">
                                        <p><strong v-if="e.startDate != '' || e.endDate != ''">{{
                                            e.startDate }} - {{ e.endDate }}</strong></p>
                                        <p><strong v-if="e.company != '' || e.location != ''">{{
                                            e.company }} | {{ e.location }}</strong></p>
                                        <p><strong v-if="e.role != ''">{{ e.role }}</strong></p>
                                        <p v-if="e.summary != ''">{{ e.summary }}</p>
                                    </div>
                                </div>
                            </div>
                            <div v-if="education.course != '' || educationArray.length > 0">
                                <h2 class="common_border_btm_rs border_h2_tag">Education</h2>
                                <div data-placeholder="Place holder for your education records" class="text-wrap">
                                    <!-- <div v-show="educationArray.length == 0">
                                        <p><strong>Aug 2015 - Sept 2018</strong></p>
                                        <p><strong>Institute Name | Location</strong></p>
                                        <p><strong>Course or Qualification</strong></p>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                            Lorem
                                            Ipsum
                                            has been the industry's standard dummy text ever since the 1500s, when an
                                            unknown
                                            printer took</p>
                                    </div> -->
                                    <div v-if="education.course != ''">
                                        <p><strong v-if="education.startDate != '' || education.endDate != ''">{{
                                            education.startDate | customMonthYear }} -
                                                {{
                                                    education.endDate | customMonthYear }}</strong></p>
                                        <p><strong v-if="education.institution != '' || education.location != ''">{{
                                            education.institution
                                                }}
                                                | {{
                                                    education.location }}</strong></p>
                                        <p><strong v-if="education.course != ''">{{ education.course }}</strong></p>
                                        <p v-if="education.summary != ''">{{ education.summary }}</p>
                                    </div>
                                    <div v-for="edu of educationArray" :key="edu">
                                        <p><strong v-if="edu.startDate != '' || edu.endDate != ''">{{ edu.startDate }} -
                                                {{
                                                    edu.endDate }}</strong></p>
                                        <p><strong v-if="edu.institution != '' || edu.location != ''">{{ edu.institution
                                                }}
                                                | {{
                                                    edu.location }}</strong></p>
                                        <p><strong v-if="edu.course != ''">{{ edu.course }}</strong></p>
                                        <p v-if="edu.summary != ''">{{ edu.summary }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-5"></div>
                    <div class="resume-2-buttons">
                        <button class="swap-btn" @click="handleSwap"><i
                                class="material-icons-outlined">swap_horizontal</i>Swap
                            Columns Position
                            (Left/Right)</button>
                        <button style="align-self: flex-end;" @click="downloadToPDF" class="save-btn">Save as
                            PDF</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="mobile_tab_max_width_flex col-xl-8 col-md-8 resume-background mobile_preview_section"
            :id="'fullPageResume'" v-if="step == 2 && currentStepper == 5">
            <!-- <div class="flex_div_rs">
                    <div class="left_side_rs">
                        <img :src="webUrl + '/assets/images/Group-797.png'" class="left_side_rs_img">
                        <div class="left_inner_dv_rs">
                            <h2 class="heading_left_h2_tg">Contact</h2>
                            <div class="content_section">
                                <h5>Mobile</h5>
                                <p>+65 1234 5678</p>
                            </div>
                            <div class="content_section">
                                <h5>Email</h5>
                                <p>abc@elconnect.sg</p>
                            </div>
                            <div class="content_section">
                                <h5>Address</h5>
                                <p>Example, examples example <br>examplessssss Singapore 123456</p>
                            </div>
                            <h2 class="heading_left_h2_tg">Interests</h2>
                            <ul class="interest_ul">
                                <li>Interest 1</li>
                                <li>Interest 2</li>
                                <li>Interest 3</li>
                                <li>Interest 4</li>
                                <li>Interest 5</li>
                            </ul>
                            <h2 class="heading_left_h2_tg">Skills</h2>
                            <ul class="interest_ul">
                                <li>Skill 1</li>
                                <li class="list_style_none"><img :src="webUrl + '/assets/images/Frame-1598.png'"></li>
                                <li>Skill 2</li>
                                <li class="list_style_none"><img :src="webUrl + '/assets/images/Frame-1598.png'"></li>
                                <li>Skill 3</li>
                                <li class="list_style_none"><img :src="webUrl + '/assets/images/Frame-1598.png'"></li>
                                <li>Skill 4</li>
                                <li class="list_style_none"><img :src="webUrl + '/assets/images/Frame-1598.png'"></li>
                            </ul>
                        </div>
                    </div>
                    <div class="right_side_rs">
                        <h2>Divya D/O Thachinamoorthi</h2>
                        <p>Hardworking College Student seeking employment. Adept in various social media platforms
                            and office technology programs. Bringing forth a motivated attitude and a variety of
                            powerful skills. Committed to utilizing my skills to further the mission of a company.
                        </p>
                        <h2 class="common_border_btm_rs border_h2_tag">Employment History</h2>
                        <p><strong>Aug 2019 Sept 2023</strong></p>
                        <p><strong>Company Name | Location</strong></p>
                        <p><strong>Role Title</strong></p>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
                            has been the industry's standard dummy text ever since the 1500s, when an unknown
                            printer took</p>
                        <p><strong>Oct 2023 - Dec 2024</strong></p>
                        <p><strong>EL Connect | 745 Lor. 5 Toa Payoh, Singapore 319455</strong></p>
                        <p><strong>Role Title</strong></p>
                        <h2 class="common_border_btm_rs border_h2_tag">Education</h2>
                        <p><strong>Aug 2015 - Sept 2018</strong></p>
                        <p><strong>Institute Name | Location</strong></p>
                        <p><strong>Course or Qualification</strong></p>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
                            has been the industry's standard dummy text ever since the 1500s, when an unknown
                            printer took</p>
                        <p><strong>Oct 2023 - Dec 2024</strong></p>
                        <p><strong>EL Connect | 745 Lor. 5 Toa Payoh, Singapore 319455</strong></p>
                        <p><strong>Bachelor Degree in Computer Science</strong></p>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
                            has been the industry's standard dummy text ever since the 1500s, when an unknown
                            printer took</p>
                    </div>
                </div> -->
            <div class="pt-5"></div>
            <div class="flex_div_rs" id="resume">
                <div :class=leftCol>
                    <div v-if="profilePic != ''">
                        <!-- <img :src="webUrl + '/assets/images/Group-797.png'" class="left_side_rs_img"
                            v-if="profilePic == ''"> -->
                        <img :src="profilePic" class="left_side_rs_img">
                    </div>
                    <div class="left_inner_dv_rs">
                        <h2 class="heading_left_h2_tg">Contact</h2>
                        <div class="content_section">
                            <h5>Mobile</h5>
                            <p data-placeholder="+65 1234 5678">{{ mobileNo }}</p>
                        </div>
                        <div class="content_section">
                            <h5>Email</h5>
                            <p data-placeholder="abc@elconnect.sg">{{ email }}</p>
                        </div>
                        <div class="content_section">
                            <h5>Address</h5>
                            <p data-placeholder="Example, examples example <br>examplessssss Singapore 123456">{{
                                residentialAddress }}</p>
                        </div>
                        <div v-if="interests.length > 0">
                            <h2 class="heading_left_h2_tg">Interests</h2>
                            <!-- <ul class="interest_ul" v-if="interests.length == 0 && interest == ''">
                            <li>Interest 1</li>
                            <li>Interest 2</li>
                            <li>Interest 3</li>
                            <li>Interest 4</li>
                            <li>Interest 5</li>
                        </ul> -->
                            <ul class="interest_ul">
                                <li v-for="i in interests" :key="i">{{ i }}</li>
                            </ul>
                        </div>
                        <h2 class="heading_left_h2_tg">Skills</h2>
                        <ul class="interest_ul" v-if="skills[0][0] == ''">
                            <li>Skill 1</li>
                            <!-- <li class="list_style_none"><img :src="webUrl + '/assets/images/Frame-1598.png'"></li> -->
                            <li class="list_style_none">
                                <div class="skill-rating mobile_skill_preview">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </li>
                            <li>Skill 2</li>
                            <!-- <li class="list_style_none"><img :src="webUrl + '/assets/images/Frame-1598.png'"></li> -->
                            <li class="list_style_none">
                                <div class="skill-rating mobile_skill_preview">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </li>
                            <li>Skill 3</li>
                            <!-- <li class="list_style_none"><img :src="webUrl + '/assets/images/Frame-1598.png'"></li> -->
                            <li class="list_style_none">
                                <div class="skill-rating mobile_skill_preview">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </li>
                            <li>Skill 4</li>
                            <!-- <li class="list_style_none"><img :src="webUrl + '/assets/images/Frame-1598.png'"></li> -->
                            <li class="list_style_none">
                                <div class="skill-rating mobile_skill_preview">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </li>
                        </ul>
                        <ul class="interest_ul" v-else>
                            <li v-for="(skill, index) of skills">
                                <div v-if="skill[0] != ''">
                                    {{ skill[0] }}<br>
                                    <!-- the purpose of having 10 in this manner is to ensure the skill rating is reflected correctly no matter what sequence the user completes the inputs in -->
                                    <div class="skill-rating-inside mobile_skill_preview mobile-inside">
                                        <span v-if="skill[1] == 5" class="skill-checked"
                                            :id="'skillinside' + index + 'rate5'"></span>
                                        <span v-if="skill[1] < 5" :id="'skillinside' + index + 'rate5'"></span>
                                        <span v-if="skill[1] >= 4" class="skill-checked"
                                            :id="'skillinside' + index + 'rate4'"></span>
                                        <span v-if="skill[1] < 4" :id="'skillinside' + index + 'rate4'"></span>
                                        <span v-if="skill[1] >= 3" class="skill-checked"
                                            :id="'skillinside' + index + 'rate3'"></span>
                                        <span v-if="skill[1] < 3" :id="'skillinside' + index + 'rate3'"></span>
                                        <span v-if="skill[1] >= 2" class="skill-checked"
                                            :id="'skillinside' + index + 'rate2'"></span>
                                        <span v-if="skill[1] < 2" :id="'skillinside' + index + 'rate2'"></span>
                                        <span v-if="skill[1] >= 1" class="skill-checked"
                                            :id="'skillinside' + index + 'rate1'"></span>
                                        <span v-if="skill[1] < 1" :id="'skillinside' + index + 'rate1'"></span>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div :class=rightCol>
                    <div data-placeholder="Full Name" class="right_side_rs_name">{{ fullName }}</div>
                    <p v-show="aboutMeSummary.length == 0"><b>Place
                            holder
                            for your about me</b>
                    </p>
                    <p v-show="aboutMeSummary.length > 0">{{
                        aboutMeSummary
                    }}</p>
                    <div class="resume-section" v-if="employmentInfo.role != '' || employmentHistory.length > 0">
                        <h2 class="common_border_btm_rs border_h2_tag">Employment History</h2>
                        <div data-placeholder="Place holder for your employment history/ work experience"
                            class="text-wrap">
                            <!-- <div v-show="employmentHistory.length == 0">
                                <p><strong>Aug 2019 - Sept 2023</strong></p>
                                <p><strong>Company Name | Location</strong></p>
                                <p><strong>Role Title</strong></p>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                    Ipsum
                                    has been the industry's standard dummy text ever since the 1500s, when an
                                    unknown
                                    printer took</p>
                            </div> -->
                            <div v-if="employmentInfo.role != ''">
                                <p><strong v-if="employmentInfo.startDate != '' || employmentInfo.endDate != ''">{{
                                    employmentInfo.startDate | customMonthYear }} - {{
                                            employmentInfo.endDate |
                                            customMonthYear }}</strong></p>
                                <p><strong v-if="employmentInfo.company != '' || employmentInfo.location != ''">{{
                                    employmentInfo.company }} | {{ employmentInfo.location }}</strong></p>
                                <p><strong v-if="employmentInfo.role != ''">{{ employmentInfo.role }}</strong>
                                </p>
                                <p v-if="employmentInfo.summary != ''">{{ employmentInfo.summary }}</p>
                            </div>
                            <div v-for="e of employmentHistory" :key="e">
                                <p><strong v-if="e.startDate != '' || e.endDate != ''">{{
                                    e.startDate }} - {{ e.endDate }}</strong></p>
                                <p><strong v-if="e.company != '' || e.location != ''">{{
                                    e.company }} | {{ e.location }}</strong></p>
                                <p><strong v-if="e.role != ''">{{ e.role }}</strong></p>
                                <p v-if="e.summary != ''">{{ e.summary }}</p>
                            </div>
                        </div>
                    </div>
                    <div v-if="education.course != '' || educationArray.length > 0">
                        <h2 class="common_border_btm_rs border_h2_tag">Education</h2>
                        <div data-placeholder="Place holder for your education records" class="text-wrap">
                            <!-- <div v-show="educationArray.length == 0">
                            <p><strong>Aug 2015 - Sept 2018</strong></p>
                            <p><strong>Institute Name | Location</strong></p>
                            <p><strong>Course or Qualification</strong></p>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                Ipsum
                                has been the industry's standard dummy text ever since the 1500s, when an unknown
                                printer took</p>
                        </div> -->
                            <div v-if="education.course != ''">
                                <p><strong v-if="education.startDate != '' || education.endDate != ''">{{
                                    education.startDate | customMonthYear }} -
                                        {{
                                            education.endDate | customMonthYear }}</strong></p>
                                <p><strong v-if="education.institution != '' || education.location != ''">{{
                                    education.institution
                                        }}
                                        | {{
                                            education.location }}</strong></p>
                                <p><strong v-if="education.course != ''">{{ education.course }}</strong></p>
                                <p v-if="education.summary != ''">{{ education.summary }}</p>
                            </div>
                            <div v-for="edu of educationArray" :key="edu">
                                <p><strong v-if="edu.startDate != '' || edu.endDate != ''">{{ edu.startDate }} - {{
                                    edu.endDate }}</strong></p>
                                <p><strong v-if="edu.institution != '' || edu.location != ''">{{ edu.institution }} | {{
                                    edu.location }}</strong></p>
                                <p><strong v-if="edu.course != ''">{{ edu.course }}</strong></p>
                                <p v-if="edu.summary != ''">{{ edu.summary }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="mt-5"></div> -->
            <div class="resume-2-buttons-mobile pb-5">
                <div class="mobile-swap-center">
                    <button class="swap-btn" @click="handleSwap"><i
                            class="material-icons-outlined">swap_horizontal</i>Swap
                        Columns
                        Position
                        (Left/Right)</button>
                </div>
                <div class="mobile-save-back-buttons next_btn space-between">
                    <button class="back_btn" @click="backToEdit"><i
                            class="material-icons-outlined">arrow_back</i>Back</button>
                    <button @click="downloadToPDF" class="save-btn" style="margin-top: 0 !important;">Save as
                        PDF</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { POST_API, LOGIN, PERMISSION_DATA } from "../../../store/actions.type"
import JwtService from '../../../common/jwt.service';
import axios from 'axios';
import html2pdf from 'html2pdf.js';
import Datepicker from 'vuejs-datepicker';
import VueCropper from 'vue-cropperjs';
import moment from 'moment';

export default {
      metaInfo: {
        title: 'Revolutionize your job search with our AI Resume Builder',
        meta: [
            { charset: 'utf-8' },
            { equiv: 'content-type', content: 'text/html' },
            { name: 'description', content: 'Our AI Resume Builder helps you craft compelling resumes tailored to your skills and experience, giving you the edge to stand out in the competitive job market.' },
            { name: 'viewport', content: 'width=device-width, initial-scale=1' },
            {property: 'og:title', content: 'Revolutionize your job search with our AI Resume Builder'},
            {property: 'og:site_name', content: 'EL Connect'},
            {property: 'og:type', content: 'website'},
            {property: 'og:url', content: 'https://elconnect.sg/resume-builder'},
            {property: 'og:image', content: 'https://elconnect.sgp1.digitaloceanspaces.com/v2/general_setting/202281202127_general_setting.png'},
            {property: 'og:description', content: 'Our AI Resume Builder helps you craft compelling resumes tailored to your skills and experience, giving you the edge to stand out in the competitive job market.'},
            {name: 'twitter:card', content: 'summary'}
        ],
        link: [
            {rel: 'canonical', href: 'https://elconnect.sg/resume-builder'}
        ]
      },
    data() {
        return {
            webUrl: process.env.VUE_APP_URL,
            elcyUrl: process.env.VUE_APP_URL_ELCY,
            step: 1,
            currentStepper: 1,
            customDateResume: 'MMM yyyy',
            profilePic: '',
            tempPic: '',
            resumeOption: '',
            fullName: '',
            mobileNo: '',
            email: '',
            residentialAddress: '',
            aboutMeSummary: '',
            aboutMeSummaryLoading: false,
            interest: '',
            interests: [],
            employmentHistory: [],
            employmentInfo: {
                role: '',
                company: '',
                location: '',
                summary: '',
                startDate: '',
                endDate: '',
                loading: false,
            },
            educationArray: [],
            education: {
                course: '',
                institution: '',
                location: '',
                summary: '',
                startDate: '',
                endDate: '',
                loading: false,
            },
            skills: [
                ['', 0],
                ['', 0],
                ['', 0]
            ],
            uploadedResume: '',
            leftCol: ['left_side_rs', 'left-col'],
            rightCol: ['right_side_rs', 'right-col'],
            columnSwapped: false,
            profileImgSection: true,
            interestSection: true,
            stepper: {
                2: [['step-text'], ['step-circle'], ['step-line']],
                3: [['step-text'], ['step-circle'], ['step-line']],
                4: [['step-text'], ['step-circle']],
            },
            formBtn: ['next_btn', 'flex-end'],
            noClose: true,
            columnSwapped: false,
            errMessage: '',
            skillRecommendations: "",
            recommendation: "",            
        }
    },
    components: {
        Datepicker,
        VueCropper,
    },
    computed: {

    },
    filters: {
        customMonthYear(date) {
            return (date) ? moment(date).format('MMM yyyy') : 'Present';
        }
    },
    watch: {
        interest(text) {
            if (text.includes(',')) {
                var interestArr = text.split(',');
                interestArr = interestArr.map(x => x.trim());
                this.interests = interestArr;
            } else {
                this.interests = [text];
            }

            if (text == '') {
                this.interests = [];
            }
        }
    },
    methods: {
        showSuccess(msg) {
            this.$swal({
                position: 'center',
                icon: 'success',
                title: msg,
                showConfirmButton: false,
                timer: 1500
            });
        },
        openResumeBuilderPopUp() {
            this.$refs['resume-builder-modal'].show();
        },
        newResume() {
            $('.new_resume_div').addClass("add_border_rs");
            $('.existing_resume_div').removeClass("add_border_rs");
            this.resumeOption = 'new';
        },
        existingResume() {
            $('.new_resume_div').removeClass("add_border_rs");
            $('.existing_resume_div').addClass("add_border_rs");
            this.resumeOption = 'uploaded';
        },
        async downloadToPDF() {
            const resume = document.getElementById('resume');
            const config = {
                margin: 0,
                filename: 'Resume',
                jsPDF: {
                    unit: 'mm',
                    format: 'a4',
                }
            }
            html2pdf().set(config).from(resume).save();
            this.showSuccess('Resume Downloaded to PDF')
        },
        async getGeneratedText(category, text) {
            if (text == '') {
                this.errMessage = 'The text is empty. Please type something :)';
                this.showAlert();
                return;
            }
            if (category == 1) {
                this.aboutMeSummaryLoading = true;
                this.aboutMeSummary = '';
            } else if (category == 2) {
                this.employmentInfo.loading = true;
                this.employmentInfo.summary = '';
            } else if (category == 3) {
                this.education.loading = true;
                this.education.summary = '';
            } 
            const token = JwtService.getToken();
            await fetch(this.elcyUrl + "/regenerateText", {
                method: 'POST',
                body: JSON.stringify({
                    category: category,
                    text: text,
                }),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            }).then(async (res) => {
                if (res.status == 401) {
                    this.errMessage = 'Token expired. Please refresh the page.';
                    this.showAlert();
                    this.tokenForGuest();
                    this.resetLoading(category);
                    return;
                } else if (res.status == 500) {
                    this.errMessage = 'Failed to get response. Please try again in a minute.';
                    this.showAlert();
                    this.resetLoading(category);
                    return;
                }
                try {
                    const reader = res.body.getReader();
                    var status = true;
                    while (status) {
                        const { done, value } = await reader.read();

                        if (done) {
                            status = false;
                            break;
                        }
                        const text = new TextDecoder().decode(value);
                        this.resetLoading(category);
                        if (category == 1) {
                            this.aboutMeSummary += text;
                        } else if (category == 2) {
                            this.employmentInfo.summary += text;
                        } else if (category == 3) {
                            this.education.summary += text;
                        } 
                    }
                } catch (err) {
                    console.log(err)
                }
            })
        },
        async getSkillRecommendations(category) {  
            this.recommendation = "";
            console.log(category);
            const token = JwtService.getToken();
            await fetch(this.elcyUrl + "/generateKeySkills", {
                method: 'POST',
                body: JSON.stringify({
                    category: category,
                }),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            }).then(async (res) => {
                if (res.status == 401) {
                    this.errMessage = 'Token expired. Please refresh the page.';
                    this.showAlert();
                    this.tokenForGuest();
                    this.resetLoading(category);
                    return;
                } else if (res.status == 500) {
                    this.errMessage = 'Failed to get response. Please try again in a minute.';
                    this.showAlert();
                    this.resetLoading(category);
                    return;
                }
                try {
                    const reader = res.body.getReader();
                    var status = true;
                    while (status) {
                        const { done, value } = await reader.read();

                        if (done) {
                            status = false;
                            break;
                        }
                        const text = new TextDecoder().decode(value);
                        this.resetLoading(category);
                        this.recommendation += text;
                    }
                } catch (err) {
                    console.log(err)
                }
            })
            
        },
        handleSwap() {
            if (this.columnSwapped == true) {
                this.leftCol.pop();
                this.rightCol.pop();
                this.columnSwapped = false;
                return;
            }
            this.leftCol.push('swap-right')
            this.rightCol.push('swap-left')
            this.columnSwapped = true;
        },
        resetLoading(category) {
            if (category == 1) {
                this.aboutMeSummaryLoading = false;
            } else if (category == 2) {
                this.employmentInfo.loading = false;
            } else if (category == 3) {
                this.education.loading = false;
            }
        },
        async tokenForGuest() {
            await axios.get(this.elcyUrl + '/tokenForGuest', {
                headers: {
                    'Content-Type': 'application/json'
                },
            }).then((res) => {
                JwtService.setToken(res.data.jwtToken);
                return Promise.resolve(true);
            })
        },
        async init() {
            try {
                const apiToken = JwtService.getToken();
                if (apiToken == undefined || apiToken == null) {
                    // this.errMessage = 'No Token found, generating guest token for you :)'
                    // this.showAlert();
                    await this.tokenForGuest();
                }
            } catch (err) {
                console.log(err)
            }
        },
        showAlert() {
            this.$swal({
                position: 'center',
                icon: 'error',
                title: this.errMessage,
                showConfirmButton: false,
                timer: 1500
            }).then(() => {
                this.errMessage = '';
            });
        },
        nextStepper() {
            if (this.currentStepper == 3) {
                if (this.employmentInfo.role != '' && this.employmentInfo.company != '') {
                    this.addEmploymentRecord();
                }
            } else if (this.currentStepper == 4) {
                if (this.education.course != '' && this.education.institution != '') {
                    this.addEducationRecord();
                }
                this.downloadToPDF();
                return;
            }
            this.currentStepper += 1;
            this.formBtn[1] = 'space-between';
            this.stepper[this.currentStepper][0].push('active-step');
            this.stepper[this.currentStepper][1].push('active-circle');
            if (this.currentStepper !== 4) {
                this.stepper[this.currentStepper][2].push('active-line');
            }
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },
        backStepper() {
            if (this.currentStepper === 2) {
                this.formBtn[1] = 'flex-end';
            }
            this.stepper[this.currentStepper][0].pop();
            this.stepper[this.currentStepper][1].pop();
            if (this.currentStepper !== 4) {
                this.stepper[this.currentStepper][2].pop();
            }
            this.currentStepper -= 1;
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },
        stepperOnClick(index) {
            if (index == this.currentStepper) {
                return;
            }
            if (index > 1) {
                this.formBtn[1] = 'space-between';
            } else if (index == 1) {
                this.formBtn[1] = 'flex-end';
            }
            if (index > this.currentStepper) {
                for (let i = 2; i <= index; i++) {
                    if (this.stepper[i][0].length < 2 && this.stepper[i][1].length < 2) {
                        this.stepper[i][0].push('active-step');
                        this.stepper[i][1].push('active-circle');
                        if (i != 4 && this.stepper[i][2].length < 2) {
                            this.stepper[i][2].push('active-line');
                        }
                    }
                }
            } else {
                for (let i = this.currentStepper; i > index; i--) {
                    if (this.stepper[i][0].length > 1 && this.stepper[i][1].length > 1) {
                        this.stepper[i][0].pop();
                        this.stepper[i][1].pop();
                        if (i != 4 && this.stepper[i][2].length > 1) {
                            this.stepper[i][2].pop();
                        }
                    }
                }
            }
            this.currentStepper = index;
        },
        imageHandler(event) {
            const input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                this.tempPic = input.files[0];
                if (this.tempPic.type != 'image/png' && this.tempPic.type != 'image/jpeg' && this.tempPic.type != 'image/jpg') {
                    this.errMessage = 'Please select only .png,.jpg or .jpeg image.';
                    this.tempPic = '';
                    event.target.value = '';
                    this.showAlert();
                } else {
                    reader.onload = (e) => {
                        this.tempPic = e.target.result;
                        this.showImg();
                        event.target.value = '';
                    }
                }
            }
        },
        showImg() {
            this.$refs['crop-modal'].show();
        },
        onSubmitImg(evt) {
            evt.preventDefault();
            this.$refs['crop-modal'].hide();
            this.$refs['crop-modal'].refresh();
        },
        cropImage() {
            this.$refs['crop-modal'].hide();
            this.profilePic = this.$refs.cropper.getCroppedCanvas().toDataURL();
        },
        reset() {
            this.$refs.cropper.reset();
        },
        clearProfilePic() {
            this.profilePic = '';
            this.tempPic = '';
        },
        setImage(e) {
            const file = e.target.files[0];
            if (file.type.indexOf('image/') === -1) {
                this.errMessage = 'Please select an image file';
                this.showAlert();
                return;
            }
            if (typeof FileReader === 'function') {
                const reader = new FileReader();
                reader.onload = (event) => {
                    this.$refs.cropper.replace(event.target.result);
                };
                reader.readAsDataURL(file);
            } else {
                this.errMessage = 'Sorry, FileReader API not supported';
                this.showAlert();
            }
        },
        skillRate(index, value) {
            if (this.skills[index][1] > value) {
                for (let i = this.skills[index][1]; i > value; i--) {
                    $("#skill" + index + "rate" + i).removeClass("skill-checked");
                    $("#skillinside" + index + "rate" + i).removeClass("skill-checked");
                }
            } else {
                for (let i = 1; i <= value; i++) {
                    $("#skill" + index + "rate" + i).addClass("skill-checked");
                    $("#skillinside" + index + "rate" + i).addClass("skill-checked");
                }
            }
            this.skills[index][1] = value;
        },
        addSkill() {
            this.skills.push(['', 0]);
        },
        removeSkill() {
            this.skills.pop();
        },
        addEmploymentRecord() {
            var startDate = '';
            var endDate = '';
            if (this.employmentInfo.startDate) {
                startDate = moment(this.employmentInfo.startDate).format('MMM yyyy');
            }
            if (this.employmentInfo.endDate) {
                endDate = moment(this.employmentInfo.endDate).format('MMM yyyy');
            } else if (this.employmentInfo.startDate && !this.employmentInfo.endDate) {
                endDate = 'Present';
            }
            this.employmentHistory.push({
                startDate: startDate,
                endDate: endDate,
                company: this.employmentInfo.company,
                location: this.employmentInfo.location,
                role: this.employmentInfo.role,
                summary: this.employmentInfo.summary,
            });
            this.clearEmploymentInfo();
        },
        removeEmploymentRecord() {
            this.employmentHistory.pop();
            this.clearEmploymentInfo();
        },
        clearEmploymentInfo() {
            this.employmentInfo.role = '';
            this.employmentInfo.company = '';
            this.employmentInfo.location = '';
            this.employmentInfo.startDate = '';
            this.employmentInfo.endDate = '';
            this.employmentInfo.summary = '';
        },
        addEducationRecord() {
            var startDate = '';
            var endDate = '';
            if (this.education.startDate) {
                startDate = moment(this.education.startDate).format('MMM yyyy');
            }
            if (this.education.endDate) {
                endDate = moment(this.education.endDate).format('MMM yyyy');
            } else if (this.education.startDate && !this.education.endDate) {
                endDate = 'Present';
            }
            this.educationArray.push({
                startDate: startDate,
                endDate: endDate,
                course: this.education.course,
                location: this.education.location,
                institution: this.education.institution,
                summary: this.education.summary,
            });
            this.clearEducation();
        },
        removeEducationRecord() {
            this.educationArray.pop();
            this.clearEducation();
        },
        clearEducation() {
            this.education.course = '';
            this.education.institution = '';
            this.education.location = '';
            this.education.summary = '';
            this.education.startDate = '';
            this.education.endDate = '';
        },
        editFinish() {
            this.currentStepper = 5;
            $('#editPanel').addClass('hide-edit');
            // $('#fullPageResume').addClass('full-page');
            // $('#fullPageResume').removeClass('col-xl-8 col-md-8');
        },
        handleSwap() {
            if (this.columnSwapped == true) {
                this.leftCol.pop();
                this.rightCol.pop();
                this.columnSwapped = false;
                return;
            }
            this.leftCol.push('swap-right')
            this.rightCol.push('swap-left')
            this.columnSwapped = true;
        },
        backToEdit() {
            this.currentStepper = 4;
            $('#editPanel').removeClass('hide-edit');
        },
        checkModal() {
            this.step += 1;
            if (this.resumeOption == 'uploaded') {
                document.getElementById('uploadfileinput').click();
            }
        },
        uploadedResumeFile(event) {
            var input = event.target;
            if (input.files && input.files[0]) {
                this.uploadedResume = input.files[0];
                if (this.uploadedResume.type != 'application/pdf') {
                    this.errMessage = "File need to be PDF"
                    this.showAlert();
                    return;
                } else {
                    const fileReader = new FileReader();
                    fileReader.readAsDataURL(this.uploadedResume);
                    fileReader.onload = async (e) => {
                        this.showSuccess('File uploaded successfully\nPlease give it a while')
                        // console.log(`e`, e.target.result);
                        await axios.post(this.elcyUrl + '/handleUploadedResume', {
                            data: e.target.result
                        }, {
                            headers: {
                                "Authorization": `Bearer ${JwtService.getToken()}`,
                                'Content-Type': 'application/json'
                            },
                        })
                            .then((res) => {
                                console.log(`res`, res.data)
                                if (res.data == 'failed') throw new Error('failed to load the pdf');
                                const resumeData = res.data;
                                this.fullName = resumeData.name;
                                this.email = resumeData.emailAddr;
                                this.mobileNo = resumeData.contactNo;
                                this.residentialAddress = resumeData.address;
                                this.aboutMeSummary = resumeData.aboutMeSummary;
                                this.employmentHistory = resumeData.employmentArr;
                                this.educationArray = resumeData.educationArr;
                                resumeData.skills.map((s, index) => {
                                    if (index > 2) {
                                        this.skills.push([s, 0]);
                                    } else {
                                        this.skills[index][0] = s;
                                    }
                                });
                                this.interests = resumeData.interests;
                            }).catch(err => {
                                console.log(err)
                                this.errMessage = err.message;
                                this.showAlert();
                            })
                    }
                    return;
                }
            }
        },
    },
    mounted() {
        this.init();
    }
}
</script>

<style scoped>
.resume-background {
    background: #989898 !important;
}

#resume {
    height: 296.8mm;
    /* this height and weight is fixed to keep A4 size */
    width: 210mm;
    margin: auto;
}

.left-col {
    order: 1;
}

.left-col.swap-right {
    order: 2 !important;
}

.right-col {
    order: 2;
}

.right-col.swap-left {
    order: 1 !important;
}

.section-headline {
    font-size: 20px;
    font-weight: var(--headline-weight);
    margin-bottom: 15px;
    margin-top: 0;
}

.resume-section {
    margin-bottom: 30px;
}

#resume ul {
    padding-inline-start: 16px;
    margin-block-end: 0px;
    margin-block-start: 5px;
}

ul.contactSection {
    list-style: none;
}

.contactSection li {
    margin-left: -15px;
}

.contactSection span {
    margin-right: 8px;
}

.profile-pic {
    display: block;
    width: 160px;
    height: 160px;
    border: 5px solid var(--highlight-color-left);
    margin-bottom: 20px;
    object-fit: cover;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
    text-align: center;
    line-height: 160px;
}

.text-wrap {
    word-wrap: break-word;
}

div:empty:not(:focus):before {
    content: attr(data-placeholder);
    color: #999999;
}

span:empty:not(:focus):before {
    content: attr(data-placeholder);
    color: #999999;
}

ul:empty:not(:focus):before {
    content: attr(data-placeholder);
    color: #999999;
}

h2:empty:not(:focus)::before {
    content: attr(data-placeholder);
    color: #999999;
}

p:empty:not(:focus)::before {
    content: attr(data-placeholder);
    color: #999999;
}

.stepper-container {
    display: flex;
    flex-direction: row;
    gap: 6px;
    justify-content: center;
    align-content: center;
    margin-bottom: 20px;
    flex-wrap: wrap;
}

.step-circle {
    border: 2px solid #777777;
    font-size: 14px;
    font-weight: 600;
    border-radius: 100%;
    text-align: center;
    width: 30px;
    height: 30px;
    line-height: 30px;
    vertical-align: middle;
}

.step-line {
    width: 50px;
    border: 1px solid #777777;
    height: 1px;
    margin-top: 15px;
}

.step-text {
    display: flex;
    flex-direction: column;
    width: 40px;
    align-items: center;
    cursor: pointer;
}

.step-text-content {
    width: 100px;
    text-align: center;
    margin-top: 10px;
    font-size: 16px;
    font-family: 'Nunito' !important;
}

.active-step {
    color: #4C8BF5;
}

.active-circle {
    border: 2px solid #4C8BF5 !important;
}

.active-line {
    border: 1px solid #4C8BF5 !important;
}

.flex-end {
    justify-content: flex-end;
}

.space-between {
    justify-content: space-between !important;
}

.step-done-circle {
    border: 2px solid #4C8BF5 !important;
    background-color: #4C8BF5 !important;
    font-size: 14px;
    border-radius: 100%;
    text-align: center;
    width: 30px;
    height: 30px;
    line-height: 30px;
    vertical-align: middle;
    color: #ffffff;
}

.dummy_img_resume {
    display: block;
    width: 160px;
    height: 160px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 100%;
    text-align: center;
}

.object-fit-scaledown {
    object-fit: scale-down !important;
}

.object-fit-cover {
    object-fit: cover !important;
}

.category-select {
    height: 48px;
	line-height: 48px;
	padding: 0 20px;
	outline: none;
	font-size: 16px;
	color: #808080;
	margin: 0 0 16px 0;
	max-width: 100%;
	width: 100%;
	box-sizing: border-box;
	display: block;
	background-color: #fff;
	font-weight: 500;
	opacity: 1;
	border-radius: 4px;
	box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.12);
}

.category-select:focus {
    color: #808080;
	transition: box-shadow 0.2s !important;
	opacity: 1;
	box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.12);
}

.skill-rating {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    /* gap: 20px; */
    justify-content: space-evenly;
    margin-top: 14px;
    margin-bottom: 20px;
}

.skill-rating span {
    width: 15px;
    height: 15px;
    border-radius: 100%;
    border: 1px solid #777777;
    cursor: pointer;
}

.skill-rating span:before {
    display: none;
}

.skill-rating span:hover~span,
.skill-rating span:hover {
    background-color: #777777;
}

.skill-checked {
    background-color: #FCA50F !important;
}

.skill-rating-inside {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    justify-content: flex-end;
    gap: 15px;
    margin-bottom: 10px;
}

.skill-rating-inside span {
    width: 15px;
    height: 15px;
    border-radius: 100%;
    background-color: #ffffff;
}

.hide-edit {
    display: none;
}

.full-page {
    width: 100% !important;
    display: block;
    justify-content: center;
}

.resume-2-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 210mm;
    margin: 0 auto 5% auto;
}

.save-btn {
    background: #EF1AA7;
    color: #fff;
    padding: 7px 40px 7px 40px;
    border-radius: 30px;
    font-family: 'Nunito' !important;
    font-weight: 800;
    font-size: 16px;
}

.swap-btn i {
    vertical-align: bottom;
    margin-right: 5px;
    font-size: 22px;
}

.swap-btn {
    background: unset !important;
    color: #ffffff !important;
    padding: unset !important;
    font-family: 'Nunito' !important;
    font-weight: 800;
    font-size: 16px;
}

.mobile-swap-center {
    order: 1 !important;
    align-self: center;
}

.resume-2-buttons-mobile {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 5% auto 0 auto;
    row-gap: 15px;
}

.mobile-save-back-buttons {
    order: 2 !important;
}

@media only screen and (max-width: 768px)
{
    .mobile-inside span {
        margin-right: 0 !important;
    }

    .mobile-inside {
        gap: 10px !important;
        margin-bottom: 3px !important;
    }
}
</style>
