<template>
  <div>
    <!-- <div class="container full_container_dsk">
            <div class="row custom_dsk_row"> -->
    <div class="row">
      <div class="container my_new_container">
        <!-- Dashboard Headline -->
        <div class="dashboard-headline">
          <h1>Opt Out WhatsApp Blast</h1>
          <!-- Breadcrumbs -->
          <nav id="breadcrumbs" class="dark">
            <ul>
              <li><a href="#">Home</a></li>
              <li>Opt Out WhatsApp Blasts</li>
            </ul>
          </nav>
        </div>
        <b-tabs class="my_tab_job tab_header_action_th common_tab_inline_scroll">
          <b-tab :title="'Freelancer'" @click="changeTab('freelancer')">
            <div class="container new_cnt_pdng my_new_container">
              <div class="row">
                <div
                  class="search_common_margin_bottom_28 col-xl-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_18_mobile"
                >
                  <div class="search-box custom_search_admin desktop_margin_top_zero">
                    <b-input-group-prepend>
                      <b-form-input
                        id="searchBtn"
                        @keyup.enter.native="filter = searchKeyword"
                        v-model="searchKeyword"
                        placeholder="Search by Name, Email, Mobile Number"
                      ></b-form-input>
                      <b-button class="btn points_events"
                        ><i class="fa fa-search" @click="filter = searchKeyword"></i
                      ></b-button>
                    </b-input-group-prepend>
                  </div>
                </div>

                <div
                  class="search_btn_div_width search_common_margin_bottom_28 col-xl-2 col-md-2 mobile_tab_max_width_flex search_common_margin_bottom_18_mobile"
                >
                  <b-button
                    @click="filter = searchKeyword"
                    class="search_btn_whitelist mobile_tab_margin_right_zero common_width_100_min_width_100_button button ripple-effect search_btn_all filter_btn_widht_120 desktop_margin_left_zero"
                    style="width: 120px"
                    >Search</b-button
                  >
                </div>
                <div class="col-xl-12 mobile_tab_max_width_flex">
                  <div class="sidebar-widget penalty_btn desktop_margin_bottom_zero">
                    <div
                      class="search_common_margin_bottom_18_mobile select-all-checkbox whitelist_select employer_multiple_radio"
                      v-if="items && items.length > 0 && show('delete')"
                    >
                      <div class="checkbox_with_trash">
                        <b-form-checkbox
                          v-model="isSelectAllCheckedFL"
                          @change="toggleSelectAllWhitelist"
                        >
                          Select All
                        </b-form-checkbox>
                        <b-link
                          v-if="items && items.length > 0"
                          class="btn green-border-btn trash_btn_whitelist"
                          :class="whitelistRemoveIDArr.length == 0 ? 'disabled' : ''"
                          @click="removeMultipleFromWhatsappOptOutFL()"
                          ><i class="icon-feather-trash-2"></i
                        ></b-link>
                      </div>
                    </div>
                    <b-button
                      v-if="filter || searchKeyword"
                      @click="resetFilter()"
                      class="new_reset_text mobile_tab_padding_right_zero mobile_tab_padding_left_zero mobile_tab_padding_top_zero mobile_tab_padding_bottom_zero new_black_reset_filter common_font_size_14 common_reset_btn_filter_transactions button ripple-effect search_all_red_btn filter_btn_widht_120 desktop_margin_right_zero"
                      style="background-color: red"
                      ><span class="mobile_reset">Reset filters</span></b-button
                    >
                    <b-button
                      v-if="show('add')"
                      @click="openInfoModel()"
                      class="desktop_margin_left_zero search_common_margin_bottom_18_mobile search_common_margin_bottom_28 mobile_tab_margin_left_zero common_width_100_min_width_100_button common_hover_effect_white_bg"
                      >Create</b-button
                    >
                    <b-button
                      class="desktop_margin_left_zero search_common_margin_bottom_18_mobile search_common_margin_bottom_28 mobile_tab_margin_left_zero common_width_100_min_width_100_button common_hover_effect_white_bg"
                      @click="exportAllFL()"
                      v-if="show('export')"
                      style="background-color: #4c8bf5; color: white"
                    >
                      Export
                    </b-button>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="container my_new_container show_filter_arrow">
                <!--     <div class="select-all-checkbox" v-if="items && items.length > 0">
                  <b-form-checkbox v-model="isSelectAllCheckedFL" @change="toggleSelectAllWhitelist">
                    Select All
                  </b-form-checkbox>
                </div> -->
                <b-table
                  ref="datatableFreelancer"
                  show-empty
                  striped
                  hover
                  responsive
                  :items="getWhatsAppOptPutFL"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter="filter"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  class="mobile_min_width_1000 eight_grid_tbl_new"
                >
                  <template #cell(checkBox)="items" v-if="show('delete')">
                    <b-form-checkbox
                      v-model="whitelistRemoveIDArr"
                      :value="items.item.user_id"
                    ></b-form-checkbox>
                  </template>
                  <template #cell(last_name)="items">
                    <div class="new-name-td-box">
                      <div class="whole_div_img_and_name">
                        <div class="manage_user_img">
                          <b-img
                            v-if="items.item.thumbnail || items.item.profile_image"
                            :src="
                              items.item.thumbnail
                                ? items.item.thumbnail
                                : items.item.profile_image
                                ? items.item.profile_image
                                : webUrl + 'assets/images/user-avatar-small-01.png'
                            "
                            alt="User Icon"
                            height="100px"
                            width="100px"
                            class="square_img"
                          >
                          </b-img>
                          <b-img
                            v-else-if="
                              items.item.ft_profile_image_thumbnail || items.item.ft_profile_image
                            "
                            :src="
                              items.item.ft_profile_image_thumbnail
                                ? items.item.ft_profile_image_thumbnail
                                : items.item.ft_profile_image
                                ? items.item.ft_profile_image
                                : webUrl + 'assets/images/user-avatar-small-01.png'
                            "
                            alt="User Icon"
                            height="100px"
                            width="100px"
                            class="square_img"
                          >
                          </b-img>

                          <b-img
                            v-else
                            :src="webUrl + 'assets/images/user-avatar-small-01.png'"
                            alt="User Icon"
                            height="100px"
                            width="100px"
                            class="square_img"
                          >
                          </b-img>
                        </div>
                        <div class="user_name_div">
                          <b-link
                            :to="
                              showFreelancer('view profile')
                                ? {
                                    name: 'jobseeker-dp-profile-view',
                                    params: { id: encrypt(items.item.user_id) },
                                  }
                                : null
                            "
                            target="_blank"
                            style="color: #4c8bf5"
                          >
                            {{ items.item.last_name | capitalize }}
                            {{ items.item.first_name | capitalize }}
                          </b-link>
                        </div>
                      </div>
                      <p class="grey-text">
                        {{ items.item.email }}
                      </p>
                    </div>
                  </template>
                  <template #cell(mobile_number)="items">
                    <b-link
                      :href="'https://wa.me/+65' + items.item.mobile_number"
                      target="_blank"
                      style="color: #4c8bf5"
                    >
                      {{ items.item.country_code }}-{{ items.item.mobile_number }}
                    </b-link>
                  </template>
                  <template #cell(whatsapp_blast_opt)="items">
                    <p class="green_status" v-if="items.item.whatsapp_blast_opt == 'on'">
                      {{ "ON" }}
                    </p>
                    <p class="red_status" v-if="items.item.whatsapp_blast_opt == 'off'">
                      {{ "OFF" }}
                    </p>
                  </template>
                  <template #cell(whatsapp_blast_remarks)="data">
                    <div class="d-flex align-items-center justify-content-between">
                      <span>{{ data.item.whatsapp_blast_remarks }}</span>
                      <!-- Edit button: clicking this will open the edit modal -->
                      <b-button size="sm" variant="link" @click="openEditRemarkModal(data.item)">
                        <i class="fa fa-pencil"></i>
                      </b-button>
                    </div>
                  </template>
                </b-table>
                <nav class="pagination-box custom_pagination_border_radius">
                  <div class="page-row-box d-inline-block">
                    <b-form-group id="input-group-4">
                      <span class="d-inline-block">Rows per page</span>
                      <span class="d-inline-block">
                        <b-form-select v-model="form.rowsPerPage" @change="getWhatsAppOptPutFL">
                          <option>25</option>
                          <option>50</option>
                          <option>75</option>
                          <option>100</option>
                        </b-form-select></span
                      >
                    </b-form-group>
                  </div>
                  <div class="total-page-count d-inline-block">
                    <ul>
                      <li>
                        <span v-if="totalRows != 0">{{ from }} - {{ to }} of {{ totalRows }}</span>
                      </li>
                    </ul>
                  </div>
                  <b-pagination
                    :total-rows="totalRows"
                    :per-page="perPage"
                    v-model="currentPage"
                    prev-text=""
                    next-text=""
                    hide-goto-end-buttons
                  />
                </nav>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
    <!-- Spacer -->
    <!-- <div class="margin-top-70"></div> -->
    <!-- Spacer / End-->
    <!-- Row / End -->

    <b-modal
      ref="create-whatsapp-opt-out-modal"
      title="Opt out from whatsapp blast"
      hide-footer
      modal-class="background_custom_class"
      content-class="common_model_header common_model_title"
    >
      <div class="edit-form-box model_margin_top_zero model_legend">
        <div class="profile-repeate form-box-field">
          <b-row>
            <b-col lg="12" md="12" class="submit-field">
              <b-form-group
                id="input-group-8"
                label="Select Freelancer"
                class="required model_margin_bottom_zero"
              >
                <multiselect
                  v-model="create_whatsapp_opt_out_fl"
                  id="ajax"
                  label="last_name"
                  :custom-label="customLabelFL"
                  track-by="id"
                  placeholder="Type to search Freelancer"
                  open-direction="bottom"
                  :options="createWhatsappOptOutFLList"
                  :multiple="true"
                  :searchable="true"
                  :clear-on-select="true"
                  :close-on-select="true"
                  :value="create_whatsapp_opt_out_fl"
                  :allow-empty="true"
                  @input="mapCompId(create_whatsapp_opt_out_fl)"
                  @open="handleOpenComFL"
                  @search-change="handleSearchChangeFL"
                  class="job_list_multi_select model_multi_select"
                >
                  <span slot="noResult">No Data Found.</span>
                </multiselect>
              </b-form-group>
            </b-col>
            <b-col lg="12" md="12" class="submit-field">
              <b-form-group
                id="input-group-remarks"
                label="Remarks"
                class="required model_margin_bottom_zero"
              >
                <b-form-textarea
                  v-model="remarks"
                  placeholder="Enter any remarks"
                  rows="3"
                  max-rows="5"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
          <div class="text-md-left text-center desktop_margin_top_zero common_crop_reset_btn">
            <center>
              <b-button
                type="button"
                @click="storeMultipleWhatsAppOptOutFL()"
                variant="success"
                class="button_min_width_130 model_common_bt_success_color x-md btn-shadow desktop_margin_left_zero desktop_margin_right_zero"
                >Submit</b-button
              >
            </center>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      ref="edit-whatsapp-remark-modal"
      title="Edit WhatsApp Blast Remark"
      hide-footer
      modal-class="background_custom_class"
      content-class="common_model_header common_model_title"
    >
      <div class="model_margin_top_zero model_legend">
        <b-form-group label="Edit Remark">
          <b-form-textarea
            v-model="editRemark"
            placeholder="Enter updated remark"
            rows="3"
            max-rows="5"
          ></b-form-textarea>
        </b-form-group>
        <div slot="footer" class="form-btn common_crop_reset_btn">
          <b-button
            variant="primary"
            @click="updateWhatsappRemark"
            class="model_common_bt_success_color"
            >Save</b-button
          >
          <b-button
            variant="secondary"
            @click="hideEditRemarkModal"
            class="model_common_bt_lite_color"
            >Cancel</b-button
          >
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { POST_API } from "../../../../store/actions.type";
import permission from "../../../../../../server/permission.js";
import Multiselect from "vue-multiselect";

import moment from "moment";

export default {
  data() {
    return {
      fields: [
        {
          key: "checkBox",
          label: "Select All",
          sortable: false,
          thStyle: { width: "8%" },
        },
        {
          key: "last_name",
          label: "Name & Email",
          sortable: true,
          thStyle: { width: "22%" },
        },
        {
          key: "mobile_number",
          label: "Mobile Number",
          sortable: true,
          thStyle: { width: "15%" },
        },
        {
          key: "whatsapp_blast_opt",
          label: "WhatsApp Blast",
          sortable: true,
          thStyle: { width: "15%" },
        },
        {
          key: "whatsapp_blast_remarks",
          label: "WhatsApp Blast Remarks",
          sortable: true,
          thStyle: { width: "15%" },
        },
      ],

      pager: {},
      pageOfItems: [],
      items: null,
      totalRows: 0,
      from: "",
      to: "",
      currentPage: 1,
      searchKeyword: "",
      perPage: 25,
      pageOptions: [25, 50, 75, 100],
      sortBy: null,
      sortDirection: "desc",
      filter: "",
      sortDesc: true,
      remarks: "",
      editRemark: "",
      editUserId: null,
      webUrl: process.env.VUE_APP_URL,
      webServerUrl: process.env.VUE_APP_SERVER_URL,

      form: {
        rowsPerPage: 25,
        user_id: "",
      },
      currentTab: "freelancer",
      actions: "",
      actionsFreelancer: "",
      company_whitelist: "",
      whitelisted_companies: "",
      designation: "",
      whitelistRemoveIDArr: [],
      isSelectAllCheckedFL: false,
      create_whatsapp_opt_out_fl: [],
      createWhatsappOptOutFLList: [],
      is_whitelist: "no",
      isFLListLoaded: false,
      searchDataFL: "",
    };
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    moment: function (date) {
      if (date != null) return moment(date).utc().format("DD MMM YYYY hh:mm A");
      else return "-";
    },
  },
  components: {
    Multiselect,
  },
  watch: {
    whitelistRemoveIDArr(newSelectedItems) {
      this.isSelectAllCheckedFL = newSelectedItems.length === this.items.length;
    },
    items(newItems) {
      if (newItems.length !== this.whitelistRemoveIDArr.length) {
        this.isSelectAllCheckedFL = false;
      }
    },
  },
  methods: {
    openEditRemarkModal(item) {
      console.log("Edit button clicked for:", item);
      this.editRemark = item.whatsapp_blast_remarks;
      this.editUserId = item.user_id;
      this.$refs["edit-whatsapp-remark-modal"].show();
    },

    hideEditRemarkModal() {
      this.$refs["edit-whatsapp-remark-modal"].hide();
    },

    updateWhatsappRemark() {
      this.$store
        .dispatch(POST_API, {
          data: {
            user_id: this.editUserId,
            remarks: this.editRemark,
          },
          api: "/api/update-whatsapp-remark",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.$swal({
              position: "center",
              icon: "success",
              iconColor: "#4c8bf5",
              title: "Remark updated successfully",
              showConfirmButton: false,
              timer: 1500,
            }).then(() => {
              this.refreshDataTable();
              this.hideEditRemarkModal();
            });
          }
        })
        .catch((error) => {
          console.error("Error updating remark:", error);
        });
    },
    showSuccessAlert(message) {
      this.$swal({
        position: "center",
        icon: "success",
        iconColor: "#4c8bf5",
        title: message,
        showConfirmButton: false,
        timer: 1500,
      }).then((result) => {
        this.refreshDataTable();
        // this.$router.push({ name: 'job-poster-list' });
      });
    },
    showAlert() {
      this.$swal({
        position: "center",
        icon: "error",
        title: this.error_message,
        showConfirmButton: false,
        timer: 1500,
      });
    },

    customLabelFL({ full_name, first_name, last_name, country_code, mobile_number }) {
      // return `${full_name} (${country_code}-${mobile_number})`;
      return `${last_name} ${first_name} (${country_code}-${mobile_number})`;
    },

    changeTab(currentTab) {
      this.currentTab = currentTab;
      this.isSelectAllCheckedFL = false;
      this.whitelistRemoveIDArr = [];
      this.$refs.datatableFreelancer.refresh();
    },
    resetFilter() {
      this.company_whitelist = "";
      this.filter = "";
      this.searchKeyword = "";
      this.$refs.datatableFreelancer.refresh();
    },
    refreshDataTable() {
      this.whitelisted_companies = "";
      this.isSelectAllCheckedFL = false;
      this.whitelistRemoveIDArr = [];
      this.create_whatsapp_opt_out_fl = [];
      this.$refs.datatableFreelancer.refresh();
    },

    toggleSelectAllWhitelist() {
      if (this.isSelectAllCheckedFL) {
        this.whitelistRemoveIDArr = this.items.map((item) => item.user_id);
      } else {
        this.whitelistRemoveIDArr = [];
      }
    },
    singleIDSelectWhitelist(event, userId) {
      if (event) {
        this.whitelistRemoveIDArr.push(userId);
      } else {
        const index = this.whitelistRemoveIDArr.indexOf(userId);
        if (index > -1) {
          this.whitelistRemoveIDArr.splice(index, 1);
        }
      }
      this.isSelectAllCheckedFL = this.whitelistRemoveIDArr.length === this.items.length;
    },

    exportAllFL() {
      var base_url_v2 = this.webServerUrl;
      var search = this.filter != "" ? this.filter : "null";
      var page = this.currentPage;
      var rowsPerPage = this.form.rowsPerPage;
      var sortBy = this.sortBy ? this.sortBy : "u.id";
      var sortDirection = this.sortDesc ? "desc" : "asc";

      var export_url =
        "/api/export-whatsapp-opt-out-fl/" +
        search +
        "/" +
        page +
        "/" +
        rowsPerPage +
        "/" +
        sortBy +
        "/" +
        sortDirection;

      window.open(base_url_v2 + export_url, "_blank");
    },
    getWhatsAppOptPutFL() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            page: this.currentPage,
            keyword: this.filter,
            rowsPerPage: this.form.rowsPerPage,
            sortBy: this.sortBy ? this.sortBy : "u.id",
            sortDirection: this.sortDesc ? "desc" : "asc",
          },
          api: "/api/whatsAppOptOutFL",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            // this.showAlert();
            return [];
          } else {
            this.items = this.$store.getters.getResults.pageOfItems;
            this.totalRows = this.$store.getters.getResults.pager.totalItems;
            this.perPage = this.$store.getters.getResults.pager.pageSize;
            this.from = this.$store.getters.getResults.pager.from;
            this.to = this.$store.getters.getResults.pager.to;
            return this.items;
          }
        });
    },

    storeMultipleWhatsAppOptOutFL(type) {
      var msg = "Are you sure you want to opt out these users from whatsapp blast?";
      var msgg = "Users opted out from whatsapp blast successfully.";
      this.$swal({
        title: "Please Confirm",
        text: msg,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      })
        .then((result) => {
          if (result.value) {
            return this.$store
              .dispatch(POST_API, {
                data: {
                  flIDArr: this.create_whatsapp_opt_out_fl,
                  remarks: this.remarks,
                },
                api: "/api/whatsapp-opt-out-multiple-fl",
              })
              .then(() => {
                if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
                } else {
                  this.hideModel();
                  this.$swal({
                    position: "center",
                    icon: "success",
                    iconColor: "#4c8bf5",
                    title: msgg,
                    showConfirmButton: false,
                    timer: 2500,
                  }).then((result) => {
                    this.refreshDataTable();
                  });
                }
              });
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },

    removeMultipleFromWhatsappOptOutFL() {
      var msg = "Are you sure you want to remove these users from opt out from whatsapp blast?";
      var msgg = "Users removed from opt out from whatsapp blast successfully.";
      this.$swal({
        title: "Please Confirm",
        text: msg,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        showCloseButton: true,
        imageUrl: "/assets/images/alert-icon.png",
        customClass: {
          container: "whitelist_container",
        },
      }).then((result) => {
        if (result.value) {
          return this.$store
            .dispatch(POST_API, {
              data: {
                idArr: this.whitelistRemoveIDArr,
              },
              api: "/api/whatsapp-opt-out-remove-multiple-fl",
            })
            .then(() => {
              if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showAlert();
              } else {
                this.$swal({
                  position: "center",
                  icon: "success",
                  iconColor: "#4c8bf5",
                  title: msgg,
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  this.refreshDataTable();
                });
              }
            });
        }
      });
    },

    openInfoModel() {
      this.is_whitelist = "no";
      this.loadLimitedFreelancer();
      this.create_whatsapp_opt_out_fl = [];
      this.remarks = "";
      this.$refs["create-whatsapp-opt-out-modal"].show();
    },
    mapCompId(value) {
      this.comp_id = value.id;
    },
    hideModel() {
      this.$refs["create-whatsapp-opt-out-modal"].hide();
    },

    handleOpenComFL() {
      if (!this.isFLListLoaded) {
        this.loadLimitedFreelancer();
        // this.freelancerListCreateWhitelistAdmin();
      }
    },

    handleSearchChangeFL(searchDataFL) {
      this.searchDataFL = searchDataFL;
      if (searchDataFL) {
        this.searchFreelancer(searchDataFL);
      } else {
        this.loadLimitedFreelancer();
      }
    },

    loadLimitedFreelancer() {
      this.$store
        .dispatch(POST_API, {
          data: { limit: 20, is_whitelist: this.is_whitelist },
          api: "/api/freelancer-list-create-whitelist-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.createWhatsappOptOutFLList = this.$store.getters.getResults.freelancers;
            this.isFLListLoaded = true;
          }
        });
    },

    searchFreelancer(searchDataFL) {
      this.$store
        .dispatch(POST_API, {
          data: { keyword: searchDataFL },
          api: "/api/freelancer-list-create-whitelist-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.createWhatsappOptOutFLList = this.$store.getters.getResults.freelancers;
          }
        });
    },

    permissionStaff() {
      if (this.$store.getters.getCurrentUserPermission) {
        if (
          this.$store.getters.getCurrentUserPermission.data.length > 0 &&
          this.$store.getters.currentUser.user_type_id == 2
        ) {
          var menu = this.$store.getters.getCurrentUserPermission.data;
          this.actions = permission.getPermissionStaff(menu, "Opt Out Whatsapp Blast");
          this.actionsFreelancer = permission.getPermissionStaff(menu, "Jobseekers");
        }
      }
    },
    showFreelancer(action) {
      if (this.$store.getters.currentUser.user_type_id == 2) {
        return this.actionsFreelancer.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
    show(action) {
      if (this.$store.getters.currentUser.user_type_id == 2) {
        return this.actions.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
  },
  mounted() {
    $(".show_filter_arrow .table thead th div").contents().unwrap();
    $(".dashboard-content-container .simplebar-scroll-content").animate(
      {
        scrollTop: 0,
      },
      "fast"
    );
    this.form.user_id =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : "";
    this.designation =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.designation : "";

    this.permissionStaff();
    //document.title = "EL Connect - Admin - Website Contents - Job Posters";
  },
};
</script>
