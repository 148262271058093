<template>
  <div>
    <div>
      <div class="dashboard-content-inner my_custom_dash admin_extra_padding">
        <!-- Dashboard Headline -->
        <div class="dashboard-headline heading_with_btn manage_job_mobile_hide">
          <h1>Manage Job Ads</h1>
          <b-link
            v-if="show('export job')"
            @click="exportJobs(activeTab)"
            class="mobile_margin_bottom_10 common_width_100_min_width_100_button mr-3 common_hover_effect_white_bg  common_z_index_zero btn button ripple-effect transaction_add_btn_admin_e"
          >
            Export
          </b-link>
          <b-link
            class="mobile_margin_bottom_10 common_width_100_min_width_100_button mr-3 common_hover_effect_white_bg  common_z_index_zero btn button ripple-effect transaction_add_btn_admin_e"
            :to="{ name: 'fullTimePostJob' }"
            v-if="show('create job')"
            >Post Job</b-link
          >
          <b-link
            v-if="show('copy URL with keyboard')"
            @click="openShareJobModalKeyword()"
            class="mrgn_left_zero_mobile btn green-border-btn text-white"
          >
            Copy URL With Keyword
          </b-link>

          <!-- Breadcrumbs -->
          <nav id="breadcrumbs" class="dark employer_nav">
            <ul>
              <li><a href="#">Home</a></li>
              <li><a href="#">Job</a></li>
              <li>Manage Job Ads</li>
            </ul>
          </nav>
        </div>
        <div class="dashboard-headline heading_with_btn manage_job_desktop_hide">
          <h1>Manage Job Ads</h1>
          <b-link
            class="mobile_margin_bottom_10 common_width_100_min_width_100_button desktop_margin_left_zero mr-3 common_hover_effect_white_bg  common_z_index_zero btn button ripple-effect transaction_add_btn_admin_e"
            :to="{ name: 'fullTimePostJob' }"
            v-if="show('create job')"
            >Post Job</b-link
          >
          <div class="url_with_export">
            <b-link
              v-if="show('copy URL with keyboard')"
              @click="openShareJobModalKeyword()"
              class="ml-0 mrgn_left_zero_mobile btn green-border-btn text-white"
            >
              Copy URL With Keyword
            </b-link>
            <b-link
              v-if="show('export job')"
              @click="exportJobs(activeTab)"
              class="common_width_100_min_width_100_button desktop_margin_left_zero common_hover_effect_white_bg  common_z_index_zero button ripple-effect transaction_add_btn_admin_e"
            >
              Export
            </b-link>
          </div>
        </div>
        <!-- Row -->

        <div class="row">
          <!-- Dashboard Box -->
          <div class="col-xl-12">
            <div class="dashboard-box margin-top-0">
              <!-- Headline -->
              <div class="headline">
                <h3><i class="icon-material-outline-business-center"></i> Job Ads</h3>
              </div>
              <!-- <div class="col-xl-12"> -->
              <div class="container new_cnt_pdng my_new_container">
                <div class="row desktop_search_top_div">
                  <div class="col-xl-12">
                    <div class="row">
                      <!-- <div class="upper_mix_functiobality"> -->
                      <!-- <div class="col-xl-4 col-md-4 mobile_max_width_and_flex mobile_paddding_unset search_div_daily_job new_search_manage_job"> -->
                      <div
                        class="big_screen_search_filter col-xl-3 col-md-3 search_common_margin_bottom_28 submit-field mobile_tab_max_width_flex mobile_max_width_and_flex new_search_div_daily_job new_search_manage_job"
                      >
                        <label class="desktop_margin_bottom_zero hided_label">Category:</label>
                        <div class="search-box custom_search_admin new_custom_search_admin_remove">
                          <div role="group" class="input-group">
                            <!---->
                            <div class="input-group-prepend">
                              <input
                                id="searchBtn"
                                type="text"
                                placeholder="Search by keyword"
                                v-model="filterSearch"
                                v-on:keyup.enter="changeTabStatus(activeTab)"
                                class="form-control"
                              />
                              <b-button class="btn points_events"
                                ><i class="fa fa-search" @click="changeTabStatus(activeTab)"></i
                              ></b-button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-xl-3 col-md-3 search_common_margin_bottom_28 mobile_tab_max_width_flex"
                      >
                        <label class="desktop_margin_bottom_zero">Company:</label>
                        <!-- <b-form-select v-model="company_id_filter" id="jbs_category" class="jbs_category common_height_38" @change="changeTabStatus(activeTab)">
                                        <option value="">All</option>
                                        <option :value="employer.id" v-for="employer in employers">{{employer.last_name |capitalize}} {{employer.first_name |capitalize}}</option>
                                    </b-form-select> -->
                        <b-form-group id="input-group-8" class="desktop_margin_bottom_zero">
                          <multiselect
                            id="ajax"
                            v-model="company_id_filter"
                            :custom-label="customLabel"
                            tag-placeholder="Search Company"
                            placeholder="All"
                            :options="employers"
                            label="full_name"
                            track-by="company_id"
                            @input="changeTabStatus(activeTab)"
                            :multiple="false"
                            :searchable="true"
                            :clear-on-select="true"
                            :close-on-select="true"
                            :allow-empty="false"
                            deselect-label="Can't remove this value"
                            open-direction="bottom"
                            class="job_list_multi_select model_multi_select admin_multi_select"
                            ><span slot="noResult">No Employer Found.</span></multiselect
                          >
                        </b-form-group>
                      </div>
                      <!-- <div class="col-xl-2 col-md-2 search_common_margin_bottom_28 mobile_tab_max_width_flex  ">
                                     <label class="desktop_margin_bottom_zero">Services:</label>
                                    <b-form-select v-model="category_id_filter" id="jbs_category" class="jbs_category common_height_38" @change="changeTabStatus(activeTab)">
                                        <option value="">All</option>
                                        <option :value="category.id" v-for="category in categories">{{category.name |capitalize}}</option>
                                    </b-form-select>
                                </div> -->
                      <div
                        class="col-xl-3 col-md-3 search_common_margin_bottom_28 mobile_tab_max_width_flex"
                      >
                        <label class="desktop_margin_bottom_zero">Services:</label>
                        <b-form-group
                          id="jbs_category"
                          class="jbs_category desktop_margin_bottom_zero"
                        >
                          <multiselect
                            id="ajax"
                            @input="changeTabStatus(activeTab)"
                            v-model="category_id_filter"
                            tag-placeholder="Search Category"
                            placeholder="Search Category"
                            :options="categories"
                            label="name"
                            track-by="id"
                            :multiple="true"
                            :searchable="true"
                            :clear-on-select="true"
                            :close-on-select="true"
                            :allow-empty="true"
                            :show-labels="false"
                            class="job_list_multi_select model_multi_select admin_multi_select new_multi_admin"
                            ><span slot="noResult">No Category Found.</span></multiselect
                          >
                        </b-form-group>
                      </div>
                      <!-- <div class="col-md-3 dropdown_daily_job">
                                    <div class="label_div text_align_end_desktop">
                                        <span>Company:</span>
                                    </div>
                                    <div class="input_div select_dropdown">
                                    <select name="cars" id="cars">
                                        <option value="All">All</option>
                                        <option value="saab">Saab</option>
                                        <option value="mercedes">Mercedes</option>
                                        <option value="audi">Audi</option>
                                        </select>
                                    </div>
                                </div> -->

                      <div
                        class="col-xl-3 col-md-3 calneder_open_is search_common_margin_bottom_28 submit-field mobile_tab_max_width_flex mobile_max_width_and_flex"
                      >
                        <label class="desktop_margin_bottom_zero">Date Range:</label>
                        <div class="reset_btn_full_flex mobile_tab_display_block">
                          <div
                            class="calender_div_job_in_job calneder_custom_icon width_100_per calender_border"
                          >
                            <!-- <label class="desktop_margin_bottom_zero">Date Range:</label> -->
                            <!-- <div class="input_div"> -->
                            <b-form-group id="input-group-6" class="desktop_margin_bottom_zero">
                              <date-range-picker
                                ref="picker"
                                :minDate="minDate"
                                :opens="center"
                                :locale-data="locale"
                                :auto-apply="auto"
                                v-model="dateRange"
                                :ranges="range"
                                @update="changeTabStatus(activeTab)"
                                class="manage_job_date_claneder common_date_range_picker_new"
                              >
                                <div slot="input" slot-scope="picker">
                                  {{ picker.startDate | datePicker1 }} -
                                  {{ picker.endDate | datePicker1 }}
                                </div>
                              </date-range-picker>
                              <span class="input-icon admin_input_icon web_common_input_icon">
                                <b-img
                                  v-bind:src="webUrl + 'assets/images/calendar-grey-icon02.svg'"
                                  alt="Calander Icon"
                                ></b-img>
                              </span>
                            </b-form-group>
                            <!-- </div> -->
                            <!-- <div class="input_div">
                                                <input type="date" id="birthday" name="birthday">
                                            </div> -->
                          </div>
                          <div class="reset_btn_top reset_btn_mrgn common_reset_btn">
                            <button @click="clear">RESET</button>
                          </div>
                        </div>
                      </div>
                      <!-- </div> -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- </div> -->
              <!-- here put a spinner or whatever you want to indicate that a request is in progress -->
              <div class="tab-section">
                <b-tabs
                  class="my_tab_job remove_bx admin_full_time_job common_border_top_right_left_radius_unset daily_job_mange_div desktop_margin_top_zero"
                >
                  <b-tab
                    :title="'IN PROGRESS (' + inprogressCount + ')'"
                    :active="inprogress"
                    @click="changeTabStatus('inprogress')"
                    class="reject_last_li"
                  >
                    <div class="content" v-if="jobData.length > 0">
                      <ul class="dashboard-box-list">
                        <li v-for="jobDatas in jobData" class="admin_featured_full">
                          <div class="listing_features_new">
                            <h5 v-if="jobDatas.featured == 'yes'"><b>Feature Ad</b></h5>
                          </div>
                          <!-- Job Listing -->
                          <div class="job-listing">
                            <!-- Job Listing Details -->
                            <div class="job-listing-details">
                              <!-- Logo -->
                              <!-- 											<a href="#" class="job-listing-company-logo">
                                                            <img src="images/company-logo-05.png" alt="">
                                                        </a> -->

                              <!-- Details -->
                              <div class="job-listing-description">
                                <!-- <span class="job-listing-title" v-if="jobDatas.title.length >= 25"><b-link :to="{name: 'fullTimeJobDetail', params: {id: encrypt(jobDatas.jobId)}}">{{jobDatas.title.substring(0,25)+'...' | capitalize}}</b-link></span> -->
                                <!-- <span class="job-listing-title" v-else><b-link :to="{name: 'fullTimeJobDetail', params: {id: encrypt(jobDatas.jobId)}}">{{jobDatas.title | capitalize}}</b-link></span> -->
                                <span class="job-listing-title"
                                  ><b>{{ jobDatas.title | capitalize }}</b></span
                                >
                                <h3
                                  class="job-listing-title new_all_job_title mobile_margin_top_15"
                                  v-if="jobDatas.description"
                                  style="margin-bottom: 15px; margin-left: 20px"
                                ></h3>
                                <!-- Job Listing Footer -->
                                <div class="job-listing-footer manage_job_li_nw">
                                  <ul class="fullTimeJobdata new_full_time_data">
                                    <li>
                                      <span v-if="jobDatas.company_name != null">
                                        <i class="icon-material-outline-business"></i
                                        ><b> {{ jobDatas.company_name | capitalize }}</b></span
                                      >
                                    </li>
                                  </ul>
                                  <ul class="fullTimeJobdata new_full_time_data">
                                    <li>
                                      <span v-if="jobDatas.start_date != null"
                                        ><i class="icon-material-outline-access-time"></i>
                                        {{ jobDatas.start_date | customFormatterOne }} </span
                                      ><span v-if="jobDatas.end_date != null">
                                        - {{ jobDatas.end_date | customFormatterOne }}</span
                                      >
                                      <!-- <br v-if="jobDatas.end_date != null"> -->
                                    </li>
                                  </ul>

                                  <ul class="fullTimeJobdata new_full_time_data">
                                    <li>
                                      <span v-if="jobDatas.service_categories_name"
                                        ><i class="icon-material-outline-business"></i>
                                        <span>{{
                                          jobDatas.service_categories_name == "Other"
                                            ? jobDatas.other_service_category
                                            : jobDatas.service_categories_name | removeUnderscore
                                        }}</span></span
                                      >
                                    </li>
                                    <li class="mobile_jb_hide">
                                      <span v-if="jobDatas.job_type"
                                        ><i class="icon-material-outline-business-center"></i>
                                        <span>{{
                                          jobDatas.job_type | customizeRoleType | capitalize
                                        }}</span></span
                                      >
                                    </li>
                                    <li class="mobile_jb_hide">
                                      <span v-if="jobDatas.level_title"
                                        ><i class="icon-material-outline-face"></i>
                                        {{ jobDatas.level_title | capitalize }}</span
                                      >
                                    </li>
                                  </ul>
                                  <ul class="fullTimeJobdata new_full_time_data">
                                    <li v-if="jobDatas.actual_posted_by_first_name">
                                      <span>
                                        <i class="icon-material-outline-date-range"></i>Posted By:
                                        {{ jobDatas.actual_posted_by_last_name | capitalize }}
                                        {{
                                          jobDatas.actual_posted_by_first_name | capitalize
                                        }}</span
                                      >
                                    </li>

                                    <li>
                                      <span v-if="jobDatas.posted_date">
                                        <i class="icon-material-outline-date-range"></i>Posted On:
                                        {{ jobDatas.posted_date | customFormatterposted }}</span
                                      >
                                    </li>

                                    <li>
                                      <span v-if="jobDatas.job_address"
                                        ><i class="icon-material-outline-location-on"></i>
                                        <span>{{
                                          jobDatas.job_address == "Other"
                                            ? jobDatas.other_location
                                            : jobDatas.job_address | capitalize
                                        }}</span></span
                                      >
                                    </li>
                                    <li>
                                      <span v-if="jobDatas.job_unique_id"
                                        ><i class="material-icons-outlined job_id_icon"
                                          >card_travel_outlined</i
                                        >Ads ID:
                                        <span>{{ jobDatas.job_unique_id | capitalize }}</span></span
                                      >
                                    </li>
                                    <li>
                                      <span
                                        v-if="
                                          (jobDatas.min_salary != 0 && jobDatas.max_salary != 0) ||
                                          jobDatas.min_salary > 0 ||
                                          jobDatas.max_salary > 0
                                        "
                                      >
                                        <i class="icon-material-outline-local-atm"></i
                                        ><span v-if="jobDatas.job_type == 'part-time'"
                                          >Per Hour Rate</span
                                        >
                                        <span v-else></span> ${{ jobDatas.min_salary }}-${{
                                          jobDatas.max_salary
                                        }}</span
                                      >
                                    </li>
                                  </ul>
                                  <!-- <ul class="fullTimeJobdata">
                                                                    <li>
                                                                    <span v-if="jobDatas.company_name != null"> <i class="icon-material-outline-business"></i><b> {{jobDatas.company_name | capitalize}}</b></span><br v-if="jobDatas.company_name != null"><br>
                                                                    <span v-if="jobDatas.start_date != null"> <i class="icon-material-outline-date-range"></i>Start Date {{jobDatas.start_date | customFormatterOne}}</span><br v-if="jobDatas.start_date != null">
                                                                    <span v-if="jobDatas.end_date != null"> <i class="icon-material-outline-date-range"></i>End Date {{jobDatas.end_date | customFormatterOne}}</span><br v-if="jobDatas.end_date != null">
                                                                    <span> <i class="icon-material-outline-date-range"></i>Posted on {{jobDatas.posted_date | customFormatterposted}}</span>

                                                                    </li>
                                                                </ul> -->
                                  <!-- <ul>
                                                                    <li><i class="icon-material-outline-date-range"></i> Posted on {{jobDatas.posted_date | customFormatterOne}}</li>
                                                                </ul> -->

                                  <div
                                    class="all_job_list_home featured_job_section_lists fulltimefeature"
                                  >
                                    <!-- <span v-if="jobDatas.featured == 'yes'"><b>Featured Ad</b></span> -->
                                    <!-- <h5 v-if="jobDatas.featured == 'yes'"><b>Featured Ad</b></h5> -->
                                    <!--<h5 v-else class="red_bg"><b>No Featured Ad</b></h5>-->
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <!-- Buttons -->
                          <div
                            class="buttons-to-right always-visible upcoming_job_btnz common_flex_wrap job_view_count_inline"
                            v-if="
                              show('view job Detail') ||
                              showViewCandidates('view') ||
                              show('edit job') ||
                              show('duplicate job') ||
                              show('remove job') ||
                              show('activate/inactivate') ||
                              show('feature job')
                            "
                          >
                            <div
                              class="manage_candidate_in_admin job_view_count_inline_div"
                              v-if="show('view job Detail')"
                            >
                              <!-- <a href="javascript:void(0);" @click="jobDetail(encrypt(jobDatas.jobId),$event)" class="button ripple-effect"><i aria-hidden="true" class="fa fa-eye"></i>&nbsp;&nbsp;View Detail </a> -->
                              <a
                                :href="'/v2/admin/fulltime/job-detail/' + encrypt(jobDatas.jobId)"
                                target="_blank"
                                class="button ripple-effect"
                                ><i aria-hidden="true" class="fa fa-eye"></i>&nbsp;&nbsp;View Detail
                              </a>
                            </div>
                            <div
                              class="manage_candidate_in_admin job_view_count_inline_div"
                              v-if="showViewCandidates('view')"
                            >
                              <!-- <a href="javascript:void(0);" @click="viewCandidates(encrypt(jobDatas.jobId))" class="button ripple-effect"><i class="icon-material-outline-supervisor-account"></i> View Candidates <span class="button-info">{{jobDatas.total_applicants}}</span></a> -->
                              <a
                                :href="
                                  '/v2/admin-fulltimejob-viewcandidates/' + encrypt(jobDatas.jobId)
                                "
                                target="_blank"
                                class="button ripple-effect"
                                ><i class="icon-material-outline-supervisor-account"></i> View
                                Candidates
                                <span class="button-info">{{ jobDatas.total_applicants }}</span></a
                              >
                            </div>
                            <div
                              class="other_operation_admin_full_time new_other_op job_view_count_inline_div"
                              v-if="
                                show('edit job') ||
                                show('duplicate job') ||
                                show('remove job') ||
                                show('activate/inactivate') ||
                                show('feature job')
                              "
                            >
                              <b-link
                                v-if="show('edit job')"
                                href="javascript:void(0);"
                                :to="{
                                  name: 'fullTimeEditJob',
                                  params: { id: encrypt(jobDatas.jobId) },
                                }"
                                class="button gray ripple-effect ico edit-btn-job-list"
                                data-tippy-placement="top"
                                ><i class="material-icons-outlined common_overflow_unset"
                                  >border_color_outlined</i
                                >Edit</b-link
                              >
                              <b-link
                                v-if="show('duplicate job')"
                                href="javascript:void(0);"
                                :to="{
                                  name: 'fullTimeRepostJob',
                                  params: { id: encrypt(jobDatas.jobId) },
                                }"
                                class="button gray ripple-effect ico repost-btn-job-list"
                                title="Repost"
                                data-tippy-placement="top"
                                ><i class="material-icons-outlined common_overflow_unset"
                                  >repeat_outlined</i
                                >Duplicate</b-link
                              >
                              <a
                                v-if="show('remove job')"
                                href="javascript:void(0);"
                                @click="deleteJob(jobDatas.jobId)"
                                class="button gray ripple-effect ico remove-btn-job-list"
                                data-tippy-placement="top"
                                ><i class="icon-feather-trash-2"></i>Remove</a
                              >
                              <a
                                @click="
                                  changeStatus(
                                    jobDatas.jobId,
                                    jobDatas.job_status_val,
                                    jobDatas.title,
                                    jobDatas.end_date
                                  )
                                "
                                id="show_hide_status"
                                v-if="
                                  jobDatas.job_status_val == 'active' && show('activate/inactivate')
                                "
                                class="button gray ripple-effect ico non-active-btn-job-list"
                              >
                                <i class="fa fa-eye-slash" aria-hidden="true"></i>Inactivate
                              </a>
                              <a
                                @click="
                                  changeStatus(
                                    jobDatas.jobId,
                                    jobDatas.job_status_val,
                                    jobDatas.title,
                                    jobDatas.end_date
                                  )
                                "
                                id="show_hide_status"
                                v-else-if="
                                  jobDatas.job_status_val == 'inactive' &&
                                  show('activate/inactivate')
                                "
                                class="button gray ripple-effect ico active-btn-job-list"
                              >
                                <i class="fa fa-eye" aria-hidden="true"></i>Activate
                              </a>
                              <a
                                @click="featureJob(jobDatas.jobId, jobDatas.company_id)"
                                id="show_hide_status"
                                v-if="jobDatas.featured == 'no' && show('feature job')"
                                class="button gray ripple-effect ico feature-btn-job-list"
                              >
                                <i class="gray ripple-effect ico" aria-hidden="true"></i>Featured ad
                              </a>
                            </div>
                            <div
                              class="fa fa-eye view_count_job view_count_job_admin job_view_count_inline_div"
                              v-if="jobDatas.view_count"
                            >
                              <span>{{ jobDatas.view_count | commaSeperator }}</span>
                            </div>
                            <!-- <a @click="featureJob(jobDatas.jobId,jobDatas.company_id)" v-if="jobDatas.featured == 'no'" class="button gray ripple-effect ico">
                                                    </a> -->
                            <!-- <b-link href="" @click="featureJob(jobDatas.jobId,jobDatas.company_id)" v-if="jobDatas.featured == 'no'" class="button gray ripple-effect ico" title="Feature job" data-tippy-placement="top"><i class="material-icons-outlined">new_releases</i></b-link> -->

                            <!-- <a href="#" @click ="changeStatus(jobDatas.jobId, jobDatas.job_status_val)" v-if="jobDatas.job_status_val == 'active'">Inactivate</a> -->
                            <!-- <a href="#" v-else-if="jobDatas.job_status_val == 'inactive'" @click ="changeStatus(jobDatas.jobId, jobDatas.job_status_val)">Activate</a> -->
                            <!-- <b-link href=""  v-if="jobDatas.featured == 'no'" class="button gray ripple-effect ico" title="Feature job" data-tippy-placement="top" @click="$bvModal.show('my_manage_job_model')"><i class="material-icons-outlined">new_releases</i></b-link> -->
                            <!-- <b-link href=""  v-if="jobDatas.featured == 'no'" class="button gray ripple-effect ico" title="Feature job" data-tippy-placement="top" @click="onClickFeature(jobDatas.jobId,jobDatas.company_id)"><i class="material-icons-outlined">new_releases</i></b-link>
                                                        <div class="">
                                                            <b-modal id="my_manage_job_model" ref="my_manage_job_model" title="BootstrapVue" >
                                                                <h2>Feature Job</h2>
                                                                <p>Please select the featured job package</p>
                                                                <div class="custom_select_manage_jobz">
                                                                    <b-form-select v-model="selected" :options="options"></b-form-select>
                                                                    <b-form-select v-model="ft_feature_job" id="package" class="package" @change="onChangeFeature()">
                                                                        <option value="" disabled>Select featured job package</option>
                                                                        <option value="ft_1_day_free_featured_jobs">1 days - Free Featured - ({{ft_1_day_free_featured_jobs}} Remaining)</option>
                                                                        <option value="ft_7_day_free_featured_jobs">7 days - Free Featured - ({{ft_7_day_free_featured_jobs}} Remaining)</option>
                                                                        <option value="ft_14_day_free_featured_jobs">14 days - Free Featured - ({{ft_14_day_free_featured_jobs}} Remaining)</option>
                                                                        <option value="ft_30_day_free_featured_jobs">30 days - Free Featured - ({{ft_30_day_free_featured_jobs}} Remaining)</option>
                                                                        <option value="coins">7 days - 800 EL Coins</option>
                                                                    </b-form-select>
                                                                </div>
                                                                <h3 class="coin_msg" style="display:none">You will have <b-img :src="webUrl+'assets/images/Icons_Coin.png'" class="coins_manage_job_img"></b-img> {{remaining_coins}} EL Coins left after purchasing.</h3>
                                                                <p class="red_p">Note: Action cannot be undo once confirmed.</p>
                                                                <div class="model_footer_btns">
                                                                    <b-button  block @click="$bvModal.hide('my_manage_job_model')">Cancel</b-button>
                                                                    <button class="blue_btn" @click="submitFeatured()">Confirm Feature</button>
                                                                </div>
                                                            </b-modal>
                                                        </div> -->
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div class="content text-center" v-else>
                      <p class="margin_top_1rem"><b>No Record Found</b></p>
                    </div>
                  </b-tab>
                  <b-tab
                    :title="'UPCOMING (' + upcomingCount + ')'"
                    :active="upcoming"
                    @click="changeTabStatus('upcoming')"
                    class="reject_last_li"
                  >
                    <div class="content" v-if="jobData.length > 0">
                      <ul class="dashboard-box-list">
                        <li v-for="jobDatas in jobData" class="admin_featured_full">
                          <div class="listing_features_new">
                            <h5 v-if="jobDatas.featured == 'yes'"><b>Featured Ad</b></h5>
                          </div>
                          <!-- Job Listing -->
                          <div class="job-listing">
                            <!-- Job Listing Details -->
                            <div class="job-listing-details">
                              <!-- Logo -->
                              <!-- 											<a href="#" class="job-listing-company-logo">
                                                            <img src="images/company-logo-05.png" alt="">
                                                        </a> -->

                              <!-- Details -->
                              <div class="job-listing-description">
                                <!-- <span class="job-listing-title" v-if="jobDatas.title.length >= 25"><b-link :to="{name: 'fullTimeJobDetail', params: {id: encrypt(jobDatas.jobId)}}">{{jobDatas.title.substring(0,25)+'...' | capitalize}}</b-link></span> -->
                                <!-- <span class="job-listing-title" v-else><b-link :to="{name: 'fullTimeJobDetail', params: {id: encrypt(jobDatas.jobId)}}">{{jobDatas.title | capitalize}}</b-link></span> -->
                                <span class="job-listing-title"
                                  ><b>{{ jobDatas.title | capitalize }}</b></span
                                >
                                <h3
                                  class="job-listing-title new_all_job_title mobile_margin_top_15"
                                  v-if="jobDatas.description"
                                  style="margin-bottom: 15px; margin-left: 20px"
                                ></h3>
                                <!-- Job Listing Footer -->
                                <div class="job-listing-footer manage_job_li_nw">
                                  <ul class="fullTimeJobdata new_full_time_data">
                                    <li>
                                      <span v-if="jobDatas.company_name != null">
                                        <i class="icon-material-outline-business"></i
                                        ><b> {{ jobDatas.company_name | capitalize }}</b></span
                                      >
                                    </li>
                                  </ul>
                                  <ul class="fullTimeJobdata new_full_time_data">
                                    <li>
                                      <span v-if="jobDatas.start_date != null"
                                        ><i class="icon-material-outline-access-time"></i>
                                        {{ jobDatas.start_date | customFormatterOne }} </span
                                      ><span v-if="jobDatas.end_date != null">
                                        - {{ jobDatas.end_date | customFormatterOne }}</span
                                      >
                                      <!-- <br v-if="jobDatas.end_date != null"> -->
                                    </li>
                                  </ul>

                                  <ul class="fullTimeJobdata new_full_time_data">
                                    <li>
                                      <span v-if="jobDatas.service_categories_name"
                                        ><i class="icon-material-outline-business"></i>
                                        <span>{{
                                          jobDatas.service_categories_name == "Other"
                                            ? jobDatas.other_service_category
                                            : jobDatas.service_categories_name | removeUnderscore
                                        }}</span></span
                                      >
                                    </li>
                                    <li class="mobile_jb_hide">
                                      <span v-if="jobDatas.job_type"
                                        ><i class="icon-material-outline-business-center"></i>
                                        <span>{{
                                          jobDatas.job_type | customizeRoleType | capitalize
                                        }}</span></span
                                      >
                                    </li>
                                    <li class="mobile_jb_hide">
                                      <span v-if="jobDatas.level_title"
                                        ><i class="icon-material-outline-face"></i>
                                        {{ jobDatas.level_title | capitalize }}</span
                                      >
                                    </li>
                                  </ul>
                                  <ul class="fullTimeJobdata new_full_time_data">
                                    <li v-if="jobDatas.actual_posted_by_first_name">
                                      <span>
                                        <i class="icon-material-outline-date-range"></i>Posted By:
                                        {{ jobDatas.actual_posted_by_last_name | capitalize }}
                                        {{
                                          jobDatas.actual_posted_by_first_name | capitalize
                                        }}</span
                                      >
                                    </li>

                                    <li>
                                      <span v-if="jobDatas.posted_date">
                                        <i class="icon-material-outline-date-range"></i>Posted on:
                                        {{ jobDatas.posted_date | customFormatterposted }}</span
                                      >
                                    </li>

                                    <li>
                                      <span v-if="jobDatas.job_address"
                                        ><i class="icon-material-outline-location-on"></i>
                                        <span>{{
                                          jobDatas.job_address == "Other"
                                            ? jobDatas.other_location
                                            : jobDatas.job_address | capitalize
                                        }}</span></span
                                      >
                                    </li>
                                    <li>
                                      <span v-if="jobDatas.job_unique_id"
                                        ><i class="material-icons-outlined job_id_icon"
                                          >card_travel_outlined</i
                                        >Ads ID:
                                        <span>{{ jobDatas.job_unique_id | capitalize }}</span></span
                                      >
                                    </li>
                                    <li>
                                      <span
                                        v-if="
                                          (jobDatas.min_salary != 0 && jobDatas.max_salary != 0) ||
                                          jobDatas.min_salary > 0 ||
                                          jobDatas.max_salary > 0
                                        "
                                      >
                                        <i class="icon-material-outline-local-atm"></i
                                        ><span v-if="jobDatas.job_type == 'part-time'"
                                          >Per Hour Rate</span
                                        >
                                        <span v-else></span> ${{ jobDatas.min_salary }}-${{
                                          jobDatas.max_salary
                                        }}</span
                                      >
                                    </li>
                                  </ul>
                                  <!-- <ul class="fullTimeJobdata">
                                                                    <li>
                                                                    <span v-if="jobDatas.company_name != null"> <i class="icon-material-outline-business"></i><b> {{jobDatas.company_name | capitalize}}</b></span><br v-if="jobDatas.company_name != null"><br>

                                                                    <span v-if="jobDatas.start_date != null"> <i class="icon-material-outline-date-range"></i>Start Date {{jobDatas.start_date | customFormatterOne}}</span><br v-if="jobDatas.start_date != null">
                                                                    <span v-if="jobDatas.end_date != null"> <i class="icon-material-outline-date-range"></i>End Date {{jobDatas.end_date | customFormatterOne}}</span><br v-if="jobDatas.end_date != null">
                                                                    <span> <i class="icon-material-outline-date-range"></i>Posted on {{jobDatas.posted_date | customFormatterOne}}</span>

                                                                    </li>
                                                                </ul> -->

                                  <div
                                    class="all_job_list_home featured_job_section_lists fulltimefeature"
                                  >
                                    <!-- <h5 v-if="jobDatas.featured == 'yes'"><b>Featured Ad</b></h5> -->
                                    <!--<h5 v-else class="red_bg"><b>No Featured Ad</b></h5>-->
                                  </div>
                                  <!-- <ul>
                                                                    <li><i class="icon-material-outline-date-range"></i> Posted on {{jobDatas.posted_date | customFormatterOne}}</li>
                                                                </ul> -->
                                </div>
                              </div>
                            </div>
                          </div>

                          <!-- Buttons -->
                          <div
                            class="buttons-to-right always-visible upcoming_job_btnz common_flex_wrap job_view_count_inline"
                            v-if="
                              show('view job Detail') ||
                              show('edit job') ||
                              show('duplicate job') ||
                              show('remove job')
                            "
                          >
                            <div
                              class="manage_candidate_in_admin job_view_count_inline_div"
                              v-if="show('view job Detail')"
                            >
                              <!-- <a href="javascript:void(0);" @click="jobDetail(encrypt(jobDatas.jobId),$event)" class="button ripple-effect"><i aria-hidden="true" class="fa fa-eye"></i>&nbsp;&nbsp;View Detail </a> -->
                              <a
                                :href="'/v2/admin/fulltime/job-detail/' + encrypt(jobDatas.jobId)"
                                target="_blank"
                                class="button ripple-effect"
                                ><i aria-hidden="true" class="fa fa-eye"></i>&nbsp;&nbsp;View Detail
                              </a>
                            </div>
                            <!-- <a href="#" @click="viewCandidates(encrypt(jobDatas.jobId))" class="button ripple-effect"><i class="icon-material-outline-supervisor-account"></i> View Candidates <span class="button-info">{{jobDatas.total_applicants}}</span></a> -->
                            <div
                              class="other_operation_admin_full_time new_other_op job_view_count_inline_div"
                              v-if="show('edit job') || show('duplicate job') || show('remove job')"
                            >
                              <b-link
                                v-if="show('edit job')"
                                href="javascript:void(0);"
                                :to="{
                                  name: 'fullTimeEditJob',
                                  params: { id: encrypt(jobDatas.jobId) },
                                }"
                                class="button gray ripple-effect ico edit-btn-job-list"
                                data-tippy-placement="top"
                                ><i class="material-icons-outlined common_overflow_unset"
                                  >border_color_outlined</i
                                >Edit</b-link
                              >
                              <b-link
                                v-if="show('duplicate job')"
                                href="javascript:void(0);"
                                :to="{
                                  name: 'fullTimeRepostJob',
                                  params: { id: encrypt(jobDatas.jobId) },
                                }"
                                class="button gray ripple-effect ico repost-btn-job-list"
                                data-tippy-placement="top"
                                ><i class="material-icons-outlined common_overflow_unset"
                                  >repeat_outlined</i
                                >Duplicate</b-link
                              >
                              <a
                                v-if="show('remove job')"
                                href="javascript:void(0);"
                                @click="deleteJob(jobDatas.jobId)"
                                class="button gray ripple-effect ico remove-btn-job-list"
                                data-tippy-placement="top"
                                ><i class="icon-feather-trash-2"></i>Remove</a
                              >
                            </div>
                            <div
                              class="fa fa-eye view_count_job job_view_count_inline_div"
                              v-if="jobDatas.view_count"
                            >
                              <span>{{ jobDatas.view_count | commaSeperator }}</span>
                            </div>
                            <!-- <a @click="changeStatus(jobDatas.jobId, jobDatas.job_status_val)" id="show_hide_status" v-if="jobDatas.job_status_val == 'active'" class="button gray ripple-effect ico">
                                                    <i class="fa fa-eye-slash" aria-hidden="true"></i>
                                                    </a>
                                                    <a @click="changeStatus(jobDatas.jobId, jobDatas.job_status_val)" id="show_hide_status" v-else-if="jobDatas.job_status_val == 'inactive'" class="button gray ripple-effect ico">
                                                    <i class="fa fa-eye" aria-hidden="true"></i>
                                                    </a> -->
                            <!-- <a href="#" @click ="changeStatus(jobDatas.jobId, jobDatas.job_status_val)" v-if="jobDatas.job_status_val == 'active'">Inactivate</a> -->
                            <!-- <a href="#" v-else-if="jobDatas.job_status_val == 'inactive'" @click ="changeStatus(jobDatas.jobId, jobDatas.job_status_val)">Activate</a> -->
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div class="content text-center" v-else>
                      <p class="margin_top_1rem"><b>No Record Found</b></p>
                    </div>
                  </b-tab>
                  <b-tab
                    :title="'EXPIRED (' + expiredCount + ')'"
                    :active="expired"
                    @click="changeTabStatus('expired')"
                    class="reject_last_li"
                  >
                    <div class="content" v-if="jobData.length > 0">
                      <ul class="dashboard-box-list">
                        <li v-for="jobDatas in jobData" class="admin_featured_full">
                          <!-- Job Listing -->
                          <div class="listing_features_new">
                            <h5 v-if="jobDatas.featured == 'yes'"><b>Featured Ad</b></h5>
                          </div>
                          <div class="job-listing">
                            <!-- Job Listing Details -->
                            <div class="job-listing-details">
                              <!-- Logo -->
                              <!-- 											<a href="#" class="job-listing-company-logo">
                                                            <img src="images/company-logo-05.png" alt="">
                                                        </a> -->

                              <!-- Details -->
                              <div class="job-listing-description">
                                <!-- <span class="job-listing-title" v-if="jobDatas.title.length >= 25"><b-link :to="{name: 'fullTimeJobDetail', params: {id: encrypt(jobDatas.jobId)}}">{{jobDatas.title.substring(0,25)+'...' | capitalize}}</b-link></span>
                                                            <span class="job-listing-title" v-else><b-link :to="{name: 'fullTimeJobDetail', params: {id: encrypt(jobDatas.jobId)}}">{{jobDatas.title | capitalize}}</b-link></span> -->
                                <span class="job-listing-title"
                                  ><b>{{ jobDatas.title | capitalize }}</b></span
                                >
                                <h3
                                  class="job-listing-title new_all_job_title mobile_margin_top_15"
                                  v-if="jobDatas.description"
                                  style="margin-bottom: 15px; margin-left: 20px"
                                ></h3>
                                <!-- Job Listing Footer -->
                                <div class="job-listing-footer manage_job_li_nw">
                                  <ul class="fullTimeJobdata new_full_time_data">
                                    <li>
                                      <span v-if="jobDatas.company_name != null">
                                        <i class="icon-material-outline-business"></i
                                        ><b> {{ jobDatas.company_name | capitalize }}</b></span
                                      >
                                    </li>
                                  </ul>
                                  <ul class="fullTimeJobdata new_full_time_data">
                                    <li>
                                      <span v-if="jobDatas.start_date != null"
                                        ><i class="icon-material-outline-access-time"></i>
                                        {{ jobDatas.start_date | customFormatterOne }} </span
                                      ><span v-if="jobDatas.end_date != null">
                                        - {{ jobDatas.end_date | customFormatterOne }}</span
                                      >
                                      <!-- <br v-if="jobDatas.end_date != null"> -->
                                    </li>
                                  </ul>

                                  <ul class="fullTimeJobdata new_full_time_data">
                                    <li>
                                      <span v-if="jobDatas.service_categories_name"
                                        ><i class="icon-material-outline-business"></i>
                                        <span>{{
                                          jobDatas.service_categories_name == "Other"
                                            ? jobDatas.other_service_category
                                            : jobDatas.service_categories_name | removeUnderscore
                                        }}</span></span
                                      >
                                    </li>
                                    <li class="mobile_jb_hide">
                                      <span v-if="jobDatas.job_type"
                                        ><i class="icon-material-outline-business-center"></i>
                                        <span>{{
                                          jobDatas.job_type | customizeRoleType | capitalize
                                        }}</span></span
                                      >
                                    </li>
                                    <li class="mobile_jb_hide">
                                      <span v-if="jobDatas.level_title"
                                        ><i class="icon-material-outline-face"></i>
                                        {{ jobDatas.level_title | capitalize }}</span
                                      >
                                    </li>
                                  </ul>
                                  <ul class="fullTimeJobdata new_full_time_data">
                                    <li v-if="jobDatas.actual_posted_by_first_name">
                                      <span>
                                        <i class="icon-material-outline-date-range"></i>Posted By:
                                        {{ jobDatas.actual_posted_by_last_name | capitalize }}
                                        {{
                                          jobDatas.actual_posted_by_first_name | capitalize
                                        }}</span
                                      >
                                    </li>

                                    <li>
                                      <span v-if="jobDatas.posted_date">
                                        <i class="icon-material-outline-date-range"></i>Posted on:
                                        {{ jobDatas.posted_date | customFormatterposted }}</span
                                      >
                                    </li>

                                    <li>
                                      <span v-if="jobDatas.job_address"
                                        ><i class="icon-material-outline-location-on"></i>
                                        <span>{{
                                          jobDatas.job_address == "Other"
                                            ? jobDatas.other_location
                                            : jobDatas.job_address | capitalize
                                        }}</span></span
                                      >
                                    </li>
                                    <li>
                                      <span v-if="jobDatas.job_unique_id"
                                        ><i class="material-icons-outlined job_id_icon"
                                          >card_travel_outlined</i
                                        >Ads ID:
                                        <span>{{ jobDatas.job_unique_id | capitalize }}</span></span
                                      >
                                    </li>
                                    <li>
                                      <span
                                        v-if="
                                          (jobDatas.min_salary != 0 && jobDatas.max_salary != 0) ||
                                          jobDatas.min_salary > 0 ||
                                          jobDatas.max_salary > 0
                                        "
                                      >
                                        <i class="icon-material-outline-local-atm"></i
                                        ><span v-if="jobDatas.job_type == 'part-time'"
                                          >Per Hour Rate</span
                                        >
                                        <span v-else></span> ${{ jobDatas.min_salary }}-${{
                                          jobDatas.max_salary
                                        }}</span
                                      >
                                    </li>
                                  </ul>
                                  <!-- <ul class="fullTimeJobdata">
                                                                    <li>
                                                                    <span v-if="jobDatas.company_name != null"> <i class="icon-material-outline-business"></i><b> {{jobDatas.company_name | capitalize}}</b></span><br v-if="jobDatas.company_name != null"><br>
                                                                    <span v-if="jobDatas.start_date != null"> <i class="icon-material-outline-date-range"></i>Start Date {{jobDatas.start_date | customFormatterOne}}</span><br v-if="jobDatas.start_date != null">
                                                                    <span v-if="jobDatas.end_date != null"> <i class="icon-material-outline-date-range"></i>End Date {{jobDatas.end_date | customFormatterOne}}</span><br v-if="jobDatas.end_date != null">
                                                                    <span> <i class="icon-material-outline-date-range"></i>Posted on {{jobDatas.posted_date | customFormatterOne}}</span>

                                                                    </li>
                                                                </ul> -->
                                  <div
                                    class="all_job_list_home featured_job_section_lists fulltimefeature"
                                  >
                                    <!-- <h5 v-if="jobDatas.featured == 'yes'"><b>Featured Ad</b></h5> -->
                                    <!--<h5 v-else class="red_bg"><b>No Featured Ad</b></h5>-->
                                  </div>
                                  <!-- <ul>
                                                                    <li><i class="icon-material-outline-date-range"></i> Posted on {{jobDatas.posted_date | customFormatterOne}}</li>
                                                                </ul> -->
                                </div>
                              </div>
                            </div>
                          </div>

                          <!-- Buttons -->
                          <div
                            class="buttons-to-right always-visible upcoming_job_btnz common_flex_wrap job_view_count_inline"
                            v-if="
                              show('view job Detail') ||
                              showViewCandidates('view') ||
                              show('duplicate job') ||
                              show('remove job')
                            "
                          >
                            <div
                              class="manage_candidate_in_admin job_view_count_inline_div"
                              v-if="show('view job Detail')"
                            >
                              <!-- <a href="javascript:void(0);" @click="jobDetail(encrypt(jobDatas.jobId),$event)" class="button ripple-effect"><i aria-hidden="true" class="fa fa-eye"></i>&nbsp;&nbsp;View Detail </a> -->
                              <a
                                :href="'/v2/admin/fulltime/job-detail/' + encrypt(jobDatas.jobId)"
                                target="_blank"
                                class="button ripple-effect"
                                ><i aria-hidden="true" class="fa fa-eye"></i>&nbsp;&nbsp;View Detail
                              </a>
                            </div>
                            <div
                              class="manage_candidate_in_admin job_view_count_inline_div"
                              v-if="showViewCandidates('view')"
                            >
                              <!-- <a href="javascript:void(0);" @click="viewCandidates(encrypt(jobDatas.jobId))" class="button ripple-effect"><i class="icon-material-outline-supervisor-account"></i> View Candidates <span class="button-info">{{jobDatas.total_applicants}}</span></a> -->
                              <a
                                :href="
                                  '/v2/admin-fulltimejob-viewcandidates/' + encrypt(jobDatas.jobId)
                                "
                                target="_blank"
                                class="button ripple-effect"
                                ><i class="icon-material-outline-supervisor-account"></i> View
                                Candidates
                                <span class="button-info">{{ jobDatas.total_applicants }}</span></a
                              >
                            </div>
                            <!-- <b-link href="" :to="{name: 'fullTimeEditJob', params: {id: encrypt(jobDatas.jobId)}}" class="button gray ripple-effect ico" title="Edit" data-tippy-placement="top"><i class="material-icons-outlined common_overflow_unset">border_color_outlined</i></b-link> -->
                            <div
                              class="other_operation_admin_full_time new_other_op job_view_count_inline_div"
                              v-if="show('duplicate job') || show('remove job')"
                            >
                              <b-link
                                v-if="show('duplicate job')"
                                href="javascript:void(0);"
                                :to="{
                                  name: 'fullTimeRepostJob',
                                  params: { id: encrypt(jobDatas.jobId) },
                                }"
                                class="button gray ripple-effect ico repost-btn-job-list"
                                title="Repost"
                                data-tippy-placement="top"
                                ><i class="material-icons-outlined common_overflow_unset"
                                  >repeat_outlined</i
                                >Duplicate</b-link
                              >
                              <a
                                v-if="show('remove job')"
                                href="javascript:void(0);"
                                @click="deleteJob(jobDatas.jobId)"
                                class="button gray ripple-effect ico remove-btn-job-list"
                                data-tippy-placement="top"
                                ><i class="icon-feather-trash-2"></i>Remove</a
                              >
                            </div>
                            <div
                              class="fa fa-eye view_count_job job_view_count_inline_div"
                              v-if="jobDatas.view_count"
                            >
                              <span>{{ jobDatas.view_count | commaSeperator }}</span>
                            </div>
                            <!-- <a @click="changeStatus(jobDatas.jobId, jobDatas.job_status_val)" id="show_hide_status" v-if="jobDatas.job_status_val == 'active'" class="button gray ripple-effect ico">
                                                    <i class="fa fa-eye-slash" aria-hidden="true"></i>
                                                    </a>
                                                    <a @click="changeStatus(jobDatas.jobId, jobDatas.job_status_val)" id="show_hide_status" v-else-if="jobDatas.job_status_val == 'inactive'" class="button gray ripple-effect ico">
                                                    <i class="fa fa-eye" aria-hidden="true"></i>
                                                    </a> -->
                            <!-- <a href="#" @click ="changeStatus(jobDatas.jobId, jobDatas.job_status_val)" v-if="jobDatas.job_status_val == 'active'">Inactivate</a> -->
                            <!-- <a href="#" v-else-if="jobDatas.job_status_val == 'inactive'" @click ="changeStatus(jobDatas.jobId, jobDatas.job_status_val)">Activate</a> -->
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div class="content text-center" v-else>
                      <p class="margin_top_1rem"><b>No Record Found</b></p>
                    </div>
                  </b-tab>
                  <b-tab
                    :title="'INACTIVE (' + inactiveCount + ')'"
                    :active="inactiveTab"
                    @click="changeTabStatus('inactiveTab')"
                    class="reject_last_li"
                  >
                    <div class="content" v-if="jobData.length > 0">
                      <ul class="dashboard-box-list">
                        <li v-for="jobDatas in jobData" class="admin_featured_full">
                          <div class="listing_features_new">
                            <h5 v-if="jobDatas.featured == 'yes'"><b>Featured Ad</b></h5>
                          </div>
                          <!-- Job Listing -->
                          <div class="job-listing">
                            <!-- Job Listing Details -->
                            <div class="job-listing-details">
                              <!-- Logo -->
                              <!-- 											<a href="#" class="job-listing-company-logo">
                                                            <img src="images/company-logo-05.png" alt="">
                                                        </a> -->

                              <!-- Details -->
                              <div class="job-listing-description">
                                <!-- <span class="job-listing-title" v-if="jobDatas.title.length >= 25"><b-link :to="{name: 'fullTimeJobDetail', params: {id: encrypt(jobDatas.jobId)}}">{{jobDatas.title.substring(0,25)+'...' | capitalize}}</b-link></span> -->
                                <!-- <span class="job-listing-title" v-else><b-link :to="{name: 'fullTimeJobDetail', params: {id: encrypt(jobDatas.jobId)}}">{{jobDatas.title | capitalize}}</b-link></span> -->
                                <span class="job-listing-title"
                                  ><b>{{ jobDatas.title | capitalize }}</b></span
                                >
                                <h3
                                  class="job-listing-title new_all_job_title mobile_margin_top_15"
                                  v-if="jobDatas.description"
                                  style="margin-bottom: 15px; margin-left: 20px"
                                ></h3>
                                <!-- Job Listing Footer -->
                                <div class="job-listing-footer manage_job_li_nw">
                                  <ul class="fullTimeJobdata new_full_time_data">
                                    <li>
                                      <span v-if="jobDatas.company_name != null">
                                        <i class="icon-material-outline-business"></i
                                        ><b> {{ jobDatas.company_name | capitalize }}</b></span
                                      >
                                    </li>
                                  </ul>
                                  <ul class="fullTimeJobdata new_full_time_data">
                                    <li>
                                      <span v-if="jobDatas.start_date != null"
                                        ><i class="icon-material-outline-access-time"></i>
                                        {{ jobDatas.start_date | customFormatterOne }} </span
                                      ><span v-if="jobDatas.end_date != null">
                                        - {{ jobDatas.end_date | customFormatterOne }}</span
                                      >
                                      <!-- <br v-if="jobDatas.end_date != null"> -->
                                    </li>
                                  </ul>

                                  <ul class="fullTimeJobdata new_full_time_data">
                                    <li>
                                      <span v-if="jobDatas.service_categories_name"
                                        ><i class="icon-material-outline-business"></i>
                                        <span>{{
                                          jobDatas.service_categories_name == "Other"
                                            ? jobDatas.other_service_category
                                            : jobDatas.service_categories_name | removeUnderscore
                                        }}</span></span
                                      >
                                    </li>
                                    <li class="mobile_jb_hide">
                                      <span v-if="jobDatas.job_type"
                                        ><i class="icon-material-outline-business-center"></i>
                                        <span>{{
                                          jobDatas.job_type | customizeRoleType | capitalize
                                        }}</span></span
                                      >
                                    </li>
                                    <li class="mobile_jb_hide">
                                      <span v-if="jobDatas.level_title"
                                        ><i class="icon-material-outline-face"></i>
                                        {{ jobDatas.level_title | capitalize }}</span
                                      >
                                    </li>
                                  </ul>
                                  <ul class="fullTimeJobdata new_full_time_data">
                                    <li v-if="jobDatas.actual_posted_by_first_name">
                                      <span>
                                        <i class="icon-material-outline-date-range"></i>Posted By:
                                        {{ jobDatas.actual_posted_by_last_name | capitalize }}
                                        {{
                                          jobDatas.actual_posted_by_first_name | capitalize
                                        }}</span
                                      >
                                    </li>

                                    <li>
                                      <span v-if="jobDatas.posted_date">
                                        <i class="icon-material-outline-date-range"></i>Posted On:
                                        {{ jobDatas.posted_date | customFormatterposted }}</span
                                      >
                                    </li>

                                    <li>
                                      <span v-if="jobDatas.job_address"
                                        ><i class="icon-material-outline-location-on"></i>
                                        <span>{{
                                          jobDatas.job_address == "Other"
                                            ? jobDatas.other_location
                                            : jobDatas.job_address | capitalize
                                        }}</span></span
                                      >
                                    </li>
                                    <li>
                                      <span v-if="jobDatas.job_unique_id"
                                        ><i class="material-icons-outlined job_id_icon"
                                          >card_travel_outlined</i
                                        >Ads ID:
                                        <span>{{ jobDatas.job_unique_id | capitalize }}</span></span
                                      >
                                    </li>
                                    <li>
                                      <span
                                        v-if="
                                          (jobDatas.min_salary != 0 && jobDatas.max_salary != 0) ||
                                          jobDatas.min_salary > 0 ||
                                          jobDatas.max_salary > 0
                                        "
                                      >
                                        <i class="icon-material-outline-local-atm"></i
                                        ><span v-if="jobDatas.job_type == 'part-time'"
                                          >Per Hour Rate</span
                                        >
                                        <span v-else></span> ${{ jobDatas.min_salary }}-${{
                                          jobDatas.max_salary
                                        }}</span
                                      >
                                    </li>
                                    <li v-if="jobDatas.inactivate_by_user">
                                      <span>
                                        <i class="icon-material-outline-date-range"></i>Inactivated
                                        By:
                                        {{ jobDatas.inactivate_by_user | capitalize }}
                                      </span>
                                    </li>
                                  </ul>
                                  <!-- <ul class="fullTimeJobdata">
                                                                    <li>
                                                                    <span v-if="jobDatas.company_name != null"> <i class="icon-material-outline-business"></i><b> {{jobDatas.company_name | capitalize}}</b></span><br v-if="jobDatas.company_name != null"><br>
                                                                    <span v-if="jobDatas.start_date != null"> <i class="icon-material-outline-date-range"></i>Start Date {{jobDatas.start_date | customFormatterOne}}</span><br v-if="jobDatas.start_date != null">
                                                                    <span v-if="jobDatas.end_date != null"> <i class="icon-material-outline-date-range"></i>End Date {{jobDatas.end_date | customFormatterOne}}</span><br v-if="jobDatas.end_date != null">
                                                                    <span> <i class="icon-material-outline-date-range"></i>Posted on {{jobDatas.posted_date | customFormatterposted}}</span>

                                                                    </li>
                                                                </ul> -->
                                  <!-- <ul>
                                                                    <li><i class="icon-material-outline-date-range"></i> Posted on {{jobDatas.posted_date | customFormatterOne}}</li>
                                                                </ul> -->

                                  <div
                                    class="all_job_list_home featured_job_section_lists fulltimefeature"
                                  >
                                    <!-- <h5 v-if="jobDatas.featured == 'yes'"><b>Featured Ad</b></h5> -->
                                    <!--<h5 v-else class="red_bg"><b>No Featured Ad</b></h5>-->
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <!-- Buttons -->
                          <div
                            class="buttons-to-right always-visible upcoming_job_btnz common_flex_wrap job_view_count_inline"
                            v-if="
                              show('view job Detail') ||
                              showViewCandidates('view') ||
                              show('duplicate job') ||
                              show('remove job') ||
                              show('activate/inactivate')
                            "
                          >
                            <div
                              class="manage_candidate_in_admin job_view_count_inline_div"
                              v-if="show('view job Detail')"
                            >
                              <!-- <a href="javascript:void(0);" @click="jobDetail(encrypt(jobDatas.jobId),$event)" class="button ripple-effect"><i aria-hidden="true" class="fa fa-eye"></i>&nbsp;&nbsp;View Detail </a> -->
                              <a
                                :href="'/v2/admin/fulltime/job-detail/' + encrypt(jobDatas.jobId)"
                                target="_blank"
                                class="button ripple-effect"
                                ><i aria-hidden="true" class="fa fa-eye"></i>&nbsp;&nbsp;View Detail
                              </a>
                            </div>
                            <div
                              class="manage_candidate_in_admin job_view_count_inline_div"
                              v-if="showViewCandidates('view')"
                            >
                              <!-- <a href="javascript:void(0);" @click="viewCandidates(encrypt(jobDatas.jobId))" class="button ripple-effect"><i class="icon-material-outline-supervisor-account"></i> View Candidates <span class="button-info">{{jobDatas.total_applicants}}</span></a> -->
                              <a
                                :href="
                                  '/v2/admin-fulltimejob-viewcandidates/' + encrypt(jobDatas.jobId)
                                "
                                target="_blank"
                                class="button ripple-effect"
                                ><i class="icon-material-outline-supervisor-account"></i> View
                                Candidates
                                <span class="button-info">{{ jobDatas.total_applicants }}</span></a
                              >
                            </div>
                            <div
                              class="other_operation_admin_full_time new_other_op job_view_count_inline_div"
                              v-if="
                                show('duplicate job') ||
                                show('remove job') ||
                                show('activate/inactivate')
                              "
                            >
                              <!-- <b-link href="" :to="{name: 'fullTimeEditJob', params: {id: encrypt(jobDatas.jobId)}}" class="button gray ripple-effect ico edit-btn-job-list" data-tippy-placement="top"><i class="material-icons-outlined common_overflow_unset">border_color_outlined</i>Edit</b-link> -->
                              <b-link
                                v-if="show('duplicate job')"
                                href="javascript:void(0);"
                                :to="{
                                  name: 'fullTimeRepostJob',
                                  params: { id: encrypt(jobDatas.jobId) },
                                }"
                                class="button gray ripple-effect ico repost-btn-job-list"
                                title="Repost"
                                data-tippy-placement="top"
                                ><i class="material-icons-outlined common_overflow_unset"
                                  >repeat_outlined</i
                                >Duplicate</b-link
                              >
                              <a
                                v-if="show('remove job')"
                                href="javascript:void(0);"
                                @click="deleteJob(jobDatas.jobId)"
                                class="button gray ripple-effect ico remove-btn-job-list"
                                data-tippy-placement="top"
                                ><i class="icon-feather-trash-2"></i>Remove</a
                              >
                              <a
                                @click="changeStatus(jobDatas.jobId, jobDatas.job_status_val)"
                                id="show_hide_status"
                                v-if="
                                  jobDatas.job_status_val == 'active' && show('activate/inactivate')
                                "
                                class="button gray ripple-effect ico non-active-btn-job-list"
                              >
                                <i class="fa fa-eye-slash" aria-hidden="true"></i>Inactivate
                              </a>
                              <a
                                @click="changeStatus(jobDatas.jobId, jobDatas.job_status_val)"
                                id="show_hide_status"
                                v-else-if="
                                  jobDatas.job_status_val == 'inactive' &&
                                  show('activate/inactivate')
                                "
                                class="button gray ripple-effect ico active-btn-job-list"
                              >
                                <i class="fa fa-eye" aria-hidden="true"></i>Activate
                              </a>
                            </div>
                            <div
                              class="fa fa-eye view_count_job job_view_count_inline_div"
                              v-if="jobDatas.view_count"
                            >
                              <span>{{ jobDatas.view_count | commaSeperator }}</span>
                            </div>
                            <!-- <a @click="featureJob(jobDatas.jobId,jobDatas.company_id)" v-if="jobDatas.featured == 'no'" class="button gray ripple-effect ico">
                                                    </a> -->
                            <!-- <b-link href="" @click="featureJob(jobDatas.jobId,jobDatas.company_id)" v-if="jobDatas.featured == 'no'" class="button gray ripple-effect ico" title="Feature job" data-tippy-placement="top"><i class="material-icons-outlined">new_releases</i></b-link> -->

                            <!-- <a href="#" @click ="changeStatus(jobDatas.jobId, jobDatas.job_status_val)" v-if="jobDatas.job_status_val == 'active'">Inactivate</a> -->
                            <!-- <a href="#" v-else-if="jobDatas.job_status_val == 'inactive'" @click ="changeStatus(jobDatas.jobId, jobDatas.job_status_val)">Activate</a> -->
                            <!-- <b-link href=""  v-if="jobDatas.featured == 'no'" class="button gray ripple-effect ico" title="Feature job" data-tippy-placement="top" @click="$bvModal.show('my_manage_job_model')"><i class="material-icons-outlined">new_releases</i></b-link> -->
                            <!-- <b-link href=""  v-if="jobDatas.featured == 'no'" class="button gray ripple-effect ico" title="Feature job" data-tippy-placement="top" @click="onClickFeature(jobDatas.jobId,jobDatas.company_id)"><i class="material-icons-outlined">new_releases</i></b-link>
                                                        <div class="">
                                                            <b-modal id="my_manage_job_model" ref="my_manage_job_model" title="BootstrapVue" >
                                                                <h2>Feature Job</h2>
                                                                <p>Please select the featured job package</p>
                                                                <div class="custom_select_manage_jobz">
                                                                    <b-form-select v-model="selected" :options="options"></b-form-select>
                                                                    <b-form-select v-model="ft_feature_job" id="package" class="package" @change="onChangeFeature()">
                                                                        <option value="" disabled>Select featured job package</option>
                                                                        <option value="ft_1_day_free_featured_jobs">1 days - Free Featured - ({{ft_1_day_free_featured_jobs}} Remaining)</option>
                                                                        <option value="ft_7_day_free_featured_jobs">7 days - Free Featured - ({{ft_7_day_free_featured_jobs}} Remaining)</option>
                                                                        <option value="ft_14_day_free_featured_jobs">14 days - Free Featured - ({{ft_14_day_free_featured_jobs}} Remaining)</option>
                                                                        <option value="ft_30_day_free_featured_jobs">30 days - Free Featured - ({{ft_30_day_free_featured_jobs}} Remaining)</option>
                                                                        <option value="coins">7 days - 800 EL Coins</option>
                                                                    </b-form-select>
                                                                </div>
                                                                <h3 class="coin_msg" style="display:none">You will have <b-img :src="webUrl+'assets/images/Icons_Coin.png'" class="coins_manage_job_img"></b-img> {{remaining_coins}} EL Coins left after purchasing.</h3>
                                                                <p class="red_p">Note: Action cannot be undo once confirmed.</p>
                                                                <div class="model_footer_btns">
                                                                    <b-button  block @click="$bvModal.hide('my_manage_job_model')">Cancel</b-button>
                                                                    <button class="blue_btn" @click="submitFeatured()">Confirm Feature</button>
                                                                </div>
                                                            </b-modal>
                                                        </div> -->
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div class="content text-center" v-else>
                      <p class="margin_top_1rem"><b>No Record Found</b></p>
                    </div>
                  </b-tab>
                  <b-tab
                    :title="'All (' + allCount + ')'"
                    :active="allTab"
                    @click="changeTabStatus('allTab')"
                    class="reject_last_li"
                  >
                    <div class="content" v-if="jobData.length > 0">
                      <ul class="dashboard-box-list">
                        <li v-for="jobDatas in jobData" class="admin_featured_full">
                          <div class="listing_features_new">
                            <h5 v-if="jobDatas.featured == 'yes'"><b>Featured Ad</b></h5>
                          </div>

                          <!-- Job Listing -->
                          <div class="job-listing">
                            <!-- Job Listing Details -->
                            <div class="job-listing-details">
                              <!-- Logo -->
                              <!-- 											<a href="#" class="job-listing-company-logo">
                                                            <img src="images/company-logo-05.png" alt="">
                                                        </a> -->

                              <!-- Details -->
                              <div class="job-listing-description">
                                <!-- <span class="job-listing-title" v-if="jobDatas.title.length >= 25"><b-link :to="{name: 'fullTimeJobDetail', params: {id: encrypt(jobDatas.jobId)}}">{{jobDatas.title.substring(0,25)+'...' | capitalize}}</b-link></span> -->
                                <!-- <span class="job-listing-title" v-else><b-link :to="{name: 'fullTimeJobDetail', params: {id: encrypt(jobDatas.jobId)}}">{{jobDatas.title | capitalize}}</b-link></span> -->
                                <span class="job-listing-title mobile_display_block_admin"
                                  ><b>{{ jobDatas.title | capitalize }}</b></span
                                >
                                <span
                                  class="user_status_all_tab_admin all_tab_blue"
                                  v-if="jobDatas.job_status_val == 'active'"
                                  >In Progress</span
                                >
                                <span
                                  class="user_status_all_tab_admin all_tab_green"
                                  v-if="
                                    jobDatas.start_date > today &&
                                    jobDatas.job_status_val == 'inactive'
                                  "
                                  >Upcoming</span
                                >
                                <span
                                  class="user_status_all_tab_admin all_tab_red"
                                  v-if="jobDatas.end_date < today"
                                  >Expired</span
                                >
                                <span
                                  class="user_status_all_tab_admin all_tab_yellow"
                                  v-if="
                                    jobDatas.start_date <= today &&
                                    jobDatas.end_date >= today &&
                                    jobDatas.job_status_val == 'inactive'
                                  "
                                  >Inactive</span
                                >

                                <h3
                                  class="job-listing-title new_all_job_title mobile_margin_top_15"
                                  v-if="jobDatas.description"
                                  style="margin-bottom: 15px; margin-left: 20px"
                                ></h3>
                                <!-- Job Listing Footer -->
                                <div class="job-listing-footer manage_job_li_nw">
                                  <ul class="fullTimeJobdata new_full_time_data">
                                    <li>
                                      <span v-if="jobDatas.company_name != null">
                                        <i class="icon-material-outline-business"></i
                                        ><b> {{ jobDatas.company_name | capitalize }}</b></span
                                      >
                                    </li>
                                  </ul>
                                  <ul class="fullTimeJobdata new_full_time_data">
                                    <li>
                                      <span v-if="jobDatas.start_date != null"
                                        ><i class="icon-material-outline-access-time"></i>
                                        {{ jobDatas.start_date | customFormatterOne }} </span
                                      ><span v-if="jobDatas.end_date != null">
                                        - {{ jobDatas.end_date | customFormatterOne }}</span
                                      >
                                      <!-- <br v-if="jobDatas.end_date != null"> -->
                                    </li>
                                  </ul>

                                  <ul class="fullTimeJobdata new_full_time_data">
                                    <li>
                                      <span v-if="jobDatas.service_categories_name"
                                        ><i class="icon-material-outline-business"></i>
                                        <span>{{
                                          jobDatas.service_categories_name == "Other"
                                            ? jobDatas.other_service_category
                                            : jobDatas.service_categories_name | removeUnderscore
                                        }}</span></span
                                      >
                                    </li>
                                    <li class="mobile_jb_hide">
                                      <span v-if="jobDatas.job_type"
                                        ><i class="icon-material-outline-business-center"></i>
                                        <span>{{
                                          jobDatas.job_type | customizeRoleType | capitalize
                                        }}</span></span
                                      >
                                    </li>
                                    <li class="mobile_jb_hide">
                                      <span v-if="jobDatas.level_title"
                                        ><i class="icon-material-outline-face"></i>
                                        {{ jobDatas.level_title | capitalize }}</span
                                      >
                                    </li>
                                  </ul>
                                  <ul class="fullTimeJobdata new_full_time_data">
                                    <li v-if="jobDatas.actual_posted_by_first_name">
                                      <span>
                                        <i class="icon-material-outline-date-range"></i>Posted By:
                                        {{ jobDatas.actual_posted_by_last_name | capitalize }}
                                        {{
                                          jobDatas.actual_posted_by_first_name | capitalize
                                        }}</span
                                      >
                                    </li>

                                    <li>
                                      <span v-if="jobDatas.posted_date">
                                        <i class="icon-material-outline-date-range"></i>Posted On:
                                        {{ jobDatas.posted_date | customFormatterposted }}</span
                                      >
                                    </li>

                                    <li>
                                      <span v-if="jobDatas.job_address"
                                        ><i class="icon-material-outline-location-on"></i>
                                        <span>{{
                                          jobDatas.job_address == "Other"
                                            ? jobDatas.other_location
                                            : jobDatas.job_address | capitalize
                                        }}</span></span
                                      >
                                    </li>
                                    <li>
                                      <span v-if="jobDatas.job_unique_id"
                                        ><i class="material-icons-outlined job_id_icon"
                                          >card_travel_outlined</i
                                        >Ads ID:
                                        <span>{{ jobDatas.job_unique_id | capitalize }}</span></span
                                      >
                                    </li>
                                    <li>
                                      <span
                                        v-if="
                                          (jobDatas.min_salary != 0 && jobDatas.max_salary != 0) ||
                                          jobDatas.min_salary > 0 ||
                                          jobDatas.max_salary > 0
                                        "
                                      >
                                        <i class="icon-material-outline-local-atm"></i
                                        ><span v-if="jobDatas.job_type == 'part-time'"
                                          >Per Hour Rate</span
                                        >
                                        <span v-else></span> ${{ jobDatas.min_salary }}-${{
                                          jobDatas.max_salary
                                        }}</span
                                      >
                                    </li>
                                  </ul>
                                  <!-- <ul class="fullTimeJobdata">
                                                                    <li>
                                                                    <span v-if="jobDatas.company_name != null"> <i class="icon-material-outline-business"></i><b> {{jobDatas.company_name | capitalize}}</b></span><br v-if="jobDatas.company_name != null"><br>
                                                                    <span v-if="jobDatas.start_date != null"> <i class="icon-material-outline-date-range"></i>Start Date {{jobDatas.start_date | customFormatterOne}}</span><br v-if="jobDatas.start_date != null">
                                                                    <span v-if="jobDatas.end_date != null"> <i class="icon-material-outline-date-range"></i>End Date {{jobDatas.end_date | customFormatterOne}}</span><br v-if="jobDatas.end_date != null">
                                                                    <span> <i class="icon-material-outline-date-range"></i>Posted on {{jobDatas.posted_date | customFormatterposted}}</span>

                                                                    </li>
                                                                </ul> -->
                                  <!-- <ul>
                                                                    <li><i class="icon-material-outline-date-range"></i> Posted on {{jobDatas.posted_date | customFormatterOne}}</li>
                                                                </ul> -->

                                  <div
                                    class="all_job_list_home featured_job_section_lists fulltimefeature"
                                  >
                                    <!-- <span v-if="jobDatas.featured == 'yes'"><b>Featured Ad</b></span> -->
                                    <!-- <h5 v-if="jobDatas.featured == 'yes'"><b>Featured Ad</b></h5> -->
                                    <!--<h5 v-else class="red_bg"><b>No Featured Ad</b></h5>-->
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <!-- Buttons -->
                          <div
                            class="buttons-to-right always-visible upcoming_job_btnz common_flex_wrap job_view_count_inline"
                            v-if="
                              show('view job Detail') ||
                              showViewCandidates('view') ||
                              show('edit job') ||
                              show('duplicate job') ||
                              show('remove job') ||
                              show('activate/inactivate') ||
                              show('feature job')
                            "
                          >
                            <div
                              class="manage_candidate_in_admin job_view_count_inline_div"
                              v-if="show('view job Detail')"
                            >
                              <!-- <a href="javascript:void(0);" @click="jobDetail(encrypt(jobDatas.jobId),$event)" class="button ripple-effect"><i aria-hidden="true" class="fa fa-eye"></i>&nbsp;&nbsp;View Detail </a> -->
                              <a
                                :href="'/v2/admin/fulltime/job-detail/' + encrypt(jobDatas.jobId)"
                                target="_blank"
                                class="button ripple-effect"
                                ><i aria-hidden="true" class="fa fa-eye"></i>&nbsp;&nbsp;View Detail
                              </a>
                            </div>
                            <div
                              class="manage_candidate_in_admin job_view_count_inline_div"
                              v-if="
                                showViewCandidates('view') &&
                                (jobDatas.job_status_val == 'active' ||
                                  jobDatas.end_date < today ||
                                  (jobDatas.start_date <= today &&
                                    jobDatas.end_date >= today &&
                                    jobDatas.job_status_val == 'inactive'))
                              "
                            >
                              <!-- <a href="javascript:void(0);" @click="viewCandidates(encrypt(jobDatas.jobId))" class="button ripple-effect"><i class="icon-material-outline-supervisor-account"></i> View Candidates <span class="button-info">{{jobDatas.total_applicants}}</span></a> -->
                              <a
                                :href="
                                  '/v2/admin-fulltimejob-viewcandidates/' + encrypt(jobDatas.jobId)
                                "
                                target="_blank"
                                class="button ripple-effect"
                                ><i class="icon-material-outline-supervisor-account"></i> View
                                Candidates
                                <span class="button-info">{{ jobDatas.total_applicants }}</span></a
                              >
                            </div>
                            <div
                              class="other_operation_admin_full_time new_other_op job_view_count_inline_div"
                              v-if="
                                show('edit job') ||
                                show('duplicate job') ||
                                show('remove job') ||
                                show('activate/inactivate') ||
                                show('feature job')
                              "
                            >
                              <b-link
                                v-if="
                                  show('edit job') &&
                                  (jobDatas.job_status_val == 'active' ||
                                    (jobDatas.start_date > today &&
                                      jobDatas.job_status_val == 'inactive') ||
                                    (jobDatas.start_date <= today &&
                                      jobDatas.end_date >= today &&
                                      jobDatas.job_status_val == 'inactive'))
                                "
                                href="javascript:void(0);"
                                :to="{
                                  name: 'fullTimeEditJob',
                                  params: { id: encrypt(jobDatas.jobId) },
                                }"
                                class="button gray ripple-effect ico edit-btn-job-list"
                                data-tippy-placement="top"
                                ><i class="material-icons-outlined common_overflow_unset"
                                  >border_color_outlined</i
                                >Edit</b-link
                              >
                              <b-link
                                v-if="show('duplicate job')"
                                href="javascript:void(0);"
                                :to="{
                                  name: 'fullTimeRepostJob',
                                  params: { id: encrypt(jobDatas.jobId) },
                                }"
                                class="button gray ripple-effect ico repost-btn-job-list"
                                title="Repost"
                                data-tippy-placement="top"
                                ><i class="material-icons-outlined common_overflow_unset"
                                  >repeat_outlined</i
                                >Duplicate</b-link
                              >
                              <a
                                v-if="show('remove job')"
                                href="javascript:void(0);"
                                @click="deleteJob(jobDatas.jobId)"
                                class="button gray ripple-effect ico remove-btn-job-list"
                                data-tippy-placement="top"
                                ><i class="icon-feather-trash-2"></i>Remove</a
                              >
                              <a
                                @click="
                                  changeStatus(
                                    jobDatas.jobId,
                                    jobDatas.job_status_val,
                                    jobDatas.title,
                                    jobDatas.end_date
                                  )
                                "
                                id="show_hide_status"
                                v-if="
                                  jobDatas.job_status_val == 'active' &&
                                  show('activate/inactivate') &&
                                  (jobDatas.job_status_val == 'active' ||
                                    (jobDatas.start_date <= today &&
                                      jobDatas.end_date >= today &&
                                      jobDatas.job_status_val == 'inactive'))
                                "
                                class="button gray ripple-effect ico non-active-btn-job-list"
                              >
                                <i class="fa fa-eye-slash" aria-hidden="true"></i>Inactivate
                              </a>
                              <a
                                @click="
                                  changeStatus(
                                    jobDatas.jobId,
                                    jobDatas.job_status_val,
                                    jobDatas.title,
                                    jobDatas.end_date
                                  )
                                "
                                id="show_hide_status"
                                v-else-if="
                                  jobDatas.job_status_val == 'inactive' &&
                                  show('activate/inactivate') &&
                                  (jobDatas.job_status_val == 'active' ||
                                    (jobDatas.start_date <= today &&
                                      jobDatas.end_date >= today &&
                                      jobDatas.job_status_val == 'inactive'))
                                "
                                class="button gray ripple-effect ico active-btn-job-list"
                              >
                                <i class="fa fa-eye" aria-hidden="true"></i>Activate
                              </a>
                              <a
                                @click="featureJob(jobDatas.jobId, jobDatas.company_id)"
                                id="show_hide_status"
                                v-if="
                                  jobDatas.featured == 'no' &&
                                  show('feature job') &&
                                  (jobDatas.job_status_val == 'active' ||
                                    (jobDatas.start_date <= today &&
                                      jobDatas.end_date >= today &&
                                      jobDatas.job_status_val == 'inactive'))
                                "
                                class="button gray ripple-effect ico feature-btn-job-list"
                              >
                                <i class="gray ripple-effect ico" aria-hidden="true"></i>Featured ad
                              </a>
                            </div>
                            <div
                              class="fa fa-eye view_count_job view_count_job_admin job_view_count_inline_div"
                              v-if="jobDatas.view_count"
                            >
                              <span>{{ jobDatas.view_count | commaSeperator }}</span>
                            </div>

                            <!-- <a @click="featureJob(jobDatas.jobId,jobDatas.company_id)" v-if="jobDatas.featured == 'no'" class="button gray ripple-effect ico">
                                                    </a> -->
                            <!-- <b-link href="" @click="featureJob(jobDatas.jobId,jobDatas.company_id)" v-if="jobDatas.featured == 'no'" class="button gray ripple-effect ico" title="Feature job" data-tippy-placement="top"><i class="material-icons-outlined">new_releases</i></b-link> -->

                            <!-- <a href="#" @click ="changeStatus(jobDatas.jobId, jobDatas.job_status_val)" v-if="jobDatas.job_status_val == 'active'">Inactivate</a> -->
                            <!-- <a href="#" v-else-if="jobDatas.job_status_val == 'inactive'" @click ="changeStatus(jobDatas.jobId, jobDatas.job_status_val)">Activate</a> -->
                            <!-- <b-link href=""  v-if="jobDatas.featured == 'no'" class="button gray ripple-effect ico" title="Feature job" data-tippy-placement="top" @click="$bvModal.show('my_manage_job_model')"><i class="material-icons-outlined">new_releases</i></b-link> -->
                            <!-- <b-link href=""  v-if="jobDatas.featured == 'no'" class="button gray ripple-effect ico" title="Feature job" data-tippy-placement="top" @click="onClickFeature(jobDatas.jobId,jobDatas.company_id)"><i class="material-icons-outlined">new_releases</i></b-link>
                                                        <div class="">
                                                            <b-modal id="my_manage_job_model" ref="my_manage_job_model" title="BootstrapVue" >
                                                                <h2>Feature Job</h2>
                                                                <p>Please select the featured job package</p>
                                                                <div class="custom_select_manage_jobz">
                                                                    <b-form-select v-model="selected" :options="options"></b-form-select>
                                                                    <b-form-select v-model="ft_feature_job" id="package" class="package" @change="onChangeFeature()">
                                                                        <option value="" disabled>Select featured job package</option>
                                                                        <option value="ft_1_day_free_featured_jobs">1 days - Free Featured - ({{ft_1_day_free_featured_jobs}} Remaining)</option>
                                                                        <option value="ft_7_day_free_featured_jobs">7 days - Free Featured - ({{ft_7_day_free_featured_jobs}} Remaining)</option>
                                                                        <option value="ft_14_day_free_featured_jobs">14 days - Free Featured - ({{ft_14_day_free_featured_jobs}} Remaining)</option>
                                                                        <option value="ft_30_day_free_featured_jobs">30 days - Free Featured - ({{ft_30_day_free_featured_jobs}} Remaining)</option>
                                                                        <option value="coins">7 days - 800 EL Coins</option>
                                                                    </b-form-select>
                                                                </div>
                                                                <h3 class="coin_msg" style="display:none">You will have <b-img :src="webUrl+'assets/images/Icons_Coin.png'" class="coins_manage_job_img"></b-img> {{remaining_coins}} EL Coins left after purchasing.</h3>
                                                                <p class="red_p">Note: Action cannot be undo once confirmed.</p>
                                                                <div class="model_footer_btns">
                                                                    <b-button  block @click="$bvModal.hide('my_manage_job_model')">Cancel</b-button>
                                                                    <button class="blue_btn" @click="submitFeatured()">Confirm Feature</button>
                                                                </div>
                                                            </b-modal>
                                                        </div> -->
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div class="content text-center" v-else>
                      <p class="margin_top_1rem"><b>No Record Found</b></p>
                    </div>
                  </b-tab>
                </b-tabs>
              </div>
            </div>
          </div>
        </div>
        <!-- Row / End -->
        <!-- Row / End -->
        <div class="clearfix"></div>
        <nav class="pagination-box custom_pagination_border_radius">
          <div class="page-row-box d-inline-block">
            <b-form-group id="input-group-4">
              <span class="d-inline-block">Rows per page</span>
              <span class="d-inline-block"
                ><b-form-select v-model="form.rowsPerPage" @input="changeTabStatus(activeTab)">
                  <option>25</option>
                  <option>50</option>
                  <option>75</option>
                  <option>100</option>
                </b-form-select></span
              >
            </b-form-group>
          </div>
          <div class="total-page-count d-inline-block">
            <ul>
              <li>
                <span v-if="totalRows != 0">{{ from }} - {{ to }} of {{ totalRows }}</span>
              </li>
            </ul>
          </div>
          <b-pagination
            @input="changeTabStatus(activeTab)"
            :total-rows="totalRows"
            :per-page="perPage"
            v-model="currentPage"
            prev-text=""
            next-text=""
            hide-goto-end-buttons
          />
        </nav>
        <div class="clearfix"></div>

        <b-modal
          @hidden="hideModal"
          hide-footer
          title="Sharable Url"
          content-class="common_model_header common_model_title"
          modal-class="background_custom_class"
          id="add-note-modal-prevent-closing"
          ref="share-modal-keyword"
          centered
        >
          <div class="edit-task model_legend">
            <div class="popup-header">
              <!-- <h2 class="">Sharable Url</h2> -->
              <div class="mt-2">
                <div class="addUserForm">
                  <div class="editForm mw-100 pl-0">
                    <b-row>
                      <b-col lg="8" md="8">
                        <b-form-group id="input-group-5" label="Search Text" class="submit-field">
                          <b-form-input
                            type="text"
                            placeholder=""
                            v-model="search"
                            class="with-border model_margin_bottom_zero"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col lg="8" md="8">
                        <b-form-group
                          id="input-group-5"
                          label="Category"
                          class="required submit-field"
                        >
                          <span class="custom-dropdown">
                            <b-form-select
                              v-model="share_service_id"
                              @click="searchUrl()"
                              class="model_margin_bottom_zero"
                            >
                              <option value="0">Please Select Services</option>
                              <option
                                :value="categoryVal.id"
                                v-for="categoryVal in serviceCategories"
                              >
                                {{ categoryVal.name }}
                              </option>
                            </b-form-select>
                          </span>
                        </b-form-group>
                      </b-col>

                      <b-col lg="4" md="4">
                        <b-form-group
                          id="input-group-5"
                          label="Category"
                          class="common_visibility_hidden_model_legend"
                        >
                          <b-button
                            @click="searchUrl()"
                            variant="success desktop_margin_left_zero share-job-class"
                            class="sharabele_url_btn button button-ripple-effect model_margin_left_zero model_margin_right_zero"
                            >GENERATE URL</b-button
                          >
                        </b-form-group>
                      </b-col>

                      <b-col lg="8" md="8">
                        <b-form-group
                          id="input-group-5"
                          label="Job Url with Keyword"
                          class="submit-field"
                        >
                          <b-form-input
                            id="copy-keyword-url"
                            type="text"
                            placeholder=""
                            v-model="keyword_url"
                            class="model_margin_bottom_zero with-border"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>

                      <b-col lg="4" md="4">
                        <b-form-group
                          id="input-group-5"
                          label="Category"
                          class="common_visibility_hidden_model_legend"
                        >
                          <b-button
                            @click="copyURLKeyword()"
                            variant="success desktop_margin_left_zero share-key-class"
                            class="sharabele_url_btn button button-ripple-effect model_margin_left_zero model_margin_right_zero"
                            >COPY URL</b-button
                          >
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <div slot="footer" class="form-btn common_crop_reset_btn">
                      <b-button
                        @click="hideModal()"
                        variant="light"
                        class="model_common_bt_lite_color"
                        >Cancel</b-button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-modal>
        <!-- <div class="margin-top-70"></div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { POST_API } from "../../../store/actions.type";
import moment from "moment";
import Bus from "../../../event-bus";
import DateRangePicker from "vue2-daterange-picker";
import Multiselect from "vue-multiselect";
import permission from "../../../../../server/permission.js";

export default {
  data() {
    return {
      jobData: "",
      skill: "",
      daily: "",
      full_time: "",
      location: "",
      type: "",
      keyword: "",
      salary: "",
      error_message: "",
      skillsArr: [],
      tagArr: [],
      value: [0, 15000],
      pager: {},
      pageOfItems: [],
      serviceCategories: [],
      options: [],
      items: null,
      totalRows: 0,
      share_service_id: 0,

      sliderValue: 50,
      from: "",
      to: "",
      currentPage: 1,
      perPage: 25,
      pageOptions: [25, 50, 75, 100],
      form: {
        rowsPerPage: 25,
      },
      activeTab: "inprogress",
      inprogress: true,
      upcoming: false,
      expired: false,
      inactiveTab: false,
      allTab: false,
      inprogressCount: "",
      upcomingCount: "",
      expiredCount: "",
      inactiveCount: "",
      allCount: "",
      loading: false,
      keyword_url: "",
      selected: null,
      webUrl: process.env.VUE_APP_URL,
      ft_feature_job: "",
      ft_1_day_free_featured_jobs: 0,
      ft_7_day_free_featured_jobs: 0,
      ft_14_day_free_featured_jobs: 0,
      ft_30_day_free_featured_jobs: 0,
      sett_ft_1_day_featured_job_coins: 0,
      sett_ft_7_day_featured_job_coins: 0,
      sett_ft_14_day_featured_job_coins: 0,
      sett_ft_30_day_featured_job_coins: 0,
      total_coins: 0,
      remaining_coins: 0,
      company_id_feature: "",
      job_id_feature: "",

      filterSearch: "",
      dateRange: {
        startDate: "",
        endDate: "",
      },
      start_date_filter: "",
      end_date_filter: "",
      range: false,
      date: moment().utc().format("YYYY-MM-DD"),
      format: "YYYY-MM-DD HH:mm",
      auto: true,
      center: "center",
      minDate: null,
      locale: {
        direction: "ltr", //direction of text
        format: "DD-MMM-YYYY", //fomart of the dates displayed
        separator: " - ", //separator between the two ranges
        applyLabel: "Apply",
        cancelLabel: "Cancel",
        /*weekLabel: 'W',
                    customRangeLabel: 'Custom Range',*/
        daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
        monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
        firstDay: 0, //ISO first day of week - see moment documenations for details
        sameDateFormat: {
          from: "DD MM YYYY, hh:mm",
          to: "hh:mm",
        },
      },
      company_id_filter: "",
      employers: [],
      category_id_filter: [],
      categories: [],
      search: "",
      webServerUrl: process.env.VUE_APP_SERVER_URL,
      actions: "",
      viewCandidatesAction: "",
      today: moment().tz("Singapore").format("YYYY-MM-DD"),
    };
  },

  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    capitalizeAll: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.toUpperCase();
    },
    customFormatterOne(date) {
      return moment(date).format("DD MMM YYYY");
    },
    // customFormatterposted(date) {
    //     return moment(date).utc().format('DD MMM YYYY hh:mm A');
    // },
    customFormatterposted(date) {
      return moment(date).utc().format("DD MMM YYYY hh:mm A");
    },
    removeUnderscore: function (value) {
      if (!value) return "";
      var i,
        frags = value.split("_");
      for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
      }
      return frags.join(" ");
    },
    customizeRoleType: function (value) {
      if (!value) return "";
      var i,
        frags = value.split("-");
      for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
      }
      return frags.join(" ");
    },
    datePicker1: function (value) {
      if (value == null) {
        return "";
      } else {
        return moment(value).format("DD MMM YYYY");
      }
    },
    commaSeperator: function (value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },

  components: {
    DateRangePicker,
    Multiselect,
  },
  methods: {
    showAlert() {
      this.$swal({
        position: "center",
        icon: "error",
        title: this.error_message,
        showConfirmButton: false,
        timer: 1500,
      });
    },
    showSuccessAlert(message) {
      this.$swal({
        position: "center",
        icon: "success",
        iconColor: "#4c8bf5",
        title: message,
        showConfirmButton: false,
        timer: 1500,
      }).then((result) => {
        // this.$router.push({ name: 'manage-jobs' });
        this.changeTabStatus(this.activeTab);
        this.getTotalCount();
      });
    },
    clear() {
      this.filterSearch = "";
      this.dateRange.startDate = null;
      this.dateRange.endDate = null;
      this.company_id_filter = "";
      this.category_id_filter = [];
      this.changeTabStatus(this.activeTab);
    },
    customLabel({ first_name, last_name, name }) {
      return `${name} (${last_name} ${first_name})`;
    },
    getEmployers() {
      this.$store
        .dispatch(POST_API, {
          data: {},
          api: "/api/employer-job-list-ft-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;

            this.$swal({
              position: "center",
              icon: "error",
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.employers = this.$store.getters.getResults.data;
          }
        });
    },
    getDropdownData() {
      this.$store
        .dispatch(POST_API, {
          data: {
            table: "service_categories",
          },
          api: "/api/dropdown-list-category",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;

            this.$swal({
              position: "center",
              icon: "error",
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.categories = this.$store.getters.getResults.data;
          }
        });
    },
    getprofileEmployer(company_id) {
      this.$store
        .dispatch(POST_API, {
          data: {
            company_id: company_id,
          },
          api: "/api/get-employer-profile-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;

            this.$swal({
              position: "center",
              icon: "error",
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.ft_1_day_free_featured_jobs =
              this.$store.getters.getResults.data.ft_1_day_free_featured_jobs;
            this.ft_7_day_free_featured_jobs =
              this.$store.getters.getResults.data.ft_7_day_free_featured_jobs;
            this.ft_14_day_free_featured_jobs =
              this.$store.getters.getResults.data.ft_14_day_free_featured_jobs;
            this.ft_30_day_free_featured_jobs =
              this.$store.getters.getResults.data.ft_30_day_free_featured_jobs;
            this.sett_ft_1_day_featured_job_coins =
              this.$store.getters.getResults.ft_1_day_featured_job_coins;
            this.sett_ft_7_day_featured_job_coins =
              this.$store.getters.getResults.ft_7_day_featured_job_coins;
            this.sett_ft_14_day_featured_job_coins =
              this.$store.getters.getResults.ft_14_day_featured_job_coins;
            this.sett_ft_30_day_featured_job_coins =
              this.$store.getters.getResults.ft_30_day_featured_job_coins;
            this.total_coins = this.$store.getters.getResults.total_coins;
            this.remaining_coins = this.$store.getters.getResults.total_coins;
            if (this.ft_feature_job == "ft_1_day_free_featured_jobs") {
              if (this.ft_1_day_free_featured_jobs == 0) {
                this.remaining_coins = this.total_coins - this.sett_ft_1_day_featured_job_coins;
              } else {
                this.remaining_coins = this.total_coins;
              }
            } else if (this.ft_feature_job == "ft_7_day_free_featured_jobs") {
              if (this.ft_7_day_free_featured_jobs == 0) {
                this.remaining_coins = this.total_coins - this.sett_ft_7_day_featured_job_coins;
              } else {
                this.remaining_coins = this.total_coins;
              }
            } else if (this.ft_feature_job == "ft_14_day_free_featured_jobs") {
              if (this.ft_14_day_free_featured_jobs == 0) {
                this.remaining_coins = this.total_coins - this.sett_ft_14_day_featured_job_coins;
              } else {
                this.remaining_coins = this.total_coins;
              }
            } else {
              if (this.ft_30_day_free_featured_jobs == 0) {
                this.remaining_coins = this.total_coins - this.sett_ft_30_day_featured_job_coins;
              } else {
                this.remaining_coins = this.total_coins;
              }
            }
            if (this.remaining_coins > 0) {
              $(".coin_msg").css("display", "block");
            }
          }
        });
    },

    getprofileEmployerInfo(company_id) {
      this.$store
        .dispatch(POST_API, {
          data: {
            company_id: company_id,
          },
          api: "/api/get-employer-profile-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;

            this.$swal({
              position: "center",
              icon: "error",
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.ft_1_day_free_featured_jobs =
              this.$store.getters.getResults.data.ft_1_day_free_featured_jobs;
            this.ft_7_day_free_featured_jobs =
              this.$store.getters.getResults.data.ft_7_day_free_featured_jobs;
            this.ft_14_day_free_featured_jobs =
              this.$store.getters.getResults.data.ft_14_day_free_featured_jobs;
            this.ft_30_day_free_featured_jobs =
              this.$store.getters.getResults.data.ft_30_day_free_featured_jobs;
            this.sett_ft_1_day_featured_job_coins =
              this.$store.getters.getResults.ft_1_day_featured_job_coins;
            this.sett_ft_7_day_featured_job_coins =
              this.$store.getters.getResults.ft_7_day_featured_job_coins;
            this.sett_ft_14_day_featured_job_coins =
              this.$store.getters.getResults.ft_14_day_featured_job_coins;
            this.sett_ft_30_day_featured_job_coins =
              this.$store.getters.getResults.ft_30_day_featured_job_coins;
            this.total_coins = this.$store.getters.getResults.total_coins;
            this.remaining_coins = this.$store.getters.getResults.total_coins;
          }
        });
    },

    onChangeFeature() {
      this.getprofileEmployer(this.company_id_feature);
    },
    onClickFeature(jobId, company_id) {
      this.job_id_feature = jobId;
      this.company_id_feature = company_id;

      this.ft_feature_job = "";
      this.getprofileEmployerInfo(company_id);
      // this.$refs['my_manage_job_model'].show();
      this.$bvModal.show("my_manage_job_model");
    },
    changeTabStatus(status) {
      this.loading = true;
      this.activeTab = status;
      if (this.activeTab == "upcoming") {
        this.upcoming = true;
        this.inprogress = false;
        this.expired = false;
        this.inactiveTab = false;
        this.allTab = false;
      } else if (this.activeTab == "expired") {
        this.expired = true;
        this.inprogress = false;
        this.upcoming = false;
        this.inactiveTab = false;
        this.allTab = false;
      } else if (this.activeTab == "inactiveTab") {
        this.inactiveTab = true;
        this.inprogress = false;
        this.upcoming = false;
        this.expired = false;
        this.allTab = false;
      } else if (this.activeTab == "allTab") {
        this.allTab = true;
        this.inprogress = false;
        this.upcoming = false;
        this.expired = false;
        this.inactiveTab = false;
      } else {
        this.inprogress = true;
        this.upcoming = false;
        this.expired = false;
        this.inactiveTab = false;
        this.allTab = false;
      }
      (this.start_date_filter = this.dateRange.startDate
        ? moment(this.dateRange.startDate).format("YYYY-MM-DD")
        : ""),
        (this.end_date_filter = this.dateRange.endDate
          ? moment(this.dateRange.endDate).format("YYYY-MM-DD")
          : "");
      $(".dashboard-content-inner").animate(
        {
          scrollTop: 0,
        },
        "slow"
      );
      return this.$store
        .dispatch(POST_API, {
          data: {
            page: this.currentPage,
            rowsPerPage: this.form.rowsPerPage,
            userId: this.user_id,
            activeTab: status,
            search_filter: this.filterSearch,
            start_date_filter: this.start_date_filter,
            end_date_filter: this.end_date_filter,
            company_id_filter: this.company_id_filter.company_id
              ? this.company_id_filter.company_id
              : "",
            category_id_filter: this.category_id_filter,
          },
          api: "/api/fullTimeJob",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            this.loading = false;

            return [];
          } else {
            $(".dashboard-content-container .simplebar-scroll-content").animate(
              {
                scrollTop: 0,
              },
              "slow"
            );
            this.loading = false;

            this.jobData = this.$store.getters.getResults.pageOfItems;
            this.totalRows = this.$store.getters.getResults.pager.totalItems;
            this.perPage = this.$store.getters.getResults.pager.pageSize;
            this.from = this.$store.getters.getResults.pager.from;
            this.to = this.$store.getters.getResults.pager.to;
            this.getTotalCount();
            Bus.$emit("get_fl_emp_user_count");
            return this.jobData;
          }
        });
    },
    // submitFeatured() {
    //     if(this.ft_feature_job == ''){
    //         this.error_message = 'Please select the featured job package';
    //         this.showAlert();
    //     }else{

    //     var message='Job featured successfully.';
    //         return this.$store.dispatch(POST_API, {
    //                 data:{
    //                 id: this.job_id_feature,
    //                 ft_feature_job: this.ft_feature_job,
    //                 company_admin_id: this.company_admin_id ? this.company_admin_id:this.user_id,
    //                 company_id:this.company_id_feature,
    //                 page: this.currentPage,
    //                 keyword: this.filter
    //                 },
    //                 api:'/api/featureJob'
    //             })
    //         .then(() => {
    //                 if (this.$store.getters.containsErrors) {
    //                     this.error_message = this.$store.getters.getErrors;
    //                     this.showAlert();
    //                     this.$bvModal.hide('my_manage_job_model')
    //                     // if(this.error_message = 'Insufficient Coins for featuring the job.'){
    //                     //     this.$router.push({ name: 'elcoins' });
    //                     // }
    //                 } else {
    //                     this.showSuccessAlert(message);
    //                     this.changeTabStatus(this.activeTab);
    //                     this.getTotalCount();
    //                     this.$bvModal.hide('my_manage_job_model')
    //                     // window.location.replace('/v2/admin/fulltime-job-list');
    //                 }
    //             });
    //     }
    // },
    featureJob(id, company_id) {
      console.log(company_id, "ss");
      var msg = "Are you sure you want to set it to featured job?";
      var message = "Job featured successfully.";

      this.$swal({
        title: "Please Confirm",
        text: msg,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#4C8BF5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      })
        .then((result) => {
          if (result.value) {
            return this.$store
              .dispatch(POST_API, {
                data: {
                  id: id,
                  company_admin_id: this.company_admin_id ? this.company_admin_id : this.user_id,
                  company_id: company_id,
                  page: this.currentPage,
                  keyword: this.filter,
                },
                api: "/api/featureJob",
              })
              .then(() => {
                if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
                } else {
                  // this.changeTabStatus(this.activeTab);
                  // this.getTotalCount();
                  this.showSuccessAlert(message);

                  // window.location.replace('/v2/admin/fulltime-job-list');
                }
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    deleteJob(id) {
      this.$swal({
        title: "Please Confirm",
        text: "Are you sure you want to delete this record?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#4C8BF5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      })
        .then((result) => {
          if (result.value) {
            return this.$store
              .dispatch(POST_API, {
                data: {
                  id: id,
                },
                api: "/api/deleteJob",
              })
              .then(() => {
                if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
                } else {
                  var message = "Job Deleted successfully.";
                  this.showSuccessAlert(message);
                  // this.changeTabStatus(this.activeTab);
                  // this.getTotalCount();
                  Bus.$emit("get_fl_emp_user_count");
                  // window.location.replace('/v2/employer/manage-jobs');
                }
              });
          }
        })
        .catch((err) => {});
    },
    exportJobs(status) {
      console.log(status, "status");
      var base_url = this.webServerUrl;
      var start_date = this.start_date_filter ? this.start_date_filter : "null";
      var end_date = this.end_date_filter ? this.end_date_filter : "null";
      var search_filter = this.filterSearch != "" ? this.filterSearch : "null";
      var company_id_filter = this.company_id_filter.company_id
        ? this.company_id_filter.company_id
        : "null";
      var category_id_filter = this.category_id_filter ? this.category_id_filter : "null";
      var activeTab = status ? status : null;
      var page = this.currentPage;
      var rowsPerPage = this.form.rowsPerPage;
      var catArr = [];

      if (this.category_id_filter.length > 0) {
        this.category_id_filter.forEach((element) => {
          catArr.push(element.id);
        });
      }
      var category_id_filter = this.category_id_filter.length > 0 ? catArr : "null";
      var export_url =
        "/api/full-time-job-admin-excel/" +
        search_filter +
        "/" +
        page +
        "/" +
        rowsPerPage +
        "/" +
        activeTab +
        "/" +
        company_id_filter +
        "/" +
        category_id_filter +
        "/" +
        start_date +
        "/" +
        end_date;
      window.open(base_url + export_url, "_blank");
    },
    changeStatus(id, status, title, end_date) {
      if (status == "active") {
        // var msg = 'Are you sure you want to inactivate this record?';
        var message = "Job Inactivated successfully.";
        var new_status = "inactive";
        var pop_up_title = title;
        var end_date = moment(end_date);
        var current_date = moment(new Date());
        // console.log(`Remaining Days : ${end_date.diff(current_date, 'days')} day(s)`);
        var ramaining_days = `${end_date.diff(current_date, "days")} days`;
        var pop_up_content =
          '<center><h2 class="swal2-title" id="swal2-title">Please confirm to<br>inactivate this job posting</h2></center><ul class="custom_swal_list_manage_job admin_manage_job"><li><b>Job Title : </b>' +
          pop_up_title +
          '</li><li class="middle_para_job"><b>Remaining Days : </b>' +
          ramaining_days +
          "</li></ul>";
      } else {
        var msg = "Are you sure you want to activate this record?";
        var message = "Job Activated successfully.";
        var new_status = "active";
        var pop_up_content =
          '<center><h2 class="swal2-title activate_h2" id="swal2-title">Please Confirm</h2><p>Are you sure you want to activate this record?</p></center>';
      }
      this.$swal({
        // title: 'Please Confirm',
        text: msg,
        html: pop_up_content,
        // type: 'warning',
        type: "success",
        icon: "success",
        iconColor: "#4c8bf5",
        showCancelButton: true,
        confirmButtonColor: "#4C8BF5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Proceed",
        cancelButtonText: "Cancel",
        customClass: {
          container: "manage_job_container_class",
        },
      })
        .then((result) => {
          if (result.value) {
            return this.$store
              .dispatch(POST_API, {
                data: {
                  id: id,
                  logged_in_user: this.user_id,
                  status: new_status,
                  page: this.currentPage,
                  keyword: this.filter,
                },
                api: "/api/changeJobStatus",
              })
              .then(() => {
                if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
                } else {
                  this.showSuccessAlert(message);
                  // this.changeTabStatus(this.activeTab);
                  // this.getTotalCount();
                  // window.location.replace('/v2/manage-jobs');
                }
              });
          }
        })
        .catch((err) => {});
    },
    jobDetail(id, event) {
      if (event) {
        event.stopPropagation();
      }
      //  window.location.replace('/v2/admin/fulltime/job-detail/'+ id);
      window.open("/v2/admin/fulltime/job-detail/" + id, "_blank");
    },
    viewCandidates(id) {
      // window.location.replace('/v2/admin-fulltimejob-viewcandidates/'+id);
      window.open("/v2/admin-fulltimejob-viewcandidates/" + id, "_blank");
    },

    getTotalCount() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            search_filter: this.filterSearch,
            start_date_filter: this.start_date_filter,
            end_date_filter: this.end_date_filter,
            company_id_filter: this.company_id_filter.company_id
              ? this.company_id_filter.company_id
              : "",
            category_id_filter: this.category_id_filter,
          },
          api: "/api/adminJobsTotalCount",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            // this.showAlert();
            return [];
          } else {
            this.inprogressCount = this.$store.getters.getResults.inprogressCount;
            this.upcomingCount = this.$store.getters.getResults.upcomingCount;
            this.expiredCount = this.$store.getters.getResults.expiredCount;
            this.inactiveCount = this.$store.getters.getResults.inactiveCount;
            this.allCount = this.$store.getters.getResults.allCount;
          }
        });
    },

    async copyURLKeyword() {
      try {
        let copyText = document.querySelector("#copy-keyword-url");
        if (copyText != "") {
          copyText.select();
          document.execCommand("copy");
          // var mytext = $('#copy-url').val();
          // navigator.permissions.query({ name: "clipboard-write" }).then((result) => {
          //     if (result.state == "granted" || result.state == "prompt") {
          //         navigator.clipboard.writeText(mytext);
          //     }
          // });
          $(".share-key-class").html("Copied!");
          $(".share-key-class").prop("disabled", true);
        }
        // Snackbar.show({
        //     text: 'Copied to clipboard!',
        // });
        // alert('Copied to Clipboard.');
      } catch ($e) {
        console.log($e);

        // alert('Cannot copy');
      }
    },

    openShareJobModalKeyword() {
      // console.log(process.env.MIX_APP_URL_V2_WEB);
      this.$refs["share-modal-keyword"].show();
      // this.share_job_id = job_id;
      // this.job_url =  process.env.MIX_APP_URL_V2_WEB + "job-daily-paid?jobId=" + btoa(job_id);
      // this.company_url =  process.env.MIX_APP_URL_V2_WEB + "job-daily-paid-comp?compId=" + btoa(comp_id) +"&comp_name="+btoa(comp_name);
    },
    searchUrl() {
      $(".share-key-class").html("Copy URL");
      $(".share-key-class").prop("disabled", false);
      var url =
        this.webUrl +
        "job-full-time-list?filter=" +
        btoa(this.search) +
        "&cat_id=" +
        this.share_service_id;
      // var url = this.webUrl + "singapore-daily-paid-part-time-jobs/" + (this.share_service_id) + "/"+(this.search);;

      $("#copy-keyword-url").val(url);
      this.keyword_url = url;
    },

    getServiceCategory() {
      return this.$store
        .dispatch(POST_API, {
          api: "/api/service-categories-new-ft",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.serviceCategories = this.$store.getters.getResults.data;
            return this.serviceCategories;
          }
        });
    },

    hideModal() {
      this.$refs["share-modal-keyword"].hide();
    },
    permissionStaff() {
      if (this.$store.getters.getCurrentUserPermission) {
        if (
          this.$store.getters.getCurrentUserPermission.data.length > 0 &&
          this.$store.getters.currentUser.user_type_id == 2
        ) {
          var menu = this.$store.getters.getCurrentUserPermission.data;
          this.actions = permission.getPermissionStaff(menu, "Job Ads");
          this.viewCandidatesAction = permission.getPermissionStaff(menu, "View Candidates");
        }
      }
    },
    show(action) {
      if (this.$store.getters.currentUser.user_type_id == 2) {
        return this.actions.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
    showViewCandidates(action) {
      if (this.$store.getters.currentUser.user_type_id == 2) {
        return this.viewCandidatesAction.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
  },
  mounted() {
    console.log(this.keyword_url);
    if (this.keyword_url == "") {
      console.log(this.keyword_url, "jjj");

      $(".share-key-class").prop("disabled", true);
    }
    Bus.$emit("get_fl_emp_user_count");
    //document.title = "EL Connect - Admin - Full Time Jobs";
    this.user_id =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : "";
    this.getEmployers();
    this.getDropdownData();
    this.changeTabStatus(this.activeTab);
    this.getServiceCategory();

    this.getTotalCount();
    this.permissionStaff();

    $(".dashboard-content-container .simplebar-scroll-content").animate(
      {
        scrollTop: 0,
      },
      "fast"
    );
  },
};
</script>
