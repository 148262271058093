<template>
    <div>
    <!-- Page Content
        ================================================== -->
        <div class="container">
                <div class="row align_center common_bottom_padding_rewards_60 common_top_padding_rewards_60">
                  <div class="col-xl-4 col-md-4 rewards_display_none_mobile">
                        <img :src="webUrl+'assets/images/illiustration-2.png'" alt="illiustration">
                  </div>
                  <div class="col-xl-7 col-md-7 rewards_left_80">
                        <h1 class="rewards_h1 swift-up-text">Unlock Exclusive Rewards & <br>Deals with EL Connect!</h1>
                        <p class="rewards_p">Discover amazing offers and bundle deals exclusively for EL Connect users.<br> It's quick, easy, and tailored to give you more value with every purchase.</p>
                            <button class="rewards_button" v-b-modal.modal-26>
                                <div>
                                    <span class="first_animation_span"> Claim Your Rewards Today! </span
                                    ><span class="second_animation_span"> Claim Your Rewards Today! </span>
                                </div>
                            </button>

                  </div>
                </div>
                 <div class="row common_bottom_padding_rewards_60">
                    <div class="col-xl-12">
                        <h2 class="list_h2">List of Rewards</h2>
                    </div>
                    <div class="col-xl-12">
                           <VueSlickCarousel :arrows="true" :dots="false" v-bind="settings" ref="carousel" v-if="all_rewards.length" class="job_detail_slider reward_slider">
                                <div class="box_slide" v-for="(reward, h) in all_rewards" :key="h">
                                        <!-- <img :src="webUrl+'assets/images/Rewards - My Little Coconut 2.png'"> -->
                                    <div class="bg_image_rewards" v-bind:style="{backgroundImage: reward.banner_image != null ? 'url('+ reward.banner_image +')' : 'url('+ webUrl+'assets/images/Rewards-My-Little-Coconut-2.png'+ ')'} ">
                                        <div class="" :class="reward.end_date < current_date ? 'overlay_div' : 'reward_overlay_div_not_exist'">
                                        </div>
                                    </div>
                                    <div class="box_shadow_div">
                                        <h2>{{reward.title}}</h2>
                                        <span class="text_span_rewards_slider"><span class="mat_icon material-icons-outlined">store_mall_directory_outlined</span> {{reward.company_name}}</span>
                                         <span class="text_span_rewards_slider"><span class="mat_icon material-icons-outlined">calendar_month_outlined</span> {{reward.start_date}} - {{reward.end_date}}</span>
                                        <button class="claim_btn_rewards"  v-b-modal.modal-26>
                                            <div>
                                                <span class="first_animation_span"> Claim Now! </span
                                                ><span class="second_animation_span"> Claim Now! </span>
                                            </div>
                                        </button>
                                    </div>

                                </div>
				            </VueSlickCarousel>
                    </div>
                </div>
        </div>
        <div class="bg_colour_reward common_bottom_margin_rewards_60">
            <div class="container">
                <div class="row align_center">
                    <div class="col-xl-4 col-md-4 mobile_txt_rewards">
                        <div>
                            <img :src="webUrl+'/assets/images/iMockup - iPhone-15-Pro-Max.png'" class="number_img" alt="iPhone 15 Pro Max">
                        </div>
                    </div>
                    <div class="col-xl-8 col-md-8 mobile_txt_count_rewards">
                        <div class="numbering">
                            <h3 class="text_count_rewards">1</h3>
                            <div class="text_with_count_main_div_rewards">
                                <p class="count_text_rewards animate-on-scroll">Download EL Connect</p>
                                <h3 class="animate-on-scroll">Download our app from the App Store or Google Play Store. After installation,<br> log in or sign up for a new account.</h3>
                            </div>
                        </div>
                         <div class="numbering">
                            <h3 class="text_count_rewards">2</h3>
                            <div class="text_with_count_main_div_rewards">
                                <p class="count_text_rewards animate-on-scroll">Navigate to the Rewards Section</p>
                                <h3 class="animate-on-scroll">Once logged in, go to the "Rewards" section on the homepage. Browse the<br> available offers and choose the reward you'd like to claim.</h3>
                            </div>
                        </div>
                         <div class="numbering">
                            <h3 class="text_count_rewards">3</h3>
                            <div class="text_with_count_main_div_rewards mb-0 ">
                                <p class="count_text_rewards animate-on-scroll">Verify with the Merchant</p>
                                <h3 class="animate-on-scroll">Once you’re at the merchant’s location, show your reward ID in your app to the <br>merchant for verification. Your reward will be redeemed instantly!</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-bind:style="{backgroundImage: 'url('+ webUrl+'assets/images/Group-810.png'+ ')'} " class="footer_rewards">
            <div class="container">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="whole_store">
                            <h2 class="explore_h2 ">Explore more
                                <span class="bounce">
                                    <span>r</span>
                                    <span>e</span>
                                    <span>w</span>
                                    <span>a</span>
                                    <span>r</span>
                                    <span>d</span>
                                    <span>s</span>
                                </span> 
                            with our app
                            </h2>
                            <p>Ready to enjoy amazing deals and special offers? <br>
                                Download our app and start exploring today. <br>
                                The sooner you start, the sooner you save—get the app and claim those rewards today!
                            </p>
                            <div class="store_div_rewards">
                                <a v-bind:href="
                                    (get_ios_link.option_key = 'ios_ptuser_link'
                                    ? get_ios_link.option_value
                                    : get_ios_link.option_value)
                                " target="_blank">
                                    <img :src="webUrl+'assets/images/image-55.png'" class="app_rewards" alt="Apple Store">
                                </a>
                                <a v-bind:href="
                                        (get_android_link.option_key = 'android_ptuser_link'
                                        ? get_android_link.option_value
                                        : get_android_link.option_value)
                                    "
                                    target="_blank">
                                    <img :src="webUrl+'assets/images/image-51.png'" alt="Google Play">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
         <b-modal id="modal-26" hide-header  centered ref="rewards-modal" title="" hide-footer content-class="common_model_header common_model_title" modal-class="background_custom_class rewards_pop_up">
            <div class="reward_modal_whole_div">
                <div class="left_gradient_div_rewards">
                    <div class="button_div_rewards mobile_reward_btn">
                        <button type="button" aria-label="Close" class="close" @click="closeRewardsModel()">×</button>
                    </div>
                    <div class="reward_cut_img">
                        <img :src="webUrl+'assets/images/Group-808.png'">
                    </div>
                </div>
                 <div class="right_content_div_rewards">
                    <div class="button_div_rewards desktop_reward_btn">
                        <button type="button" aria-label="Close" class="close" @click="closeRewardsModel()">×</button>
                    </div>
                    <div class="inner_content_rewards">
                        <h2>DOWNLOAD OUR APP</h2>
                        <p class="desktop_reward_btn">Discover daily paid jobs and full-time opportunities at your fingertips. Stay connected and manage your profile easily with our app. </p>
                        <p class="mobile_reward_btn">Discover daily paid jobs and full-time <br>opportunities at your fingertips. Stay connected <br>and manage your profile easily with our app. </p>
                        <div class="whole_icon_rewards">
                            <div class="qr_div_reward">
                                <a target="_blank" href="https://onelink.to/pkye4c"><img :src="webUrl+'assets/images/image-560.png'" class="qr_rewards"></a>
                            </div>
                            <div class="store_div_rewards">
                                <a v-bind:href="
                                    (get_ios_link.option_key = 'ios_ptuser_link'
                                    ? get_ios_link.option_value
                                    : get_ios_link.option_value)
                                " target="_blank">
                                    <img :src="webUrl+'assets/images/image-550.png'" class="apple_rewards">
                                </a>
                                <br>
                                <a v-bind:href="
                                        (get_android_link.option_key = 'android_ptuser_link'
                                        ? get_android_link.option_value
                                        : get_android_link.option_value)
                                    "
                                    target="_blank">
                                    <img :src="webUrl+'assets/images/image-551.png'" class="play_rewards">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<style scoped>
  @import '../../assets/css/style.css';
  @import '../../assets/css/icons.css';

  /* Color css  */

  @import '../../assets/css/colors/blue.css';

</style>
<script>
    import { POST_API, LOGIN, PERMISSION_DATA, LOGOUT } from "../../store/actions.type";
    import { Carousel, Slide } from 'vue-carousel';
    import VueSlickCarousel from 'vue-slick-carousel'
    import 'vue-slick-carousel/dist/vue-slick-carousel.css'
    import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
    import moment from "moment";

    export default {
          metaInfo: {
            title: 'Unlock Exclusive Rewards & Deals with EL Connect!',
            meta: [
                { charset: 'utf-8' },
                { equiv: 'content-type', content: 'text/html' },
                { name: 'description', content: "Explore EL Connect's rewards program offering exclusive benefits and incentives for job seekers and employers in Singapore."},
                { name: 'viewport', content: 'width=device-width, initial-scale=1' },
                {property: 'og:title', content: 'Unlock Exclusive Rewards & Deals with EL Connect!'},
                {property: 'og:site_name', content: 'EL Connect'},
                {property: 'og:type', content: 'website'},
                {property: 'og:url', content: 'https://elconnect.sg/rewards'},
                {property: 'og:image', content: 'https://elconnect.sgp1.digitaloceanspaces.com/v2/general_setting/202281202127_general_setting.png'},
                {property: 'og:description', content: "Explore EL Connect's rewards program offering exclusive benefits and incentives for job seekers and employers in Singapore."},
                {name: 'twitter:card', content: 'summary'}
            ],
            link: [
                {rel: 'canonical', href: 'https://elconnect.sg/rewards'}
            ]
        },
        data() {
            return {
                current_date : moment().format('Y-M-D'),
                qrdata: [],
                get_ios_link: [],
                get_android_link: [],
                all_rewards:[],
                webUrl: process.env.VUE_APP_URL,
                settings: {
				"slidesToShow": 3,
				"slidesToScroll": 3,
				"speed": 800,
				"infinite": false,
				responsive: [
					{
					// breakpoint: 768,
					// settings: {
					// 	"slidesToShow": 1,
					// 		"slidesToScroll": 1,
					// 		"speed": 800,
					// 		"infinite": false,
                    //         "dots":true,
                    //         "arrows":false
					//     }
                         "breakpoint": 998,
                            "settings": {
                                "slidesToShow": 3,
                                "slidesToScroll": 3,
                                // "infinite": true,
                                "dots": false,
                                "arrow":true
                            }
                            },
                            {
                            "breakpoint": 1000,
                            "settings": {
                                "slidesToShow": 1,
                                "slidesToScroll": 1,
                                // "infinite": true,
                                "dots": false,
                                "arrow":true
                            }
                            },
                            {
                            "breakpoint": 768,
                            "settings": {
                                "slidesToShow": 1,
                                "slidesToScroll": 1,
                                "initialSlide": 1,
                                "dots": false,
                                "arrow":true
                            }
                            },
                            {
                            "breakpoint": 480,
                            "settings": {
                                "slidesToShow": 1,
                                "slidesToScroll": 1,
                                "dots": false,
                                "arrow":true
                            }

					}
				]
			}
            }
        },
        methods:{
        getQRData() {
            return this.$store
            .dispatch(POST_API, {
                api: "/api/qr-data",
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showAlert();
                return [];
                } else {
                if (this.$store.getters.getResults.data) {
                    this.qrdata = this.$store.getters.getResults.data;
                } else {
                }
                }
            });
        },
         getFooterData() {
            return this.$store
                .dispatch(POST_API, {
                api: "/api/footer-data",
                })
                .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    // this.showAlert();
                    return [];
                } else {
                    this.get_android_link = this.$store.getters.getResults.get_android_link[0];
                    this.get_ios_link = this.$store.getters.getResults.get_ios_link[0];
                }
                });
            },
        getRewardData() {
            return this.$store
                .dispatch(POST_API, {
                api: "/api/reward-data-website",
                })
                .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    // this.showAlert();
                    return [];
                } else {
                    this.all_rewards = this.$store.getters.getResults.data;
                }
                });
            },
        closeRewardsModel(){
             this.$refs["rewards-modal"].hide();
         },
        },
        components:{
            VueSlickCarousel,
            Carousel,
            Slide
        },
        mounted() {
            this.getQRData();
            this.getFooterData();
            this.getRewardData();
            window.scrollTo(0, 0);

            // Observer for '.animate-on-scroll' and '.slide-in-left' elements
            const animationElements = document.querySelectorAll('.animate-on-scroll, .slide-in-left');
            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            entry.target.classList.add('animate');
                        } else {
                            entry.target.classList.remove('animate');
                        }
                    });
                },
                { threshold: 0.5 }
            );

            animationElements.forEach((el) => observer.observe(el));

            // Animate text character-by-character for the element with ID 'text'
            const text = document.getElementById('text');
            if (text) {
                let newDom = '';
                const animationDelay = 6;

                for (let i = 0; i < text.innerText.length; i++) {
                    newDom += `<span class="char">${text.innerText[i] === ' ' ? '&nbsp;' : text.innerText[i]}</span>`;
                }

                text.innerHTML = newDom;
                const children = text.children;

                for (let i = 0; i < children.length; i++) {
                    children[i].style.animationDelay = `${animationDelay * i}ms`;
                }
            }

            // Animate swift-up text
            const swiftUpElements = document.querySelectorAll('.swift-up-text');
            swiftUpElements.forEach((elem) => {
                const words = elem.textContent.split(' ');
                elem.innerHTML = ''; // Clear original text content

                words.forEach((word, index) => {
                    const span = `<span class="swift-up-span"><i class="swift-up-i">${word}</i></span>`;
                    elem.innerHTML += span;

                    // Add a space after each word, except the last one
                    if (index < words.length - 1) {
                        elem.innerHTML += ' ';
                    }
                });

                const children = elem.querySelectorAll('.swift-up-span > .swift-up-i');
                children.forEach((child, index) => {
                    child.style.animationDelay = `${index * 0.2}s`;
                });
            });
        }
}
</script>
