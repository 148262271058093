<template>

    <div class="animated fadeIn">
      <div class="manage-job-section">
        <!-- <div
          class="black-strip-head d-lg-flex d-sm-flex align-items-center padding_top_25 manage_job_top_anchor new_mobile_display_block justify-content-between"
        > -->
          <div class="dashboard-headline">
            <h1 class="mr-auto">Staff Deployment</h1>
          </div>
        <!-- </div> -->
      </div>
      <div class="row">
        <div class="col-xl-6  mobile_tab_max_width_flex search_common_margin_bottom_28">
                <label class="timesheet_label new_style_label"> Sales (Applies to table & chart):</label>
                <fieldset>
                    <b-form-select id="selectServices" :plain="false" v-model="closed_by" @change="handleSalesChange" class="common_select_focus common_padding_left_right_20 common_border_search height_unset desktop_margin_bottom_zero">
                      <option value=""></option>
                      <option :value="manager.id" v-for="manager in allClosedBy" :key="manager.id">
                          {{ manager.last_name | capitalize }} {{ manager.first_name | capitalize }}
                      </option>
                    </b-form-select>
                </fieldset>
        </div>
        <div v-if="showResetButton" class="penalty_btn  col-xl-6 mobile_tab_max_width_flex search_common_margin_bottom_28 invoices_btns balance_summary_btn">
              <b-button
                  v-if="showResetButton"
                  @click="resetFilter()"
                  class="btn reset_penality_mobile ml-0 new_reset_text mobile_tab_padding_right_zero mobile_tab_padding_left_zero mobile_tab_padding_top_zero mobile_tab_padding_bottom_zero staff_reset_btn  common_reset_btn_filter_transactions button ripple-effect search_all_red_btn filter_btn_widht_120 desktop_margin_right_zero mr-0 btn-secondary"
                  style="background-color: red"
                  ><span class="mobile_reset common_text_align_start_second_screen">Reset filters</span>
              </b-button>
        </div>
      </div>
        
        <div class="graph_div staff_graph_div" style="">
          <div class="row">
            <div class="col-xl-5 search_common_margin_bottom_28">
              <h2 class="common_staff_h2">Deployment Overview</h2>
            </div>
            <div class="col-xl-7 search_common_margin_bottom_28 common_text_align_end_desktop common_text_align_start_second_screen">
              <b-button :variant="graphPeriod === 'month' ? 'selected_btn' : 'non_selected_btn'" class="ml-0" @click="setGraphPeriod('month')">This Month</b-button>
              <b-button :variant="graphPeriod === 'year' ? 'selected_btn' : 'non_selected_btn'" @click="setGraphPeriod('year')">This Year</b-button>
              <b-button :variant="graphPeriod === 'last' ? 'selected_btn' : 'non_selected_btn'" class="mr-0" @click="setGraphPeriod('last')">Last Year</b-button>
            </div>
          </div>
          <div class="deployment_chart">
            <canvas ref="verticalBarChart" style="" class=""></canvas>
          </div>
        </div>              
        <!-- <b-row> -->
          <b-col lg="12" class="remove_min_height detailde_deployment_div">
            <div class="tab-box--head top_custom_head desktop_search_top_div">
               <h2 class="mb-3 common_staff_h2">Detailed Deployment</h2> 
              <div class="row">
                  <div class="col-xl-4 mobile_tab_max_width_flex search_common_margin_bottom_28">
                    <div class="select-service-box date-picker-field clearfix">
                      <!-- <div class="label_div text_align_end_desktop">
                                <label>Date</label>
                              </div> -->
                      <b-form-group id="input-group-7" class="required mb-0">
                        <date-range-picker
                              class="desktop_display_block common_date_range_picker_for_v2 web_bottom_zero timesheet_calender_new calender_border transactions_jb_calender calender_div_job_in_job common_width_100"
                              ref="picker"
                              :minDate="minDate"
                              :opens="center"
                              :locale-data="locale"
                              :auto-apply="auto"
                              v-model="dateRange"
                              :ranges="range"
                              @update="dateFilterFunction()"
                            >
                              <div slot="input" slot-scope="picker">
                                {{ picker.startDate | date }} - {{ picker.endDate | date }}
                              </div>
                            </date-range-picker>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="col-xl-8 mobile_tab_max_width_flex search_common_margin_bottom_28 invoices_btns balance_summary_btn">
                      <b-button 
                        v-if="show('export')"
                        class="ml-0 mr-0 btn ml-0 common_export_btn common_z_index_zero button ripple-effect desktop_margin_right_zero"
                        @click="exportData()"
                        >Export</b-button
                      >
                  </div>
                </div>
              </div>
    
              <div class="my_neer show_filter_arrow">
                <div class="uploaded_count total_deployed_heading"><h1 class="ml-0"><b>Total Deployed: {{ totalDeployedPage }}</b></h1></div>
              <!-- <div>Total Deployed: {{ totalDeployedPage }}</div> -->
            <!-- Modified b-table: Grouping rows are wrapped in a real <tbody> -->
              <b-table
                ref="datatable"
                show-empty
                striped
                hover
                responsive
                :items="getListing"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                class="new_layout_table_like_v1  revenue_table_admin revenue_report_admin_new common_bill_tables"
              >
              <template #cell(date)="items">
              {{ items.item.date }}
            </template>

            <template #cell(company)="items">
              <div v-for="(job, index) in items.item.jobs" :key="index">
                {{ job.company_name }}
              </div>
              <hr>
            </template>

            <template #cell(deployed)="items">
              <div v-for="(job, index) in items.item.jobs" :key="index">
                {{ job.job_completed_count + job.job_suspended_count }}   
              </div>
              <hr>
              <div>Total: {{ deployedTotalForRow(items.item) }}</div>
            </template>


            </b-table>
          </div>
            <nav class="pagination-box custom_pagination_border_radius">
              <div class="page-row-box d-inline-block">
                <b-form-group id="input-group-4">
                  <span class="d-inline-block">Rows per page</span>
                  <span class="d-inline-block"
                    ><b-form-select v-model="form.rowsPerPage" @change="getListing">
                      <option>25</option>
                      <option>50</option>
                      <option>75</option>
                      <option>100</option>
                    </b-form-select></span>
                </b-form-group>
              </div>
              <div class="total-page-count d-inline-block">
                <ul>
                  <li>
                    <span v-if="totalRows != 0">{{ from }} - {{ to }} of {{ totalRows }}</span>
                  </li>
                </ul>
              </div>
              <b-pagination
                :total-rows="totalRows"
                :per-page="perPage"
                v-model="currentPage"
                prev-text=""
                next-text=""
                hide-goto-end-buttons
              />
            </nav>
          </b-col>
        <!-- </b-row> -->
    </div>
  </template>

<script>
import { POST_API } from '../../../store/actions.type'
import moment from 'moment'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import Datepicker from 'vuejs-datepicker';
import Multiselect from 'vue-multiselect'
import Chart from 'chart.js';
import permission from "../../../../../server/permission.js";

export default {

    data() {
        return {
            fields: [
        { key: 'date', label: 'Date' },
        { key: 'company', label: 'Companies' },
        { key: 'deployed', label: 'Deployed Number' }
      ],


            pager: {},
            pageOfItems: [],
            minDate: null,
            maxDate:null,
            center: 'center',

            staffDeployments: [],
            items:[],
            itemsGraph:[],
            filter: '',
            totalRows: 0,
            format: 'YYYY-MM-DD HH:mm',
            sortBy: null,
            sortDesc: true,
            sortDirection: 'desc',
            from: '',
            to: '',
            currentPage: 1,
            searchKeyword:'',
            perPage: 25,
            pageOptions: [25, 50, 75, 100],
            auto: true,
            range: false,
            dateRange: {
                startDate: '',
                endDate: ''
            },
            dateRange: {
                startDate: moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
                endDate: moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
            },
            locale: {
                direction: 'ltr', //direction of text
                format: 'DD-MMM-YYYY', //fomart of the dates displayed
                separator: ' - ', //separator between the two ranges
                applyLabel: 'Apply',
                cancelLabel: 'Cancel',
                /*weekLabel: 'W',
                customRangeLabel: 'Custom Range',*/
                daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
                monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
                firstDay: 0, //ISO first day of week - see moment documenations for details
                sameDateFormat: {
                    from: "DD MM YYYY, hh:mm",
                    to: "hh:mm",
                },
            },

            webUrl: process.env.VUE_APP_URL,
            webUrlV1: process.env.VUE_APP_URL_V1,

            currentTab:'total',
            totalDeployedArr:[],
            allDatesArr:[],
            form:{
                rowsPerPage:25,
            },
            total_uploaded:0,
            total_uploaded_past:0,
            webServerUrl: process.env.VUE_APP_SERVER_URL,
            designation:'',
            attachedCompanies:'',
            allStatusCompanies:[],
            selectedTime:[],
            selectedTimeUpcomingV1:[],
            job_start_time:null,
            job_start_time_upcoming_v1:null,
            allCompanies:[],
            allServiceCategory:[],
            allClosedBy:[],
            company_id:'',
            service_category_id: '',
            closed_by: '',
            graphPeriod: "month", 
            graphStartDate: "",
            graphEndDate: "",
            barChartJobData: {},
            verticalBarChartInstance: null,
            fieldsBreakdownDynamic:[],
            webUrlV1Admin: process.env.VUE_APP_URL_V1_Admin,

            chartData: {
                // labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                labels: [],

                datasets: [{
                label: 'Sales Data',
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
                // data: [65, 59, 80, 81, 56, 55, 40]
                data: []

                }]
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: false,
                    position: 'top', 
                    labels: {
                        boxWidth: 0, 
                    }
                },
                scales: {
                yAxes: [{
                    ticks: {
                    beginAtZero: true,
                    }
                }]
                }
            },
            sum_upcoming_v1 : 0,
            company_id_upcoming_v1:'all',
            closed_by_upcoming_v1:'all',
            actions:'',
            actionsEmployer:'',
            actionsManageDPJobs:''
        }
    },
    filters: {
        capitalize: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
        customFormatterOne(date) {
            return moment(date).utc().format('DD MMM YYYY hh:mm A');
        },

        removeUnderscore: function(value) {
            if (!value) return ''
            var i, frags = value.split('_');
            for (i=0; i<frags.length; i++) {
                frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
            }
            return frags.join(' ');
        },
        date: function(date) {
            if(date){
                return moment(date).format('DD MMM YYYY');
            }else{
                return '-'
            }
        },
        datePicker: function(value) {
            if (value == null) {
                return '';
            } else {
                return moment(value).format('DD MMM YYYY')
            }
        },
        momentDate: function(date) {
            if(date != null)
              return moment(date).format('DD-MM-YYYY')
            else
              return '-';
        },
        momentTime: function(date) {
            if(date != null)
              return moment(date, "HH:mm:ss").format("hh:mm A")
            else
              return '-';
        },
    },

    components: {
            DateRangePicker,
            Datepicker,
            Multiselect,
            Chart
    },
    computed: {
        totalDeployedPage() {
            return this.items.reduce((total, row) => {
            return total + this.deployedTotalForRow(row);
            }, 0);
        },
        defaultStartDate() {
            return moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
        },
        defaultEndDate() {
            return moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
        },
        showResetButton() {
            return (
            this.closed_by !== ""
            );
        },
    },
    methods:{
      exportData(){
          var base_url = this.webServerUrl;
          var start_date = this.dateRange.startDate ? moment(this.dateRange.startDate).format('YYYY-MM-DD') : 'null';
          var end_date = this.dateRange.endDate ? moment(this.dateRange.endDate).format('YYYY-MM-DD') : 'null';

          if(start_date == 'null' || end_date == 'null'){
              this.error_message = 'Please select date range.';
              this.showAlert();
          }else if(this.closed_by == ''){
              this.error_message = 'Please select sales.';
              this.showAlert();
          }else{
              var export_url = '/api/export-staff-deployment/'+this.closed_by+'/'+start_date+'/'+end_date;
              window.open(base_url+export_url,'_blank')
          }
      },
      handleSalesChange() {
        this.getListing().then(() => {
          this.$refs.datatable.refresh();
        });
        this.getGraphData();
      },
      resetFilter() {
        this.closed_by = '';
        this.getListing().then(() => {
        this.$refs.datatable.refresh();
        })
        this.getGraphData().then(() => {
            this.$refs.verticalBarChart.refresh();
        });
      },
        getAllClosedByByStatus(){
              return this.$store.dispatch(POST_API, {
                 data:{
                 },
                 api: '/api/daily-deployment-closedby-by-status'
              })
              .then(() => {
                  if (this.$store.getters.containsErrors) {
                      this.error_message = this.$store.getters.getErrors;
                      this.showAlert();
                      return [];
                  } else {
                      this.allClosedBy = this.$store.getters.getResults.data;
                  }
              });
        },
        deployedTotalForRow(row) {
            return row.jobs.reduce((sum, job) => {
            return sum + (Number(job.job_completed_count) + Number(job.job_suspended_count));
            }, 0);
        },
        formatDate(date) {
            return moment(date).format('DD-MM-YYYY');
        },
        showSuccessAlert(message) {
            this.$swal({
                position: 'center',
                icon: 'success',
                iconColor:'#4c8bf5',
                title: message,
                showConfirmButton: false,
                timer: 1500
            }).then((result) => {
                if(this.currentTab == 'total'){
                    this.getTotal().then(() => {
                        this.$refs.datatableTotal.refresh();
                    });
                }else if(this.currentTab == 'breakdown'){
                    this.getBreakdown()
                }else if(this.currentTab == 'upcoming'){
                    this.getUpcoming().then(() => {
                        this.company_id = '';
                    this.getAllCompaniesByStatus('upcoming');
                            // this.$refs.datatableUpcoming.refresh();
                        });
                }else if(this.currentTab == 'upcoming_v1'){
                    this.company_id_upcoming_v1 = 'all';
                    this.getAllCompaniesByStatus('upcoming_v1');
                    this.getUpcomingV1()
                }else{
                    this.getPast().then(() => {
                        this.company_id = '';
                this.getAllCompaniesByStatus('past');
                        // this.$refs.datatablePast.refresh();
                    });
                }
            });
        },
        dateFilterFunction(){
            this.getListing().then(() => {
            this.$refs.datatable.refresh();
          })
        },
        showAlert() {
            this.$swal({
                position: 'center',
                icon: 'error',
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            })
        },
        downloadPdf(url){
          window.open(url);
        },
        setGraphPeriod(period) {
          this.graphPeriod = period
          if (period === "month") {
            // This Month: first and last day of current month
            this.graphStartDate = moment().startOf("month").format("YYYY-MM-DD")
            this.graphEndDate = moment().endOf("month").format("YYYY-MM-DD")
            // this.graphStartDate = '2024-03-01';
            // this.graphEndDate = '2024-03-31';
          } else if (period === "year") {
            // This Year: first and last day of current year
            this.graphStartDate = moment().startOf("year").format("YYYY-MM-DD")
            this.graphEndDate = moment().endOf("year").format("YYYY-MM-DD")
          } else if (period === "last") {
            // Last Year: first and last day of last year
            this.graphStartDate = moment().subtract(1, "year").startOf("year").format("YYYY-MM-DD")
            this.graphEndDate = moment().subtract(1, "year").endOf("year").format("YYYY-MM-DD")
          }
          this.getGraphData()
        },
        getGraphData() {
          console.log(this.graphPeriod,'hello')
          const payload = {
            start_date: this.graphStartDate || moment().startOf("month").format("YYYY-MM-DD"),
            end_date: this.graphEndDate || moment().endOf("month").format("YYYY-MM-DD"),
            closed_by: this.closed_by || "", 
            type: this.graphPeriod === "month" ? "month" : "year"
          };
          console.log("Graph payload:", payload);
          return this.$store
            .dispatch(POST_API, {
              data: payload,
              api: "/api/get-graph-data"
            })
            .then(() => {
              if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                console.error("Error in graph API:", this.error_message);
                return;
              }
              const apiData = this.$store.getters.getResults.data || [];
              console.log("Graph API response:", apiData);

              let fullLabels = [];
              if (this.graphPeriod === "month") {
                let start = moment(payload.start_date, "YYYY-MM-DD");
                const end = moment(payload.end_date, "YYYY-MM-DD");
                while (start <= end) {
                  fullLabels.push(start.format("DD/MM"));
                  start.add(1, "days");
                }
              } else {
                let start = moment(payload.start_date, "YYYY-MM-DD");
                const end = moment(payload.end_date, "YYYY-MM-DD");
                while (start <= end) {
                  fullLabels.push(start.format("MMM-YY"));
                  start.add(1, "months");
                }
              }

              const dataMap = {};
              apiData.forEach(item => {
                let label;
                if (this.graphPeriod === "month") {
                  label = moment(item.period).format("DD/MM");
                } else {
                  label = moment(item.period).format("MMM-YY");
                }
                dataMap[label] = item.job_completed_count + item.job_suspended_count;
              });

              const finalDataPoints = fullLabels.map(label => dataMap[label] || 0);

              this.barChartJobData = {
                labels: fullLabels,
                datasets: [
                  {
                    label: "Total Deployed",
                    data: finalDataPoints,
                    backgroundColor: "#ef1aa7", 
                    borderColor: "#ef1aa7",
                    borderWidth: 1,
                    barPercentage: 0.6,
                    categoryPercentage: 0.6,
                    barThickness: 10,
                  },
                ],
              };
              const isMonth = this.graphPeriod === "month";
              const chartOptions = {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true
                      },
                      gridLines: {
                        color: "rgba(0, 0, 0, 0.1)",
                        zeroLineColor: "rgba(0, 0, 0, 0.1)",
                      },
                    },
                  ],
                  xAxes: [
                    {
                      gridLines: {
                        display: false, 
                      },
                      ticks: {
                       // minRotation: this.graphPeriod === "month" ? 45 : 0,
                       // maxRotation: this.graphPeriod === "month" ? 45 : 0,
                        minRotation: this.graphPeriod === "month" ? 45 : (["year", "last"].includes(this.graphPeriod) ? 45 : ""),
                        maxRotation: this.graphPeriod === "month" ? 45 : (["year", "last"].includes(this.graphPeriod) ? 45 : ""),
                        autoSkip: false, 
                        callback: function(value, index, values) {
                            // Show every alternate date on mobile
                            if (isMonth && window.innerWidth <= 768 && index % 2 !== 0) return '';  
                            return value; 
                          }
                        },
                    },
                  ],
                },
                layout:{
                  padding:{
                    top:20
                  }
                },
                legend: {
                  display: false,
                },
                tooltips: {
                  callbacks: {
                    label: function(tooltipItem, data) {
                      return data.datasets[0].label + ": " + tooltipItem.yLabel;
                    },
                  },
                },
                animation: {
                  onComplete: function () {
                    const chartInstance = this.chart;
                    const ctx = chartInstance.ctx;
                    ctx.font = Chart.helpers.fontString(
                      12,
                      "bold",
                      Chart.defaults.global.defaultFontFamily
                    );
                    ctx.fillStyle = "#666";
                    ctx.textAlign = "center";
                    ctx.textBaseline = "bottom";
                    this.data.datasets.forEach(function (dataset, i) {
                      const meta = chartInstance.controller.getDatasetMeta(i);
                      meta.data.forEach(function (bar, index) {
                        const value = dataset.data[index];
                        if (value !== 0) {
                          const xPos = bar._model.x;
                          const yPos = bar._model.y - 5;
                          ctx.fillText(value, xPos, yPos);
                        }
                      });
                    });
                  },
                },
              };

              if (!this.$refs.verticalBarChart) {
                console.error("verticalBarChart canvas element not found");
                return;
              }
              const ctx = this.$refs.verticalBarChart.getContext("2d");

              if (this.verticalBarChartInstance) {
                this.verticalBarChartInstance.destroy();
              }

              this.verticalBarChartInstance = new Chart(ctx, {
                type: "bar",
                data: this.barChartJobData,
                options: chartOptions,
              });
            })
            .catch(err => {
              console.error("Error fetching graph data:", err);
            });
        },

         getListing() {
            return this.$store.dispatch(POST_API, {
                data:{
                    start_date:this.dateRange.startDate? moment(this.dateRange.startDate).format('YYYY-MM-DD') : '2025-01-01',
                    end_date:this.dateRange.endDate? moment(this.dateRange.endDate).format('YYYY-MM-DD') : '2025-12-31',
                    page: this.currentPage,
                    rowsPerPage: this.form.rowsPerPage,
                    closed_by: this.closed_by || '',
                },
                api: '/api/staff-deployment'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    // this.showAlert();
                    return [];
                } else {
                    this.items = this.$store.getters.getResults.data.pageOfItems;
                    this.totalRows = this.$store.getters.getResults.data.pager.totalItems;
                    this.perPage = this.$store.getters.getResults.data.pager.pageSize;
                    this.from = this.$store.getters.getResults.data.pager.from;
                    this.to = this.$store.getters.getResults.data.pager.to;
                    return this.items;
                }
            });
        },
        permissionStaff(){
            if(this.$store.getters.getCurrentUserPermission){
              if (this.$store.getters.getCurrentUserPermission.data.length > 0 && this.$store.getters.currentUser.user_type_id == 2) {
               var menu = this.$store.getters.getCurrentUserPermission.data;
               this.actions = permission.getPermissionStaff(menu,'Staff Deployment');
               this.actionsEmployer = permission.getPermissionStaff(menu,'Companies');
               this.actionsManageDPJobs = permission.getPermissionStaff(menu,'DP Jobs Admin');

              }
            }
        },
        show(action){
            if (this.$store.getters.currentUser.user_type_id == 2) {
                return this.actions.indexOf(action) >= 0 ? true : false ;
            }else{
                return true;
            }
        },
        showEmployer(action){
            if (this.$store.getters.currentUser.user_type_id == 2) {
                return this.actionsEmployer.indexOf(action) >= 0 ? true : false ;
            }else{
                return true;
            }
        },
    },
     mounted(){
            $(".show_filter_arrow .table thead th div").contents().unwrap();
            $(".dashboard-content-container .simplebar-scroll-content").animate({
            scrollTop: 0
            }, "fast");
            //document.title = "EL Connect - Jobseeker - Transaction";
            this.user_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
            this.user_type_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.user_type_id : '');

            this.designation = this.$store.getters.currentUser.designation ? this.$store.getters.currentUser.designation.toLowerCase() : '';
            this.attachedCompanies = (this.$store.getters.currentUser.attached_with_company != null ? this.$store.getters.currentUser.attached_with_company : 'null') ;
            // this.getAllServiceCategoryByStatus();
            this.permissionStaff();
            this.getAllClosedByByStatus();
            this.setGraphPeriod("month")
            this.getGraphData()


    }
}
</script>
