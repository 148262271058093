<template>
  <div class="animated fadeIn create_footer desktop_padding_bottom_zero">
    <div class="create-new-job-section email-template-section">
      <!-- <div class="black-strip-head d-lg-flex d-sm-flex align-items-center justify-content-between">
                <h1 class="header_detail_new_h1"><a class="back-arrow" href="javascript:history.back(1);"></a>Edit Certificate</h1>
            </div> -->
      <!-- <div class="dashboard-headline">
                <h1><span class="icon-material-outline-arrow-back new_arrow_back" @click="$router.go(-1)"></span>Edit</h1>
            </div> -->
      <div id="breadcrumb-v2" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb :items="breadCrumb()" />
        </div>
      </div>
      <div class="edit-profile-section border-radius-4 white-bg common_padding_30">
        <div class="submit-field mobile_bottom_two_eight">
          <h5>Select Email <span class="required_star">*</span></h5>
          <b-form-select
            v-model="form.broadcast_notification_email"
            id="nationality"
            class="custom-select web_bottom_zero"
          >
            <option value="" disabled>Select</option>
            <option
              :value="notificationEmail.id"
              v-for="(notificationEmail, id) in notificationEmails"
              :key="id"
            >
              {{ notificationEmail.email }}
            </option>
          </b-form-select>
        </div>
        <b-form @submit="formSubmit">
          <b-col lg="0" md="0" class="submit-field">
            <div class="single_accordian container broadcast_faq_new">
              <div class="faq-content-box clearfix">
                <div>
                  <div class="accordion-listing-box">
                    <b-card no-body>
                      <b-card-header header-tag="header" role="tab">
                        <b-button v-b-toggle="'accordion-' + key" class="m-1" variant="info">
                          <div class="dashboard-box">
                            <div class="headline">
                              <h3>Optional Filters</h3>
                            </div>
                          </div>
                        </b-button>
                      </b-card-header>
                      <b-collapse :id="'accordion-' + key" accordion="my-accordion" role="tabpanel">
                        <b-card-body>
                          <div class="content with-padding">
                            <div class="row">
                              <b-col lg="6" md="6">
                                <!-- <b-form-group id="input-group-8" label="Company Name"> -->
                                <div class="submit-field">
                                  <h5>Company Name</h5>
                                  <multiselect
                                    v-model="company"
                                    id="ajax"
                                    label="name"
                                    track-by="id"
                                    placeholder="Type to search company name"
                                    open-direction="bottom"
                                    :options="selectedCompany"
                                    :multiple="false"
                                    :searchable="true"
                                    :clear-on-select="true"
                                    :close-on-select="true"
                                    :value="companyName"
                                    :allow-empty="true"
                                    @input="dateFilter()"
                                    class="new_multi_slec_list job_list_multi_select multiselect_border"
                                  >
                                    <span slot="noResult">No Company Found.</span>
                                  </multiselect>
                                </div>
                                <!-- </b-form-group> -->
                              </b-col>
                              <b-col lg="6" md="6">
                                <!-- <b-form-group id="input-group-8" label="Start Time"> -->
                                <div class="submit-field">
                                  <h5>Start Time</h5>
                                  <multiselect
                                    v-model="address"
                                    id="ajax"
                                    label="address"
                                    track-by="id"
                                    placeholder="Type to search address"
                                    open-direction="bottom"
                                    :multiple="true"
                                    :options="selectedAddress"
                                    :searchable="true"
                                    :clear-on-select="false"
                                    :close-on-select="false"
                                    :allow-empty="true"
                                    @close="dateFilter()"
                                    class="new_multi_slec_list job_list_multi_select multiselect_border"
                                  >
                                    <span slot="noResult">No Address Found.</span>
                                  </multiselect>
                                </div>
                                <!-- </b-form-group> -->
                              </b-col>
                              <b-col lg="6" md="6">
                                <!-- <b-form-group id="input-group-8" label="Address"> -->
                                <div class="submit-field">
                                  <h5>Address</h5>
                                  <multiselect
                                    v-model="address"
                                    id="ajax"
                                    label="address"
                                    track-by="id"
                                    placeholder="Type to search address"
                                    open-direction="bottom"
                                    :multiple="true"
                                    :options="selectedAddress"
                                    :searchable="true"
                                    :clear-on-select="false"
                                    :close-on-select="false"
                                    :allow-empty="true"
                                    @close="dateFilter()"
                                    class="new_multi_slec_list job_list_multi_select multiselect_border"
                                  >
                                    <span slot="noResult">No Address Found.</span>
                                  </multiselect>
                                </div>
                                <!-- </b-form-group> -->
                              </b-col>
                              <b-col lg="6" md="6">
                                <!-- <b-form-group id="input-group-8" label="Weekday / Weekend"> -->
                                <div class="submit-field">
                                  <h5>Weekday / Weekend</h5>
                                  <multiselect
                                    v-model="day"
                                    placeholder="Type to search"
                                    open-direction="bottom"
                                    :multiple="false"
                                    :options="selectedDay"
                                    :searchable="true"
                                    :clear-on-select="false"
                                    :close-on-select="false"
                                    :allow-empty="true"
                                    @close="dateFilter()"
                                    class="new_multi_slec_list job_list_multi_select multiselect_border"
                                  >
                                    <span slot="noResult">No Weekday / Weekend Found.</span>
                                  </multiselect>
                                </div>
                                <!-- </b-form-group> -->
                              </b-col>
                              <b-col lg="12" md="12" class="filter_broadcast">
                                <b-button
                                  type="button"
                                  variant="success"
                                  @click="dateFilter()"
                                  class="button desktop_margin_left_zero"
                                  >Filter</b-button
                                >
                                <b-button
                                  variant="light"
                                  class="desktop_margin_left_zero"
                                  @click="resetFilter()"
                                  >Reset</b-button
                                >
                              </b-col>
                            </div>
                          </div>
                        </b-card-body>
                      </b-collapse>
                    </b-card>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
          <div class="row">
            <b-col
              lg="6"
              md="6"
              sm="12"
              class="z_index_zero submit-field notification_radio common_radio_and_checkbox_color"
            >
              <h5 class="required">
                Notification Type<span class="required" style="color: red">*</span>
              </h5>
              <b-form-group
                id="input-group-8"
                label=""
                class="category-radio common_fix_radio desktop_margin_bottom_zero"
              >
                <b-form-radio
                  name="platform"
                  value="push"
                  v-model="form.type"
                  @change="changePlatform('push')"
                  >Push Notification</b-form-radio
                >
                <b-form-radio
                  name="platform"
                  value="email"
                  v-model="form.type"
                  @change="changePlatform('email')"
                  >Email Notification</b-form-radio
                >
              </b-form-group>
            </b-col>
            <b-col lg="6" md="6" sm="12" v-if="is_push" class="submit-field">
              <h5 class="required">Platform<span class="required" style="color: red">*</span></h5>

              <b-form-group class="desktop_margin_bottom_zero notification_check_box">
                <b-form-checkbox-group
                  id="checkbox-group-2"
                  v-model="form.platform"
                  name="flavour-2"
                >
                  <b-form-checkbox value="android" class="opacity_unset">Android</b-form-checkbox>
                  <b-form-checkbox value="ios" class="opacity_unset">IOS</b-form-checkbox>
                </b-form-checkbox-group>
              </b-form-group>
            </b-col>
          </div>
          <b-col lg="0" md="0" sm="0" class="submit-field">
            <!-- <p class="required mt-2 mb-2" >Job ID</p> -->

            <!-- <b-form-group id="input-group-8" label="Job ID" class="z_index_notification"> -->
            <h5>Job ID</h5>
            <div class="custom-dropdown">
              <!-- <b-form-select v-model="form.job_id" >
                                        <option value="" disabled>Please Select Job ID</option>
                                        <option :value="job.id" v-for="job in jobs">{{job.title}} ({{job.job_unique_id}})</option>
                                    </b-form-select> -->
              <multiselect
                v-model="form.job_id"
                id="ajax"
                label="title"
                track-by="id"
                @input="
                  onChangeJobData(form.job_id.ti, form.job_id.job_scope, form.job_id.hourly_cost)
                "
                placeholder="Type to search job id"
                open-direction="bottom"
                :options="jobs"
                :multiple="false"
                :searchable="true"
                :clear-on-select="true"
                :close-on-select="true"
                class="new_multi_slec_list job_list_multi_select multiselect_border job_id_noti"
              >
                <span slot="noResult"><!-- No User Found -->There are no users.</span>
              </multiselect>
            </div>
            <!-- </b-form-group> -->
          </b-col>
          <!-- <div class="edit-form-box form-box-field desktop_margin_top_zero"> -->
          <b-col lg="0" md="0" sm="0" class="submit-field common_input_field">
            <!-- <b-form-group id="input-group-5" label="Title" class="required"> -->
            <h5>Title</h5>
            <b-form-input
              id="input-1"
              type="text"
              placeholder=""
              v-model="form.title"
              class="input-text with-border"
            ></b-form-input>
            <!-- </b-form-group> -->
          </b-col>
          <b-col lg="0" md="0" class="submit-field">
            <!-- <b-form-group id="input-group-5" label="Description" class="required"> -->
            <h5>Description</h5>
            <b-form-textarea
              id="input-5"
              type="text"
              placeholder=""
              v-model="form.description"
              v-if="form.type != 'email'"
              class="notification_textarea"
            ></b-form-textarea>

            <ckeditor
              v-model="form.description"
              :config="editorConfig"
              v-if="form.type == 'email'"
            ></ckeditor>
            <!-- </b-form-group> -->
          </b-col>
          <b-col
            lg="0"
            md="0"
            sm="0"
            class="submit-field notification_radio common_radio_and_checkbox_color"
          >
            <h5 class="required">
              Selected User<span class="required" style="color: red">*</span>
            </h5>

            <b-form-group id="input-group-8" label="" class="category-radio common_fix_radio">
              <b-form-radio
                name="selected"
                value="active"
                v-model="form.selected"
                @change="changeSelect('active')"
                >Active Users <b>{{ count }}</b></b-form-radio
              >
              <b-form-radio
                name="selected"
                value="selected"
                v-model="form.selected"
                @change="changeSelect('selected')"
                >Selective Users</b-form-radio
              >
              <b-form-radio
                name="selected"
                value="all"
                v-model="form.selected"
                @change="changeSelect('all')"
                >All Completed Users <b>{{ user_count }}</b></b-form-radio
              >
              <b-form-radio
                name="selected"
                value="incomplete"
                v-model="form.selected"
                @change="changeSelect('incomplete')"
                >All Incomplete Users <b>{{ inc_user_count }}</b></b-form-radio
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox-group
                id="checkbox-group-2"
                v-model="form.user_type"
                name="flavour-2"
                @change="filterUser($event)"
                :disabled="user_type"
                class="notification_check_box"
              >
                <b-form-checkbox v-if="is_push == false" value="2" class="opacity_unset"
                  >ELC Admin</b-form-checkbox
                >
                <b-form-checkbox value="3" class="opacity_unset">Company Admin</b-form-checkbox>
                <b-form-checkbox value="8" class="opacity_unset">OM</b-form-checkbox>
                <b-form-checkbox value="4" class="opacity_unset">Supervisor</b-form-checkbox>
                <b-form-checkbox value="5" class="opacity_unset">Freelancer</b-form-checkbox>
                <b-form-checkbox value="6" class="opacity_unset">Individual</b-form-checkbox>
                <b-form-checkbox value="7" class="opacity_unset">Full Time FL</b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>
          </b-col>
          <div class="row">
            <b-col lg="5" md="5" sm="5" class="submit-field">
              <multiselect
                v-model="form.selected_user"
                id="ajax"
                label="full_name"
                track-by="id"
                placeholder="Type to search user"
                open-direction="bottom"
                :options="users"
                :multiple="true"
                :searchable="true"
                :clear-on-select="true"
                :close-on-select="true"
                :disabled="multiselect"
                class="new_multi_slec_list job_list_multi_select multiselect_border"
              >
                <span slot="noResult"><!-- No User Found -->There are no users.</span>
              </multiselect>
            </b-col>
          </div>
          <!-- <br>
                            <b-row>
                        <b-col lg="12" md="12">
                                <p class="red-text"><strong>Optional Filters</strong></p>
                                <hr>
                        </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="3" md="3">
                            <b-form-group id="input-group-8" label="Company Name">
                                <multiselect v-model="company" id="ajax" label="name" track-by="id" placeholder="Type to search company name" open-direction="bottom" :options="selectedCompany" :multiple="false"  :searchable="true" :clear-on-select="true" :close-on-select="true" :value="companyName" :allow-empty="true" @input="dateFilter()">
                                <span slot="noResult">No Company Found.</span>
                                </multiselect>
                            </b-form-group>
                            </b-col>
                            <b-col lg="3" md="3">
                            <b-form-group id="input-group-8" label="Address">
                                <multiselect v-model="address" id="ajax" label="address" track-by="id"  placeholder="Type to search address" open-direction="bottom" :multiple="true" :options="selectedAddress"  :searchable="true" :clear-on-select="false" :close-on-select="false"
                                :allow-empty="true" @close="dateFilter()">
                                    <span slot="noResult">No Address Found.</span>
                                </multiselect>
                            </b-form-group>
                            </b-col>
                            <b-col lg="3" md="3">
                            <b-form-group id="input-group-8" label="Start Time">
                                <multiselect v-model="time" id="ajax" label="start_time" track-by="id"  placeholder="Type to search start time" open-direction="bottom" :multiple="true" :options="selectedTime"  :searchable="true" :clear-on-select="false" :close-on-select="false"
                                :allow-empty="true" @close="dateFilter()">
                                    <span slot="noResult">No Start Time Found.</span>
                                </multiselect>
                            </b-form-group>
                            </b-col>
                            <b-col lg="3" md="3">
                            <b-form-group id="input-group-8" label="Weekday / Weekend">
                                <multiselect v-model="day"  placeholder="Type to search" open-direction="bottom" :multiple="false" :options="selectedDay"  :searchable="true" :clear-on-select="false" :close-on-select="false"
                                :allow-empty="true" @close="dateFilter()">
                                    <span slot="noResult">No Weekday / Weekend Found.</span>
                                </multiselect>
                            </b-form-group>
                            </b-col>
                            <b-col lg="12" md="12">
                                <b-button type="button" variant="success" @click="dateFilter()" class="desktop_margin_left_zero">Filter</b-button>
                                <b-button variant="light" class="desktop_margin_left_zero" @click="resetFilter()">Reset</b-button>
                            </b-col>

                        </b-row> -->
          <!-- <hr> -->

          <b-col lg="0" md="0" sm="0" class="">
            <div class="select-service-box date-picker-field clearfix">
              <b-row v-for="(app, index) in append" :key="index">
                <b-col
                  lg="5"
                  md="5"
                  sm="12"
                  class="submit-field mobile_max_widht_and_flex common_input_field"
                >
                  <!-- <b-form-group id="input-group-6" label="Schedule For Date:" class="append-date" > -->
                  <h5>Schedule For Date</h5>
                  <!-- <date-range-picker ref="picker" :timePicker="timePicker" :single-date-picker="singlePicker" :timePicker24Hour="timePicker24Hour" :minDate="minDate" :opens="center" :locale-data="locale" :auto-apply="auto" v-model="app.date" :ranges='range'>
                                                <div slot="input" slot-scope="picker">
                                                    {{ app.date != '' ? picker.startDate : '' | date }}
                                                </div>
                                            </date-range-picker>

                                            <span class="input-icon">
                                                <b-img v-bind:src="webUrl+'/images/calendar-grey-icon02.svg'" alt="Calander Icon"></b-img>
                                            </span> -->
                  <b-form-input
                    id="input-1"
                    type="date"
                    placeholder=""
                    v-model="app.date"
                  ></b-form-input>
                  <!-- </b-form-group> -->
                </b-col>
                <b-col
                  lg="5"
                  md="5"
                  sm="12"
                  class="submit-field mobile_max_widht_and_flex start_time_new common_input_field"
                >
                  <!-- <b-form-group id="input-group-6" label="Schedule For Time:"  class="append-date new_cross_icon_notification">                                         -->
                  <h5>Schedule For Time</h5>
                  <!-- <b-form-input id="input-1" type="time" placeholder="" v-model="app.time" value="2013-01-08"></b-form-input> -->
                  <vue-timepicker
                    format="hh:mm A"
                    v-model="app.time"
                    class="common_time_picker desktop_margin_bottom_zero"
                  ></vue-timepicker>
                  <!-- <span class="input-icon"><b-img v-bind:src="webUrl+ '/images/clock-grey-icon02.svg'" alt="Clock Icon"></b-img></span> -->
                  <!-- </b-form-group> -->
                </b-col>
                <!-- <b-col lg="2" md="2" sm="12" class="submit-field red_div_notification">
                                        <b-form-group id="input-group-6" label="" class="desktop_margin_bottom_zero">
                                            <h5 class="notification_opacity_zero">Schedule</h5>
                                            <a href="javascript:void(0);" v-if="index != 0" @click="removeRow(index)" class="add-remove-btn add_more_notification_btn"><span class="add_plus material-icons-outlined red_icon">remove_circle_outlined</span></a>
                                            <a href="javascript:void(0);"  v-if="index == 0" @click="appendRow" class="add-remove-btn remove_more_notification_btn"><span class="add_plus material-icons-outlined">add_circle_outlined</span></a>
                                        </b-form-group>

                                    </b-col> -->
              </b-row>
            </div>
          </b-col>

          <b-row>
            <div class="col-md-12 col-sm-12 col-xl-12">
              <div slot="footer" class="form-btn mt-3 desktop_margin_top_zero">
                <b-button
                  type="submit"
                  variant="success"
                  class="submit_btn_noti button desktop_margin_left_zero"
                  >Submit</b-button
                >
                <b-button
                  :to="{ name: 'broadcast-notification-list' }"
                  variant="light"
                  class="cancel_btn"
                  >Cancel</b-button
                >
              </div>
            </div>

            <!-- <b-col lg="4" md="4">
                                <span>Current Notification Count: <b>{{current_notification_count}}</b></span>
                            </b-col> -->
          </b-row>

          <!-- </div> -->
        </b-form>
      </div>
    </div>
  </div>
</template>
<script>
import { POST_API } from "../../../store/actions.type";
import moment from "moment";
import Multiselect from "vue-multiselect";
import CKEditor from "ckeditor4-vue";
import VueTimepicker from "vuejs-timepicker";
import Datepicker from "vuejs-datepicker";
export default {
  data() {
    return {
      form: {
        title: "",
        description: "",
        platform: [],
        type: "",
        user_type: [],
        selected: "",
        selected_user: [],
        job_id: "",
        broadcast_notification_email: [],
      },
      items: null,
      // id: Vue.prototype.decrypt(this.$route.params.id),
      id: atob(this.$route.params.id),
      error_message: "",
      selectedImage: "",
      success_message: "",
      dismissSecs: 3,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      delta: undefined,
      users: [],
      jobs: [],
      company: "",
      address: "",
      time: "",
      day: "",
      selectedAddress: [],
      selectedDay: ["weekday", "weekend", "all"],
      selectedTime: [],
      selectedCompany: [],

      actions: "",
      user_type_id: "",
      multiselect: true,
      platformArray: [{ title: "android" }, { title: "ios" }, { title: "all" }, { title: "users" }],
      is_push: false,
      user_type: true,
      editorConfig: {
        versionCheck: false,
        removeButtons: "Maximize",
        extraPlugins: "copyformatting,colorbutton,bidi,colordialog,widget,justify",
        allowedContent: true,
      },
      count: "",
      user_count: "",
      inc_user_count: "",

      noti: [],
      auto: false,
      center: "right",
      minDate: null,
      locale: {
        direction: "ltr", //direction of text
        format: "dd-mm-yyyy  HH:00:00", //fomart of the dates displayed
        separator: " - ", //separator between the two ranges
        applyLabel: "Apply",
        cancelLabel: "Cancel",
        /*weekLabel: 'W',
                customRangeLabel: 'Custom Range',*/
        daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
        monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
        firstDay: 0, //ISO first day of week - see moment documenations for details
        sameDateFormat: {
          from: "DD MM YYYY, hh:mm",
          to: "hh:mm",
        },
      },
      webUrl: process.env.VUE_APP_URL,
      timePicker24Hour: true,
      timePicker: true,
      dateInput: {
        placeholder: "Select Date",
      },
      calendarTimeInput: {
        readonly: true,
        step: 30,
        inputClass: "my_custom_class",
      },
      range: false,
      singlePicker: true,
      dateRange: {
        startDate: "",
      },
      blockRemoval: true,
      append: [
        {
          time: {
            hh: "",
            mm: "",
            A: "",
          },
          date: "",
        },
      ],
      defaultDate: null,
      companyName: "",
      employers: [],
      key: "",
      notificationEmails: [],
    };
  },
  components: {
    Multiselect,
    ckeditor: CKEditor.component,
    VueTimepicker,
    Datepicker,
  },
  methods: {
    breadCrumb() {
      var item = [
        {
          to: { name: "broadcast-notification-list" },
          text: "Broadcast Notification",
        },
        {
          to: null,
          text: "Edit",
        },
      ];
      return item;
    },
    getEditData() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            status: this.status,
            page: this.currentPage,
            keyword: this.filter,
            id: this.id,
          },
          api: "/api/admin-get-duplicate-notification",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            return [];
          } else {
            this.noti = this.$store.getters.getResults.data;
            (this.form.title = this.noti.title),
              (this.form.type = this.noti.notification_type),
              this.changePlatform("push");
            // this.form.platform = ['android', 'ios'];
            this.form.platform =
              this.noti.platform == "all"
                ? ["android", "ios"]
                : this.noti.platform == "android"
                ? (this.noti.platform = ["android"])
                : this.noti.platform == "ios"
                ? (this.noti.platform = ["ios"])
                : (this.noti.platform = []);

            this.form.description = this.noti.description;
            this.form.broadcast_notification_email = this.noti.email_id;

            this.getJobById(this.noti.job_id);

            // this.dateRange.startDate = this.noti.scheduled_start_date != null ? moment(this.noti.scheduled_start_date).toDate() : '';

            if (this.noti.scheduled_start_date != null) {
              var scheduled_time = new Date(this.noti.scheduled_start_date);
              console.log(
                moment(this.noti.scheduled_start_date).format("YYYY-MM-DD"),
                scheduled_time.getUTCHours(),
                scheduled_time.getUTCMinutes()
              );

              let h = scheduled_time.getUTCHours();
              let m = scheduled_time.getUTCMinutes();
              let ampm = h >= 12 ? "PM" : "AM";
              h = h % 12; //reminder
              h = h ? h : 12;
              // var ampm = scheduled_time.getHours() < 12 ? 'AM' : 'PM';
              this.append = [
                {
                  time: {
                    // hh: String(scheduled_time.getHours()).padStart(2, '0'),
                    // hh: h < 10 ? '0'+h : h,
                    hh: h.toString().padStart(2, "0"),
                    // mm: String(scheduled_time.getMinutes()).padStart(2, '0'),
                    mm: m.toString().padStart(2, "0"),
                    A: ampm,
                  },
                  date:
                    this.noti.scheduled_start_date != null
                      ? moment(this.noti.scheduled_start_date).format("YYYY-MM-DD")
                      : "",
                },
              ];
              console.log(
                moment(this.noti.scheduled_start_date).format("YYYY-MM-DD"),
                h + ":" + m + " " + ampm
              );
            }
          }
        });
    },

    showAlert() {
      this.$swal({
        position: "center",
        icon: "error",
        title: this.error_message,
        showConfirmButton: false,
        timer: 1500,
      });
    },
    formSubmit(evt) {
      evt.preventDefault();
      var err = false;
      const jobId =
        Array.isArray(this.form.job_id) && this.form.job_id.length > 0
          ? this.form.job_id[0].id
          : this.form.job_id && this.form.job_id.id
          ? this.form.job_id.id
          : "";

      console.log(jobId);
      console.log(this.append[0], "this.append.time");
      // if(this.append[0].date == ''){
      //     err = true;
      //     this.error_message = 'Schedule For Date is required';
      //     this.showAlert();

      // }
      // if(this.append[0].date && (this.append[0].time.hh == '' || this.append[0].time.mm == '' || this.append[0].time.A == '')){
      //     err = true;
      //     this.error_message = 'Schedule For Time is required';
      //     this.showAlert();

      // }

      if (err == false) {
        this.$store
          .dispatch(POST_API, {
            data: {
              title: this.form.title,
              description: this.form.description,
              platform: this.form.platform,
              email_id: this.form.broadcast_notification_email,
              type: this.form.type,
              user_type: this.form.user_type,
              selected: this.form.selected,
              selected_user: this.form.selected_user,
              users: this.users,
              // job_id:(this.form.job_id != null ? this.form.job_id.id ? this.form.job_id.id : this.form.job_id[0].id : ''),
              // job_id:(this.form.job_id ? this.form.job_id.id ? this.form.job_id.id : this.form.job_id[0].id : ''),
              job_id: jobId,

              date: this.append,
              notification_id: this.id,
            },
            api: "/api/admin-update-bulk-notification",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showAlert();
            } else {
              this.form.title = "";
              this.form.description = "";
              this.form.platform = [];
              this.form.type = "";
              this.form.user_type = ["5"];
              this.user_type = true;
              this.form.selected = "";
              this.form.selected_user = [];
              this.users = [];
              this.form.job_id = "";
              this.append = [];
              this.appendRow();
              var msg = "Notification Updated Successfully";
              this.showSuccessAlert();
            }
          });
      }
    },

    showSuccessAlert() {
      this.$swal({
        position: "center",
        icon: "success",
        iconColor: "#4c8bf5",
        title: "Notification Updated Successfully.",
        showConfirmButton: false,
        timer: 1500,
      }).then((result) => {
        this.$router.push({ name: "broadcast-notification-list" });
      });
    },

    getJobById(id) {
      // console.log(moment().subtract(16, 'years').format('DD-MM-YYYY'));
      return this.$store
        .dispatch(POST_API, {
          data: {
            job_id: id,
          },
          api: "/api/notification-upcoming-jobs-byId",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.selectedCompany = this.$store.getters.getResults.data;
            this.form.job_id = this.$store.getters.getResults.data;
          }
        });
    },
    getCompanyList() {
      // console.log(moment().subtract(16, 'years').format('DD-MM-YYYY'));
      return this.$store
        .dispatch(POST_API, {
          data: {},
          api: "/api/notification-company-listing",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.selectedCompany = this.$store.getters.getResults.data;
            return this.selectedCompany;
          }
        });
    },
    getUserListing(user_list = null) {
      this.$store
        .dispatch(POST_API, {
          data: {
            user_type_id: this.form.user_type != "" ? this.form.user_type : [],
            platform: this.form.platform,
            user_list: user_list,
          },
          api: "/api/notification-user-list",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.users = this.$store.getters.getResults.data;

            if (this.form.selected == "active") {
              this.count = this.$store.getters.getResults.count;
              this.count = "(" + this.count + ")";
              this.user_count = "";
              this.inc_user_count = "";
            } else if (this.form.selected == "all") {
              this.user_count = this.$store.getters.getResults.count;
              this.user_count = "(" + this.user_count + ")";
              this.count = "";
              this.inc_user_count = "";
            } else if (this.form.selected == "incomplete") {
              this.inc_user_count = this.$store.getters.getResults.count;
              this.inc_user_count = "(" + this.inc_user_count + ")";
              this.count = "";
              this.user_count = "";
            } else {
              this.count = "";
              this.user_count = "";
              this.inc_user_count = "";
            }
          }
        });
    },
    dateFilter() {
      this.getJobsListing();
    },
    resetFilter() {
      this.company = "";
      this.remarks = "";
      this.time = "";
      this.address = "";
      this.day = "";
      this.dateFilter();
    },
    getJobsListing() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            id: this.company ? [this.company.id] : null,
            type: "multiple",
            time: this.time ? this.time.map((obj) => obj.start_time) : null,
            address: this.address ? this.address.map((obj) => obj.address) : null,
            day_type: this.day ? this.day : null,
          },
          api: "/api/notification-upcoming-jobs",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.jobs = this.$store.getters.getResults.data;
            this.selectedTime = this.jobs.filter((job, index, self) => {
              return index === self.findIndex((j) => j.start_time === job.start_time);
            });
            this.selectedAddress = this.jobs.filter((job, index, self) => {
              return index === self.findIndex((j) => j.address === job.address);
            });

            return this.jobs;
          }
        });
    },
    onChangeJobData(title, description, hourly_cost) {
      this.form.title = hourly_cost + "/HR " + title;
      this.form.description = description;
    },
    changePlatform(value) {
      this.form.platform = [];
      // this.form.description = null;
      this.is_push = value == "push" ? true : false;
    },
    changeSelect(value) {
      this.user_type = false;
      this.user_count = "";
      this.count = "";
      this.users = [];

      this.form.selected_user = [];
      // if (value == 'all') {
      //     this.multiselect = true;
      //     // this.form.user_type = ['2','3','4','5','6'];
      //     this.form.user_type = [];

      // }else{
      this.multiselect = false;
      if (value == "selected") {
        this.form.user_type = [];
        this.getUserListing(value);
      } else {
        this.form.user_type = ["5"];
        this.getUserListing(value);
      }

      // }
    },
    filterUser(event) {
      this.form.user_type = event;
      this.users = [];

      this.getUserListing(this.form.selected);
    },
    appendRow() {
      this.append.push({
        time: {
          hh: moment().format("hh"),
          mm: moment().format("mm"),
          A: moment().format("A"),
        },
        date: moment().format("YYYY-MM-DD"),
      });
    },
    removeRow(lineId) {
      if (this.blockRemoval) {
        this.append.splice(lineId, 1);
      }
    },
    getNotificationEmails() {
      return this.$store
        .dispatch(POST_API, {
          api: "/api/broadcast-notification-active-email",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.notificationEmails = this.$store.getters.getResults.data;
          }
        });
    },
  },
  mounted() {
    $(".dashboard-content-container .simplebar-scroll-content").animate(
      {
        scrollTop: 0,
      },
      "fast"
    );
    //document.title = "EL Connect - Admin - Database - Edit Certificate";
    this.getNotificationEmails();
    this.getJobsListing();
    this.getCompanyList();
    this.getEditData();

    // this.changeSelect('active')
  },
};
</script>
