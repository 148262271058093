import { render, staticRenderFns } from "./TermsConditionsEmp.vue?vue&type=template&id=87e2efce&"
import script from "./TermsConditionsEmp.vue?vue&type=script&lang=js&"
export * from "./TermsConditionsEmp.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports