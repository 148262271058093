
<template>
    <div>
        <div>
            <div class="dashboard-content-inner my_custom_dash" >
                <!-- Dashboard Headline -->
                <div class="dashboard-headline">
                    <h1>Manage Jobs</h1>
                    <!-- Breadcrumbs -->
                    <nav id="breadcrumbs" class="dark employer_nav">
                        <ul>
                            <li><a href="#">Home</a></li>
                            <li><a href="#">Job</a></li>
                            <li>Manage Jobs</li>
                        </ul>
                    </nav>
                </div>
                <!-- Row -->
            <div class="row emplpyees_manage_job_emp_row" >
                <!-- Dashboard Box -->
                <div class="col-xl-12 mobile_left_right_zero_pdng">
                    <div class="dashboard-box margin-top-0 daily_dashboard_box">
                        <!-- Headline -->
                        <!--<div class="headline">
                            <h3><i class="icon-material-outline-business-center"></i> My Job Listings</h3>
                        </div>-->
                            <!-- <div v-if="loading" >
                        <div class = 'loader'>
                        </div> -->

                        <!-- here put a spinner or whatever you want to indicate that a request is in progress -->
                <!-- </div> -->
                    <div class="upper_mix_functiobality">
                        <div class="desktop_flex">
                        <div class="col-md-3 search_div_daily_job">
                        <div class="search-box custom_search_admin new_custom_search_admin_remove">
                            <div role="group" class="input-group">
                                <!---->
                                <div class="input-group-prepend">
                                    <input id="searchBtn" type="text" placeholder="Search by keyword" v-model="filterSearch" v-on:keyup.enter='getList(activeTab,activeApplicantTab,filterSearch)' class="form-control">
                                    <button type="button" @click="getList(activeTab,activeApplicantTab,filterSearch)" class="btn btn btn-secondary"><i class="fa fa-search" aria-hidden="true"></i></button></div></div></div>
                        </div>
                        <div class="col-md-3 dropdown_daily_job">
                            <div class="label_div text_align_end_desktop">
                                <span>Company:</span>
                            </div>
                            <div class="input_div select_dropdown">
                            <b-form-select v-model="company_id" class="select_mrgn picker" @change="changeTabStatus(activeTab,'selected')">
                                             <option value="" disabled>Select Company</option>
                                            <option :value="company.id" v-for="company in companydata">{{company.name | capitalize}}</option>
                            </b-form-select>
                            </div>
                        </div>
                        <div class="col-md-3 calender_daily_job">
                            <div class="label_div text_align_end_desktop">
                                <span>Date Range:</span>
                            </div>
                            <div class="input_div">
                                <b-form-group id="input-group-6">
                                    <date-range-picker ref="picker" :minDate="minDate" :opens="center" :locale-data="locale" :auto-apply="auto" v-model="dateRange" :ranges='range' @update="changeTabStatus(activeTab,'selected')">
                                        <div slot="input" slot-scope="picker">
                                            {{ picker.startDate | datePicker1 }} - {{ picker.endDate | datePicker1 }}
                                        </div>
                                    </date-range-picker>
                                    <span class="input-icon">
                                        <b-img v-bind:src="webUrl+'assets/images/calendar-grey-icon02.svg'" alt="Calander Icon"></b-img>
                                    </span>
                                </b-form-group>
                            </div>
                            <!-- <div class="input_div">
                                <input type="date" id="birthday" name="birthday">
                            </div> -->
                        </div>

                        </div>
                    </div>

                    <div class="upper_mix_functiobality">

                    </div>

                    <div class="tab-section daily_job_mange_div">
                        <b-tabs class="my_tab_job remove_bx">
                            <b-tab :title="'IN PROGRESS'" :active='inprogress' @click="changeTabStatus('inprogress','selected')" class="">
                                <div class="top_desc_div" v-for="(jobDataVal,jobDatakey) in jobDatas">
                                    <ul class="com_id_ul">
                                        <li>{{jobDataVal.job_unique_id}}</li>
                                        <li v-if="jobDataVal.job_unique_id == 2"><button class="demand_btn">ON DEMAND JOB</button></li>
                                        <li>SRVICE AMT: S${{jobDataVal.service_fee}}</li>
                                    </ul>
                                    <div class="comp_heading_div">
                                        <h2 class="top_color_h2_single">{{jobDataVal.title}}</h2>
                                    </div>
                                    <div class="rate_div">
                                        <!-- <div class="status_btn">
                                                    <button class="ack_btn"  @click="openAttachSupModal(jobDataVal.id)">Assign User</button>
                                         </div> -->
                                        <div class="rate_text">
                                            <h2>S${{jobDataVal.hourly_cost}}/HR</h2>
                                        </div>

                                        <!-- <div class="action_div">
                                            <button class="action_btn">ACTIONS<i class="fa fa-caret-down"></i></button>
                                        </div> -->
                                    </div>
                                    <div class="img_with_desc">
                                            <div class="com_imgs">
                                                <img v-if="jobDataVal.userData && jobDataVal.userData.companydata && jobDataVal.userData.companydata.company_logo != null" :src="jobDataVal.userData.companydata.company_logo" :key="jobDataVal.userData.companydata.company_logo" alt="User Icon">
                                                <img v-else  :src="webUrl+'assets/images/user-avatar-sm.jpg'" alt="User Icon">
                                                <!-- <img :src="webUrl+'assets/images/user-avatar-sm.jpg'"> -->
                                            </div>
                                            <div class="com_desc">
                                                <h2>{{(jobDataVal.userData && jobDataVal.userData.companydata) ? jobDataVal.userData.companydata.name : ''}}</h2>
                                            </div>
                                    </div>
                                    <div class="full_desc_emps">
                                        <ul>
                                            <li>Posted By:<span>{{(jobDataVal.userData && jobDataVal.userData.last_name) ? jobDataVal.userData.last_name : ''}} {{(jobDataVal.userData && jobDataVal.userData.first_name) ? jobDataVal.userData.first_name : ''}}</span></li>
                                            <li>Job Posted D& Time:<span>{{jobDataVal.start_date | moment}} {{jobDataVal.start_time | jobStartEndTime}}</span></li>
                                            <li>Job Duration:<span>{{jobDataVal.start_date | moment}} {{jobDataVal.start_time | jobStartEndTime}} - {{jobDataVal.start_time | jobStartEndTime}}</span></li>
                                            <li>Job Hours:<span>{{jobDataVal.working_hours}}</span></li>
                                            <li>Vacancy:<span>{{jobDataVal.userJobs.length}}/{{jobDataVal.total_vacancies}}</span></li>
                                            <li v-if="jobDataVal.work_site_contact_person">Work Site Contact Person Name:<span>{{jobDataVal.work_site_contact_person ? jobDataVal.work_site_contact_person : ''}}</span></li>
                                            <li v-if="jobDataVal.work_site_contact_person_mobile_no">Work Site Contact Person Number:<span>+{{jobDataVal.work_site_contact_person_country_code ? jobDataVal.work_site_contact_person_country_code : ''}}-{{jobDataVal.work_site_contact_person_mobile_no ? jobDataVal.work_site_contact_person_mobile_no : ''}}</span></li>
                                        </ul>
                                    <div class="dtl_text">
                                        <h2 v-if="jobDataVal.description">Job Requirements</h2>
                                        <p>{{jobDataVal.description}}</p>
                                        <h2 v-if="jobDataVal.job_scope">Job Scope:</h2>
                                        <p>{{jobDataVal.job_scope}}</p>
                                        <p v-if="jobDataVal.address"><i class="fa fa-map-marker"></i>{{jobDataVal.address}}</p>
                                        <p v-if="jobDataVal.lunch_pay_hours"><strong>Note:</strong>Break hour not paid - {{jobDataVal.lunch_pay_hours}} Hr</p>
                                    </div>
                                    </div>
                                <hr>

                                </div>
                                <div class="tab-box-listing border-radius-4 white-bg border-midium-light mb-3 text-center" v-if="jobDatas.length == 0" >
                                    <p class="actionData">No Record Found</p>
                                </div>
                            </b-tab>
                            <b-tab :title="'UPCOMING'" :active='upcoming' @click="changeTabStatus('upcoming','selected')" class="reject_last_li">
                                <div class="top_desc_div" v-for="(jobDataVal,jobDatakey) in jobDatas">
                                    <ul class="com_id_ul">
                                        <li>{{jobDataVal.job_unique_id}}</li>
                                        <li v-if="jobDataVal.job_unique_id == 2"><button class="demand_btn">ON DEMAND JOB</button></li>
                                        <li>SRVICE AMT: S${{jobDataVal.service_fee}}</li>
                                    </ul>
                                    <div class="comp_heading_div">
                                        <h2 class="top_color_h2_single">{{jobDataVal.title}}</h2>
                                    </div>
                                    <div class="rate_div">

                                        <div class="rate_text">
                                            <h2>S${{jobDataVal.hourly_cost}}/HR</h2>
                                        </div>


                                        <!-- <div class="action_div">
                                            <button class="action_btn">ACTIONS<i class="fa fa-caret-down"></i></button>
                                        </div> -->
                                    </div>
                                    <div class="img_with_desc">
                                            <div class="com_imgs">
                                                <img v-if="jobDataVal.userData && jobDataVal.userData.companydata && jobDataVal.userData.companydata.company_logo != null" :src="jobDataVal.userData.companydata.company_logo" :key="jobDataVal.userData.companydata.company_logo" alt="User Icon">
                                                <img v-else  :src="webUrl+'assets/images/user-avatar-sm.jpg'" alt="User Icon">
                                                <!-- <img :src="webUrl+'assets/images/user-avatar-sm.jpg'"> -->
                                            </div>
                                            <div class="com_desc">
                                                <h2>{{(jobDataVal.userData && jobDataVal.userData.companydata) ? jobDataVal.userData.companydata.name : ''}}</h2>
                                            </div>
                                    </div>
                                    <div class="full_desc_emps">
                                        <ul>
                                            <li>Posted By:<span>{{(jobDataVal.userData && jobDataVal.userData.last_name) ? jobDataVal.userData.last_name : ''}} {{(jobDataVal.userData && jobDataVal.userData.first_name) ? jobDataVal.userData.first_name : ''}}</span></li>
                                            <li>Job Posted D& Time:<span>{{jobDataVal.start_date | moment}} {{jobDataVal.start_time | jobStartEndTime}}</span></li>
                                            <li>Job Duration:<span>{{jobDataVal.start_date | moment}} {{jobDataVal.start_time | jobStartEndTime}} - {{jobDataVal.start_time | jobStartEndTime}}</span></li>
                                            <li>Job Hours:<span>{{jobDataVal.working_hours}}</span></li>
                                            <li>Vacancy:<span>{{jobDataVal.userJobs.length}}/{{jobDataVal.total_vacancies}}</span></li>
                                            <li v-if="jobDataVal.work_site_contact_person">Work Site Contact Person Name:<span>{{jobDataVal.work_site_contact_person ? jobDataVal.work_site_contact_person : ''}}</span></li>
                                            <li v-if="jobDataVal.work_site_contact_person_mobile_no">Work Site Contact Person Number:<span>+{{jobDataVal.work_site_contact_person_country_code ? jobDataVal.work_site_contact_person_country_code : ''}}-{{jobDataVal.work_site_contact_person_mobile_no ? jobDataVal.work_site_contact_person_mobile_no : ''}}</span></li>
                                        </ul>
                                    <div class="dtl_text">
                                        <h2 v-if="jobDataVal.description">Job Requirements</h2>
                                        <p>{{jobDataVal.description}}</p>
                                        <h2 v-if="jobDataVal.job_scope">Job Scope:</h2>
                                        <p>{{jobDataVal.job_scope}}</p>
                                        <p v-if="jobDataVal.address"><i class="fa fa-map-marker"></i>{{jobDataVal.address}}</p>
                                        <p v-if="jobDataVal.lunch_pay_hours"><strong>Note:</strong>Break hour not paid - {{jobDataVal.lunch_pay_hours}} Hr</p>
                                    </div>
                                    </div>
                                    <div class="top_desc_div" v-for="(ratingval,ratekey) in jobDataVal.ratings">
                                         <div class="daily_job_manage_user_description_div">
                                                    <h2>{{ratingval.rated_user.last_name ? ratingval.rated_user.last_name : ''}} {{ratingval.rated_user.first_name ? ratingval.rated_user.first_name : ''}}</h2>
                                        </div>
                                        <div>
                                               <star-rating v-model="ratingval.rating" v-bind:star-size="20" :show-rating="false"></star-rating>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-box-listing border-radius-4 white-bg border-midium-light mb-3 text-center" v-if="jobDatas.length == 0" >
                                    <p class="actionData">No Record Found</p>
                                </div>

                            </b-tab>
                            <b-tab :title="'COMPLETED'" :active='completed' @click="changeTabStatus('completed','selected')" class="reject_last_li">
                                <div class="top_desc_div" v-for="(jobDataVal,jobDatakey) in jobDatas">
                                    <ul class="com_id_ul">
                                        <li>{{jobDataVal.job_unique_id}}</li>
                                        <li v-if="jobDataVal.job_unique_id == 2"><button class="demand_btn">ON DEMAND JOB</button></li>
                                        <li>SRVICE AMT: S${{jobDataVal.service_fee}}</li>
                                    </ul>
                                    <div class="comp_heading_div">
                                        <h2 class="top_color_h2_single">{{jobDataVal.title}}</h2>
                                    </div>
                                    <div class="rate_div">
                                        <div class="rate_text">
                                            <h2>S${{jobDataVal.hourly_cost}}/HR</h2>
                                        </div>
                                        <!-- <div class="action_div">
                                            <button class="action_btn">ACTIONS<i class="fa fa-caret-down"></i></button>
                                        </div> -->
                                    </div>
                                    <div class="img_with_desc">
                                            <div class="com_imgs">
                                                <img v-if="jobDataVal.userData && jobDataVal.userData.companydata && jobDataVal.userData.companydata.company_logo != null" :src="jobDataVal.userData.companydata.company_logo" :key="jobDataVal.userData.companydata.company_logo" alt="User Icon">
                                                <img v-else  :src="webUrl+'assets/images/user-avatar-sm.jpg'" alt="User Icon">
                                                <!-- <img :src="webUrl+'assets/images/user-avatar-sm.jpg'"> -->
                                            </div>
                                            <div class="com_desc">
                                                <h2>{{(jobDataVal.userData && jobDataVal.userData.companydata) ? jobDataVal.userData.companydata.name : ''}}</h2>
                                            </div>
                                    </div>
                                    <div class="full_desc_emps">
                                        <ul>
                                            <li>Posted By:<span>{{(jobDataVal.userData && jobDataVal.userData.last_name) ? jobDataVal.userData.last_name : ''}} {{(jobDataVal.userData && jobDataVal.userData.first_name) ? jobDataVal.userData.first_name : ''}}</span></li>
                                            <li>Job Posted D& Time:<span>{{jobDataVal.start_date | moment}} {{jobDataVal.start_time | jobStartEndTime}}</span></li>
                                            <li>Job Duration:<span>{{jobDataVal.start_date | moment}} {{jobDataVal.start_time | jobStartEndTime}} - {{jobDataVal.start_time | jobStartEndTime}}</span></li>
                                            <li>Job Hours:<span>{{jobDataVal.working_hours}}</span></li>
                                            <li>Vacancy:<span>{{jobDataVal.userJobs.length}}/{{jobDataVal.total_vacancies}}</span></li>
                                            <li v-if="jobDataVal.work_site_contact_person">Work Site Contact Person Name:<span>{{jobDataVal.work_site_contact_person ? jobDataVal.work_site_contact_person : ''}}</span></li>
                                            <li v-if="jobDataVal.work_site_contact_person_mobile_no">Work Site Contact Person Number:<span>+{{jobDataVal.work_site_contact_person_country_code ? jobDataVal.work_site_contact_person_country_code : ''}}-{{jobDataVal.work_site_contact_person_mobile_no ? jobDataVal.work_site_contact_person_mobile_no : ''}}</span></li>
                                        </ul>
                                    <div class="dtl_text">
                                        <h2 v-if="jobDataVal.description">Job Requirements</h2>
                                        <p>{{jobDataVal.description}}</p>
                                        <h2 v-if="jobDataVal.job_scope">Job Scope:</h2>
                                        <p>{{jobDataVal.job_scope}}</p>
                                        <p v-if="jobDataVal.address"><i class="fa fa-map-marker"></i>{{jobDataVal.address}}</p>
                                        <p v-if="jobDataVal.lunch_pay_hours"><strong>Note:</strong>Break hour not paid - {{jobDataVal.lunch_pay_hours}} Hr</p>
                                    </div>
                                    </div>
                                    <h2 v-if="jobDataVal.ratings.length > 0"><hr></h2>
                                    <div class="top_desc_div" v-for="(ratingval,ratekey) in jobDataVal.ratings">
                                         <div class="daily_job_manage_user_description_div" style="width:10% !important">
                                                    <h2>{{ratingval.rated_user.last_name ? ratingval.rated_user.last_name : ''}} {{ratingval.rated_user.first_name ? ratingval.rated_user.first_name : ''}}</h2>
                                        </div>
                                        <div style="margin-top:-14px !important">
                                               <star-rating v-model="ratingval.rating" v-bind:star-size="20" :show-rating="false"></star-rating>
                                        </div>
                                    </div>
                                    <hr>
                                </div>
                                <div class="tab-box-listing border-radius-4 white-bg border-midium-light mb-3 text-center" v-if="jobDatas.length == 0" >
                                    <p class="actionData">No Record Found</p>
                                </div>
                            </b-tab>
                            <b-tab :title="'CANCELLED'" :active='cancelled' @click="changeTabStatus('cancelled','selected')" class="reject_last_li">
                                <div class="top_desc_div" v-for="(jobDataVal,jobDatakey) in jobDatas">
                                    <ul class="com_id_ul">
                                        <li>{{jobDataVal.job_unique_id}}</li>
                                        <li v-if="jobDataVal.job_unique_id == 2"><button class="demand_btn">ON DEMAND JOB</button></li>
                                        <li>SERVICE AMT: S${{jobDataVal.service_fee}}</li>
                                    </ul>
                                    <div class="comp_heading_div">
                                        <h2 class="top_color_h2_single">{{jobDataVal.title}}</h2>
                                    </div>
                                    <div class="rate_div">
                                        <div class="rate_text">
                                            <h2>S${{jobDataVal.hourly_cost}}/HR</h2>
                                        </div>
                                        <!-- <div class="action_div">
                                            <button class="action_btn">ACTIONS<i class="fa fa-caret-down"></i></button>
                                        </div> -->
                                    </div>
                                    <div class="img_with_desc">
                                            <div class="com_imgs">
                                                <img v-if="jobDataVal.userData && jobDataVal.userData.companydata && jobDataVal.userData.companydata.company_logo != null" :src="jobDataVal.userData.companydata.company_logo" :key="jobDataVal.userData.companydata.company_logo" alt="User Icon">
                                                <img v-else  :src="webUrl+'assets/images/user-avatar-sm.jpg'" alt="User Icon">
                                                <!-- <img :src="webUrl+'assets/images/user-avatar-sm.jpg'"> -->
                                            </div>
                                            <div class="com_desc">
                                                <h2>{{(jobDataVal.userData && jobDataVal.userData.companydata) ? jobDataVal.userData.companydata.name : ''}}</h2>
                                            </div>
                                    </div>
                                    <div class="full_desc_emps">
                                        <ul>
                                            <li>Posted By:<span>{{(jobDataVal.userData && jobDataVal.userData.last_name) ? jobDataVal.userData.last_name : ''}} {{(jobDataVal.userData && jobDataVal.userData.first_name) ? jobDataVal.userData.first_name : ''}}</span></li>
                                            <li>Job Posted D& Time:<span>{{jobDataVal.start_date | moment}} {{jobDataVal.start_time | jobStartEndTime}}</span></li>
                                            <li>Job Duration:<span>{{jobDataVal.start_date | moment}} {{jobDataVal.start_time | jobStartEndTime}} - {{jobDataVal.start_time | jobStartEndTime}}</span></li>
                                            <li>Job Hours:<span>{{jobDataVal.working_hours}}</span></li>
                                            <li>Vacancy:<span>{{jobDataVal.userJobs.length}}/{{jobDataVal.total_vacancies}}</span></li>
                                            <li v-if="jobDataVal.work_site_contact_person">Work Site Contact Person Name:<span>{{jobDataVal.work_site_contact_person ? jobDataVal.work_site_contact_person : ''}}</span></li>
                                            <li v-if="jobDataVal.work_site_contact_person_mobile_no">Work Site Contact Person Number:<span>+{{jobDataVal.work_site_contact_person_country_code ? jobDataVal.work_site_contact_person_country_code : ''}}-{{jobDataVal.work_site_contact_person_mobile_no ? jobDataVal.work_site_contact_person_mobile_no : ''}}</span></li>
                                        </ul>
                                    <div class="dtl_text">
                                        <h2 v-if="jobDataVal.description">Job Requirements</h2>
                                        <p>{{jobDataVal.description}}</p>
                                        <h2 v-if="jobDataVal.job_scope">Job Scope:</h2>
                                        <p>{{jobDataVal.job_scope}}</p>
                                        <p v-if="jobDataVal.address"><i class="fa fa-map-marker"></i>{{jobDataVal.address}}</p>
                                        <p v-if="jobDataVal.lunch_pay_hours"><strong>Note:</strong>Break hour not paid - {{jobDataVal.lunch_pay_hours}} Hr</p>
                                    </div>
                                    </div>
                                    <div class="top_desc_div" v-for="(ratingval,ratekey) in jobDataVal.ratings">
                                        <div class="daily_job_manage_user_description_div">
                                                    <h2>{{ratingval.rated_user.last_name ? ratingval.rated_user.last_name : ''}} {{ratingval.rated_user.first_name ? ratingval.rated_user.first_name : ''}}</h2>
                                                    <div class="star-rating">
                                                        <star-rating v-model="ratingval.rating" v-bind:star-size="20" :show-rating="false"></star-rating>
                                                        <!-- <span class="star"></span>
                                                        <span class="star"></span>
                                                        <span class="star"></span>
                                                        <span class="star"></span>
                                                        <span class="star"></span> -->
                                                    </div>
                                                        <!-- <div>
                                                            <p v-if="items.item.proposed_arrival_time != null">ETA: {{items.item.proposed_arrival_time != null ? items.item.proposed_arrival_time : '' | moment }} | {{ items.item.proposed_arrival_time != null ? items.item.proposed_arrival_time : '' | applicantOriginalSET }}</p>
                                                            <p v-else>ETA: NA</p>
                                                        </div> -->
                                                </div>
                                    </div>
                                    <hr>
                                </div>
                                <div class="tab-box-listing border-radius-4 white-bg border-midium-light mb-3 text-center" v-if="jobDatas.length == 0" >
                                    <p class="actionData">No Record Found</p>
                                </div>
                            </b-tab>
                            <nav class="pagination-box">
                                                <div class="page-row-box d-inline-block">
                                                    <b-form-group id="input-group-4">
                                                        <span class="d-inline-block">Rows per page</span>
                                                        <span class="d-inline-block">
                                                        <b-form-select v-model="form.rowsPerPage" @change="changeTabStatus('all','selected')">
                                                                    <option>25</option>
                                                                    <option>50</option>
                                                                    <option>75</option>
                                                                    <option>100</option>
                                                        </b-form-select></span>
                                                    </b-form-group>
                                                </div>
                                                <div class="total-page-count d-inline-block">
                                                    <ul>
                                                        <li>
                                                            <span v-if='totalRows != 0'>{{from}} - {{to}} of {{totalRows}}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <b-pagination :total-rows="totalRows"   :per-page="perPage" v-model="currentPage" prev-text="" next-text="" hide-goto-end-buttons />
                            </nav>
                        </b-tabs>
                        </div>
                            </div>
                </div>
            </div>
            <!-- Row / End -->
            <!-- Row / End -->
            <div class="clearfix"></div>

            <div class="clearfix"></div>
            <div class="margin-top-70"></div>
            </div>
            <b-modal hide-footer hide-header id="attach-sup-modal" ref="attach-sup-modal" centered>
                <div class="edit-task">
                    <b-form @submit.prevent="assignJobstouser" method="post">
                        <div class="popup-header">
                            <h2 class="">Select User to assign job</h2>
                            <div class="mt-2">
                                <div class="addUserForm">
                                    <div class="editForm mw-100 pl-0">
                                        <b-row>
                                          <b-col lg="12" md="12">
                                            <b-form-group id="input-group-8" label="All Supervisors" class="required">
                                             <multiselect v-model="supervisorName" :custom-label="customLabel" tag-placeholder="Add this as new tag" placeholder="Enter to search tag" label="full_name" track-by="id" :options="selectedSupervisor" :multiple="false" class="new_multi_slec_list" ><span slot="noResult">No Supervisor Found.</span></multiselect>


                                            </b-form-group>
                                          </b-col>

                                        </b-row>

                                        <div slot="footer" class="form-btn">
                                          <b-button type="submit" variant="success">Submit</b-button>
                                          <b-button @click="hideModal()" variant="light">Cancel</b-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-form>
                </div>
            </b-modal>
	    </div>
    </div>
</template>

<script>
    import { POST_API } from "../../../store/actions.type"
    import { GET_API } from "../../../store/actions.type"

    import moment from 'moment'
    import StarRating from 'vue-star-rating'
    import DateRangePicker from 'vue2-daterange-picker'
    import Multiselect from 'vue-multiselect'

    export default {

        data() {
            return {
                    fields: [
                    { key: 'company_name', label: 'Company Name', sortable: true },
                    { key: 'service_category', label: 'Industry', sortable: true   },
                    { key: 'title', label: 'Job Title', sortable: true   },
                    { key: 'start_date', label: 'Job Start Date', sortable: true },
                    { key: 'applied_on', label: 'Applied On', sortable: true },
                    { key: 'job_status', label: 'Job Status', sortable: true },
                    { key: 'status', label: 'FL Status', }
                ],
                pager: {},
                pageOfItems: [],
                items:null,
                jobDatas: [],
                jobDatasAll:[],
                company_id:'',
                skill:'',
                daily:'',
                full_time:'',
                location:'',
                type:'',
                keyword:'',
                salary:'',
                error_message:'',
                skillsArr: [],
                tagArr: [],
                value: [0, 15000],
                pager: {},
                pageOfItems: [],
                options: [],
                items:null,
                totalRows: 0,
                sliderValue: 50,
                from: '',
                to: '',
                currentPage: 1,
                perPage: 25,
                pageOptions: [25, 50, 75, 100],
                form:{
                    rowsPerPage:25,
                },
                activeTab:'inprogress',
                activeApplicantTab:'selected',
                inprogress:true,
                upcoming:false,
                completed:false,
                cancelled:false,
                all:false,
                selected:true,
                application:false,
                system_withdrawn:false,
                applicant_withdrawn:false,
                rate_review:false,
                activeCount:'',
                shortlistedCount:'',
                hiredCount:'',
                rejectedCount:'',
                allCount:'',
                remaining_feature_jobs:0,
                loading: false,
                webUrl: process.env.VUE_APP_URL,
                webServerUrl: process.env.VUE_APP_SERVER_URL,
                userDatas:'',
                selectedsup:[],
                supervisorName:'',
                selectedSupervisor:[],

            id: atob(this.$route.params.id),
            pager: {},
            pageOfItems: [],
            items:null,
            totalRows: 0,
            from: '',
            to: '',
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 75, 100],
            sortBy: null,
            sortDirection: 'asc',
            filter: '',
            status: 'active',
            sortDesc: true,
            range: false,
            date: moment().format('YYYY-MM-DD'),
            format: 'YYYY-MM-DD HH:mm',
            auto: true,
            center: 'center',
            minDate: null,
            companydata:[],
            locale: {
                direction: 'ltr', //direction of text
                format: 'DD-MMM-YYYY', //fomart of the dates displayed
                separator: ' - ', //separator between the two ranges
                applyLabel: 'Apply',
                cancelLabel: 'Cancel',
                /*weekLabel: 'W',
                customRangeLabel: 'Custom Range',*/
                daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
                monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
                firstDay: 0, //ISO first day of week - see moment documenations for details
                sameDateFormat: {
                    from: "DD MM YYYY, hh:mm",
                    to: "hh:mm",
                },
            },
            timePicker24Hour: true,
            timePicker: true,
            dateInput: {
                placeholder: "Select Date"
            },
            calendarTimeInput: {
                readonly: true,
                step: 30,
                inputClass: "my_custom_class"
            },
            readOnly:true,
            dateRange: {
                startDate: '',
                endDate: ''
            },
            start_date_filter:'',
            end_date_filter:'',
            filterSearch:''
            }
        },
        components: {
            StarRating,
            DateRangePicker,
            Multiselect
        },
        filters: {
            moment: function(date) {
                return moment(date).format('DD MMM YYYY');
            },
            capitalize: function(value) {
                if (!value) return ''
                value = value.toString()
                return value.charAt(0).toUpperCase() + value.slice(1)
            },
            capitalizeAll: function(value) {
                if (!value) return ''
                value = value.toString()
                return value.toUpperCase()
            },
            customFormatterOne(date) {
                return moment(date).format('DD MMM YYYY');
            },

            applicantOriginalSET: function(date) {
                if(date =='' || date == null){
                    return '';
                }else{
                    return moment(date).format("hh:mm A");
                }
            },
            applicantStartEndTime: function(date) {
                if(date =='' || date == null){
                    return '';
                }else{
                    return moment(date, "HH:mm:ss").format("hh:mm A");
                }
            },
            jobStartEndTime: function(date) {
                if(date =='' || date == null){
                    return '';
                }else{
                    return moment(date, "HH:mm:ss").format("hh:mm A");
                }
            },
            datePicker1: function(value) {
                if (value == null) {
                    return '';
                } else {
                    return moment(value).format('DD MMM YYYY')
                }
            },
        },


        methods:{
            getEmpUsers() {
                return this.$store.dispatch(POST_API, {
                    data:{
                        company_admin: this.user_id,
                        company_id: this.company_id,
                        page: this.currentPage,
                        keyword: this.filter,
                        rowsPerPage:this.form.rowsPerPage,
                        sortBy: this.sortBy ? this.sortBy: 'id',
                        sortDirection:this.sortDesc ? 'asc' : 'desc',
                    },
                    api: '/api/emp-manage-user-list'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        // this.showAlert();
                        return [];
                    } else {
                        this.items = this.$store.getters.getResults.pageOfItems;
                        this.totalRows = this.$store.getters.getResults.pager.totalItems;
                        this.perPage = this.$store.getters.getResults.pager.pageSize;
                        this.from = this.$store.getters.getResults.pager.from;
                        this.to = this.$store.getters.getResults.pager.to;
                        return this.items;
                    }
                });
            },
            showAlert() {
                this.$swal({
                    position: 'center',
                    icon: 'error',
                    title: this.error_message,
                    showConfirmButton: false,
                    timer: 1500
                })
            },

            btoa(id){
               return btoa(id);
            },
            showSuccessAlert(message) {
                this.$swal({
                    position: 'center',
                    icon: 'success',
                    iconColor:'#4c8bf5',
                    title: message,
                    showConfirmButton: false,
                    timer: 1500
                }).then((result) => {
                    // this.$router.push({ name: 'manage-jobs' });
                });
            },
            exportJobs(){
                var tabStatus = this.activeTab == 'inprogress' ? 'in-progress' : this.activeTab;
                var start_date_filter = this.dateRange.startDate ? moment(this.dateRange.startDate).format('YYYY-MM-DD') : null;
                var end_date_filter = this.dateRange.endDate ? moment(this.dateRange.endDate).format('YYYY-MM-DD') : null;
                var filterSearch = this.filterSearch != '' ? this.filterSearch : null;
                return this.$store.dispatch(GET_API, {
                    api: '/api/exportJobs/'+tabStatus+'/'+this.user_id+'/'+start_date_filter+'/'+end_date_filter+'/'+filterSearch
                    // api: '/api/exportJobs/'+tabStatus

                })
                .then(() => {
                    var base_url = this.webServerUrl;
                    var export_url = '/api/exportJobs/'+tabStatus+'/'+this.user_id+'/'+start_date_filter+'/'+end_date_filter+'/'+filterSearch+'?nocache=1666084804705/';
                    // var export_url = '/api/exportJobs/'+tabStatus+'?nocache=1666084804705/';
                    // var export_url = '/api/export-jobs/'+this.status+'/'+this.user_id+'/'+search+'/'+this.form.filterServiceCategory+'/'+companiesFilter+'/'+start_date+'/'+end_date;
                    window.open(base_url+export_url,'_blank')
                });

            },

            exportJobsByName(){
                var tabStatus = this.activeTab == 'inprogress' ? 'in-progress' : this.activeTab;
                var start_date_filter = this.dateRange.startDate ? moment(this.dateRange.startDate).format('YYYY-MM-DD') : null;
                var end_date_filter = this.dateRange.endDate ? moment(this.dateRange.endDate).format('YYYY-MM-DD') : null;
                var filterSearch = this.filterSearch != '' ? this.filterSearch : null;
                return this.$store.dispatch(GET_API, {
                    // api: '/api/exportJobsByName/'+tabStatus
                    api: '/api/exportJobsByName/'+tabStatus+'/'+this.user_id+'/'+start_date_filter+'/'+end_date_filter+'/'+filterSearch
                })
                .then(() => {
                    var base_url = this.webServerUrl;
                    // var export_url = '/api/exportJobsByName/'+tabStatus+'?nocache=1666084804705/';
                    var export_url = '/api/exportJobsByName/'+tabStatus+'/'+this.user_id+'/'+start_date_filter+'/'+end_date_filter+'/'+filterSearch+'?nocache=1666084804705/';
                    // var export_url = '/api/export-jobs/'+this.status+'/'+this.user_id+'/'+search+'/'+this.form.filterServiceCategory+'/'+companiesFilter+'/'+start_date+'/'+end_date;
                    window.open(base_url+export_url,'_blank')
                });

            },

            changeTabStatus(status,user_status) {
                this.loading = true;
                this.activeTab = status;
                if(this.activeTab == 'upcoming'){
                    this.upcoming = true;
                    this.inprogress = false;
                    this.completed = false;
                    this.cancelled = false;
                    this.all = false;
                }else if(this.activeTab == 'completed'){
                    this.completed = true;
                    this.inprogress = false;
                    this.upcoming = false;
                    this.cancelled = false;
                    this.all = false;
                }else if(this.activeTab == 'cancelled'){
                    this.cancelled = true;
                    this.inprogress = false;
                    this.upcoming = false;
                    this.completed = false;
                    this.all = false;
                }else if(this.activeTab == 'all'){
                    this.all = true;
                    this.inprogress = false;
                    this.upcoming = false;
                    this.completed = false;
                    this.cancelled = false;
                }else{
                    this.inprogress = true;
                    this.expired = false;
                    this.upcoming = false;
                    this.completed = false;
                    this.cancelled = false;
                    this.all = false;
                }
                $(".dashboard-content-inner").animate({
                        scrollTop: 0
                }, "slow");
                this.start_date_filter = this.dateRange.startDate ? moment(this.dateRange.startDate).format('YYYY-MM-DD') : '',
                this.end_date_filter = this.dateRange.endDate ? moment(this.dateRange.endDate).format('YYYY-MM-DD') : '';
                var api = this.activeTab == 'all' ? '/api/dailyPaidAssignedJobs' : '/api/dailyPaidJobsAdmin';
                return this.$store.dispatch(POST_API, {
                        data:{
                            page: this.currentPage,
                            rowsPerPage:this.form.rowsPerPage,
                            userId:this.id,
                            company_id:this.company_id,
                            user_type_id:this.user_type_id,
                            is_company_head:this.is_company_head,
                            start_date_filter:this.start_date_filter,
                            end_date_filter:this.end_date_filter,
                            activeTab:status,
                            status:status == 'inprogress' ? 'in-progress' : status,
                            user_status:user_status
                },
                    api: api
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        this.loading = false;

                        return [];
                    } else {
                        $(".dashboard-content-container .simplebar-scroll-content").animate({
                        scrollTop: 0
                        }, "slow");
                        this.loading = false;

                        if(this.activeTab == 'all'){

                            this.jobDatasAll = this.$store.getters.getResults.pageOfItems;

                        }else{
                            this.jobDatas = this.$store.getters.getResults.pageOfItems;

                        }

                       // this.items = this.$store.getters.getResults.pageOfItems;
                        this.totalRows = this.$store.getters.getResults.pager.totalItems;
                        this.perPage = this.$store.getters.getResults.pager.pageSize;
                        this.from = this.$store.getters.getResults.pager.from;
                        this.to = this.$store.getters.getResults.pager.to;
                        return this.jobDatas;
                    }
                });
            },

            paginate(status,user_status) {

                alert('gjk');
                this.loading = true;
                this.activeTab = status;
                if(this.activeTab == 'upcoming'){
                    this.upcoming = true;
                    this.inprogress = false;
                    this.completed = false;
                    this.cancelled = false;
                    this.all = false;
                }else if(this.activeTab == 'completed'){
                    this.completed = true;
                    this.inprogress = false;
                    this.upcoming = false;
                    this.cancelled = false;
                    this.all = false;
                }else if(this.activeTab == 'cancelled'){
                    this.cancelled = true;
                    this.inprogress = false;
                    this.upcoming = false;
                    this.completed = false;
                    this.all = false;
                }else if(this.activeTab == 'all'){
                    this.all = true;
                    this.inprogress = false;
                    this.upcoming = false;
                    this.completed = false;
                    this.cancelled = false;
                }else{
                    this.inprogress = true;
                    this.expired = false;
                    this.upcoming = false;
                    this.completed = false;
                    this.cancelled = false;
                    this.all = false;
                }
                $(".dashboard-content-inner").animate({
                        scrollTop: 0
                }, "slow");
                this.start_date = this.dateRange.startDate ? moment(this.dateRange.startDate).format('YYYY-MM-DD HH:mm:ss') : '',
                this.end_date = this.dateRange.endDate ? moment(this.dateRange.endDate).format('YYYY-MM-DD HH:mm:ss') : '';
                var api = this.activeTab == 'all' ? '/api/dailyPaidJobsEmployerAll' : '/api/dailyPaidJobsAdmin';
                return this.$store.dispatch(POST_API, {
                        data:{
                            page: this.currentPage,
                            rowsPerPage:this.form.rowsPerPage,
                            userId:this.id,
                            company_id:this.company_id,
                            user_type_id:this.user_type_id,
                            is_company_head:this.is_company_head,
                            activeTab:status,
                            status:status == 'inprogress' ? 'in-progress' : status,
                            user_status:user_status
                },
                    api: api
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        this.loading = false;

                        return [];
                    } else {
                        $(".dashboard-content-container .simplebar-scroll-content").animate({
                        scrollTop: 0
                        }, "slow");
                        this.loading = false;

                        this.jobDatas = this.$store.getters.getResults.pageOfItems;
                        this.totalRows = this.$store.getters.getResults.pager.totalItems;
                        this.perPage = this.$store.getters.getResults.pager.pageSize;
                        this.from = this.$store.getters.getResults.pager.from;
                        this.to = this.$store.getters.getResults.pager.to;
                        //return this.jobDatas;
                    }
                });
            },

            getList(status,user_status,filterSearch) {
                this.loading = true;
                this.activeTab = status;
                if(this.activeTab == 'upcoming'){
                    this.upcoming = true;
                    this.inprogress = false;
                    this.completed = false;
                    this.cancelled = false;
                    this.all = false;
                }else if(this.activeTab == 'completed'){
                    this.completed = true;
                    this.inprogress = false;
                    this.upcoming = false;
                    this.cancelled = false;
                    this.all = false;
                }else if(this.activeTab == 'cancelled'){
                    this.cancelled = true;
                    this.inprogress = false;
                    this.upcoming = false;
                    this.completed = false;
                    this.all = false;
                }else if(this.activeTab == 'all'){
                    this.all = true;
                    this.inprogress = false;
                    this.upcoming = false;
                    this.completed = false;
                    this.cancelled = false;
                }else{
                    this.inprogress = true;
                    this.expired = false;
                    this.upcoming = false;
                    this.completed = false;
                    this.cancelled = false;
                    this.all = false;
                }
                $(".dashboard-content-inner").animate({
                        scrollTop: 0
                }, "slow");
                this.start_date = this.dateRange.startDate ? moment(this.dateRange.startDate).format('YYYY-MM-DD HH:mm:ss') : '',
                this.end_date = this.dateRange.endDate ? moment(this.dateRange.endDate).format('YYYY-MM-DD HH:mm:ss') : '';
                var api = this.activeTab == 'all' ? '/api/dailyPaidJobsEmployerAll' : '/api/dailyPaidJobsAdmin';
                return this.$store.dispatch(POST_API, {
                        data:{
                            page: this.currentPage,
                            rowsPerPage:this.form.rowsPerPage,
                            userId:this.id,
                            company_id:this.company_id,
                            user_type_id:this.user_type_id,
                            is_company_head:this.is_company_head,
                            activeTab:status,
                            status:status == 'inprogress' ? 'in-progress' : status,
                            user_status:user_status,
                            filterSearch:filterSearch
                },
                    api: api
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        this.loading = false;

                        return [];
                    } else {
                        $(".dashboard-content-container .simplebar-scroll-content").animate({
                        scrollTop: 0
                        }, "slow");
                        this.loading = false;

                        this.jobDatas = this.$store.getters.getResults.pageOfItems;
                        this.totalRows = this.$store.getters.getResults.pager.totalItems;
                        this.perPage = this.$store.getters.getResults.pager.pageSize;
                        this.from = this.$store.getters.getResults.pager.from;
                        this.to = this.$store.getters.getResults.pager.to;
                        //return this.jobDatas;
                    }
                });
            },
            deleteJob(id){
                this.$swal({
                    title: 'Please Confirm',
                    text: 'Are you sure you want to delete this record?',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#4C8BF5',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes!'
                })
                .then(result => {
                    if (result.value) {
                        return this.$store.dispatch(POST_API, {
                            data:{
                            id: id,
                            },
                            api:'/api/deleteJob',
                        })
                        .then(() => {
                            if (this.$store.getters.containsErrors) {
                                this.error_message = this.$store.getters.getErrors;
                                this.showAlert();
                            } else {
                                var message='Job Deleted successfully.';
                                this.showSuccessAlert(message);
                                window.location.replace('/v2/employer/manage-jobs');
                            }
                        });
                    }
                })
                .catch(err => {
                })
            },
            changeApplicantTabStatus(status,user_status,id,key) {

                this.activeTab = status;
                if(this.activeTab == 'upcoming'){
                    this.upcoming = true;
                    this.inprogress = false;
                    this.completed = false;
                    this.cancelled = false;
                    this.all = false;
                }else if(this.activeTab == 'completed'){
                    this.completed = true;
                    this.inprogress = false;
                    this.upcoming = false;
                    this.cancelled = false;
                    this.all = false;
                }else if(this.activeTab == 'cancelled'){
                    this.cancelled = true;
                    this.inprogress = false;
                    this.upcoming = false;
                    this.completed = false;
                    this.all = false;
                }else if(this.activeTab == 'all'){
                    this.all = true;
                    this.inprogress = false;
                    this.upcoming = false;
                    this.completed = false;
                    this.cancelled = false;
                }else{
                    this.inprogress = true;
                    this.expired = false;
                    this.upcoming = false;
                    this.completed = false;
                    this.cancelled = false;
                    this.all = false;
                }
                return this.$store.dispatch(POST_API, {
                        data:{

                            jobId:id,
                            // jobId:1887,
                            status:status == 'inprogress' ? 'in-progress' : status,
                            user_status:user_status,
                },
                    api: '/api/dailyPaidJobsEmployerById'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        // this.showAlert();
                        return [];
                    } else {

                        var userDatas = this.$store.getters.getResults.data[0].userJobs;

                        this.jobDatas[key].userJobs = userDatas;

                        // return this.jobDatas.key;
                    }
                });
                },

            changeStatus(id, status) {
                if (status == 'active') {
                    var msg = 'Are you sure you want to inactivate this record?';
                    var message='Job Inactivated successfully.';
                    var new_status = 'inactive';
                }
                else{
                    var msg = 'Are you sure you want to activate this record?';
                    var message='Job Activated successfully.';
                    var new_status = 'active';
                }
                this.$swal({
                    title: 'Please Confirm',
                    text: msg,
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#4C8BF5',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No'
                })
                .then(result => {
                    if (result.value) {
                        return this.$store.dispatch(POST_API, {
                                data:{
                                id: id,
                                status:new_status,
                                page: this.currentPage,
                                keyword: this.filter
                                },
                                api:'/api/changeJobStatus'
                        })
                        .then(() => {
                            if (this.$store.getters.containsErrors) {
                                this.error_message = this.$store.getters.getErrors;
                                this.showAlert();
                            } else {
                                this.showSuccessAlert(message);
                                this.changeTabStatus(this.activeTab,this.activeApplicantTab);
			                    this.getTotalCount();
                                // window.location.replace('/v2/manage-jobs');
                            }
                        });
                    }
                })
                .catch(err => {
                })
            },

            featureJob(id,company_id) {

                if(this.remaining_feature_jobs == 0){
                     var msg = 'Are you sure you want to feature this job? Since you do not have any remaining feature jobs so coins will be deducted from your account';
                }else{
                     var msg = 'Are you sure you want to feature this job? Since you have remaining feature jobs so coins will not be deducted';
                }
                var message='Job featured successfully.';

                this.$swal({
                    title: 'Please Confirm',
                    text: msg,
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#4C8BF5',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No'
                })
                .then(result => {
                    if (result.value) {
                        return this.$store.dispatch(POST_API, {
                                data:{
                                id: id,
                                company_admin_id: this.company_admin_id ? this.company_admin_id:this.user_id,
                                company_id:company_id,
                                page: this.currentPage,
                                keyword: this.filter
                                },
                                api:'/api/featureJob'
                        })
                        .then(() => {
                            if (this.$store.getters.containsErrors) {
                                this.error_message = this.$store.getters.getErrors;
                                this.showAlert();
                                if(this.error_message = 'Insufficient Coins for featuring the job.'){
                                    this.$router.push({ name: 'elcoins' });
                                }
                            } else {
                                this.showSuccessAlert(message);
                                this.changeTabStatus(this.activeTab,this.activeApplicantTab);
			                    this.getTotalCount();
                                window.location.replace('/v2/employer/manage-jobs');
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err)
                })
            },

            manageCandidate(id){

                window.location.replace('/v2/manage-candidates/'+id);

            },

            awardJob(userId, jobId) {

             this.$swal({
                title: 'Please Confirm',
                text: 'Are you sure to award.',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#4C8BF5',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes!'
              })

            .then(result => {
                if (result.value) {
                        return this.$store.dispatch(POST_API, {
                           data:{
                            job_id: jobId,
                            user_id:userId,
                            employer_id:this.user_id,
                           },
                           api: '/api/awardUpcomingJobWeb'
                        })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        this.items = this.$store.getters.getResults.data;
                        this.success_message = this.$store.getters.getResults.message;
                        this.showSuccessAlert(this.success_message);
                        this.getList('in-progress','selected',this.filterSearch);
                        return this.items;
                    }
                });
                    }
                })


        },
            notSuitable(userId, jobId) {

             this.$swal({
                title: 'Please Confirm',
                text: 'Are you sure to remove this user.',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#4C8BF5',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes!'
              })

            .then(result => {
                if (result.value) {
                        return this.$store.dispatch(POST_API, {
                           data:{
                            job_id: jobId,
                            user_id:userId,
                           },
                           api: '/api/mw-not-suitable'
                        })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        this.items = this.$store.getters.getResults.data;
                        this.success_message = this.$store.getters.getResults.message;
                        this.showSuccessAlert(this.success_message);
                        this.getList('in-progress','selected',this.filterSearch);
                        return this.items;
                    }
                });
                    }
                })


        },



        assignJobstouser(evt) {
          evt.preventDefault();
          this.hideModal();
          this.$store.dispatch(POST_API, {
            data:{
              user_id: this.supervisorName,
              job_id: this.job_id_data,
            },
            api: "/api/assignJobstouser",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showAlert();
            } else {

              this.success_message = this.$store.getters.getResults.message;

             this.$swal({
                position: 'center',
                icon: 'success',
                iconColor:'#4c8bf5',
                title: this.success_message,
                showConfirmButton: false,
                timer: 1500
             })
            }
          });
        },

         openAttachSupModal(id)
        {
          this.$refs['attach-sup-modal'].show();
          this.job_id_data = id;

          return this.$store.dispatch(POST_API, {
            data:{
                  user_id: this.user_id,
                },
            api:"/api/list-supervisors-to-assign-om-jobs",
          })
          .then(() => {
              if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
              }
              else {
                  this.selectedSupervisor = this.$store.getters.getResults.data;
                  this.supervisorName = this.$store.getters.getResults.selected;

                  // return this.selectedSupervisor;
              }
          });
        },
        getCompanyData() {
            return this.$store.dispatch(POST_API, {
                data:{

                },
                api: '/api/company-list-admin'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    // this.showAlert();
                    return [];
                } else {
                    this.companydata = this.$store.getters.getResults.data;

                   // return this.items;
                }
            });
        },
        customLabel({ first_name, last_name }) {
          return `${last_name} ${first_name}`
        },
         hideModal() {
                 this.$refs['attach-sup-modal'].hide()
            },

        },
        mounted(){
            //document.title = "EL Connect - Employer - Manage Jobs";
            this.user_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
            this.user_type_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.user_type_id : '');
            this.is_company_head = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.is_company_head : '');
            this.company_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.company_id : '');
            this.company_admin_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.company_admin_id : '');
            this.getList(this.activeTab,this.activeApplicantTab,this.filterSearch);
            this.getCompanyData();
            // this.changeApplicantTabStatus(this.activeApplicantTab);

            $(".dashboard-content-container .simplebar-scroll-content").animate({
            scrollTop: 0
        }, "fast");
        }
    }
</script>

