<template>
  <div>
    <!-- <div class="container full_container_dsk">
            <div class="row custom_dsk_row"> -->
    <div class="row">
      <div class="container my_new_container">
          <!-- Dashboard Headline -->
          <div class="dashboard-headline">
              <h1>Pay Incentive & Referral</h1>
              <!-- Breadcrumbs -->
              <nav id="breadcrumbs" class="dark">
                <ul>
                  <li><a href="#">Home</a></li>
                  <li>Pay Incentive</li>
                </ul>
              </nav>
          </div>
        <!-- <b-tabs class="my_tab_job tab_header_action_th common_tab_inline_scroll">
          <b-tab :title="'Incentive'" @click="changeTab('incentive')"> -->
            <!-- <div class="container new_cnt_pdng my_new_container"> -->
              <div class="row">
                <div
                  class="search_common_margin_bottom_28 col-xl-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_18_mobile"
                >
                  <div class="search-box custom_search_admin desktop_margin_top_zero">
                    <b-input-group-prepend>
                      <b-form-input
                        id="searchBtn"
                        @keyup.enter.native="filter = searchKeyword"
                        v-model="searchKeyword"
                        placeholder="Search"
                      ></b-form-input>
                      <b-button class="btn points_events"
                        ><i class="fa fa-search" @click="filter = searchKeyword"></i
                      ></b-button>
                    </b-input-group-prepend>
                  </div>
                </div>
                <div class="col-xl-5 search_common_margin_bottom_28 mobile_tab_max_width_flex">
                        <label class="new_style_label required_sign required desktop_margin_bottom_zero mobile_margin_top_zero">Date:</label>
                        <b-form-group id="input-group-6" class="desktop_margin_bottom_zero">
                            <datepicker v-model="month" @input="getListing1" :format="customFormatter" minimum-view="month" maximum-view="year" class="add_calender_icon common_normal_calender_height freelancer_incentive_calender"></datepicker>
                        </b-form-group>
                    </div>
                <div
                  class="search_common_margin_bottom_28 col-xl-3  mobile_tab_max_width_flex search_common_margin_bottom_18_mobile"
                >
                  <b-button
                    @click="getIncentiveDetail"
                    class="common_export_btn incentive_btn_padding button ripple-effect search_btn_all  desktop_margin_left_zero"
                    >Search</b-button
                  >
                  <button
                    class="common_export_btn incentive_btn_padding mobile_tab_margin_right_zero button ripple-effect search_btn_all desktop_margin_left_zero btn-secondary"
                    @click="exportIncentive()"
                    v-if="show('export')"
                  >
                    Export
                  </button>
                </div>
                <div class="col-xl-12  mobile_tab_max_width_flex" v-if="items && items.length > 0 && showFreelancer('whitelist fl')">
                  <div class="sidebar-widget penalty_btn search_common_margin_bottom_28">
                    <div
                      class=""
                      v-if="items && items.length > 0 && showFreelancer('whitelist fl')"
                    >
                      <div class="checkbox_with_trash checkbox_with_trash_incentive">
                        <b-form-checkbox class="mr-3"
                          v-model="isSelectAllCheckedUsers"
                          @change="toggleSelectAllWhitelist"
                        >
                          Select All
                        </b-form-checkbox>
                        <b-link
                          v-if="items && items.length > 0"
                          class="mr-3 btn incentive_btn_padding mobile_tab_margin_right_zero  button ripple-effect search_btn_all  desktop_margin_left_zero btn-secondary"
                          :class="userIDArr.length == 0 ? 'disabled' : ''"
                          @click="payMultiple()"
                          ><i class="material-icons-outlined plus_icon_incentive">add_outlined</i>
                        Pay Multiple</b-link>
                           <b-button
                            v-if="filter || searchKeyword || month != month_filter"
                            @click="resetFilter()"
                            class="ml-3 new_reset_text  mobile_tab_padding_right_zero mobile_tab_padding_left_zero mobile_tab_padding_top_zero mobile_tab_padding_bottom_zero  new_black_reset_filter common_font_size_14 common_reset_btn_filter_transactions button ripple-effect search_all_red_btn filter_btn_widht_120 desktop_margin_right_zero m-0"
                            style="background-color: red; margin-top: 20px;">
                            <span class="mobile_reset">Reset filters</span></b-button
                        >
                    </div>
                  </div>
                </div>
                <div class="mobile_reset_filter_btn col-xl-12 mobile_tab_max_width_flex" v-if="items && items.length > 0 && showFreelancer('whitelist fl')">
                  <b-button
                    v-if="filter || searchKeyword || month != month_filter"
                    @click="resetFilter()"
                    class="ml-3 new_reset_text  mobile_tab_padding_right_zero mobile_tab_padding_left_zero mobile_tab_padding_top_zero mobile_tab_padding_bottom_zero  new_black_reset_filter common_font_size_14 common_reset_btn_filter_transactions button ripple-effect search_all_red_btn filter_btn_widht_120 desktop_margin_right_zero m-0"
                    style="background-color: red; margin-top: 20px;">
                    <span class="mobile_reset">Reset filters</span></b-button
                    >
                </div>
              </div>
            </div>
            <!-- <b-link
              v-if="items && items.length > 0"
              class="btn green-border-btn"
              :class="userIDArr.length == 0 ? 'disabled' : ''"
              @click="payMultiple()"
              >Remove</b-link
            > -->
            <div class="row">
              <div class="container my_new_container show_filter_arrow">
                <!--     <div class="select-all-checkbox" v-if="items && items.length > 0">
                  <b-form-checkbox v-model="isSelectAllCheckedUsers" @change="toggleSelectAllWhitelist">
                    Select All
                  </b-form-checkbox>
                </div> -->
                <b-table
                  ref="datatableIncentive"
                  show-empty
                  striped
                  hover
                  responsive
                  :items="getIncentiveDetail"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter="filter"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  class="new_layout_table_like_v1"
                >
                  <!-- <template #cell(checkBox)="items">
                                    <b-form-checkbox
                                        @input="singleIDSelectWhitelist($event,items.item.user_id)"
                                    ></b-form-checkbox>
                                </template> -->
                  <!-- <template #head(checkBox)="items">
                                    <b-form-checkbox
                                    v-model="isSelectAllCheckedUsers"
                                    @change="toggleSelectAllWhitelist"
                                    ></b-form-checkbox>
                                </template> -->

                  <template #cell(checkBox)="items" v-if="showFreelancer('whitelist fl')">
                    <b-form-checkbox v-if="items.item.status == 'unpaid'"
                      v-model="userIDArr"
                      :value="items.item"
                    ></b-form-checkbox>
                  </template>
                  <template #cell(ptuser)="items">
                    <div class="new-name-td-box">
                      <div class="whole_div_img_and_name">
                        <div class="user_name_div">
                          <b-link
                            :to="
                              showFreelancer('view profile')
                                ? {
                                    name: 'jobseeker-dp-profile-view',
                                    params: { id: encrypt(items.item.id) },
                                  }
                                : null
                            "
                            target="_blank"
                            style="color: #4c8bf5"
                          >
                            {{ items.item.last_name | capitalize }}
                            {{ items.item.first_name | capitalize }}
                          </b-link>
                        </div>
                      </div>

                    </div>
                  </template>



                  <template #cell(nric)="items">
                      {{ items.item.nric }}
                  </template>
                  <template #cell(mobile_number)="items">
                    <b-link
                      :href="'https://wa.me/+65' + items.item.mobile_number"
                      target="_blank"
                      style="color: #4c8bf5"
                    >
                    {{items.item.country_code}}-{{items.item.mobile_number}}
                    </b-link>
                  </template>
                  <template #cell(user_status)="items">
                    {{ items.item.user_status}}
                  </template>
                  <template #cell(incentive)="items">
                        S${{items.item.user_incentive != 0 ? items.item.user_incentive : '0.00'}}
                    </template>
                    <template #cell(referral)="items">
                        S${{items.item.user_referral != 0 ? items.item.user_referral : '0.00'}}
                    </template>
                    <template #cell(transaction_id)="items">
                        {{items.item.transaction_id}}
                    </template>
                    <template #cell(transaction_date)="items">
                        {{items.item.transaction_date | customFormatterOne}}
                    </template>
                  <template #cell(status)="items">
                    <div style="display:flex">
                      <p class="green_status" v-if="items.item.status == 'paid'">
                      {{ items.item.status | capitalize }}
                      </p>
                      <p class="red_status" v-else>
                        {{ items.item.status | capitalize }}
                      </p>
                      <b-badge variant="danger" v-if="items.item.to_process == 'no' && items.item.status != 'paid'" >! </b-badge>
                    </div>
                  </template>

                  <template #cell(toggleAction)="items">
                    <div class="toggle-action toggle_margin_unset">
                      <b-dropdown class="mx-1" right text="Action" boundary="window">
                        <b-dropdown-item v-on:click="showModel(items.item.id, 'add',items.item.user_incentive,items.item.user_referral)" v-if="items.item.status == 'unpaid' && show('pay')">Pay Incentive</b-dropdown-item>
                        <b-dropdown-item v-if="show('revert') && items.item.status == 'paid' " v-on:click="showModel(items.item.id, 'revert',items.item.user_incentive,items.item.user_referral)">Revert Incentive</b-dropdown-item>
                        <b-dropdown-item v-on:click="toProcess(items.item.id, 'yes')" v-if="(items.item.to_process == 'no') && (items.item.status == 'unpaid') && show('process incentive')">Process Incentive</b-dropdown-item>
                        <b-dropdown-item v-on:click="toProcess(items.item.id, 'no')" v-if="(items.item.to_process == 'yes') && (items.item.status == 'unpaid') && show('process incentive')">Hold Incentive</b-dropdown-item>

                      </b-dropdown>
                    </div>
                  </template>
                </b-table>
                <nav class="pagination-box custom_pagination_border_radius">
                  <div class="page-row-box d-inline-block">
                    <b-form-group id="input-group-4">
                      <span class="d-inline-block">Rows per page</span>
                      <span class="d-inline-block">
                        <b-form-select v-model="form.rowsPerPage" @change="getIncentiveDetail">
                          <option>25</option>
                          <option>50</option>
                          <option>75</option>
                          <option>100</option>
                        </b-form-select></span
                      >
                    </b-form-group>
                  </div>
                  <div class="total-page-count d-inline-block">
                    <ul>
                      <li>
                        <span v-if="totalRows != 0">{{ from }} - {{ to }} of {{ totalRows }}</span>
                      </li>
                    </ul>
                  </div>
                  <b-pagination
                    :total-rows="totalRows"
                    :per-page="perPage"
                    v-model="currentPage"
                    prev-text=""
                    next-text=""
                    hide-goto-end-buttons
                  />
                </nav>
              </div>
            </div>
          <!-- </b-tab> -->

          <!-- <b-tab :title="'BAN FROM EM'" @click="changeTab('ban')">
            <div class="container new_cnt_pdng my_new_container">
              <div class="row">
                <div
                  class="col-xl-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28"
                >
                  <div class="search-box custom_search_admin desktop_margin_top_zero">
                    <b-input-group-prepend>
                      <b-form-input
                        id="searchBtn"
                        @keyup.enter.native="filterBan = searchKeywordBan"
                        v-model="searchKeywordBan"
                        placeholder="Search by Name, Email, Company Name"
                      ></b-form-input>
                      <b-button class="btn points_events"
                        ><i class="fa fa-search" @click="filter = searchKeywordBan"></i
                      ></b-button>
                    </b-input-group-prepend>
                  </div>
                </div>
                <div class="col-xl-4 search_common_margin_bottom_28 mobile_tab_max_width_flex">
                  <b-form-group id="input-group-8" class="desktop_margin_bottom_zero">
                    <multiselect
                      id="ajax"
                      v-model="company_ban"
                      :custom-label="customLabel"
                      tag-placeholder="Search Company"
                      placeholder="Company"
                      :options="employers"
                      label="full_name"
                      track-by="company_id"
                      @input="changeTab('ban')"
                      :multiple="true"
                      :searchable="true"
                      :clear-on-select="true"
                      :close-on-select="true"
                      :allow-empty="true"
                      deselect-label="Can't remove this value"
                      open-direction="bottom"
                      class="job_list_multi_select model_multi_select admin_multi_select new_multiselct_height common_black_multiselect_border"
                      ><span slot="noResult">No Employer Found.</span></multiselect
                    >
                  </b-form-group>
                </div>
                <div
                  class="jobseeker_status_div search_common_margin_bottom_28 col-xl-2 col-md-2 mobile_tab_max_width_flex search_common_margin_bottom_18_mobile"
                >
                  <b-form-select
                    v-model="flStatus_ban"
                    @change="changeTab('ban')"
                    class="common_select_focus common_padding_left_right_20 common_border_search height_unset desktop_margin_bottom_zero"
                  >
                    <option value="">Jobseeker Status</option>
                    <option :value="ac_status" v-for="ac_status in accountStatus">
                      {{ ac_status | capitalize }}
                    </option>
                  </b-form-select>
                </div>
                <div
                  class="search_btn_div_width search_common_margin_bottom_28 col-xl-2 col-md-2 mobile_tab_max_width_flex search_common_margin_bottom_18_mobile"
                >
                  <b-button
                    @click="changeTab('ban')"
                    class="btn search_btn_whitelist mobile_tab_margin_right_zero common_width_100_min_width_100_button button ripple-effect search_btn_all filter_btn_widht_120 desktop_margin_left_zero btn-secondary"
                    style="width: 120px"
                    >Search</b-button
                  >

                </div>

                <div class="col-xl-12 col-md-12 mobile_tab_max_width_flex">
                  <div class="sidebar-widget penalty_btn desktop_margin_bottom_zero">
                    <div
                      class="search_common_margin_bottom_18_mobile select-all-checkbox whitelist_select employer_multiple_radio"
                      v-if="itemsBan && itemsBan.length > 0 && showFreelancer('restrict companies')"
                    >
                      <div class="checkbox_with_trash">
                        <b-form-checkbox
                          v-model="isSelectAllCheckedBan"
                          @change="toggleSelectAllBan"
                        >
                          Select All
                        </b-form-checkbox>
                        <b-link
                          v-if="itemsBan && itemsBan.length > 0"
                          class="btn green-border-btn trash_btn_whitelist"
                          :class="banRemoveIDArr.length == 0 ? 'disabled' : ''"
                          @click="removeMultipleFromBan()"
                          ><i class="icon-feather-trash-2"></i
                        ></b-link>
                      </div>
                    </div>
                    <b-button
                      v-if="filterBan || searchKeywordBan || company_ban || flStatus_ban"
                      @click="resetFilter()"
                      class="btn new_reset_text big_reset_txt search_common_margin_bottom_28 search_common_margin_bottom_18_mobile mobile_tab_padding_right_zero mobile_tab_padding_left_zero mobile_tab_padding_top_zero mobile_tab_padding_bottom_zero common_width_100_min_width_100_button new_black_reset_filter common_font_size_14 common_reset_btn_filter_transactions button ripple-effect search_all_red_btn filter_btn_widht_120 desktop_margin_right_zero btn-secondary"
                      style="background-color: red"
                      ><span class="mobile_reset">Reset all filters</span></b-button
                    >

                    <b-button
                      v-if="showFreelancer('restrict companies')"
                      @click="openInfoModel(null, 'ban create')"
                      class="desktop_margin_left_zero search_common_margin_bottom_18_mobile search_common_margin_bottom_28 mobile_tab_margin_left_zero common_width_100_min_width_100_button common_hover_effect_white_bg"
                      >Create</b-button
                    >
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="container my_new_container show_filter_arrow">
                <b-table
                  ref="datatableBan"
                  show-empty
                  striped
                  hover
                  responsive
                  :items="getBanEM"
                  :fields="fieldsBanLinked"
                  :current-page="currentPageBan"
                  :per-page="perPageBan"
                  :filter="filterBan"
                  :sort-by.sync="sortByBan"
                  :sort-desc.sync="sortDescBan"
                  :sort-direction="sortDirectionBan"
                  class="mobile_min_width_1000 eight_grid_tbl_new"
                >
                  <template #cell(checkBox)="items" v-if="showFreelancer('restrict companies')">
                    <b-form-checkbox
                      v-model="banRemoveIDArr"
                      :value="items.item.user_id"
                    ></b-form-checkbox>
                  </template>
                  <template #cell(last_name)="items">
                    <div class="new-name-td-box">
                      <div class="whole_div_img_and_name">
                        <div class="manage_user_img">
                          <b-img
                            v-if="items.item.thumbnail || items.item.profile_image"
                            :src="
                              items.item.thumbnail
                                ? items.item.thumbnail
                                : items.item.profile_image
                                ? items.item.profile_image
                                : webUrl + 'assets/images/user-avatar-small-01.png'
                            "
                            alt="User Icon"
                            height="100px"
                            width="100px"
                            class="square_img"
                          >
                          </b-img>
                          <b-img
                            v-else-if="
                              items.item.ft_profile_image_thumbnail || items.item.ft_profile_image
                            "
                            :src="
                              items.item.ft_profile_image_thumbnail
                                ? items.item.ft_profile_image_thumbnail
                                : items.item.ft_profile_image
                                ? items.item.ft_profile_image
                                : webUrl + 'assets/images/user-avatar-small-01.png'
                            "
                            alt="User Icon"
                            height="100px"
                            width="100px"
                            class="square_img"
                          >
                          </b-img>

                          <b-img
                            v-else
                            :src="webUrl + 'assets/images/user-avatar-small-01.png'"
                            alt="User Icon"
                            height="100px"
                            width="100px"
                            class="square_img"
                          >
                          </b-img>
                        </div>
                        <div class="user_name_div">
                          <b-link
                            :to="
                              showFreelancer('view profile')
                                ? {
                                    name: 'jobseeker-dp-profile-view',
                                    params: { id: encrypt(items.item.user_id) },
                                  }
                                : null
                            "
                            target="_blank"
                            style="color: #4c8bf5"
                          >
                            {{ items.item.last_name | capitalize }}
                            {{ items.item.first_name | capitalize }}
                          </b-link>
                        </div>
                      </div>
                      <p class="grey-text">
                        {{ items.item.email }}
                      </p>
                    </div>
                  </template>
                  <template #cell(mobile_number)="items">
                    <b-link
                      :href="'https://wa.me/+65' + items.item.mobile_number"
                      target="_blank"
                      style="color: #4c8bf5"
                    >
                      {{ items.item.country_code }}-{{ items.item.mobile_number }}
                    </b-link>
                  </template>
                  <template #cell(other_description)="items">
                    {{ items.item.other_description ? items.item.other_description : "-" }}
                  </template>
                  <template #cell(user_status)="items">
                    <p class="green_status" v-if="items.item.user_status == 'active'">
                      {{ items.item.user_status | capitalize }}
                    </p>
                    <p class="orange__status" v-if="items.item.user_status == 'inactive'">
                      {{ items.item.user_status | capitalize }}
                    </p>
                    <p class="red_status" v-if="items.item.user_status == 'deleted'">
                      {{ items.item.user_status | capitalize }}
                    </p>
                    <p class="red_status" v-if="items.item.user_status == 'blacklisted'">
                      {{ items.item.user_status | capitalize }}
                    </p>
                  </template>

                  <template #cell(name)="items">
                    {{ items.item.company_name | capitalize }}
                  </template>

                  <template #cell(toggleAction)="items">
                    <div class="toggle-action toggle_margin_unset">
                      <b-dropdown class="mx-1" right text="Action" boundary="window">
                        <b-dropdown-item
                          v-if="
                            items.item.user_status != 'deleted' &&
                            showFreelancer('restrict companies')
                          "
                          @click="
                            openInfoModel(
                              items.item.user_id,
                              'restrict',
                              items.item.restricted_companies
                            )
                          "
                          >Ban From EM
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-if="
                            items.item.user_status != 'deleted' &&
                            showFreelancer('restrict companies')
                          "
                          @click="removeFromBanLinkCompanies('restrict', items.item.user_id)"
                          >Remove from Restricted list

                    </div>
                  </template>
                </b-table>
                <nav class="pagination-box custom_pagination_border_radius">
                  <div class="page-row-box d-inline-block">
                    <b-form-group id="input-group-4">
                      <span class="d-inline-block">Rows per page</span>
                      <span class="d-inline-block">
                        <b-form-select v-model="form.rowsPerPageBan" @change="getBanEM">
                          <option>25</option>
                          <option>50</option>
                          <option>75</option>
                          <option>100</option>
                        </b-form-select></span
                      >
                    </b-form-group>
                  </div>
                  <div class="total-page-count d-inline-block">
                    <ul>
                      <li>
                        <span v-if="totalRowsBan != 0"
                          >{{ fromBan }} - {{ toBan }} of {{ totalRowsBan }}</span
                        >
                      </li>
                    </ul>
                  </div>
                  <b-pagination
                    :total-rows="totalRowsBan"
                    :per-page="perPageBan"
                    v-model="currentPageBan"
                    prev-text=""
                    next-text=""
                    hide-goto-end-buttons
                  />
                </nav>
              </div>
            </div>
          </b-tab>
           -->
        <!-- </b-tabs> -->
      <!-- </div> -->
    </div>
    <b-modal ref="modal" title="Pay Incetive & Referral" hide-footer  content-class="common_model_header common_model_title" modal-class="background_custom_class">
          <b-form>
            <div class="edit-form-box model_margin_top_zero">
                <div class="profile-repeate form-box-field">
                    <b-row>
                        <b-col lg="12" md="12">
                            <div class="form-map-box model_legend">
                                <b-row>
                                    <b-col lg="6" md="6" class="submit-field">
                                    <b-form-group id="input-group-1" label="Incentive" class="required model_margin_bottom_zero">
                                        <b-form-input id="input-1" type="text" placeholder="" @input="addSum()" v-model="incentive_amt" class="input-with-border model_border_and_box_shadow form-control"></b-form-input>
                                    </b-form-group>
                                    </b-col>
                                    <b-col lg="6" md="6" class="submit-field">
                                    <b-form-group id="input-group-1" label="Referral" class="required model_margin_bottom_zero">
                                        <b-form-input id="input-1" type="text" placeholder="" @input="addSum()" v-model="referral_amt" class="input-with-border model_border_and_box_shadow form-control"></b-form-input>
                                    </b-form-group>
                                    </b-col>
                                    <b-col lg="6" md="6" class="submit-field">
                                    <b-form-group id="input-group-1" label="Sum" class="model_margin_bottom_zero">
                                        <b-form-input id="input-1" type="text" placeholder="" v-model="sum" :disabled="disabled" class="input-with-border model_border_and_box_shadow form-control"></b-form-input>
                                    </b-form-group>
                                    </b-col>
                                </b-row>
                              <div slot="footer" class="form-btn common_crop_reset_btn mt-2">
                                  <b-button type="button" variant="success" class="model_common_bt_success_color desktop_margin_right_zero desktop_margin_left_zero" @click="payIncentive()">Submit</b-button>

                                  <b-button @click = "hideModel()" variant="light" class="model_common_bt_lite_color">Cancel</b-button>
                              </div>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </div>
          </b-form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { POST_API } from "../../../store/actions.type";
import permission from "../../../../../server/permission.js";
import Datepicker from 'vuejs-datepicker';


import moment from "moment";

export default {
  data() {
    return {
      fields: [
        { key: "checkBox", label: "Select All", sortable: false, thStyle: { width: "5%" } },
        { key: "nric", label: "NRIC", sortable: true, thStyle: { width: "15%" } },
        { key: "ptuser", label: "Name", sortable: true, thStyle: { width: "15%" } },
        { key: "mobile_number", label: "Mobile Number", sortable: true, thStyle: { width: "20%" } },
        { key: 'incentive', label: 'Incentive', sortable: false , thStyle:  {width: '10%'}},
        { key: 'referral', label: 'Referral', sortable: false , thStyle:  {width: '10%'}},
        { key: 'status', label: 'Status', sortable: false , thStyle:  {width: '6%'}},
        { key: 'transaction_id', label: 'Transaction ID', sortable: false , thStyle:  {width: '10%'}},
        { key: 'transaction_date', label: 'Transaction Date', sortable: false , thStyle:  {width: '10%'}},
        { key: "toggleAction", label: "Actions", thStyle:  {width: '2%'} },
      ],

      fieldsBanLinked: [
        { key: "checkBox", label: "Select All", sortable: false, thStyle: { width: "8%" } },
        { key: "last_name", label: "Name & Email", sortable: true, thStyle: { width: "22%" } },
        { key: "mobile_number", label: "Mobile Number", sortable: true, thStyle: { width: "15%" } },
        {
          key: "user_status",
          label: "Jobseeker Status",
          sortable: true,
          thStyle: { width: "15%" },
        },
        { key: "company_names", label: "Company Name", sortable: false, thStyle: { width: "20%" } },
        { key: "remarks", label: "Remarks", sortable: true, thStyle: { width: "15%" } },
        { key: "toggleAction", label: "Actions" },
      ],
      pager: {},
      pageOfItems: [],
      items: null,
      totalRows: 0,
      from: "",
      to: "",
      currentPage: 1,
      searchKeyword: "",
      perPage: 25,
      pageOptions: [25, 50, 75, 100],
      sortBy: 'users.last_name',
      sortDirection: "desc",
      filter: "",
      sortDesc: true,
      status: "active",
      month: moment().subtract(1, 'months').format('DD MMM YYYY'),
      month_filter: moment().subtract(1, 'months').format('DD MMM YYYY'),
      webUrlV1: process.env.VUE_APP_URL_V1,
      type : 'add',

      pagerBan: {},
      pageOfItemsBan: [],
      itemsBan: null,
      totalRowsBan: 0,
      fromBan: "",
      toBan: "",
      currentPageBan: 1,
      searchKeywordBan: "",
      perPageBan: 25,
      pageOptionsBan: [25, 50, 75, 100],
      sortByBan: null,
      sortDirectionBan: "desc",
      filterBan: "",
      sortDescBan: true,
      referral_amt : 0,
      incentive_amt : 0,
      sum : 0,
      disabled : true,


      webUrl: process.env.VUE_APP_URL,
      form: {
        rowsPerPage: 25,
        rowsPerPageBan: 25,
        user_id: "",
      },
      currentTab: "incentive",
      actions: "",
      actionsFreelancer: "",
      employers: [],
      accountStatus: ["active", "inactive", "blacklisted"],
      company_whitelist: "",
      company_ban: "",
      company_linked: "",
      flStatus_whitelist: "",
      flStatus_ban: "",
      flStatus_linked: "",
      restricted_companies: "",
      linked_companies: "",
      whitelisted_companies: "",
      whitelist_disable_companies: false,
      whitelist_all_companies: false,
      restrict_remark: "",
      view_remark: "",
      whitelist_remark: "",
      restrict: false,
      companiesList: "",
      rest_user_id: "",
      designation: "",
      userIDArr: [],
      isSelectAllCheckedUsers: false,
      create_whitelist_companies: [],
      create_whitelist_fl: [],
      create_whitelist_remarks: "",
      createWhitelistCompaniesList: [],
      createWhitelistFLList: [],
      is_whitelist: "no",
      isCompanyListLoaded: false,
      isFLListLoaded: false,
      searchDataCompany: "",
      searchDataFL: "",
      createBanLinkedTitle: "Ban From EM",
      banRemoveIDArr: [],
      isSelectAllCheckedBan: false,
      linkRemoveIDArr: [],
      isSelectAllCheckedLink: false,
      create_ban_linked_companies: [],
      create_ban_linked_fl: [],
      create_ban_linked_remarks: "",
      multiple:'no'
    };
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    moment: function (date) {
      if (date != null) return moment(date).utc().format("DD MMM YYYY hh:mm A");
      else return "-";
    },
    customFormatterOne(date) {
        if(date != '-')
            return moment(date).format('DD-MM-YYYY hh:mm A');
        else
            return '-';
    },
  },
  components: {
    Datepicker,
  },
  //  watch: {
  //   userIDArr(newSelectedItems) {
  //     this.isSelectAllCheckedUsers = newSelectedItems.length === this.items.length;
  //   },
  //   items(newItems) {
  //     if (newItems.length !== this.userIDArr.length) {
  //       this.isSelectAllCheckedUsers = false;
  //     }
  //   },


  // },
  methods: {
    customFormatter(date) {
            return moment(date).format('MMM,YYYY');
        },
    showSuccessAlert(message) {
      this.$swal({
        position: "center",
        icon: "success",
        iconColor: "#4c8bf5",
        title: message,
        showConfirmButton: false,
        timer: 1500,
      }).then((result) => {
        this.refreshDataTable();
        // this.$router.push({ name: 'job-poster-list' });
      });
    },
    showAlert() {
      this.$swal({
        position: "center",
        icon: "error",
        title: this.error_message,
        showConfirmButton: false,
        timer: 1500,
      });
    },
    customLabel({ first_name, last_name, name }) {
      return `${name} (${last_name} ${first_name})`;
    },
    customLabelCompany({ name }) {
      return `${name}`;
    },
    // customLabelFL({ first_name, last_name }) {
    //   return `${last_name} ${first_name}`;
    // },
    customLabelFL({ full_name, first_name, last_name, country_code, mobile_number }) {
      // return `${full_name} (${country_code}-${mobile_number})`;
      return `${last_name} ${first_name} (${country_code}-${mobile_number})`;
    },
    changeTab(currentTab) {
      this.currentTab = currentTab;
      this.isSelectAllCheckedUsers = false;
      this.userIDArr = [];

      if (this.currentTab == "incentive") {
        this.$refs.datatableIncentive.refresh();
      } else {
          this.$refs.datatableBan.refresh();
      }
    },
    toggleSelectAllWhitelist() {
      console.log(this.isSelectAllCheckedUsers,'this.isSelectAllCheckedUsers',this.items,this.isSelectAllCheckedUsers)
      if (this.isSelectAllCheckedUsers) {
        this.userIDArr = this.items.filter(item => { return item.status != 'paid'});
      } else {
        this.userIDArr = [];
      }
    },

    resetFilter() {
      if (this.currentTab == "incentive") {
        this.month = moment().subtract(1, 'months').format('DD MMM YYYY');
        this.filter = "";
        this.searchKeyword = "";
        this.$refs.datatableIncentive.refresh();
      } else {
        if (this.currentTab == "ban") {
          this.company_ban = "";
          this.flStatus_ban = "";
          this.filterBan = "";
          this.searchKeywordBan = "";
          this.$refs.datatableBan.refresh();
        }
      }
    },
    refreshDataTable() {
      this.isSelectAllCheckedUsers = false;
      this.userIDArr = [];
      this.$refs.datatableIncentive.refresh();
    },

    getIncentiveDetail() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            page: this.currentPage,
            keyword: this.filter,
            month: '20 Jul 2023',

            month: this.month,
            rowsPerPage: this.form.rowsPerPage,
            sortBy: this.sortBy,
            sortDirection: this.sortDesc ? "desc" : "asc",
            currentTab: this.currentTab,
            status: this.status,
          },
          api: "/api/incentive-got-users",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            // this.showAlert();
            return [];
          } else {
            this.items = this.$store.getters.getResults.data.data;
            this.totalRows = this.$store.getters.getResults.data.total;
            this.perPage = this.$store.getters.getResults.data.per_page;
            this.from = this.$store.getters.getResults.data.from;
            this.to = this.$store.getters.getResults.data.to;
            return this.items;
          }
        });
    },

    showModel(id,type,incentive,referral){
      console.log(id,'idd')
            this.user_id = id;
            this.type = type,
            this.referral_amt = parseFloat(referral),
            this.incentive_amt = parseFloat(incentive);

            this.addSum();

            if(type == 'add'){
                this.$refs['modal'].show();
            }else{
                this.revertIncentive();
            }


        },
        getListing1() {
            this.getIncentiveDetail();
            this.$refs.datatableIncentive.refresh();
        },
        addSum(){
            this.sum = parseFloat(this.referral_amt) + parseFloat(this.incentive_amt);

        },
        payIncentive() {

            var user = [];
            user = [{'id' : this.user_id , 'month': this.month}];

            this.$store.dispatch(POST_API, {
                    data: {
                        user_id: user,
                        month: this.month,
                        incentive: this.incentive_amt,
                        referral: this.referral_amt,
                        type : this.type,
                        multiple:'no'

                    },
                    api: "/api/pay-incentive",
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        this.success_message = this.$store.getters.getResults.message;
                        this.hideModel();
                        this.getListing1();
                        this.$swal({
                        position: "center",
                        icon: "success",
                        iconColor: "#4c8bf5",
                        title: this.success_message,
                        showConfirmButton: false,
                        timer: 1500,
                      }).then((result) => {
                        this.refreshDataTable();
                      });
                    }
                });
        },
        revertIncentive() {

            var user = [];
            user = [{'id' : this.user_id , 'month': this.month}];

            this.$swal({
                title: 'Please Confirm',
                text: 'Do you want to revert incentive?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'OK'
                })
            .then(result => {
                if (result.value) {
                    this.$store.dispatch(POST_API, {
                            data: {
                                user_id: user,
                                month: this.month,
                                incentive: this.incentive_amt,
                                referral: this.referral_amt,
                                type : this.type,
                                multiple:'no'

                            },
                            api: "/api/pay-incentive",
                        })
                        .then(() => {
                            if (this.$store.getters.containsErrors) {
                                this.error_message = this.$store.getters.getErrors;
                                this.showAlert();
                            } else {
                                this.success_message = this.$store.getters.getResults.message;
                                this.hideModel();
                                // this.getListing1();
                                this.$swal({
                                  position: "center",
                                  icon: "success",
                                  iconColor: "#4c8bf5",
                                  title: 'Incentive reverted successfully.',
                                  showConfirmButton: false,
                                  timer: 1500,
                                }).then((result) => {
                                  this.refreshDataTable();
                                });
                            }
                        });
                    }
                });
        },
        payMultiple() {
          var msg = "Are you sure you want to pay these users?";
          var msgg = "Incentive paid successfully.";
          this.$swal({
            title: "Please Confirm",
            text: msg,
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            showCloseButton: true,
            imageUrl: "/assets/images/alert-icon.png",
            customClass: {
              container: "whitelist_container",
            },
          }).then((result) => {
            if (result.value) {
              return this.$store
                .dispatch(POST_API, {
                  data: {
                    user_id: this.userIDArr,
                    month: this.month,
                    incentive: this.incentive_amt,
                    referral: this.referral_amt,
                    // type : this.type,
                    type : 'add',
                    multiple:'yes'
                  },
                  api: "/api/pay-incentive",
                })
                .then(() => {
                  if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                  } else {
                    this.$swal({
                      position: "center",
                      icon: "success",
                      iconColor: "#4c8bf5",
                      title: msgg,
                      showConfirmButton: false,
                      timer: 1500,
                    }).then((result) => {
                      this.refreshDataTable();
                    });
                  }
                });
            }
          });
        },
        mapCompId(value) {
          this.comp_id = value.id;
        },
        hideModel() {
          this.$refs["modal"].hide();
        },
        exportIncentive(){

          var base_url = this.webUrlV1;
          var export_url = '/api/export-incentive-got-users/'+(this.filter != null && this.filter != '' ? this.filter : 'null')+'/'+(this.month != '' ? moment(this.month).format('YYYY-MM') : null);
          console.log(base_url+export_url)
          window.open(base_url+export_url,'_blank')
        },


        permissionStaff() {
          if (this.$store.getters.getCurrentUserPermission) {
            if (
              this.$store.getters.getCurrentUserPermission.data.length > 0 &&
              this.$store.getters.currentUser.user_type_id == 2
            ) {
              var menu = this.$store.getters.getCurrentUserPermission.data;
              this.actions = permission.getPermissionStaff(menu, "Pay Incentive");
              this.actionsFreelancer = permission.getPermissionStaff(menu, "Jobseekers");
            }
          }
        },
        showFreelancer(action) {
          if (this.$store.getters.currentUser.user_type_id == 2) {
            return this.actionsFreelancer.indexOf(action) >= 0 ? true : false;
          } else {
            return true;
          }
        },
      show(action) {
        if (this.$store.getters.currentUser.user_type_id == 2) {
          return this.actions.indexOf(action) >= 0 ? true : false;
        } else {
          return true;
        }
      },
      toProcess(id,toProcess) {

        this.$store.dispatch(POST_API, {
            data: {
              id: id,
              toProcess:toProcess,
              month: moment(this.month).format('MMM YYYY'),
            },
            api: "/api/process-jobseeker-incentive",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showAlert();
          } else {
              this.success_message = this.$store.getters.getResults.message;
              this.hideModel();
              this.getListing1();
              this.$swal({
              position: "center",
              icon: "success",
              iconColor: "#4c8bf5",
              title: this.success_message,
              showConfirmButton: false,
              timer: 1500,
            }).then((result) => {
              this.refreshDataTable();
            });
          }
        });
      },
    },
    mounted() {
      $(".show_filter_arrow .table thead th div").contents().unwrap();
      $(".dashboard-content-container .simplebar-scroll-content").animate(
        {
          scrollTop: 0,
        },
        "fast"
      );
      this.form.user_id =
        this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : "";
      this.designation =
        this.$store.getters.currentUser != null ? this.$store.getters.currentUser.designation : "";

      this.permissionStaff();
      //document.title = "EL Connect - Admin - Website Contents - Job Posters";
    },
  };
</script>
