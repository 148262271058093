<template>

  <div class="animated fadeIn">
    <div class="manage-job-section">
      <div
        class="black-strip-head d-lg-flex d-sm-flex align-items-center padding_top_25 manage_job_top_anchor new_mobile_display_block justify-content-between"
      >
        <div class="dashboard-headline">
          <h1 class="mr-auto">Balance Summary</h1>
        </div>
      </div>
    </div>

    <b-row>
      <b-col lg="12">
        <div class="tab-box--head top_custom_head desktop_search_top_div">
          <div class="row">
              <div
                class="col-xl-3 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28"
              >
                <div class="search-box custom_search_admin desktop_margin_top_zero">
                  <b-input-group-prepend>
                    <b-form-input
                      id="searchBtn"
                      @keyup.enter.native="filter = search"
                      v-model="search"
                      placeholder="Search by keyword"
                    ></b-form-input>
                    <b-button class="btn points_events"
                      ><i class="fa fa-search" @click="filter = search"></i
                    ></b-button>
                  </b-input-group-prepend>
                </div>
              </div>

              <div class="col-xl-4 mobile_tab_max_width_flex search_common_margin_bottom_28">
                <label class="timesheet_label new_style_label required_sign required desktop_margin_bottom_zero mobile_margin_top_zero">Created on</label>       
                <div class="select-service-box date-picker-field clearfix">
                  <!-- <div class="label_div text_align_end_desktop">
                            <label>Date</label>
                          </div> -->
                  <b-form-group id="input-group-7" class="required mb-0">
                    <date-range-picker
                          class="common_date_range_picker_for_v2 web_bottom_zero timesheet_calender_new calender_border transactions_jb_calender calender_div_job_in_job common_width_100"
                          ref="picker"
                          :minDate="minDate"
                          :opens="center"
                          :locale-data="locale"
                          :auto-apply="auto"
                          v-model="dateRange"
                          :ranges="range"
                          @update="dateFilterFunction()"
                        >
                          <div slot="input" slot-scope="picker">
                            {{ picker.startDate | date }} - {{ picker.endDate | date }}
                          </div>
                        </date-range-picker>
                  </b-form-group>
                </div>
              </div>

              <div class="col-xl-12 mobile_tab_max_width_flex search_common_margin_bottom_28 invoices_btns balance_summary_btn">
                  <b-button
                    v-if="search != null || dateRange.startDate != null && dateRange.endDate != null"
                    @click="resetFilter()"
                    class="mobile_tab_padding_right_zero mobile_tab_padding_left_zero mobile_tab_padding_top_zero mobile_tab_padding_bottom_zero new_black_reset_filter_invoices  new_black_reset_filter common_font_size_14 common_reset_btn_filter_transactions button ripple-effect search_all_red_btn filter_btn_widht_120 reset_btn_balance_summary"
                    style="background-color: red"
                    ><span class="mobile_reset">Reset filters</span></b-button
                  >
                  <b-button v-if="show('export-invoices-data')"
                    class="coloured_btn_bl first_screen_invoices_button common_z_index_zero button ripple-effect new_add_btn_admin_e"
                    @click="generateExcel()"
                    >Export Invoices Data</b-button
                  >
                  <b-button v-if="show('download-all-pdfs')"
                    class="coloured_btn_bl first_screen_invoices_button common_z_index_zero button ripple-effect new_add_btn_admin_e"
                    @click="openModel()"
                    >Download All Pdf</b-button
                  >
              </div>
            </div>
          </div>

          <div class="my_neer show_filter_arrow">
            <b-table
              ref="datatable"
              show-empty
              striped
              hover
              responsive
              :items="getListing"
              :fields="fields"
              :current-page="currentPage"
              :per-page="perPage"
              :filter="filter"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              class="new_layout_table_like_v1 common_bill_tables old_name_td_table_for_mobile"
            >
            <!-- <template #cell(employer)="items">
              {{ items.item.employer }}
            </template>

            <template #cell(company)="items">
              {{ items.item.company }}
            </template> -->
            <template #cell(employer_company)="items">
              <div class="name-td-box old_name_td">
                 <!-- <div class="whole_div_img_and_name"> -->
                  <!-- <div class="manage_user_img"> -->
                    <img :src="items.item.company_logo ? items.item.company_logo : webUrl + 'assets/images/company-logo-placeholders.png'" class="">
                  <!-- </div> -->
                  <!-- <div class="user_name_div"> -->
                     <p class="blue_user_name old_name_td_emp_name">{{ items.item.employer }}</p>
                     <p class="grey-text word-break company_name_daily_deployment old_name_td_company_name">{{ items.item.company }}</p>
                  <!-- </div> -->
                 <!-- </div> -->
              </div>
            </template>
            <template #cell(summary_number)="items">
              {{ items.item.summary_number}}
            </template>

            <!-- <template #cell(summary_date)="items">
              {{ items.item.summary_date}}
            </template> -->

            <template #cell(summary_date)="items">
              {{ items.item.summary_date ? moment(items.item.summary_date).format("DD MMM YYYY") : "" }}
            </template>

            <template #cell(created_at)="data">
              {{ data.item.created_at ? moment(data.item.created_at).format("DD MMM YYYY") : "" }}<br>
              {{ data.item.created_at ? moment(data.item.created_at).format("hh:mm A") : "" }}
            </template>
            <template #cell(pending_amount)="items">
              {{ items.item.pending_amount}}
            </template>
            <template #cell(toggleAction)="items">
              <div class="toggle-action">
                <b-dropdown class="mx-1" right text="Actions" boundary="window">
                  <b-dropdown-item @click="downloadReport(items.item.url)" v-if="show('download')">Download Report
                  </b-dropdown-item>
                  <b-dropdown-item @click="generateBalanceSummary(items.item.company_id, items.item.url, items.item.summary_date, items.item.summary_number, 'update')" v-if="show('generate-balance-summary')">Generate Invoice
                  </b-dropdown-item>
                  <b-dropdown-item @click="sendEmail(items.item.company_id, items.item.url, items.item.summary_date, items.item.summary_number)" v-if="show('send-balance-summary')">Send Balance Summary Email
                  </b-dropdown-item>  
                  <b-dropdown-item @click="deleteRecord(items.item.summary_id)" v-if="show('delete')">Delete
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
          </b-table>
        </div>
        <nav class="pagination-box custom_pagination_border_radius">
          <div class="page-row-box d-inline-block">
            <b-form-group id="input-group-4">
              <span class="d-inline-block">Rows per page</span>
              <span class="d-inline-block"
                ><b-form-select v-model="form.rowsPerPage" @change="getListing">
                  <option>25</option>
                  <option>50</option>
                  <option>75</option>
                  <option>100</option>
                </b-form-select></span>
            </b-form-group>
          </div>
          <div class="total-page-count d-inline-block">
            <ul>
              <li>
                <span v-if="totalRows != 0">{{ from }} - {{ to }} of {{ totalRows }}</span>
              </li>
            </ul>
          </div>
          <b-pagination
            :total-rows="totalRows"
            :per-page="perPage"
            v-model="currentPage"
            prev-text=""
            next-text=""
            hide-goto-end-buttons
          />
        </nav>
      </b-col>
    </b-row>

    <b-modal ref="invoice-modal" title="Select Option" hide-footer content-class="common_model_header common_model_title" modal-class="background_custom_class">
        <!-- <b-form> -->
            <div class="edit-form-box model_margin_top_zero">
                <div class="profile-repeate form-box-field">
                    <b-row>
                        <b-col lg="12" md="12">
                            <div class="form-map-box model_legend">
                              <div>
                                <b-col lg="0" md="0">
                                  <b-form-group id="input-group-6" label="Balance Summary Month & Year" class="required">
                                      <datepicker v-model="month" :format="customFormatterThree" initial-view="year" minimum-view="month" :disabled-dates="disabledFromDate1" class="ticket_close_btn_from_date"></datepicker>
                                      <span class="input-icon normal_calender_single"><b-img v-bind:src="webUrl+'/images/calendar-grey-icon02.svg'" alt="Calander Icon"></b-img></span>
                                  </b-form-group>
                                </b-col>
                                <!-- <b-col lg="0" md="0">
                                  <b-form-group id="input-group-8" label="Duration " class="category-radio employer_multiple_radio">
                                      <b-form-radio v-model="duration" name="duration" value="1">1-15</b-form-radio>
                                      <b-form-radio v-model="duration" name="duration" value="2">16-30</b-form-radio>       
                                  </b-form-group>
                                </b-col> -->
                              </div>
                              <div slot="footer" class="form-btn common_crop_reset_btn">
                                  <b-button type="submit" variant="success" @click="downloadAllPdf()" class="model_common_bt_success_color">Submit</b-button>
                                  <b-button @click = "hideModel()" variant="light" class="model_common_bt_lite_color">Cancel</b-button>
                              </div>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </div>
        <!-- </b-form> -->
    </b-modal>
    <b-modal ref="range-modal" title="Select Range" hide-footer content-class="common_model_header common_model_title" modal-class="background_custom_class">
        <!-- <b-form> -->
            <div class="edit-form-box model_margin_top_zero">
                <div class="profile-repeate form-box-field">
                    <b-row>
                        <b-col lg="12" md="12">
                            <div class="form-map-box model_legend">
                                <b-col lg="0" md="0">
                                  <b-form-group id="input-group-6" label="Date Range:" >
                                        <date-range-picker ref="picker" :minDate="minDate" :opens="center" :locale-data="locale" :auto-apply="auto" v-model="dateRange" :ranges='range' @update="updateRange()" class="width_100_per common_date_range_width_hundered common_date_range_model_form_control">
                                            <div slot="input" slot-scope="picker">
                                                {{ picker.startDate | date }} - {{ picker.endDate | date }}
                                            </div>
                                        </date-range-picker>
                                        <span class="input-icon normal_calender_single">
                                            <b-img v-bind:src="webUrl+'/images/calendar-grey-icon02.svg'" alt="Calander Icon"></b-img>
                                        </span>
                                    </b-form-group>
                                </b-col>
                              <div slot="footer" class="form-btn common_crop_reset_btn">
                                  <b-button type="submit" variant="success" class="model_common_bt_success_color" @click="exportWeeklyReport()">Submit</b-button>
                                  <b-button @click="hideRangeModel()" variant="light" class="model_common_bt_lite_color">Cancel</b-button>
                              </div>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </div>
        <!-- </b-form> -->
    </b-modal>
  </div>
</template>

<script>
  import { POST_API } from "../../../store/actions.type";
  import moment from "moment";
  import Datepicker from 'vuejs-datepicker';
  import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
  import permission from "../../../../../server/permission.js";
  import DateRangePicker from 'vue2-daterange-picker'

  
  export default {
     data() {
       return {
        moment,
        fields: [
        {
          key: "employer_company",
          label: "Employer & Company",
          sortable: true,
          thStyle: { width: "20%" } 
        },
        { key: "summary_number", label: "Summary Number", sortable: true, thStyle: { width: "20%" } },
        {
          key: "summary_date",
          label: "Summary Date",
          sortable: true,
          thStyle: { width: "20%" } 
        },
        { key: "created_at", label: "Created At", sortable: true, thStyle: { width: "20%" }  },
        { key: "pending_amount", label: "Amount", sortable: true, thStyle: { width: "15%" }  },
        { key: 'toggleAction', label: 'Actions',  thStyle: { width: "5%" } },
        ],

        currentPage: 1,
        perPage: 25,
        totalRows: 0,
        search: "",
        pager: {},
        pageOfItems: [],
        minDate: null,
        center: "center",
        date: moment().format("YYYY-MM-DD"),
        items: null,
        totalRows: 0,
        format: "YYYY-MM-DD HH:mm",
        from: "",
        to: "",
        currentPage: 1,
        search: "",
        perPage: 25,
        itemsJobs: null,
        pageOptionsJobs: [25, 50, 75, 100],
        auto: true,
        range: false,
        month: moment().format('DD MMM YYYY'),
        pageOptions: [25, 50, 75, 100],
        locale: {
          direction: "ltr", //direction of text   
          format: "DD-MMM-YYYY", //fomart of the dates displayed
          separator: " - ", //separator between the two ranges
          applyLabel: "Apply",
          cancelLabel: "Cancel",
          /*weekLabel: 'W',
                  customRangeLabel: 'Custom Range',*/
          daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
          monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
          firstDay: 0, //ISO first day of week - see moment documenations for details
          sameDateFormat: {
            from: "DD MM YYYY, hh:mm",
            to: "hh:mm",
          },
        },
        sortBy: null,
        sortDirection: "desc",
        filter: "",
        sortDesc: true,
        webUrl: process.env.VUE_APP_URL,
        webUrlV1: process.env.VUE_APP_URL_V1,
        dateRange: {
          startDate: null,
          endDate: null
        },
        form: {
          rowsPerPage: 25,
          status: "",
        },
        webServerUrl: process.env.VUE_APP_SERVER_URL,
        actions: "",
        actionsFreelancer: "",
        user_id:"",
        designation:"",
        disabledFromDate1: {
            from: new Date()
        },
        duration:1,
       };
     },
    filters: {
      customFormatterOne(date) {
        return moment(date).utc().format("DD MMM YYYY hh:mm A");
      },
      date: function (date) {
        if (date) {
          return moment(date).format("DD MMM YYYY");
        } else {
          return "-";
        }
      },
      moment: function(date) {
              if (!date) return '-'
              return moment(date).format('DD-MM-YYYY hh:mm A')
      },
      momentDate: function(date) {
              if(date != null)
                return moment(date).format('DD-MM-YYYY')
              else
                return '-';
          },
      monthYear: function(value) {
          if (value == null) {
              return '';
          } else {
            var spl = value.split('-');
          
            if(spl[2] == '16'){
              return moment(value).format('16 MMM YYYY')
            }else{
              return moment(value).format('01 MMM YYYY')
            }
          }
      },
      date_formate: function(date) {
        if(date != null)
          return moment(date).format('DD-MM-YYYY hh:mm A')
        else
          return '-';
      },
      
    },
    components: {
      Datepicker,
      DateRangePicker
  },
    methods: {
      permissionStaff() {
        if (this.$store.getters.getCurrentUserPermission) {
          if (
            this.$store.getters.getCurrentUserPermission.data.length > 0 &&
            this.$store.getters.currentUser.user_type_id == 2
          ) {
            var menu = this.$store.getters.getCurrentUserPermission.data;
            this.actions = permission.getPermissionStaff(menu, "Balance Summaries");
            this.actionsFreelancer = permission.getPermissionStaff(menu, "Jobseekers");
          }
        }
      },
      show
      (action){
          if (this.$store.getters.currentUser.user_type_id == 2) {
              return this.actions.indexOf(action) >= 0 ? true : false ;  
          }else{
              return true;
          }
      },
      sendEmail(id, url, date, summary_number) {
        const payload = {
          company_id: id,
          summary_date: date,
          url: url,
          summary_number: summary_number
        };
        return this.$store.dispatch(POST_API, {
          data: payload,
          api: "/api/send-balance-summary-email"
        })
        .then(() => {
          console.log("Email sent successfully.");
        })
        .catch((error) => {
          console.error("Error sending balance summary email:", error);
        });
      },
      customFormatterThree(date) {
        return moment(date).format('MMM,YYYY');
      },
      downloadAllPdf() {
        const monthYear = this.month ? moment(this.month).format("MM-YYYY") : "null";
        // const duration = this.duration; 

        // Build the API URL similar to exportPDF()
        const base_url = this.webServerUrl;
        // const export_url = `/api/download-summaries-zip/${monthYear}/${duration}`;
        const export_url = `/api/download-summaries-zip/${monthYear}`;

        console.log("Download All PDF URL:", base_url + export_url);
        window.open(base_url + export_url, "_blank");
      },
      openModel()
      {
        this.$refs['invoice-modal'].show();
      },
      hideModel()
      {
        this.$refs['invoice-modal'].hide();
      },
      downloadReport(url){
        window.open(url, '_blank');
      },
      deleteRecord(id) {
        const msg = "Are you sure you want to delete this balance summary?";
        
        this.$swal.fire({
          title: 'Please Confirm',
          text: msg,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        })
        .then(result => {
          if (result.value) {
            return this.$store.dispatch(POST_API, {
              data: { id: id },
              api: '/api/delete-balance-summary'
            })
            .then(() => {
              if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showAlert();
              } else {
                const msg = this.$store.getters.getResults.message;
                this.showSuccessAlertStatus(msg);
                this.getListing().then(() => {
                  this.$refs.datatable.refresh();
                });
              }
            })
            .catch(err => {
              console.error("Error deleting balance summary:", err);
            });
          }
        })
        .catch(err => {
          // Optional: handle error from swal here if needed
          console.error("Error with confirmation dialog:", err);
        });
      },
      showSuccessAlertStatus(message) {
        this.$swal.fire({
          position: 'top-center',
          type: 'success',
          text: message,
          showConfirmButton: false,
          timer: 3000
        })
      },

      dateFilterFunction()
      {
          this.getListing().then(() => {
              this.$refs.datatable.refresh();
          })
      },
      resetFilter() {
        this.search = null;
        this.dateRange.startDate = null;
        this.dateRange.endDate = null;
        this.getListing().then(() => {
          this.$refs.datatable.refresh();
        })
      },
      getListing() {
        let sortField = this.sortBy;
        if (sortField === "employer_company") {
          sortField = "companies.name";
        }
        const payload = {
          page: this.currentPage,
          keyword: this.search,
          rowsPerPage: this.perPage,
          sortBy: sortField || "",
          sortDirection: this.sortDesc ? "desc" : "asc",
          sortDesc:this.sortDesc,
          startDate: this.dateRange.startDate ? moment(this.dateRange.startDate).format('YYYY-MM-DD') : '',
          endDate: this.dateRange.endDate ? moment(this.dateRange.endDate).format('YYYY-MM-DD') : '',
        };

        return this.$store
          .dispatch(POST_API, {
            data: payload,
            api: "/api/balance-summaries" // Ensure this matches your backend route
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              return [];
            } else {


              this.items = this.$store.getters.getResults.data.data;
              this.totalRows = this.$store.getters.getResults.data.total;
              this.perPage = this.$store.getters.getResults.data.per_page;
              this.from = this.$store.getters.getResults.data.from
              this.to = this.$store.getters.getResults.data.to


              
              return this.items;
            }
          })
          .catch((err) => {
            console.error("Error fetching balance summaries:", err);
          });
      },
      generateExcel() {
        const base_url = this.webServerUrl; 
        const exportEndpoint = "/api/balance-summary-admin-excel";
      
        window.open("https://v2.elconnect.sg/v2/api/balance-summary-admin-excel", "_blank");
      },


      generateBalanceSummary(id, url, date, number, generate_type) {
        const payload = {
          company_id: id,
          summary_date: date,
          type: generate_type,
          summary_number: number,
          url: url,
          email: false
        };

        this.$store.dispatch(POST_API, {
          data: payload,  
          api: "/api/emp-balance-summary"
        })
        .then(response => {
          if(response.success){
            //const base64Pdf = response.pdfBase64;
            this.downloadReport(url);
            this.$refs.datatable.refresh();
          } else {
            console.error("Error:", response.message);
          }
        })
        .catch(error => {
          console.error("Error generating balance summary:", error);
        });
      },


      formatDate(date) {
        return date ? moment(date).format("DD MMM YYYY") : "-";
      },

      
    },
    
    mounted() {
      $(".show_filter_arrow .table thead th div").contents().unwrap();
      this.user_id = this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : "";
      this.designation = this.$store.getters.currentUser != null ? this.$store.getters.currentUser.designation : "";
      this.permissionStaff();
      this.getListing();
    },
  };
  </script>
  