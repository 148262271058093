<template>
    <div class="animated fadeIn create_footer desktop_padding_bottom_zero">
        <div class="create-new-job-section email-template-section">
            <!-- <div id="breadcrumb-v2" class="mb-1">
                <div class="d-flex justify-content-start breadcrumb-wrapper">
                    <b-breadcrumb
                    :items="breadCrumb()"
                    />
                </div>
            </div> -->
            <div class="dashboard-headline">
                <h1>Add Expense (ID140423)</h1>
            </div>
            <div class="row">
                <div class="col-xl-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28">
                    <div class="search-box custom_search_admin desktop_margin_top_zero">
                        <!-- <b-input-group> -->
                            <b-input-group-prepend>
                                <b-form-input id="searchBtn" @keyup.enter.native="filter = searchKeyword" v-model="searchKeyword" placeholder="Search by keyword"></b-form-input>
                                <b-button class="btn points_events"><i class="fa fa-search" @click="filter = searchKeyword"></i></b-button>
                            </b-input-group-prepend>
                            <!-- </b-input-group> -->
                    </div>
                </div> 
                <div class="common_text_align_start_for_tab common_text_align_start_second_screen col-xl-8 mobile_tab_max_width_flex search_common_margin_bottom_28 common_text_align_end_desktop">
                    <b-button 
                    class="ml-0 common_export_btn common_z_index_zero button ripple-effect  desktop_margin_right_zero"
                    >Update</b-button
                    >
                    <b-button 
                    class="common_z_index_zero common_export_btn button ripple-effect  desktop_margin_right_zero"
                    >View SR</b-button
                    >
                </div>
            </div>
            <b-table 
                ref="datatable"
                show-empty
                striped
                hover
                responsive
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                class="new_layout_table_like_v1 mobile_min_width_850"
              >
                <template #cell(fl_name)="items">
                  <p>{{items.item.fl_name}}</p>
              </template>
              <template #cell(fl_earning)="items">
                  <p>{{items.item.fl_earning}}</p>
              </template>
              <template #cell(serviced_amount)="items">
                 <p>{{items.item.serviced_amount}}</p>
              </template>
              <template #cell(adjustment_amount)="items">
                  <b-form-input v-model="items.item.adjustment_amount"></b-form-input>
              </template>
            </b-table>
             <nav class="pagination-box custom_pagination_border_radius">
                <div class="page-row-box d-inline-block">
                    <b-form-group id="input-group-4">
                    <span class="d-inline-block">Rows per page</span>
                    <span class="d-inline-block"
                        ><b-form-select>
                        <option>25</option>
                        <option>50</option>
                        <option>75</option>
                        <option>100</option>
                        </b-form-select></span
                    >
                    </b-form-group>
                </div>
                <div class="total-page-count d-inline-block">
                    <ul>
                    <li>
                        <span v-if="totalRows != 0">{{ from }} - {{ to }} of {{ totalRows }}</span>
                    </li>
                    </ul>
                </div>
                <b-pagination
                    :total-rows="totalRows"
                    :per-page="perPage"
                    prev-text=""
                    next-text=""
                    hide-goto-end-buttons
                />
            </nav>
        </div>
    </div>
</template>

<script>

export default {

    data() {
        return {
           fields: [
                { key: 'fl_name', label: 'FL Name', sortable: true, thStyle: { width: "25%" } },
                { key: 'fl_earning', label: 'FL Earning', sortable: true, thStyle: { width: "25%" } },
                { key: 'serviced_amount', label: 'Serviced Amount', sortable: true, thStyle: { width: "25%" } },
                { key: 'adjustment_amount', label: 'Adjustment Amount', sortable: true, thStyle: { width: "25%" } },
            ],
            pager: {},
            pageOfItems: [],
            totalRows: 0,
            from: "",
            to: "",
            currentPage: 1,
            perPage: 25,
            pageOptionsJobs: [25, 50, 75, 100],
            form: {
                rowsPerPage: 25,
                status: "",
            },
        }
    },
    components: {
        
    },
    methods: {
        // breadCrumb(){
        //     var item = [{
        //         to:{name:'dp-jobs'},
        //         text: 'Manage Jobs',
        //     },{
        //         to:null,
        //         text: 'Add Job Expense'
        //     }];
        //     return item;
        // },
         getServiceReportListing() {
      return this.$store
        .dispatch(POST_API, {
          data:{
            page: this.currentPage,
            keyword: this.search,
            rowsPerPage:this.form.rowsPerPage,
            sortBy:this.sortBy,
            sortDirection:this.sortDirection,
            sortDesc:this.sortDesc,
            startDate: this.dateRange.startDate ? moment(this.dateRange.startDate).format('YYYY-MM-DD') : '',
            endDate: this.dateRange.endDate ? moment(this.dateRange.endDate).format('YYYY-MM-DD') : '',
          },
          api: "/api/service-report-list",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            // this.showAlert();
            return [];
          } else {
            this.items = this.$store.getters.getResults.data.data;
            this.totalRows = this.$store.getters.getResults.data.total;
            this.perPage = this.$store.getters.getResults.data.per_page;
            this.from = this.$store.getters.getResults.data.from
            this.to = this.$store.getters.getResults.data.to
              
            return this.items;
          }
        });
    },
    },
    mounted()
    {
        $(".dashboard-content-container .simplebar-scroll-content").animate({ 
            scrollTop: 0 
        }, "fast");
    }
};

</script>
