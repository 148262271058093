<template>
  <div>
    <!-- <div class="container full_container_dsk">
            <div class="row custom_dsk_row"> -->
    <div class="row">
        <!-- <div class="col-xl-12"> -->
        <div class="container my_new_container">
          <div class="dashboard-headline">
              <h1>Company Fulfilment Rate %</h1>
          </div>
          <div class="">
              <div class="row">
                    <div class="new_first_screen_max_width_and_flex col-xl-3 search_common_margin_bottom_28 mobile_tab_max_width_flex">
                     <b-form-select v-model="company_id_filter" id="jbs_category" @input="onChangeDataCom" class="jbs_category common_select_focus common_border_search  height_unset ">
                        <option value="">Select Company</option>
                        <option :value="employer.company_id" v-for="employer in employers">{{employer.name}}</option>
                    </b-form-select>
                    </div>
                      <div class="new_first_screen_max_width_and_flex col-xl-4 mobile_pl pl-0 search_common_margin_bottom_28 mobile_tab_max_width_flex">
                          <label class="rate_label timesheet_label new_style_label required_sign required desktop_margin_bottom_zero mobile_margin_top_zero">Select Month:</label>
                            <b-form-group id="input-group-6" class="desktop_margin_bottom_zero">
                              <datepicker v-model="defaultDate" @input="onChangeData" format="MMMM yyyy" minimum-view="month" month-picker class="add_calender_icon common_normal_calender_height freelancer_incentive_calender freelancer_incentive_big_calender mobile_top_calender"></datepicker>
                          </b-form-group>
                      </div>
                      <div class="new_first_screen_max_width_and_flex col-xl-3 mobile_pl pl-0 search_common_margin_bottom_28 mobile_tab_max_width_flex">
                        <label class="rate_label timesheet_label new_style_label required_sign required desktop_margin_bottom_zero mobile_margin_top_zero">to</label>
                          <b-form-group id="input-group-6" class="desktop_margin_bottom_zero">
                              <datepicker v-model="defaultDate1" @input="onChangeData" format="MMMM yyyy" minimum-view="month" month-picker class="add_calender_icon common_normal_calender_height freelancer_incentive_calender freelancer_incentive_big_calender mobile_top_calender"></datepicker>
                          </b-form-group>
                      </div>
                      <!-- <div class="row"> -->
                      <div class="new_first_screen_max_width_and_flex pdng_rate col-xl-2 pl-0 dashboard_table_btn_export search_common_margin_bottom_28 mobile_tab_max_width_flex">
                         <b-button
                          v-if="company_id_filter || defaultDate != defaultDate_filter || defaultDate1 != defaultDate1_filter"
                          @click="resetFilter()"
                          class="reset_btn_fullfillment_rate new_reset_text mobile_tab_padding_right_zero mobile_tab_padding_left_zero mobile_tab_padding_top_zero mobile_tab_padding_bottom_zero new_black_reset_filter common_font_size_14 common_reset_btn_filter_transactions button ripple-effect search_all_red_btn filter_btn_widht_120 desktop_margin_left_zero"
                          style="background-color: red"
                          ><span class="mobile_reset">Reset filters</span></b-button
                        >
                        <b-link :disabled="disabledExportBtn" class="export_btn_rate btn ml-0 common_export_anchor common_export_btn common_z_index_zero button ripple-effect  btn-secondary"
                          @click="exportAllData()" v-if="show('export')"
                          >Export</b-link
                        >
                       
                      </div>
                      <!-- <div class="col-xl-6 dashboard_table_btn_export search_common_margin_bottom_28 mobile_tab_max_width_flex"> -->
                          
                      <!-- </div> -->
                    <!-- </div> -->
              </div>
            <div class="row">
              <div class="col-xl-12">
                <div class="p-0 container my_new_container show_filter_arrow black_header">
                    <div v-if="job_data && Object.keys(job_data).length > 0">
                      <div v-for="(records, companyName) in job_data" :key="companyName">
                          <!-- Company Name Header -->
                          <!-- <br> -->
                          <div class="company-header company_header_admin">
                            <h3><b>{{ companyName }}</b></h3>
                          </div>
                          <!-- <br> -->
                          <!-- Table for Each Company -->
                          <b-table
                          ref="datatable"
                          show-empty
                          striped
                          hover
                          responsive
                          :items="records"
                          :fields="jobFields"
                          class="mb-4 new_layout_table_like_v1 emp_new_box_tbl_mrgn desktop_margin_top_zero new_box_tbl_mrgn"
                          >
                          <template #cell(month)="items">
                              {{ items.item.month }}
                          </template>

                          <template #cell(job_posted)="items">
                              {{ items.item.job_posted ? items.item.job_posted : "0" }}
                          </template>

                          <template #cell(job_completed)="items">
                              {{ items.item.job_completed ? items.item.job_completed : "0" }}
                          </template>

                          <template #cell(job_cancelled)="items">
                              {{ items.item.job_cancelled ? items.item.job_cancelled : "0" }}
                          </template>

                          <template #cell(job_without_app)="items">
                              {{ items.item.job_without_app ? items.item.job_without_app : "0" }}
                          </template>

                          <template #cell(job_cancelled_byfl)="items">
                              {{ items.item.job_cancelled_byfl ? items.item.job_cancelled_byfl : "0" }}
                          </template>

                          <template #cell(job_cancelled_by_admin)="items">
                              {{ items.item.job_cancelled_by_admin ? items.item.job_cancelled_by_admin : "0" }}
                          </template>

                          <template #cell(fulfilment)="items">
                              <p v-if="parseFloat(items.item.fulfilment) > 50">
                              {{ items.item.fulfilment ? items.item.fulfilment + ' %' : "0.00 %" }}
                              </p>
                              <p class="red_status" v-else>
                              {{ items.item.fulfilment ? items.item.fulfilment + ' %' : "0.00 %" }}
                              </p>
                          </template>
                          </b-table>
                      </div>
                    </div>
                    <!-- Show No Records Found only if job_data is empty -->
                    <div v-else class="text-center py-3">
                      <b-table
                        ref="datatable"
                        show-empty
                        striped
                        hover
                        responsive
                        :items="records"
                        :fields="jobFields"
                        class="mb-4 new_layout_table_like_v1 emp_new_box_tbl_mrgn desktop_margin_top_zero new_box_tbl_mrgn"
                        >
                        <h4>There are no records to show</h4>
                      </b-table>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- </div> -->
      </div>
    <!-- Spacer -->
    <!-- <div class="margin-top-70"></div> -->
    <!-- Spacer / End-->
    <!-- Row / End -->
   
  </div>
</template>

<script>
import { POST_API } from "../../../../store/actions.type";
import permission from "../../../../../../server/permission.js";
import Datepicker from 'vuejs-datepicker';

import moment from "moment";

export default {
  data() {
    return {
      jobFields:[
      { key: "month", label: "Month", sortable: false },
      { key: "job_posted", label: "Total Job Posting", sortable: false },
      { key: "job_completed", label: "Completed Jobs", sortable: false },
      { key: "job_cancelled", label: "Cancelled Jobs", sortable: false },
      { key: "job_without_app", label: "Jobs without Applicant", sortable: false },
      { key: "job_cancelled_byfl", label: "No Show/Freelancer Cancelled", sortable: false },
      { key: "job_cancelled_by_admin", label: "Employer Cancelled", sortable: false },
      { key: "fulfilment", label: "Fulfilment %", sortable: false }
      ],

      pager: {},
      pageOfItems: [],
      job_data: null,
      totalRows: 0,
      from: "",
      to: "",
      currentPage: 1,
      searchKeyword: "",
      perPage: 25,
      pageOptions: [25, 50, 75, 100],
      sortBy: null,
      sortDirection: "desc",
      filter: "",
      sortDesc: true,

      webUrl: process.env.VUE_APP_URL,
      webServerUrl: process.env.VUE_APP_SERVER_URL,

      form: {
        rowsPerPage: 25,
        user_id: "",
      },
      actions: "",
      actionsFreelancer: "",
      employers: "",
      company_id_filter:"",
      designation: "",
      disabledExportBtn:false,
      
      defaultDate: moment().subtract(1, 'months').format('01 MMM YYYY'),
      defaultDate1: moment().endOf('month').format('DD MMM YYYY'),
      defaultDate_filter: moment().subtract(1, 'months').format('01 MMM YYYY'),
      defaultDate1_filter: moment().endOf('month').format('DD MMM YYYY'),
      maxDateUpcoming:moment().format('YYYY-MM-DD'),
    };
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    moment: function (date) {
      if (date != null) return moment(date).utc().format("DD MMM YYYY hh:mm A");
      else return "-";
    },
  },
  components: {
    Datepicker,
  },
  

  methods: {
    
    showAlert() {
      this.$swal({
        position: "center",
        icon: "error",
        title: this.error_message,
        showConfirmButton: false,
        timer: 1500,
      });
    },
    

    resetFilter() {
        this.filter = "";
        this.searchKeyword = "";
        this.company_id_filter = "";
        this.defaultDate = moment().subtract(1, 'months').format('01 MMM YYYY')
        this.defaultDate1 = moment().endOf('month').format('DD MMM YYYY')
         this.getCompanyFulfilmentRate().then(() => {
                // this.$refs.datatable.refresh();
            });
      
    },
    
    exportAllData() {
      var base_url_v2 = this.webServerUrl;
        var startDate = this.defaultDate ? moment(this.defaultDate).format('YYYY-MM-DD') : 'null';
        var endDate = this.defaultDate1 ? moment(this.defaultDate1).format('YYYY-MM-DD') : 'null';
        var company_filter =  this.company_id_filter
              ? this.company_id_filter
              : "null";
      var export_url =
        "/api/export-company-fulfilment-rate/" +
        company_filter +
         "/" +
        startDate +
         "/" +
        endDate;
     
      window.open(base_url_v2 + export_url, "_blank");
    },
    onChangeData()
        {
            this.company_id_filter = "";
            this.defaultDate = moment(this.defaultDate).format('DD MMM YYYY');
            this.defaultDate1 = moment(this.defaultDate1).endOf('month').format('DD MMM YYYY');

            this.getCompanyFulfilmentRate().then(() => {
                // this.$refs.datatable.refresh();
            });

        },
    onChangeDataCom()
    {
        this.defaultDate = moment(this.defaultDate).format('DD MMM YYYY');
        this.defaultDate1 = moment(this.defaultDate1).endOf('month').format('DD MMM YYYY');

        this.getCompanyFulfilmentRate().then(() => {
            // this.$refs.datatable.refresh();
        });

    },
    getCompanyFulfilmentRate() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            // page: this.currentPage,
            // keyword: this.filter,
            // rowsPerPage: this.form.rowsPerPage,
            // sortBy: this.sortBy ? this.sortBy : "u.id",
            // sortDirection: this.sortDesc ? "desc" : "asc",
            // id: this.company_id,
            // id:561,
            startDate: this.defaultDate ? moment(this.defaultDate).format('YYYY-MM-DD') : '',
            endDate: this.defaultDate1 ? moment(this.defaultDate1).format('YYYY-MM-DD') : '',
            id: this.company_id_filter
              ? this.company_id_filter
              : "",
          },
          api: "/api/companyFulfilmentRate",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            // this.showAlert();
            return [];
          } else {
            this.job_data = this.$store.getters.getResults.data.jobs;
            this.employers = this.$store.getters.getResults.data.companies;

            if(this.job_data != null)
            {
                this.disabledExportBtn = false;
            }
            else{
              this.disabledExportBtn = true;
            }
            console.log(this.job_data)
            return this.job_data
          }
        });
    },
    
    
    mapCompId(value) {
      this.comp_id = value.id;
    },
    
    
    // getEmployers() {
    //   this.$store
    //     .dispatch(POST_API, {
    //       data: {},
    //       // api: "/api/employer-job-list-ft-admin",
    //       api: "/api/employer-job-list",
    //       // api: "/api/employer-with-job-list",


    //     })
    //     .then(() => {
    //       if (this.$store.getters.containsErrors) {
    //         this.error_message = this.$store.getters.getErrors;

    //         this.$swal({
    //           position: "center",
    //           icon: "error",
    //           title: this.error_message,
    //           showConfirmButton: false,
    //           timer: 1500,
    //         });
    //       } else {
    //         this.employers = this.$store.getters.getResults.data;
    //       }
    //     });
    // },
    permissionStaff() {
      if (this.$store.getters.getCurrentUserPermission) {
        if (
          this.$store.getters.getCurrentUserPermission.data.length > 0 &&
          this.$store.getters.currentUser.user_type_id == 2
        ) {
          var menu = this.$store.getters.getCurrentUserPermission.data;
          this.actions = permission.getPermissionStaff(menu, "Company Fulfilment Rate");
          this.actionsFreelancer = permission.getPermissionStaff(menu, "Jobseekers");
        }
      }
    },
    showFreelancer(action) {
      if (this.$store.getters.currentUser.user_type_id == 2) {
        return this.actionsFreelancer.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
    show(action) {
      if (this.$store.getters.currentUser.user_type_id == 2) {
        return this.actions.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
  },
  mounted() {
    $(".show_filter_arrow .table thead th div").contents().unwrap();
    $(".dashboard-content-container .simplebar-scroll-content").animate(
      {
        scrollTop: 0,
      },
      "fast"
    );
    this.form.user_id =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : "";
    this.designation =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.designation : "";

    this.permissionStaff();
    // this.getEmployers();
    this.getCompanyFulfilmentRate()
    //document.title = "EL Connect - Admin - Website Contents - Job Posters";
  },
};
</script>
